/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import {
  Container, Row, Col, Button, Spinner, Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { daily } from '../../assets/api/axios';
import {
  CustomTable, ErrorHandler, CustomModal, CustomDropdown,
} from '../../component/common';
import Permission from '../../access&permissions/permission';

class LLDaily extends Component {
  constructor(props) {
    super(props);
    const { userPermission } = props;
    this.canIEdit = userPermission && userPermission.includes(Permission.LOVELOCAL_DAILY_WRITE);
    this.state = {
      dailyType: this.canIEdit ? 'create' : 'list',
      feeds: null,
      loading: true,
      error: false,
      page: 1,
      rowsPerPage: 10,
      feedType: 'HOW_TO_PROMOTE_YOUR_SHOP',
      contentType: 'VIDEO_POST',
      tag: [],
      heading: '',
      subHeading: '',
      description: '',
      deeplink: '',
      language: 'en',
      url: '',
      submitting: false,
      submitError: false,
      editFeedsId: null,
      deleteFeedsId: null,
      selectedFeedType: null,
      selectedLanguage: null,
      createStory: true,
    };
  }

  componentDidMount = () => {
    const { dailyType } = this.state;
    if (dailyType === 'list') {
      this.loadDaily();
    }
  }

  loadDaily = () => {
    const {
      page, rowsPerPage, selectedFeedType, selectedLanguage,
    } = this.state;
    const offset = (page - 1) * rowsPerPage;
    this.setState({ loading: true });
    daily(
      'get',
      {
        offset: offset.toString(),
        limit: rowsPerPage,
        type: selectedFeedType,
        languageCode: selectedLanguage,
      },
      null,
      null,
    ).then((res) => {
      this.setState({
        feeds: res.data,
        loading: false,
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });
  }

  handleDailyType = (type) => {
    this.setState({
      dailyType: type,
    }, () => {
      const { dailyType } = this.state;
      if (dailyType === 'list') {
        this.loadDaily();
      }
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleRadioButtonChange = (event) => {
    const { name } = event.target;
    this.setState((state) => ({
      [name]: !state[name],
    }));
  }

  handleSubmitDaily = () => {
    const {
      feedType, tag, contentType, heading, subHeading,
      description, deeplink, url, editFeedsId, language,
      createStory,
    } = this.state;
    this.setState({
      submitting: true,
      submitError: false,
    });
    daily(
      `${editFeedsId ? 'PATCH' : 'POST'}`,
      {},
      {
        feedType,
        tags: tag.map((item) => item.value),
        contentType,
        heading,
        subHeading,
        description,
        deeplink,
        url,
        languageCode: language,
        createStory,
      },
      `${!editFeedsId ? '' : editFeedsId}`,
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          submitting: false,
        }, () => {
          this.setState({
            editFeedsId: null,
            feedType: 'HOW_TO_PROMOTE_YOUR_SHOP',
            tag: [],
            contentType: 'VIDEO_POST',
            heading: '',
            subHeading: '',
            description: '',
            deeplink: '',
            url: '',
            language: 'en',
            submitting: false,
            submitError: false,
            createStory: true,
          }, () => {
            this.loadDaily();
          });
        });
      } else {
        throw new Error();
      }
    }).catch(() => {
      this.setState({
        submitting: false,
        submitError: true,
      });
    });
  }

  deleteFeed = () => {
    const { deleteFeedsId } = this.state;
    this.setState({
      submitting: true,
    });

    daily(
      'DELETE',
      {},
      null,
      deleteFeedsId,
    ).then(() => {
      this.setState({
        submitting: false,
        deleteFeedsId: null,
      }, () => {
        this.loadDaily();
      });
    }).catch(() => {
      this.setState({
        submitting: false,
        submitError: true,
      });
    });
  }

  onNext = () => {
    const { page } = this.state;
    this.setState({
      page: page + 1,
    }, this.loadDaily);
  }

  onPrev = () => {
    const { page } = this.state;
    this.setState({
      page: page - 1,
    }, this.loadDaily);
  }

  onSubmitPage = () => {
    const { page } = this.state;
    this.setState({
      page,
    }, this.loadDaily);
  }

  onSubmitRowsPerPage = () => {
    const { rowsPerPage } = this.state;
    this.setState({
      rowsPerPage,
    }, this.loadDaily);
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  render() {
    const {
      dailyType, loading, error, feeds, page, rowsPerPage,
      feedType, contentType, heading, subHeading, deeplink,
      description, url, submitting, submitError,
      editFeedsId, deleteFeedsId, selectedFeedType, language,
      selectedLanguage, createStory, tag,
    } = this.state;

    const tagOptions = [
      {
        value: 'GROW_BUSINESS',
        label: 'GROW_BUSINESS',
      },
      {
        value: 'FULFIL_ORDERS',
        label: 'FULFIL_ORDERS',
      },
      {
        value: 'MODIFY_ORDERS',
        label: 'MODIFY_ORDERS',
      },
      {
        value: 'SETTLEMENTS',
        label: 'SETTLEMENTS',
      },
      {
        value: 'CATALOGUING_PRODUCTS',
        label: 'CATALOGUING_PRODUCTS',
      },
    ];

    const feedTypes = (
      <>
        <option value="HOW_TO_PROMOTE_YOUR_SHOP">How to Promote your Shop</option>
        <option value="HOW_TO_MANAGE_ORDERS">How to Manage Orders</option>
        <option value="HOW_TO_MANAGE_CATALOGUE">How to Manage Catalogue</option>
        <option value="HOW_SETTLEMENTS_WORK_ON_LOVELOCAL">How Settlements Work on LoveLocal</option>
        <option value="TOP_VIDEOS">Top Videos</option>
        <option value="NEWS_ON_KIRANA">News on Kirana</option>
        <option value="NEW_FEATURES">New Features</option>
        <option value="WHAT_OTHER_RETAILERS_SAY">What Other Retailers Say</option>
      </>
    );

    const editFeedModalBody = (
      <Col>
        {
          submitError
          && (
            <ErrorHandler
              retryLogic={() => {
                this.handleSubmitDaily();
              }}
            />
          )
        }
        {
          submitting
          && (
            <div
              className="w-100 pt-3 d-flex align-items-center justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          )
        }
        {
          !submitError && !submitting
          && (
            <Col
              className="mx-3 pt-3"
            >
              <Form
                onSubmit={() => {
                  this.handleSubmitDaily();
                }}
              >
                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    Feed Type:
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <select
                      name="feedType"
                      id="feedType"
                      className="form-control"
                      value={feedType}
                      onChange={this.handleChange}
                    >
                      {feedTypes}
                    </select>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    Tag:
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <Select
                      id="tags"
                      onChange={(selectedTypes) => {
                        this.setState({
                          tag: selectedTypes,
                        });
                      }}
                      value={tag}
                      isMulti
                      options={tagOptions}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    Language:
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <select
                      name="language"
                      id="language"
                      className="form-control"
                      value={language}
                      onChange={this.handleChange}
                    >
                      <option value="en">English</option>
                      <option value="gu">Gujarati</option>
                      <option value="hi">Hindi</option>
                      <option value="kn">Kanada</option>
                      <option value="mr">Marathi</option>
                      <option value="te">Telugu</option>
                    </select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    Content Type:
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <select
                      name="contentType"
                      id="contentType"
                      className="form-control"
                      value={contentType}
                      onChange={this.handleChange}
                    >
                      <option value="VIDEO_POST">VIDEO POST</option>
                      {
                        feedType === 'OTHER' && (
                          <option value="IMAGE_POST">IMAGE POST</option>
                        )
                      }
                    </select>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    Heading:
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <Form.Control
                      name="heading"
                      value={heading}
                      id="heading"
                      onChange={this.handleChange}
                      autoComplete="off"
                      maxLength={128}
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    Sub Heading:
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <Form.Control
                      name="subHeading"
                      value={subHeading}
                      id="subHeading"
                      onChange={this.handleChange}
                      autoComplete="off"
                      maxLength={128}
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    Description:
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <div className="fs-01 text-secondary text-right">
                      {description.length}
                      /128
                    </div>
                    <Form.Control
                      name="description"
                      value={description}
                      id="description"
                      onChange={this.handleChange}
                      autoComplete="off"
                      maxLength={128}
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    Feed Url:
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <Form.Control
                      name="url"
                      value={url}
                      id="url"
                      onChange={this.handleChange}
                      autoComplete="off"
                      required
                    />
                    <div
                      className="mt-1 text-medium"
                    >
                      Use the format: https://www.youtube.com/watch?v=&lt;video_id&gt;
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    Deep Link:
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <Form.Control
                      name="deeplink"
                      value={deeplink}
                      id="deeplink"
                      onChange={this.handleChange}
                      autoComplete="off"
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b>
                      Create Story:
                    </b>
                  </Form.Label>
                  <Col
                    xs={4}
                    className="px-3"
                  >
                    <Form.Check
                      inline
                      label="Yes"
                      name="createStory"
                      type="radio"
                      checked={createStory}
                      onChange={this.handleRadioButtonChange}
                    />
                  </Col>
                  <Col
                    xs={4}
                    className="px-3"
                  >
                    <Form.Check
                      inline
                      label="No"
                      name="createStory"
                      type="radio"
                      checked={!createStory}
                      onChange={this.handleRadioButtonChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={6} />
                  <Col sm={6}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="px-6 py-2"
                      disabled={
                      !heading
                      || !subHeading
                      || !deeplink
                      || !description
                      || !url
                      || !deeplink
                      || !language
                    }
                    >
                      SUBMIT
                    </Button>
                  </Col>
                </Form.Group>

              </Form>
            </Col>
          )
        }
      </Col>
    );

    const deleteFeedsConfirmationModalBody = (
      <Col>
        {
          submitError
          && (
            <ErrorHandler
              retryLogic={() => {
                this.deleteFeed();
              }}
            />
          )
        }
        {
          submitting
          && (
            <div
              className="w-100 pt-3 d-flex align-items-center justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          )
        }
        {
          !submitError && !submitting && deleteFeedsId
          && (
            <Container
              className="justify-content-center"
            >
              <Row
                className="mx-auto mt-4"
              >
                <Col
                  xs={24}
                  className="text-center fs-3"
                >
                  Are you sure you want to&nbsp;
                  <span
                    className="text-danger"
                  >
                    DELETE
                  </span>
                  &nbsp;this feed?
                </Col>
              </Row>
              <Row
                className="mt-4"
              >
                <Col
                  xs={12}
                  className="text-right"
                >
                  <Button
                    variant="outline-primary"
                    value="cancel"
                    className="py-1 px-3 fs-2"
                    disabled={submitting}
                    onClick={() => {
                      this.setState({
                        deleteFeedsId: null,
                      });
                    }}
                  >
                    CANCEL
                  </Button>
                </Col>
                <Col
                  xs={12}
                  className="text-left"
                >
                  <Button
                    variant="outline-danger"
                    value="submit"
                    className="py-1 px-3 fs-2 lg active"
                    disabled={submitting}
                    onClick={() => {
                      this.deleteFeed();
                    }}
                  >
                    DELETE
                  </Button>
                </Col>
              </Row>
            </Container>
          )
        }
      </Col>
    );

    const languageMap = {
      en: 'English',
      gu: 'Gujarati',
      hi: 'Hindi',
      kn: 'Kanada',
      mr: 'Marathi',
      te: 'Telugu',
    };

    const config = [
      {
        key: 'id',
        displayText: 'Id',
      },
      {
        key: 'languageCode',
        displayText: 'Language',
        renderer: (data) => (
          <span>
            {languageMap[data.languageCode]}
          </span>
        ),
      },
      {
        key: 'createdOn',
        displayText: 'Created on',
      },
      {
        key: 'feedType',
        displayText: 'Feed Type',
      },
      {
        key: 'tags',
        displayText: 'Tag',
        renderer: (data) => (
          <span>
            {data.tags.join(', ')}
          </span>
        ),
      },
      {
        key: 'contentType',
        displayText: 'Content Type',
      },
      {
        key: 'heading',
        displayText: 'Heading',
      },
      {
        key: 'subHeading',
        displayText: 'Sub Heading',
      },
      {
        key: 'description',
        displayText: 'Description',
      },
      {
        key: 'deeplink',
        displayText: 'View Deeplink',
        renderer: (data) => (
          <div>
            {data.deeplink ? (
              <a
                href={data.deeplink}
                target="_blank"
                rel="noopener noreferrer"
              >
                VIEW
              </a>
            ) : 'NA'}
          </div>
        ),
      },
      {
        key: 'url',
        displayText: 'View Url',
        renderer: (data) => (
          <div>
            {data.url ? (
              <a
                href={data.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                VIEW
              </a>
            ) : 'NA'}
          </div>
        ),
      },
      ...this.canIEdit ? [{
        key: 'edit',
        displayText: 'EDIT',
        renderer: (data) => (
          <Button
            variant="link"
            className="p-0"
            onClick={() => {
              this.setState({
                editFeedsId: data.id,
                feedType: data.feedType,
                tag: (data.tags)
                  .map((item) => ({ value: item, label: item })),
                language: data.languageCode,
                contentType: data.contentType,
                heading: data.heading,
                subHeading: data.subHeading,
                description: data.description,
                deeplink: data.deeplink,
                url: data.url,
                createStory: data.createStory,
              });
            }}
          >
            Edit
          </Button>
        ),
      },
      {
        key: 'delete',
        displayText: 'DELETE',
        renderer: (data) => (
          <Button
            variant="link"
            className="p-0"
            onClick={() => {
              this.setState({
                deleteFeedsId: data.id,
              });
            }}
          >
            Delete
          </Button>
        ),
      }] : [],
    ];

    return (
      <Container
        fluid
        className="bg-white"
      >
        <CustomModal
          body={editFeedModalBody}
          closeButton
          size="lg"
          show={!!editFeedsId}
          title="Edit Feed"
          onHide={() => {
            this.setState({
              editFeedsId: null,
              submitting: false,
              submitError: false,
              feedType: 'HOW_TO_PROMOTE_YOUR_SHOP',
              contentType: 'VIDEO_POST',
              heading: '',
              subHeading: '',
              description: '',
              deeplink: '',
              url: '',
              createStory: true,
            });
          }}
        />
        <CustomModal
          body={deleteFeedsConfirmationModalBody}
          closeButton
          size="lg"
          show={!!deleteFeedsId}
          title="Delete Banner"
          onHide={() => {
            this.setState({
              deleteFeedsId: null,
              submitting: false,
              submitError: false,
            });
          }}
        />

        <Row>
          <Col
            xs={12}
            className={`p-0 m-0 ${this.canIEdit ? '' : 'd-none'}`}
          >
            <Button
              variant="link"
              block
              className={`rounded-0 border py-2 ${
                dailyType === 'create' ? 'border-primary' : 'border-secondary text-dark'
              }`}
              onClick={() => this.handleDailyType('create')}
            >
              <b>Create Daily</b>
            </Button>
          </Col>
          <Col
            xs={12}
            className="p-0 m-0"
          >
            <Button
              variant="link"
              block
              className={`rounded-0 border py-2 ${
                dailyType === 'list' ? 'border-primary' : 'border-secondary text-dark'
              }`}
              onClick={() => this.handleDailyType('list')}
            >
              <b>Daily Feeds</b>
            </Button>
          </Col>
        </Row>
        <>
          {dailyType === 'create' ? (
            <>
              <Row
                className="pt-3"
              >
                <Col
                  xs={4}
                >
                  <b>Language :</b>
                </Col>
                <Col
                  xs={8}
                >
                  <select
                    name="language"
                    id="language"
                    className="form-control"
                    value={language}
                    onChange={this.handleChange}
                  >
                    <option value="en">English</option>
                    <option value="gu">Gujarati</option>
                    <option value="hi">Hindi</option>
                    <option value="kn">Kanada</option>
                    <option value="mr">Marathi</option>
                    <option value="te">Telugu</option>
                  </select>
                </Col>
              </Row>
              <Row
                className="pt-3"
              >
                <Col
                  xs={4}
                >
                  <b>Feed Type :</b>
                </Col>
                <Col
                  xs={8}
                >
                  <select
                    name="feedType"
                    id="feedType"
                    className="form-control"
                    value={feedType}
                    onChange={this.handleChange}
                  >
                    {feedTypes}
                  </select>
                </Col>
              </Row>
              <Row
                className="pt-3"
              >
                <Col
                  xs={4}
                >
                  <b>Tag :</b>
                </Col>
                <Col
                  xs={8}
                >
                  <Select
                    id="tags"
                    onChange={(selectedTypes) => {
                      this.setState({
                        tag: selectedTypes,
                      });
                    }}
                    value={tag}
                    isMulti
                    options={tagOptions}
                  />
                </Col>
              </Row>
              <Row
                className="pt-3"
              >
                <Col
                  xs={4}
                >
                  <b>Content Type :</b>
                </Col>
                <Col
                  xs={8}
                >
                  <select
                    name="contentType"
                    id="contentType"
                    className="form-control"
                    value={contentType}
                    onChange={this.handleChange}
                  >
                    <option value="VIDEO_POST">VIDEO POST</option>
                    {
                      feedType === 'OTHER' && (
                        <option value="IMAGE_POST">IMAGE POST</option>
                      )
                    }
                  </select>
                </Col>
              </Row>
              <Row
                className="pt-3"
              >
                <Col
                  xs={4}
                >
                  <b>Heading :</b>
                </Col>
                <Col
                  xs={8}
                >
                  <input
                    type="text"
                    name="heading"
                    className="form-control"
                    value={heading}
                    onChange={this.handleChange}
                    maxLength={128}
                  />
                </Col>
              </Row>
              <Row
                className="pt-3"
              >
                <Col
                  xs={4}
                >
                  <b>Sub Heading :</b>
                </Col>
                <Col
                  xs={8}
                >
                  <input
                    type="text"
                    name="subHeading"
                    className="form-control"
                    value={subHeading}
                    onChange={this.handleChange}
                    maxLength={128}
                  />
                </Col>
              </Row>
              <Row
                className="pt-3"
              >
                <Col
                  xs={4}
                >
                  <b>Description :</b>
                </Col>
                <Col
                  xs={8}
                >
                  <div className="fs-01 text-secondary text-right">
                    {description.length}
                    /128
                  </div>
                  <textarea
                    type="text"
                    name="description"
                    className="form-control"
                    value={description}
                    onChange={this.handleChange}
                    maxLength={128}
                  />
                </Col>
              </Row>
              <Row
                className="pt-3"
              >
                <Col
                  xs={4}
                >
                  <b>Feed Url :</b>
                </Col>
                <Col
                  xs={8}
                >
                  <input
                    type="text"
                    name="url"
                    className="form-control"
                    value={url}
                    onChange={this.handleChange}
                  />
                  <div
                    className="mt-1 text-medium"
                  >
                    Use the format: https://www.youtube.com/watch?v=&lt;video_id&gt;
                  </div>
                </Col>
              </Row>
              <Row
                className="pt-3"
              >
                <Col
                  xs={4}
                >
                  <b>Deep Link :</b>
                </Col>
                <Col
                  xs={8}
                >
                  <input
                    type="text"
                    name="deeplink"
                    className="form-control"
                    value={deeplink}
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <Row
                className="pt-3"
              >
                <Col
                  xs={4}
                >
                  <b>
                    Create Story:
                  </b>
                </Col>
                <Col
                  xs={4}
                  className="px-3"
                >
                  <Form.Check
                    inline
                    label="Yes"
                    name="createStory"
                    type="radio"
                    checked={createStory}
                    onChange={this.handleRadioButtonChange}
                  />
                </Col>
                <Col
                  xs={4}
                  className="px-3"
                >
                  <Form.Check
                    inline
                    label="No"
                    name="createStory"
                    type="radio"
                    checked={!createStory}
                    onChange={this.handleRadioButtonChange}
                  />
                </Col>
              </Row>
              <Row
                className="py-3"
              >
                <Col
                  xs={12}
                  className="text-center"
                >
                  {
                    submitting ? (
                      <Spinner
                        variant="primary"
                        animation="border"
                      />
                    ) : (
                      <Button
                        variant="primary"
                        className="px-6 py-2"
                        disabled={
                      !heading
                      || !subHeading
                      || !deeplink
                      || !description
                      || !url
                      || !deeplink
                    }
                        onClick={this.handleSubmitDaily}
                      >
                        Submit
                      </Button>
                    )
                  }
                  {
                    submitError && (
                      <div
                        className="text-danger py-3"
                      >
                        Something Went Wrong, Please Try Again !!
                      </div>
                    )
                  }
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              {loading ? (
                <Col
                  xs={24}
                  className="text-center py-3"
                >
                  <Spinner
                    variant="primary"
                    animation="border"
                  />
                </Col>
              ) : error ? (
                <Col
                  xs={24}
                  className="text-center py-3"
                >
                  <span
                    className="text-danger"
                  >
                    Oops Something Went Wrong!!
                  </span>
                </Col>
              ) : (
                <Col
                  xs={24}
                  className="p-0 m-0"
                >
                  <Row
                    className="mx-0"
                  >
                    <Col
                      xs="auto"
                      className="p-2"
                    >
                      <CustomDropdown
                        item={{
                          key: 'feedType',
                          displayText: 'Feed Type',
                          options: [
                            { value: 'HOW_TO_PROMOTE_YOUR_SHOP', label: 'How to Promote your Shop' },
                            { value: 'HOW_TO_MANAGE_ORDERS', label: 'How to Manage Orders' },
                            { value: 'HOW_TO_MANAGE_CATALOGUE', label: 'How to Manage Catalogue' },
                            { value: 'HOW_SETTLEMENTS_WORK_ON_LOVELOCAL', label: 'How Settlements Work on LoveLocal' },
                            { value: 'TOP_VIDEOS', label: 'Top Videos' },
                            { value: 'NEWS_ON_KIRANA', label: 'News on Kirana' },
                            { value: 'NEW_FEATURES', label: 'New Features' },
                            { value: 'WHAT_OTHER_RETAILERS_SAY', label: 'What Other Retailers Say' },
                          ],
                        }}
                        selectedVal={selectedFeedType}
                        onChange={(item) => {
                          this.setState({
                            selectedFeedType: item.feedType,
                          }, () => {
                            this.loadDaily();
                          });
                        }}
                        closeButton
                      />
                    </Col>
                    <Col
                      xs="auto"
                      className="p-2"
                    >
                      <CustomDropdown
                        item={{
                          key: 'language',
                          displayText: 'Language',
                          options: [
                            {
                              label: 'English',
                              value: 'en',
                            },
                            {
                              label: 'Gujarati',
                              value: 'gu',
                            },
                            {
                              label: 'Hindi',
                              value: 'hi',
                            },
                            {
                              label: 'Kanada',
                              value: 'kn',
                            },
                            {
                              label: 'Marathi',
                              value: 'mr',
                            },
                            {
                              label: 'Telugu',
                              value: 'te',
                            },
                          ],
                        }}
                        selectedVal={selectedLanguage}
                        onChange={(item) => {
                          this.setState({
                            selectedLanguage: item.language,
                          }, () => {
                            this.loadDaily();
                          });
                        }}
                        closeButton
                      />
                    </Col>
                  </Row>
                  <Row
                    xs="auto"
                    className="mx-0"
                  >
                    <CustomTable
                      headers={config}
                      content={feeds.results}
                      keyField="id"
                      variant="white"
                      rowsPerPage={rowsPerPage}
                      page={page}
                      totalItems={feeds.count}
                      hasPrev={feeds.hasPrevious}
                      hasNext={feeds.hasNext}
                      onNext={this.onNext}
                      onPrev={this.onPrev}
                      onSubmitPage={this.onSubmitPage}
                      onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                      updateRowsPageInput={this.handleRowsPageInput}
                    />
                  </Row>
                </Col>
              )}
            </Row>
          )}
        </>
      </Container>
    );
  }
}

LLDaily.propTypes = {
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default LLDaily;
