import React, { Component } from 'react';
import {
  Container, Row, Col, Form, Button, Spinner,
} from 'react-bootstrap';
import { exclusiveOffer } from '../assets/api/axios';

class CreateOffer extends Component {
  constructor() {
    super();
    const params = new URL(window.location).searchParams;
    const productId = params.get('productId');
    const displayName = params.get('displayName');
    const mrp = params.get('mrp');
    const sellingPrice = params.get('sellingPrice');
    this.state = {
      productId,
      displayName,
      mrp,
      sellingPrice,
      discount: 0,
      finalPrice: sellingPrice,
      fundedBy: 'MPAANI',
      maxQuantity: 1,
      requestStatus: null,
      expiryDate: '',
      applicableOn: 'CUSTOMER',
    };
    this.handleInputOnChange = this.handleInputOnChange.bind(this);
    this.handleRadioField = this.handleRadioField.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onSave() {
    const {
      productId,
      discount,
      fundedBy,
      maxQuantity,
      expiryDate,
      applicableOn,
    } = this.state;
    const date = new Date(expiryDate);
    date.setHours(23, 59, 59);
    const data = {
      retailerProductId: parseInt(productId, 10),
      discount: parseFloat(discount),
      maxQuantity: parseFloat(maxQuantity),
      expiryDate: date.getTime(),
      fundedBy,
      applicableOn,
    };
    this.setState({ requestStatus: 'pending' }, () => {
      exclusiveOffer('POST', data)
        .then(() => {
          this.setState({ requestStatus: 'success' });
        })
        .catch(() => {
          this.setState({ requestStatus: 'failed' }, () => {
            setTimeout(() => {
              this.setState({ requestStatus: null });
            }, 3000);
          });
        });
    });
  }

  handleInputOnChange(event) {
    const additionalField = {};
    const { sellingPrice } = this.state;
    if (event.target.id === 'discount') {
      additionalField.finalPrice = sellingPrice - event.target.value;
      additionalField.finalPrice = Math.round(additionalField.finalPrice * 10) / 10;
    }
    this.setState({
      [event.target.id]: event.target.value,
      ...additionalField,
    });
  }

  handleRadioField(identifier, value, checked) {
    if (checked) {
      this.setState({ [identifier]: value });
    }
  }

  render() {
    const {
      displayName,
      mrp,
      sellingPrice,
      discount,
      fundedBy,
      expiryDate,
      finalPrice,
      maxQuantity,
      applicableOn,
      requestStatus,
    } = this.state;

    let showcase = (
      <Button
        variant="primary"
        onClick={this.onSave}
        disabled={!(discount && maxQuantity && expiryDate)}
      >
           save
      </Button>
    );
    switch (requestStatus) {
      case 'pending':
        showcase = <Spinner animation="border" variant="primary" />;
        break;
      case 'failed':
        showcase = <b className="fs-5 text-danger">Failed</b>;
        break;
      case 'success':
        showcase = (
          <Button variant="success" disabled>
            saved
          </Button>
        );
        break;
      default:
        break;
    }

    return (
      <Container fluid>
        <Row className="my-2">
          <Col xs={24} className="fs-2 my-2">
             Create App Exclusive Offer
          </Col>
          <Col xs={24} className="my-3">
            <Form>
              <Form.Group as={Row} className="align-items-center">
                <Form.Label column md={4}>
                       Product
                </Form.Label>
                <Col md={10}>
                  <Form.Control
                    type="text"
                    id="displayName"
                    disabled
                    defaultValue={displayName}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="align-items-center">
                <Form.Label column md={4}>
                       Mrp
                </Form.Label>
                <Col md={10}>
                  <Form.Control
                    type="number"
                    id="mrp"
                    disabled
                    defaultValue={mrp}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="align-items-center">
                <Form.Label column md={4}>
                       Selling Price
                </Form.Label>
                <Col md={10}>
                  <Form.Control
                    type="number"
                    id="sellingPrice"
                    disabled
                    defaultValue={sellingPrice}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column md={4}>
                       Discount
                </Form.Label>
                <Col md={10}>
                  <Form.Control
                    type="number"
                    id="discount"
                    value={discount}
                    onChange={this.handleInputOnChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column md={4}>
                       Final Price
                </Form.Label>
                <Col md={10}>
                  <Form.Control
                    type="number"
                    disabled
                    value={finalPrice}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md={4}>
                      Max Quantity
                </Col>
                <Col md={10}>
                  <Form.Control
                    type="number"
                    id="maxQuantity"
                    value={maxQuantity}
                    onChange={this.handleInputOnChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="align-items-center">
                <Col md={4}>
                    Restriction
                </Col>
                <Col md={10}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    label="Customer Level"
                    id="custom-inline-radio-customer"
                    checked={applicableOn === 'CUSTOMER'}
                    onClick={(e) => this.handleRadioField('applicableOn', 'CUSTOMER', e.target.checked)}
                  />
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    label="Order Level"
                    id="custom-inline-radio-order"
                    checked={applicableOn === 'ORDER'}
                    onClick={(e) => this.handleRadioField('applicableOn', 'ORDER', e.target.checked)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="align-items-center">
                <Col md={4}>
                    Funded By
                </Col>
                <Col md={10}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    label="mpaani"
                    id="custom-inline-radio-mpaani"
                    checked={fundedBy === 'MPAANI'}
                    onClick={(e) => this.handleRadioField('fundedBy', 'MPAANI', e.target.checked)}
                  />
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    label="Retailer"
                    id="custom-inline-radio-retailer"
                    checked={fundedBy === 'RETAILER'}
                    onClick={(e) => this.handleRadioField('fundedBy', 'RETAILER', e.target.checked)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="align-items-center">
                <Col md={4}>
                    Valid Till
                </Col>
                <Col md={10}>
                  <Form.Control
                    type="date"
                    id="expiryDate"
                    value={expiryDate}
                    onChange={this.handleInputOnChange}
                  />
                </Col>
              </Form.Group>
            </Form>
          </Col>
          <Col md={15} className="text-center">
            {showcase}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CreateOffer;
