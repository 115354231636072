import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Form, Button,
} from 'react-bootstrap';

const paymentModeCheck = (paymentModes) => {
  const filteredPaymentModes = paymentModes.filter((mode) => mode.isEnabled);
  if (filteredPaymentModes.length === 1) {
    return filteredPaymentModes[0];
  }
  return 'Both';
};

const CheckoutStore = ({
  storeCart, checkoutStore, updateCheckoutStore, createOrders,
  orderRequest, deliveryOption, cartDetails,
}) => {
  const storePaymentMode = paymentModeCheck(storeCart.store.paymentMode);
  const couponPaymentMode = storeCart.coupon.couponApplied ? paymentModeCheck(storeCart.coupon.couponApplied.paymentMode) : 'Both';
  if (storePaymentMode !== 'Both' && storePaymentMode.code !== checkoutStore.paymentPreference) {
    updateCheckoutStore({
      ...checkoutStore,
      paymentPreference: storePaymentMode.code,
    });
  } else if (couponPaymentMode !== 'Both' && couponPaymentMode.code !== checkoutStore.paymentPreference) {
    updateCheckoutStore({
      ...checkoutStore,
      paymentPreference: couponPaymentMode.code,
    });
  }

  return (
    <Col xs={24} className="shadow bg-white mt-3">
      <Row className="py-3">
        <Col>
          <Row>
            <Col xs={16}><b>{storeCart.store.displayName}</b></Col>
          </Row>
          <Row className="">
            <Col xs={16}>Item Total</Col>
            <Col xs={8} className="text-right"><b>{`- \u20B9 ${storeCart.bill.totalProductPrice}`}</b></Col>
          </Row>
          <Row className="text-success">
            <Col xs={16}>Platform Fee</Col>
            <Col xs={8} className="text-right"><b>{`+ \u20B9 ${cartDetails?.cart?.totalPlatformFee}`}</b></Col>
          </Row>
          <Row className="text-success">
            <Col xs={16}>Discount</Col>
            <Col xs={8} className="text-right"><b>{`- \u20B9 ${storeCart.coupon.discountAmount}`}</b></Col>
          </Row>
          <Row className="">
            <Col xs={16}>Delivery Fee</Col>
            <Col xs={8} className="text-right">
              {`+ \u20B9 ${storeCart.bill.distanceCharge}`}
            </Col>
          </Row>
          <Row className={`${storeCart.bill.deliveryPenalty > 0 ? '' : 'd-none'}`}>
            <Col xs={16}>
              Delivery Penalty
              <div className="text-danger fs-0">
                <span>
                  *
                </span>
                {`To get rid from delivery penalty shop for more \u20B9 ${
                  storeCart.store.homeDeliveryMinAmount
                  - storeCart.bill.amount + storeCart.bill.deliveryAmount
                }`}
              </div>
            </Col>
            <Col xs={8} className="text-right">
              {`+ \u20B9 ${storeCart.bill.deliveryPenalty}`}
            </Col>
          </Row>
          <Row className="font-weight-bold">
            <Col xs={16}>Total Amount</Col>
            <Col xs={8} className="text-right">
              {`\u20B9 ${deliveryOption === 'PFS'
                ? storeCart.bill.amount - storeCart.coupon.discountAmount - storeCart.bill.deliveryAmount : storeCart.bill.amount - storeCart.coupon.discountAmount}`}
            </Col>
          </Row>
        </Col>
        <Col xs={10} className="border-left">
          <div><b>Select Payment Mode</b></div>
          <div>
            {
              // eslint-disable-next-line no-nested-ternary
              storePaymentMode === 'Both'
                ? couponPaymentMode === 'Both' ? (
                  <Form.Group controlId={`${storeCart.store.code}-payment-mode`} className="mb-0">
                    <Form.Check
                      custom
                      label="Online Payment"
                      type="radio"
                      id={`${storeCart.store.code}-DIGITAL`}
                      checked={checkoutStore && checkoutStore.paymentPreference === 'DIGITAL'}
                      onChange={checkoutStore ? () => {
                        updateCheckoutStore({
                          ...checkoutStore,
                          paymentPreference: 'DIGITAL',
                        });
                      } : null}
                      className={checkoutStore && checkoutStore.paymentPreference === 'DIGITAL' ? 'text-primary font-weight-bold' : ''}
                    />
                    <Form.Check
                      custom
                      label={deliveryOption === 'PFS' ? 'Pay at Store' : 'Cash On Delivery'}
                      type="radio"
                      id={`${storeCart.store.code}-CASH`}
                      checked={checkoutStore && checkoutStore.paymentPreference === 'CASH'}
                      onChange={checkoutStore ? () => {
                        updateCheckoutStore({
                          ...checkoutStore,
                          paymentPreference: 'CASH',
                        });
                      } : null}
                      className={checkoutStore && checkoutStore.paymentPreference === 'CASH' ? 'text-primary font-weight-bold' : ''}
                    />
                  </Form.Group>
                ) : (
                  <div className="text-muted">
                    <i>{`*Payment Mode is locked to ${couponPaymentMode.name} because of Applied Coupon*`}</i>
                  </div>
                )
                : (
                  <div className="text-muted">
                    <i>{`*This Store accepts only ${storePaymentMode.name}*`}</i>
                  </div>
                )
            }
          </div>
        </Col>
      </Row>
      {deliveryOption === 'ADDRESS'
      && (
      <Row className={`mb-3 ${
        storeCart.store.homeDeliveryMinAmount - storeCart.bill.amount <= 0
        || storeCart.bill.deliveryAmount <= 0
          ? 'd-none' : ''}`}
      >
        <Col>
          <div className="bg-danger-50 text-center p-1">
            <b>
              <div>
                {`Alert! Minimum home delivery order amount is \u20B9 ${storeCart.store.homeDeliveryMinAmount}. Shop for more \u20B9 ${storeCart.store.homeDeliveryMinAmount - storeCart.bill.amount + storeCart.bill.deliveryAmount} to get free delivery`}
              </div>
              <div>
                {`You are being charged with \u20B9 ${storeCart.bill.deliveryAmount} delivery charge`}
              </div>
            </b>
          </div>
        </Col>
      </Row>
      )}
      <Row className="mb-3 align-items-center">
        <Col>
          <Form.Control
            type="text"
            placeholder="Add any special note or your preferred delivery timing"
            value={checkoutStore ? checkoutStore.description : ''}
            onChange={checkoutStore ? (event) => {
              updateCheckoutStore({
                ...checkoutStore,
                description: event.target.value,
              });
            } : null}
          />
        </Col>
        <Col xs="auto" md={10} className="text-right">
          <Button
            onClick={() => createOrders(checkoutStore)}
            disabled={orderRequest || checkoutStore.paymentPreference === ''}
            className="px-4 px-md-5"
          >
            <b>Checkout</b>
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

CheckoutStore.defaultProps = {
  checkoutStore: null,
};

CheckoutStore.propTypes = {
  cartDetails: PropTypes.shape({
    cart: {
      totalPlatformFee: PropTypes.number,
    },
  }).isRequired,
  storeCart: PropTypes.shape({
    coupon: PropTypes.shape({
      couponApplied: PropTypes.shape({
        paymentMode: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      discountAmount: PropTypes.number,
    }),
    store: PropTypes.shape({
      paymentMode: PropTypes.arrayOf(PropTypes.shape({})),
      code: PropTypes.string,
      displayName: PropTypes.string,
      homeDeliveryMinAmount: PropTypes.number,
    }),
    bill: PropTypes.shape({
      amount: PropTypes.number,
      deliveryAmount: PropTypes.number,
      totalProductPrice: PropTypes.number,
      deliveryPenalty: PropTypes.number,
      distanceCharge: PropTypes.number,
    }),
  }).isRequired,
  checkoutStore: PropTypes.shape({
    storeId: PropTypes.string,
    paymentPreference: PropTypes.string,
    description: PropTypes.string,
  }),
  updateCheckoutStore: PropTypes.func.isRequired,
  createOrders: PropTypes.func.isRequired,
  orderRequest: PropTypes.bool.isRequired,
  deliveryOption: PropTypes.string.isRequired,
};

export default CheckoutStore;
