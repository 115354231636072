import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Button, Container,
} from 'react-bootstrap';

function ErrorHandler(props) {
  const { retryLogic, onCancel } = props;
  return (
    <Container
      className="my-2"
    >
      <Row>
        <Col
          xs={24}
          className="fs-1 text-danger mb-4 text-center"
        >
          Something went wrong. Please try again.
        </Col>
        <Col
          xs={24}
          className="d-flex align-items-center justify-content-center"
        >
          <Button
            onClick={retryLogic}
            className="mx-2 fs-1 rounded-0"
          >
            Retry
          </Button>
          {onCancel && (
          <Button
            onClick={onCancel}
            className="mx-2 fs-1 rounded-0"
            variant="outline-primary"
          >
            Cancel
          </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
}

ErrorHandler.propTypes = {
  retryLogic: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

ErrorHandler.defaultProps = {
  onCancel: null,
};

export default ErrorHandler;
