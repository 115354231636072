import React from 'react';
import { PropTypes } from 'prop-types';
import { Spinner, Button } from 'react-bootstrap';
import { CustomModal } from '../../common';

function ProcessingStatus(props) {
  const {
    loading, show, error, onRetry, onCancel,
    border,
  } = props;
  const body = (
    <div
      id="processing-status"
      className="p-4"
    >
      {loading && (
      <div
        className="text-center"
      >
        <Spinner
          animation="border"
          variant="primary"
        />
      </div>
      )}
      {error && (
      <div
        className="d-flex align-items-center justify-content-between"
      >
        <div
          className="fs-1 text-danger"
        >
          Something went wrong
        </div>
        <div
          className="d-flex"
        >
          <Button
            variant="primary-dark"
            onClick={onRetry}
            className="fs-01 mx-2"
          >
            RETRY
          </Button>
          <Button
            variant="light"
            onClick={onCancel}
            className="fs-01 mx-2"
          >
            CANCEL
          </Button>
        </div>
      </div>
      )}
    </div>
  );
  return (
    <CustomModal
      show={show}
      body={body}
      autoSize
      onHide={loading ? () => {} : onCancel}
      border={border}
    />
  );
}

ProcessingStatus.propTypes = {
  show: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  onRetry: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  border: PropTypes.bool,
};

ProcessingStatus.defaultProps = {
  border: false,
};

export default ProcessingStatus;
