import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import {
  Button,
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import '../../assets/scss/om-status-tracker/OMStatusTracker.scss';
import { CustomTable, ErrorHandler } from '../../component/common';
import { OMLogs, orderManagerList } from '../../assets/api/axios';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 50;
  p = (p && p > 0) ? p : 1;
  return ({
    ...param, l, p,
  });
};

class OMStatusTracker extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    this.state = {
      rowsPerPage: param.l,
      page: param.p,
      param,
      orderManagers: null,
      orderManagerLog: null,
      tag: '',
      loading: true,
      error: false,
      view: 'orderManager',
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleLoad = (data = {}) => {
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p,
    } = newParam;
    this.setState({ loading: true });

    orderManagerList(
      'GET',
      '',
      null,
      {
        page: p,
        limit: l,
      },
      this.source.token,
    ).then((res) => {
      this.setState({
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
        loading: false,
        error: false,
        orderManagers: res.data,
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  patchOrderManagerStatus = async (tag, status) => {
    orderManagerList(
      'PATCH',
      tag,
      {
        status,
      },
      {},
      this.source.token,
    ).then(() => {
      this.handleLoad();
    }).catch(() => {
      this.setState({
        error: true,
      });
    });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleLoad({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleLoad({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleLoad({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleLoad({ p: page });
    }
  }

  handleLoadLogs = (data = {}) => {
    const { param, tag } = this.state;
    const newParam = { ...param, ...data };
    const {
      l,
      p,
    } = newParam;
    this.setState({ loading: true });
    OMLogs(
      'GET',
      tag || data.tag,
      {
        page: p,
        limit: l,
      },
      this.source.token,
    ).then((res) => {
      this.setState({
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
        loading: false,
        error: false,
        orderManagerLog: res.data,
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });
  }

  handleRowsPageInputModal = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNextModal = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleLoadLogs({ p: param.p + 1 });
    }
  }

  onPrevModal = () => {
    const { param } = this.state;
    this.handleLoadLogs({ p: param.p - 1 });
  }

  onSubmitRowsPerPageModal = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleLoadLogs({ l: rowsPerPage });
    }
  }

  onSubmitPageModal = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleLoadLogs({ p: page });
    }
  }

  setLogsData = (data = {}) => {
    this.setState({
      tag: data.tag,
      view: 'logs',
      page: 1,
      param: {
        l: 10,
        p: 1,
      },
    });
    this.handleLoadLogs({
      param: {
        l: 10,
        p: 1,
      },
      l: 10,
      p: 1,
      tag: data.tag,
      name: data.name,
    });
  }

  closeLogsModal = () => {
    const { param } = this.state;
    Object.keys(param).forEach((item) => {
      if (item !== 'l' && item !== 'p') delete param[item];
    });
    this.setState({
      view: 'orderManager',
      page: 1,
      tag: '',
      orderManagerLog: '',
      rowsPerPage: 50,
      param: {
        l: 50,
        p: 1,
      },
    });
    this.handleLoad({
      param: {
        l: 50,
        p: 1,
      },
      l: 50,
      p: 1,
    });
  }

  render() {
    const {
      loading, error, orderManagers, orderManagerLog, param, rowsPerPage, page, view,
    } = this.state;
    const modalHeaders = [
      {
        key: 'agentName',
        displayText: 'Name',
      },
      {
        key: 'status',
        displayText: 'Status',
      },
      {
        key: 'changedBy',
        displayText: 'Changed By',
      },
      {
        key: 'changedOn',
        displayText: 'changed On',
      },
    ];
    const headers = [
      {
        key: 'designation',
        displayText: 'Order Manager Tag',
      },
      {
        key: 'agentName',
        displayText: 'Order Manager Name',
      },
      {
        key: 'status',
        displayText: 'Status',
        renderer: (data) => (
          <Row className="mx-0 align-items-center min-w100">
            <Button
              variant="primary"
              type="button"
              className="btn btn-sm btn-success mr-2 status-style"
              disabled={data.status === 'ACTIVE'}
              onClick={() => this.patchOrderManagerStatus(data.designation, 'ACTIVE')}
            >
              Active
            </Button>
            <Button
              type="button"
              className="btn btn-sm btn-danger mr-2"
              disabled={data.status === 'INACTIVE'}
              onClick={() => this.patchOrderManagerStatus(data.designation, 'INACTIVE')}
            >
              InActive
            </Button>
          </Row>
        ),
      },
      {
        key: 'viewLogs',
        displayText: 'View Logs',
        renderer: (data) => (
          <Row className="mx-0 align-items-center min-w100">
            <Button
              type="button"
              className="btn-primary btn-sm"
              onClick={() => this.setLogsData({ tag: data.designation, name: data.agentName })}
            >
              View Logs
            </Button>
          </Row>

        ),
      },
    ];
    if ((!orderManagers || !orderManagerLog) && loading) {
      return (
        <div
          className="d-flex align-items-center
             justify-content-center h-100"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!orderManagers && !loading && error) {
      return (
        <div
          className="d-flex align-items-center
             justify-content-center h-100"
        >
          <ErrorHandler
            retryLogic={() => {
              this.setState({
                loading: true,
                error: false,
              }, () => {
                this.handleLoad();
              });
            }}
          />
        </div>
      );
    }
    return (
      <Container>
        {view === 'orderManager'
          ? (
            <Row className="bg-white">
              <Col
                xs={24}
                className="px-0"
              >
                <CustomTable
                  isPaginated
                  headers={headers}
                  content={orderManagers.data}
                  keyField="orderManagerId"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={orderManagers.count}
                  hasPrev={orderManagers.hasPrev}
                  hasNext={orderManagers.hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                  pagiationPostion="position-sticky"
                />
              </Col>
            </Row>
          )
          : (
            <Row className="bg-white">
              <Col
                xs={24}
                className="px-0"
              >
                <Button
                  variant="outline-primary"
                  onClick={() => this.closeLogsModal()}
                >
                  Back
                </Button>
                <CustomTable
                  isPaginated
                  headers={modalHeaders}
                  content={orderManagerLog.data}
                  keyField="orderManagerLog"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={orderManagerLog.count}
                  hasPrev={!!orderManagerLog.prev}
                  hasNext={!!orderManagerLog.next}
                  onNext={this.onNextModal}
                  onPrev={this.onPrevModal}
                  onSubmitPage={this.onSubmitPageModal}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPageModal}
                  updateRowsPageInput={this.handleRowsPageInputModal}
                  pagiationPostion="position-sticky"
                />
              </Col>
            </Row>
          )}
      </Container>
    );
  }
}
OMStatusTracker.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};
export default OMStatusTracker;
