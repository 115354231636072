import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import {
  Button, Container, Spinner, Row, Col,
  InputGroup, Form,
} from 'react-bootstrap';
import { refundDetail } from '../../assets/api/axios';
import {
  CustomDropdown, CustomTable, CustomModal, DatePicker,
} from '../../component/common';
import Svg from '../../component/common/Svg';
import Constant from '../../utilities/Constant';
import { Utils } from '../../utilities';
import '../../assets/scss/Refunds/RefundDashboard.scss';
import Permission from '../../access&permissions/permission';

const isProdEnv = process.env.REACT_APP_ENV === 'production';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, startDate, endDate,
  } = param;
  const {
    sid = '', oid = '', status = '', requestType = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  startDate = Number(startDate) || '';
  endDate = Number(endDate) || '';
  return ({
    l,
    p,
    sid,
    oid,
    status,
    requestType,
    startDate,
    endDate,
  });
};

const getRequestStatusColor = (status) => {
  if (status === 'processing') {
    return 'text-warning';
  } if (status === 'processed') {
    return 'text-green';
  } if (status === 'failed') {
    return 'text-danger';
  } if (status === 'write_off') {
    return 'text-green';
  }
  return 'text-medium';
};

class RefundDashboard extends Component {
  constructor(props) {
    super(props);
    const { history } = this.props;
    const params = getQueryParams(history.location.search);
    const { userPermission } = props;
    this.canIEdit = userPermission
      && userPermission.includes(Permission.REFUND_DASHBOARD_WRITE);
    this.state = {
      refunds: null,
      storeIds: params.sid,
      orderIds: params.oid,
      rowsPerPage: params.l,
      page: params.p,
      params,
      loading: true,
      error: false,
      loadingMetaData: false,
      loadingMetaDataError: false,
      selectedRequest: null,
      showTransactionMeta: false,
      orderTransactionMeta: null,
      showStatusDetail: false,
      requestStatusDetail: null,
      selectRefundRequestType: '',
      refundRequestIds: {},
      submitSingleAction: false,
      performingAction: '',
      amountToRefund: '',
      remark: '',
      remarkForCustomer: '',
      isInstantPayment: true,
      processingRefundRequest: false,
      processingRefundRequestErrMsg: '',
      processingRefundRequestModal: false,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount() {
    this.handleLoad();
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { params } = this.state;
    const newParams = { ...params, ...data };
    const offset = (newParams.p - 1) * newParams.l;
    const {
      l, sid, oid, status, requestType, startDate, endDate,
    } = newParams;
    refundDetail(
      'GET',
      this.source.token,
      {
        viewType: 'DEFAULT',
        page: `${offset}`,
        limit: l,
        status,
        requestType,
        storeIds: sid,
        orderIds: oid,
        startDate,
        endDate,
      },
      null,
    ).then((res) => {
      this.setState({
        refunds: res.data,
        rowsPerPage: newParams.l,
        page: newParams.p,
        params: newParams,
        loading: false,
      }, () => {
        if (Object.keys(newParams).find((key) => newParams[key] !== params[key])) {
          Object.keys(newParams).forEach((key) => {
            if (!newParams[key]) {
              delete newParams[key];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParams),
          });
        }
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad(newParams);
      });
    };
  }

  handleRequestProcessing = (data) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      loading: true,
      error: false,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleSubmitAction = () => {
    const {
      remark, remarkForCustomer, isInstantPayment, performingAction,
      selectedRequest, amountToRefund,
    } = this.state;
    this.setState({
      processingRefundRequestErrMsg: '',
      processingRefundRequest: true,
    });
    refundDetail(
      'POST',
      this.source.token,
      {},
      {
        action: performingAction,
        body: {
          requestId: selectedRequest.requestId,
          amountToRefund,
          remark,
          remarkForCustomer,
          ...performingAction === 'PARTIAL_REFUND'
            || performingAction === 'PROCESS_REFUND'
            ? {
              isInstantPayment,
            } : {},
        },
      },
    ).then((res) => {
      if (res.status === 200) {
        const { refunds: newRefunds } = this.state;
        const index = (newRefunds.data).findIndex((refund) => (
          refund.requestId === selectedRequest.requestId
        ));
        if (index !== -1) {
          newRefunds.data[index] = { ...newRefunds.data[index], ...res.data };
        }
        this.setState({
          refunds: newRefunds,
          processingRefundRequest: false,
          processingRefundRequestModal: false,
          submitSingleAction: false,
          performingAction: '',
        });
      } else {
        throw new Error();
      }
    }).catch((error) => {
      this.setState({
        processingRefundRequestErrMsg: error.response
          ? `Error: ${JSON.stringify(error.response.data)}`
          : 'Error',
        processingRefundRequest: false,
      });
    });
    this.retry = () => {
      this.handleSubmitAction();
    };
  }

  async handleBulkSubmitAction() {
    const {
      refundRequestIds: ids, remark, remarkForCustomer, isInstantPayment, performingAction,
    } = this.state;
    Object.keys(ids).forEach(async (item) => {
      ids[item].statusMessage = 'PROCESSING';
      ids[item].statusTextColor = 'text-warning';
      this.setState({
        refundRequestIds: ids,
      });
      // eslint-disable-next-line no-await-in-loop
      await refundDetail(
        'POST',
        this.source.token,
        {},
        {
          action: performingAction,
          body: {
            requestId: ids[item].requestId,
            amountToRefund: ids[item].requestedRefundAmount,
            remark,
            remarkForCustomer,
            ...performingAction === 'PARTIAL_REFUND' ? {
              isInstantPayment,
            } : {},
          },
        },
      ).then((res) => {
        if (res.status === 200) {
          const { refunds: newRefunds } = this.state;
          const index = (newRefunds.data).findIndex((refund) => (
            refund.requestId === Number(item)
          ));
          if (index !== -1) {
            newRefunds.data[index] = { ...newRefunds.data[index], ...res.data };
          }
          ids[item].statusMessage = 'SUCCESS';
          ids[item].statusTextColor = 'text-green';
          this.setState({
            refundRequestIds: ids,
            refunds: newRefunds,
            processingRefundRequest: false,
            performingAction: '',
          });
        } else {
          throw new Error();
        }
      }).catch((error) => {
        ids[item].statusMessage = error.response
          ? `Error: ${JSON.stringify(error.response.data)}`
          : 'Error';
        ids[item].statusTextColor = 'text-danger';
        this.setState({
          refundRequestIds: ids,
          processingRefundRequest: false,
          performingAction: '',
        });
      });
    });
  }

  onSubmitOrderIds = () => {
    const { orderIds, params } = this.state;
    if (orderIds !== params.oid) {
      this.handleRequestProcessing({ oid: orderIds });
    }
  }

  onSubmitStoreIds = () => {
    const { storeIds, params } = this.state;
    if (storeIds !== params.sid) {
      this.handleRequestProcessing({ sid: storeIds });
    }
  }

  handleDateChange = (startDate, endDate) => {
    const { params } = this.state;
    if (startDate !== params.startDate
      || endDate !== params.endDate) {
      this.handleRequestProcessing({
        startDate,
        endDate,
        p: 1,
      });
    }
  }

  loadTransactionMeta = () => {
    const { selectedRequest } = this.state;
    refundDetail(
      'GET',
      this.source.token,
      {
        viewType: 'TRANSACTIONS_META',
        orderId: selectedRequest.orderId,
      },
    ).then((res) => {
      this.setState({
        loadingMetaData: false,
        orderTransactionMeta: res.data,
      });
    }).catch(() => {
      this.setState({
        loadingMetaData: false,
        loadingMetaDataError: true,
      });
    });
    this.retry = () => {
      this.setState({
        loadingMetaData: true,
        loadingMetaDataError: false,
      }, () => {
        this.loadTransactionMeta();
      });
    };
  }

  loadRequestStatusDetail = () => {
    const { selectedRequest } = this.state;
    refundDetail(
      'GET',
      this.source.token,
      {
        viewType: 'STATUS_DETAIL',
        requestId: selectedRequest.requestId,
      },
    ).then((res) => {
      this.setState({
        loadingMetaData: false,
        requestStatusDetail: res.data,
      });
    }).catch(() => {
      this.setState({
        loadingMetaData: false,
        loadingMetaDataError: true,
      });
    });
    this.retry = () => {
      this.setState({
        loadingMetaData: true,
        loadingMetaDataError: false,
      }, () => {
        this.loadRequestStatusDetail();
      });
    };
  }

  handleSelectRefundRequest = (data) => {
    const { selectRefundRequestType } = data;
    const { refunds } = this.state;
    const newRefundRequestIds = {};
    if (selectRefundRequestType === 'all') {
      (refunds.data).filter((item) => item.status === 'pending' || item.status === 'on_hold').map(
        (r) => {
          newRefundRequestIds[r.requestId] = {
            ...r,
            statusMessage: 'NOT INITIATED',
            statusTextColor: '',
          };
          return 0;
        },
      );
    }
    this.setState({
      refundRequestIds: newRefundRequestIds,
      selectRefundRequestType,
    });
  }

  handleRequestIdSelection = (e, s) => {
    const { checked } = e.target;
    const { refundRequestIds: newRefundRequestIds } = this.state;

    if (checked) {
      newRefundRequestIds[s.requestId] = {
        ...s,
        statusMessage: 'NOT INITIATED',
        statusTextColor: '',
      };
      this.setState({
        refundRequestIds: newRefundRequestIds,
      });
    } else {
      delete newRefundRequestIds[s.requestId];
      this.setState({
        refundRequestIds: newRefundRequestIds,
      });
    }
  }

  onNext = () => {
    const { params } = this.state;
    this.handleRequestProcessing({ p: params.p + 1 });
  }

  onPrev = () => {
    const { params } = this.state;
    this.handleRequestProcessing({ p: params.p - 1 });
  }

  onSubmitPage = () => {
    const { params, page } = this.state;
    if (params.p !== page) {
      this.handleRequestProcessing({ p: page });
    }
  }

  onSubmitRowsPerPage = () => {
    const { params, rowsPerPage } = this.state;
    if (params.l !== rowsPerPage) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  render() {
    const {
      refunds, rowsPerPage, page, params, loading, error, storeIds,
      orderIds,
      loadingMetaDataError, orderTransactionMeta, showTransactionMeta, showStatusDetail,
      requestStatusDetail, loadingMetaData, selectRefundRequestType, refundRequestIds,
      processingRefundRequest, processingRefundRequestModal, remark, remarkForCustomer,
      isInstantPayment, submitSingleAction, performingAction, amountToRefund,
      processingRefundRequestErrMsg, selectedRequest,
    } = this.state;

    const filtersConfig = [
      {
        key: 'status',
        displayText: 'Status',
        options: [
          {
            label: 'PENDING',
            value: 'pending',
          },
          {
            label: 'ON_HOLD',
            value: 'on_hold',
          },
          {
            label: 'PROCESSING',
            value: 'processing',
          },
          {
            label: 'WRITE_OFF',
            value: 'write_off',
          },
          {
            label: 'PROCESSED',
            value: 'processed',
          },
          {
            label: 'FAILED',
            value: 'failed',
          },
        ],
      },
      {
        key: 'requestType',
        displayText: 'Request Type',
        options: [
          {
            label: 'PRODUCT_ALTERED',
            value: 'product_altered',
          },
          {
            label: 'ORDER_CANCELLED',
            value: 'order_cancelled',
          },
        ],
      },
    ];

    let headers = [
      {
        key: 'requestId',
        displayText: 'Request Id',
      },
      {
        key: 'orderId',
        displayText: 'Order Id',
        renderer: (data) => (
          <Button
            variant="link"
            onClick={() => {
              window.open(`/order-details/${data.orderId}`, '_blank');
            }}
            className="fs-01 p-0"
          >
            {data.orderId}
          </Button>
        ),
      },
      {
        key: 'requestType',
        displayText: 'Request Type',
      },
      {
        key: 'requestByUser',
        displayText: 'Request By',
      },
      {
        key: 'requestProcessedByUser',
        displayText: 'Processed By',
      },
      {
        key: 'remark',
        displayText: 'Remark',
      },
      {
        key: 'requestDatetime',
        displayText: 'Requested On',
        renderer: (data) => Utils.dateString(data.requestDatetime),
      },
      {
        key: 'requestAcceptedDatetime',
        displayText: 'Accepted On',
        renderer: (data) => Utils.dateString(data.requestAcceptedDatetime),
      },
      {
        key: 'requestProcessedDatetime',
        displayText: 'Processed On',
        renderer: (data) => Utils.dateString(data.requestProcessedDatetime),
      },
      {
        key: 'requestedRefundAmount',
        displayText: 'Refund Amount',
      },
      {
        key: 'amountInitiatedForRefund',
        displayText: 'Amt. Initiated For Refund',
      },
      {
        key: 'totalProcessedRefundAmount',
        displayText: 'Total Processed Refund Amt.',
      },
      {
        key: 'storeId',
        displayText: ' Store Id',
      },
      {
        key: 'storeName',
        displayText: 'Store Name',
      },
      {
        key: 'status',
        displayText: 'Status',
        renderer: (data) => (
          <Button
            variant="link"
            onClick={() => {
              if (data.status === 'pending' || data.status === 'on_hold') {
                return;
              }
              this.setState({
                selectedRequest: data,
                showStatusDetail: true,
                loadingMetaData: true,
              }, this.loadRequestStatusDetail);
            }}
            className={`fs-01 p-0 ${getRequestStatusColor(data.status)}`}
          >
            {data.status.toUpperCase()}
          </Button>
        ),
      },
      ...this.canIEdit ? [{
        key: 'action',
        displayText: 'Action',
        renderer: (data) => {
          if (data.status !== 'pending' && data.status !== 'on_hold') {
            return (<div className="text-center">--</div>);
          }
          return (
            <select
              className="form-control fs-01 minw-120p"
              onChange={(event) => {
                if (!event.target.value) {
                  return;
                }
                this.setState({
                  selectRefundRequestType: false,
                  selectedRequest: data,
                  remark: data.remark,
                  amountToRefund: data.requestedRefundAmount,
                  remarkForCustomer: data.remarkForCustomer,
                  processingRefundRequestModal: true,
                  performingAction: event.target.value,
                  submitSingleAction: true,
                });
              }}
              disabled={processingRefundRequest}
              value={selectedRequest && selectedRequest.requestId === data.requestId
                ? performingAction : ''}
            >
              <option value="">--SELECT ACTION--</option>
              <option value="PROCESS_REFUND">PROCESS_REFUND</option>
              <option value="PARTIAL_REFUND">PARTIAL_REFUND</option>
              <option value="MARK_WRITE_OFF">MARK_WRITE_OFF</option>
              <option value="MARK_ON_HOLD">MARK_ON_HOLD</option>
            </select>
          );
        },
      }] : [],
      {
        key: 'order_transactions_meta',
        displayText: 'Order Transaction Meta',
        renderer: (data) => (
          <Button
            variant="link"
            onClick={() => {
              this.setState({
                selectedRequest: data,
                showTransactionMeta: true,
                loadingMetaData: true,
              }, this.loadTransactionMeta);
            }}
            className="fs-01 p-0"
          >
            VIEW
          </Button>
        ),
      },
    ];

    if (selectRefundRequestType) {
      headers = [
        {
          key: 'action-dropdown',
          displayText: (
            <CustomDropdown
              item={{
                key: 'action',
                displayText: 'Action',
                options: [
                  {
                    label: 'PROCESS_REFUND',
                    value: 'PROCESS_REFUND',
                  },
                  {
                    label: 'MARK_WRITE_OFF',
                    value: 'MARK_WRITE_OFF',
                  },
                  {
                    label: 'MARK_ON_HOLD',
                    value: 'MARK_ON_HOLD',
                  },
                ],
              }}
              onChange={(target) => {
                if (!!refundRequestIds && Object.keys(refundRequestIds).length > 0) {
                  this.setState({
                    remark: '',
                    remarkForCustomer: '',
                    processingRefundRequestModal: true,
                    performingAction: target.action,
                  });
                }
              }}
              selectedVal=""
              disabled={processingRefundRequest}
            />
          ),
          renderer: (data) => {
            const request = refundRequestIds[data.requestId];
            if (!request && data.status !== 'pending' && data.status !== 'on_hold') {
              return '';
            }
            if (!!request && request.statusMessage === 'SUCCESS') {
              return (
                <Svg
                  svg="circleDone"
                  width="15px"
                  fill={Constant.Color.GREEN}
                />
              );
            }
            return (
              <Form.Check
                custom
                id={`${data.requestId}-checkbox`}
                label=""
                value={data.requestId}
                checked={!!request}
                onChange={(e) => this.handleRequestIdSelection(e, data)}
              />
            );
          },
        },
        {
          key: 'action-status',
          displayText: 'Processing Status',
          renderer: (data) => {
            const obj = refundRequestIds[data.requestId];
            if (obj) {
              return (
                <div
                  className={obj.statusTextColor}
                >
                  {obj.statusMessage}
                </div>
              );
            }
            return '';
          },
        },
      ].concat(headers);
    }

    const transactionMetaHeaders = [
      {
        key: 'transactionId',
        displayText: 'Transaction Id',
      },
      {
        key: 'status',
        displayText: 'Status',
      },
      {
        key: 'transactionChannel',
        displayText: 'Transaction Channel',
      },
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => Utils.dateString(data.createdOn),
      },
      {
        key: 'transactionFees',
        displayText: 'Transaction Fees',
      },
      {
        key: 'paidAmount',
        displayText: 'Paid Amount',
      },
      {
        key: 'refundInFlow',
        displayText: 'Refund In Flow',
      },
      {
        key: 'totalRefundedAmount',
        displayText: 'Total Refunded Amt.',
      },
    ];

    const statusDetailHeaders = [
      {
        key: 'refundId',
        displayText: 'Refund Id',
      },
      {
        key: 'requestId',
        displayText: 'Request Id',
      },
      {
        key: 'transactionId',
        displayText: 'Transaction Id',
      },
      {
        key: 'transactionChannel',
        displayText: 'Transaction Channel',
      },
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => Utils.dateString(data.createdOn),
      },
      {
        key: 'processedOn',
        displayText: 'Processed On',
        renderer: (data) => Utils.dateString(data.processedOn),
      },
      {
        key: 'refundAmount',
        displayText: 'Refund Amt.',
      },
      {
        key: 'status',
        displayText: 'Status',
        renderer: (data) => (
          <div
            className={`fs-01 p-0 ${getRequestStatusColor(data.status)}`}
          >
            {data.status.toUpperCase()}
          </div>
        ),
      },
    ];

    const transactionMetaAndStatusDetailModalBody = () => {
      if (loadingMetaData) {
        return (
          <div
            className="pt-3 text-center"
          >
            <Spinner
              animation="border"
              variant="primary"
            />
          </div>
        );
      }
      if (loadingMetaDataError) {
        return (
          <div
            className="pt-3 text-center"
          >
            <span
              className="text-danger"
            >
              Something Went Wrong. Please try again.
            </span>
            <Button
              variant="primary"
              className="ml-2 fs-01"
              onClick={this.retry}
            >
              Retry
            </Button>
          </div>
        );
      }
      if (orderTransactionMeta) {
        return (
          <CustomTable
            keyField="transactionId"
            headers={transactionMetaHeaders}
            content={orderTransactionMeta.data}
            totalItems={orderTransactionMeta.count}
            isPaginated={false}
            page={page}
          />
        );
      }
      return (
        requestStatusDetail && (
          <CustomTable
            keyField="requestId"
            headers={statusDetailHeaders}
            content={requestStatusDetail.results}
            totalItems={requestStatusDetail.count}
            isPaginated={false}
            page={page}
          />
        )
      );
    };

    if (loading) {
      return (
        <div
          className="pt-3 text-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (error) {
      return (
        <div
          className="pt-3 text-center"
        >
          <span
            className="text-danger"
          >
            Something Went Wrong
          </span>
          <div>
            <Button
              variant="primary"
              onClick={() => this.retry()}
            >
              Retry
            </Button>
          </div>
        </div>
      );
    }

    return (
      <Container
        fluid
        className="bg-white"
      >
        <CustomModal
          show={showTransactionMeta || showStatusDetail}
          size="lg"
          body={transactionMetaAndStatusDetailModalBody()}
          closeButton
          title={showTransactionMeta ? 'Order Transaction Meta' : 'Transaction Status Detail'}
          onHide={() => {
            this.setState({
              showTransactionMeta: false,
              showStatusDetail: false,
              orderTransactionMeta: null,
              requestStatusDetail: null,
              loadingMetaData: false,
              loadingMetaDataError: false,
            });
          }}
        />
        <CustomModal
          show={processingRefundRequestModal}
          closeButton
          onHide={() => {
            this.setState({
              processingRefundRequestModal: false,
              processingRefundRequestErrMsg: '',
              remark: '',
              remarkForCustomer: '',
              amountToRefund: '',
              isInstantPayment: true,
              submitSingleAction: false,
              performingAction: '',
            });
          }}
          title="Process Refund Request"
          size="lg"
          body={(
            <Container>
              {
                !selectRefundRequestType && (
                  <Row
                    className="pt-3"
                  >
                    <Col
                      xs={8}
                    >
                      <b>Amount to Refund:</b>
                    </Col>
                    <Col
                      className="px-3"
                      xs={16}
                    >
                      <input
                        type="number"
                        className="form-control"
                        name="amountToRefund"
                        value={amountToRefund}
                        disabled={performingAction !== 'PARTIAL_REFUND'}
                        onChange={(event) => {
                          const { value } = event.target;
                          if (value <= selectedRequest.requestedRefundAmount) {
                            this.handleOnChange(event);
                          }
                        }}
                      />
                      {
                        selectedRequest && performingAction === 'PARTIAL_REFUND' && (
                          <span className="fs-01 text-secondary">
                            *Amount to refund should be less than
                            &nbsp;
                            {selectedRequest.requestedRefundAmount}
                          </span>
                        )
                      }
                    </Col>
                  </Row>
                )
              }
              <Row
                className="pt-3"
              >
                <Col
                  xs={8}
                >
                  <b>Remark:</b>
                </Col>
                <Col
                  className="px-3"
                  xs={16}
                >
                  <input
                    type="text"
                    className="form-control"
                    name="remark"
                    value={remark}
                    onChange={this.handleOnChange}
                  />
                </Col>
              </Row>
              <Row
                className="pt-3"
              >
                <Col
                  xs={8}
                >
                  <b>Remark For Customer:</b>
                </Col>
                <Col
                  className="px-3"
                  xs={16}
                >
                  <input
                    type="text"
                    className="form-control"
                    name="remarkForCustomer"
                    value={remarkForCustomer}
                    onChange={this.handleOnChange}
                  />
                </Col>
              </Row>
              {
                (
                  performingAction === 'PARTIAL_REFUND'
                  || performingAction === 'PROCESS_REFUND'
                ) && (
                  <Row
                    className="pt-3"
                  >
                    <Col
                      xs={8}
                    >
                      <b>Is Instant Payment:</b>
                    </Col>
                    <Col
                      className="px-3"
                      xs={16}
                    >
                      <Form.Check
                        custom
                        inline
                        type="radio"
                        label="Yes"
                        id="instant-payment"
                        checked={isInstantPayment}
                        onClick={() => {
                          this.setState({
                            isInstantPayment: !isInstantPayment,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                )
              }
              <Row>
                <Col
                  xs={24}
                  className="text-center pt-3"
                >
                  <Button
                    variant="primary"
                    className="px-3 py-2 mr-4"
                    disabled={processingRefundRequest}
                    onClick={() => {
                      this.setState({
                        processingRefundRequestModal: false,
                        submitSingleAction: false,
                        performingAction: '',
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="success"
                    className="px-3 py-2"
                    disabled={
                      processingRefundRequest || (
                        !selectRefundRequestType
                        && (!amountToRefund || parseFloat(amountToRefund) <= 0)
                      )
                    }
                    onClick={() => {
                      this.setState({
                        ...submitSingleAction ? {} : { processingRefundRequestModal: false },
                        processingRefundRequest: true,
                      }, () => {
                        if (submitSingleAction) {
                          this.handleSubmitAction();
                          return;
                        }
                        this.handleBulkSubmitAction();
                      });
                    }}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
              <Row className="pt-4 text-center text-danger">
                {
                  processingRefundRequest && (
                    <Col>
                      <Spinner
                        animation="border"
                        variant="primary"
                      />
                    </Col>
                  )
                }
                {
                  processingRefundRequestErrMsg && (
                    <Col>
                      {processingRefundRequestErrMsg}
                    </Col>
                  )
                }
              </Row>
            </Container>
          )}
        />
        <Row
          fluid
          className="p-2"
        >
          <Col
            xs="auto"
            className="px-0"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Order Ids"
                name="orderIds"
                className="fs-01 rounded-0"
                value={orderIds}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.onSubmitOrderIds();
                  }
                }}
              />
            </InputGroup>
          </Col>
          <Col
            xs="auto"
            className="pl-2 pr-0"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Store Ids"
                name="storeIds"
                className="fs-01 rounded-0"
                value={storeIds}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.onSubmitStoreIds();
                  }
                }}
              />
            </InputGroup>
          </Col>
          {
            filtersConfig.map((item) => (
              <Col
                xs="auto"
                className="pl-2 pr-0"
              >
                <CustomDropdown
                  item={item}
                  closeButton
                  onChange={this.handleRequestProcessing}
                  selectedVal={params[item.key]}
                />
              </Col>
            ))
          }
          <Col
            xs="auto"
            className="pl-2 pr-0"
          >
            <DatePicker
              isDateRange
              onApply={(dateRange) => {
                this.handleDateChange(
                  new Date(dateRange.startDate).getTime(),
                  new Date(dateRange.endDate).getTime(),
                );
              }}
              startDate={params.startDate}
              endDate={params.endDate}
              onClear={() => {
                this.handleRequestProcessing({
                  startDate: '', endDate: '',
                });
              }}
            />
          </Col>
          {
            this.canIEdit && (
              <Col
                xs="auto"
                className="pl-2 pr-0"
              >
                <CustomDropdown
                  item={{
                    key: 'selectRefundRequestType',
                    displayText: 'Select Request',
                    options: [
                      {
                        label: 'All',
                        value: 'all',
                      },
                      {
                        label: 'Manually',
                        value: 'manually',
                      },
                      {
                        label: 'None',
                        value: '',
                      },
                    ],
                  }}
                  onChange={this.handleSelectRefundRequest}
                  selectedVal={selectRefundRequestType}
                  closeButton={false}
                  disabled={processingRefundRequest}
                />
              </Col>
            )
          }
        </Row>
        <Row
          fluid
        >
          {
            refunds && (
              <CustomTable
                keyField="id"
                headers={headers}
                content={refunds.data}
                isPaginated
                totalItems={refunds.count}
                hasPrev={refunds.prev !== ''}
                hasNext={refunds.next !== ''}
                l={params.l}
                p={params.p}
                rowsPerPage={rowsPerPage}
                page={page}
                onNext={this.onNext}
                onPrev={this.onPrev}
                updateRowsPageInput={this.handleRowsPageInput}
                onSubmitPage={this.onSubmitPage}
                onSubmitRowsPerPage={this.onSubmitRowsPerPage}
              />
            )
          }
        </Row>
      </Container>
    );
  }
}

RefundDashboard.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default RefundDashboard;
