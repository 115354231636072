import React, { Component } from 'react';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import { referrerChecks } from '../../assets/api/axios';

class ReferralCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referrerNumber: '',
      referredTo: '',
      isSubmitting: false,
      isError: false,
      referrals: null,
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onSubmitReferralCheck = () => {
    const { referrerNumber, referredTo } = this.state;
    this.setState({
      isSubmitting: true,
      isError: false,
      referrals: null,
    });
    referrerChecks(
      'GET',
      {
        referrerNumber,
        referredTo,
      },
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          isSubmitting: false,
          referrals: res.data,
        });
      } else {
        throw new Error();
      }
    }).catch(() => {
      this.setState({
        isSubmitting: false,
        isError: true,
      });
    });
  }

  render() {
    const {
      referrerNumber, referredTo, referrals,
      isSubmitting, isError,
    } = this.state;

    return (
      <Container
        fluid
        className="bg-white h-100 pt-3"
      >
        <Row>
          <Col
            xs={10}
            className="d-flex"
          >
            <div>
              <b>Referrer Number:</b>
            </div>
            <div
              className="pl-3 w-75"
            >
              <input
                type="text"
                className="form-control"
                name="referrerNumber"
                placeholder="Referrer Number (Required*)"
                value={referrerNumber}
                onChange={this.handleChange}
              />
            </div>
          </Col>
          <Col
            xs={10}
            className="d-flex"
          >
            <div>
              <b>Referred To:</b>
            </div>
            <div
              className="pl-3 w-75"
            >
              <input
                type="text"
                className="form-control"
                name="referredTo"
                placeholder="In Comma Separated (e.g 12345,54321) (Optional)"
                value={referredTo}
                onChange={this.handleChange}
              />
            </div>
          </Col>
          <Col
            xs={4}
          >
            <Button
              variant="primary"
              className="px-3"
              onClick={this.onSubmitReferralCheck}
              disabled={
                isSubmitting
                || !referrerNumber
              }
            >
              Submit
            </Button>
          </Col>
          <Col
            className="border-bottom w-100 pt-3"
          />
        </Row>
        <Row>
          {
            isSubmitting && (
              <Col
                className="p-3 mx-auto text-center"
                xs={18}
              >
                <Spinner
                  variant="primary"
                  animation="border"
                />
              </Col>
            )
          }
          {
            isError && (
              <Col
                className="p-3 mx-auto text-center text-danger"
                xs={12}
              >
                <b>
                  Oops Something Went Wrong!!
                  Please Try Again!!
                </b>
              </Col>
            )
          }
          {
            !!referrals && (
            <Col
              className="p-3 mx-auto"
              xs={16}
            >
              <Row>
                <Col
                  className="text-center text-info border border-info p-1"
                >
                  <span>
                    <b>
                      Referrer Number:&nbsp;&nbsp;
                      {referrals.referrerNumber}
                    </b>
                  </span>
                  <span
                    className="pl-3"
                  >
                    <b>
                      Referrer Code:&nbsp;&nbsp;
                      {referrals.referrerCode}
                    </b>
                  </span>
                </Col>
              </Row>
              <Row>
                {
                  referrals.referredNumberChecks.map((item) => (
                    <Col
                      className="mt-3 border border-secondary"
                      xs={24}
                    >
                      <div
                        className="text-center py-1 font-weight-bold"
                      >
                        Referred To:&nbsp;&nbsp;
                        {item.number}
                      </div>
                      {
                        item.check.length > 0 && (
                          item.check.map((check) => (
                            <Row
                              className="border p-1"
                            >
                              <Col
                                xs={10}
                              >
                                {`${check.key}`}
                              </Col>
                              <Col
                                xs={2}
                                className="border-right"
                              />
                              <Col
                                xs={10}
                              >
                                {`${check.value}`}
                              </Col>
                            </Row>
                          ))
                        )
                      }
                    </Col>
                  ))
                }
              </Row>
            </Col>
            )
          }
        </Row>
      </Container>
    );
  }
}

export default ReferralCheck;
