import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Row, Spinner,
} from 'react-bootstrap';
import { getKycDocumentTypes, indianStatesAndTerritories } from '../../../utilities/Utils';
import { CustomModal } from '../../common';
import { kycVerification, storeProfile, uploadImage } from '../../../assets/api/axios';
import { validateRegex } from '../../../utilities/FormValidations';

class KYCVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessKycType: '',
      businessKycId: '',
      selectedState: '',
      personalKycType: '',
      personalKycId: '',
      dlDOB: null,
      kycVerificationState: '',
      kycVerificationError: '',
      personalKycSubmittedDetails: {},
      kycVerificationReasons: null,
      gstNumber: '',
      storeCategory: '',
      drugLicenseNumber: '',
      invalidLicenseNumber: false,
      drugLicenseImage: '',
      imageUploadStatus: '',
      drugLicenseSubmitStatus: '',
    };
    this.hiddenFileInput = React.createRef();
  }

  componentDidMount = () => {
    const { profile } = this.props;
    this.setState({
      businessKycType: profile.kyc.businessKycType,
      businessKycId: profile.kyc.businessKycId,
      personalKycType: profile.kyc.personalKycType,
      personalKycId: profile.kyc.personalKycId,
      gstNumber: profile.kyc.gstNumber,
      storeCategory: profile.storeType.displayName,
      drugLicenseNumber: profile.kyc.drugLicenseNumber,
      drugLicenseImage: profile.kyc.drugLicenseImage,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  submitPersonalKyc = (kycParams = {}) => {
    const { profile } = this.props;
    this.setState({
      kycVerificationState: 'loading',
    }, () => {
      kycVerification(
        'get',
        {
          ...kycParams,
        },
        profile.code,
        null,
        null,
      ).then((res) => {
        this.setState({
          kycVerificationState: '',
          personalKycSubmittedDetails: {
            ...res.data,
          },
        }, () => {
          this.kycActionParams('personal');
        });
      })
        .catch((error) => {
          let errorResp = '';
          if (
            error
            && error.response
            && error.response.data
            && error.response.data[0]
            && error.response.data[0].client_message
          ) {
            errorResp = error.response.data[0].client_message;
          } else {
            errorResp = 'Oops, Something Went Wrong!';
          }
          this.setState({
            kycVerificationState: 'error',
            kycVerificationError: errorResp,
          });
        });
    });
  }

  kycActionParams = (type = '') => {
    const {
      personalKycSubmittedDetails, personalKycType, businessKycType,
      businessKycId, selectedState,
    } = this.state;
    const { profile } = this.props;
    const businessKycDefaultParams = {
      isBusinessKyc: true,
      shopName: profile.displayName,
      pinCode: profile.pinCode,
    };
    let kycValidationParams = {};
    if (type === 'business') {
      switch (businessKycType) {
        case 'UDYOG_AADHAR_CERTIFICATE':
          kycValidationParams = {
            ...businessKycDefaultParams,
            action: 'UDHYOGAADHAAR',
            uan: businessKycId,
          };
          break;

        case 'UDYAM_AADHAR_CERTIFICATE':
          kycValidationParams = {
            ...businessKycDefaultParams,
            action: 'UDYAMREGISTRATION',
            udyamNumber: businessKycId,
          };
          break;

        case 'PAN_CARD':
          kycValidationParams = {
            ...businessKycDefaultParams,
            action: 'PANCARD',
            panNumber: businessKycId,
          };
          break;

        case 'SHOP_ESTABLISHMENT_GUMASTA_LICENSE':
          kycValidationParams = {
            ...businessKycDefaultParams,
            action: 'SHOPREGISTRATION',
            registrationNumber: businessKycId,
            state: selectedState ? selectedState.displayName : '',
          };
          break;

        case 'GST_CERTIFICATE':
          kycValidationParams = {
            ...businessKycDefaultParams,
            action: 'GSTIN',
            gstin: businessKycId,
          };
          break;

        case 'FSSAI_CERTIFICATE':
          kycValidationParams = {
            ...businessKycDefaultParams,
            action: 'FSSAI',
            fssai: businessKycId,
          };
          break;

        default:
          break;
      }
    } else {
      switch (personalKycType) {
        case 'DRIVING_LICENCE':
          kycValidationParams = {
            action: 'VERIFYFACEMATCHES',
            firstImage: profile.kyc.ownerImage,
            secondImage: personalKycSubmittedDetails ? personalKycSubmittedDetails.photo : '',
            shopOwnerName: profile.ownerName,
          };
          break;

        case 'VOTER_ID':
          kycValidationParams = {
            action: 'VERIFYVOTERID',
            shopOwnerName: profile.ownerName,
          };
          break;

        case 'PAN_CARD':
          kycValidationParams = {
            action: 'VERIFYPANCARD',
            shopOwnerName: profile.ownerName,
          };
          break;

        case 'UDYOG_AADHAR_CERTIFICATE':
          kycValidationParams = {
            action: 'VERIFYFACEMATCHES',
            firstImage: profile.kyc.ownerImage,
            secondImage: personalKycSubmittedDetails ? personalKycSubmittedDetails.photo : '',
            shopOwnerName: profile.ownerName,
          };
          break;

        default:
          break;
      }
    }
    this.validateKycDetails(type, kycValidationParams);
  }

  gstValidationParams = () => {
    const { gstNumber } = this.state;
    const { profile } = this.props;
    const gstParams = {
      isBusinessKyc: true,
      shopName: profile.displayName,
      pinCode: profile.pinCode,
      action: 'GSTIN',
      gstin: gstNumber,
      onlyGst: true,
    };
    this.validateKycDetails('gst', gstParams);
  }

  drugLicenseDetails = () => {
    const { drugLicenseNumber, drugLicenseImage } = this.state;
    const { profile } = this.props;
    const isValid = validateRegex('drugLicenseNumber', drugLicenseNumber);
    if (!isValid) {
      this.setState({
        invalidLicenseNumber: true,
        drugLicenseSubmitStatus: '',
      });
      return;
    }
    storeProfile(
      'PATCH',
      profile.code,
      {
        kyc: {
          drugLicenseNumber,
          drugLicenseImage,
        },
      },
    ).then(() => {
      this.setState({
        drugLicenseSubmitStatus: 'success',
      }, () => {
        setTimeout(() => {
          this.setState({
            drugLicenseSubmitStatus: '',
          });
        }, 5000);
      });
    }).catch(() => {
      this.setState({
        drugLicenseSubmitStatus: 'error',
      });
    });
  }

  validateKycDetails = (type, kycParams = {}) => {
    const { profile, setProfileDetails } = this.props;
    this.setState({
      kycVerificationState: 'loading',
      kycVerificationError: '',
    });
    kycVerification(
      'get',
      {
        ...kycParams,
      },
      profile.code,
      null,
      null,
    ).then((resp) => {
      this.setState({
        kycVerificationState: 'success',
        kycVerificationReasons: {
          type,
          reasons: resp.data.isCompletelyVerified ? [] : resp.data.reasons,
          isCompletelyVerified: resp.data.isCompletelyVerified,
        },
      }, setProfileDetails);
    }).catch((error) => {
      let errorResp = '';
      if (
        error
            && error.response
            && error.response.data
            && error.response.data[0]
            && error.response.data[0].client_message
      ) {
        errorResp = error.response.data[0].client_message;
      } else {
        errorResp = 'Oops, Something Went Wrong!';
      }
      this.setState({
        kycVerificationState: 'error',
        kycVerificationError: errorResp,
      });
    });
  }

  handleImageChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('img', file);
    if (file) {
      uploadImage(
        'POST',
        formData,
      ).then((res) => {
        this.setState({
          drugLicenseImage: res.data.url,
          imageUploadStatus: '',
        });
      }).catch(() => {
        this.setState({ imageUploadStatus: 'error' });
      });
    }
  }

  render() {
    const {
      businessKycId, businessKycType, personalKycType, personalKycId,
      selectedState, kycVerificationState, kycVerificationReasons, dlDOB,
      kycVerificationError, gstNumber, storeCategory, drugLicenseNumber,
      drugLicenseImage, imageUploadStatus, invalidLicenseNumber, drugLicenseSubmitStatus,
    } = this.state;

    const businessKycTypes = getKycDocumentTypes('businessKyc');
    const personalKycTypes = getKycDocumentTypes('personalKyc');

    let personalKycValidationAction = () => {
      /* TODO document why this arrow function is empty */
    };

    if (personalKycType === 'DRIVING_LICENCE') {
      personalKycValidationAction = () => this.submitPersonalKyc({
        action: 'VERIFYDRIVINGLICENCE',
        drivingLicence: personalKycId,
        dob: new Date(dlDOB).getTime(),
      });
    } else if (personalKycType === 'VOTER_ID') {
      personalKycValidationAction = () => this.submitPersonalKyc({
        action: 'VERIFYVOTERID',
        epicNumber: personalKycId,
      });
    } else if (personalKycType === 'PAN_CARD') {
      personalKycValidationAction = () => this.submitPersonalKyc({
        action: 'VERIFYPANCARD',
        panNumber: personalKycId,
      });
    }

    return (
      <>
        <CustomModal
          show={!!kycVerificationReasons || !!kycVerificationState}
          title="KYC Verification Status"
          onHide={() => {
            this.setState({
              kycVerificationState: '',
              kycVerificationError: '',
              kycVerificationReasons: null,
            });
          }}
          closeButton
          autoSize
          body={(
            <Row
              className="m-0 py-3"
            >
              {kycVerificationState === 'loading' && (
                <Col
                  className="text-center"
                >
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                </Col>
              )}
              {kycVerificationState === 'error' && (
                <Col
                  className="text-center text-danger"
                >
                  {kycVerificationError}
                </Col>
              )}
              {
                !!kycVerificationReasons && (
                  kycVerificationReasons.isCompletelyVerified ? (
                    <Col
                      className="text-center text-success"
                    >
                      {`${
                        (kycVerificationReasons.type).toUpperCase()
                      } KYC is completely Verified!!`}
                    </Col>
                  ) : (
                    <Col>
                      <p
                        className="text-danger"
                      >
                        {(kycVerificationReasons.type).toUpperCase()}
                        &nbsp;KYC Complete Validation Failure Reasons
                      </p>
                      {
                        kycVerificationReasons.reasons.map((reason) => (
                          <div key={reason}>
                            &#8226;
                            {reason}
                          </div>
                        ))
                      }
                    </Col>
                  )
                )
              }
            </Row>
          )}
        />
        <Row
          className=""
        >
          {/* Business KYC */}
          <Col
            xs={24}
            className="pb-3 text-center text-info"
          >
            <b>&#8226;Validate Business KYC&#8226;</b>
          </Col>

          <Col
            xs={24}
            className="pb-3"
          >
            <div>
              <select
                className="form-control rounded-0"
                name="businessKycType"
                id="business"
                value={businessKycType}
                onChange={this.handleChange}
              >
                <option
                  value=""
                >
                  Select Document
                </option>
                {
                businessKycTypes.map((item) => (
                  <option
                    key={item.id}
                    value={item.id}
                  >
                    {item.displayName}
                  </option>
                ))
            }
              </select>
            </div>
            {
              businessKycType === 'SHOP_ESTABLISHMENT_GUMASTA_LICENSE' && (
                <div>
                  <select
                    className="form-control rounded-0"
                    name="selectedState"
                    id="state"
                    value={selectedState}
                    onChange={this.handleChange}
                  >
                    <option
                      value=""
                    >
                      Select State
                    </option>
                    {
                      indianStatesAndTerritories.map((item) => (
                        <option
                          key={item.id}
                          value={item.id}
                        >
                          {item.displayName}
                        </option>
                      ))
                    }
                  </select>
                </div>
              )
            }
            <div>
              <input
                className="form-control rounded-0"
                placeholder="Enter Document id"
                type="text"
                name="businessKycId"
                value={businessKycId}
                onChange={this.handleChange}
              />
            </div>
            <div>
              <Button
                className="rounded-0"
                block
                disabled={
                !businessKycId
                || !businessKycType
                || (businessKycType === 'SHOP_ESTABLISHMENT_GUMASTA_LICENSE' && !selectedState)
                || kycVerificationState === 'loading'
              }
                onClick={() => {
                  this.kycActionParams('business');
                }}
              >
                VALIDATE
              </Button>
            </div>
          </Col>

          <Col
            xs={24}
            className="pb-3 text-center text-info"
          >
            <b>&#8226;Validate Personal KYC&#8226;</b>
          </Col>

          {/* Personal KYC */}
          <Col
            xs={24}
            className="pb-3"
          >
            <div>
              <select
                className="form-control rounded-0"
                name="personalKycType"
                id="personal"
                value={personalKycType}
                onChange={this.handleChange}
              >
                <option
                  value=""
                >
                  Select Document
                </option>
                {
                  personalKycTypes.map((item) => (
                    <option
                      key={item.id}
                      value={item.id}
                    >
                      {item.displayName}
                    </option>
                  ))
                }
              </select>
            </div>
            {
              personalKycType === 'DRIVING_LICENCE' && (
                <div>
                  <input
                    className="form-control rounded-0"
                    type="date"
                    name="dlDOB"
                    value={dlDOB}
                    onChange={this.handleChange}
                  />
                </div>
              )
            }
            <div>
              <input
                className="form-control rounded-0"
                placeholder="Enter Document id"
                type="text"
                name="personalKycId"
                value={personalKycId}
                onChange={this.handleChange}
              />
            </div>
            <div>
              <Button
                className="rounded-0"
                block
                disabled={
                    !personalKycId
                    || !personalKycType
                    || personalKycType === 'UDYOG_AADHAR_CERTIFICATE'
                    || (personalKycType === 'DRIVING_LICENCE' && !dlDOB)
                    || kycVerificationState === 'loading'
                }
                onClick={() => {
                  personalKycValidationAction();
                }}
              >
                VALIDATE
              </Button>
            </div>
          </Col>

          {/* Validate GST Number */}
          {
            businessKycType !== 'GST_CERTIFICATE' && (
              <>
                <Col
                  xs={24}
                  className="pb-3 text-center text-info"
                >
                  <b>&#8226;Validate GST Certificate&#8226;</b>
                </Col>
                <Col
                  xs={24}
                  className="pb-3"
                >
                  <div>
                    <input
                      className="form-control rounded-0"
                      placeholder="Enter GST Number"
                      type="text"
                      name="gstNumber"
                      value={gstNumber}
                      onChange={this.handleChange}
                      maxLength="15"
                    />
                  </div>
                  <div>
                    <Button
                      className="rounded-0"
                      block
                      disabled={
                        !gstNumber || (gstNumber && gstNumber.length !== 15)
                        || kycVerificationState === 'loading'
                      }
                      onClick={() => {
                        this.gstValidationParams();
                      }}
                    >
                      VALIDATE
                    </Button>
                  </div>
                </Col>
              </>
            )
          }
          {
            storeCategory === 'PHARMACIES' && (
              <>
                <Col
                  xs={24}
                  className="pb-3 text-center text-info"
                >
                  <b>&#8226;Add Drug License&#8226;</b>
                </Col>
                <Col
                  xs={24}
                  className="pb-3"
                >
                  <div>
                    <input
                      className="form-control rounded-0"
                      placeholder="Please enter your Drug License Number"
                      type="text"
                      name="drugLicenseNumber"
                      value={drugLicenseNumber}
                      onChange={this.handleChange}
                      maxLength="20"
                    />
                    {
                      invalidLicenseNumber && (
                        <div className="text-center text-danger">
                          Please enter valid drug license number
                        </div>
                      )
                    }
                  </div>
                  <div className="my-2">
                    <input
                      type="file"
                      className="d-none"
                      accept="image/*"
                      ref={this.hiddenFileInput}
                      onChange={(e) => {
                        this.setState({
                          imageUploadStatus: 'loading',
                        }, this.handleImageChange(e));
                      }}
                    />
                    <Button
                      variant="primary"
                      className="fs-01 font-weight-bold"
                      onClick={() => {
                        this.hiddenFileInput.current.click();
                      }}
                      disabled={imageUploadStatus === 'loading'}
                    >
                      UPLOAD LICENSE IMAGE
                    </Button>
                    {
                      imageUploadStatus === 'loading' && (
                        <div className="pl-3 mt-1">
                          <Spinner
                            animation="border"
                            variant="primary"
                          />
                        </div>
                      )
                    }
                    {
                      imageUploadStatus === 'error' && (
                        <div className="text-danger mt-1">
                          Oops something went wrong
                        </div>
                      )
                    }
                    {
                      drugLicenseImage && (
                        <div>
                          <img
                            src={drugLicenseImage}
                            alt="drugImage"
                            width="250px"
                            className="py-1"
                          />
                        </div>
                      )
                    }
                  </div>
                  <div>
                    <Button
                      className="rounded-0"
                      block
                      disabled={!drugLicenseNumber || !drugLicenseImage || drugLicenseSubmitStatus === 'loading'}
                      onClick={() => {
                        this.setState({
                          invalidLicenseNumber: false,
                          drugLicenseSubmitStatus: 'loading',
                        }, this.drugLicenseDetails);
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                  {
                    drugLicenseSubmitStatus === 'loading' && (
                      <div className="d-flex justify-content-center mt-1">
                        <Spinner
                          animation="border"
                          variant="primary"
                        />
                      </div>
                    )
                  }
                  {
                    drugLicenseSubmitStatus === 'error' && (
                      <div className="text-danger text-center mt-1">
                        Oops something went wrong
                      </div>
                    )
                  }
                  {
                    drugLicenseSubmitStatus === 'success' && (
                      <div className="text-success text-center mt-1">
                        Submitted
                      </div>
                    )
                  }
                </Col>
              </>
            )
          }
        </Row>
      </>
    );
  }
}

KYCVerification.propTypes = {
  profile: PropTypes.shape({
    code: PropTypes.string.isRequired,
    pinCode: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    ownerName: PropTypes.string.isRequired,
    kyc: PropTypes.shape({
      businessKycType: PropTypes.string,
      businessKycId: PropTypes.string,
      personalKycType: PropTypes.string,
      personalKycId: PropTypes.string,
      ownerImage: PropTypes.string,
      gstNumber: PropTypes.string,
      drugLicenseNumber: PropTypes.string,
      drugLicenseImage: PropTypes.string,
    }),
    storeType: PropTypes.shape({
      displayName: PropTypes.string,
    }),
  }).isRequired,
  setProfileDetails: PropTypes.func.isRequired,
};

export default KYCVerification;
