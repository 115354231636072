/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Spinner, Form, Button,
} from 'react-bootstrap';
import Axios from 'axios';
import QueryString from 'query-string';
import { storeJobStatus } from '../../assets/api/axios';
import { CustomModal, CustomTable, ErrorHandler } from '../../component/common';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    ...param,
    l,
    p,
  });
};

class StoreAggregatedJobStatus extends React.Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    this.state = {
      storeId: '',
      jobId: '',
      jobStatusType: '',
      selectedRow: null,
      error: false,
      loading: false,
      storeJobStatusData: null,
      rowsPerPage: param.l,
      page: param.p,
      param,
    };
    this.source = Axios.CancelToken.source();
  }

  handleLoad = (data = {}) => {
    this.setState({
      loading: true,
    });
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p,
    } = newParam;
    const offset = (p - 1) * l;
    const { storeId, jobId, jobStatusType } = this.state;
    const jobIdParam = jobId ? { jobId } : {};
    const jobStatusTypeParam = jobStatusType ? { jobStatusType } : {};
    const params = {
      ...jobIdParam,
      ...jobStatusTypeParam,
      offset,
      limit: l,
    };
    storeJobStatus(
      'GET',
      storeId,
      params,
    ).then((response) => {
      this.setState({
        storeJobStatusData: response.data,
        loading: false,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleOnChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }


  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  render() {
    const {
      storeId, jobId, jobStatusType, storeJobStatusData,
      param, rowsPerPage, page, loading,
      selectedRow, error,
    } = this.state;

    const headers = [
      {
        key: 'status',
        displayText: 'Status',
      },
      {
        key: 'date',
        displayText: 'Date',
      },
      {
        key: 'products',
        displayText: 'Products',
        renderer: (data) => (
          <Button
            variant="link"
            className="p-0 fs-01"
            onClick={() => {
              this.setState({
                selectedRow: data,
              });
            }}
          >
            VIEW PRODUCTS
          </Button>
        ),
      },
    ];

    return (
      <Container
        fluid="true"
      >
        {
          !!selectedRow && (
            <CustomModal
              show={!!selectedRow}
              onHide={() => {
                this.setState({
                  selectedRow: null,
                });
              }}
              size="lg"
              title="Products"
              closeButton
              body={(
                <Row
                  className="my-4 pl-4"
                >
                  {
                    selectedRow.products.map((item) => (
                      <Col
                        key={item}
                        xs={4}
                        className="py-1"
                      >
                        {item}
                      </Col>
                    ))
                  }
                </Row>
              )}
            />
          )
        }
        <Row
          fluid
          className="mx-0 my-2"
        >
          <Col
            xs={24}
          >
            <Form>
              <Form.Group
                as={Row}
                className="py-2"
              >
                <Col
                  xs={4}
                >
                  <Form.Label>
                    <span
                      className="text-danger"
                    >
                      *&nbsp;
                    </span>
                    Store Id:
                  </Form.Label>
                </Col>
                <Col
                  xs={12}
                >
                  <Form.Control
                    id="storeId"
                    type="number"
                    value={storeId}
                    onChange={(e) => this.handleOnChange(e)}
                    placeholder="Enter Store Id"
                    autoComplete="off"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="pb-2"
              >
                <Col
                  xs={4}
                >
                  <Form.Label>
                    Job Id:
                  </Form.Label>
                </Col>
                <Col
                  xs={12}
                >
                  <Form.Control
                    id="jobId"
                    type="text"
                    value={jobId}
                    onChange={(e) => this.handleOnChange(e)}
                    placeholder="Enter jobId"
                    autoComplete="off"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="pb-2"
              >
                <Col
                  xs={4}
                >
                  <Form.Label>
                    Job status type:
                  </Form.Label>
                </Col>
                <Col
                  xs={12}
                >
                  <select
                    id="jobStatusType"
                    className="form-control"
                    value={jobStatusType}
                    onChange={(e) => this.handleOnChange(e)}
                  >
                    <option key="none" value="">
                      None
                    </option>
                    <option key="ADDJOBS" value="ADDJOBS">
                      ADDJOBS
                    </option>
                    <option key="UPDATEJOB" value="UPDATEJOB">
                      UPDATEJOB
                    </option>
                  </select>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column xs={4} />
                <Col
                  xs={12}
                >
                  <Button
                    variant="primary"
                    disabled={storeId === ''}
                    onClick={() => {
                      this.handleLoad();
                    }}
                  >
                    SUBMIT
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        {
          loading && (
            <Row>
              <Col
                xs={24}
                className="text-center pt-3"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </Col>
            </Row>
          )
        }
        {
          error && !loading && (
            <Row>
              <Col
                xs={24}
                className="text-center pt-3"
              >
                <ErrorHandler
                  retryLogic={() => this.retry()}
                />
              </Col>
            </Row>
          )
        }
        {
          !!storeJobStatusData && !error && !loading && (
            <CustomTable
              headers={headers}
              content={storeJobStatusData.results}
              keyField="date"
              l={param.l}
              p={param.p}
              rowsPerPage={rowsPerPage}
              page={page}
              totalItems={storeJobStatusData.count}
              hasPrev={storeJobStatusData.hasPrev}
              hasNext={storeJobStatusData.hasNext}
              onNext={this.onNext}
              onPrev={this.onPrev}
              onSubmitPage={this.onSubmitPage}
              onSubmitRowsPerPage={this.onSubmitRowsPerPage}
              updateRowsPageInput={this.handleRowsPageInput}
            />
          )
        }
      </Container>
    );
  }
}

StoreAggregatedJobStatus.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default StoreAggregatedJobStatus;
