import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

function CellLayout(props) {
  const { data, title } = props;
  return (
    <div
      id="cell-layout"
      className="mh-100 overflow-y"
    >
      {title && (
      <div
        className="p-2 bg-light sticky-top fs-0 text-medium"
      >
        <b>{title}</b>
      </div>
      )}
      <Table
        bordered
        className="m-0 fs-0"
      >
        <tbody>
          {data.map((item) => (
            <tr
              key={item.header}
              className="fs-0 text-medium"
            >
              <th
                className="py-2 minw-120p"
              >
                {item.header}
              </th>
              <td
                className="py-2 minw-120p"
              >
                {item.content}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

CellLayout.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.number,
      PropTypes.string,
    ]),
    content: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.number,
      PropTypes.string,
    ]),
  })).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
};

CellLayout.defaultProps = {
  title: null,
};

export default CellLayout;
