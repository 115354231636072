import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal, CustomTable, ErrorHandler } from '../../component/common';
import { storePinning } from '../../assets/api/axios';
import { ProcessingStatus } from '../../component/derived/table-list';
import { dateString } from '../../utilities/Utils';

function UnpinnedStores(props) {
  const { show, onHide } = props;
  const [state, setState] = useState({
    loadingStatus: '',
    storeList: null,
    rowsPerPage: 10,
    page: 1,
  });

  const {
    loadingStatus, storeList, rowsPerPage, page,
  } = state;

  const handleLoad = (params = {}) => {
    setState({
      ...state,
      loadingStatus: 'loading',
    });
    const newParams = { page, rowsPerPage, ...params };
    storePinning(
      'GET',
      null,
      'unpinned-stores',
      {
        page: newParams.page,
        limit: newParams.rowsPerPage,
      },
    ).then((res) => {
      setState({
        ...state,
        storeList: res.data,
        loadingStatus: 'success',
        page: newParams.page,
        rowsPerPage: newParams.rowsPerPage,
      });
    }).catch(() => {
      setState({
        ...state,
        loadingStatus: 'error',
      });
    });
  };

  useEffect(() => {
    handleLoad();
    // eslint-disable-next-line
  }, []);

  const onNext = () => {
    handleLoad({ page: page + 1 });
  };

  const onPrev = () => {
    handleLoad({ page: page - 1 });
  };

  const onSubmitRowsPerPage = () => {
    handleLoad();
  };

  const handleRowsPageInput = (value, field) => {
    setState(() => {
      if (field === 'page') {
        return {
          ...state,
          page: value,
        };
      }
      return {
        ...state,
        rowsPerPage: value,
      };
    });
  };

  const onSubmitPage = () => {
    handleLoad();
  };

  const onCancel = () => {
    setState({
      ...state,
      loadingStatus: '',
    });
  };

  const headers = [
    {
      key: 'storeId',
      displayText: 'Id',
    },
    {
      key: 'storeName',
      displayText: 'Name',
    },
    {
      key: 'unpinnedOn',
      displayText: 'Unpinned On',
      renderer: (data) => dateString(data.unpinnedOn),
    },
    {
      key: 'pincode',
      displayText: 'PinCode',
    },
  ];

  const body = (
    <Container
      fluid
      className="py-2"
    >
      <Row>
        {(() => {
          let showcase = null;
          if (!storeList && loadingStatus === 'loading') {
            showcase = (
              <Col
                xs={24}
                className="d-flex align-items-center justify-content-center"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </Col>
            );
          } else if (!storeList && loadingStatus === 'error') {
            showcase = (
              <Col
                xs={24}
                className="d-flex align-items-center justify-content-center"
              >
                <ErrorHandler
                  retryLogic={() => { handleLoad(); }}
                />
              </Col>
            );
          } else if (storeList) {
            showcase = (
              <Col
                xs={24}
                className="px-0"
              >
                <>
                  <ProcessingStatus
                    show={loadingStatus === 'loading' || loadingStatus === 'error'}
                    loading={loadingStatus === 'loading'}
                    error={loadingStatus === 'error'}
                    onRetry={() => handleLoad()}
                    onCancel={() => onCancel()}
                    border
                  />
                  <CustomTable
                    headers={headers}
                    content={storeList?.data}
                    keyField="storeId"
                    totalItems={storeList?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    l={rowsPerPage}
                    p={page}
                    hasNext={!!storeList?.next}
                    hasPrev={!!storeList?.prev}
                    onNext={onNext}
                    onPrev={onPrev}
                    onSubmitPage={onSubmitPage}
                    onSubmitRowsPerPage={onSubmitRowsPerPage}
                    updateRowsPageInput={handleRowsPageInput}
                    pagiationPostion="position-sticky"
                  />
                </>
              </Col>
            );
          }
          return showcase;
        })()}
      </Row>
    </Container>
  );

  return (
    <CustomModal
      show={show}
      onHide={onHide}
      title="Unpinned Stores"
      body={body}
      size="lg"
      closeButton
    />
  );
}

UnpinnedStores.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default UnpinnedStores;
