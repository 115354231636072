import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import { exotelCallInit, exotelCallDetails } from '../../assets/api/axios';
import { ErrorHandler } from '../../component/common';
import { Storage } from '../../utilities';

class PatchExotelCallDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      currentExotelCallId: null,
      callPurpose: '',
      additionalCallPurpose: '',
      adminMsg: '',
      callComments: '',
      processingCall: false,
      callError: false,
      processingPatch: false,
      patchError: false,
      patchSuccess: false,
    };
  }

  componentDidMount() {
    this.setState({ processingCall: true });
    this.initiateExotelCall();
  }

  handleChangeExotelDetails = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  initiateExotelCall = () => {
    const { phoneNumber, action, orderId } = this.props;
    const exotelCallMeta = {
      action,
      meta: { ...orderId ? { orderId } : {} },
      callFromUser: {
        adminUser: Storage.getUserName(),
        phoneNumber: Storage.getUserNumber(),
      },
      callToUser: {
        phoneNumber,
      },
    };
    exotelCallInit('post', exotelCallMeta)
      .then((res) => {
        this.setState({
          currentExotelCallId: res.data.id,
          processingCall: false,
          callError: false,
        });
      })
      .catch(() => {
        this.setState({
          processingCall: false,
          callError: true,
        });
      });
  }

  patchExotelCallDetails = () => {
    const {
      adminMsg, callComments, callPurpose, currentExotelCallId, additionalCallPurpose,
    } = this.state;
    const { onHide } = this.props;
    exotelCallDetails(
      'PATCH',
      {
        callDescription: adminMsg,
        callComments,
        callLocation: 'Order Management',
        callPurpose: `${callPurpose}${additionalCallPurpose
          ? ` - ${additionalCallPurpose}` : ''
        }`,
      },
      currentExotelCallId,
    ).then(() => {
      this.setState({
        patchSuccess: true,
      });
      onHide();
    })
      .catch(() => {
        this.setState({
          processingPatch: false,
          patchError: true,
        });
      });
  }

  reset = () => {
    this.setState({
      currentExotelCallId: null,
      callPurpose: '',
      additionalCallPurpose: '',
      adminMsg: '',
      callComments: '',
      processingCall: false,
      callError: false,
      processingPatch: false,
      patchError: false,
    });
  }

  render() {
    const {
      adminMsg, callComments,
      callPurpose, patchError, processingPatch,
      processingCall, callError, currentExotelCallId, patchSuccess,
    } = this.state;

    if (processingCall) {
      return (
        <Container>
          <Row className="py-2 justify-content-center d-flex">
            <Spinner
              variant="primary"
              animation="border"
            />
          </Row>
        </Container>
      );
    }

    if (!processingCall && callError) {
      return (
        <ErrorHandler
          retryLogic={() => {
            this.setState({
              processingCall: true,
              callError: false,
            }, () => {
              this.initiateExotelCall();
            });
          }}
        />
      );
    }

    return (
      <Container>

        {
          (!processingCall && !callError && currentExotelCallId)
          && (
            <Row>
              <Col
                xs={24}
                className="mt-2 p-2 fs-3 text-center text-medium"
              >
                <b>
                  Your request initiated successfully
                </b>
              </Col>
              <Col xs={24} className="p-2 fs-3 text-center text-medium">
                <b>
                  Please fill following details and submit
                </b>
              </Col>
            </Row>
          )
        }
        <Row className="pt-2">
          <Col xs={12}>
            <b>Call Purpose</b>
            <span className="text-danger">*</span>
          </Col>
          <Col xs={24} className="pt-2">
            <select
              name="callPurpose"
              value={callPurpose}
              className="form-control"
              onChange={this.handleChangeExotelDetails}
            >
              <option value="Order Pending">Order Pending</option>
              <option value="Rerouting Attempt">Rerouting Attempt</option>
              <option value="Order Modification">Order Modification</option>
              <option value="Order Cancellation">Order Cancellation</option>
              <option value="Fraud Calling">Fraud Calling</option>
              <option value="Bank Details Update/Verification">Bank Details Update/Verification</option>
              <option value="Other">Other</option>
            </select>
          </Col>
        </Row>
        {
          (callPurpose === 'Other')
          && (
            <Row className="pt-2 mt-2">
              <Col xs={24} className="pt-2 d-flex">
                <input
                  placeholder="Enter Call Purpose"
                  type="text"
                  name="callComments"
                  value={callComments}
                  className="form-control"
                  onChange={this.handleChangeExotelDetails}
                />
              </Col>
            </Row>
          )
        }
        <Row className="pt-2 mt-2">
          <Col xs={24}>
            <b>Call Comments</b>
            <span className="text-danger">*</span>
          </Col>
          <Col xs={24} className="pt-2">
            <input
              type="text"
              placeholder="Enter Call Comment"
              name="adminMsg"
              value={adminMsg}
              className="form-control"
              onChange={this.handleChangeExotelDetails}
            />
          </Col>
        </Row>

        <Row className="pt-2 mx-2">
          <Col xs={24} className="text-center">
            {processingPatch
              ? (
                <Row className="py-2 d-flex justify-content-center ">
                  <Spinner
                    variant="primary"
                    animation="border"
                  />
                </Row>
              ) : (
                <>
                  <Button
                    variant="primary"
                    block
                    onClick={() => {
                      this.setState({
                        processingPatch: true,
                        patchError: false,
                      }, () => {
                        this.patchExotelCallDetails();
                      });
                    }}
                    disabled={
                      !callPurpose || !adminMsg
                    }
                  >
                    Submit
                  </Button>
                  <div className="py-2 text-danger">
                    {(!processingPatch && patchError)
                      && (
                        <b>Oops Something went wrong!!</b>
                      )}
                  </div>
                  {
                    patchSuccess
                    && (
                      <Row className="py-2 justify-content-center">
                        Call purpose submitted successfully
                      </Row>
                    )
                  }
                </>
              )}
          </Col>
        </Row>
      </Container>
    );
  }
}

PatchExotelCallDetails.propTypes = {
  onHide: PropTypes.func.isRequired,
  orderId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  action: PropTypes.string.isRequired,
  phoneNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

PatchExotelCallDetails.defaultProps = {
  orderId: '',
};

export default PatchExotelCallDetails;
