import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import { CustomModal, CustomTable } from '../../../component/common';

const ViewPrescription = (props) => {
  const { show, onHide, prescriptionList } = props;

  const headers = [
    {
      key: 'image',
      displayText: 'Image',
      renderer: (data) => (
        <div
          className="product-image-div"
        >
          <img
            src={data.url}
            alt=""
            className="w-100 h-100"
          />
        </div>
      ),
    },
    {
      key: 'imageUrl',
      displayText: 'URL',
      renderer: (data) => (
        <Button
          variant="link"
          className="fs-01 pt-3 px-0"
          onClick={() => {
            window.open(data.url);
          }}
        >
          {data.url}
        </Button>
      ),
    },
  ];

  const body = (
    <Container
      className="py-4"
    >
      <Row>
        <Col
          xs={24}
          className="p-0"
        >
          <CustomTable
            headers={headers}
            content={prescriptionList}
            isPaginated={false}
            totalItems={prescriptionList.length}
          />
        </Col>
      </Row>
    </Container>
  );

  return (
    <CustomModal
      show={show}
      title="View Prescription"
      onHide={onHide}
      size="lg"
      body={body}
      closeButton
    />
  );
};

ViewPrescription.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  prescriptionList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ViewPrescription;
