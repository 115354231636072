import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import Svg from '../../component/common/svg/File';
import { Constant } from '../../utilities';
import { CustomTable, ErrorHandler } from '../../component/common';
import { ProcessingStatus } from '../../component/derived/table-list';
import { dateString, useStateWithCallback } from '../../utilities/Utils';
import { storePinning } from '../../assets/api/axios';
import UnpinnedStores from './UnpinnedStores';
import permission from '../../access&permissions/permission';

const getFileIcon = (link) => (link
  ? (
    <Button
      variant="link"
      className="p-0 fs-01"
      onClick={() => {
        window.open(link, '_blank');
      }}
    >
      <Svg
        width="1.3rem"
        fill={Constant.Color.PRIMARY}
      />
    </Button>
  ) : null
);

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let { l, p } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    ...param,
    l,
    p,
  });
};

function StoreManagement(props) {
  const { history, userPermission } = props;
  const { pathname } = history.location;
  const param = getQueryParams(history.location.search);

  const [state, setState] = useStateWithCallback({
    uploadType: 'stores',
    csvFile: '',
    uploadingStatus: '',
    uploadError: '',
    downloadFileStatus: '',
    uploadedFiles: null,
    loadingStatus: '',
    showUnpinnedStore: false,
    rowPerPage: param?.l,
    page: param?.p,
    params: param,
  });
  const {
    uploadType, loadingStatus, uploadedFiles, params,
    rowPerPage, page, csvFile, uploadingStatus, uploadError,
    downloadFileStatus, showUnpinnedStore,
  } = state;

  const hiddenFileInput = React.createRef();
  const canUpload = userPermission.includes(permission.STORE_MANAGEMENT_WRITE);

  const handleLoad = (data = {}) => {
    setState({
      ...state,
      loadingStatus: 'loading',
    });
    const newParams = { ...params, ...data };
    const { l, p } = newParams;
    storePinning(
      'GET',
      null,
      uploadType,
      {
        page: p,
        limit: l,
      },
    ).then((res) => {
      setState({
        ...state,
        uploadedFiles: res.data,
        loadingStatus: '',
        params: newParams,
        csvFile: '',
      }, () => {
        if (Object.keys(newParams).find((key) => (newParams[key] !== params[key]))) {
          Object.keys(newParams).forEach((item) => {
            if (!newParams[item]) {
              delete newParams[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParams),
          });
        }
      });
    }).catch(() => {
      setState({
        ...state,
        loadingStatus: 'error',
      });
    });
  };

  const handleRequestProcessing = (data = {}) => {
    setState({
      ...state,
      loadingStatus: 'loading',
    }, () => {
      handleLoad(data);
    });
  };

  useEffect(() => {
    handleLoad({ p: 1, l: 10 });
    // eslint-disable-next-line
  }, [uploadType]);

  const handleOption = (e) => {
    setState({
      ...state,
      uploadType: e.target.id,
      uploadedFiles: '',
      csvFile: '',
      uploadingStatus: '',
      downloadFileStatus: '',
    });
  };

  const onNext = () => {
    if (params.p + 1 !== params.p) {
      handleRequestProcessing({ p: params.p + 1 });
    }
  };

  const onPrev = () => {
    if (params.p - 1 !== params.p) {
      handleRequestProcessing({ p: params.p - 1 });
    }
  };

  const handleRowsPageInput = (value, field) => {
    if (field === 'page') {
      setState({
        ...state,
        page: value,
      });
    } else {
      setState({
        ...state,
        rowPerPage: value,
      });
    }
  };

  const onSubmitRowsPerPage = () => {
    if (params.l !== rowPerPage) {
      handleRequestProcessing({ l: rowPerPage });
    }
  };

  const onSubmitPage = () => {
    if (params.p !== page) {
      handleRequestProcessing({ p: page });
    }
  };

  const handleCsvUpload = (retry = 3) => {
    setState({
      ...state,
      uploadingStatus: 'uploading',
    });
    let data = {};
    const formData = new FormData();
    formData.append('csv', csvFile);
    data = formData;
    storePinning(
      'POST',
      data,
      uploadType,
    ).then(() => {
      setState({
        ...state,
        uploadingStatus: 'uploaded',
        csvFile: '',
      });
      setTimeout(() => {
        setState({
          ...state,
          uploadingStatus: '',
          csvFile: '',
        });
        handleRequestProcessing({ p: 1, l: 10 });
      }, 1000);
    }).catch((err) => {
      if (retry > 1) {
        handleCsvUpload(retry - 1);
        return;
      }
      let errorMsg = 'Oops something went wrong. Retry!';
      if (err?.response?.data?.errors[0]?.message) {
        errorMsg = err.response.data.errors[0].message;
      }
      setState({
        ...state,
        uploadingStatus: 'failed',
        uploadError: errorMsg,
      });
    });
  };

  const handleDownloadFile = () => {
    setState({
      ...state,
      downloadFileStatus: 'loading',
    });
    storePinning(
      'GET',
      null,
      `download/${uploadType}`,
      {},
    ).then((res) => {
      const { fileUrl } = res.data.data;
      setState({
        ...state,
        downloadFileStatus: '',
      });
      window.open(fileUrl, '_self');
    }).catch(() => {
      setState({
        ...state,
        downloadFileStatus: 'error',
      });
    });
  };

  const headers = [
    {
      key: 'id',
      displayText: 'Id',
    },
    {
      key: 'createdAt',
      displayText: 'Uploaded On',
      renderer: (data) => dateString(data.createdAt) || '--',
    },
    {
      key: 'uploadedBy',
      displayText: 'User',
    },
    {
      key: 'uploadedFileUrl',
      displayText: 'Uploaded File',
      renderer: (data) => getFileIcon(data.uploadedFileUrl),
    },
    {
      key: 'status',
      displayText: 'Status',
    },
    {
      key: 'successCount',
      displayText: 'Success Count',
    },
    {
      key: 'responseFileUrl',
      displayText: 'Response File',
      renderer: (data) => getFileIcon(data.responseFileUrl),
    },
  ];

  return (
    <Container
      fluid
      className="h-100 bg-white"
    >
      {
        showUnpinnedStore && (
          <UnpinnedStores
            show={showUnpinnedStore}
            onHide={() => setState({ ...state, showUnpinnedStore: false })}
          />
        )
      }
      <Row className="py-2">
        <Col>
          <Container>
            <Row>
              <Col>
                <input
                  type="radio"
                  id="stores"
                  checked={uploadType === 'stores'}
                  onChange={handleOption}
                  className="fs-1"
                  disabled={uploadingStatus === 'uploading'}
                />
                <span className="pl-1 fs-1 font-weight-bold">Pin Stores</span>
              </Col>
              <Col>
                <input
                  type="radio"
                  id="recommended-stores"
                  checked={uploadType === 'recommended-stores'}
                  onChange={handleOption}
                  className="fs-1"
                  disabled={uploadingStatus === 'uploading'}
                />
                <span className="pl-1 fs-1 font-weight-bold">Pin Recommended Store</span>
              </Col>
              <Col>
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    setState({
                      ...state,
                      showUnpinnedStore: true,
                    });
                  }}
                >
                  Unpinned Stores
                </Button>
              </Col>
            </Row>
            <Row className="pt-4 pb-1">
              <Col>
                <span className="font-weight-bold">Upload File</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <input
                  type="file"
                  className="d-none"
                  accept=".xls,.xlsx,.csv"
                  ref={hiddenFileInput}
                  onChange={(e) => {
                    setState({
                      ...state,
                      csvFile: e.target.files[0],
                      uploadingStatus: '',
                      uploadError: '',
                    });
                  }}
                />
                <Button
                  variant="primary"
                  className="fs-01 font-weight-bold mr-5"
                  onClick={() => {
                    hiddenFileInput.current.click();
                  }}
                  disabled={!canUpload || uploadingStatus === 'uploading'}
                >
                  Choose csv
                </Button>
                <Button
                  variant="primary"
                  className="fs-01 font-weight-bold"
                  onClick={handleCsvUpload}
                  disabled={!csvFile || uploadingStatus === 'uploading'}
                >
                  Upload
                </Button>
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <span
                  className="fs-1 font-weight-bold pr-2"
                >
                  Download Latest Ranking File
                </span>
                <Button
                  variant="link"
                  className="p-0"
                  onClick={handleDownloadFile}
                  disabled={downloadFileStatus === 'loading'}
                >
                  <Svg
                    width="1.3rem"
                    fill={downloadFileStatus === 'loading' ? Constant.Color.BLACK : Constant.Color.PRIMARY}
                  />
                </Button>
                &nbsp;&nbsp;
                {
                  downloadFileStatus === 'loading' && (
                    <Spinner
                      animation="border"
                      variant="primary"
                      size="sm"
                    />
                  )
                }
                {
                  downloadFileStatus === 'error' && (
                    <span className="fs-0 text-danger">Retry</span>
                  )
                }
              </Col>
            </Row>
            <span className="fs-0 text-success mr-2">{csvFile?.name}</span>
            {
              uploadingStatus === 'failed' && (
                <span className="fs-0 text-danger">
                  {uploadError}
                </span>
              )
            }
            {
              uploadingStatus === 'uploaded' && (
                <span className="fs-0 text-success">
                  File uploaded successfully !!
                </span>
              )
            }
          </Container>
        </Col>
      </Row>
      <Row className="flex-column pt-3">
        {(() => {
          let showcase = null;
          if (!uploadedFiles && loadingStatus === 'loading') {
            showcase = (
              <div
                className="d-flex align-items-center
                justify-content-center"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (!uploadedFiles && loadingStatus === 'error') {
            showcase = (
              <div
                className="d-flex align-items-center
                justify-content-center"
              >
                <ErrorHandler
                  retryLogic={() => handleRequestProcessing()}
                />
              </div>
            );
          } else if (uploadedFiles) {
            showcase = (
              <>
                <ProcessingStatus
                  show={loadingStatus === 'loading' || loadingStatus === 'error'}
                  loading={loadingStatus === 'loading'}
                  error={loadingStatus === 'error'}
                  onRetry={() => handleRequestProcessing()}
                  onCancel={() => {
                    setState({
                      ...state,
                      loadingStatus: '',
                    });
                  }}
                />
                <Col className="px-0 pt-4">
                  <span className="fs-1 font-weight-bold p-2">Upload History</span>
                  <CustomTable
                    headers={headers}
                    content={uploadedFiles?.data}
                    keyField="id"
                    totalItems={uploadedFiles?.count}
                    l={params.l}
                    p={params.p}
                    rowsPerPage={rowPerPage}
                    page={page}
                    hasPrev={!!uploadedFiles?.prev}
                    hasNext={!!uploadedFiles?.next}
                    onNext={onNext}
                    onPrev={onPrev}
                    onSubmitPage={onSubmitPage}
                    onSubmitRowsPerPage={onSubmitRowsPerPage}
                    updateRowsPageInput={handleRowsPageInput}
                  />
                </Col>
              </>
            );
          }
          return showcase;
        })()}
      </Row>
    </Container>
  );
}

StoreManagement.propTypes = {
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default StoreManagement;
