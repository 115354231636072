import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Button, Container, Spinner, Row, Col,
  InputGroup, Form,
} from 'react-bootstrap';
import { retailerProfile } from '../../assets/api/axios';
import { CustomTable, CustomModal } from '../../component/common';
import Svg from '../../component/common/Svg';
import Constant from '../../utilities/Constant';
import RetailerStoryForm from '../../layouts/review-panal/RetailerStoryForm';
import { dateString, retailerProfileMetaData } from '../../utilities/Utils';
import Permission from '../../access&permissions/permission';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let { l, p } = param;
  const { sid = '' } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    l,
    p,
    sid,
  });
};

class RetailerStoryReview extends Component {
  constructor(props) {
    super(props);
    const { history } = this.props;
    const params = getQueryParams(history.location.search);
    const { userPermission } = props;
    this.canIEdit = userPermission
      && userPermission.includes(Permission.RETAILER_STORY_REVIEW_WRITE);
    this.state = {
      retailerOwnStories: null,
      storeId: params.sid,
      rowsPerPage: params.l,
      page: params.p,
      params,
      loading: true,
      error: false,
      showStory: false,
      showStoryStoreId: '',
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount() {
    this.handleLoad();
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { params } = this.state;
    const newParams = { ...params, ...data };
    const offset = (newParams.p - 1) * newParams.l;
    const { l, sid } = newParams;
    retailerProfile(
      'GET',
      sid || null,
      {
        page: `${offset || 1}`,
        limit: l,
      },
      null,
      this.source.token,
    ).then((res) => {
      this.setState({
        retailerOwnStories: sid && res.data.data.isCreated
          ? {
            count: 1,
            next: false,
            prev: false,
            data: [res.data.data],
          } : res.data,
        rowsPerPage: newParams.l,
        page: newParams.p,
        params: newParams,
        loading: false,
      }, () => {
        if (Object.keys(newParams).find((key) => newParams[key] !== params[key])) {
          Object.keys(newParams).forEach((key) => {
            if (!newParams[key]) {
              delete newParams[key];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParams),
          });
        }
      });
    }).catch(() => {
      this.setState({
        error: true,
      });
    });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad(newParams);
      });
    };
  }

  handleRequestProcessing = (data) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      loading: true,
      error: false,
    }, () => {
      this.handleLoad(data);
    });
  }

  onNext = () => {
    const { params } = this.state;
    this.handleRequestProcessing({ p: params.p + 1 });
  }

  onPrev = () => {
    const { params } = this.state;
    this.handleRequestProcessing({ p: params.p - 1 });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onSubmitPage = () => {
    const { params, page } = this.state;
    if (params.p !== page) {
      this.handleRequestProcessing({ p: page });
    }
  }

  onSubmitRowsPerPage = () => {
    const { params, rowsPerPage } = this.state;
    if (params.l !== rowsPerPage) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onSubmitStoreId = () => {
    const { storeId, params } = this.state;
    if (storeId !== params.sid) {
      this.handleRequestProcessing({ sid: storeId });
    }
  }

  render() {
    const {
      retailerOwnStories, rowsPerPage, page, params,
      loading, error, storeId, showStory, showStoryStoreId,
    } = this.state;
    const { userPermission } = this.props;

    const headers = [
      {
        key: 'storeCode',
        displayText: 'Shop Id',
        renderer: (data) => (
          <Link
            to={`/retailer/${data.storeCode}`}
            className="text-primary cursor-pointer"
          >
            {data.storeCode}
          </Link>
        ),
      },
      {
        key: 'storeName',
        displayText: 'Shop Name',
      },
      {
        key: 'profileCreatedOn',
        displayText: 'Created On',
        renderer: (data) => (
          <>{dateString(data.profileCreatedOn)}</>
        ),
      },
      {
        key: 'isCompleted',
        displayText: 'Completed',
        renderer: (data) => (
          <div
            className={data.isCompleted ? 'text-green' : 'text-danger'}
          >
            {data.isCompleted ? 'YES' : 'NO'}
          </div>
        ),
      },
      {
        key: 'isApproved',
        displayText: 'Approved',
        renderer: (data) => (
          <div
            className={data.isApproved ? 'text-green' : 'text-danger'}
          >
            {data.isApproved ? 'YES' : 'NO'}
          </div>
        ),
      },
      {
        key: 'pendingQuestions',
        displayText: 'Pending Questions',
        renderer: (data) => (
          <div>
            {(
              Object.keys(retailerProfileMetaData).filter(
                (x) => !(data.completedPages || []).includes(x),
              )
            ).join(', ') || 'NA'}
          </div>
        ),
      },
      ...this.canIEdit ? [{
        key: 'viewStory',
        displayText: 'Retailer Story',
        renderer: (data) => (
          <Col
            className="text-primary cursor-pointer"
            onClick={() => {
              this.setState({ showStory: true, showStoryStoreId: data.storeCode });
            }}
          >
            VIEW
          </Col>
        ),
      }] : [],
    ];

    if (loading) {
      return (
        <div
          className="pt-3 text-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (error) {
      return (
        <div
          className="pt-3 text-center"
        >
          <span
            className="text-danger"
          >
            Something Went Wrong
          </span>
          <div>
            <Button
              variant="primary"
              onClick={() => this.retry()}
            >
              Retry
            </Button>
          </div>
        </div>
      );
    }

    return (
      <Container
        fluid
        className="bg-white"
      >
        <CustomModal
          show={showStory}
          body={(
            <RetailerStoryForm
              approving
              editing={false}
              storeId={showStoryStoreId}
              userPermission={userPermission}
            />
          )}
          title="Retailer Story"
          closeButton
          onHide={() => {
            this.setState({
              showStory: false,
            });
          }}
          size="lg"
        />
        <Row
          fluid
          className="p-2"
        >
          <Col
            xs="auto"
            className="px-0"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Store Id"
                name="storeId"
                className="fs-01 rounded-0"
                value={storeId}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.onSubmitStoreId();
                  }
                }}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row
          fluid
        >
          {
            retailerOwnStories && (
              <CustomTable
                keyField="id"
                headers={headers}
                content={retailerOwnStories.data}
                isPaginated
                totalItems={retailerOwnStories.count}
                hasPrev={!!retailerOwnStories.prev}
                hasNext={!!retailerOwnStories.next}
                l={params.l}
                p={params.p}
                rowsPerPage={rowsPerPage}
                page={page}
                onNext={this.onNext}
                onPrev={this.onPrev}
                updateRowsPageInput={this.handleRowsPageInput}
                onSubmitPage={this.onSubmitPage}
                onSubmitRowsPerPage={this.onSubmitRowsPerPage}
              />
            )
          }
        </Row>
      </Container>
    );
  }
}

RetailerStoryReview.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default RetailerStoryReview;
