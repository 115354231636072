import React from 'react';
import PropTypes from 'prop-types';

const ShopIcon = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      stroke="#999"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1.333 4.5h13.334v2.667l-.466.28a2.334 2.334 0 0 1-2.401 0l-.467-.28-.466.28a2.333 2.333 0 0 1-2.4 0L8 7.167l-.466.28a2.333 2.333 0 0 1-2.401 0l-.466-.28-.467.28a2.333 2.333 0 0 1-2.4 0l-.467-.28V4.5Z"
    />
    <path
      stroke="#999"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.667 7.996v7.17h10.666V7.834M2.667 4.441V1.833h10.666V4.5"
    />
    <path
      stroke="#999"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.333 11.167h3.334v4H6.333v-4Z"
    />
  </svg>
);

ShopIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

ShopIcon.defaultProps = {
  width: 16,
  height: 17,
};

export default ShopIcon;
