import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 16 16"
  >
    <path
      fill={fill}
      d="M4.115 4.181v8.724h6.66V14h-6.66C3.499 14 3 13.51 3 12.905V4.18h1.115zM10.445 2L13 4.505v6.223c0 .564-.435 1.029-.993 1.088l-.122.007h-5.55c-.296 0-.58-.116-.788-.32-.18-.177-.291-.407-.32-.652l-.007-.123V3.095c0-.564.435-1.03.994-1.089L6.335 2h4.11zm-.78 1.095h-3.33v7.629h5.546l.004-5.448h-2.22v-2.18z"
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export default Svg;
