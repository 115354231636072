import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { CustomModal, CustomTable } from '../../component/common';
import { dateString } from '../../utilities/Utils';

function PhoneNumberLogs(props) {
  const { show, onHide, phoneLogs } = props;

  const body = (
    <Container
      fluid
      className="py-4"
    >
      <Row>
        {
          (phoneLogs && phoneLogs.length > 0) ? (
            <Col className="px-0 overflow-y minh-640p">
              <CustomTable
                headers={[
                  {
                    key: 'phoneNumber',
                    displayText: 'Phone Number',
                  },
                  {
                    key: 'modifiedOn',
                    displayText: 'Modified On',
                    renderer: (data) => dateString(data.modifiedOn) || '--',
                  },
                ]}
                content={phoneLogs}
                keyField="phoneNumber"
                totalItems={phoneLogs.length}
                isPaginated={false}
              />
            </Col>
          ) : (
            <Col className="d-flex justify-content-center align-item-center">
              Not Available any Phone Number Change Logs
            </Col>
          )
        }
      </Row>
    </Container>
  );

  return (
    <CustomModal
      show={show}
      onHide={onHide}
      title="Phone Number Change Logs"
      body={body}
      closeButton
      autoSize
    />
  );
}

PhoneNumberLogs.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  phoneLogs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PhoneNumberLogs;
