import React from 'react';
import PropTypes from 'prop-types';

const WhatsApp = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.05 23.794h-.005c-2 0-3.965-.5-5.71-1.448L0 24l1.695-6.163A11.828 11.828 0 0 1 .1 11.892C.103 5.335 5.463 0 12.05 0a11.901 11.901 0 0 1 8.453 3.488A11.792 11.792 0 0 1 24 11.9c-.003 6.556-5.361 11.89-11.95 11.893Z"
      fill="url(#a)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.045 23.794h.005C18.64 23.79 23.997 18.457 24 11.9a11.792 11.792 0 0 0-3.497-8.413A11.901 11.901 0 0 0 12.05 0C5.463 0 .103 5.335.1 11.892c0 2.096.55 4.142 1.595 5.945L0 24l6.335-1.654a11.984 11.984 0 0 0 5.71 1.448Zm-5.417-3.6.363.213a9.954 9.954 0 0 0 5.055 1.378h.004c5.475 0 9.93-4.434 9.932-9.884a9.801 9.801 0 0 0-2.906-6.994 9.889 9.889 0 0 0-7.022-2.898c-5.478 0-9.934 4.434-9.936 9.884 0 1.867.524 3.686 1.518 5.26l.237.374-1.004 3.648 3.76-.982Zm11.443-5.465c-.06-.1-.201-.167-.41-.267l-.163-.08c-.298-.149-1.766-.867-2.04-.967-.274-.099-.473-.148-.672.15-.199.296-.771.966-.945 1.164-.174.199-.348.223-.647.075a4.417 4.417 0 0 0-.197-.089c-.42-.181-1.25-.54-2.204-1.386-.888-.788-1.487-1.761-1.661-2.059-.174-.297-.019-.458.13-.606.09-.089.193-.213.296-.338.051-.063.103-.125.153-.183.126-.147.181-.259.256-.41l.042-.086c.1-.198.05-.371-.025-.52-.05-.1-.334-.787-.59-1.41l-.33-.797c-.214-.511-.431-.51-.605-.509h-.067C8.218 6.4 8.019 6.4 7.82 6.4a1.1 1.1 0 0 0-.797.372l-.058.062c-.311.33-.987 1.048-.987 2.417 0 1.455 1.06 2.86 1.217 3.07l.003.004.052.073c.357.51 2.249 3.21 5.048 4.414.713.306 1.269.489 1.702.625.716.227 1.367.195 1.881.118.574-.085 1.767-.718 2.015-1.413.25-.694.25-1.289.175-1.413Z"
      fill="#FDFDFD"
    />
    <defs>
      <linearGradient
        id="a"
        x1={-0.335}
        y1={24.333}
        x2={23.831}
        y2={0.051}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#24B43B" />
        <stop offset={1} stopColor="#54CF61" />
      </linearGradient>
    </defs>
  </svg>
);

WhatsApp.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default WhatsApp;
