/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Form, Button, InputGroup, Spinner,
} from 'react-bootstrap';
import QueryString from 'query-string';
import { Svg } from '../component/common';
import { Constant, Storage, Utils } from '../utilities';
import { authLogin, authRegister, userLogin } from '../assets/api/axios';
import Token from '../assets/auth/Token';
import initiateFirebase from '../integrations/firebase/Firebase';
import { iniializeFirebaseMessaging } from '../integrations/firebase/Messaging';
import ErrorFeedback from '../utilities/ErrorFeedback';

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      show: false,
      loading: false,
      error: null,
      loginStep: '',
      googleOtp: '',
      authenticator_link: '',
      qrGetStatus: '',
      otpVerificationStatus: '',
    };
  }

  componentDidMount = () => {
    const { history } = this.props;
    const loginStep = Storage.getLoginStep();
    this.setState({
      loginStep,
    }, () => {
      switch (loginStep) {
        case 'initial_logged_in':
          Storage.setLoginStep('auth_otp_screen');
          this.setState({
            loginStep: 'auth_otp_screen',
          });
          break;

        case 'google_authenticated':
          if (Utils.isAuthenticated()) {
            history.push('/');
          } else {
            Storage.setStoreCategory('');
            this.setState({
              loginStep: '',
            });
          }
          break;
        default:
          break;
      }
    });
  }

  handleChange = (e) => {
    const { error } = this.state;
    const { id, value } = e.target;
    const newError = error;
    if (newError && newError[id]) {
      delete newError[id];
    }
    this.setState({
      [id]: value,
      error: newError,
    });
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    const { password, username } = this.state;
    let error = null;
    if (!username) {
      error = {};
      error.username = ErrorFeedback.userName.missing;
      this.setState({ error });
      return;
    }
    if (!password) {
      error = {};
      error.password = ErrorFeedback.passMiss.missing;
      this.setState({ error });
      return;
    }
    this.setState({
      loading: true,
      error: null,
    }, () => {
      this.onLogin();
    });
  }

  toggleVisibility = () => {
    const { show } = this.state;
    this.setState({
      show: !show,
    });
  }

  onLogin = () => {
    const { updateUserAuthorization } = this.props;
    const { username, password } = this.state;
    userLogin(
      'post',
      {
        username,
        password,
        clientType: 'CRM',
      },
      {},
    )
      .then((res) => {
        const {
          username: user, phoneNumber, sessionToken,
          permissionsSet, allowAuthenticatorRegistration,
        } = res.data;
        Storage.setToken(Token);
        Storage.setUserName(user);
        Storage.setUserNumber(phoneNumber);
        Storage.setUserSession(sessionToken);
        Storage.setUserPermissions(permissionsSet);
        Storage.setUser(res.data);
        Storage.setLastLoggedInAtEpoch(new Date().getTime());
        Storage.setLoginStep('initial_logged_in');
        initiateFirebase();
        iniializeFirebaseMessaging();
        updateUserAuthorization();
        this.setState({
          loading: false,
          loginStep: 'initial_logged_in',
        }, () => {
          if (allowAuthenticatorRegistration) {
            this.getQRScanner(user);
          } else {
            Storage.setLoginStep('auth_otp_screen');
            this.setState({
              loginStep: 'auth_otp_screen',
            });
          }
        });
      })
      .catch((e) => {
        const error = {
          request: 'Something went wrong',
        };
        if (e.status === 400) {
          error.request = 'Invalid username or password';
        }
        this.setState({
          loading: false,
          error,
        });
      });
  }

  getQRScanner = (username) => {
    this.setState({
      qrGetStatus: 'loading',
    });
    authRegister(
      'post',
      {
        username,
      },
      {},
    ).then((res) => {
      const { data } = res.data;
      this.setState({
        qrGetStatus: 'success',
        authenticator_link: data.authenticator_link,
      });
    }).catch((e) => {
      const error = {
        request: 'Something went wrong',
      };
      if (e.response && e.response.data && e.response.status === 400) {
        error.request = JSON.stringify(e.response.data.errors);
      }
      this.setState({
        qrGetStatus: 'error',
        error,
      });
    });
  }

  submitAuthenticatorOtp = () => {
    const { googleOtp } = this.state;
    const { history } = this.props;
    const userName = Storage.getUserName();
    const param = QueryString.parse(history.location.search);
    this.setState({
      otpVerificationStatus: 'loading',
    });
    authLogin(
      'post',
      {
        username: userName,
        otp: googleOtp,
      },
      {},
    ).then(() => {
      Storage.setLoginStep('google_authenticated');
      this.setState({
        otpVerificationStatus: 'success',
      });
      history.push(param.location || '/');
    }).catch((e) => {
      const error = {
        request: 'Something went wrong',
      };
      if (e.response && e.response.data && e.response.status === 400) {
        error.request = JSON.stringify(e.response.data.errors);
      }
      this.setState({
        otpVerificationStatus: 'error',
        error,
      });
    });
  }

  render() {
    const {
      username, password, show, loading, error, loginStep,
      otpVerificationStatus, qrGetStatus, authenticator_link,
      googleOtp,
    } = this.state;
    return (
      <Container
        className="h-100"
      >
        <Row
          className="h-100 align-items-center
            justify-content-center"
        >
          <Col
            xs={20}
            sm={16}
            md={12}
            lg={8}
            className="bg-white shadow"
          >
            {(() => {
              let authScreen = null;
              if (!loginStep) {
                authScreen = (
                  <div>
                    <div
                      className="mt-3 text-primary"
                    >
                      Login to Admin Dashboard
                    </div>
                    <Form
                      onSubmit={this.onFormSubmit}
                      className="py-3"
                    >
                      <Form.Group
                        controlId="username"
                      >
                        <Form.Label>
                          Username
                        </Form.Label>
                        <Form.Control
                          type="text"
                          variant="danger"
                          placeholder="username"
                          value={username}
                          onChange={this.handleChange}
                          className="fs-0"
                          autoComplete="off"
                          size="lg"
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              e.preventDefault();
                              document.getElementById('password').focus();
                            }
                          }}
                          isInvalid={error && error.username}
                        />
                        {error && error.username && (
                        <div
                          className="fs-0 text-danger mb-1"
                        >
                          {error.username}
                        </div>
                        )}
                      </Form.Group>
                      <Form.Group
                        controlId="password"
                      >
                        <Form.Label>
                          Password
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            type={show ? 'text' : 'password'}
                            placeholder="password"
                            value={password}
                            onChange={this.handleChange}
                            className="fs-0"
                            autoComplete="off"
                            size="lg"
                            isInvalid={error && error.password}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="light"
                              className="border"
                              onClick={this.toggleVisibility}
                            >
                              <Svg
                                svg="eye"
                                width="1.2rem"
                                fill={show
                                  ? Constant.Color.PRIMARY
                                  : Constant.Color.MEDIUM}
                              />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        {error && error.password && (
                        <div
                          className="fs-0 text-danger mb-1"
                        >
                          {error.password}
                        </div>
                        )}
                      </Form.Group>
                      {loading
                        ? (
                          <Spinner
                            animation="border"
                            variant="primary"
                          />
                        )
                        : (
                          <Button
                            block
                            type="submit"
                            className="fs-0"
                          >
                            NEXT
                          </Button>
                        )}
                      {error && error.request && (
                      <div
                        className="text-danger mt-1 fs-0"
                      >
                        {error.request}
                      </div>
                      )}
                    </Form>
                  </div>
                );
              } else if (loginStep === 'initial_logged_in') {
                authScreen = (
                  <div
                    className="py-3 bg-white"
                  >
                    {
                      qrGetStatus === 'loading' && (
                        <div
                          className="text-center"
                        >
                          <Spinner
                            variant="primary"
                            animation="border"
                          />
                        </div>
                      )
                    }

                    {
                      qrGetStatus === 'error' && (
                        <div
                          className="text-center text-center"
                        >
                          {error && error.request}
                        </div>
                      )
                    }

                    {
                      (qrGetStatus === 'success' && authenticator_link) && (
                        <>
                          <div
                            className="text-center"
                          >
                            Download the free
                            &nbsp;
                            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN&gl=US">
                              Google Authenticator
                            </a>
                            &nbsp;
                            app, add a new account, and then scan this barcode to set your profile.
                          </div>
                          <div
                            className="pt-3 d-flex justify-container-center"
                          >
                            <div>
                              <div
                                className="fs-0 text-info text-center"
                              >
                                Please Scan this QR code before you click on NEXT Button
                              </div>
                              <div
                                className="pt-1 d-flex justify-content-center align-items-center"
                              >
                                <img
                                  src={authenticator_link}
                                  alt="qr-code"
                                  width="200px"
                                  height="200px"
                                />
                              </div>
                              <div
                                className="pt-4"
                              >
                                <Button
                                  block
                                  variant="primary"
                                  onClick={() => {
                                    Storage.setLoginStep('auth_otp_screen');
                                    this.setState({
                                      error: {},
                                      loginStep: 'auth_otp_screen',
                                    });
                                  }}
                                >
                                  NEXT
                                </Button>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    }
                  </div>
                );
              } else if (loginStep === 'auth_otp_screen') {
                authScreen = (
                  <div
                    className="py-3 bg-white"
                  >
                    <div
                      className="text-center"
                    >
                      Enter the OTP generated on the Google Authenticator app.
                      If you do not have Google Authenticator app, Please reach out to support team.
                    </div>
                    <div
                      className="pt-3"
                    >
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter OTP"
                        value={googleOtp}
                        onChange={(event) => {
                          this.setState({
                            googleOtp: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div
                      className="pt-4"
                    >
                      <Button
                        block
                        variant="primary"
                        disabled={!googleOtp || otpVerificationStatus === 'loading'}
                        onClick={() => {
                          this.submitAuthenticatorOtp();
                        }}
                      >
                        SUBMIT
                      </Button>
                    </div>
                    <div
                      className="pt-1 text-danger text-center"
                    >
                      {error && error.request}
                    </div>
                  </div>
                );
              }
              return authScreen;
            })()}
          </Col>
        </Row>
      </Container>
    );
  }
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }).isRequired,
  updateUserAuthorization: PropTypes.func.isRequired,
};

export default Login;
