/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import {
  Button,
  Col, Container, Row, Spinner, Form,
} from 'react-bootstrap';
import {
  CustomModal, ErrorHandler,
} from '../../component/common';
import { citySpecificRewards } from '../../assets/api/axios';

function CreateCityRewards(props) {
  const {
    show, onHide, editReward, createReward, refresh, reward, cities,
  } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [city, setCity] = useState((editReward && reward.city) || []);
  const [id, setId] = useState((editReward && reward.id) || '');
  const [rewardAmount, setRewardAmount] = useState((editReward && reward.rewardAmount) || '');
  const [isActive, setIsActive] = useState((editReward && reward.isActive) || '');

  function reset() {
    setCity([]);
    setRewardAmount('');
    setIsActive(!isActive);
    setSubmitting(false);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitting(true);
    setSubmitError(null);

    let data = {
      city: city.label,
      rewardAmount,
    };

    if (editReward) {
      data = {
        id,
        city: city.label,
        rewardAmount,
        isActive,
      };
    }

    citySpecificRewards(
      editReward ? 'PATCH' : 'POST',
      {},
      { ...data },
    )
      .then((res) => {
        if (res.status === 200) {
          onHide();
          refresh();
          reset();
          setSubmitting(false);
        } else {
          throw new Error('Submission failed');
        }
      })
      .catch((err) => {
        let errorMsg = 'Oops! Something went wrong';
        if (err.response && err.response.status === 400 && err.response.data.error) {
          errorMsg = err.response.data.error;
        }
        setSubmitError(errorMsg);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  const body = (
    <Container>
      <Row>
        {(() => {
          let showcase = null;
          if (editReward && loading) {
            showcase = (
              <Col>
                <div
                  className="h-100 d-flex align-items-center
                  justify-content-center"
                >
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                </div>
              </Col>
            );
          } else if (editReward && !loading && error) {
            showcase = (
              <Col>
                <div
                  className="h-100 d-flex align-items-center
                  justify-content-center"
                >
                  <ErrorHandler
                    retryLogic={() => {
                    //   refresh();
                    }}
                  />
                </div>
              </Col>
            );
          } else {
            showcase = (
              <Col
                className="mx-3 py-3"
              >
                <Form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >

                  { editReward && (
                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Id:</b>
                    </Form.Label>
                    <Col
                      sm={12}
                    >
                      <Form.Label column sm={6}>
                        {id}
                      </Form.Label>
                    </Col>
                  </Form.Group>
                  )}

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>City Name:</b>
                    </Form.Label>
                    {editReward ? (
                      <Col
                        sm={12}
                      >
                        <Form.Label column sm={6}>
                          {city}
                        </Form.Label>
                      </Col>
                    )
                      : (
                        <Col
                          sm={12}
                        >
                          <Select
                            id="city"
                            onChange={(e) => {
                              setCity(e);
                            }}
                            value={city}
                            options={Object.keys(cities)
                              .map((item) => ({ label: cities[item], value: item }))}
                            required
                          />
                        </Col>
                      )}
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Reward Amount:</b>
                    </Form.Label>
                    <Col
                      sm={12}
                    >
                      <Form.Control
                        value={rewardAmount}
                        id="rewardAmount"
                        onChange={(e) => {
                          setRewardAmount(e.target.value);
                        }}
                        autoComplete="off"
                        maxLength={128}
                      />
                    </Col>
                  </Form.Group>
                  {
                    editReward && (
                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Is Active:</b>
                      </Form.Label>
                      <Col
                        sm={12}
                      >
                        <div
                          className="custom-control custom-switch"
                        >
                          <input
                            id="isActive"
                            type="checkbox"
                            className="custom-control-input"
                            checked={isActive}
                            onChange={() => {
                              setIsActive(!isActive);
                            }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="isActive"
                          />
                        </div>
                      </Col>
                    </Form.Group>
                    )
                  }
                  <Form.Group as={Row}>
                    <Form.Label column sm={6} />
                    <Col>
                      <Button
                        block
                        type="submit"
                        variant="primary"
                        // disabled={disabled}
                      >
                        {editReward ? 'UPDATE' : 'CREATE'}
                      </Button>
                    </Col>
                  </Form.Group>

                  {
                    submitting && (
                      <div
                        className="d-flex align-item-center justify-content-center"
                      >
                        <Col sm={6} />
                        <Spinner
                          animation="border"
                          variant="primary"
                        />
                      </div>
                    )
                  }
                  {
                    !!submitError && (
                      <div
                        className="d-flex align-item-center justify-content-center"
                      >
                        <Col sm={6} />
                        <span className="text-danger">
                          {submitError}
                        </span>
                      </div>
                    )
                  }
                </Form>
              </Col>
            );
          }
          return showcase;
        })()}
      </Row>
    </Container>
  );

  return (
    <>
      {
        (createReward || editReward) && (
        <CustomModal
          show={show}
          title={editReward ? 'Edit Reward' : 'Create Reward'}
          autoSize
          body={body}
          onHide={() => {
            onHide();
            if (createReward) reset();
          }}
          closeButton
          backdrop
          border
        />
        )
}
    </>
  );
}

CreateCityRewards.propTypes = {
  onHide: PropTypes.func.isRequired,
  refresh: PropTypes.func,
  show: PropTypes.bool.isRequired,
  editReward: PropTypes.bool.isRequired,
  createReward: PropTypes.bool,
  cities: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  reward: PropTypes.shape({
    id: PropTypes.string,
    city: PropTypes.arrayOf(
      PropTypes.string,
    ),
    rewardAmount: PropTypes.number,
    isActive: PropTypes.bool,
  }),
};

CreateCityRewards.defaultProps = {
  reward: '',
  createReward: false,
  refresh: null,
};
export default CreateCityRewards;
