import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 19.447 12.066"
  >
    <path
      d="M260.278,372.956l-.433-2.117h0l-.4-2.031a1.311,1.311,0,0,0-1.307-1.022h-1.745a.537.537,0,1,0,0,1.072h1.745a.187.187,0,0,1,.187.146l.361,1.824-1.613,2.349-3.353.068h0a.2.2,0,0,1-.133-.051.174.174,0,0,1-.057-.128V370.94a.554.554,0,0,0-.57-.536H249.73V367.4a.554.554,0,0,0-.57-.536H244.57a.554.554,0,0,0-.57.536v3.545a.554.554,0,0,0,.57.536h1.538a4.366,4.366,0,0,0-1.456,2.162l-.631,2.161a.511.511,0,0,0,.1.467.583.583,0,0,0,.453.211h1.507a3.211,3.211,0,0,0,6.25-.046l4.807-.071a3.121,3.121,0,0,0,3.141,2.566,2.99,2.99,0,1,0,0-5.97Zm-15.138-5.025h3.451V370.4h-3.451Zm.611,5.991a3.518,3.518,0,0,1,3.41-2.446h3.224v1.59a1.206,1.206,0,0,0,.4.894,1.365,1.365,0,0,0,.958.357l3.655-.074a.579.579,0,0,0,.466-.245l1.13-1.646.593,2.9-14.269.154Zm3.447,3.932a2.023,2.023,0,0,1-1.952-1.377h1.961l1.952-.029a2.021,2.021,0,0,1-1.961,1.406Zm11.077,0a2.011,2.011,0,0,1-1.988-1.511l2-.029a.584.584,0,0,0,.433-.2.513.513,0,0,0,.117-.439l-.335-1.636a1.91,1.91,0,1,1-.225,3.814Zm0,0"
      transform="translate(-244 -366.859)"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
