import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.4 0.300003C1.90294 0.300003 1.5 0.702947 1.5 1.2C1.5 1.69706 1.90294 2.1 2.4 2.1H7.2C8.02843 2.1 8.7 2.77158 8.7 3.6C8.7 4.42843 8.02843 5.1 7.2 5.1H3.3V4.5H7.2C7.69706 4.5 8.1 4.09706 8.1 3.6C8.1 3.10295 7.69706 2.7 7.2 2.7H2.4C1.90294 2.7 1.5 3.10295 1.5 3.6V10.65C1.5 11.1471 1.90294 11.55 2.4 11.55C2.89706 11.55 3.3 11.1471 3.3 10.65V6.9H3.9V10.35C3.9 10.8471 4.30294 11.25 4.8 11.25C5.29706 11.25 5.7 10.8471 5.7 10.35V6.9H7.2C9.02254 6.9 10.5 5.42254 10.5 3.6C10.5 1.77746 9.02254 0.300003 7.2 0.300003H2.4Z"
      fill="#D08F10"
    />
  </svg>

);

Svg.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default Svg;
