import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width, stroke }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    viewBox="0 0 24 24"
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <circle
        cx="12"
        cy="12"
        r="9"
        fill={fill}
        fillRule="nonzero"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.5 9l3 3m-3 3l3-3"
      />
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
};

export default Svg;
