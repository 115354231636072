import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { secondToTimeFormat } from '../../utilities/Utils';

class OrderTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalTimeElapsed: '',
    };
    this.createdOnTimer = -1;
  }

  componentDidMount = () => {
    const { orderDetails } = this.props;
    const deliveryClass = orderDetails?.store?.deliveryClass;
    const orderCreatedOn = orderDetails?.createdOn;
    const shopOpenTime = orderDetails?.store?.shopOpenTime;

    if (deliveryClass !== 'NEXTDAY') {
      this.calculateOrderTime();
    } else {
      this.scheduleTimerForNextDay(shopOpenTime);
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.createdOnTimer);
  }

  scheduleTimerForNextDay = (shopOpenTime) => {
    const now = new Date().getTime();
    // eslint-disable-next-line max-len
    const shopOpenDateTime = new Date(shopOpenTime).getTime() - 330 * 60 * 1000; // shopOpenTime is in IST so subtracting 5 hours 30 minutes

    if (now > shopOpenDateTime) {
      this.calculateOrderTime(shopOpenDateTime);
    }
  }

  calculateOrderTime = (shopOpenDateTime) => {
    const { orderDetails } = this.props;
    const createdOnTime = orderDetails.createdOn;
    const orderClosed = orderDetails.deliveryStatus === 'DELIVERED' || orderDetails.deliveryStatus === 'CANCELLED';
    const shopDeliveryClass = orderDetails.store?.deliveryClass;
    const tillTime = orderClosed
      ? new Date(orderDetails.orderClosedOn).getTime() : new Date().getTime();
    const timeElapsed = shopDeliveryClass === 'NEXTDAY' ? ((tillTime - new Date(shopOpenDateTime).getTime())) / 1000 : ((tillTime - new Date(createdOnTime).getTime())) / 1000; // in seconds
    this.setState({
      totalTimeElapsed: timeElapsed,
    }, () => {
      clearInterval(this.createdOnTimer);
      if (!orderClosed) {
        this.createdOnTimer = setInterval(() => {
          this.setState((state) => ({
            totalTimeElapsed: state.totalTimeElapsed + 1,
          }));
        }, 1000);
      }
    });
  }

  render() {
    const { totalTimeElapsed } = this.state;
    const orderAcceptanceTimeOver = Math.floor(totalTimeElapsed / 60) > 15;

    return (
      <div className={`${orderAcceptanceTimeOver ? 'bg-danger' : 'bg-primary'} text-white font-weight-bold px-2 py-1 border-radius-4`}>
        {secondToTimeFormat(totalTimeElapsed)}
      </div>
    );
  }
}

OrderTimer.propTypes = {
  orderDetails: PropTypes.shape({
    createdOn: PropTypes.string,
    deliveryStatus: PropTypes.string,
    orderClosedOn: PropTypes.string,
    store: PropTypes.shape({
      deliveryClass: PropTypes.string,
      shopOpenTime: PropTypes.string,
    }),
  }).isRequired,
};

export default OrderTimer;
