import React from 'react';
import PropTypes from 'prop-types';

const CancelIcon = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      fill="#D14444"
      fillRule="evenodd"
      d="M5.4 14.141c.811.35 1.678.526 2.6.526.923 0 1.79-.174 2.6-.525a6.774 6.774 0 0 0 2.117-1.425c.6-.6 1.075-1.305 1.424-2.117a6.538 6.538 0 0 0 .526-2.6 6.454 6.454 0 0 0-.525-2.6 6.757 6.757 0 0 0-1.425-2.117A6.722 6.722 0 0 0 10.6 1.86 6.494 6.494 0 0 0 8 1.333c-.921 0-1.788.175-2.6.526a6.722 6.722 0 0 0-2.117 1.424c-.599.6-1.074 1.305-1.424 2.117A6.476 6.476 0 0 0 1.333 8c0 .922.176 1.788.526 2.6.35.812.824 1.517 1.424 2.117.6.6 1.306 1.074 2.117 1.424Zm.2-2.808 2.4-2.4 2.4 2.4.933-.933-2.4-2.4 2.4-2.4-.933-.933-2.4 2.4-2.4-2.4-.933.933 2.4 2.4-2.4 2.4.933.933Z"
      clipRule="evenodd"
    />
  </svg>
);

CancelIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

CancelIcon.defaultProps = {
  width: 16,
  height: 16,
};

export default CancelIcon;
