import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Header(props) {
  const { toggleSidebar, onLogout, loggingOut } = props;
  return (
    <Container
      fluid
      className="bg-primary h-100"
    >
      <Row
        className="align-items-center text-white h-100"
      >
        <Col
          xs="auto"
          className="px-4"
        >
          <Button
            variant="link"
            className="p-0 text-white fs-3"
            onClick={() => toggleSidebar()}
          >
            &#9776;
          </Button>
        </Col>
        <Col
          xs="auto"
          className="fs-1 font-weight-bold"
        >
          <Link
            to="/"
            className="text-white"
          >
            Admin Dashboard
          </Link>
        </Col>
        <Col
          xs="auto"
          className="ml-auto"
        >
          <Button
            className="fs-1 font-weight-bold"
            onClick={onLogout}
          >
            {
              !loggingOut
                ? 'Logout'
                : (
                  <Spinner
                    variant="primary"
                    animation="border"
                    size="sm"
                    className="text-white"
                  />
                )
            }
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

Header.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  loggingOut: PropTypes.bool.isRequired,
};

export default Header;
