import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <g id="Frames-24px">
      <rect fill="none" width={width} />
    </g>
    <g id="Solid">
      <g>
        <path
          fill={fill}
          d="M12,8.002c-2.757,0-5,2.243-5,5s2.243,5,5,5s5-2.243,5-5S14.757,8.002,12,8.002z M12,16.002
           c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S13.654,16.002,12,16.002z"
        />
        <path
          fill={fill}
          d="M20,6.002h-3.465l-1.406-2.108c-0.372-0.559-0.994-0.892-1.664-0.892h-2.93
           c-0.67,0-1.292,0.333-1.664,0.892L7.465,6.002H4c-1.103,0-2,0.897-2,2v11c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2v-11
           C22,6.899,21.103,6.002,20,6.002z M4,19.002v-11h4.535l2-3h2.93l2,3H20l0.001,11H4z"
        />
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
