import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import ThumbnailSvg from '../../assets/icon/thumbnail.svg';

const CustomProduct = ({ product }) => (
  <Col
    xs={24}
  >
    <div
      className="py-2"
    >
      <Row
        className="mx-0"
      >
        <Col
          xs={4}
          className="p-1 text-center product-image-div"
        >
          <img
            src={product.url || ThumbnailSvg}
            alt=""
            className="product-image"
          />
        </Col>
        <Col
          className="py-1 pl-1 pr-4"
        >
          <div
            className="pb-1 font-weight-bold"
          >
            {product.displayName}
          </div>
          <div
            className="pb-1"
          >
            <b>
              <span
                className="text-secondary"
              >
                {product.unitString}
              </span>
            </b>
          </div>
        </Col>
        <Col
          xs="auto"
          className="px-0 pt-3"
        >
          <div>
            &times;&nbsp;
            <span
              className="border border-primary text-primary py-1 px-2 rounded"
            >
              <b>{product.quantity}</b>
            </span>
          </div>
        </Col>
      </Row>
    </div>
  </Col>
);

CustomProduct.propTypes = {
  product: PropTypes.shape({
    url: PropTypes.string,
    displayName: PropTypes.string,
    unitString: PropTypes.string,
    quantity: PropTypes.number,
  }).isRequired,
};

export default CustomProduct;
