import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import {
  Container, Row, Col, Spinner, Button,
} from 'react-bootstrap';
import {
  Svg, ErrorHandler, CustomTable, DatePicker,
} from '../../component/common';
import { Constant } from '../../utilities';
import { ProcessingStatus } from '../../component/derived/table-list';
import { genericBulkUpload } from '../../assets/api/axios';
import Permission from '../../access&permissions/permission';

const getFileIcon = (link) => (link
  ? (
    <Button
      variant="link"
      className="p-0 fs-01"
      onClick={() => {
        window.open(link, '_blank');
      }}
    >
      <Svg
        svg="file"
        width="1.3rem"
        fill={Constant.Color.PRIMARY}
      />
    </Button>
  )
  : null
);

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, startDate, endDate,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  startDate = Number(startDate) || '';
  endDate = Number(endDate) || '';
  return ({
    ...param, l, p, startDate, endDate,
  });
};

class BulkCouponCreation extends React.Component {
  constructor(props) {
    const param = getQueryParams(props.history.location.search);
    super();
    const { userPermission } = props;
    this.canIEdit = userPermission && userPermission.includes(Permission.COUPON_CREATION_WRITE);
    this.state = {
      rowsPerPage: param.l,
      page: param.p,
      loading: true,
      error: false,
      bulkCoupon: null,
      param,
      file: null,
      uploadStatus: '',
      errorMessage: '',
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, startDate, endDate,
    } = newParam;
    const offset = (p - 1) * l;
    genericBulkUpload(
      'get',
      null,
      {
        offset: `${offset}`,
        limit: l,
        uploadType: 'BULKCOUPONCREATION',
        startDate,
        endDate,
      },
      this.source.token,
    )
      .then((res) => {
        this.setState({
          bulkCoupon: { ...res.data },
          loading: false,
          rowsPerPage: l,
          page: p,
          param: { ...newParam },
        }, () => {
          if (
            Object.keys(newParam).find((key) => (newParam[key] !== param[key]))
          ) {
            Object.keys(newParam).forEach((item) => {
              if (!newParam[item]) {
                delete newParam[item];
              }
            });
            history.push({
              path: pathname,
              search: QueryString.stringify(newParam),
            });
          }
        });
      }).catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleDateChange = (startDate, endDate) => {
    const { param } = this.state;
    if (startDate !== param.startDate
      || endDate !== param.endDate) {
      this.handleRequestProcessing({
        startDate,
        endDate,
        p: 1,
      });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const {
      l, p,
    } = param;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source;
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
    });
  }

  resetFilters = () => {
    const { param } = this.state;
    if (param.startDate) {
      this.handleRequestProcessing({
        ...getQueryParams({
          p: 1, startDate: '', endDate: '',
        }),
      });
    }
  }

  handleBulkUpload = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  }

  handleSubmit = () => {
    const { file } = this.state;
    const formData = new FormData();
    formData.append('data', file);
    formData.append('uploadType', 'BULKCOUPONCREATION');
    this.setState({ uploadStatus: 'loading' });
    genericBulkUpload(
      'POST',
      formData,
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState(
            {
              uploadStatus: 'success',
              errorMessage: '',
              file: null,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  uploadStatus: '',
                });
              }, 3000);
              this.handleLoad();
            },
          );
        }
      })
      .catch((err) => {
        if (
          err
          && err.response
          && err.response.data
          && err.response.data.server_response
        ) {
          this.setState({
            uploadStatus: 'error',
            errorMessage: err.response.data.server_response,
          });
          return;
        }
        this.setState({
          uploadStatus: 'error',
          errorMessage: 'Oops! Something went wrong.',
        });
      });
  }

  render() {
    const {
      loading, error, uploadStatus, file,
      bulkCoupon, param, rowsPerPage, page,
      errorMessage,
    } = this.state;

    const headers = [
      {
        key: 'id',
        displayText: 'ID',
      },
      {
        key: 'created_on',
        displayText: 'Uploaded on',
        renderer: (data) => data.createdOn && new Date(data.createdOn).toLocaleString(),
      },
      {
        key: 'user',
        displayText: 'User',
      },
      {
        key: 'requestFileLink',
        displayText: 'Uploaded File',
        renderer: (data) => getFileIcon(data.requestFileLink),
      },
      {
        key: 'status',
        displayText: 'Status',
      },
      {
        key: 'responseFile',
        displayText: 'Response File',
        renderer: (data) => getFileIcon(data.response && data.response.responseFileLink),
      },
    ];

    if (!bulkCoupon && loading) {
      return (
        <div
          className="h-100 d-flex align-items-center
             justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!bulkCoupon && !loading && error) {
      return (
        <div
          className="h-100 d-flex align-items-center
             justify-content-center"
        >
          <ErrorHandler
            retryLogic={() => this.handleRequestProcessing()}
          />
        </div>
      );
    }

    if (bulkCoupon) {
      return (
        <div
          id="bulkCoupon"
          className="table-list bg-white"
        >
          <ProcessingStatus
            show={loading || error}
            loading={loading}
            error={error}
            onRetry={() => this.retry()}
            onCancel={this.onCancel}
          />
          <Container
            fluid
            className="mx-0 px-0"
          >
            <Row
              className="mx-0"
            >
              <Col
                xs={24}
                className="px-0 mb-2 bg-white filter-section"
              >
                <Row
                  className="mx-0"
                >
                  <Col
                    xs="auto"
                    className="px-2 py-1"
                  >
                    <DatePicker
                      isDateRange
                      onApply={(dateRange) => {
                        this.handleDateChange(
                          new Date(dateRange.startDate).getTime(),
                          new Date(dateRange.endDate).getTime(),
                        );
                      }}
                      startDate={param.startDate}
                      endDate={param.endDate}
                      onClear={() => {
                        this.handleRequestProcessing({
                          startDate: '', endDate: '',
                        });
                      }}
                    />
                  </Col>
                  <Col
                    xs="auto"
                    className="py-1 ml-auto px-3"
                  >
                    <Button
                      variant="outline-primary"
                      className="fs-01"
                      onClick={this.resetFilters}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={24}
                className={`${this.canIEdit ? '' : 'd-none'} px-2 mb-2 py-3`}
              >
                <div
                  className="font-weight-bold text-center fs-2"
                >
                  Upload File
                </div>
                <div
                  className="text-center pt-2"
                >
                  <input
                    type="file"
                    accept={
                      `.csv,
                      application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                      application/vnd.ms-excel`
                    }
                    onChange={this.handleBulkUpload}
                  />
                  <Button
                    variant="primary"
                    className="border border-dark py-1 px-4"
                    disabled={uploadStatus === 'loading' || !file}
                    onClick={this.handleSubmit}
                  >
                    {
                      uploadStatus === 'loading' && (
                        <Spinner
                          variant="secondary"
                          animation="border"
                          size="sm"
                        />
                      )
                    }
                    &nbsp;&nbsp;SUBMIT&nbsp;&nbsp;
                  </Button>
                </div>
                {
                  uploadStatus === 'success' && (
                    <div
                      className="text-center pt-3 fs-2 text-success"
                    >
                      <b>File Uploaded Successfully!!</b>
                    </div>
                  )
                }
                {
                  uploadStatus === 'error' && (
                    <div
                      className="text-center pt-3 fs-2 text-danger"
                    >
                      <b>{errorMessage}</b>
                    </div>
                  )
                }
              </Col>
              <Col
                xs={24}
                className="px-2 mb-2"
              >
                Upload History
              </Col>
              <Col
                xs={24}
                className="px-0"
              >
                <CustomTable
                  headers={headers}
                  content={bulkCoupon.results}
                  keyField="id"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={bulkCoupon.count}
                  hasPrev={bulkCoupon.hasPrevious}
                  hasNext={bulkCoupon.hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    return null;
  }
}

BulkCouponCreation.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default BulkCouponCreation;
