import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import QueryString from 'query-string';
import { Svg, ErrorHandler } from '../../component/common';
import { Constant, Storage, Utils } from '../../utilities';
import { storeDetails } from '../../assets/api/axios';
import ExotelCallPatchDetails from '../../component/derived/call-logs/ExotelCallPatchDetails';
import RetailerDetails from './RetailerDetails';
import Analytics from './Analytics';
import CallLogs from './CallLogs';
import Catalog from './Catalog';
import Overview from './Overview';
import PendingSettlements from '../settlements/PendingSettlements';
import ReconciledSettlements from '../settlements/ReconciledSettlements';
import Setting from './Setting';
import '../../assets/scss/RetailerManagement/RetailerFigures.scss';
import Reviews from './Reviews';
import Order from './Order';
import Product from './Product';
import BulkProductAddition from './BulkProductAddition';
import Permission from '../../access&permissions/permission';
import OnboardingStates from './OnboardingStates';
import Feeds from '../feeds/feeds';
import SmartCatalogue from './SmartCatalogue';
import SpecialOffer from './SpecialOffer';
import RetailerPayments from './RetailerPayments';
import OfferClass from './OfferClass';
import SMSTemplate from '../../component/common/SMSTemplate';
import WhatsAppTemplate from '../../component/common/WhatsAppTemplate';
import CommentHistory from './CommentHistory';
import RetailerWallet from './RetailerWallet';

const viewItems = {
  onboarding: 'Onboarding Status',
  analytics: 'Analytics',
  call: 'Call Logs',
  catalog: 'Catalog',
  overview: 'Overview',
  pendingSettlement: 'Pending Settlement',
  reconciledSettlement: 'Reconciled Settlement',
  setting: 'Setting',
  reviews: 'Review & Rating',
  order: 'Orders',
  product: 'Product',
  bulkProductAddition: 'Bulk Product Addition',
  feed: 'Retailer Feeds',
  smartcatalogue: 'Smart Catalogue',
  specialOffer: 'Special Offers',
  retailerPayments: 'Retailer Payments',
  offerClass: 'Offer Class',
  commentHistory: 'Comment History',
  retailerWallet: 'LoveLocal Coins',
};

function getQueryParams(data) {
  const param = QueryString.parse(data);
  if (Object.keys(viewItems).includes(param.view)) {
    return { view: param.view };
  }
  return { ...param, view: '' };
}

class RetailerFigures extends React.Component {
  constructor(props) {
    super();
    const { match, history } = props;
    const param = getQueryParams(history.location.search);
    const { view } = param;
    const { code } = match.params;
    this.state = {
      view,
      code,
      loading: true,
      error: false,
      initiateCall: false,
      param,
      retailerDetails: {},
      smsModal: false,
      whatsappModal: false,
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    const { match, history } = props;
    const param = getQueryParams(history.location.search);
    if (
      param.view !== state.view
        || match.params.code !== state.code
    ) {
      return {
        view: param.view,
        code: match.params.code,
      };
    }
    return null;
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { code, view } = this.state;
    if (prevState.code !== code) {
      this.handleRequest();
    }
    if (prevState.view !== view
      && prevState.code === code) {
      this.handleLoad(true);
    }
  }

  handleLoad = (isBackRefresh = false) => {
    const { code } = this.state;
    storeDetails('get', code)
      .then((res) => {
        this.setState({
          loading: false,
          error: false,
          retailerDetails: { ...res.data },
        });
      })
      .catch(() => {
        if (!isBackRefresh) {
          this.setState({
            loading: false,
            error: true,
          });
        }
      });
  }

  handleRequest = () => {
    this.setState({
      loading: true,
      error: false,
    }, () => {
      this.handleLoad();
    });
  }

  updateRetailerDetails = (data) => {
    const { retailerDetails } = this.state;
    this.setState({
      retailerDetails: {
        ...retailerDetails,
        ...data,
      },
    });
  }

  render() {
    const {
      view, code, retailerDetails, loading, error, initiateCall, param,
      smsModal, whatsappModal,
    } = this.state;
    const { history, userPermission } = this.props;

    if (loading) {
      return (
        <div
          className="h-100 d-flex align-items-center
             justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!loading && error) {
      return (
        <div
          className="h-100 d-flex align-items-center
             justify-content-center"
        >
          <ErrorHandler
            retryLogic={() => this.handleRequest()}
          />
        </div>
      );
    }

    const title = viewItems[view];
    let exotelCall = true;
    let communication = true;
    let Component = RetailerDetails;
    switch (view) {
      case 'onboarding':
        Component = OnboardingStates;
        exotelCall = false;
        communication = false;
        break;
      case 'analytics':
        Component = Analytics;
        exotelCall = false;
        communication = false;
        break;
      case 'call':
        Component = CallLogs;
        communication = false;
        break;
      case 'catalog':
        Component = Catalog;
        exotelCall = false;
        communication = false;
        break;
      case 'overview':
        Component = Overview;
        exotelCall = false;
        communication = false;
        break;
      case 'pendingSettlement':
        Component = PendingSettlements;
        exotelCall = false;
        communication = false;
        break;
      case 'reconciledSettlement':
        Component = ReconciledSettlements;
        exotelCall = false;
        communication = false;
        break;
      case 'setting':
        Component = Setting;
        exotelCall = true;
        exotelCall = false;
        communication = false;
        break;
      case 'reviews':
        Component = Reviews;
        exotelCall = false;
        communication = false;
        break;
      case 'order':
        Component = Order;
        exotelCall = false;
        communication = false;
        break;
      case 'product':
        Component = Product;
        exotelCall = false;
        communication = false;
        break;
      case 'bulkProductAddition':
        Component = BulkProductAddition;
        exotelCall = false;
        communication = false;
        break;
      case 'feed':
        Component = Feeds;
        exotelCall = false;
        communication = false;
        break;
      case 'specialOffer':
        Component = SpecialOffer;
        exotelCall = false;
        communication = false;
        break;
      case 'retailerPayments':
        Component = RetailerPayments;
        exotelCall = false;
        communication = false;
        break;
      case 'smartcatalogue':
        Component = SmartCatalogue;
        exotelCall = false;
        communication = false;
        break;
      case 'offerClass':
        Component = OfferClass;
        exotelCall = false;
        communication = false;
        break;
      case 'commentHistory':
        Component = CommentHistory;
        exotelCall = false;
        break;
      case 'retailerWallet':
        Component = RetailerWallet;
        exotelCall = false;
        break;
      default:
        break;
    }

    if (retailerDetails) {
      return (
        <div
          className="h-100"
        >
          <ExotelCallPatchDetails
            phoneNumber={retailerDetails.phoneNumber}
            action="CALL_TO_RETAILER"
            show={initiateCall}
            onHide={() => {
              this.setState({
                initiateCall: false,
              });
            }}
          />
          {
            smsModal && (
              <SMSTemplate
                show={smsModal}
                onHide={() => this.setState({ smsModal: false })}
                phoneNumber={retailerDetails.phoneNumber}
                handleRequestProcessing={() => {}}
              />
            )
          }
          {
            whatsappModal && (
              <WhatsAppTemplate
                show={whatsappModal}
                onHide={() => this.setState({ whatsappModal: false })}
                phoneNumber={retailerDetails.whatsappNumber}
                handleRequestProcessing={() => {}}
              />
            )
          }
          <Container
            fluid
            id="retailer-figures"
            className="h-100"
          >
            <Row
              className="h-100 align-items-start"
            >
              <Col
                xs={24}
                className="header fs-2 font-weight-bold py-1"
              >
                <Row
                  className="h-100 align-items-center"
                >
                  <Col
                    xs="auto"
                    className="px-2"
                  >
                    <Button
                      variant="link"
                      className="p-0 d-flex align-items-center"
                      onClick={() => {
                        const urlParam = { ...param };
                        delete urlParam.view;
                        const query = QueryString.stringify(
                          Utils.deleteEmptyField(urlParam),
                        );
                        history.push(
                          `/retailer${view ? `/${code}` : ''}${query ? `?${query}` : ''}`,
                        );
                      }}
                    >
                      <Svg
                        svg="arrow"
                        width="1.3rem"
                        fill={Constant.Color.PRIMARY}
                      />
                    </Button>

                  </Col>
                  <Col
                    xs="auto"
                    className="px-2 text-truncate"
                  >
                    {retailerDetails.name}
                  </Col>
                  {exotelCall && (
                    <Col
                      xs="auto"
                      className="px-2"
                    >
                      <Button
                        className="fs-0 py-1"
                        size="sm"
                        onClick={() => {
                          window.open(window.location.href, '_blank');
                          this.setState({
                            initiateCall: true,
                          });
                        }}
                        disabled={!userPermission.includes(Permission.CALL_TO_RETAILER)
                          || !retailerDetails.phoneNumber || !Storage.getUserNumber()}
                      >
                        Initiate Call
                      </Button>
                    </Col>
                  )}
                  {
                    communication && (
                      <Col
                        xs="auto"
                        className="px-2"
                      >
                        <Button
                          variant="outline-primary"
                          onClick={() => {
                            this.setState({
                              smsModal: true,
                            });
                          }}
                          className="fs-0 py-1"
                          disabled={!userPermission.includes(Permission.CALL_TO_RETAILER)
                            || !retailerDetails.phoneNumber || !Storage.getUserNumber()}
                        >
                          <Svg
                            svg="sms"
                            width="24"
                            height="24"
                          />
                          &nbsp;
                          SMS
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          variant="outline-primary"
                          onClick={() => {
                            this.setState({
                              whatsappModal: true,
                            });
                          }}
                          className="fs-0 py-1"
                          disabled={!userPermission.includes(Permission.CALL_TO_RETAILER)
                            || !retailerDetails.whatsappNumber || !Storage.getUserNumber()}
                        >
                          <Svg
                            svg="whatsapp"
                            width="24"
                            height="24"
                          />
                          &nbsp;
                          WhatsApp
                        </Button>
                      </Col>
                    )
                  }
                  {retailerDetails.storeState && (
                    <Col
                      xs="auto"
                      className="px-2"
                    >
                      <Button
                        className="fs-0 py-1"
                        size="sm"
                        variant={retailerDetails.deliveryStatus ? 'outline-success' : 'outline-danger'}
                      >
                        {retailerDetails.storeState}
                      </Button>
                    </Col>
                  )}
                  {title && (
                  <Col
                    xs={24}
                    md="auto"
                    className="px-md-2 px-5"
                  >
                    {title}
                  </Col>
                  )}
                </Row>
              </Col>
              <Col
                xs={24}
                className="px-0 content"
              >
                <Component
                  {...this.props}
                  retailerDetails={retailerDetails}
                  isDetailPage
                  updateRetailerDetails={this.updateRetailerDetails}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    return null;
  }
}

RetailerFigures.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default RetailerFigures;
