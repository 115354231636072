import React from 'react';
import PropTypes from 'prop-types';
import { cellToLatLng } from 'h3-js';
import { Marker, Polygon } from 'react-google-maps';

const customMarker = '/images/tickIcon.png';

function Hex(props) {
  const { hexPoints, modalView, handleHex } = props;
  const centerLatLng = cellToLatLng(hexPoints?.hexId);
  const selectedHex = hexPoints?.isSelected;
  return (
    <>
      {
        selectedHex && (
          <Marker
            position={{ lat: centerLatLng[0], lng: centerLatLng[1] }}
            icon={customMarker}
            onClick={() => {
              if (modalView === 'EDIT') {
                handleHex(hexPoints, !selectedHex);
              }
            }}
          />
        )
      }
      <Polygon
        paths={hexPoints?.coordinates}
        options={{
          fillColor: `${selectedHex ? '#9dc497' : '#c4c2c2'}`,
          strokeColor: `${selectedHex ? '#138800' : '#808080'}`,
          strokeWeight: 1,
          fillOpacity: 0.5,
        }}
        onClick={() => {
          if (modalView === 'EDIT') {
            handleHex(hexPoints, !selectedHex);
          }
        }}
      />
    </>
  );
}

Hex.propTypes = {
  hexPoints: PropTypes.shape({
    hexId: PropTypes.string,
    isSelected: PropTypes.bool,
    coordinates: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }).isRequired,
  modalView: PropTypes.string.isRequired,
  handleHex: PropTypes.func.isRequired,
};

export default Hex;
