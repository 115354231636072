import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import moment from 'moment';
import {
  Button,
  Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';
import { freeSampleCampaign } from '../../assets/api/axios';
import { CustomDropdown, CustomTable, ErrorHandler } from '../../component/common';
import { dateString } from '../../utilities/Utils';
import { getUserName } from '../../utilities/Storage';
import AddStores from './AddStores';
import { ProcessingStatus } from '../../component/derived/table-list';
import RemoveStore from './RemoveStore';
import permission from '../../access&permissions/permission';

function CampaignDetails(props) {
  const { match, history } = props;
  const param = QueryString.parse(history.location.search);
  const campaignId = (match.params.campaignId || param.campaignId);
  const { userPermission } = props;
  const canEdit = userPermission.includes(permission.BRAND_SAMPLING_CAMPAIGN_WRITE);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [editCampaign, setEditCampaign] = useState({
    campaignName: '',
    stores: [],
    productId: '',
    active: '',
    mov: '',
    launchDate: new Date(),
    validTill: new Date(),
    totalStock: '',
  });
  const [campaign, setCampaign] = useState(null);
  const [invalidDate, setInvalidDate] = useState('');
  const [invalidStock, setInvalidStock] = useState('');
  const [submitting, setSubmitting] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [addStoreModal, setAddStoreModal] = useState(false);
  const [removeStoreId, setRemoveStoreId] = useState('');
  const [sampleCsv, setSampleCsv] = useState('');

  const {
    campaignName, stores, productId, active, mov, launchDate, validTill, totalStock,
  } = editCampaign;

  const disabled = !campaignName || !productId || !totalStock || submitting === 'loading' || !canEdit;

  const eligibleOptions = {
    key: 'active',
    displayText: '',
    options: [
      {
        label: 'True',
        value: 'true',
      },
      {
        label: 'False',
        value: 'false',
      },
    ],
  };

  const handleLoad = () => {
    setLoading(true);
    setError(false);
    freeSampleCampaign(
      'GET',
      campaignId,
      null,
      {},
    ).then((res) => {
      const { data } = res.data;
      setCampaign(data);
      setEditCampaign({
        campaignName: data.name,
        stores: data.stores,
        productId: data.libraryProductId,
        active: data.active,
        mov: data.mov,
        launchDate: data.launchDate,
        validTill: data.validTill,
        totalStock: data.totalStock,
      });
      setSampleCsv(data.sampleCsv);
      setLoading(false);
      setError(false);
    }).catch(() => {
      setLoading(false);
      setError(true);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditCampaign({ ...editCampaign, [name]: value });
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (validTill < launchDate) {
      setInvalidDate('Please Check!! Valid Till Date is Less than Launch Date');
      return;
    }
    if (totalStock < campaign.allocatedStock) {
      setInvalidStock('Please Check!! Total stocks should be more than allocated stocks');
      return;
    }
    setInvalidDate('');
    setInvalidStock('');
    setSubmitting('loading');
    setSubmitError('');
    freeSampleCampaign(
      'PATCH',
      campaignId,
      {
        name: campaignName,
        active,
        launchDate: (new Date(launchDate).getTime() / 1000),
        validTill: (new Date(validTill).getTime() / 1000),
        mov,
        userId: getUserName(),
        totalStock: parseInt(totalStock, 10),
      },
    ).then((res) => {
      if (res.status === 200) {
        setSubmitting('success');
        handleLoad();
        setTimeout(() => {
          setSubmitting('');
        }, 5000);
      } else {
        throw new Error();
      }
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong';
      if (
        err
        && err.response
        && err.response.status === 400
        && err.response.data
        && err.response.data.errors
        && err.response.data.errors[0].message
      ) {
        errorMsg = err.response.data.errors[0].message;
      }
      setSubmitting('error');
      setSubmitError(errorMsg);
    });
  }

  useEffect(() => {
    handleLoad();
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setLoading(false);
    setError(false);
    setSubmitting('');
    setSubmitError('');
  };

  return (
    <>
      <AddStores
        show={addStoreModal}
        onHide={() => setAddStoreModal(false)}
        campaignId={campaignId}
        handleRequestProcessing={handleLoad}
        sampleCsv={sampleCsv}
      />
      <RemoveStore
        show={!!removeStoreId}
        onHide={() => setRemoveStoreId('')}
        id={removeStoreId}
        handleRequestProcessing={handleLoad}
      />
      {(() => {
        let showcase = null;
        if (!campaign && loading) {
          showcase = (
            <div
              className="h-100 d-flex align-items-center
              justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          );
        } else if (!loading && error) {
          showcase = (
            <div
              className="h-100 d-flex align-items-center
              justify-content-center"
            >
              <ErrorHandler
                retryLogic={() => {
                  handleLoad();
                }}
              />
            </div>
          );
        } else if (campaign) {
          showcase = (
            <>
              <ProcessingStatus
                show={loading || error}
                loading={loading}
                error={error}
                onRetry={handleLoad}
                onCancel={onCancel}
              />
              <Container
                className="p-1"
              >
                <Row className="align-items-center justify-content-end my-1">
                  <Button
                    variant="primary"
                    className="fs-1 py-2"
                    onClick={() => { setAddStoreModal(true); }}
                    disabled={!canEdit}
                  >
                    Add Stores
                  </Button>
                </Row>
                <Row className="fs-3">
                  <b>Campaign Details</b>
                </Row>
                <Row
                  className="border border-black border-radius-4 my-1 minh-820p"
                >
                  <Col
                    xs={24}
                    className="mx-3 py-3"
                  >
                    <Form
                      onSubmit={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Campaign Name</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <Form.Control
                            value={campaignName}
                            name="campaignName"
                            onChange={handleChange}
                            maxLength={128}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Product Id</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <input
                            type="number"
                            value={productId}
                            name="productId"
                            onChange={handleChange}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Product Name</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <Form.Control
                            value={campaign.libraryProductName}
                            readOnly
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Is Eligible</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <CustomDropdown
                            item={eligibleOptions}
                            name="active"
                            onChange={(item) => {
                              setEditCampaign({
                                ...editCampaign,
                                active: item.active,
                              });
                            }}
                            selectedVal={active.toString()}
                            closeButton={false}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Launch Date</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <input
                            type="datetime-local"
                            className="form-control"
                            value={moment(launchDate).format('YYYY-MM-DDTHH:mm')}
                            id="launchDate"
                            onChange={(e) => {
                              setEditCampaign({
                                ...editCampaign,
                                launchDate: e.target.value,
                              });
                            }}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Valid Till</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <input
                            type="datetime-local"
                            className="form-control"
                            value={moment(validTill).format('YYYY-MM-DDTHH:mm')}
                            id="validTill"
                            onChange={(e) => {
                              setEditCampaign({
                                ...editCampaign,
                                validTill: e.target.value,
                              });
                            }}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                      {
                        !!invalidDate && (
                          <div
                            className="d-flex align-item-center justify-content-center"
                          >
                            <span className="text-danger mt-n2 mb-2">
                              {invalidDate}
                            </span>
                          </div>
                        )
                      }
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Created On</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <Form.Control
                            value={dateString(campaign.createdAt)}
                            readOnly
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Modified On</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <Form.Control
                            value={dateString(campaign.modifiedAt)}
                            readOnly
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Modified By</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <Form.Control
                            value={campaign.modifiedById}
                            readOnly
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>MOV</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <input
                            type="number"
                            value={mov}
                            name="mov"
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Total Stock</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <input
                            type="number"
                            value={totalStock}
                            name="totalStock"
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                      {
                        !!invalidStock && (
                          <div
                            className="d-flex align-item-center justify-content-center"
                          >
                            <span className="text-danger mt-n2 mb-2">
                              {invalidStock}
                            </span>
                          </div>
                        )
                      }
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Total Allocation</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <input
                            value={campaign.allocatedStock}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Remaining Stocks</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <input
                            value={campaign.remainingStock}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                      {
                        stores.length > 0 && (
                          <Form.Group as={Row}>
                            <Form.Label column sm={6}>
                              <b>Stores</b>
                            </Form.Label>
                            <Col
                              sm={12}
                            >
                              <CustomTable
                                headers={[
                                  {
                                    key: 'storeId',
                                    displayText: 'StoreId',
                                  },
                                  {
                                    key: 'totalStoreStock',
                                    displayText: 'Total Stock',
                                  },
                                  {
                                    key: 'stockUsage',
                                    displayText: 'Stock Usage',
                                  },
                                  {
                                    key: 'stockPending',
                                    displayText: 'Stock Pending',
                                  },
                                  {
                                    key: 'action',
                                    displayText: 'Action',
                                    renderer: (storeData) => (
                                      <Button
                                        onClick={() => {
                                          setRemoveStoreId(storeData.id);
                                        }}
                                        className="p-1 fs-0"
                                      >
                                        Remove
                                      </Button>
                                    ),
                                  },
                                ]}
                                content={stores}
                                keyField="storeId"
                                totalItems={stores.length}
                                isPaginated={false}
                              />
                            </Col>
                          </Form.Group>
                        )
                      }
                      <Form.Group as={Row} className="mt-2">
                        <Form.Label column sm={6} />
                        <Col xs={17}>
                          <Button
                            block
                            type="submit"
                            variant="primary"
                            disabled={disabled}
                          >
                            UPDATE
                          </Button>
                        </Col>
                      </Form.Group>
                      {
                        submitting === 'loading' && (
                          <div
                            className="d-flex align-item-center justify-content-center"
                          >
                            <Col sm={6} />
                            <Spinner
                              animation="border"
                              variant="primary"
                            />
                          </div>
                        )
                      }
                      {
                        submitting === 'success' && (
                          <div
                            className="d-flex align-item-center justify-content-center"
                          >
                            <Col sm={6} />
                            <b className="text-success">Campaign Updated</b>
                          </div>
                        )
                      }
                      {
                        !!submitError && (
                          <div
                            className="d-flex align-item-center justify-content-center"
                          >
                            <Col sm={6} />
                            <span className="text-danger">
                              {submitError}
                            </span>
                          </div>
                        )
                      }
                    </Form>
                  </Col>
                </Row>
              </Container>
            </>
          );
        }
        return showcase;
      })()}
    </>
  );
}

CampaignDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      campaignId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default CampaignDetails;
