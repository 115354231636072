import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width }}
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
  >
    <g id="Frames-24px">
      <rect fill="none" style={{ width }} />
    </g>
    <g id="Outline">
      <g>
        <path
          fill={fill}
          d="M21,3h-3V1h-2v6h2V5h3v15H3V5h2V3H3C1.896,3,1,3.896,1,5v15c0,1.104,0.896,2,2,2h18c1.104,0,2-0.896,2-2V5
C23,3.896,22.104,3,21,3z"
        />
        <polygon fill={fill} points="8,5 15,5 15,3 8,3 8,1 6,1 6,7 8,7" />
        <rect x="5" y="9" fill={fill} width="4" height="4" />
        <rect x="10" y="9" fill={fill} width="4" height="4" />
        <rect x="15" y="9" fill={fill} width="4" height="4" />
        <rect x="5" y="14" fill={fill} width="4" height="4" />
        <rect x="10" y="14" fill={fill} width="4" height="4" />
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
