import React, { useState } from 'react';
import {
  Button, Col, Container, Row, Table,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import Svg from '../../component/common/svg/EditIcon';
import { Constant } from '../../utilities';
import { CustomModal } from '../../component/common';
import EditBusinessTiming from './EditBusinessTiming';

const BusinessTimings = ({ businessTimings, setBusinessTimings, storeCode }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [editingBTInstance, setEditingBTInstance] = useState(null);

  const convertTime12HrsFormat = (time) => moment(time).format('hh:mm A');

  const updatedSlots = [
    {
      displayText: 'DAY',
      slots: 'TIMINGS',
      id: 'title',
    },
    ...(businessTimings || []),
  ];

  const handleEdit = (id, day) => {
    setEditingId(id);
    setIsEditing(true);
    setEditingBTInstance(day);
  };

  const handleClose = () => {
    setIsEditing(false);
    setEditingId(null);
  };

  const handleSave = (updatedTimings) => {
    setBusinessTimings(updatedTimings);
    handleClose();
  };

  return (
    <Container fluid>
      <Row>
        <Col lg="12">

          {isEditing && (
          <CustomModal
            show={isEditing}
            onHide={handleClose}
            body={<EditBusinessTiming storeCode={storeCode} id={editingId} businessTimingsInstance={editingBTInstance} businessTimings={businessTimings} onClose={handleClose} onSave={handleSave} />}
          />
          )}
          <Table className="business-table" responsive="sm">
            <tbody>
              {businessTimings && businessTimings.length > 0 && updatedSlots.length > 0 ? (
                updatedSlots.map((day) => (
                  <tr key={day.id}>
                    <td>
                      <div style={{ fontWeight: 'bold' }}>
                        {day.displayText}
                      </div>
                    </td>
                    <td>
                      {(() => {
                        if (day.id === 'title') {
                          return (
                            <div style={{ fontWeight: 'bold' }}>{day.slots}</div>
                          );
                        }
                        if (day.isClosed) {
                          return (
                            <div style={{ color: 'red' }}>Closed</div>
                          );
                        }
                        return day.slots.map((slot) => (
                          <div>
                            {`${convertTime12HrsFormat(new Date(slot.startTime))}-${convertTime12HrsFormat(new Date(slot.endTime))}`}
                          </div>
                        ));
                      })()}
                    </td>
                    <td>
                      <Button
                        variant="outline"
                        onClick={() => handleEdit(day.id, day)}
                        className="edit-icon"
                      >
                        {day.id !== 'title' && (
                        <Svg
                          svg="editIcon"
                          height={22}
                          width={22}
                          stroke={Constant.Color.BLACK}
                        />
                        )}
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No business timings available</td>
                </tr>
              )}
            </tbody>
          </Table>

        </Col>
      </Row>

    </Container>
  );
};

BusinessTimings.propTypes = {
  businessTimings: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string.isRequired,
      slots: PropTypes.arrayOf(
        PropTypes.shape({
          startTime: PropTypes.oneOfType([
            PropTypes.string,
          ]).isRequired,
          endTime: PropTypes.oneOfType([
            PropTypes.string,
          ]).isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  setBusinessTimings: PropTypes.func.isRequired,
  storeCode: PropTypes.string.isRequired,
};

export default BusinessTimings;
