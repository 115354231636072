import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Spinner, Button,
} from 'react-bootstrap';
import { whatsappNotifications } from '../../../assets/api/axios';
import { CustomModal, CustomTable } from '../../../component/common';

const WhatsappNotifications = (props) => {
  const { orderId, show, toggleModal } = props;
  const [loadStatus, setLoadStatus] = useState('');
  const [whatsappNotificationsLogs, setWhatsappNotificationsLogs] = useState([]);

  const getWhatsappNotifications = () => {
    setLoadStatus('LOADING');
    whatsappNotifications(
      'GET', { orderId },
    ).then((res) => {
      setWhatsappNotificationsLogs(res.data.results);
      setLoadStatus('SUCCESS');
    }).catch(() => {
      setLoadStatus('ERROR');
    });
  };

  useEffect(() => {
    if (!loadStatus) {
      getWhatsappNotifications();
    }
  });

  const retry = () => {
    setLoadStatus('');
  };

  const headerConfig = [
    {
      key: 'messageId',
      displayText: 'Message Id',
    },
    {
      key: 'messageType',
      displayText: 'Message Type',
    },
    {
      key: 'header',
      displayText: 'Header',
    },
    {
      key: 'body',
      displayText: 'Message Body',
      renderer: (data) => (
        <div
          className="minw-250"
        >
          {data.body}
        </div>
      ),
    },
    {
      key: 'to',
      displayText: 'Shared To',
    },
    {
      key: 'sentTime',
      displayText: 'Sent Time',
    },
    {
      key: 'receivedTime',
      displayText: 'Received Time',
    },
    {
      key: 'readTime',
      displayText: 'Read Time',
    },
    {
      key: 'templateId',
      displayText: 'Template Id',
    },
    {
      key: 'templateName',
      displayText: 'Template Name',
    },
  ];

  return (
    <CustomModal
      show={show}
      size="lg"
      onHide={toggleModal}
      closeButton
      body={(
        <Container className="p-4">
          {
            loadStatus === 'LOADING' && (
              <Col
                xs={24}
                className="text-primary text-center"
              >
                <Row className="py-2 justify-content-center d-flex">
                  <Spinner
                    variant="primary"
                    animation="border"
                  />
                </Row>
                <Row className="py-2 justify-content-center">
                  Fetching Whatsapp Notifications Logs
                </Row>
              </Col>
            )
          }
          {
            loadStatus === 'SUCCESS' && (
              <CustomTable
                keyField="messageId"
                headers={headerConfig}
                content={whatsappNotificationsLogs}
                isPaginated={false}
                totalItems={whatsappNotificationsLogs.length}
              />
            )
          }
          {
            loadStatus === 'ERROR' && (
              <Col xs={24} className="text-danger text-center p-2">
                Oops Something went wrong while fetching Whatsapp Notifications logs,
                Please try again!!
                &nbsp;&nbsp;
                <Button
                  variant="link"
                  className="text-primary font-weight-bold"
                  onClick={() => { retry(); }}
                >
                  RETRY
                </Button>
              </Col>
            )
          }
          <Col
            xs={24}
            className="d-flex justify-content-center"
          >
            <Button
              variant="outline-primary"
              onClick={toggleModal}
            >
              EXIT
            </Button>
          </Col>
        </Container>
      )}
    />
  );
};

WhatsappNotifications.propTypes = {
  orderId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  show: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default WhatsappNotifications;
