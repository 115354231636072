import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import DeleteSvg from '../../assets/icon/delete.svg';
import ThumbnailSvg from '../../assets/icon/thumbnail.svg';

const inCart = (list, productId, storeId) => {
  let flag = false;
  list.forEach((item) => {
    if (item.productId === productId && item.storeId === storeId) { flag = true; }
  });
  return flag;
};

const getProductQuantity = (list, productId) => {
  let quantity = 0;
  list.forEach((item) => {
    if (item.productId === productId) {
      quantity = item.quantity;
    }
  });
  return quantity;
};

function ProductView(props) {
  const {
    product,
    cartItems,
    handleAddProductInCart,
    className,
  } = props;
  return (
    <Container fluid className={`py-3 ${className}`}>
      <Row>
        <Col xs={4} className="image-container">
          <img src={product.image || ThumbnailSvg} alt="" className="product-image" />
        </Col>
        <Col xs={20}>
          <h4 className="fs-1 mb-0">{product.displayName}</h4>
          {product.sellingStores.map((element) => (
            <Container className="p-0 pt-2 pb-2" key={element.store.id}>
              <Row className="py-1 align-items-center justify-content-between">
                <Col xs="auto">
                  <div className="fs-1 d-flex pt-1">
                    <span className="text-success pr-3">
                      &#8377;
                      {element.sellingPrice}
                    </span>
                    <span
                      className={element.sellingPrice !== element.mrp ? 'text-secondary pl-3' : 'd-none'}
                    >
                      <del>
                        &#8377;
                        {element.mrp}
                      </del>
                    </span>
                  </div>
                  <div className="fs-0 pt-1">{element.store.displayName}</div>
                </Col>
                <Col xs="auto">
                  {!inCart(cartItems, product.id, element.store.id)
                    ? (
                      <Button
                        variant="outline-success"
                        className="fs-1"
                        onClick={() => handleAddProductInCart(product.id, 1, element.store.id)}
                      >
                        +Add
                      </Button>
                    ) : (
                      <div className="d-flex">
                        <Button
                          variant="link"
                          className="text-decoration-none"
                          onClick={() => {
                            handleAddProductInCart(product.id, 0, element.store.id);
                          }}
                        >
                          <img src={DeleteSvg} alt="" />
                        </Button>
                        <div className="d-flex border border-success">
                          <Button
                            variant="link"
                            className="text-decoration-none text-success"
                            onClick={() => {
                              handleAddProductInCart(product.id,
                                getProductQuantity(cartItems, product.id) - 1, element.store.id);
                            }}
                          >
                            -
                          </Button>
                          <h4 className="text-success m-auto mb-0">
                            {getProductQuantity(cartItems, product.id)}
                          </h4>
                          <Button
                            variant="link"
                            className="text-decoration-none text-success"
                            onClick={() => {
                              handleAddProductInCart(product.id,
                                getProductQuantity(cartItems, product.id) + 1, element.store.id);
                            }}
                          >
                            +
                          </Button>
                        </div>
                      </div>
                    )}
                </Col>
              </Row>
            </Container>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

ProductView.propTypes = {
  product: PropTypes.shape({
    image: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    mrp: PropTypes.number.isRequired,
    sellingStores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  className: PropTypes.string.isRequired,
  cartItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleAddProductInCart: PropTypes.func.isRequired,
};

export default ProductView;
