import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import {
  CustomModal, CustomTable, ErrorHandler, Svg,
} from '../../component/common';
import { Constant } from '../../utilities';
import { dateString } from '../../utilities/Utils';
import { ProcessingStatus } from '../../component/derived/table-list';
import { fraudStores } from '../../assets/api/axios';

const getFileIcon = (link) => (link
  ? (
    <Button
      variant="link"
      className="p-0 fs-01"
      onClick={() => {
        window.open(link, '_blank');
      }}
    >
      <Svg
        svg="file"
        width="1.3rem"
        fill={Constant.Color.PRIMARY}
      />
    </Button>
  )
  : null
);

function MoreActions(props) {
  const { show, onHide, storeId } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [IVRError, setIVRError] = useState('');
  const [IVRDetails, setIVRDetails] = useState(null);

  const loadCustomerIVR = (action, method) => {
    fraudStores(
      method,
      storeId,
      {},
      {
        action,
      },
    ).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        setIVRDetails(res.data);
      } else {
        throw new Error();
      }
    }).catch((err) => {
      if (
        err
        && err.response
        && err.response.status === 400
        && err.response.data
        && err.response.data[0]
      ) {
        setLoading(false);
        setIVRError(err.response.data[0].client_message);
      } else {
        setError(true);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    loadCustomerIVR('MORE_ACTIONS', 'GET');
    // eslint-disable-next-line
  }, []);

  const InitiateIVRCall = () => {
    setLoading(true);
    setError(false);
    loadCustomerIVR('INITIATE_IVR_CHECKS', 'POST');
  };

  const onCancel = () => {
    setLoading(false);
    setError(false);
  };

  const body = (
    <>
      {(() => {
        let showcase = null;
        if (!IVRDetails && loading) {
          showcase = (
            <div
              className="h-100 d-flex align-items-center
              justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          );
        } else if (!IVRDetails && !loading && error) {
          showcase = (
            <div
              className="h-100 d-flex align-items-center
              justify-content-center"
            >
              <ErrorHandler
                retryLogic={() => {
                  setLoading(true);
                  setError(false);
                  loadCustomerIVR('MORE_ACTIONS', 'GET');
                }}
              />
            </div>
          );
        } else if (IVRDetails) {
          showcase = (
            <Container
              fluid
            >
              <ProcessingStatus
                show={loading || error}
                loading={loading}
                error={error}
                onRetry={InitiateIVRCall}
                onCancel={onCancel}
                border
              />
              <Row
                className="p-4"
              >
                <Col
                  xs={24}
                  lg={8}
                >
                  <Button
                    variant="outline-primary"
                    onClick={InitiateIVRCall}
                  >
                    Initiate Customer IVR Checks
                  </Button>
                </Col>
                <Col
                  xs="auto"
                  className="pt-2 fs-1"
                >
                  <b>Only one IVR check permitted in 15 days</b>
                </Col>
              </Row>
              <Row
                className="px-4 py-3 fs-2"
              >
                <b>View Previous IVR Campaign Responses</b>
              </Row>
              <Row>
                <Col
                  className="px-0"
                >
                  <CustomTable
                    headers={[
                      {
                        key: 'status',
                        displayText: 'Status',
                      },
                      {
                        key: 'dateCreated',
                        displayText: 'Created On',
                        renderer: (data) => dateString(data.dateCreated),
                      },
                      {
                        key: 'callInitialized',
                        displayText: 'Attempted',
                      },
                      {
                        key: 'callCompleted',
                        displayText: 'Completed',
                      },
                      {
                        key: 'callFailed',
                        displayText: 'Failed',
                      },
                      {
                        key: 'reportUrl',
                        displayText: 'Download',
                        renderer: (data) => getFileIcon(data.reportUrl),
                      },
                    ]}
                    content={IVRDetails.results}
                    keyField="campaignSid"
                    totalItems={IVRDetails.count}
                    isPaginated={false}
                  />
                </Col>
              </Row>
            </Container>
          );
        }
        return showcase;
      })()}
    </>
  );

  return (
    <>
      <CustomModal
        show={show}
        title="More Actions"
        size="xl"
        onHide={() => {
          setIVRDetails(null);
          onHide();
        }}
        body={body}
        closeButton
        backdrop
      />
      <CustomModal
        show={!!IVRError}
        title=" "
        autoSize
        onHide={() => {
          setIVRError('');
        }}
        closeButton
        backdrop
        border
        body={(
          <Container
            className="p-4"
          >
            <div
              className="d-flex align-items-center justify-content-between"
            >
              <div
                className="fs-1 text-danger"
              >
                {IVRError}
              </div>
              <div>
                <Button
                  variant="primary-dark"
                  onClick={() => {
                    setIVRError('');
                  }}
                  className="fs-01 mx-2"
                >
                  Okay
                </Button>
              </div>
            </div>
          </Container>
        )}
      />
    </>
  );
}

MoreActions.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  storeId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default MoreActions;
