import React, { Component } from 'react';
import {
  Container, Row, Col, Button, Spinner, InputGroup, Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import { platformFeeDashboard } from '../../assets/api/axios';
import { CustomTable, DatePicker, Svg } from '../../component/common';
import permission from '../../access&permissions/permission';
import { Constant } from '../../utilities';
import { dateString } from '../../utilities/Utils';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l,
    p,
    startDate,
    endDate,
  } = param;
  const { q = '' } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  startDate = Number(startDate) || new Date().getTime() - (1 * 24 * 60 * 60 * 1000);
  startDate = new Date(startDate).setHours(0, 0, 0, 0);
  endDate = Number(endDate) || new Date().setHours(23, 59, 59, 999);
  return ({
    ...param,
    q,
    l,
    p,
    startDate,
    endDate,
  });
};

class PlatformFee extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    const { userPermission } = this.props;
    this.canEdit = userPermission.includes(permission.BRAND_SAMPLING_CAMPAIGN_WRITE);
    this.state = {
      hasNext: false,
      hasPrevious: false,
      rowsPerPage: param.l,
      page: param.p,
      loading: true,
      error: false,
      errorMsg: '',
      platformFee: null,
      orderId: param.q,
      stDate: param.startDate,
      eDate: param.endDate,
      param,
      newParam: param,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleOnChange = (e) => {
    this.setState({
      orderId: e.target.value,
    });
  }

  onSubmitSearchText = () => {
    const {
      orderId, param,
    } = this.state;
    if (orderId !== param.q) {
      this.handleRequestProcessing({ q: orderId, p: 1 });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const {
      param, stDate, eDate,
    } = this.state;
    const newParam = {
      ...param,
      ...data,
      startDate: stDate,
      endDate: eDate,
    };
    const {
      l,
      p,
      q,
      startDate,
      endDate,
    } = newParam;
    const offset = (p);
    platformFeeDashboard(
      'GET',
      null,
      {
        page: `${offset}`,
        limit: l,
        startDate,
        endDate,
        orderId: q,
      },
      this.source.token,
    ).then((res) => {
      this.setState({
        platformFee: res.data,
        loading: false,
        hasNext: !!res.data.next,
        hasPrevious: !!res.data.prev,
        rowsPerPage: l,
        page: p,
        orderId: q,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch((err) => {
      let errorMsg = '';
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.errors
        && err.response.data.errors[0]
      ) {
        errorMsg = err.response.data.errors[0].message;
      }
      this.setState({
        error: true,
        loading: false,
        errorMsg,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
        errorMsg: '',
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handlePaymentDateChange = (startDate, endDate) => {
    this.setState({
      stDate: startDate,
      eDate: endDate,
    });
    // this.applyFilters();
  }

  applyFilters = () => {
    const { newParam, orderId } = this.state;
    this.handleRequestProcessing({
      ...newParam,
      q: orderId,
      p: 1,
    });
  }

  render() {
    const {
      param, loading, error, platformFee,
      rowsPerPage, page, hasNext, hasPrevious,
      stDate, eDate,
      orderId, errorMsg,
    } = this.state;

    const headers = [
      {
        key: 'orderId',
        displayText: 'Order Id',
        renderer: (data) => (
          <a
            href={`/order-details/${data.orderId}`}
            target="_blank"
            rel="noreferrer"
          >
            {data.orderId}
          </a>
        ),
      },
      {
        key: 'platformFee',
        displayText: 'Platform Fee',
      },
      {
        key: 'totalAmount',
        displayText: 'Total Amount',
      },
      {
        key: 'gst',
        displayText: 'GST',
      },
      {
        key: 'date',
        displayText: 'Date',
        renderer: (data) => (
          <span>
            {dateString(new Date(data.date))}
          </span>
        ),
      },
    ];

    const platformAggregateData = (platformFee) ? [
      {
        id: 1,
        name: 'Total',
        value: platformFee.totalAmount,
      },
      {
        id: 2,
        name: 'Total Platform Fee',
        value: platformFee.totalPlatformFee,
      },
      {
        id: 3,
        name: 'Total GST',
        value: platformFee.totalGst,
      },
    ] : [];

    return (
      <Container
        fluid
        className="h-100 bg-white table-list"
      >
        <Row
          className="py-2"
        >
          <Col
            xs={24}
          >
            <Row
              className="pb-3"
            >
              <Col
                xs={24}
                sm="auto"
                className="px-2 py-1"
              >
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className="rounded-0"
                    >
                      <Svg
                        svg="search"
                        width="1rem"
                        fill={Constant.Color.DARK}
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    placeholder="Order Id"
                    className="fs-01 rounded-0"
                    value={orderId}
                    onChange={this.handleOnChange}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        this.onSubmitSearchText();
                      }
                    }}
                  />
                </InputGroup>
              </Col>
              <Col
                xs="auto"
                className="d-flex align-items-center"
              >
                <div
                  className="fs-1 font-weight-bold"
                >
                  Date:
                  &nbsp;
                </div>
                <div>
                  <DatePicker
                    isDateRange
                    onApply={(dateRange) => {
                      this.handlePaymentDateChange(
                        new Date(dateRange.startDate).getTime(),
                        new Date(dateRange.endDate).getTime(),
                      );
                    }}
                    timePicker
                    startDate={stDate}
                    endDate={eDate}
                    maxDate={new Date().setHours(23, 59, 59, 999)}
                    onClear={() => {
                      this.setState({
                        stDate: '',
                        eDate: '',
                      });
                    }}
                  />
                </div>
              </Col>

              <Col
                xs="auto"
                className="d-flex align-items-center"
              >
                <Button
                  variant="primary"
                  className="py-1 fs-1 font-weight-bold"
                  onClick={() => {
                    this.applyFilters();
                  }}
                >
                  APPLY FILTERS
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row
          className="align-items-center justify-content-center"
        >
          {!!platformAggregateData.length && (
            platformAggregateData.map((item) => (
              <Col
                key={item.id}
                xs="auto"
                className="fs-01 mx-1 p-2 border border-disabled"
              >
                <div>
                  {item.name}
                  :
                  &nbsp;
                  ₹
                  {item.value}
                </div>
              </Col>
            ))
          )}
        </Row>
        <Row
          className="justify-content-center"
        >
          {
            (loading || error) && (
              <div
                className="pt-3"
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                ) : (
                  <>
                    <span
                      className="text-danger"
                    >
                      {errorMsg || 'Something Went Wrong...!'}
                    </span>
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => this.retry()}
                      >
                        Retry
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )
          }
        </Row>
        <Row className="bg-white">
          {
            !!platformFee && !loading && !error && (
              <Col
                xs={24}
                className="px-0 pt-2 table-section"
              >
                <CustomTable
                  headers={headers}
                  content={platformFee.data}
                  keyField="orderId"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={platformFee.count}
                  hasPrev={hasPrevious}
                  hasNext={hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            )
          }
        </Row>
      </Container>
    );
  }
}

PlatformFee.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default PlatformFee;
