import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import {
  catalogBulkUpload, noMatchProduct, strongMatchProduct, weakMatchProduct,
} from '../../assets/api/axios';
import {
  CustomDropdown, CustomTable, ErrorHandler,
} from '../../component/common';
import { Storage } from '../../utilities';

class ExportCatalogProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      highLoading: true,
      highError: false,
      weakLoading: true,
      weakError: false,
      noMatchLoading: true,
      noMatchError: false,
      productMatch: 'highMatch',
      action: '',
      highMatchProducts: null,
      weakMatchProducts: null,
      noMatchProducts: null,
      submitLoading: false,
      submitError: '',
      submitStatus: '',
      selectedProducts: [],
    };
  }

  componentDidMount = () => {
    this.handleHighMatch();
  }

  handleHighMatch = () => {
    const { location } = this.props;
    const { exportId } = location.state || '';
    this.setState({
      highLoading: true,
      highError: false,
    });
    strongMatchProduct(
      'GET',
      exportId,
    ).then((res) => {
      this.setState({
        highLoading: false,
        highMatchProducts: res.data,
      });
    }).catch(() => {
      this.setState({
        highLoading: false,
        highError: true,
      });
    });
  }

  handleWeakMatch = () => {
    const { location } = this.props;
    const { exportId } = location.state || '';
    this.setState({
      weakLoading: true,
      weakError: false,
    });
    weakMatchProduct(
      'GET',
      exportId,
    ).then((res) => {
      this.setState({
        weakLoading: false,
        weakMatchProducts: res.data,
      });
    }).catch(() => {
      this.setState({
        weakLoading: false,
        weakError: true,
      });
    });
  }

  handleNoMatch = () => {
    const { location } = this.props;
    const { exportId } = location.state || '';
    this.setState({
      noMatchLoading: true,
      noMatchError: false,
    });
    noMatchProduct(
      'GET',
      exportId,
    ).then((res) => {
      this.setState({
        noMatchLoading: false,
        noMatchProducts: res.data,
      });
    }).catch(() => {
      this.setState({
        noMatchLoading: false,
        noMatchError: true,
      });
    });
  }

  downloadCSVFile = () => {
    const { noMatchProducts } = this.state;
    if (
      noMatchProducts && noMatchProducts.results
      && noMatchProducts.results.length > 0
    ) {
      const headers = Object.keys(noMatchProducts.results[0]);
      const csv = [
        [headers],
        ...noMatchProducts.results.map((item) => [
          item.secondary_category_3,
          item.description,
          item.bar_code,
          item.sku_code,
          item.secondary_category_5,
          item.recomendationId,
          item.uom_qty,
          item.product_mrp,
          item.secondary_category_1,
          item.uom_type,
          item.image_url,
          item.secondary_category_2,
          item.product_selling_price,
          item.secondary_category_4,
          item.product_name,
          item.secondary_category_6,
          item.product_id,
        ]),
      ].map((e) => e.join(','))
        .join('\n');

      const hiddenElement = document.createElement('a');
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'no_match_products.csv';
      hiddenElement.click();
    }
  }

  handleSubmit = () => {
    const { selectedProducts, action, productMatch } = this.state;
    const { location } = this.props;
    const { storeId, exportId } = location.state || '';

    catalogBulkUpload(
      'PATCH',
      {
        agentId: Storage.getUserName(),
        exportId,
        data: selectedProducts,
        action,
      },
      storeId,
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          submitLoading: false,
          submitError: '',
          action: '',
          submitStatus: 'Submit successfully',
          selectedProducts: [],
        }, () => {
          setTimeout(() => {
            this.setState({
              submitStatus: '',
            });
          }, 3000);
          if (productMatch === 'highMatch') {
            this.handleHighMatch();
          } else {
            this.handleWeakMatch();
          }
        });
      } else {
        throw new Error();
      }
    }).catch((err) => {
      this.setState(() => {
        if (
          err
          && err.response
          && err.response.data
          && err.response.data.server_response
        ) {
          return {
            submitLoading: false,
            submitError: err.response.data.server_response,
          };
        }
        return {
          submitLoading: false,
          submitError: 'Oops something went wrong. Retry',
        };
      });
    });
  }

  selectRadio = (catalogProduct, matchProduct) => {
    const { selectedProducts } = this.state;
    return (
      <input
        type="radio"
        checked={
          ((selectedProducts.findIndex(
            (item) => item.recommendationId === catalogProduct.product_id
              && item.productId === matchProduct.productId,
          )) !== -1)
        }
        name={`mark-${catalogProduct.product_id}-${matchProduct.productId}`}
        onChange={() => {
          let products = selectedProducts;
          products = products.filter(
            (product) => product.recommendationId !== catalogProduct.product_id,
          );
          products.push({
            recommendationId: catalogProduct.product_id, productId: matchProduct.productId,
          });
          this.setState({
            selectedProducts: products,
          });
        }}
      />
    );
  }

  handleSelectAll = (event, catalogProducts) => {
    if (event.target.checked) {
      const selectProduct = [];
      catalogProducts.forEach((product) => {
        selectProduct.push({
          recommendationId: product.product_id, productId: product.match[0].productId,
        });
      });
      this.setState({
        selectedProducts: selectProduct,
      });
    } else {
      this.setState({
        selectedProducts: [],
      });
    }
  }

  render() {
    const {
      highLoading, weakLoading, highError, weakError, noMatchLoading, noMatchError,
      productMatch, action, submitLoading, submitError, submitStatus,
      highMatchProducts, weakMatchProducts, noMatchProducts,
      selectedProducts,
    } = this.state;

    const headers = [
      {
        key: 'product_name',
        displayText: 'Product Name',
      },
      {
        key: 'uom_type',
        displayText: 'UOM',
      },
      {
        key: 'uom_qty',
        displayText: 'Unit',
      },
      {
        key: 'product_mrp',
        displayText: 'MRP',
      },
      {
        key: 'product_selling_price',
        displayText: 'Selling Price',
      },
      {
        key: 'image_url',
        displayText: 'Image',
        renderer: (data) => (
          <div
            className="product-image-div"
          >
            <img
              src={data.image_url}
              alt=""
            />
          </div>
        ),
      },
      {
        key: 'match',
        displayText: productMatch === 'highMatch' ? (
          <>
            {
              highMatchProducts && highMatchProducts.results && (
                <>
                  <input
                    type="checkbox"
                    checked={
                      highMatchProducts.results.length === selectedProducts.length
                    }
                    onChange={(event) => {
                      this.handleSelectAll(
                        event, highMatchProducts.results,
                      );
                    }}
                    disabled={highMatchProducts.results.length === 0}
                    className="mx-1"
                  />
                  <span
                    className="pl-6"
                  >
                    Matched Product Details
                  </span>
                </>
              )
            }
          </>
        ) : (
          <div
            className="d-flex align-items-center
            justify-content-center"
          >
            Matched Product Details
          </div>
        ),
        renderer: (data) => (
          <div>
            <Row>
              <Col
                xs={24}
                className="px-0 py-0 table-list bg-white"
              >
                <CustomTable
                  headers={[
                    {
                      key: 'select',
                      displayText: productMatch === 'highMatch' ? (
                        ''
                      ) : 'Select',
                      renderer: (matchData) => this.selectRadio(data, matchData),
                    },
                    {
                      key: 'displayName',
                      displayText: 'Product Name',
                    },
                    {
                      key: 'uom',
                      displayText: 'UOM',
                    },
                    {
                      key: 'unit',
                      displayText: 'Unit',
                    },
                    {
                      key: 'mrp',
                      displayText: 'MRP',
                    },
                    {
                      key: 'productId',
                      displayText: 'Product Id',
                    },
                    {
                      key: 'imageUrl',
                      displayText: 'Image',
                      renderer: (matchData) => (
                        <div
                          className="product-image-div"
                        >
                          <img
                            src={matchData.imageUrl}
                            alt=""
                          />
                        </div>
                      ),
                    },
                  ]}
                  content={data.match}
                  keyField="productId"
                  totalItems={data.match.length}
                  isPaginated={false}
                />
              </Col>
            </Row>
          </div>
        ),
      },
    ];

    const showProductDetails = () => {
      if (productMatch === 'highMatch') {
        return (
          <>
            {(() => {
              let highShowcase = null;
              if (highLoading) {
                highShowcase = (
                  <div
                    className="d-flex align-items-center
                    justify-content-center pt-3"
                  >
                    <Spinner
                      animation="border"
                      variant="primary"
                    />
                  </div>
                );
              } else if (highError) {
                highShowcase = (
                  <div
                    className="d-flex align-items-center
                    justify-content-center pt-3"
                  >
                    <ErrorHandler
                      retryLogic={() => this.handleHighMatch()}
                    />
                  </div>
                );
              } else if (highMatchProducts) {
                highShowcase = (
                  <Row
                    className="overflow-y-scroll banner-container"
                  >
                    <Col
                      xs={24}
                      className="px-0 pt-4 table-list bg-white"
                    >
                      <CustomTable
                        headers={headers}
                        content={highMatchProducts.results}
                        keyField="product_id"
                        totalItems={highMatchProducts.count}
                        isPaginated={false}
                      />
                    </Col>
                  </Row>
                );
              }
              return highShowcase;
            })()}
          </>
        );
      }
      if (productMatch === 'weakMatch') {
        return (
          <>
            {(() => {
              let weakShowcase = null;
              if (weakLoading) {
                weakShowcase = (
                  <div
                    className="d-flex align-items-center
                    justify-content-center pt-3"
                  >
                    <Spinner
                      animation="border"
                      variant="primary"
                    />
                  </div>
                );
              } else if (weakError) {
                weakShowcase = (
                  <div
                    className="d-flex align-items-center
                    justify-content-center pt-3"
                  >
                    <ErrorHandler
                      retryLogic={() => this.handleWeakMatch()}
                    />
                  </div>
                );
              } else if (weakMatchProducts) {
                weakShowcase = (
                  <Row
                    className="overflow-y-scroll banner-container"
                  >
                    <Col
                      xs={24}
                      className="px-0 pt-4 table-list bg-white"
                    >
                      <CustomTable
                        headers={headers}
                        content={weakMatchProducts.results}
                        keyField="product_id"
                        totalItems={weakMatchProducts.count}
                        isPaginated={false}
                      />
                    </Col>
                  </Row>
                );
              }
              return weakShowcase;
            })()}
          </>
        );
      }
      if (productMatch === 'noMatch') {
        return (
          <>
            {(() => {
              let noShowCase = null;
              if (noMatchLoading) {
                noShowCase = (
                  <div
                    className="d-flex align-items-center
                      justify-content-center pt-3"
                  >
                    <Spinner
                      animation="border"
                      variant="primary"
                    />
                  </div>
                );
              } else if (noMatchError) {
                noShowCase = (
                  <div
                    className="d-flex align-items-center
                    justify-content-center pt-3"
                  >
                    <ErrorHandler
                      retryLogic={() => this.handleNoMatch()}
                    />
                  </div>
                );
              } else if (noMatchProducts) {
                noShowCase = (
                  <div
                    className="d-flex justify-content-center pt-5"
                  >
                    <Button
                      variant="primary"
                      onClick={() => {
                        this.downloadCSVFile();
                      }}
                    >
                      DOWNLOAD FILE
                    </Button>
                  </div>
                );
              }
              return noShowCase;
            })()}
          </>
        );
      }
      return '';
    };

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        <Row
          className="pt-3"
        >
          <Col
            xs={24}
            className="px-0 bg-white"
          >
            <Row
              className="mx-0 align-items-center pt-2 pb-3"
            >
              <Col
                xs="auto"
              >
                <b>ACTION :</b>
              </Col>
              <Col
                xs="auto"
              >
                <CustomDropdown
                  item={{
                    key: 'action',
                    displayText: 'Action',
                    options: [
                      {
                        label: 'Add to Catalogue',
                        value: 'ACCEPTED',
                      },
                      {
                        label: 'Rejected',
                        value: 'REJECTED',
                      },
                      {
                        label: 'Create',
                        value: 'CREATE',
                      },
                    ],
                  }}
                  onChange={(item) => {
                    this.setState({
                      action: item.action,
                    });
                  }}
                  selectedVal={action}
                  closeButton={false}
                />
              </Col>
              <Col
                xs="auto"
                className="pl-5"
              >
                <Button
                  onClick={() => {
                    this.setState({
                      submitLoading: true,
                      submitError: '',
                    }, this.handleSubmit);
                  }}
                  disabled={action === '' || submitLoading || selectedProducts.length === 0}
                >
                  {
                    submitLoading && (
                      <>
                        <Spinner
                          variant="secondary"
                          animation="border"
                          size="sm"
                        />
                        &nbsp;&nbsp;
                      </>
                    )
                  }
                  Submit
                </Button>
              </Col>

              {
                submitError && (
                  <Col
                    xs="auto"
                    className="text-danger"
                  >
                    <b>{submitError}</b>
                  </Col>
                )
              }
              {
                submitStatus && (
                  <Col
                    xs="auto"
                    className="text-success"
                  >
                    <b>{submitStatus}</b>
                  </Col>
                )
              }
            </Row>
          </Col>
          <Col
            xs={8}
            className="p-0"
          >
            <Button
              variant="link"
              block
              className={`rounded-0 border py-2 border-black ${
                productMatch === 'highMatch' ? 'bg-black text-white' : 'text-dark'
              }`}
              onClick={() => {
                if (productMatch !== 'highMatch') {
                  this.setState({
                    productMatch: 'highMatch',
                    selectedProducts: [],
                  }, this.handleHighMatch());
                }
              }}
            >
              <b>HIGH MATCH</b>
            </Button>
          </Col>
          <Col
            xs={8}
            className="p-0"
          >
            <Button
              variant="link"
              block
              className={`rounded-0 border py-2 border-black ${
                productMatch === 'weakMatch' ? 'bg-black text-white' : 'text-dark'
              }`}
              onClick={() => {
                if (productMatch !== 'weakMatch') {
                  this.setState({
                    productMatch: 'weakMatch',
                    selectedProducts: [],
                  }, this.handleWeakMatch());
                }
              }}
            >
              <b>WEAK MATCH</b>
            </Button>
          </Col>
          <Col
            xs={8}
            className="p-0"
          >
            <Button
              variant="link"
              block
              className={`rounded-0 border py-2 border-black ${
                productMatch === 'noMatch' ? 'bg-black text-white' : 'text-dark'
              }`}
              onClick={() => {
                if (productMatch !== 'noMatch') {
                  this.setState({
                    productMatch: 'noMatch',
                  }, this.handleNoMatch());
                }
              }}
            >
              <b>NO MATCH</b>
            </Button>
          </Col>
        </Row>
        {showProductDetails()}
      </Container>
    );
  }
}

ExportCatalogProductDetails.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      storeId: PropTypes.number,
      exportId: PropTypes.string,
    }),
  }).isRequired,
};

export default ExportCatalogProductDetails;
