import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import QueryString from 'query-string';
import { CustomTable, DatePicker, ErrorHandler } from '../../component/common';
import { ProcessingStatus } from '../../component/derived/table-list';
import { campaignAnalytics } from '../../assets/api/axios';
import { dateString } from '../../utilities/Utils';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, startDate, endDate,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  startDate = Number(startDate) || new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
  startDate = new Date(startDate).setHours(0, 0, 0, 0);
  endDate = Number(endDate) || new Date().setHours(23, 59, 59, 999);
  return ({
    ...param,
    l,
    p,
    startDate,
    endDate,
  });
};

class CampaignAnalytics extends React.Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    this.state = {
      loading: true,
      error: false,
      analytics: null,
      rowsPerPage: param.l,
      page: param.p,
      param,
    };
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, startDate, endDate,
    } = newParam;
    const offset = (p - 1) * l;

    campaignAnalytics(
      'GET',
      {
        offset: `${offset}`,
        limit: l,
        startDate,
        endDate,
      },
    ).then((res) => {
      this.setState({
        loading: false,
        analytics: res.data.data,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitPage = () => {
    const { page, param } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  onSubmitRowsPerPage = () => {
    const { rowsPerPage, param } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  handleRowPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  handleDateChange = (startDate, endDate) => {
    const { param } = this.state;
    if (startDate !== param.startDate
      || endDate !== param.endDate) {
      this.handleRequestProcessing({
        startDate,
        endDate,
        p: 1,
      });
    }
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const { l, p } = param;
    if (loading) {
      this.source.cancel();
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
    });
  }

  render() {
    const {
      loading, error, analytics, param,
      rowsPerPage, page,
    } = this.state;

    const headers = [
      {
        key: 'campaignType',
        displayText: 'Offer Type',
      },
      {
        key: 'date',
        displayText: 'Analytics Performed On',
        renderer: (data) => dateString(data.date),
      },
      {
        key: 'offerSentCount',
        displayText: 'Offer Sent',
      },
      {
        key: 'ordersWithOffersCount',
        displayText: 'Orders with Offers',
      },
      {
        key: 'storesWithOrdersCount',
        displayText: 'Stores with Orders',
      },
      {
        key: 'customersWithOrdersCount',
        displayText: 'Customers with Orders',
      },
      {
        key: 'averageOrderValue',
        displayText: 'Avg. Order Value',
      },
      {
        key: 'totalRevenue',
        displayText: 'Total Revenue',
      },
    ];

    return (
      <Container
        fluid
        className="h-100 bg-white px-0"
      >
        {(() => {
          let showcase = null;
          if (!analytics && loading) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center justify-content-center"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (!analytics && !loading && error) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center
                justify-content-center"
              >
                <ErrorHandler
                  retryLogic={() => this.retry()}
                />
              </div>
            );
          } else if (analytics) {
            showcase = (
              <>
                <ProcessingStatus
                  show={loading || error}
                  loading={loading}
                  error={error}
                  onRetry={this.retry}
                  onCancel={this.onCancel}
                />
                <Row
                  className="mx-0"
                >
                  <Col
                    xs={24}
                    sm="auto"
                    className="px-3 py-3"
                  >
                    <div
                      className="bg-light d-flex align-items-center justify-content-center"
                    >
                      <div className="px-1 fs-1 text-muted">
                        Campaign Date Range
                      </div>
                      <DatePicker
                        isDateRange
                        onApply={(dateRange) => {
                          this.handleDateChange(
                            new Date(dateRange.startDate).setHours(0, 0, 0),
                            new Date(dateRange.endDate).setHours(23, 59, 59),
                          );
                        }}
                        startDate={param.startDate}
                        endDate={param.endDate}
                        onClear={() => {
                          this.handleRequestProcessing({
                            startDate: '', endDate: '',
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    className="px-0"
                  >
                    <CustomTable
                      headers={headers}
                      content={analytics.results}
                      keyField="id"
                      l={param.l}
                      p={param.p}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      totalItems={analytics.count}
                      hasPrev={analytics.hasPrev}
                      hasNext={analytics.hasNext}
                      onNext={this.onNext}
                      onPrev={this.onPrev}
                      onSubmitPage={this.onSubmitPage}
                      onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                      updateRowsPageInput={this.handleRowPageInput}
                    />
                  </Col>
                </Row>
              </>
            );
          }
          return showcase;
        })()}
      </Container>
    );
  }
}

CampaignAnalytics.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default CampaignAnalytics;
