import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Button, Form, Spinner,
} from 'react-bootstrap';
import { CustomModal, Svg } from '../../common';
import { Constant } from '../../../utilities';
import { unitsMap } from '../../../utilities/Utils';

const LibraryProductModal = (props) => {
  const {
    onClose, product, variants, toggleVariant, mappingStatus,
    handleChange, isSubmitting, onSubmit, onDelivery, errors,
  } = props;
  const enabledVariants = variants.filter((item) => item.isEnabled);
  const toBeRemovedVariants = variants.filter((item) => item.toBeRemoved);
  const toBeUpdatedVariants = variants.filter((item) => item.toBeUpdated);
  const isSubmitDisabled = (
    enabledVariants.length === 0
    && toBeRemovedVariants.length === 0
    && toBeUpdatedVariants.length === 0
  );
  const body = (
    <Row
      className="h-100 flex-column mx-0"
    >
      <Col
        className="flex-grow-1 overflow-y"
      >
        <div
          className="p-4 border-bottom border-secondary"
        >
          <Row
            className="align-items-start justify-content-between mb-3"
          >
            <Col
              xs="auto"
            >
              <div
                className="product-image-container"
              >
                <img
                  src={product.imageUrl}
                  alt=""
                  className="product-image"
                />
              </div>
            </Col>
            <Col
              xs="auto"
            >
              <Button
                variant="link"
                onClick={onClose}
                className="p-0"
              >
                <Svg
                  svg="close"
                  width="20"
                  fill={Constant.Color.DARK}
                />
              </Button>
            </Col>
          </Row>
          <div
            className="font-weight-bold fs-0"
          >
            {product.displayName}
          </div>
        </div>
        <div>
          {variants.map((item, index) => {
            const isChecked = item.isEnabled || (item.alreadyMapped && !item.toBeRemoved);
            const isDisabled = onDelivery && item.alreadyMapped;
            let discount = '';
            if (item.sellingPrice && item.mrp) {
              const diff = parseFloat(item.mrp) - parseFloat(item.sellingPrice);
              if (diff > 0) {
                discount = diff;
              }
            }
            return (
              <Row
                key={item.id}
                className="mx-0 py-4 align-items-start"
              >
                <Col
                  xs="auto"
                >
                  <Button
                    variant="link"
                    onClick={() => {
                      toggleVariant(
                        item.alreadyMapped ? !item.toBeRemoved : !item.isEnabled,
                        index,
                      );
                    }}
                    disabled={isDisabled}
                    className="p-0"
                  >
                    <Svg
                      svg="checkedIcon"
                      width={24}
                      height={24}
                      borderStroke={
                        isChecked
                          ? Constant.Color.PRIMARY
                          : Constant.Color.SECONDARY
                      }
                      fill={
                        isChecked
                          ? (isDisabled
                            && Constant.Color.PRIMARY_LIGHT)
                            || Constant.Color.PRIMARY
                          : Constant.Color.TRANSPARENT
                      }
                      stroke={
                       isDisabled
                         ? Constant.Color.PRIMARY
                         : Constant.Color.WHITE
                      }
                    />
                  </Button>
                </Col>
                <Col>
                  <div
                    className="d-flex align-items-center py-2"
                  >
                    {
                      item.unit && item.uom ? (
                        <div
                          className="font-weight-bold fs-01"
                        >
                          {`${item.unit}${unitsMap[item.uom]}`}
                          &nbsp;
                          .
                          &nbsp;
                        </div>
                      ) : ''
                    }
                    {
                      !!discount
                      && (
                        <div
                          className="fs-01 text-success"
                        >
                          {`₹${discount < 10 ? '0' : ''}${discount} OFF`}
                        </div>
                      )
                    }
                  </div>
                  <Row
                    className="border mx-0 py-3 mb-2"
                  >
                    <Col
                      xs={12}
                    >
                      <div
                        className="fs-01 mb-2"
                      >
                      MRP (₹)
                      </div>
                      <Form.Control
                        type="text"
                        value={item.mrp}
                        onChange={(e) => {
                          handleChange(
                            'mrp',
                            e.target.value,
                            index,
                            item.alreadyMapped,
                          );
                        }}
                        disabled={!isChecked || isDisabled}
                        className="rounded-0"
                      />
                    </Col>
                    <Col
                      xs={12}
                    >
                      <div
                        className="fs-01 mb-2"
                      >
                      Selling Price (₹)
                      </div>
                      <Form.Control
                        type="text"
                        value={item.sellingPrice}
                        onChange={(e) => {
                          handleChange(
                            'sellingPrice',
                            e.target.value,
                            index,
                            item.alreadyMapped,
                          );
                        }}
                        disabled={!isChecked || isDisabled}
                        className="rounded-0"
                      />
                    </Col>
                  </Row>
                  {errors[item.id]
                    ? (
                      <div
                        className="fs-01 text-danger"
                      >
                        {errors[item.id]}
                      </div>
                    )
                    : ''}
                </Col>
              </Row>
            );
          })}
        </div>
      </Col>
      <Col
        className="flex-grow-0 px-0"
      >
        <Button
          onClick={() => {
            onSubmit(
              enabledVariants,
              toBeUpdatedVariants,
              toBeRemovedVariants,
            );
          }}
          disabled={isSubmitting || isSubmitDisabled}
          className="w-100 py-3 rounded-0"
        >
          <div
            className="d-flex align-items-center justify-content-center"
          >
            {
              isSubmitting
              && (
                <>
                  <Spinner
                    variant="white"
                    animation="border"
                    size="sm"
                  />
                      &nbsp;
                </>
              )
              }
            <div
              className="fs-0 text-white font-weight-bold"
            >
              {
                mappingStatus === 0 || onDelivery
                  ? 'ADD PRODUCT'
                  : 'UPDATE PRODUCT'
                }
            </div>
          </div>
        </Button>
      </Col>
    </Row>
  );
  return (
    <CustomModal
      show
      body={body}
      onHide={onClose}
    />
  );
};

LibraryProductModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  product: PropTypes.shape({
    imageUrl: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
  variants: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
  toggleVariant: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelivery: PropTypes.bool.isRequired,
  mappingStatus: PropTypes.number.isRequired,
  errors: PropTypes.shape({}).isRequired,
};

export default LibraryProductModal;
