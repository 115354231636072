import React, { useEffect, useState } from 'react';
import {
  Button, Container, Row, Spinner,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { CustomDropdown, CustomModal, CustomTable } from '../../component/common';
import CreateOrUpdateFestiveCat from './CreateOrUpdateFestiveCat';
import { festiveCategory } from '../../assets/api/axios';
import { dateString } from '../../utilities/Utils';
import permission from '../../access&permissions/permission';

function FestiveCategories(props) {
  const [festiveCats, setFestiveCats] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState('');
  const [selectedFestiveCat, selectFestiveCat] = useState(null);
  const [filters, setFilters] = useState({});
  const [showProducts, setShowProducts] = useState(null);
  const { userPermission } = props;
  const canEdit = userPermission.includes(permission.FESTIVE_CATEGORY_WRITE);

  const getFestiveCategories = (params = {}) => {
    setFilters({ ...filters, ...params });
    setLoadingStatus('LOADING');
    festiveCategory(
      'GET',
      params,
    ).then((res) => {
      setFestiveCats(res.data.data);
      setLoadingStatus('');
    }).catch(() => {
      setLoadingStatus('ERROR');
    });
  };

  useEffect(() => {
    getFestiveCategories();
  }, []);

  const headers = [
    {
      key: 'categoryCode',
      displayText: 'Slot',
      renderer: (data) => (
        <Button
          onClick={() => { selectFestiveCat(data); }}
          variant="link"
          className="p-0 fs-01"
          disabled={!canEdit}
        >
          {data.categoryCode}
        </Button>
      ),
    },
    {
      key: 'name',
      displayText: 'Name',
    },
    {
      key: 'active',
      displayText: 'Is Active',
      renderer: (data) => (
        <div
          className={data.active ? 'text-green' : 'text-danger'}
        >
          {data.active ? 'YES' : 'NO'}
        </div>
      ),
    },
    {
      key: 'launchDate',
      displayText: 'Launch On',
      renderer: (data) => dateString(data.launchDate),
    },
    {
      key: 'validTill',
      displayText: 'Valid Till',
      renderer: (data) => dateString(data.validTill),
    },
    {
      key: 'productsCount',
      displayText: 'Product Count',
      renderer: (data) => (
        <Button
          variant="link"
          className="fs-01"
          onClick={() => { setShowProducts(data); }}
        >
          {data.productsCount}
        </Button>
      ),
    },
    {
      key: 'modifiedAt',
      displayText: 'Last Modified On',
      renderer: (data) => dateString(data.modifiedAt),
    },
    {
      key: 'modifiedBy',
      displayText: 'Last Modiffied By',
    },
    {
      key: 'allStoreDeepLink',
      displayText: 'DeepLink For All Festive Stores',
    },
    {
      key: 'oneStoreDeepLink',
      displayText: 'Deeplink For Closest Match Store',
    },
  ];

  const productHeaders = [
    {
      key: 'id',
      displayText: 'Id',
    },
    {
      key: 'name',
      displayText: 'Name',
    },
    {
      key: 'imageUrl',
      displayText: 'Image',
      renderer: (data) => (
        <div>
          <img src={data.imageUrl} height={64} width={64} alt="Img" />
        </div>
      ),
    },
    {
      key: 'mrp',
      displayText: 'MRP',
    },
    {
      key: 'sellingPrice',
      displayText: 'Selling Price',
    },
  ];

  const closeModal = () => {
    selectFestiveCat(null);
  };

  if (loadingStatus === 'LOADING') {
    return (
      <div
        className="pt-3 text-center"
      >
        <Spinner
          animation="border"
          variant="primary"
        />
      </div>
    );
  }

  if (loadingStatus === 'ERROR') {
    return (
      <div
        className="pt-3 text-center"
      >
        <span
          className="text-danger"
        >
          Something Went Wrong
        </span>
        <div>
          <Button
            variant="primary"
            onClick={() => getFestiveCategories()}
          >
            Retry
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Container fluid>
      <CustomModal
        onHide={closeModal}
        show={!!selectedFestiveCat}
        body={(
          <CreateOrUpdateFestiveCat
            category={selectedFestiveCat}
            onSubmit={() => {
              closeModal();
              getFestiveCategories();
            }}
            onCancel={closeModal}
          />
        )}
        size="lg"
        title={selectedFestiveCat ? selectedFestiveCat.name : ''}
        closeButton
      />
      <CustomModal
        onHide={() => setShowProducts(null)}
        show={!!showProducts}
        body={(
          showProducts && (
            <CustomTable
              headers={productHeaders}
              content={showProducts.products}
              keyField="id"
              totalItems={showProducts.products.length}
              isPaginated={false}
            />
          )
        )}
        size="lg"
        title="Products"
        closeButton
      />
      <Row className="p-2">
        <CustomDropdown
          item={{
            key: 'isActive',
            displayText: 'Active Status',
            options: [
              {
                label: 'Active',
                value: 'true',
              },
              {
                label: 'Inactive',
                value: 'false',
              },
              {
                label: 'None',
                value: '',
              },
            ],
          }}
          onChange={(item) => {
            getFestiveCategories({
              isActive: item.isActive,
            });
          }}
          selectedVal={filters.isActive}
          closeButton
        />
      </Row>
      <Row>
        <CustomTable
          headers={headers}
          content={festiveCats}
          keyField="id"
          totalItems={festiveCats.length}
          isPaginated={false}
        />
      </Row>
    </Container>
  );
}

FestiveCategories.propTypes = {
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default FestiveCategories;
