import React, { useState } from 'react';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import PropType from 'prop-types';
import { CustomModal } from '../../component/common';
import { storeProfile, uploadImage } from '../../assets/api/axios';

function UploadKYC(props) {
  const {
    show, onHide, profile, onUploadKyc,
  } = props;
  const [personalKycType, setPersonalKycType] = useState('');
  const [personalKycTypeImage, setPersonalKycTypeImage] = useState('');
  const [businessKycType, setBusinessKycType] = useState('');
  const [businessKycTypeImage, setBusinessKycTypeImage] = useState('');
  const [shopInsideImage, setShopInsideImage] = useState('');
  const [shopOutsideImage, setShopOutsideImage] = useState('');
  const [ownerImage, setOwnerImage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [uploadingImg, setUploadingImg] = useState(false);

  const kycTypes = {
    personal_kyc: [
      {
        displayName: 'Aadhar Card',
        id: 'AADHAR_CARD',
      },
      {
        displayName: 'Driving License',
        id: 'DRIVING_LICENSE',
      },
      {
        displayName: 'Voter ID Card',
        id: 'VOTER_ID_CARD',
      },
    ],
    business_kyc: [
      {
        displayName: 'Udyog Aadhar Certificate',
        id: 'UDYOG_AADHAR_CERTIFICATE',
      },
      {
        displayName: 'Shop Establishment / Gumasta License',
        id: 'SHOP_ESTABLISHMENT_GUMASTA_LICENSE',
      },
      {
        displayName: 'Trade/Commercial Licence',
        id: 'TRADE_COMMERCIAL_LICENCE',
      },
      {
        displayName: 'GST Certificate',
        id: 'GST_CERTIFICATE',
      },
      {
        displayName: 'FSSAI Certificate',
        id: 'FSSAI_CERTIFICATE',
      },
    ],
  };

  const handleImageOnChange = (event, key) => {
    setUploadingImg(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('img', file);

    uploadImage(
      'post',
      formData,
    ).then((res) => {
      const imageURL = res.data.url;
      switch (key) {
        case 'PERSONAL_KYC':
          setPersonalKycTypeImage(imageURL);
          break;
        case 'BUSINESS_KYC':
          setBusinessKycTypeImage(imageURL);
          break;
        case 'SHOP_INSIDE':
          setShopInsideImage(imageURL);
          break;
        case 'SHOP_OUTSIDE':
          setShopOutsideImage(imageURL);
          break;
        case 'OWNER_IMAGE':
          setOwnerImage(imageURL);
          break;
        default:
          break;
      }
      setUploadingImg(false);
    }).catch(() => {
      setUploadingImg(false);
    });
  };

  const submitKyc = () => {
    setSubmitting(true);
    const data = {
      kyc: {
        status: 'PENDING',
        businessKycImage: businessKycTypeImage || profile.kyc.businessKycImage,
        personalKycImage: personalKycTypeImage || profile.kyc.personalKycImage,
        businessKycType: businessKycType || profile.kyc.businessKycType,
        personalKycType: personalKycType || profile.kyc.personalKycType,
        shopImages: {
          inside: shopInsideImage || (profile.kyc.shopImages ? profile.kyc.shopImages.inside : ''),
          outside: shopOutsideImage || (profile.kyc.shopImages ? profile.kyc.shopImages.outside : ''),
        },
        ownerImage: ownerImage || profile.kyc.ownerImage,
      },
    };

    storeProfile(
      'PATCH',
      profile.code,
      data,
    ).then((res) => {
      setSubmitting(false);
      onUploadKyc(res.data);
    }).catch(() => {
      setSubmitting(false);
      setError(true);
    });
  };

  return (
    <CustomModal
      show={show}
      title="Upload KYC"
      body={(
        <Container className="p-4">
          <Row className="py-2">
            <Col xs={8}>
              Personal KYC Type:
            </Col>
            <Col xs={16}>
              <select
                id="personal_kyc"
                className="form-control fs-01"
                value={personalKycType || profile.kyc.personalKycType}
                onChange={(e) => {
                  setPersonalKycType(e.target.value);
                }}
              >
                <option value="">Select</option>
                {
                  kycTypes.personal_kyc.map((item) => (
                    <option value={item.id} key={item.id}>{item.displayName}</option>
                  ))
                }
              </select>
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={8}>
              Personal KYC Image:
            </Col>
            <Col xs={16}>
              <Row className="mx-0">
                <input
                  type="file"
                  className="form-control input-file"
                  onChange={(e) => { handleImageOnChange(e, 'PERSONAL_KYC'); }}
                />
              </Row>
              <Row className="mx-0">
                <img
                  src={personalKycTypeImage || profile.kyc.personalKycImage}
                  alt=""
                  width="100px"
                  height="100px"
                />
              </Row>
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={8}>
              Business KYC Type:
            </Col>
            <Col xs={16}>
              <select
                id="business_kyc"
                className="form-control fs-01"
                value={businessKycType || profile.kyc.businessKycType}
                onChange={(e) => { setBusinessKycType(e.target.value); }}
              >
                <option value="">Select</option>
                {
                  kycTypes.business_kyc.map((item) => (
                    <option value={item.id} key={item.id}>{item.displayName}</option>
                  ))
                }
              </select>
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={8}>
              Business KYC Image:
            </Col>
            <Col xs={16}>
              <Row className="mx-0">
                <input
                  name="business-kyc"
                  type="file"
                  className="form-control input-file"
                  onChange={(e) => { handleImageOnChange(e, 'BUSINESS_KYC'); }}
                />
              </Row>
              <Row className="mx-0">
                <img
                  src={businessKycTypeImage || profile.kyc.businessKycImage}
                  alt=""
                  width="100px"
                  height="100px"
                />
              </Row>
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={8}>
              Store Inside Image:
            </Col>
            <Col xs={16}>
              <Row className="mx-0">
                <input
                  name="inside-img"
                  type="file"
                  className="form-control input-file"
                  onChange={(e) => { handleImageOnChange(e, 'SHOP_INSIDE'); }}
                />
              </Row>
              <Row className="mx-0">
                <img
                  src={shopInsideImage || (profile.kyc.shopImages ? profile.kyc.shopImages.inside : '')}
                  alt=""
                  width="100px"
                  height="100px"
                />
              </Row>
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={8}>
              Store Outside Image:
            </Col>
            <Col xs={16}>
              <Row className="mx-0">
                <input
                  name="outside-img"
                  type="file"
                  className="form-control input-file"
                  onChange={(e) => { handleImageOnChange(e, 'SHOP_OUTSIDE'); }}
                />
              </Row>
              <Row className="mx-0">
                <img
                  src={shopOutsideImage || (profile.kyc.shopImages ? profile.kyc.shopImages.outside : '')}
                  alt=""
                  width="100px"
                  height="100px"
                />
              </Row>
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={8}>
              Owner Image:
            </Col>
            <Col xs={16}>
              <Row className="mx-0">
                <input
                  name="owner-img"
                  type="file"
                  className="form-control input-file"
                  onChange={(e) => { handleImageOnChange(e, 'OWNER_IMAGE'); }}
                />
              </Row>
              <Row className="mx-0">
                <img
                  src={ownerImage || profile.kyc.ownerImage}
                  alt=""
                  width="100px"
                  height="100px"
                />
              </Row>
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={8}> </Col>
            <Col xs={16}>
              <Button
                onClick={() => { submitKyc(); }}
                disabled={
                  submitting
                  || uploadingImg
                  || (
                    !personalKycType && !personalKycTypeImage && !businessKycType
                    && !businessKycTypeImage && !shopInsideImage && !shopOutsideImage
                    && !ownerImage
                  )
                }
                className="font-weight-bold"
              >
                SUBMIT
              </Button>
              {
                submitting && (
                  <span className="p-2">
                    <Spinner
                      variant="primary"
                      animation="border"
                      size="sm"
                    />
                  </span>
                )
              }
              {
                error && (
                  <span className="p-2 text-danger">
                    Oops! Something went wrong, Please try again.
                  </span>
                )
              }
            </Col>
          </Row>
        </Container>
      )}
      onHide={() => {
        setPersonalKycType('');
        setPersonalKycTypeImage('');
        setBusinessKycType('');
        setBusinessKycTypeImage('');
        setShopInsideImage('');
        setShopOutsideImage('');
        setOwnerImage('');
        setSubmitting(false);
        setError(false);
        onHide();
      }}
      size="lg"
      closeButton
    />
  );
}

UploadKYC.propTypes = {
  show: PropType.bool.isRequired,
  onHide: PropType.func.isRequired,
  profile: PropType.shape({
    code: PropType.oneOfType(PropType.string, PropType.number),
    kyc: PropType.shape({
      personalKycType: PropType.string,
      businessKycType: PropType.string,
      personalKycImage: PropType.string,
      businessKycImage: PropType.string,
      shopImages: PropType.shape({
        outside: PropType.string,
        inside: PropType.string,
      }),
      ownerImage: PropType.string,
    }),
  }).isRequired,
  onUploadKyc: PropType.func.isRequired,
};

export default UploadKYC;
