import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import { agentCall } from '../../assets/api/axios';
import { CustomModal, ErrorHandler } from '../../component/common';
import { callStatusOptions } from '../../utilities/Utils';

const dispositionOptions = {
  '': [
    {
      label: 'Select',
      value: '',
    },
  ],
  Non_Connect: [
    {
      label: 'Select',
      value: '',
    },
    {
      label: 'RNR',
      value: 'RNR',
    },
    {
      label: 'Switched Off',
      value: 'Switched_Off',
    },
    {
      label: 'Invalid Number',
      value: 'Invalid_Number',
    },
    {
      label: 'Busy',
      value: 'Busy',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ],
  Callback: [
    {
      label: 'Select',
      value: '',
    },
    {
      label: 'Casual Callback',
      value: 'Casual_Callback',
    },
    {
      label: 'Potential Callback',
      value: 'Potential_Callback',
    },
  ],
  Document_Pending: [
    {
      label: 'Select',
      value: '',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ],
  Lost: [
    {
      label: 'Select',
      value: '',
    },
    {
      label: 'Fraud',
      value: 'Fraud',
    },
    {
      label: 'Low Shop Quality',
      value: 'Low_Shop_Quality',
    },
    {
      label: 'Irrelevant Category',
      value: 'Irrelevant_Category',
    },
    {
      label: 'Duplicate',
      value: 'Duplicate',
    },
    {
      label: 'No Document',
      value: 'No_Document',
    },
    {
      label: 'NC(Not Connected)',
      value: 'NC(Not_Connected)',
    },
    {
      label: 'Language Barrier',
      value: 'Language_Barrier',
    },
    {
      label: 'No Shop',
      value: 'No_Shop',
    },
    {
      label: 'Not Interested',
      value: 'Not_Interested',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ],
  Converted: [
    {
      label: 'Select',
      value: '',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ],
};

class ExotelCallPatchDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      callLogId: '',
      callStatus: '',
      disposition: '',
      remark: '',
      processingCall: false,
      callError: false,
      processingPatch: false,
      patchError: false,
      callRestrictionMsg: '',
    };
  }

  handleChangeExotelDetails = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  initiateCall = () => {
    const { leadId, exotelNumberCode } = this.props;
    agentCall(
      'post',
      {
        leadId,
        exotelNumberCode,
      },
      {},
    )
      .then((res) => {
        if (res && res.status === 200) {
          this.setState({
            callLogId: res.data.logId,
            processingCall: false,
            callError: false,
          });
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        this.setState(() => {
          if (
            err
            && err.response
            && err.response.data
            && err.response.data[0].server_message
          ) {
            return {
              processingCall: false,
              callRestrictionMsg: err.response.data[0].server_message,
            };
          }
          return {
            processingCall: false,
            callError: true,
          };
        });
      });
  }

  patchCallDetails = () => {
    const {
      callStatus, disposition, remark,
      callLogId,
    } = this.state;
    const { onHide, handleRequestProcessing } = this.props;
    agentCall(
      'PATCH',
      {
        callStatus,
        disposition,
        remark,
      },
      {},
      callLogId,
    ).then(() => {
      onHide();
      handleRequestProcessing();
    })
      .catch(() => {
        this.setState({
          processingPatch: false,
          patchError: true,
        });
      });
  }

  reset = () => {
    this.setState({
      callLogId: '',
      callStatus: '',
      disposition: '',
      remark: '',
      processingCall: false,
      callError: false,
      processingPatch: false,
      patchError: false,
    });
  }

  render() {
    const { show, onHide, border } = this.props;
    const {
      callStatus, disposition, remark,
      patchError, processingPatch,
      processingCall, callError, callLogId, callRestrictionMsg,
    } = this.state;

    let body = <div />;

    if (processingCall) {
      body = (
        <div
          className="text-center p-4"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!processingCall && callRestrictionMsg) {
      body = (
        <Container
          className="my-3"
        >
          <Row>
            <Col
              xs={24}
              className="fs-1 text-danger mb-4 text-center"
            >
              {callRestrictionMsg}
            </Col>
            <Col
              xs={24}
              className="d-flex align-items-center justify-content-center"
            >
              <Button
                onClick={onHide}
                className="mx-2 fs-1 rounded-0"
              >
                Okay
              </Button>
            </Col>
          </Row>
        </Container>
      );
    }

    if (!processingCall && !callRestrictionMsg && callError) {
      body = (
        <ErrorHandler
          retryLogic={() => {
            this.setState({
              processingCall: true,
              callError: false,
            }, () => {
              this.initiateCall();
            });
          }}
        />
      );
    }

    if (!processingCall && !callError && !!callLogId) {
      body = (
        <Container>
          <Row>
            <Col
              xs={24}
              className="p-2 bg-success text-center"
            >
              <b>Your call has been initiated successfully</b>
            </Col>

            <Col xs={24} className="mt-2 p-2 fs-2 text-center">
              <b>Disposition</b>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col xs={24}>
              <b>Call Status</b>
              <span className="text-danger">*</span>
            </Col>
            <Col xs={24} className="pt-2">
              <select
                name="callStatus"
                value={callStatus}
                className="form-control"
                onChange={(event) => {
                  if (event.target.value !== '') {
                    this.setState({
                      disposition: '',
                    });
                    this.handleChangeExotelDetails(event);
                  }
                }}
              >
                <option value="">Select</option>
                {callStatusOptions.map((call) => (
                  <option value={call.value}>{call.label}</option>
                ))}
              </select>
            </Col>
          </Row>

          <Row className="pt-4">
            <Col xs={24}>
              <b>Disposition</b>
              <span className="text-danger">*</span>
            </Col>
            <Col xs={24} className="pt-2">
              <select
                name="disposition"
                value={disposition}
                className="form-control"
                onChange={(event) => {
                  if (event.target.value !== '') {
                    this.handleChangeExotelDetails(event);
                  }
                }}
              >
                {
                  dispositionOptions[callStatus].map((status) => (
                    <option value={status.value}>{status.label}</option>
                  ))
                }
              </select>
            </Col>
          </Row>

          <Row className="pt-4">
            <Col xs={24}>
              <b>Remark</b>
              <span className="text-danger">*</span>
            </Col>
            <Col xs={24} className="pt-2">
              <input
                type="text"
                name="remark"
                value={remark}
                placeHolder="Enter the remark"
                className="form-control"
                maxLength={100}
                onChange={this.handleChangeExotelDetails}
              />
            </Col>
          </Row>

          <Row className="pt-4">
            <Col xs={24} className="text-center">
              {processingPatch
                ? (
                  <Spinner
                    variant="primary"
                    animation="border"
                  />
                ) : (
                  <>
                    <Button
                      variant="primary"
                      block
                      onClick={() => {
                        this.setState({
                          processingPatch: true,
                          patchError: false,
                        }, () => {
                          this.patchCallDetails();
                        });
                      }}
                      disabled={!callStatus || !disposition || !remark}
                    >
                      Submit
                    </Button>
                    <div className="py-2 text-danger">
                      {!processingPatch && patchError
                      && (
                        <b>Oops Something went wrong!!</b>
                      )}
                    </div>
                  </>
                )}
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <CustomModal
        show={show}
        body={body}
        onHide={onHide}
        onEnter={() => {
          this.setState({
            processingCall: true,
          }, () => {
            this.initiateCall();
          });
        }}
        autoSize
        backdrop={processingCall
          || callError || 'static'}
        reset={this.reset}
        border={border}
      />
    );
  }
}

ExotelCallPatchDetails.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  leadId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  border: PropTypes.bool,
  exotelNumberCode: PropTypes.string,
  handleRequestProcessing: PropTypes.func.isRequired,
};

ExotelCallPatchDetails.defaultProps = {
  leadId: '',
  border: false,
  exotelNumberCode: '',
};

export default ExotelCallPatchDetails;
