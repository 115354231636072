import * as React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
  const {
    width, height, fill, stroke, isCircle, borderStroke,
  } = props;
  return (
    <svg
      style={{
        width,
        height,
      }}
      viewBox="0 0 72 72"
    >
      <g
        fill="none"
        fillRule="evenodd"
      >
        {
          isCircle ? (
            <circle
              cx={36}
              cy={36}
              r={27}
              fill={fill}
              stroke={borderStroke}
              strokeWidth={1.5}
              fillRule="nonzero"
            />
          ) : (
            <rect
              width={54}
              height={54}
              x={9}
              y={9}
              fill={fill}
              stroke={borderStroke}
              strokeWidth={2}
              fillRule="nonzero"
              rx={2}
            />
          )
        }
        <path
          stroke={stroke}
          strokeWidth={5}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M27.563 37.35l4.82 5.4m0 0l12.055-13.5"
        />
      </g>
    </svg>
  );
};

Svg.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  isCircle: PropTypes.bool,
  borderStroke: PropTypes.string,
};

Svg.defaultProps = {
  isCircle: false,
  borderStroke: 'none',
  fill: 'none',
  stroke: 'none',
};

export default Svg;
