import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import { Constant } from '../../utilities';
import Svg from '../../component/common/svg/EditIcon';

const AddressForm = ({
  handleChange, handleChangeAddressType, handleSubmitAddress, selectedAddress,
  ...state
}) => {
  const [editing, setEditing] = useState(!selectedAddress.isCompleted);
  return (
    <Form>
      <h4>
        Complete Address
        <Button
          variant="link"
          className="ml-2"
          onClick={() => {
            setEditing(true);
          }}
        >
          <Svg
            svg="editIcon"
            height={24}
            width={24}
            stroke={Constant.Color.PRIMARY}
          />
        </Button>
      </h4>
      <Form.Group>
        <Form.Label>Flat / House Number</Form.Label>
        <Form.Control
          name="flatNumber"
          type="text"
          placeholder="Flat / House Number"
          value={state.flatNumber}
          onChange={handleChange}
          autoComplete="off"
          disabled={selectedAddress.isCompleted && !editing}
        />
      </Form.Group>
      <Form.Group controlId="buildingName">
        <Form.Label>Building / Society name</Form.Label>
        <Form.Control
          name="buildingName"
          type="text"
          placeholder="Building / Society name"
          value={state.buildingName}
          onChange={handleChange}
          autoComplete="off"
          disabled={selectedAddress.isCompleted && !editing}
        />
      </Form.Group>
      <Form.Group controlId="streetName">
        <Form.Label>Street Name / Number</Form.Label>
        <Form.Control
          name="streetName"
          type="text"
          placeholder="Street Name / Number"
          value={state.streetName}
          onChange={handleChange}
          autoComplete="off"
          disabled={selectedAddress.isCompleted && !editing}
        />
      </Form.Group>
      <Form.Group controlId="localityName">
        <Form.Label>Area / Locality Name</Form.Label>
        <Form.Control
          name="localityName"
          type="text"
          placeholder="Area / Locality Name"
          value={state.localityName}
          onChange={handleChange}
          autoComplete="off"
          disabled={selectedAddress.isCompleted && !editing}
        />
      </Form.Group>
      <Form.Group controlId="landmark">
        <Form.Label>Landmark</Form.Label>
        <Form.Control
          name="landmark"
          type="text"
          placeholder="Landmark"
          value={state.landmark}
          onChange={handleChange}
          autoComplete="off"
          disabled={selectedAddress.isCompleted && !editing}
        />
      </Form.Group>
      <Form.Group controlId="addressText">
        <Form.Label>Location</Form.Label>
        <Form.Control
          name="addressText"
          type="text"
          value={selectedAddress.addressText}
          disabled
        />
      </Form.Group>
      <Form.Group controlId="addressType">
        <Form.Check
          custom
          inline
          label="Home"
          type="radio"
          id="Home"
          checked={state.addressType === 'Home'}
          onChange={handleChangeAddressType}
          disabled={selectedAddress.isCompleted && !editing}
        />
        <Form.Check
          custom
          inline
          label="Work"
          type="radio"
          id="Work"
          checked={state.addressType === 'Work'}
          onChange={handleChangeAddressType}
          disabled={selectedAddress.isCompleted && !editing}
        />
        <Form.Check
          custom
          inline
          label={(
            <Form.Control
              className="border-0 p-0 h-auto w-50 other-address-type rounded-0 shadow-none bg-transparent"
              id="Other"
              name="otherAddressType"
              type="text"
              placeholder="Other..."
              value={state.otherAddressType}
              onChange={handleChange}
              onFocus={handleChangeAddressType}
              disabled={selectedAddress.isCompleted && !editing}
            />
          )}
          type="radio"
          id="Other"
          checked={state.addressType === 'Other'}
          onChange={handleChangeAddressType}
          disabled={selectedAddress.isCompleted && !editing}
        />
      </Form.Group>
      {
        selectedAddress.isCompleted && !editing && (
          <Button
            className="px-4"
            onClick={handleSubmitAddress}
          >
            <b>Proceed</b>
          </Button>
        )
      }
      {
        (
          !selectedAddress.isCompleted || editing
        ) && (
          <Button
            className="px-4"
            onClick={handleSubmitAddress}
          >
            <b>Save</b>
          </Button>
        )
      }
    </Form>
  );
};

AddressForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleChangeAddressType: PropTypes.func.isRequired,
  handleSubmitAddress: PropTypes.func.isRequired,
  selectedAddress: PropTypes.shape({
    addressText: PropTypes.string,
    isCompleted: PropTypes.bool,
  }).isRequired,
};

export default AddressForm;
