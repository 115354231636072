import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

function Backdrop(props) {
  const { show, onFade } = props;
  return (
    <Col
      id="backdrop"
      className={show ? '' : 'd-none'}
      onClick={() => onFade()}
    />
  );
}

Backdrop.propTypes = {
  show: PropTypes.bool.isRequired,
  onFade: PropTypes.func.isRequired,
};

export default Backdrop;
