/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { dateString } from '../../utilities/Utils';
import {
  CustomModal, CustomTable, ErrorHandler, Svg,
} from '../../component/common';
import { ProcessingStatus } from '../../component/derived/table-list';
import { citySpecificRewards } from '../../assets/api/axios';
import CreateCityRewards from './CreateCityRewards';

function ViewCityRewards(props) {
  const {
    show, onHide,
  } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [editRewardModal, setEditRewardModal] = useState(false);
  const [createRewardModal, setCreateRewardModal] = useState(false);
  const [selectedReward, setSelectedReward] = useState([]);
  const [cities, setCities] = useState([]);
  const [status, setStatus] = useState(false);
  const [l, setL] = useState(10);
  const [p, setP] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [results, setResults] = useState([]);

  function renderActiveStatus(data) {
    if (data === true) {
      return <span className="text-success">&#10004;</span>;
    }
    return <span className="text-danger">&#10006;</span>;
  }

  const handleLoad = (params = {}, newStatus) => {
    const newParams = {
      pageNumber, rowsPerPage, ...params,
    };
    setLoading(true);
    setError(false);

    citySpecificRewards(
      'GET',
      {
        page: pageNumber,
        limit: rowsPerPage,
        ...newStatus ? { isActive: newStatus } : '',
      },
    )
      .then((res) => {
        setResults(res.data);
        setRewards(res.data.data);
        setCities(res.data.availableStoreCities);
        setP(newParams.pageNumber);
        setL(newParams.rowsPerPage);
        setRowsPerPage(newParams.rowsPerPage);
        setLoading(false);
        if (res.data.next === '') {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  const onNext = () => {
    handleLoad({ page: pageNumber + 1 });
  };

  const onPrev = () => {
    if (pageNumber > 1) {
      handleLoad({ page: pageNumber - 1 });
    }
  };

  const onSubmitRowsPerPage = () => {
    handleLoad();
  };

  const handleRowsPageInput = (value, field) => {
    if (field === 'rowsPerPage') { setRowsPerPage(value); } else { setPageNumber(value); }
  };

  const onSubmitPage = () => {
    handleLoad();
  };

  useEffect(() => {
    handleLoad();
  }, []);

  const headers = [
    {
      key: 'id',
      displayText: 'Reward Id',
      renderer: (data) => (
        <Button
          variant="link"
          onClick={() => {
            setEditRewardModal(true);
            setSelectedReward(data);
          }}
          className="fs-01 p-0"
        >
          {data.id}
        </Button>
      ),
    },
    {
      key: 'city',
      displayText: 'City',

    },
    {
      key: 'rewardAmount',
      displayText: 'Reward Amount',
    },
    {
      key: 'createdOn',
      displayText: 'Created On',
      renderer: (data) => dateString(data.createdOn),
    },
    {
      key: 'modifiedOn',
      displayText: 'Modified On',
      renderer: (data) => dateString(data.modifiedOn),
    },
    {
      key: 'isActive',
      displayText: 'Is Active',
      renderer: (data) => renderActiveStatus(data.isActive),
    },
  ];

  const body = (
    <Container
      fluid
      className="h-100 bg-white px-0"
    >
      {(() => {
        let showcase = null;
        if (loading) {
          showcase = (
            <div
              className="h-100 d-flex align-items-center
              justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          );
        } else if (!rewards && !loading && error) {
          showcase = (
            <div
              className="h-100 d-flex align-items-center
              justify-content-center"
            >
              <ErrorHandler />
            </div>
          );
        } else if (rewards) {
          showcase = (
            <>
              <ProcessingStatus
                show={loading || error}
                loading={loading}
                error={error}
                onRetry={() => {}}
                onCancel={() => {}}
              />
              <Row
                className="mx-0"
              >
                <Col
                  className="d-flex p-3"
                >
                  <Button
                    variant={status ? 'primary' : 'secondary'}
                    className="fs-01 px-4 m-0 font-weight-bold"
                    onClick={() => {
                      const newStatus = !status;
                      setStatus(newStatus);
                      handleLoad('', newStatus);
                    }}
                  >
                    Is Active
                  </Button>
                </Col>
                <Col
                  className="d-flex justify-content-end p-3"
                >
                  <Button
                    variant="primary"
                    className="fs-01 mx-2 font-weight-bold"
                    onClick={() => {
                      setCreateRewardModal(true);
                    }}
                  >
                    <Svg
                      svg="add"
                      width="20"
                      height="20"
                    />
                    &nbsp;
                    ADD
                  </Button>
                </Col>
                <Col
                  xs={24}
                  className="px-0 pt-2 table-section"
                >
                  <CustomTable
                    headers={headers}
                    content={rewards}
                    keyField="id"
                    totalItems={results.count}
                    isPaginated
                    rowsPerPage={rowsPerPage}
                    l={l}
                    p={p}
                    page={pageNumber}
                    hasPrev={results.prev !== ''}
                    hasNext={results.next !== ''}
                    onNext={onNext}
                    onPrev={onPrev}
                    onSubmitPage={onSubmitPage}
                    onSubmitRowsPerPage={onSubmitRowsPerPage}
                    updateRowsPageInput={handleRowsPageInput}
                  />
                </Col>
              </Row>
            </>
          );
        }
        return showcase;
      })()}
    </Container>
  );

  return (
    <>
      {
        createRewardModal && (
          <CreateCityRewards
            show
            refresh={() => handleLoad()}
            onHide={() => setCreateRewardModal(false)}
            createReward={createRewardModal}
            cities={cities}
          />
        )
      }
      {
        editRewardModal && (
          <CreateCityRewards
            show
            reward={selectedReward}
            refresh={() => handleLoad()}
            editReward={editRewardModal}
            onHide={() => setEditRewardModal(false)}
            cities={cities}
          />
        )
      }
      <CustomModal
        show={show}
        title="City Specific Rewards"
        size="xl"
        body={body}
        onHide={() => {
          onHide();
        }}
        closeButton
        backdrop
      />
    </>
  );
}

ViewCityRewards.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
export default ViewCityRewards;
