import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import {
  Button, Container, Spinner, Row, Col,
  InputGroup, Form,
} from 'react-bootstrap';
import { flashsalemeta } from '../../assets/api/axios';
import { CustomTable, CustomModal, CustomDropdown } from '../../component/common';
import Svg from '../../component/common/Svg';
import Constant from '../../utilities/Constant';
import { dateString } from '../../utilities/Utils';
import permission from '../../access&permissions/permission';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let { l, p, isActive = 'true' } = param;
  const {
    sid = '', productSearchText = '', atleastOneOrder,
    jobId = '', libraryProductIds = '', typeofUser = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  isActive = isActive === 'true';
  return ({
    l,
    p,
    sid,
    productSearchText,
    isActive,
    atleastOneOrder,
    jobId,
    libraryProductIds,
    typeofUser,
  });
};

class FlashSaleDetailed extends Component {
  constructor(props) {
    super(props);
    const { history } = this.props;
    const params = getQueryParams(history.location.search);
    this.state = {
      flashSaleRecords: null,
      storeId: params.sid,
      productSearchText: params.productSearchText,
      jobId: params.jobId,
      libraryProductIds: params.libraryProductIds,
      isActive: params.isActive,
      atleastOneOrder: params.atleastOneOrder,
      rowsPerPage: params.l,
      page: params.p,
      params,
      loading: true,
      error: false,
      stopFlashSale: null,
      stopType: 'STOP_ONLY',
      reason: '',
      submitting: false,
      submitError: false,
      submitStatusMsg: '',
      selectionType: '',
      flashSaleIds: [],
      stoppingFlashSaleOffers: false,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount() {
    this.handleLoad();
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { params } = this.state;
    const newParams = { ...params, ...data };
    const offset = (newParams.p - 1) * newParams.l;
    const {
      l, sid, productSearchText, isActive, atleastOneOrder,
      jobId, libraryProductIds, typeofUser,
    } = newParams;
    flashsalemeta(
      'GET',
      {
        offset: `${offset}`,
        limit: l,
        viewType: 'detailed',
        storeIds: sid,
        productSearchText,
        onlyActive: isActive,
        leastOneOrder: atleastOneOrder,
        jobId,
        libraryProductIds,
        typeofUser,
      },
      null,
      this.source.token,
    ).then((res) => {
      this.setState({
        selectionType: '',
        flashSaleIds: [],
        flashSaleRecords: res.data,
        rowsPerPage: newParams.l,
        page: newParams.p,
        params: newParams,
        loading: false,
      }, () => {
        if (Object.keys(newParams).find((key) => newParams[key] !== params[key])) {
          Object.keys(newParams).forEach((key) => {
            if (!newParams[key]) {
              delete newParams[key];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParams),
          });
        }
      });
    }).catch(() => {
      this.setState({
        error: true,
      });
    });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad(newParams);
      });
    };
  }

  handleRequestProcessing = (data) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      loading: true,
      error: false,
    }, () => {
      this.handleLoad(data);
    });
  }

  onNext = () => {
    const { params } = this.state;
    this.handleRequestProcessing({ p: params.p + 1 });
  }

  onPrev = () => {
    const { params } = this.state;
    this.handleRequestProcessing({ p: params.p - 1 });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onSubmitPage = () => {
    const { params, page } = this.state;
    if (params.p !== page) {
      this.handleRequestProcessing({ p: page });
    }
  }

  onSubmitRowsPerPage = () => {
    const { params, rowsPerPage } = this.state;
    if (params.l !== rowsPerPage) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onSubmitStoreId = () => {
    const { storeId, params } = this.state;
    if (storeId !== params.sid) {
      this.handleRequestProcessing({ sid: storeId, p: 1 });
    }
  }

  onSubmitSearchText = () => {
    const { productSearchText, params } = this.state;
    if (productSearchText !== params.productSearchText) {
      this.handleRequestProcessing({ productSearchText, p: 1 });
    }
  }

  onSubmitJobId = () => {
    const { jobId, params } = this.state;
    if (jobId !== params.jobId) {
      this.handleRequestProcessing({ jobId, p: 1 });
    }
  }

  onSubmitLibraryProductIds = () => {
    const { libraryProductIds, params } = this.state;
    if (libraryProductIds !== params.libraryProductIds) {
      this.handleRequestProcessing({ libraryProductIds, p: 1 });
    }
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  handleStopFlashSale = () => {
    const {
      stopFlashSale, stopType, reason, flashSaleIds,
    } = this.state;
    flashsalemeta(
      'PATCH',
      {},
      {
        action: stopType,
        body: {
          ids: stopFlashSale ? [stopFlashSale.pk] : flashSaleIds,
          reason,
        },
      },
      this.source.token,
    ).then((res) => {
      this.setState({
        ...(
          res && res.data && res.data.server_response
            ? { submitStatusMsg: res.data.server_response, submitting: false }
            : {}
        ),
      }, () => {
        setTimeout(() => {
          this.setState({
            selectionType: '',
            flashSaleIds: [],
            stoppingFlashSaleOffers: false,
            stopFlashSale: null,
            submitStatusMsg: '',
            reason: '',
            stopType: 'STOP_ONLY',
            loading: true,
          }, () => {
            this.handleLoad();
          });
        }, 5000);
      });
    }).catch((err) => {
      this.setState({
        ...(
          err && err.response
          && err.response.data
          && err.response.data.server_response
            ? {
              submitStatusMsg: err.response.data.server_response,
              submitting: false,
              submitError: true,
            }
            : { submitting: false, submitError: true }
        ),
      }, () => {
        setTimeout(() => {
          this.setState({
            stopFlashSale: null,
            submitStatusMsg: '',
          }, () => {
            this.handleLoad();
          });
        }, 5000);
      });
    });
  }

  handleGroupSelection = (data) => {
    const { selectionType } = data;
    const { flashSaleRecords } = this.state;
    let newFlashSaleIds = [];
    if (selectionType === 'all') {
      newFlashSaleIds = flashSaleRecords.results.filter((item) => item.isActive)
        .map((item) => item.pk);
    }
    this.setState({
      flashSaleIds: newFlashSaleIds,
      selectionType,
    });
  }

  handleFlashSaleSelection = (e, s) => {
    const { checked } = e.target;
    const { flashSaleIds } = this.state;
    const newFlashSaleIds = flashSaleIds;

    if (checked) {
      newFlashSaleIds.push(s.pk);
    } else {
      const index = newFlashSaleIds.indexOf(s.pk);
      if (index > -1) {
        newFlashSaleIds.splice(index, 1);
      }
    }
    this.setState({
      flashSaleIds: newFlashSaleIds,
    });
  }

  render() {
    const {
      flashSaleRecords, rowsPerPage, page, params,
      loading, error, storeId, stopFlashSale,
      submitting, submitError, productSearchText,
      isActive, atleastOneOrder, reason, stopType,
      submitStatusMsg, jobId, libraryProductIds,
      selectionType, flashSaleIds, stoppingFlashSaleOffers,
    } = this.state;

    const { userPermission } = this.props;
    const showStopFlashSale = userPermission.includes(permission.FLASH_SALE_DETAILED_WRITE);

    let headers = [
      {
        key: 'pk',
        displayText: 'Id',
      },
      {
        key: 'isActive',
        displayText: 'Active',
        renderer: (data) => (
          <span className={`${data.isActive ? 'text-green' : 'text-danger'}`}>
            {data.isActive ? 'YES' : 'NO'}
          </span>
        ),
      },
      ...(
        showStopFlashSale
          ? [
            {
              key: 'stopFlashSale',
              displayText: 'Stop Falsh Sale',
              renderer: (data) => {
                if (data.isActive) {
                  return (
                    <Button
                      variant="link"
                      onClick={() => {
                        this.setState({ stopFlashSale: data });
                      }}
                      className="text-danger fs-01 p-0"
                    >
                      STOP
                    </Button>
                  );
                }
                return (
                  <span>SEALED</span>
                );
              },
            },
          ] : []
      ),
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => (
          <>{dateString(data.createdOn)}</>
        ),
      },
      {
        key: 'startDate',
        displayText: 'Started On',
        renderer: (data) => (
          <>{dateString(data.startDate)}</>
        ),
      },
      {
        key: 'expiryDate',
        displayText: 'Expiry Date',
        renderer: (data) => (
          <>{dateString(data.expiryDate)}</>
        ),
      },
      {
        key: 'jobId',
        displayText: 'Job Id',
      },
      {
        key: 'storeCode',
        displayText: 'Store Code',
      },
      {
        key: 'storeName',
        displayText: 'Store Name',
      },
      {
        key: 'typeofUser',
        displayText: 'Customer Type',
      },
      {
        key: 'retailerProductId',
        displayText: 'Retailer Product Id',
      },
      {
        key: 'libraryProductId',
        displayText: 'Library Product Id',
      },
      {
        key: 'libraryProductName',
        displayText: 'Library Product Name',
      },
      {
        key: 'libraryProductMrp',
        displayText: 'Library Product Mrp',
      },
      {
        key: 'minSpendAmount',
        displayText: 'Min Spend Amt',
      },
      {
        key: 'discountAmount',
        displayText: 'Discount Amt',
      },
      {
        key: 'totalOrderCount',
        displayText: 'Total Order Count',
      },
      {
        key: 'pendingOrderCount',
        displayText: 'Pending Order Count',
      },
      {
        key: 'rtsOrderCount',
        displayText: 'RTS Order Count',
      },
      {
        key: 'ofdOrderCount',
        displayText: 'OFD Order Count',
      },
      {
        key: 'deliveredOrderCount',
        displayText: 'Deliver Order Count',
      },
      {
        key: 'cancelledOrderCount',
        displayText: 'Cancelled Order Count',
      },
    ];

    const filterConf = [{
      key: 'typeofUser',
      displayText: 'Type of User',
      options: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'FIRST TIME USER',
          value: 'FIRST_TIME_USER',
        },
        {
          label: 'EXISTING USER',
          value: 'EXISTING_USER',
        },
        {
          label: 'GUEST USER',
          value: 'GUEST_USER',
        },
      ],
    },
    ];

    if (selectionType) {
      headers = [
        {
          key: 'action-dropdown',
          displayText: (
            <CustomDropdown
              item={{
                key: 'action',
                displayText: 'Action',
                options: [
                  {
                    label: 'STOP',
                    value: 'STOP',
                  },
                ],
              }}
              onChange={() => {
                if (flashSaleIds.length) {
                  this.setState({
                    stoppingFlashSaleOffers: true,
                  });
                }
              }}
              selectedVal=""
              disabled={stoppingFlashSaleOffers}
            />
          ),
          renderer: (data) => {
            if (!data.isActive) {
              return '';
            }
            return (
              <Form.Check
                custom
                id={`${data.pk}-checkbox`}
                label=""
                value={data.pk}
                checked={flashSaleIds.includes(data.pk)}
                onChange={(e) => this.handleFlashSaleSelection(e, data)}
              />
            );
          },
        },
      ].concat(headers);
    }

    const confirmationBody = (
      <Container>
        <Row
          className="pt-3"
        >
          <Col
            xs={8}
          >
            <b>Stop Type:</b>
          </Col>
          <Col
            className="px-3"
            xs={16}
          >
            <select
              name="stopType"
              id="stopType"
              className="form-control"
              value={stopType}
              onChange={(e) => { this.setState({ stopType: e.target.value }); }}
            >
              <option value="STOP_AND_CLEAR_FROM_CART">STOP_AND_CLEAR_FROM_CART</option>
              <option value="STOP_ONLY">STOP_ONLY</option>
            </select>
          </Col>
        </Row>
        <Row
          className="pt-3"
        >
          <Col
            xs={8}
          >
            <b>Reason:</b>
          </Col>
          <Col
            className="px-3"
            xs={16}
          >
            <input
              type="text"
              className="form-control"
              name="reason"
              value={reason}
              onChange={this.handleOnChange}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={24}
            className="text-center pt-3"
          >
            <Button
              variant="primary"
              className="px-3 py-2"
              disabled={
                !stopType
                || submitting
                || !!submitStatusMsg
              }
              onClick={() => {
                this.setState({
                  submitting: true,
                }, () => { this.handleStopFlashSale(); });
              }}
            >
              {
                submitting && (
                  <Spinner
                    variant="light"
                    size="sm"
                    animation="border"
                  />
                )
              }
              &nbsp;&nbsp;&nbsp;Submit&nbsp;&nbsp;&nbsp;
            </Button>
          </Col>
          {submitError && (
            <Col
              xs={24}
              className="text-center text-danger pt-3"
            >
              <b>Oops Something went Wrong!!</b>
            </Col>
          )}
          {
            submitStatusMsg && (
              <Col
                xs={24}
                className="text-center pt-3"
              >
                Response message:&nbsp;
                <span className={`${submitError ? 'text-danger' : 'text-green'}`}>{submitStatusMsg}</span>
              </Col>
            )
          }
        </Row>
      </Container>
    );

    if (loading) {
      return (
        <div
          className="pt-3 text-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (error) {
      return (
        <div
          className="pt-3 text-center"
        >
          <span
            className="text-danger"
          >
            Something Went Wrong
          </span>
          <div>
            <Button
              variant="primary"
              onClick={() => this.retry()}
            >
              Retry
            </Button>
          </div>
        </div>
      );
    }

    return (
      <Container
        fluid
        className="bg-white"
      >
        <CustomModal
          show={!!stopFlashSale || stoppingFlashSaleOffers}
          body={confirmationBody}
          closeButton
          onHide={() => {
            this.setState({
              stoppingFlashSaleOffers: false,
              stopFlashSale: null,
              stopType: 'STOP_ONLY',
              reason: '',
              submitStatusMsg: '',
              submitting: false,
              submitError: false,
            });
          }}
        />
        <Row
          className="px-2"
        >
          <Col
            xs="auto"
            className="px-0 py-2"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Store Id"
                name="storeId"
                className="fs-01 rounded-0"
                value={storeId}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.onSubmitStoreId();
                  }
                }}
              />
            </InputGroup>
          </Col>
          <Col
            xs="auto"
            className="py-2"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Product Name"
                name="productSearchText"
                className="fs-01 rounded-0"
                value={productSearchText}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.onSubmitSearchText();
                  }
                }}
              />
            </InputGroup>
          </Col>
          <Col
            xs="auto"
            className="pl-0 py-2"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Job Id"
                name="jobId"
                className="fs-01 rounded-0"
                value={jobId}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.onSubmitJobId();
                  }
                }}
              />
            </InputGroup>
          </Col>
          <Col
            xs="auto"
            className="pl-0 py-2"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Library Product Ids"
                name="libraryProductIds"
                className="fs-01 rounded-0"
                value={libraryProductIds}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.onSubmitLibraryProductIds();
                  }
                }}
              />
            </InputGroup>
          </Col>
          {filterConf.map((item) => (
            <Col
              key={item.key}
              xs="auto"
              className="pl-0 py-2"
            >
              <CustomDropdown
                item={item}
                onChange={this.handleDropdownChange}
                selectedVal={params[item.key]}
              />
            </Col>
          ))}
          {
            showStopFlashSale && (
              <Col
                xs="auto"
                className="pl-0 py-2"
              >
                <CustomDropdown
                  item={{
                    key: 'selectionType',
                    displayText: 'Select',
                    options: [
                      {
                        label: 'All',
                        value: 'all',
                      },
                      {
                        label: 'Manually',
                        value: 'manually',
                      },
                      {
                        label: 'None',
                        value: '',
                      },
                    ],
                  }}
                  onChange={this.handleGroupSelection}
                  selectedVal={selectionType}
                  closeButton={false}
                />
              </Col>
            )
          }
          <Col
            xs="auto"
            className="d-flex justify-content-center align-items-center pl-0 py-2"
          >
            <Form.Check
              custom
              label="Active"
              type="checkbox"
              id="active"
              checked={isActive}
              onChange={() => {
                this.setState({
                  isActive: !isActive,
                }, () => {
                  this.handleRequestProcessing({ isActive: !isActive, p: 1 });
                });
              }}
              className="mr-4"
            />
            <Form.Check
              custom
              label="Atleast One Order"
              type="checkbox"
              id="atleastOneOrder"
              checked={atleastOneOrder}
              onChange={() => {
                this.setState({
                  atleastOneOrder: !atleastOneOrder,
                }, () => {
                  this.handleRequestProcessing({ atleastOneOrder: !atleastOneOrder, p: 1 });
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          {
            flashSaleRecords && (
              <CustomTable
                keyField="pk"
                headers={headers}
                content={flashSaleRecords.results}
                isPaginated
                totalItems={flashSaleRecords.count}
                hasPrev={flashSaleRecords.hasPrevious}
                hasNext={flashSaleRecords.hasNext}
                l={params.l}
                p={params.p}
                rowsPerPage={rowsPerPage}
                page={page}
                onNext={this.onNext}
                onPrev={this.onPrev}
                updateRowsPageInput={this.handleRowsPageInput}
                onSubmitPage={this.onSubmitPage}
                onSubmitRowsPerPage={this.onSubmitRowsPerPage}
              />
            )
          }
        </Row>
      </Container>
    );
  }
}

FlashSaleDetailed.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default FlashSaleDetailed;
