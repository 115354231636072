/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import {
  Button,
  Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';
import moment from 'moment';
import { couponCampaigns } from '../../assets/api/axios';
import { CustomDropdown, ErrorHandler } from '../../component/common';
import ViewCouponUsage from './ViewCouponUsage';
import ViewCouponCode from './ViewCouponCode';

function ViewCampaign(props) {
  const { match, history } = props;
  const param = QueryString.parse(history.location.search);
  const campaignId = (match.params.campaignId || param.campaignId);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [campaignApplicableOn, setCampaignApplicableOn] = useState('all');
  const [campaignPaymentMode, setCampaignPaymentMode] = useState('all');
  const [couponUsageModal, setCouponUsageModal] = useState(false);
  const [couponCodeModal, setCouponCodeModal] = useState(false);

  function handleLoad() {
    setLoading(true);
    setError(false);
    couponCampaigns(
      'GET',
      {},
      {},
      campaignId,
    ).then((res) => {
      setLoading(false);
      setCampaignDetails(res.data);
      if (res.data.customerList.length > 0) {
        setCampaignApplicableOn('customer');
      }
      if (res.data.storeList.length > 0) {
        setCampaignApplicableOn('store');
      }
      if (res.data.paymentMode.length && res.data.paymentMode.length < 2) {
        setCampaignPaymentMode(res.data.paymentMode[0].toLowerCase());
      }
    }).catch(() => {
      setLoading(false);
      setError(true);
    });
  }

  useEffect(() => {
    handleLoad();
    // eslint-disable-next-line
  }, []);

  const discountOptions = {
    key: 'discount',
    displayText: '',
    options: [
      {
        label: 'Percentage',
        value: 'percentage',
      },
      {
        label: 'Amount',
        value: 'amount',
      },
    ],
  };

  return (
    <>
      {
        couponUsageModal && (
          <ViewCouponUsage
            show={couponUsageModal}
            onHide={() => { setCouponUsageModal(false); }}
            campaignId={campaignId}
          />
        )
      }
      {
        couponCodeModal && (
          <ViewCouponCode
            show={couponCodeModal}
            onHide={() => { setCouponCodeModal(false); }}
            campaignId={campaignId}
          />
        )
      }
      {(() => {
        let showcase = null;
        if (loading) {
          showcase = (
            <div
              className="h-100 d-flex align-items-center
              justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          );
        } else if (!loading && error) {
          showcase = (
            <div
              className="h-100 d-flex align-items-center
              justify-content-center"
            >
              <ErrorHandler
                retryLogic={() => {
                  handleLoad();
                }}
              />
            </div>
          );
        } else if (!loading && !error && campaignDetails) {
          showcase = (
            <Container
              className="p-2"
            >
              <Row className="fs-3">
                <b>Campaign Details</b>
              </Row>
              <Row
                className="border border-black border-radius-4 my-2"
              >
                <Col
                  xs={24}
                  className="mx-3 py-3"
                >
                  <Form>
                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Campaign Name:</b>
                      </Form.Label>
                      <Col
                        sm={12}
                      >
                        <Form.Control
                          value={campaignDetails.campaignName}
                          readOnly
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Campaign Type:</b>
                      </Form.Label>
                      <Col
                        xs={5}
                      >
                        <Form.Check
                          label="Single"
                          type="radio"
                          checked={campaignDetails.campaignType === 'single'}
                          className="fs-01"
                          readOnly
                        />
                      </Col>
                      <Col
                        xs={5}
                      >
                        <Form.Check
                          label="Multi"
                          type="radio"
                          checked={campaignDetails.campaignType === 'multi'}
                          className="fs-01"
                          readOnly
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Funded By:</b>
                      </Form.Label>
                      <Col
                        sm={12}
                      >
                        <Form.Control
                          value={campaignDetails.fundedBy}
                          readOnly
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Allowed for Home Delivery:</b>
                      </Form.Label>
                      <Col
                        sm={12}
                      >
                        <div
                          className="custom-control custom-switch"
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={campaignDetails.isHomeDeliveryAllowed}
                            readOnly
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="isHomeDeliveryAllowed"
                          />
                        </div>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Allowed for Pickup From Store:</b>
                      </Form.Label>
                      <Col
                        sm={12}
                      >
                        <div
                          className="custom-control custom-switch"
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={campaignDetails.isPickupFromStoreAllowed}
                            readOnly
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="isPickupFromStoreAllowed"
                          />
                        </div>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Is Public:</b>
                      </Form.Label>
                      <Col
                        sm={12}
                      >
                        <div
                          className="custom-control custom-switch"
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={campaignDetails.isPublic}
                            readOnly
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="isPublic"
                          />
                        </div>
                      </Col>
                    </Form.Group>

                    {
                      (campaignDetails.brandIds.length > 0) && (
                        <Form.Group as={Row}>
                          <Form.Label column sm={6}>
                            <b>brand Id:</b>
                          </Form.Label>
                          <Col
                            sm={12}
                          >
                            <Form.Control
                              value={campaignDetails.brandIds[0]}
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      )
                    }

                    {
                      (campaignDetails.primaryCategoryCodes.length > 0) && (
                        <Form.Group as={Row}>
                          <Form.Label column sm={6}>
                            <b>Category Id's:</b>
                          </Form.Label>
                          <Col
                            sm={12}
                          >
                            <Form.Control
                              value={campaignDetails.primaryCategoryCodes.join(', ')}
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      )
                    }

                    {
                      (campaignDetails.subCategoryCodes.length > 0) && (
                        <Form.Group as={Row}>
                          <Form.Label column sm={6}>
                            <b>Sub-Category Id's:</b>
                          </Form.Label>
                          <Col
                            sm={12}
                          >
                            <Form.Control
                              value={campaignDetails.subCategoryCodes.join(', ')}
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      )
                    }

                    {
                      (campaignDetails.productIds.length > 0) && (
                        <Form.Group as={Row}>
                          <Form.Label column sm={6}>
                            <b>Product Id's:</b>
                          </Form.Label>
                          <Col
                            sm={12}
                          >
                            <Form.Control
                              value={campaignDetails.productIds.join(', ')}
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      )
                    }

                    {
                      campaignDetails.isPublic && (
                        <Form.Group as={Row}>
                          <Form.Label column sm={6}>
                            <b>Description:</b>
                          </Form.Label>
                          <Col
                            sm={12}
                          >
                            <Form.Control
                              value={campaignDetails.description}
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      )
                    }

                    {
                      campaignDetails.isPublic && (
                        <Form.Group as={Row}>
                          <Form.Label column sm={6}>
                            <b>Deeplink:</b>
                          </Form.Label>
                          <Col
                            sm={12}
                          >
                            <Form.Control
                              value={campaignDetails.deeplink}
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      )
                    }

                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Campaign Priority:</b>
                      </Form.Label>
                      <Col
                        sm={12}
                      >
                        <input
                          type="number"
                          value={campaignDetails.priority}
                          readOnly
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Coupon Prefix:</b>
                      </Form.Label>
                      <Col
                        sm={12}
                      >
                        <Form.Control
                          value={campaignDetails.couponPrefix}
                          readOnly
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>No. of Coupon:</b>
                      </Form.Label>
                      <Col
                        sm={12}
                      >
                        <input
                          type="number"
                          value={campaignDetails.totalCouponCodes}
                          readOnly
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Max Usage Per User:</b>
                      </Form.Label>
                      <Col
                        sm={12}
                      >
                        <input
                          type="number"
                          value={campaignDetails.maxUsagePerUser}
                          readOnly
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Valid On Transactions:</b>
                      </Form.Label>
                      <Col
                        sm={12}
                      >
                        <input
                          type="text"
                          value={campaignDetails.validOnTransactions}
                          readOnly
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Valid On Transaction:</b>
                      </Form.Label>
                      <Col
                        sm={12}
                      >
                        <input
                          type="number"
                          value={campaignDetails.validOnTransaction}
                          readOnly
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Campaign For:</b>
                      </Form.Label>
                      <Col
                        xs={5}
                      >
                        <Form.Check
                          label="All"
                          type="radio"
                          checked={campaignApplicableOn === 'all'}
                          className="fs-01"
                          readOnly
                        />
                      </Col>
                      <Col
                        xs={5}
                      >
                        <Form.Check
                          label="Customer"
                          type="radio"
                          checked={campaignApplicableOn === 'customer'}
                          className="fs-01"
                          readOnly
                        />
                      </Col>
                      <Col
                        xs={5}
                      >
                        <Form.Check
                          label="Retailer"
                          type="radio"
                          checked={campaignApplicableOn === 'store'}
                          className="fs-01"
                          readOnly
                        />
                      </Col>
                    </Form.Group>

                    {
                      campaignApplicableOn === 'customer' && (
                        <Form.Group as={Row}>
                          <Form.Label column sm={6}>
                            <b>Customer: </b>
                          </Form.Label>
                          <Col
                            sm={5}
                          >
                            <textarea
                              value={(campaignDetails.customerList).join('\n')}
                              className="form-control"
                              rows={4}
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      )
                    }

                    {
                      campaignApplicableOn === 'store' && (
                        <>
                          <Form.Group as={Row}>
                            <Form.Label column sm={6}>
                              <b>Store: </b>
                            </Form.Label>
                            <Col
                              sm={5}
                            >
                              <textarea
                                value={(campaignDetails.storeList).join('\n')}
                                className="form-control"
                                rows={4}
                                readOnly
                              />
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row}>
                            <Form.Label column sm={6}>
                              <b>Added Stores:</b>
                            </Form.Label>
                            <Col
                              xs={5}
                            >
                              <Form.Check
                                label="EXCLUDE"
                                type="radio"
                                checked={campaignDetails.isAllStoreExceptRestrictedStore}
                                className="fs-01"
                                readOnly
                              />
                            </Col>
                            <Col
                              xs={5}
                            >
                              <Form.Check
                                label="INCLUDE"
                                type="radio"
                                checked={!campaignDetails.isAllStoreExceptRestrictedStore}
                                className="fs-01"
                                readOnly
                              />
                            </Col>
                          </Form.Group>
                        </>
                      )
                    }

                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>
                          Upload Image:
                        </b>
                      </Form.Label>
                      <Col
                        sm={12}
                      >
                        <img
                          src={campaignDetails.imageUrl}
                          alt="CouponImage"
                          width="316px"
                          readOnly
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Expiry Date:</b>
                      </Form.Label>
                      <Col
                        sm={7}
                      >
                        <div className="d-flex">
                          <span className="fs-0">Valid From</span>
                          <input
                            type="datetime-local"
                            className="form-control"
                            value={moment(campaignDetails.validFrom).format('YYYY-MM-DDTHH:mm')}
                            readOnly
                          />
                        </div>
                      </Col>
                      <Col
                        sm={7}
                      >
                        <div className="d-flex">
                          <span className="fs-0">Valid Till</span>
                          <input
                            type="datetime-local"
                            className="form-control"
                            value={moment(campaignDetails.validTill).format('YYYY-MM-DDTHH:mm')}
                            readOnly
                          />
                        </div>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Payment Mode:</b>
                      </Form.Label>
                      <Col
                        xs={5}
                      >
                        <Form.Check
                          label="All Payment Modes"
                          type="radio"
                          checked={campaignPaymentMode === 'all'}
                          className="fs-01"
                          readOnly
                        />
                      </Col>
                      <Col
                        xs={5}
                      >
                        <Form.Check
                          label="Digital Payment"
                          type="radio"
                          checked={campaignPaymentMode === 'digital'}
                          className="fs-01"
                          readOnly
                        />
                      </Col>
                      <Col
                        xs={5}
                      >
                        <Form.Check
                          label="Cash on Delivery"
                          type="radio"
                          checked={campaignPaymentMode === 'cash'}
                          className="fs-01"
                          readOnly
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Set Discount:</b>
                      </Form.Label>
                      <Col
                        sm={6}
                      >
                        <CustomDropdown
                          item={discountOptions}
                          selectedVal={campaignDetails.discountType}
                          closeButton={false}
                          onChange={() => { }}
                          disabled
                        />
                      </Col>
                      <Col
                        sm={6}
                      >
                        <input
                          type="number"
                          value={campaignDetails.discountValue}
                          readOnly
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Min Spend Amount:</b>
                      </Form.Label>
                      <Col
                        sm={12}
                      >
                        <input
                          type="number"
                          value={campaignDetails.minSpendAmount}
                          readOnly
                        />
                      </Col>
                    </Form.Group>

                    {
                      campaignDetails.discountType === 'percentage' && (
                        <Form.Group as={Row}>
                          <Form.Label column sm={6}>
                            <b>Max Discount:</b>
                          </Form.Label>
                          <Col
                            sm={12}
                          >
                            <input
                              type="number"
                              value={campaignDetails.maxDiscount}
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      )
                    }

                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Coupon For:</b>
                      </Form.Label>
                      <Col
                        xs={5}
                      >
                        <Form.Check
                          label="Both"
                          type="radio"
                          checked={campaignDetails.couponApplicableOn === 'both'}
                          className="fs-01"
                          readOnly
                        />
                      </Col>
                      <Col
                        xs={5}
                      >
                        <Form.Check
                          label="Web"
                          type="radio"
                          checked={campaignDetails.couponApplicableOn === 'web'}
                          className="fs-01"
                          readOnly
                        />
                      </Col>
                      <Col
                        xs={5}
                      >
                        <Form.Check
                          label="App"
                          type="radio"
                          checked={campaignDetails.couponApplicableOn === 'app'}
                          className="fs-01"
                          readOnly
                        />
                      </Col>
                    </Form.Group>

                    {
                      campaignDetails.couponApplicableOn === 'app' && (
                        <Form.Group as={Row}>
                          <Form.Label column sm={6}>
                            <b>App Version:</b>
                          </Form.Label>
                          <Col
                            xs={4}
                          >
                            <Form.Check
                              label="All"
                              type="radio"
                              checked={campaignDetails.appVersion === 'all'}
                              className="fs-01"
                              readOnly
                            />
                          </Col>
                          <Col
                            xs={4}
                          >
                            <Form.Check
                              label="Specific"
                              type="radio"
                              checked={campaignDetails.appVersion === 'specific'}
                              className="fs-01"
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      )
                    }

                    {
                      campaignDetails.couponApplicableOn === 'app' && campaignDetails.appVersion === 'specific' && (
                        <Form.Group as={Row}>
                          <Form.Label column sm={6}>
                            <b>App Version Number:</b>
                          </Form.Label>
                          <Col
                            sm={12}
                          >
                            <input
                              type="number"
                              value={campaignDetails.appVersionNumber}
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      )
                    }
                  </Form>
                </Col>
                <Col>
                  <Row
                    className="d-flex align-item-center justify-content-center
                      py-2 border-top"
                  >
                    <Button
                      variant="primary"
                      onClick={() => {
                        setCouponUsageModal(true);
                      }}
                      disabled={!campaignId}
                      className="mr-3"
                    >
                      VIEW COUPON USAGE
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        }
        return showcase;
      })()}
    </>
  );
}

ViewCampaign.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      campaignId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default ViewCampaign;

