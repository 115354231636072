import React, { Component } from 'react';
import {
  Container, Row, Col, Button, Spinner, InputGroup, Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { freeSampleCampaign } from '../../assets/api/axios';
import {
  CustomTable, Svg, CustomDropdown,
} from '../../component/common';
import { Constant } from '../../utilities';
import { dateString } from '../../utilities/Utils';
import CreateBrandCampaign from './CreateBrandCampaign';
import permission from '../../access&permissions/permission';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  const {
    searchText = '',
    active = 'true',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    ...param,
    l,
    p,
    searchText,
    active,
  });
};

class BrandSamplingCampaignListing extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    const { userPermission } = this.props;
    this.canEdit = userPermission.includes(permission.BRAND_SAMPLING_CAMPAIGN_WRITE);
    this.state = {
      hasNext: false,
      hasPrevious: false,
      rowsPerPage: param.l,
      page: param.p,
      loading: true,
      error: false,
      campaigns: null,
      searchText: param.searchText,
      param,
      createCampaignModal: false,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  onSubmitSearchText = () => {
    const {
      searchText, param,
    } = this.state;
    if (searchText !== param.searchText) {
      this.handleRequestProcessing({ searchText, p: 1 });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, searchText, active,
    } = newParam;
    const offset = (p);
    freeSampleCampaign(
      'GET',
      '',
      null,
      {
        page: `${offset}`,
        limit: l,
        searchText,
        active,
      },
      this.source.token,
    ).then((res) => {
      this.setState({
        campaigns: res.data,
        loading: false,
        hasNext: !!res.data.next,
        hasPrevious: !!res.data.prev,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
        searchText: newParam.searchText,
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  render() {
    const {
      param, loading, error, campaigns,
      rowsPerPage, page, hasNext, hasPrevious,
      searchText, createCampaignModal,
    } = this.state;

    const headers = [
      {
        key: 'id',
        displayText: 'Id',
        renderer: (data) => (
          <Link
            to={`/brand-sampling-campaign-management/${data.id}`}
            target="_blank"
          >
            {data.id}
          </Link>
        ),
      },
      {
        key: 'name',
        displayText: 'Campaign Name',
      },
      {
        key: 'libraryProductId',
        displayText: 'Product ID',
      },
      {
        key: 'libraryProductName',
        displayText: 'Product Name',
      },
      {
        key: 'active',
        displayText: 'Active',
        renderer: (data) => (
          <b className={data.active ? 'text-green' : 'text-danger'}>
            {data.active ? 'YES' : 'NO'}
          </b>
        ),
      },
      {
        key: 'mov',
        displayText: 'MOV',
      },
      {
        key: 'totalStock',
        displayText: 'Total Stocks',
      },
      {
        key: 'allocatedStock',
        displayText: 'Total Allocation',
      },
      {
        key: 'remainingStock',
        displayText: 'Remaining Stocks',
      },
      {
        key: 'noOfStoresAllocated',
        displayText: 'No of Allocated Stores',
      },
      {
        key: 'launchDate',
        displayText: 'Launch On',
        renderer: (data) => dateString(data.launchDate),
      },
      {
        key: 'validTill',
        displayText: 'Valid Till',
        renderer: (data) => dateString(data.validTill),
      },
      {
        key: 'createdAt',
        displayText: 'Created On',
        renderer: (data) => dateString(data.createdAt),
      },
      {
        key: 'modifiedAt',
        displayText: 'Modified On',
        renderer: (data) => dateString(data.modifiedAt),
      },
      {
        key: 'modifiedById',
        displayText: 'Modified By',
      },
    ];

    const filterConf = [
      {
        key: 'active',
        displayText: 'Active',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
    ];

    const searchBars = [
      (
        <Form.Control
          key="searchText"
          type="text"
          placeholder="Search text"
          name="searchText"
          className="fs-01 rounded-0"
          value={searchText}
          onChange={(e) => {
            this.setState({
              searchText: e.target.value,
            });
          }}
          autoComplete="off"
          onKeyPress={(e) => {
            if (e.which === 13) {
              this.onSubmitSearchText();
            }
          }}
        />
      ),
    ];

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        <CreateBrandCampaign
          show={createCampaignModal}
          onHide={() => this.setState({ createCampaignModal: false })}
          handleRequestProcessing={this.handleRequestProcessing}
        />
        <Row
          className="h-100 flex-column"
        >
          <Col
            className="flex-grow-0"
          >
            <Row
              className="align-items-center py-2"
            >
              {
                searchBars.map((item) => (
                  <Col
                    key={item.key}
                    xs="auto"
                    className="px-2 pt-2"
                  >
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          className="rounded-0"
                        >
                          <Svg
                            svg="search"
                            width="1rem"
                            fill={Constant.Color.DARK}
                          />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      {item}
                    </InputGroup>
                  </Col>
                ))
              }
              {
                filterConf.map((item) => (
                  <Col
                    key={item.key}
                    xs="auto"
                    className="px-2 pt-2"
                  >
                    <CustomDropdown
                      item={item}
                      onChange={this.handleDropdownChange}
                      selectedVal={param[item.key]}
                    />
                  </Col>
                ))
              }
              <Col
                className="d-flex justify-content-end mt-2"
              >
                <Button
                  variant="primary"
                  className="fs-0"
                  onClick={() => {
                    this.setState({
                      createCampaignModal: true,
                    });
                  }}
                  disabled={!this.canEdit}
                >
                  Create Campaign
                </Button>
              </Col>
            </Row>
          </Col>
          {
            (loading || error) && (
              <div
                className="pt-3 text-center"
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                ) : (
                  <>
                    <span
                      className="text-danger"
                    >
                      Something Went Wrong
                    </span>
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => this.retry()}
                      >
                        Retry
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )
          }
          {
            !!campaigns && !loading && !error && (
              <Col
                className="px-0 flex-grow-1 overflow-y pt-2"
              >
                <CustomTable
                  headers={headers}
                  content={campaigns.data}
                  keyField="id"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={campaigns.count}
                  hasPrev={hasPrevious}
                  hasNext={hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            )
          }
        </Row>
      </Container>
    );
  }
}

BrandSamplingCampaignListing.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default BrandSamplingCampaignListing;
