import React, { useEffect, useState } from 'react';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { marketplaceBrands } from '../../assets/api/axios';

function BrandDetails(props) {
  const { match } = props;
  const { brandId } = match.params;
  const [brand, setBrand] = useState({
    loading: true,
    error: false,
    data: null,
  });

  const getBrandDetails = () => {
    marketplaceBrands(
      'GET',
      brandId,
      null,
      {},
    ).then((res) => {
      setBrand({
        ...brand,
        loading: false,
        error: false,
        data: res.data.data,
      });
    }).catch(() => {
      setBrand({
        ...brand,
        loading: false,
        error: true,
        data: null,
      });
    });
  };

  useEffect(() => {
    getBrandDetails();
  }, []);

  const brandDetails = brand.data;

  return (
    <Container
      fluid
    >
      <Container
        className="h-100"
      >
        <Row
          className="align-items-center justify-content-center"
        >
          {
            (brand.loading || brand.error) && (
              <div
                className="pt-3 text-center"
              >
                {brand.loading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                ) : (
                  <>
                    <span
                      className="text-danger"
                    >
                      Something Went Wrong
                    </span>
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => {
                          getBrandDetails();
                        }}
                      >
                        Retry
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )
          }
        </Row>
        {
          brandDetails && (
            <>
              <Row
                className="px-3 py-2 mt-3 mx-3 bg-white border-radius-16"
              >
                <Col
                  xs="auto"
                  style={{
                    width: '80px',
                    height: '80px',
                  }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <img
                    src={brandDetails.brandImage}
                    alt={brandDetails.businessName}
                    className="mw-100 mh-100"
                  />
                </Col>
                <Col
                  className="pl-3"
                >
                  <div
                    className="font-weight-bold fs-3"
                  >
                    {brandDetails.businessName}
                  </div>
                  <div
                    className="mt-1 fs-1"
                  >
                    {brandDetails.description}
                  </div>
                </Col>
              </Row>
              <Row
                className="px-3 py-2 mt-3 mx-3 bg-white border-radius-16 align-items-center"
              >
                <Col
                  xs={24}
                >
                  <div
                    className="mb-2 font-weight-bold fs-2"
                  >
                    Other Details
                  </div>
                  <div
                    className="mt-3"
                  >
                    Contact -&nbsp;
                    <a
                      className="text-blue"
                      href={`callto:${brandDetails.phoneNumber}`}
                    >
                      {brandDetails.phoneNumber}
                    </a>
                  </div>
                  <div
                    className="mt-3"
                  >
                    Min Order Amt -&nbsp;
                    {brandDetails.minOrderAmount}
                  </div>

                  <div
                    className="mt-3"
                  >
                    Address -&nbsp;
                    {brandDetails.address}
                  </div>
                </Col>
              </Row>
            </>
          )
        }
      </Container>
    </Container>
  );
}

BrandDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      brandId: PropTypes.string,
    }),
  }).isRequired,
};

export default BrandDetails;
