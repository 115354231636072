import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './CreateOrUpdateFestiveCat.scss';
import { festiveCategory, uploadImage } from '../../assets/api/axios';

function CreateOrUpdateFestiveCat({
  category: categoryFromProp, onSubmit, onCancel,
}) {
  const category = categoryFromProp || {};
  const [name, setName] = useState(category.name || '');
  const catIconRef = useRef(null);
  const [active, setActiveStatus] = useState(category.active || false);
  const [launchDate, setLaunchDate] = useState(category.launchDate || '');
  const [validTill, setValidTill] = useState(category.validTill || '');
  const dataSetRef = useRef(null);
  const [action, setAction] = useState('SET_AS_CURRENT_LIST');
  const [submitStatus, setSubmitStatus] = useState('');

  const updateChanges = (e) => {
    e.preventDefault();
    setSubmitStatus('SUBMITTING');
    const catIconUpload = new Promise((resolve, reject) => {
      if (!catIconRef.current.files[0]) {
        resolve(category.icon);
        return;
      }
      const formData = new FormData();
      formData.append('img', catIconRef.current.files[0]);
      uploadImage(
        'POST',
        formData,
      ).then((res) => {
        resolve(res.data.url);
        onclose();
      }).catch(() => {
        reject(new Error('Error while uploading images'));
      });
    });

    catIconUpload.then((url) => {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('isActive', active);
      formData.append('launchDate', new Date(launchDate).getTime());
      formData.append('endDate', new Date(validTill).getTime());
      formData.append('icon', url);
      formData.append('csv', dataSetRef.current.files[0]);
      if (action !== 'REMOVE_ALL') {
        formData.append('action', action);
      }

      festiveCategory(
        'PATCH',
        {},
        category.id,
        formData,
      ).then(() => {
        onSubmit();
        setSubmitStatus('SUCCESS');
      }).catch(() => {
        setSubmitStatus('ERROR');
      });
    }).catch(() => {
      setSubmitStatus('ERROR');
    });
  };

  return (
    <form onSubmit={updateChanges}>
      <lable>
        <span className="lableText">Name</span>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </lable>

      <lable>
        <span className="lableText">Festive Category Icon Upload</span>
        <input
          type="file"
          ref={catIconRef}
          required={!catIconRef && !category.icon}
        />
      </lable>
      <lable>
        <span className="lableText" />
        <img src={category.icon} height={100} weight={100} alt="" />
      </lable>

      <lable>
        <span className="lableText">Active Status</span>
        <div className="switch">
          <input
            type="checkbox"
            id="active-status"
            checked={active}
            onChange={() => setActiveStatus(!active)}
          />
          <span className="slider" />
        </div>
      </lable>

      <lable>
        <span className="lableText">Launch Date</span>
        <input
          type="datetime-local"
          id="launch-date"
          value={launchDate}
          onChange={(e) => setLaunchDate(e.target.value)}
          required
        />
      </lable>

      <lable>
        <span className="lableText">End Date</span>
        <input
          type="datetime-local"
          id="end-date"
          value={validTill}
          onChange={(e) => setValidTill(e.target.value)}
          required
        />
      </lable>

      <lable>
        <span className="lableText">Method</span>
        <select
          required
          onChange={(e) => setAction(e.target.value)}
          value={action}
        >
          <option value="">--Select Method--</option>
          <option value="ADD_NEW">ADD</option>
          <option value="REMOVE_ALL">REMOVE</option>
          <option value="REPLACE_AND_ADD">SET AS CURRENT LIST</option>
        </select>
      </lable>

      {
        action !== 'REMOVE_ALL' && (
          <lable>
            <span className="lableText">Upload Library Products</span>
            <div>
              <input type="file" ref={dataSetRef} />
              <div>
                <a
                  href="https://generic-bulk-upload.s3.ap-south-1.amazonaws.com/sample_file.csv"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button type="button" className="py-1 font-weight-bold text-primary fs-01" style={{ borderWidth: 0, background: 'none' }}>
                    DOWNLOAD SAMPLE CSV
                  </button>
                </a>
              </div>
            </div>
          </lable>
        )
      }

      <lable>
        <span className="lableText" />
        <div className="d-flex" style={{ gap: '10px' }}>
          <button type="button" onClick={() => onCancel()} disabled={submitStatus === 'SUBMITTING'}>Cancel</button>
          <button type="submit" disabled={submitStatus === 'SUBMITTING'}>Update</button>
          {submitStatus === 'ERROR' ? <div className="text-danger">Oops! Something went wrong!</div> : null}
        </div>
      </lable>

      <fieldset />
    </form>
  );
}

CreateOrUpdateFestiveCat.propTypes = {
  category: PropTypes.shape({ }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

CreateOrUpdateFestiveCat.defaultProps = {
  category: null,
};

export default CreateOrUpdateFestiveCat;
