import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal } from '../../component/common';
import { storeProfile, uploadImage } from '../../assets/api/axios';
import { validateRegex } from '../../utilities/FormValidations';

function UploadBankDetails(props) {
  const {
    show, onHide, profile, handleUploadDetails,
  } = props;
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [gstNumber, setGstNumber] = useState('');
  const [proofType, setProofType] = useState('');
  const [proofTypeImage, setProofTypeImage] = useState('');
  const [panHolderName, setPanHolderName] = useState('');
  const [panNumber, setPanNumber] = useState('');
  const [panImage, setPanImage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [uploadingImg, setUploadingImg] = useState(false);

  const handleImageOnChange = (event, key) => {
    setUploadingImg(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('img', file);

    uploadImage(
      'POST',
      formData,
    ).then((res) => {
      const imageURL = res.data.url;
      switch (key) {
        case 'PROOF_TYPE_IMAGE':
          setProofTypeImage(imageURL);
          break;
        case 'PAN_IMAGE':
          setPanImage(imageURL);
          break;
        default:
          break;
      }
      setUploadingImg(false);
    }).catch(() => {
      setUploadingImg(false);
    });
  };

  const handleSubmit = () => {
    setSubmitting(true);
    const data = {
      account: {
        accountName,
        accountNumber,
        ifscCode,
        gstNumber,
        proofType,
        cancelledChequeUrl: proofTypeImage,
      },
      kyc: {
        panImage,
        panNumber,
        panHolderName,
      },
      setupStages: {
        bankDetail: true,
      },
      bank: {
        status: 'PENDING',
      },
    };

    storeProfile(
      'PATCH',
      profile.code,
      data,
    ).then((res) => {
      setSubmitting(false);
      setError(false);
      handleUploadDetails(res.data);
    }).catch(() => {
      setSubmitting(false);
      setError(true);
    });
  };

  return (
    <CustomModal
      show={show}
      title="Upload Bank Details"
      body={(
        <Container className="p-4">
          <Row className="py-2">
            <Col xs={8}>
              Account Name:
            </Col>
            <Col xs={16}>
              <input
                type="text"
                name="accountName"
                value={accountName || profile.account.accountName}
                className="form-control"
                placeholder="Enter Account Name"
                onChange={(e) => setAccountName(e.target.value)}
                maxLength="35"
              />
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={8}>
              Account Number:
            </Col>
            <Col xs={16}>
              <input
                type="text"
                name="accountNumber"
                value={accountNumber || profile.account.accountNumber}
                className="form-control"
                placeholder="Enter Account Number"
                onChange={(e) => {
                  if (validateRegex('accountNumber', e.target.value)) {
                    setAccountNumber(e.target.value);
                  }
                }}
                maxLength="17"
              />
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={8}>
              IFSC Code:
            </Col>
            <Col xs={16}>
              <input
                type="text"
                name="ifscCode"
                value={ifscCode || profile.account.ifscCode}
                className="form-control text-transform:uppercase"
                placeholder="Enter IFSC Code"
                onChange={(e) => {
                  if (validateRegex('ifscCode', e.target.value)) {
                    setIfscCode(e.target.value.toLocaleUpperCase());
                  }
                }}
                maxLength="11"
              />
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={8}>
              GST Number:
            </Col>
            <Col xs={16}>
              <input
                type="text"
                name="gstNumber"
                value={gstNumber || profile.account.gstNumber}
                className="form-control"
                placeholder="Enter GST Number"
                onChange={(e) => {
                  if (validateRegex('gstNumber', e.target.value)) {
                    setGstNumber(e.target.value.toLocaleUpperCase());
                  }
                }}
                maxLength="15"
              />
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={8}>
              Proof Type:
            </Col>
            <Col xs={16}>
              <select
                id="proofType"
                className="form-control"
                value={proofType || profile.account.proofType}
                onChange={(e) => {
                  if (e.target.value !== '') {
                    setProofType(e.target.value);
                  }
                }}
              >
                <option value="">Select</option>
                <option value="CANCELLED_CHEQUE">CANCELLED CHEQUE</option>
                <option value="BANK_STATEMENT">BANK STATEMENT</option>
                <option value="BANK_PASSBOOK">BANK PASSBOOK</option>
              </select>
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={8}>
              Proof Type Image:
            </Col>
            <Col xs={16}>
              <Row className="mx-0">
                <input
                  type="file"
                  className="form-control input-file"
                  onChange={(e) => { handleImageOnChange(e, 'PROOF_TYPE_IMAGE'); }}
                />
              </Row>
              <Row className="mx-0">
                <img
                  src={proofTypeImage || profile.account.cancelledChequeUrl}
                  alt=""
                  width="100px"
                  height="100px"
                />
              </Row>
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={8}>
              Pan Holder Name:
            </Col>
            <Col xs={16}>
              <input
                type="text"
                name="panHolderName"
                value={panHolderName || profile.kyc.panHolderName}
                className="form-control"
                placeholder="Enter Pan Holder Name"
                onChange={(e) => setPanHolderName(e.target.value)}
                maxLength="35"
              />
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={8}>
              PAN Number:
            </Col>
            <Col xs={16}>
              <input
                type="text"
                name="panNumber"
                value={panNumber || profile.kyc.panNumber}
                className="form-control"
                placeholder="Enter PAN Number"
                onChange={(e) => {
                  if (validateRegex('panNumber', e.target.value)) {
                    setPanNumber(e.target.value.toLocaleUpperCase());
                  }
                }}
                maxLength="10"
              />
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={8}>
              Pan Image:
            </Col>
            <Col xs={16}>
              <Row className="mx-0">
                <input
                  type="file"
                  className="form-control input-file"
                  onChange={(e) => { handleImageOnChange(e, 'PAN_IMAGE'); }}
                />
              </Row>
              <Row className="mx-0">
                <img
                  src={panImage || profile.kyc.panImage}
                  alt=""
                  width="100px"
                  height="100px"
                />
              </Row>
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={8} />
            <Col xs={16}>
              <Button
                onClick={handleSubmit}
                disabled={
                  submitting
                  || uploadingImg
                  || (
                    !accountName && !accountNumber && !ifscCode && !gstNumber
                    && !proofType && !proofTypeImage && !panHolderName && !panNumber
                    && !panImage
                  )
                }
                className="font-weight-bold"
              >
                SUBMIT
              </Button>
              {
                submitting && (
                  <span className="p-2">
                    <Spinner
                      variant="primary"
                      animation="border"
                      size="sm"
                    />
                  </span>
                )
              }
              {
                error && (
                  <span className="p-2 text-danger">
                    Oops! Something went wrong, Please try again.
                  </span>
                )
              }
            </Col>
          </Row>
        </Container>
      )}
      onHide={() => {
        setAccountName('');
        setAccountNumber('');
        setIfscCode('');
        setGstNumber('');
        setProofType('');
        setProofTypeImage('');
        setPanHolderName('');
        setPanNumber('');
        setPanImage('');
        setSubmitting(false);
        setError(false);
        onHide();
      }}
      size="lg"
      closeButton
    />
  );
}

UploadBankDetails.propTypes = {
  show: PropType.bool.isRequired,
  onHide: PropType.func.isRequired,
  profile: PropType.shape({
    code: PropType.oneOfType(PropType.string, PropType.number),
    account: PropType.shape({
      accountName: PropType.string,
      accountNumber: PropType.string,
      ifscCode: PropType.string,
      gstNumber: PropType.string,
      proofType: PropType.string,
      cancelledChequeUrl: PropType.string,
    }),
    kyc: PropType.shape({
      panHolderName: PropType.string,
      panNumber: PropType.string,
      panImage: PropType.string,
    }),
  }).isRequired,
  handleUploadDetails: PropType.func.isRequired,
};

export default UploadBankDetails;
