import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Container, Row, Col, Spinner, Card, Button, Form,
} from 'react-bootstrap';
import Axios from 'axios';
import QueryString from 'query-string';
import moment from 'moment';
import {
  CustomModal, ErrorHandler, CustomDropdown, CustomPagination, DatePicker,
} from '../../component/common';
import { banners, uploadImage, storeCategory } from '../../assets/api/axios';
import { dateTimeOptions, languages } from '../../utilities/Utils';
import Svg from '../../component/common/Svg';
import { ProcessingStatus } from '../../component/derived/table-list';
import Permission from '../../access&permissions/permission';

function getOneMonthLaterDate() {
  const now = new Date();
  return new Date(now.setMonth(now.getMonth() + 1));
}

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, startDate, endDate,
  } = param;
  const {
    status = '',
    cardType = '',
    storeTypeIds = '',
    language = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  startDate = Number(startDate) || '';
  endDate = Number(endDate) || '';
  return ({
    l,
    p,
    startDate,
    endDate,
    status,
    cardType,
    storeTypeIds,
    language,
  });
};

const bgcolorMapping = {
  PINK: {
    logo: 'WHITE',
    storeName: 'YELLOW',
    storeAddress: 'WHITE',
  },
  WHITE: {
    logo: 'PINK',
    storeName: 'PINK',
    storeAddress: 'BLUE',
  },
  BLUE: {
    logo: 'WHITE',
    storeName: 'WHITE',
    storeAddress: 'YELLOW',
  },
  YELLOW: {
    logo: 'BLUE',
    storeName: 'BLUE',
    storeAddress: 'PINK',
  },
};

const logoColor = {
  PINK: 'white',
  WHITE: 'pink',
  BLUE: 'white',
  YELLOW: 'primary',
};

const shopName = {
  PINK: 'warning',
  WHITE: 'pink',
  BLUE: 'white',
  YELLOW: 'primary',
};

const shopAddress = {
  PINK: 'white',
  WHITE: 'primary',
  BLUE: 'warning',
  YELLOW: 'pink',
};

const textColor = {
  WHITE: 'white',
  PINK: 'pink',
  BLUE: 'primary',
  YELLOW: 'warning',
};

const textFontSize = {
  XXXS: '8',
  XXS: '9',
  XS: '10',
  SM: '12',
  MD: '14',
  REG: '16',
  LG: '20',
};

const textFontStyle = {
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
};

const textFontType = {
  SERIF: 'serif',
  SANS_SERIF: 'sans-serif',
  MONOSPACE: 'monospace',
  COURIER_NEW: 'courier_new',
  FREEMONO: 'freemono',
};

const headersAndBootomDefault = {
  isOption: true,
  textLimit: '15',
  text: '',
  schema: {
    color: 'WHITE',
    fontType: '',
    fontStyle: '',
    fontSize: 'MD',
  },
};

class ShareOfferCard extends React.Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    const { userPermission } = props;
    this.canIEdit = userPermission && userPermission.includes(Permission.SHARE_OFFER_CARD_WRITE);
    this.state = {
      bannersData: null,
      loading: true,
      error: false,
      addShareCard: false,
      submittingShareCard: false,
      submittingShareCardError: false,
      title: '',
      text: '',
      shareCardImage: '',
      launchDate: new Date(),
      expiryDate: getOneMonthLaterDate(),
      selectedLanguage: null,
      priorityOrder: 1,
      storeIds: '',
      shareCardUidToDelete: null,
      shareCardUidToEdit: null,
      uploadingImg: false,
      invalidDateErrorMsg: '',
      addCustomCard: false,
      background: null,
      storeColorInfo: '',
      headerOne: { ...headersAndBootomDefault },
      headerTwo: { ...headersAndBootomDefault },
      footer: { ...headersAndBootomDefault },
      editCustomCard: false,
      storeCategories: [],
      selectedStoreCategories: [],
      rowsPerPage: param.l,
      page: param.p,
      param,
      stDate: param.startDate,
      eDate: param.endDate,
      newParam: param,
      createStory: true,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    storeCategory('GET').then((res) => {
      this.setState({
        storeCategories: res.data.results.map((category) => ({
          label: category.displayName,
          value: (category.id).toString(),
        })),
      });
    });
    this.handleLoad();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param, stDate, eDate } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, status, cardType, storeTypeIds, language,
    } = newParam;
    const offset = (p - 1) * l;
    banners(
      'GET',
      null,
      {
        banner_type: 'B2B_OFFER',
        offset,
        limit: l,
        startDate: stDate,
        endDate: eDate,
        status,
        cardType,
        storeTypeIds,
        language,
      },
    ).then((res) => {
      this.setState({
        bannersData: res.data,
        loading: false,
        error: false,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  addShareCard = (e) => {
    if (e) {
      e.preventDefault();
    }
    const {
      shareCardImage, title, text, priorityOrder, storeIds,
      addShareCard, shareCardUidToEdit, launchDate, expiryDate, selectedLanguage,
      storeColorInfo, headerOne, headerTwo, footer, background, editCustomCard,
      selectedStoreCategories, createStory,
    } = this.state;

    if (launchDate > expiryDate) {
      this.setState({
        invalidDateErrorMsg: 'Please Check!! Launch Date is Greater than Expiry Date',
      });
      return;
    }

    this.setState({
      submittingShareCard: true,
      invalidDateErrorMsg: '',
    });

    let method = '';
    let data = null;
    let uid = '';

    const defaultRequestBody = {
      bannerType: 'B2B_OFFER',
      title,
      text,
      img: shareCardImage,
      priorityOrder,
      language: selectedLanguage.value,
      storeCodes: storeIds,
      isPublic: true,
      launchDate: launchDate.getTime(),
      expiryDate: expiryDate.getTime(),
      type: 'REGULAR',
      selectedStoreCategories,
      background,
      storeColorInfo,
      createStory,
    };

    if ((addShareCard || !!shareCardUidToEdit) && !editCustomCard) {
      method = `${addShareCard ? 'POST' : 'PATCH'}`;
      data = { ...defaultRequestBody };
      uid = `${addShareCard ? '' : shareCardUidToEdit}`;
    } else {
      method = `${editCustomCard ? 'PATCH' : 'POST'}`;
      data = {
        ...defaultRequestBody,
        type: 'CUSTOM',
        headerOne,
        headerTwo,
        footer,
      };
      uid = `${!editCustomCard ? '' : shareCardUidToEdit}`;
    }

    banners(
      method,
      data,
      {},
      uid,
    ).then(() => {
      this.setState({
        addShareCard: false,
        shareCardUidToEdit: null,
        submittingShareCard: false,
        title: '',
        text: '',
        shareCardImage: '',
        launchDate: new Date(),
        expiryDate: getOneMonthLaterDate(),
        invalidDateErrorMsg: '',
        priorityOrder: 1,
        selectedLanguage: null,
        storeIds: '',
        addCustomCard: false,
        background: null,
        storeColorInfo: '',
        headerOne: { ...headersAndBootomDefault },
        headerTwo: { ...headersAndBootomDefault },
        footer: { ...headersAndBootomDefault },
        editCustomCard: false,
        selectedStoreCategories: [],
        createStory: true,
      }, () => {
        this.handleLoad();
      });
    }).catch(() => {
      this.setState({
        submittingShareCard: false,
        submittingShareCardError: true,
      });
      return false;
    });
  }

  deleteShareCard = () => {
    const { shareCardUidToDelete } = this.state;
    this.setState({
      submittingShareCard: true,
    });
    banners(
      'DELETE',
      null,
      {},
      shareCardUidToDelete,
    ).then(() => {
      this.setState({
        submittingShareCard: false,
        shareCardUidToDelete: null,
      }, () => {
        this.handleLoad();
      });
    }).catch(() => {
      this.setState({
        submittingShareCard: false,
        submittingShareCardError: true,
      });
    });
  }

  handleInputOnChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  handleChangeDate = (event) => {
    this.setState({
      [event.target.id]: new Date(event.target.value),
    });
  }

  handleImageOnChange = (event) => {
    this.setState({
      uploadingImg: true,
    });
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('img', file);

    if (file) {
      uploadImage(
        'post',
        formData,
      ).then((res) => {
        this.setState({
          uploadingImg: false,
          shareCardImage: res.data.url,
        });
      }).catch(() => {
        this.setState({
          uploadingImg: false,
        });
      });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const {
      l, p,
    } = param;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source;
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
    });
  }

  handleRadioButtonChange = (event) => {
    const { name } = event.target;
    this.setState((state) => ({
      [name]: !state[name],
    }));
  }

  handleDateChange = (startDate, endDate) => {
    this.setState({
      stDate: startDate,
      eDate: endDate,
    });
  }

  applyFilters = () => {
    const { newParam } = this.state;
    this.handleRequestProcessing({
      ...newParam,
      p: 1,
    });
  }

  resetFilters = () => {
    this.handleRequestProcessing({
      status: '',
      cardType: '',
      stDate: '',
      eDate: '',
      p: 1,
      storeTypeIds: '',
      language: '',
    });
    this.setState((state) => ({
      stDate: '',
      eDate: '',
      newParam: {
        ...state.newParam,
        status: '',
        cardType: '',
        p: 1,
        storeTypeIds: '',
        language: '',
      },
    }));
  }

  handleDropdownChange = (data) => {
    this.setState((state) => ({
      newParam: { ...state.newParam, ...data },
    }));
  }

  render() {
    const {
      bannersData, loading, error, addShareCard, submittingShareCard,
      title, text, shareCardImage, submittingShareCardError,
      priorityOrder, shareCardUidToDelete, shareCardUidToEdit,
      storeIds, uploadingImg, launchDate, expiryDate, invalidDateErrorMsg,
      selectedLanguage,
      addCustomCard, headerOne, headerTwo, footer, background, editCustomCard,
      // eslint-disable-next-line no-unused-vars
      storeColorInfo, storeCategories, selectedStoreCategories, offset,
      rowsPerPage, page, param, stDate, eDate, newParam, createStory,
    } = this.state;

    const filterConfForSingleSelect = [
      {
        key: 'status',
        displayText: 'Card Status',
        options: [
          {
            label: 'Scheduled',
            value: 'SCHEDULED',
          },
          {
            label: 'Active',
            value: 'ACTIVE',
          },
          {
            label: 'Completed',
            value: 'COMPLETED',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
      {
        key: 'cardType',
        displayText: 'Card Type',
        options: [
          {
            label: 'Custom',
            value: 'CUSTOM',
          },
          {
            label: 'Regular',
            value: 'REGULAR',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
    ];

    const filterConfForMultiSelect = [
      {
        key: 'storeTypeIds',
        displayText: 'Store Type',
        options: (
          [{ label: 'ALL', value: '2' }, ...storeCategories]
        ),
      },
      {
        key: 'language',
        displayText: 'Language',
        options: (
          Object.keys(languages).map((item) => ({
            label: languages[item],
            value: item,
          }))
        ),
      },
    ];

    const bannersList = (
      <Row>
        <Col
          xs={13}
          className="mx-auto"
        >
          {
            bannersData
            && bannersData.results.map(
              (banner) => (
                <Card
                  border="secondary"
                  xs="auto"
                  className="mt-1 mx-auto pt-2"
                  style={{ width: '100%' }}
                  key={banner.uid}
                >
                  <Row
                    className={`mx-2 flex-row-reverse ${this.canIEdit ? 'd-flex' : 'd-hidden'}`}
                  >
                    <Col
                      xs="auto"
                      className="text-danger pb-2 px-2 cursor-pointer"
                      onClick={() => {
                        this.setState({
                          shareCardUidToDelete: banner.uid,
                        });
                      }}
                    >
                      DELETE
                    </Col>
                    <Col
                      xs="auto"
                      className="text-primary pb-2 px-2 cursor-pointer"
                      onClick={() => {
                        const commonFields = {
                          shareCardUidToEdit: banner.uid,
                          title: banner.title,
                          text: banner.text,
                          shareCardImage: banner.banner_image_url,
                          priorityOrder: banner.priority_order,
                          selectedLanguage: {
                            label: languages[banner.language], value: banner.language,
                          },
                          launchDate: new Date(banner.launchDate),
                          expiryDate: new Date(banner.expiryDate),
                          storeIds: banner.storeCodes,
                          selectedStoreCategories: banner.selectedStoreCategories,
                          background: banner.background,
                          storeColorInfo: banner.storeColorInfo,
                          createStory: banner.createStory,
                        };
                        this.setState(() => {
                          if (banner.type === 'CUSTOM') {
                            return {
                              editCustomCard: true,
                              headerOne: banner.headerOne,
                              headerTwo: banner.headerTwo,
                              footer: banner.footer,
                              ...commonFields,
                            };
                          }
                          return {
                            ...commonFields,
                          };
                        });
                      }}
                    >
                      EDIT
                    </Col>
                    {
                      banner.type === 'CUSTOM' && (
                        <Col
                          className="text-success p-2"
                        >
                          <b>Custom Card</b>
                        </Col>
                      )
                    }
                  </Row>
                  <Row
                    className="m-1"
                  >
                    <Col
                      xs={3}
                    >
                      <Card.Img
                        src={banner.banner_image_url}
                      />
                    </Col>
                    <Col
                      xs={21}
                    >
                      <Card.Body
                        className="text-dark p-0"
                      >
                        <Row className="mx-0">
                          <h6
                            className="card-text fs-02 font-weight-bold"
                          >
                            {banner.title}
                          </h6>
                        </Row>
                        <Row className="mx-0">
                          <h6
                            className="card-text pt-2 fs-03"
                          >
                            <b>
                              {banner.text}
                            </b>
                          </h6>
                        </Row>
                        <Row className="mx-0">
                          <h6
                            className="card-text pt-2 fs-03"
                          >
                            {banner.storeCodes}
                          </h6>
                        </Row>
                        <Row className="mx-0">
                          <Col
                            className="d-flex px-0 py-2 m-0 fs-01"
                          >
                            <div>
                              <b
                                className="text-primary"
                              >
                                Launch Date:
                              </b>
                              &nbsp;
                              {
                                new Date(banner.launchDate).toLocaleDateString(
                                  'en',
                                  dateTimeOptions,
                                )
                              }
                            </div>
                            <div
                              className="pl-3"
                            >
                              <b
                                className="text-primary"
                              >
                                Expiry Date:
                              </b>
                              &nbsp;
                              {
                                new Date(banner.expiryDate).toLocaleDateString(
                                  'en',
                                  dateTimeOptions,
                                )
                              }
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              ),
            )
          }
        </Col>
      </Row>
    );

    const bgColor = {
      key: 'action',
      displayText: 'Select',
      options: [
        {
          label: 'White',
          value: 'WHITE',
        },
        {
          label: 'Blue',
          value: 'BLUE',
        },
        {
          label: 'Yellow',
          value: 'YELLOW',
        },
        {
          label: 'Pink',
          value: 'PINK',
        },
      ],
    };

    const colorType = {
      key: 'color',
      displayText: 'Color',
      options: [
        {
          label: 'White',
          value: 'WHITE',
        },
        {
          label: 'Blue',
          value: 'BLUE',
        },
        {
          label: 'Yellow',
          value: 'YELLOW',
        },
        {
          label: 'Pink',
          value: 'PINK',
        },
        {
          label: 'Black',
          value: 'BLACK',
        },
      ],
    };

    const fontType = {
      key: 'type',
      displayText: 'Font Type',
      options: [
        {
          label: 'Normal',
          value: 'NORMAL',
        },
        {
          label: 'Serif',
          value: 'SERIF',
        },
        {
          label: 'Monospace',
          value: 'MONOSPACE',
        },
      ],
    };

    const fontStyle = {
      key: 'style',
      displayText: 'Font Style',
      options: [
        {
          label: 'Bold',
          value: 'BOLD',
        },
        {
          label: 'Italic',
          value: 'ITALIC',
        },
        {
          label: 'Underline',
          value: 'UNDERLINE',
        },
      ],
    };

    const fontSize = {
      key: 'size',
      displayText: 'Font Size',
      options: [
        {
          label: '8',
          value: 'XXXS',
        },
        {
          label: '9',
          value: 'XXS',
        },
        {
          label: '10',
          value: 'XS',
        },
        {
          label: '12',
          value: 'SM',
        },
        {
          label: '14',
          value: 'MD',
        },
        {
          label: '16',
          value: 'REG',
        },
        {
          label: '20',
          value: 'LG',
        },
      ],
    };

    const HeaderBody = (property, name, customtitle) => (
      <>
        <Form.Group as={Row}>
          <Form.Label column sm={6}>
            <b>
              {customtitle}
              :
            </b>
          </Form.Label>
          <Col
            sm={4}
          >
            <input
              className="form-control fs-0"
              type="text"
              placeholder={`Enter ${customtitle} Text`}
              onChange={(e) => {
                this.setState({
                  [name]: {
                    ...property,
                    text: e.target.value,
                  },
                });
              }}
              value={property.text}
              maxLength="20"
            />
          </Col>
          <Col
            sm={4}
          >
            <input
              className="form-control fs-0"
              type="number"
              placeholder="Enter Text Limit"
              onChange={(e) => {
                if (e.target.value !== '' && (e.target.value < 1 || e.target.value > 20)) {
                  return;
                }
                this.setState({
                  [name]: {
                    ...property,
                    textLimit: e.target.value,
                  },
                });
              }}
              value={property.textLimit}
            />
          </Col>
          <Col
            sm={4}
          >
            <CustomDropdown
              item={fontSize}
              onChange={(item) => {
                this.setState({
                  [name]: {
                    ...property,
                    schema: {
                      ...property.schema,
                      fontSize: item.size,
                    },
                  },
                });
              }}
              selectedVal={property.schema.fontSize}
            />
          </Col>
        </Form.Group>
      </>
    );

    const DropDownBody = (property, name) => (
      <>
        <Form.Group as={Row}>
          <Form.Label column sm={6}>
            <b>
              Properties:
            </b>
          </Form.Label>
          <Col
            sm={4}
          >
            <Form.Check
              type="checkbox"
              label="Retailer to Edit"
              checked={property.isOption === false}
              onChange={() => {
                this.setState({
                  [name]: {
                    ...property,
                    isOption: !property.isOption,
                  },
                });
              }}
            />
          </Col>
          <Col
            sm={4}
          >
            <CustomDropdown
              item={colorType}
              onChange={(item) => {
                this.setState({
                  [name]: {
                    ...property,
                    schema: {
                      ...property.schema,
                      color: item.color,
                    },
                  },
                });
              }}
              selectedVal={property.schema.color}
            />
          </Col>
          <Col
            sm={5}
          >
            <CustomDropdown
              item={fontType}
              onChange={(item) => {
                this.setState({
                  [name]: {
                    ...property,
                    schema: {
                      ...property.schema,
                      fontType: item.type,
                    },
                  },
                });
              }}
              selectedVal={property.schema.fontType}
            />
            <p>(Optional)</p>
          </Col>
          <Col
            sm={3}
          >
            <CustomDropdown
              item={fontStyle}
              onChange={(item) => {
                this.setState({
                  [name]: {
                    ...property,
                    schema: {
                      ...property.schema,
                      fontStyle: item.style,
                    },
                  },
                });
              }}
              selectedVal={property.schema.fontStyle}
            />
            <p>(Optional)</p>
          </Col>
        </Form.Group>
      </>
    );

    const addCustomCardModalBody = (
      <Col>
        {
          submittingShareCardError
          && (
            <ErrorHandler
              retryLogic={() => {
                this.addShareCard();
              }}
            />
          )
        }
        {
          submittingShareCard
          && (
            <div
              className="w-100 pt-3 d-flex align-items-center justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          )
        }
        {
          !submittingShareCard
          && !submittingShareCardError
          && (
            <Col
              className="mx-3 pt-3"
            >
              <Form
                onSubmit={(e) => {
                  this.addShareCard(e);
                }}
              >
                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b>
                      Title:
                    </b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <Form.Control
                      value={title}
                      id="title"
                      onChange={this.handleInputOnChange}
                      autoComplete="off"
                      required
                      maxLength={128}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b>
                      Text:
                    </b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <div className="text-secondary text-right">
                      {text.length}
                      /128
                    </div>
                    <textarea
                      className="form-control"
                      value={text}
                      id="text"
                      onChange={this.handleInputOnChange}
                      autoComplete="off"
                      rows={8}
                      maxLength={128}
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b>
                      Upload Image:
                    </b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <input
                      type="file"
                      onChange={this.handleImageOnChange}
                      required={!!addCustomCard}
                    />
                    {
                      !shareCardImage && (
                        <div
                          className="mt-1 text-medium"
                        >
                          <p
                            className="text-danger"
                          >
                            The image aspect ratio should be 1:1
                          </p>
                          {
                            uploadingImg && (
                              <span className="pl-3">
                                <Spinner
                                  animation="border"
                                  variant="primary"
                                />
                              </span>
                            )
                          }
                        </div>
                      )
                    }
                    {
                      shareCardImage && (
                        <>
                          <div className="card-preview">
                            <img
                              src={shareCardImage}
                              alt="Share Card"
                              width="316px"
                            />
                            <div
                              className="center-text"
                            >
                              <div
                                className={`header-one 
                                  text-${textColor[headerOne.schema.color]}
                                  fontSize-${textFontSize[headerOne.schema.fontSize]}
                                  fontWeight-${textFontStyle[headerOne.schema.fontStyle]}
                                  fontFamily-${textFontType[headerOne.schema.fontType]}
                                `}
                              >
                                {headerOne.text}
                              </div>
                              <div
                                className={`header-two 
                                  text-${textColor[headerTwo.schema.color]}
                                  fontSize-${textFontSize[headerTwo.schema.fontSize]}
                                  fontWeight-${textFontStyle[headerTwo.schema.fontStyle]}
                                  fontFamily-${textFontType[headerTwo.schema.fontType]}
                                `}
                              >
                                {headerTwo.text}
                              </div>
                              <div
                                className={`footer-text 
                                text-${textColor[footer.schema.color]}
                                fontSize-${textFontSize[footer.schema.fontSize]}
                                fontWeight-${textFontStyle[footer.schema.fontStyle]}
                                fontFamily-${textFontType[footer.schema.fontType]}
                              `}
                              >
                                {footer.text}
                              </div>
                            </div>
                          </div>
                          <div
                            className="footer"
                          >
                            <div
                              className="logo"
                            >
                              <Svg
                                svg="lovelocalIcon"
                                width={43}
                                height={43}
                                fill={logoColor[background] || 'white'}
                                stroke="none"
                              />
                            </div>
                            <div
                              className={`fs-0 shopName text-${shopName[background]}`}
                            >
                              ShopName
                            </div>
                            <div
                              className={`fs-0 shopAddress text-${shopAddress[background]}`}
                            >
                              ShopAddress
                            </div>
                            <div
                              className="googleIcon"
                            >
                              <Svg
                                svg="googlePlayIcon"
                                width={100}
                                height={50}
                              />
                            </div>
                          </div>
                        </>
                      )
                    }
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b>Background:</b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <CustomDropdown
                      item={bgColor}
                      onChange={(item) => {
                        this.setState({
                          background: item.action,
                          storeColorInfo: bgcolorMapping[item.action],
                        });
                      }}
                      selectedVal={background}
                    />
                  </Col>
                </Form.Group>

                {HeaderBody(headerOne, 'headerOne', 'Header 1')}
                {DropDownBody(headerOne, 'headerOne')}
                {HeaderBody(headerTwo, 'headerTwo', 'Header 2')}
                {DropDownBody(headerTwo, 'headerTwo')}
                {HeaderBody(footer, 'footer', 'Footer')}
                {DropDownBody(footer, 'footer')}

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b>
                      Launch Date:
                    </b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <input
                      className="form-control"
                      type="datetime-local"
                      value={moment(launchDate).format('YYYY-MM-DDTHH:mm')}
                      id="launchDate"
                      onChange={this.handleChangeDate}
                      autoComplete="off"
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b>
                      Expiry Date:
                    </b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <input
                      className="form-control"
                      type="datetime-local"
                      value={moment(expiryDate).format('YYYY-MM-DDTHH:mm')}
                      id="expiryDate"
                      onChange={this.handleChangeDate}
                      autoComplete="off"
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b>
                      Language:
                    </b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <Select
                      id="selectedLanguage"
                      onChange={(selectedLang) => {
                        this.setState({
                          selectedLanguage: selectedLang,
                        });
                      }}
                      value={selectedLanguage}
                      options={Object.keys(languages)
                        .map((item) => ({ label: languages[item], value: item }))}
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b>
                      Priority Order:
                    </b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <input
                      type="number"
                      value={priorityOrder}
                      id="priorityOrder"
                      onChange={this.handleInputOnChange}
                      autoComplete="off"
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b>
                      StoreIds:
                    </b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <Form.Control
                      value={storeIds}
                      id="storeIds"
                      onChange={this.handleInputOnChange}
                      autoComplete="off"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b>
                      Store Categories:
                    </b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <Select
                      id="selectedCustomerTypes"
                      onChange={(selectedTypes) => {
                        this.setState({
                          selectedStoreCategories: selectedTypes,
                        });
                      }}
                      value={selectedStoreCategories}
                      isMulti
                      options={[{ label: 'ALL', value: '2' }, ...storeCategories]}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b>
                      Create Story:
                    </b>
                  </Form.Label>
                  <Col
                    xs={4}
                    className="px-3"
                  >
                    <Form.Check
                      inline
                      label="Yes"
                      name="createStory"
                      type="radio"
                      checked={createStory}
                      onChange={this.handleRadioButtonChange}
                    />
                  </Col>
                  <Col
                    xs={4}
                    className="px-3"
                  >
                    <Form.Check
                      inline
                      label="No"
                      name="createStory"
                      type="radio"
                      checked={!createStory}
                      onChange={this.handleRadioButtonChange}
                    />
                  </Col>
                </Form.Group>

                {
                  !!invalidDateErrorMsg && (
                    <Col
                      xs={24}
                      className="mb-2 text-danger px-0"
                    >
                      {invalidDateErrorMsg}
                    </Col>
                  )
                }

                <Form.Group as={Row}>
                  <Form.Label column sm={6} />
                  <Col sm={6}>
                    <Button
                      type="submit"
                      variant="outline-primary"
                      disabled={
                        (headerOne.isOption && footer.isOption)
                        || !storeColorInfo || !shareCardImage || !selectedLanguage
                        || !headerOne.schema.color || !headerOne.schema.fontSize
                        || !headerOne.textLimit || !headerTwo.schema.color
                        || !headerTwo.schema.fontSize || !headerTwo.textLimit
                        || !footer.schema.color || !footer.schema.fontSize || !footer.textLimit
                      }
                    >
                      SUBMIT
                    </Button>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6} />
                  <Col
                    className="text-muted"
                    sm={16}
                  >
                    {
                      headerOne.isOption && footer.isOption
                      && (
                        'Atleast Headers or Footer should be selected for Retailer to edit.'
                      )
                    }
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          )
        }
      </Col>
    );

    const addOrEditShareCardModalBody = (
      <Col>
        {
          submittingShareCardError
          && (
            <ErrorHandler
              retryLogic={() => {
                this.addShareCard();
              }}
            />
          )
        }
        {
          submittingShareCard
          && (
            <div
              className="w-100 pt-3 d-flex align-items-center justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          )
        }
        {
          !submittingShareCard
          && !submittingShareCardError
          && (
            <Col
              className="mx-3 pt-3"
            >
              <Form
                onSubmit={(e) => {
                  this.addShareCard(e);
                }}
              >
                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b> Title: </b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <Form.Control
                      value={title}
                      id="title"
                      onChange={this.handleInputOnChange}
                      autoComplete="off"
                      required
                      maxLength={128}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b> Text: </b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <div className="text-secondary text-right">
                      {text.length}
                      /128
                    </div>
                    <textarea
                      value={text}
                      id="text"
                      onChange={this.handleInputOnChange}
                      autoComplete="off"
                      rows={8}
                      className="form-control"
                      maxLength={128}
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b> Image: </b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <input
                      type="file"
                      onChange={this.handleImageOnChange}
                      required={!!addShareCard}
                    />
                    {
                      !shareCardImage && (
                        <div
                          className="mt-1 text-medium"
                        >
                          <p
                            className="text-danger"
                          >
                            The image aspect ratio should be 1:1
                          </p>
                          {
                            uploadingImg && (
                              <span className="pl-3">
                                <Spinner
                                  animation="border"
                                  variant="primary"
                                />
                              </span>
                            )
                          }
                        </div>
                      )
                    }
                    {
                      shareCardImage && (
                        <>
                          <img
                            src={shareCardImage}
                            alt="Share Card"
                            width="316px"
                          />
                          <div
                            className="footer"
                          >
                            <div
                              className="logo"
                            >
                              <Svg
                                svg="lovelocalIcon"
                                width={43}
                                height={43}
                                fill={logoColor[background] || 'white'}
                                stroke="none"
                              />
                            </div>
                            <div
                              className={`fs-0 shopName text-${shopName[background]}`}
                            >
                              ShopName
                            </div>
                            <div
                              className={`fs-0 shopAddress text-${shopAddress[background]}`}
                            >
                              ShopAddress
                            </div>
                            <div
                              className="googleIcon"
                            >
                              <Svg
                                svg="googlePlayIcon"
                                width={100}
                                height={50}
                              />
                            </div>
                          </div>
                        </>
                      )
                    }
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b>Background:</b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <CustomDropdown
                      item={bgColor}
                      onChange={(item) => {
                        this.setState({
                          background: item.action,
                          storeColorInfo: bgcolorMapping[item.action],
                        });
                      }}
                      selectedVal={background}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b> Launch Date: </b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={moment(launchDate).format('YYYY-MM-DDTHH:mm')}
                      id="launchDate"
                      onChange={this.handleChangeDate}
                      autoComplete="off"
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b> Expiry Date: </b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={moment(expiryDate).format('YYYY-MM-DDTHH:mm')}
                      id="expiryDate"
                      onChange={this.handleChangeDate}
                      autoComplete="off"
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b> Language: </b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <Select
                      id="selectedLanguage"
                      onChange={(selectedLang) => {
                        this.setState({
                          selectedLanguage: selectedLang,
                        });
                      }}
                      value={selectedLanguage}
                      options={Object.keys(languages)
                        .map((item) => ({ label: languages[item], value: item }))}
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b> Priority Order: </b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <input
                      type="number"
                      value={priorityOrder}
                      id="priorityOrder"
                      onChange={this.handleInputOnChange}
                      autoComplete="off"
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b> storeIds: </b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <Form.Control
                      value={storeIds}
                      id="storeIds"
                      onChange={this.handleInputOnChange}
                      autoComplete="off"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b> Store Categories: </b>
                  </Form.Label>
                  <Col
                    sm={12}
                  >
                    <Select
                      id="selectedCustomerTypes"
                      onChange={(selectedTypes) => {
                        this.setState({
                          selectedStoreCategories: selectedTypes,
                        });
                      }}
                      value={selectedStoreCategories}
                      isMulti
                      options={[{ label: 'ALL', value: '2' }, ...storeCategories]}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    <b>
                      Create Story:
                    </b>
                  </Form.Label>
                  <Col
                    xs={4}
                    className="px-3"
                  >
                    <Form.Check
                      inline
                      label="Yes"
                      name="createStory"
                      type="radio"
                      checked={createStory}
                      onChange={this.handleRadioButtonChange}
                    />
                  </Col>
                  <Col
                    xs={4}
                    className="px-3"
                  >
                    <Form.Check
                      inline
                      label="No"
                      name="createStory"
                      type="radio"
                      checked={!createStory}
                      onChange={this.handleRadioButtonChange}
                    />
                  </Col>
                </Form.Group>

                {
                  !!invalidDateErrorMsg && (
                    <Col
                      xs={24}
                      className="mb-2 text-danger px-0"
                    >
                      {invalidDateErrorMsg}
                    </Col>
                  )
                }

                <Form.Group as={Row}>
                  <Form.Label column sm={6} />
                  <Col sm={6}>
                    <Button
                      type="submit"
                      variant="outline-primary"
                      disabled={!shareCardImage || !selectedLanguage || !storeColorInfo}
                    >
                      SUBMIT
                    </Button>
                  </Col>
                </Form.Group>

              </Form>
            </Col>
          )
        }
      </Col>
    );

    const deleteShareCardConfirmationModalBody = (
      <Col>
        {
          submittingShareCardError
          && (
            <ErrorHandler
              retryLogic={() => {
                this.deleteShareCard();
              }}
            />
          )
        }
        {
          submittingShareCard
          && (
            <div
              className="w-100 pt-3 d-flex align-items-center justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          )
        }
        {
          !submittingShareCardError && !submittingShareCard && shareCardUidToDelete
          && (
            <Container
              className="justify-content-center"
            >
              <Row
                className="mx-auto mt-4"
              >
                <Col
                  xs={24}
                  className="text-center fs-3"
                >
                  Are you sure you want to&nbsp;
                  <span
                    className="text-danger"
                  >
                    DELETE
                  </span>
                  &nbsp;this Share Card ?
                </Col>
              </Row>
              <Row
                className="mt-4"
              >
                <Col
                  xs={12}
                  className="text-right"
                >
                  <Button
                    variant="outline-primary"
                    value="cancel"
                    className="py-1 px-3 fs-2"
                    disabled={submittingShareCard}
                    onClick={() => {
                      this.setState({
                        shareCardUidToDelete: null,
                      });
                    }}
                  >
                    CANCEL
                  </Button>
                </Col>
                <Col
                  xs={12}
                  className="text-left"
                >
                  <Button
                    variant="outline-danger"
                    value="submit"
                    className="py-1 px-3 fs-2 lg active"
                    disabled={submittingShareCard}
                    onClick={() => {
                      this.deleteShareCard();
                    }}
                  >
                    DELETE
                  </Button>
                </Col>
              </Row>
            </Container>
          )
        }
      </Col>
    );

    return (
      <Container
        fluid
        className="bg-white h-100"
      >
        <CustomModal
          body={addOrEditShareCardModalBody}
          closeButton
          size="xl"
          show={(addShareCard || !!shareCardUidToEdit) && !editCustomCard}
          title={`${addShareCard ? 'Add Share Card' : 'Edit Share Card'}`}
          onHide={() => {
            this.setState({
              addShareCard: false,
              shareCardUidToEdit: false,
              submittingShareCard: false,
              submittingShareCardError: false,
              uploadingImg: false,
              title: '',
              text: '',
              shareCardImage: '',
              launchDate: new Date(),
              expiryDate: getOneMonthLaterDate(),
              invalidDateErrorMsg: '',
              priorityOrder: 1,
              selectedLanguage: null,
              storeIds: '',
              selectedStoreCategories: [],
              background: null,
              storeColorInfo: '',
              createStory: true,
            });
          }}
        />
        <CustomModal
          body={addCustomCardModalBody}
          closeButton
          size="xl"
          show={addCustomCard || editCustomCard}
          title="Add Custom Card"
          onHide={() => {
            this.setState({
              addCustomCard: false,
              submittingShareCardError: false,
              uploadingImg: false,
              title: '',
              text: '',
              shareCardImage: '',
              launchDate: new Date(),
              expiryDate: getOneMonthLaterDate(),
              invalidDateErrorMsg: '',
              priorityOrder: 1,
              selectedLanguage: null,
              storeIds: '',
              background: null,
              storeColorInfo: '',
              headerOne: { ...headersAndBootomDefault },
              headerTwo: { ...headersAndBootomDefault },
              footer: { ...headersAndBootomDefault },
              editCustomCard: false,
              shareCardUidToEdit: false,
              selectedStoreCategories: [],
              createStory: true,
            });
          }}
        />
        <CustomModal
          body={deleteShareCardConfirmationModalBody}
          closeButton
          size="lg"
          show={!!shareCardUidToDelete}
          title="Delete Share Card"
          onHide={() => {
            this.setState({
              shareCardUidToDelete: null,
              submittingShareCard: false,
              submittingShareCardError: false,
            });
          }}
        />
        <Row
          className={`py-3 px-2 ${this.canIEdit ? '' : 'd-none'}`}
        >
          <Col
            xs="auto"
            className="mt-3"
          >
            <Button
              variant="outline-primary"
              onClick={() => {
                this.setState({
                  addShareCard: true,
                });
              }}
            >
              ADD SHARE CARD
            </Button>
          </Col>
          <Col
            xs="auto"
            className="mt-3"
          >
            <Button
              variant="outline-primary"
              onClick={() => {
                this.setState({
                  addCustomCard: true,
                });
              }}
            >
              ADD CUSTOM CARD
            </Button>
          </Col>
          <Row
            className="align-items-center"
          >
            <Col
              xs="auto"
              className="pl-4"
            >
              <div className="pb-1 fs-01 text-disabled">
                Launch Date:
              </div>
              <div>
                <DatePicker
                  isDateRange
                  onApply={(dateRange) => {
                    this.handleDateChange(
                      new Date(dateRange.startDate).getTime(),
                      new Date(dateRange.endDate).getTime(),
                    );
                  }}
                  startDate={stDate}
                  endDate={eDate}
                  onClear={() => {
                    this.setState({
                      stDate: '',
                      eDate: '',
                    });
                  }}
                />
              </div>
            </Col>
            {
              filterConfForSingleSelect.map((item) => (
                <Col
                  key={item.key}
                  xs="auto"
                  className="pl-3"
                >
                  <div className="pb-1 fs-01 text-disabled">
                    {`Select ${item.displayText}:`}
                  </div>
                  <div>
                    <CustomDropdown
                      item={item}
                      onChange={(data) => {
                        this.handleDropdownChange(data);
                      }}
                      selectedVal={newParam[item.key]}
                    />
                  </div>
                </Col>
              ))
            }
            {
              filterConfForMultiSelect.map((item) => (
                <Col
                  key={item.key}
                  xs="auto"
                  className="pl-3"
                >
                  <div className="pb-1 fs-01 text-disabled">
                    {`Select ${item.displayText}:`}
                  </div>
                  <div>
                    <Select
                      id={item.key}
                      placeholder={item.displayText}
                      onChange={(selectedItems) => {
                        const selectedItemsString = selectedItems.reduce(
                          (acc, selItem, i) => acc.concat(i === 0 ? '' : ',', selItem.value), '',
                        );
                        this.handleDropdownChange({ [item.key]: selectedItemsString });
                      }}
                      selectedVal={newParam[item.key]}
                      options={item.options}
                      isMulti
                      value={newParam[item.key]
                        ? (newParam[item.key].split(','))
                          .map((val) => ({ label: val, value: val }))
                        : []}
                      className="minw-150p"
                    />
                  </div>
                </Col>
              ))
            }
          </Row>
        </Row>
        <Row className="py-2 d-flex align-items-center flex-row-reverse">
          <Col
            xs="auto"
            className="px-2"
          >
            <Button
              variant="primary"
              className="fs-01 font-weight-bold"
              onClick={this.resetFilters}
            >
              RESET FILTERS
            </Button>
          </Col>
          <Col
            xs="auto"
            className="px-2"
          >
            <Button
              variant="success"
              className="fs-01 font-weight-bold"
              onClick={this.applyFilters}
            >
              APPLY FILTERS
            </Button>
          </Col>
        </Row>
        <Row
          className="overflow-y-scroll banner-container"
        >
          {
            bannersData && (
              <Col xs={24}>
                {bannersList}
              </Col>
            )
          }
          <Col xs={24} className="text-center">
            {
              bannersData
              && bannersData.results.length === 0
              && (
                <h6
                  className="p-3 text-danger"
                >
                  Not Available any Share/Custom Card
                </h6>
              )
            }
          </Col>
          <Col
            xs={24}
            className={!bannersData && loading ? 'd-flex justify-content-center py-4' : 'd-none'}
          >
            <Spinner
              animation="border"
              variant="primary"
            />
          </Col>
          <Col
            xs={24}
            className={!bannersData && error ? 'justify-content-center py-4' : 'd-none'}
          >
            <ErrorHandler
              retryLogic={() => this.retry()}
            />
          </Col>
        </Row>
        {
          bannersData && (
            <Row>
              <ProcessingStatus
                show={loading || error}
                loading={loading}
                error={error}
                onRetry={() => this.retry()}
                onCancel={this.onCancel}
              />
              <Col
                xs={24}
                className="px-0"
              >
                <CustomPagination
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={bannersData.count}
                  hasPrev={bannersData.hasPrevious}
                  hasNext={bannersData.hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            </Row>
          )
        }
      </Container>
    );
  }
}

ShareOfferCard.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default ShareOfferCard;
