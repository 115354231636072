import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal, CustomTable, ErrorHandler } from '../../component/common';
import { fraudStore } from '../../assets/api/axios';
import permission from '../../access&permissions/permission';

class FraudDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      fraudDetails: null,
      acceptFraudStatus: '',
      rejectFraudStatus: '',
      underReviewStatus: '',
      selectedFraudReasonIds: [],
      fraudComment: '',
      isCollapse: true,
      otherReason: false,
      fraudErrorMsg: '',
      fraudModal: '',
      agentComment: '',
      rejectFraudModal: false,
    };
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleLoad = () => {
    const { storeId } = this.props;
    fraudStore(
      'GET',
      storeId,
    ).then((res) => {
      this.setState({
        fraudDetails: res.data,
        loading: false,
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });
  };

  handleStoreFraud = (fraudType) => {
    this.setState(() => {
      if (fraudType === 'under_review') {
        return {
          underReviewStatus: 'loading',
        };
      } if (fraudType === 'reject') {
        return {
          rejectFraudStatus: 'loading',
        };
      }
      return {
        acceptFraudStatus: 'loading',
      };
    });

    const { storeId } = this.props;
    const { fraudComment, selectedFraudReasonIds, agentComment } = this.state;
    let status = '';
    let comments = [];

    if (fraudType === 'under_review') {
      status = 'UNDER_REVIEW';
    } else {
      status = (fraudType === 'mark' || fraudType === 'accept') ? 'FRAUD' : 'REJECTED';
    }

    if (fraudComment) {
      comments = [fraudComment];
    }

    fraudStore(
      'PUT',
      storeId,
      {
        status,
        comments,
        reasonMapIds: selectedFraudReasonIds,
        agentComment,
      },
    ).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        this.setState({
          acceptFraudStatus: '',
          rejectFraudStatus: '',
          underReviewStatus: '',
          selectedFraudReasonIds: [],
          fraudComment: '',
        });
        if (res && res.data && res.data.feedbackMessage) {
          this.setState({
            fraudModal: res.data.feedbackMessage,
          });
        } else {
          window.location.reload();
        }
      } else {
        throw new Error();
      }
    }).catch((error) => {
      const commonFields = {
        acceptFraudStatus: '',
        rejectFraudStatus: '',
        underReviewStatus: '',
      };
      this.setState(() => {
        if (
          error
          && error.response
          && error.response.status === 400
          && error.response.data
          && error.response.data[0]
        ) {
          return {
            fraudErrorMsg: error.response.data[0].client_message,
            ...commonFields,
          };
        }
        return {
          fraudErrorMsg: 'Oops Something went wrong. Please try again!!',
          ...commonFields,
        };
      });
    });
  }

  toggleSelectOrder = (event, commentId) => {
    const { selectedFraudReasonIds } = this.state;
    if (event.target.checked) {
      selectedFraudReasonIds.push(commentId);
    } else {
      const index = selectedFraudReasonIds.findIndex((id) => id === commentId);
      selectedFraudReasonIds.splice(index, 1);
    }
    this.setState({
      selectedFraudReasonIds,
    });
  }

  selectRenderer = (data) => {
    const { selectedFraudReasonIds } = this.state;
    return (
      <input
        type="checkbox"
        checked={selectedFraudReasonIds.includes(data.id)}
        name="markCancel"
        onChange={(event) => this.toggleSelectOrder(event, data.id)}
      />
    );
  }

  handleSelectAll = (event, reasons) => {
    const { selectedFraudReasonIds } = this.state;
    if (event.target.checked) {
      const fraudReasonsIds = selectedFraudReasonIds;
      reasons.map((item) => {
        if (!selectedFraudReasonIds.includes(item.id)) {
          fraudReasonsIds.push(item.id);
        }
        return 0;
      });
      this.setState({
        selectedFraudReasonIds: fraudReasonsIds,
      });
    } else {
      const fraudReasonsIds = selectedFraudReasonIds;
      reasons.map((item) => {
        const index = selectedFraudReasonIds.indexOf(item.id);
        if (index !== -1) {
          fraudReasonsIds.splice(index, 1);
        }
        return 0;
      });
      this.setState({
        selectedFraudReasonIds: fraudReasonsIds,
      });
    }
  }

  activeStoreFraudComments = (fraudReasons = null) => {
    const { selectedFraudReasonIds } = this.state;
    const { userPermission } = this.props;
    if (fraudReasons) {
      return (
        <div
          className="py-2"
        >
          <div
            className="pb-1 font-weight-bold fs-1"
          >
            {fraudReasons.status}
          </div>
          <div>
            <CustomTable
              headers={[
                ...(
                  (fraudReasons.status === 'FLAGGED'
                  || fraudReasons.status === 'UNDER_REVIEW'
                  || (fraudReasons.status === 'FRAUD' && userPermission.includes(permission.FRAUD_ADMIN)))
                    ? [
                      {
                        key: 'select',
                        displayText: (
                          <input
                            type="checkbox"
                            checked={
                              fraudReasons.reasons
                                ? (fraudReasons.reasons.length <= selectedFraudReasonIds.length
                                  && (fraudReasons.reasons.findIndex((item) => (
                                    !selectedFraudReasonIds.includes(item.id)
                                  ))) === -1)
                                : false
                            }
                            onChange={(event) => this.handleSelectAll(event, fraudReasons.reasons)}
                            disabled={!fraudReasons || !fraudReasons.reasons}
                          />
                        ),
                        renderer: (data) => this.selectRenderer(data),
                      },
                    ] : []
                ),
                {
                  key: 'rule',
                  displayText: 'Rule',
                },
                {
                  key: 'createdOn',
                  displayText: 'Created On',
                },
                {
                  key: 'createdBy',
                  displayText: 'Created By',
                },
                {
                  key: 'lastUpdatedBy',
                  displayText: 'Last Updated By',
                },
                {
                  key: 'ruleMeta',
                  displayText: 'Rule Meta',
                },
                {
                  key: 'modifiedOn',
                  displayText: 'Modified On',
                },
                {
                  key: 'counter',
                  displayText: 'Counter',
                },
              ]}
              content={fraudReasons.reasons}
              keyField="id"
              totalItems={fraudReasons.reasons.length}
              isPaginated={false}
            />
          </div>
        </div>
      );
    }
    return null;
  }

  inactiveStoreFraudComments = (fraudReasons = null) => {
    if (fraudReasons) {
      return (
        <div
          className="py-2"
        >
          <div
            className="pb-1 font-weight-bold fs-1"
          >
            {fraudReasons.status}
          </div>
          <div>
            <CustomTable
              headers={[
                {
                  key: 'rule',
                  displayText: 'Rule',
                },
                {
                  key: 'createdOn',
                  displayText: 'Created On',
                },
                {
                  key: 'createdBy',
                  displayText: 'Created By',
                },
                {
                  key: 'lastUpdatedBy',
                  displayText: 'Last Updated By',
                },
                {
                  key: 'ruleMeta',
                  displayText: 'Rule Meta',
                },
                {
                  key: 'modifiedOn',
                  displayText: 'Modified On',
                },
                {
                  key: 'counter',
                  displayText: 'Counter',
                },
              ]}
              content={fraudReasons.reasons}
              keyField="id"
              totalItems={fraudReasons.reasons.length}
              isPaginated={false}
            />
          </div>
        </div>
      );
    }
    return null;
  }

  body = () => {
    const { userPermission } = this.props;
    const {
      loading, error, fraudDetails,
      acceptFraudStatus, rejectFraudStatus, underReviewStatus,
      selectedFraudReasonIds, fraudComment, fraudErrorMsg,
      otherReason, isCollapse,
    } = this.state;
    const disableButton = selectedFraudReasonIds.length === 0 && !fraudComment;

    return (
      <>
        {(() => {
          let showcase = null;
          if (loading) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center
                justify-content-center"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (!loading && error) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center
                justify-content-center"
              >
                <ErrorHandler
                  retryLogic={() => this.handleLoad()}
                />
              </div>
            );
          } else if (!loading && !error && fraudDetails) {
            showcase = (
              <Container
                fluid
                className="py-3"
              >
                <Row
                  className="pb-3"
                >
                  <Col
                    xs={24}
                    className="d-flex justify-content-center"
                  >
                    <div>
                      {
                        acceptFraudStatus === 'loading'
                          ? (
                            <Spinner
                              variant="primary"
                              animation="border"
                            />
                          ) : (
                            <Button
                              variant="primary"
                              className="px-3"
                              disabled={
                                rejectFraudStatus === 'loading'
                                || underReviewStatus === 'loading'
                                || disableButton
                                || (!userPermission.includes(permission.FRAUD_AGENT) && !userPermission.includes(permission.FRAUD_ADMIN))
                              }
                              onClick={() => {
                                this.handleStoreFraud('accept');
                              }}
                            >
                              Accept Fraud
                            </Button>
                          )
                        }
                    </div>
                    <div
                      className="pl-3"
                    >
                      {
                        rejectFraudStatus === 'loading'
                          ? (
                            <Spinner
                              variant="primary"
                              animation="border"
                            />
                          ) : (
                            <Button
                              variant="outline-primary"
                              className="px-3"
                              disabled={
                                acceptFraudStatus === 'loading'
                                || underReviewStatus === 'loading'
                                || disableButton
                                || selectedFraudReasonIds.length === 0
                                || (!userPermission.includes(permission.FRAUD_AGENT) && !userPermission.includes(permission.FRAUD_ADMIN))
                              }
                              onClick={() => {
                                this.setState({ rejectFraudModal: true });
                              }}
                            >
                              Reject Fraud
                            </Button>
                          )
                      }
                    </div>
                    <div
                      className="pl-3"
                    >
                      {
                        underReviewStatus === 'loading'
                          ? (
                            <Spinner
                              variant="primary"
                              animation="border"
                            />
                          ) : (
                            <Button
                              variant="outline-primary"
                              className="px-3"
                              disabled={
                                acceptFraudStatus === 'loading'
                                || rejectFraudStatus === 'loading'
                                || disableButton
                                || (!userPermission.includes(permission.FRAUD_AGENT) && !userPermission.includes(permission.FRAUD_ADMIN))
                              }
                              onClick={() => {
                                this.handleStoreFraud('under_review');
                              }}
                            >
                              Under Review
                            </Button>
                          )
                      }
                    </div>
                  </Col>
                  {
                    disableButton && (
                      <Col
                        xs={24}
                        className="d-flex justify-content-center"
                      >
                        <div>
                          (Select Atleast 1 fraud reason OR Enter Other reason)
                        </div>
                      </Col>
                    )
                  }
                  {
                    fraudErrorMsg && (
                      <Col
                        xs={24}
                        className="d-flex justify-content-center text-danger"
                      >
                        {fraudErrorMsg}
                      </Col>
                    )
                  }
                </Row>
                <Row>
                  <Col
                    xs={12}
                    className="pb-2 text-center fs-3 border-bottom"
                  >
                    <b>Store Fraud Reasons</b>
                  </Col>
                  <Col
                    xs={12}
                    className="pb-2 text-center fs-3 border-bottom"
                  >
                    <b>Overall Fraud Status:</b>
                    &nbsp;
                    <span
                      className={`
                        ${fraudDetails.overallStatus === 'FRAUD' ? 'text-danger' : ''}
                        ${(fraudDetails.overallStatus === 'UNDER_REVIEW'
                        || fraudDetails.overallStatus === 'FLAGGED') ? 'text-orange' : ''}
                        ${fraudDetails.overallStatus === 'NOT_FLAGGED' ? 'text-success' : ''}
                      `}
                    >
                      {fraudDetails.overallStatus}
                    </span>
                  </Col>
                  <Col
                    xs={24}
                  >
                    {
                      fraudDetails && fraudDetails.flags
                      && fraudDetails.flags.ACTIVE && fraudDetails.flags.ACTIVE.length > 0 && (
                        <div
                          className="border-bottom"
                        >
                          <b
                            className="text-primary fs-2"
                          >
                            Active Fraud Comments
                          </b>
                          <div
                            className="py-2"
                          >
                            {
                              fraudDetails.flags.ACTIVE.map((item) => (
                                this.activeStoreFraudComments(item)
                              ))
                            }
                          </div>
                        </div>
                      )
                    }
                    <div
                      className="pb-2 pt-2 border-bottom"
                    >
                      <Button
                        variant="outline-primary"
                        className="py-1"
                        onClick={() => {
                          this.setState({
                            otherReason: !otherReason,
                          });
                        }}
                      >
                        Add Other Reason
                      </Button>
                    </div>
                    {
                      otherReason && (
                      <input
                        type="text"
                        value={fraudComment}
                        placeholder="Enter Other Fraud Reason"
                        name="fraudComment"
                        className="form-control w-100"
                        onChange={(event) => {
                          this.setState({
                            fraudComment: event.target.value,
                          });
                        }}
                      />
                      )
                    }
                    {
                      fraudDetails && fraudDetails.flags
                      && fraudDetails.flags.NOT_ACTIVE && fraudDetails.flags.NOT_ACTIVE.length && (
                        <div
                          className={`${isCollapse ? '' : 'border-bottom'}`}
                        >
                          <b
                            className="text-success fs-2"
                          >
                            In-Active Fraud Comments
                            &nbsp;&nbsp;
                            <Button
                              variant="outline-success"
                              className="px-1 mt-1"
                              onClick={() => {
                                this.setState({
                                  isCollapse: !isCollapse,
                                });
                              }}
                            >
                              {isCollapse ? 'More' : 'Less'}
                            </Button>
                          </b>
                          {
                            !isCollapse && (
                              <div
                                className="py-2"
                              >
                                {
                                  fraudDetails.flags.NOT_ACTIVE.map((item) => (
                                    this.inactiveStoreFraudComments(item)
                                  ))
                                }
                              </div>
                            )
                          }
                        </div>
                      )
                    }
                  </Col>
                </Row>
              </Container>
            );
          }
          return showcase;
        })()}
      </>
    );
  }

  render() {
    const { show, onHide } = this.props;
    const { fraudModal, rejectFraudModal, agentComment } = this.state;
    return (
      <>
        <CustomModal
          show={show}
          title="Fraud Reason"
          size="xl"
          body={this.body()}
          onHide={() => {
            this.setState({
              acceptFraudStatus: '',
              rejectFraudStatus: '',
              underReviewStatus: '',
              selectedFraudReasonIds: [],
            }, onHide());
          }}
          closeButton
          backdrop
        />
        <CustomModal
          show={rejectFraudModal}
          title="Add Comment"
          autoSize
          onHide={() => {
            this.setState({
              rejectFraudModal: false,
            });
          }}
          body={(
            <Container>
              <Row>
                <Col
                  xs={24}
                  className="py-3 border"
                >
                  <Row
                    className="pt-2"
                  >
                    <Col>
                      <input
                        type="text"
                        value={agentComment}
                        placeholder="Enter Fraud Reason"
                        name="agentComment"
                        className="form-control w-100"
                        onChange={(event) => {
                          this.setState({
                            agentComment: event.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row
                    className="pt-4 text-center"
                  >
                    <Col>
                      <Button
                        variant="primary"
                        className="py-1"
                        onClick={() => {
                          this.setState({
                            rejectFraudModal: false,
                          }, () => {
                            this.handleStoreFraud('reject');
                          });
                        }}
                        disabled={!agentComment}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          )}
          closeButton
          backdrop
          border
        />
        <CustomModal
          show={fraudModal}
          title="Fraud Store"
          onHide={() => {
            this.setState({
              fraudModal: '',
            }, () => {
              window.location.reload();
            });
          }}
          closeButton
          autoSize
          border
          body={(
            <Container>
              <Row>
                <Col
                  xs={24}
                  className="py-3 border"
                >
                  <Row
                    className="pt-2"
                  >
                    <Col
                      className="text-danger text-center"
                    >
                      <b>{fraudModal}</b>
                    </Col>
                  </Row>
                  <Row
                    className="pt-4 text-center"
                  >
                    <Col>
                      <Button
                        variant="primary"
                        className="py-1"
                        onClick={() => {
                          this.setState({
                            fraudModal: '',
                          }, () => {
                            window.location.reload();
                          });
                        }}
                      >
                        Okay
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          )}
        />
      </>
    );
  }
}

FraudDetails.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  storeId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default FraudDetails;
