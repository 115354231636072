import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3628_62796)">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.5 3C6.39543 3 5.5 3.89543 5.5 5V19C5.5 20.1046 6.39543 21 7.5 21H8.5C9.60457 21 10.5 20.1046 10.5 19V5C10.5 3.89543 9.60457 3 8.5 3H7.5ZM15.5 3C14.3954 3 13.5 3.89543 13.5 5V19C13.5 20.1046 14.3954 21 15.5 21H16.5C17.6046 21 18.5 20.1046 18.5 19V5C18.5 3.89543 17.6046 3 16.5 3H15.5Z" fill="#808080" />
    </g>
    <defs>
      <clipPath id="clip0_3628_62796">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default Svg;
