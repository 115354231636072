import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, InputGroup, Form, Spinner, Button,
} from 'react-bootstrap';
import { Svg, ErrorHandler } from '../../component/common';
import { Constant } from '../../utilities';
import { stores } from '../../assets/api/axios';
import RetailerSettings from '../../layouts/retailer-management/RetailerSettings';
import '../../assets/scss/RetailerManagement/RetailerSetting.scss';

class Setting extends React.Component {
  constructor(props) {
    const { isDetailPage, retailerDetails } = props;
    let selectedRetailer = null;
    let loading = true;
    if (isDetailPage) {
      loading = false;
      selectedRetailer = {
        sid: retailerDetails.code,
        name: retailerDetails.name,
      };
    }
    super();
    this.state = {
      retailers: null,
      selectedRetailer,
      loading,
      error: false,
      searchText: '',
    };
    this.activeTimeout = null;
  }

  componentDidMount = () => {
    const { isDetailPage } = this.props;
    if (!isDetailPage) {
      this.handleLoad();
    }
  }

  handleLoad = () => {
    stores('get', true)
      .then((res) => {
        this.setState({
          retailers: res.data,
          loading: false,
          error: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  handleChange = (e) => {
    this.handleSuggestionContainer();
    this.setState({
      searchText: e.target.value,
      ...(e.target.value === ''
        ? {
          selectedRetailer: null,
        } : {}
      ),
    });
  }

  onFocus = () => {
    if (this.activeTimeout) {
      clearTimeout(this.activeTimeout);
    }
  }

  onBlur = () => {
    this.activeTimeout = setTimeout(() => {
      const {
        selectedRetailer,
        searchText,
      } = this.state;
      if (selectedRetailer && searchText !== selectedRetailer.name) {
        this.setState({
          searchText: selectedRetailer.name,
        });
      }
      this.handleSuggestionContainer('hidden');
    }, 200);
  }

  onSelect = (sid, name) => {
    this.setState({
      selectedRetailer: {
        sid,
        name,
      },
      searchText: name,
    });
  }

  handleSuggestionContainer = (status = 'visible') => {
    const target = document.getElementById('suggestion-container');
    if (status !== target.style.visibility) {
      target.style.visibility = status;
    }
  }

  render() {
    const { isDetailPage } = this.props;
    const {
      loading, error, searchText, retailers, selectedRetailer,
    } = this.state;

    if (loading) {
      return (
        <div
          className="h-100 d-flex align-items-center
             justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!loading && error) {
      return (
        <div
          className="h-100 d-flex align-items-center
             justify-content-center"
        >
          <ErrorHandler
            retryLogic={() => {
              this.setState({
                loading: true,
                error: false,
              }, () => {
                this.handleLoad();
              });
            }}
          />
        </div>
      );
    }

    if (retailers || isDetailPage) {
      return (
        <Container
          fluid
          id="retailer-setting"
          className="mx-0 h-100"
        >
          <Row
            className="h-100 align-items-start bg-white"
          >
            {!isDetailPage && (
            <Col
              xs={12}
              className="px-0 filter-section h-50p bg-white"
            >
              <Row
                className="mx-0 h-100 align-items-center"
              >
                <Col
                  xs={24}
                  className="px-2"
                >
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        className="rounded-0"
                      >
                        <Svg
                          svg="search"
                          width="1rem"
                          fill={Constant.Color.DARK}
                        />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      value={searchText}
                      onChange={this.handleChange}
                      placeholder="Search"
                      className="rounded-0 fs-0"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                    />
                  </InputGroup>
                  <div
                    id="suggestion-container"
                  >
                    <div
                      className="search-suggestion"
                    >
                      {retailers.onMpaani.filter((retailer) => (
                        searchText && RegExp(searchText.toLowerCase())
                          .test((`${retailer.code} ${retailer.displayName}`)
                            .toLowerCase()))).map((retailer) => (
                              <Button
                                key={retailer.code}
                                variant="white"
                                className={`w-100 text-left
                                rounded-0 fs-0 py-2 ${
                                    retailer.code === (selectedRetailer
                                    && selectedRetailer.sid)
                                      ? 'text-primary' : '  '
                                }`}
                                onClick={() => {
                                  this.onSelect(
                                    retailer.code,
                                    retailer.displayName,
                                  );
                                }}
                              >
                                {retailer.displayName}
                              </Button>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            )}
            {
              selectedRetailer && (
                <Col
                  xs={24}
                  className={`px-0 settings-showcase ${isDetailPage ? '' : 'pt-50p'}`}
                >
                  <RetailerSettings
                    {...this.props}
                    storeCode={selectedRetailer.sid}
                    isDetailPage={isDetailPage}
                  />
                </Col>
              )
            }
          </Row>
        </Container>
      );
    }
    return null;
  }
}

Setting.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }).isRequired,
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
  }),
  isDetailPage: PropTypes.bool,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

Setting.defaultProps = {
  retailerDetails: null,
  isDetailPage: false,
};

export default Setting;
