import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.042 7.68h-6.166V1.957a1.958 1.958 0 1 0-3.916 0V7.68H1.958a1.959 1.959 0 0 0 0 3.915H7.96v6.447a1.957 1.957 0 1 0 3.916 0v-6.447h6.167a1.958 1.958 0 1 0-.001-3.915Z"
      fill="#fff"
    />
  </svg>
);

Svg.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default Svg;
