/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Button, Spinner,
} from 'react-bootstrap';
import QueryString from 'query-string';
import { leadershipBoard, myOrders, myReferral } from '../../assets/api/axios';
import '../../assets/scss/LeadershipBoard/LeadershipBoard.scss';
import { DatePicker, ErrorHandler } from '../../component/common';
import CrossIcon from '../../component/common/svg/CrossIcon';
import ShopIcon from '../../component/common/svg/ShopIcon';
import TimingIcon from '../../component/common/svg/TimingIcon';
import WinningIcon from '../../component/common/svg/WinningIcon';
import { ProcessingStatus } from '../../component/derived/table-list';
import { getDateString, getInitials } from '../../utilities/Utils';
import ReferAShop from './ReferAShop';
import ViewMyRecentOrders from './ViewMyRecentOrders';
import ViewMyReferrals from './ViewMyReferrals';
import TickIcon from '../../component/common/svg/TickIcon';
import ListIcon from '../../component/common/svg/ListIcon';
import CancelIcon from '../../component/common/svg/CancelIcon';

const limit = 10;

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    startDate, endDate,
  } = param;
  const {
    orderStartDate, orderEndDate,
  } = param;
  startDate = Number(startDate) || new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
  startDate = new Date(startDate).setHours(0, 0, 0, 0);
  endDate = Number(endDate) || new Date().setHours(23, 59, 59, 999);
  return ({
    startDate,
    endDate,
    orderStartDate,
    orderEndDate,
  });
};

class LeadershipBoard extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    this.state = {
      orders: null,
      ordersLoading: true,
      ordersError: false,
      ordersOffset: 0,
      referrals: null,
      referralsLoading: true,
      referralsError: false,
      referralOffset: 0,
      myReferrals: null,
      myReferralsLoading: true,
      myReferralsError: false,
      myReferralsErrorMsg: '',
      myRecentOrders: null,
      myRecentOrdersLoading: true,
      myRecentOrdersError: false,
      myRecentOrdersErrorMsg: '',
      selectedTab: 'orders',
      referShopModal: false,
      viewReferralModal: false,
      viewOrdersModal: false,
      orderStartDate: '',
      orderEndDate: '',
      param,
    };
    this.winnerColors = ['#FBBC19', '#575A89', '#B277FF', '#47ACB1', '#A0616A'];
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleLoad = () => {
    this.handleLeaderShipOrders();
    this.handleLeaderShipReferrals();
    this.handleMyReferral();
    this.handleMyRecentOrders();
  }

  handleLeaderShipOrders = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param, ordersOffset, orders = {} } = this.state;
    const newParam = { ...param, ...data };
    const {
      startDate, endDate,
    } = newParam;
    leadershipBoard(
      'GET',
      {},
      {
        offset: `${ordersOffset}`,
        limit,
        action: 'orders',
        startDate,
        endDate,
      },
    ).then((res) => {
      this.setState({
        orders: {
          ...res.data,
          results: orders ? [...orders.results, ...res.data.results] : res.data.results,
        },
        ordersLoading: false,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        ordersLoading: false,
        ordersError: true,
      });
    });

    this.retryOrders = () => {
      this.setState({
        ordersLoading: true,
        ordersError: false,
      }, () => {
        this.handleLeaderShipOrders({ ...newParam });
      });
    };
  };

  handleLeaderShipReferrals = (data = {}) => {
    const { param, referralOffset, referrals = {} } = this.state;
    const newParam = { ...param, ...data };
    const {
      startDate, endDate,
    } = newParam;
    leadershipBoard(
      'GET',
      {},
      {
        offset: `${referralOffset}`,
        limit,
        action: 'referrals',
        startDate,
        endDate,
      },
    ).then((res) => {
      this.setState({
        // referrals: res.data,
        referrals: {
          ...res.data,
          results: referrals ? [...referrals.results, ...res.data.results] : res.data.results,
        },
        referralsLoading: false,
      });
    }).catch(() => {
      this.setState({
        referralsLoading: false,
        referralsError: true,
      });
    });

    this.retryReferrals = () => {
      this.setState({
        referralsLoading: true,
        referralsError: false,
      }, () => {
        this.handleLeaderShipReferrals({ ...newParam });
      });
    };
  }

  handleMyReferral = () => {
    myReferral(
      'GET',
      {},
      {
        offset: 0,
        limit: 3,
      },
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          myReferrals: res.data,
          myReferralsLoading: false,
        });
      } else {
        throw new Error();
      }
    }).catch((err) => {
      this.setState(() => {
        if (
          err
          && err.response
          && err.response.status === 400
          && err.response.data
          && err.response.data[0].server_message
        ) {
          return {
            myReferralsLoading: false,
            myReferralsErrorMsg: err.response.data[0].server_message,
          };
        }
        return {
          myReferralsLoading: false,
          myReferralsError: true,
        };
      });
    });

    this.retryMyReferral = () => {
      this.setState({
        myReferralsLoading: true,
        myReferralsError: false,
        myReferralsErrorMsg: '',
      }, () => {
        this.handleMyReferral();
      });
    };
  }

  handleMyRecentOrders = () => {
    const { orderStartDate, orderEndDate } = this.state;
    myOrders(
      'GET',
      {},
      {
        offset: 0,
        limit: 3,
        startDate: orderStartDate,
        endDate: orderEndDate,
      },
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          myRecentOrders: res.data,
          myRecentOrdersLoading: false,
        });
      } else {
        throw new Error();
      }
    }).catch((err) => {
      this.setState(() => {
        if (
          err
          && err.response
          && err.response.status === 400
          && err.response.data
          && err.response.data[0].server_message
        ) {
          return {
            myRecentOrdersLoading: false,
            myRecentOrdersErrorMsg: err.response.data[0].server_message,
          };
        }
        return {
          myRecentOrdersLoading: false,
          myRecentOrdersError: true,
        };
      });
    });

    this.retryMyRecentOrders = () => {
      this.setState({
        myRecentOrdersLoading: true,
        myRecentOrdersError: false,
        myRecentOrdersErrorMsg: '',
      }, () => {
        this.handleMyRecentOrders();
      });
    };
  }

  handleDateProcessing = (data = {}) => {
    this.setState({
      ordersError: false,
      referralsError: false,
      ordersLoading: true,
      referralsLoading: true,
      orders: null,
      referrals: null,
      ordersOffset: 0,
      referralOffset: 0,
    }, () => {
      this.handleLeaderShipOrders(data);
      this.handleLeaderShipReferrals(data);
    });
  }

  handleDateChange = (startDate, endDate) => {
    const { param } = this.state;
    if (startDate !== param.startDate
      || endDate !== param.endDate) {
      this.handleDateProcessing({
        startDate,
        endDate,
        p: 1,
      });
    }
  }

  handleMyOrderDateProcessing = () => {
    this.setState({
      myRecentOrdersError: false,
      myRecentOrdersLoading: true,
    }, () => {
      this.handleMyRecentOrders();
    });
  }

  handleMyOrderDateChange = (data) => {
    this.setState({
      orderStartDate: data.orderStartDate,
      orderEndDate: data.orderEndDate,
    }, this.handleMyOrderDateProcessing);
  }

  handleRequestProcessing = () => {
    this.handleMyReferral();
    this.handleLeaderShipReferrals();
  }

  onCancelMyOrders = () => {
    const { myRecentOrdersLoading } = this.state;
    if (myRecentOrdersLoading) {
      this.source.cancel();
    }
    this.setState({
      myRecentOrdersLoading: false,
      myRecentOrdersError: false,
    });
  }

  handleOrdersLazyLoad = (event) => {
    const {
      scrollHeight,
      scrollTop,
      offsetHeight,
    } = event.target;
    const {
      orders,
      ordersOffset,
      ordersLoading,
    } = this.state;
    if ((scrollHeight - scrollTop) - offsetHeight < 50
    && (ordersOffset + limit < orders.count) && !ordersLoading) {
      this.setState({
        ordersOffset: ordersOffset + limit,
        ordersLoading: true,
      }, () => { this.handleLeaderShipOrders(); });
    }
  }

  handleReferralLazyLoad = (event) => {
    const {
      scrollHeight,
      scrollTop,
      offsetHeight,
    } = event.target;
    const {
      referrals,
      referralOffset,
      referralsLoading,
    } = this.state;
    if ((scrollHeight - scrollTop) - offsetHeight < 50
    && (referralOffset + limit < referrals.count) && !referralsLoading) {
      this.setState({
        referralOffset: referralOffset + limit,
        referralsLoading: true,
      }, () => {
        this.handleLeaderShipReferrals();
      });
    }
  }

  render() {
    const {
      selectedTab, orders, ordersLoading, ordersError, param,
      referrals, referralsLoading, referralsError, orderStartDate, orderEndDate,
      myReferrals, myReferralsLoading, myReferralsError, myReferralsErrorMsg,
      myRecentOrders, myRecentOrdersLoading, myRecentOrdersError, myRecentOrdersErrorMsg,
      referShopModal, viewReferralModal, viewOrdersModal,
    } = this.state;

    return (
      <Container>
        {
          referShopModal && (
            <ReferAShop
              show={referShopModal}
              onHide={() => {
                this.setState({
                  referShopModal: false,
                });
              }}
              handleRequestProcessing={this.handleRequestProcessing}
            />
          )
        }
        {
          viewOrdersModal && (
            <ViewMyRecentOrders
              show={viewOrdersModal}
              onHide={() => {
                this.setState({
                  viewOrdersModal: false,
                });
              }}
              startDate={orderStartDate}
              endDate={orderEndDate}
            />
          )
        }
        {
          viewReferralModal && (
            <ViewMyReferrals
              show={viewReferralModal}
              onHide={() => {
                this.setState({
                  viewReferralModal: false,
                });
              }}
            />
          )
        }
        <Row
          className="my-3"
        >
          <Col
            xs={22}
            lg={14}
            className="bg-white leadership-board mt-4 mx-3"
          >
            <Row
              className="pt-4 px-2"
            >
              <Col className="fs-2">
                <b>Leaderboard</b>
              </Col>
              <Col
                className="d-flex justify-content-end my-n2"
              >
                <DatePicker
                  isDateRange
                  onApply={(dateRange) => {
                    this.handleDateChange(
                      new Date(dateRange.startDate).setHours(0, 0, 0),
                      new Date(dateRange.endDate).setHours(23, 59, 59),
                    );
                  }}
                  startDate={param.startDate}
                  endDate={param.endDate}
                  onClear={() => {
                    this.handleDateProcessing({
                      startDate: '', endDate: '',
                    });
                  }}
                />
              </Col>
            </Row>
            <Row
              className="px-4 py-2"
            >
              <Col
                xs="auto"
              >
                <Row
                  className="bg-light order-button p-1 d-flex"
                >
                  <Col
                    className={`${selectedTab === 'orders' ? 'bg-white order-button' : ''}`}
                  >
                    <Button
                      variant="link"
                      onClick={() => {
                        this.setState({
                          selectedTab: 'orders',
                        });
                      }}
                      className={`${selectedTab === 'orders' ? 'text-mid-gray' : 'text-secondary'} px-1`}
                    >
                      Orders
                    </Button>
                  </Col>
                  <Col
                    className={`${selectedTab === 'referrals' ? 'bg-white order-button' : ''}`}
                  >
                    <Button
                      variant="link"
                      onClick={() => {
                        this.setState({
                          selectedTab: 'referrals',
                        });
                      }}
                      className={`${selectedTab === 'referrals' ? 'text-mid-gray' : 'text-secondary'} px-1`}
                    >
                      Referrals
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row
              className={selectedTab === 'orders' ? 'pl-2 py-4' : 'd-none'}
            >
              <Col>
                {(() => {
                  let showcase = null;
                  if (!orders && ordersLoading) {
                    showcase = (
                      <div
                        className="h-100 d-flex align-items-center
                        justify-content-center"
                      >
                        <Spinner
                          animation="border"
                          variant="primary"
                        />
                      </div>
                    );
                  } else if (!orders && !ordersLoading && ordersError) {
                    showcase = (
                      <div
                        className="h-100 d-flex align-items-center
                        justify-content-center"
                      >
                        <ErrorHandler
                          retryLogic={() => this.retryOrders()}
                        />
                      </div>
                    );
                  } else if (orders) {
                    showcase = (
                      <Row
                        onScroll={this.handleOrdersLazyLoad}
                        className="m-0 leader-container"
                      >
                        <Col>
                          {orders.results.map((item, index) => (
                            <Row
                              className="py-2"
                              key={index}
                            >
                              <Col
                                xs="auto"
                                className="d-flex align-items-center justify-content-center pr-0"
                              >
                                <WinningIcon
                                  width={30}
                                  height={30}
                                  fill={index < 5 ? this.winnerColors[index] : '#a3a3a3'}
                                  stroke={index < 5 ? this.winnerColors[index] : ''}
                                />
                                <div
                                  className="font-weight-bold number-count text-white"
                                >
                                  {index + 1}
                                </div>
                              </Col>
                              <Col
                                xs="auto"
                                className="d-flex align-items-center"
                              >
                                <div
                                  className="emp-image border p-0  bg-primary text-white
                                    d-flex align-items-center justify-content-center"
                                >
                                  <span className="font-weight-bold">
                                    {getInitials(item.employeeName)}
                                  </span>
                                </div>
                              </Col>
                              <Col
                                className="font-weight-bold"
                              >
                                <Row>
                                  <Col
                                    xs="auto"
                                    className="px-0 d-flex align-items-center"
                                  >
                                    {item.employeeName}
                                  </Col>
                                  <Col
                                    xs="auto"
                                    className="px-2 text-secondary fs-01
                                      d-flex align-items-center"
                                  >
                                    <ListIcon
                                      width={16}
                                      height={17}
                                    />
                                    <span className="px-1">{item.totalOrders}</span>
                                    Orders
                                  </Col>
                                  <Col
                                    xs="auto"
                                    className="pl-0 text-secondary fs-01
                                      d-flex align-items-center"
                                  >
                                    <ShopIcon
                                      width={16}
                                      height={17}
                                    />
                                    <span className="px-1">{item.totalShops}</span>
                                    Shops
                                  </Col>
                                </Row>
                                <Row>
                                  {
                                    item.deliveredOrders > 0 && (
                                      <Col
                                        xs="auto"
                                        className="pl-0 pr-2 pt-2 text-secondary fs-01
                                          d-flex align-items-center"
                                      >
                                        <TickIcon
                                          width={16}
                                          height={16}
                                        />
                                        <span className="px-1">{item.deliveredOrders}</span>
                                        Delivered
                                      </Col>
                                    )
                                  }
                                  {
                                    item.retailerCancelOrders > 0 && (
                                      <Col
                                        xs="auto"
                                        className="pl-0 pr-2 pt-2 text-secondary fs-01
                                          d-flex align-items-center"
                                      >
                                        <CancelIcon
                                          width={16}
                                          height={16}
                                        />
                                        <span className="px-1">{item.retailerCancelOrders}</span>
                                        Retailer Cancelled
                                      </Col>
                                    )
                                  }
                                  {
                                    item.customerCancelOrders > 0 && (
                                      <Col
                                        xs="auto"
                                        className="pl-0 pr-2 pt-2 text-secondary fs-01
                                          d-flex align-items-center"
                                      >
                                        <CancelIcon
                                          width={16}
                                          height={16}
                                        />
                                        <span className="px-1">{item.customerCancelOrders}</span>
                                        User Cancelled
                                      </Col>
                                    )
                                  }
                                </Row>
                              </Col>
                              <Col
                                xs="auto"
                                className="d-flex align-items-center justify-content-end pr-0"
                              >
                                {
                                  index < 5
                                    ? (
                                      <div className="bg-pink-light py-2 px-3 order-button">
                                        <span
                                          className="text-success font-weight-bold fs-0"
                                        >
                                          &#8377;&nbsp;
                                          {item.totalOrderValue}
                                        </span>
                                      </div>
                                    ) : (
                                      <div
                                        className="font-weight-bold text-mid-gray"
                                      >
                                        &#8377;&nbsp;
                                        {item.totalOrderValue}
                                      </div>
                                    )
                                }
                              </Col>
                            </Row>
                          ))}
                          {orders.count === 0 && (
                            <div
                              className="d-flex align-items-center justify-content-center font-weight-bold"
                            >
                              You have not placed any order
                            </div>
                          )}
                        </Col>
                      </Row>
                    );
                  }
                  return showcase;
                })()}
              </Col>
            </Row>

            <Row
              className={selectedTab === 'referrals' ? 'px-2 py-4' : 'd-none'}
            >
              <Col>
                {(() => {
                  let showcase = null;
                  if (!referrals && referralsLoading) {
                    showcase = (
                      <div
                        className="h-100 d-flex align-items-center
                        justify-content-center"
                      >
                        <Spinner
                          animation="border"
                          variant="primary"
                        />
                      </div>
                    );
                  } else if (!referrals && !referralsLoading && referralsError) {
                    showcase = (
                      <div
                        className="h-100 d-flex align-items-center
                        justify-content-center"
                      >
                        <ErrorHandler
                          retryLogic={() => this.retryReferrals()}
                        />
                      </div>
                    );
                  } else if (referrals) {
                    showcase = (
                      <Row
                        onScroll={this.handleReferralLazyLoad}
                        className="m-0 leader-container"
                      >
                        <Col>
                          {referrals.results.map((item, index) => (
                            <Row
                              className="py-2"
                              key={index}
                            >
                              <Col
                                xs="auto"
                                className="d-flex align-items-center justify-content-center pr-0"
                              >
                                <WinningIcon
                                  width={30}
                                  height={30}
                                  fill={index < 5 ? this.winnerColors[index] : '#a3a3a3'}
                                  stroke={index < 5 ? this.winnerColors[index] : ''}
                                />
                                <div
                                  className="font-weight-bold number-count text-white"
                                >
                                  {index + 1}
                                </div>
                              </Col>
                              <Col
                                xs="auto"
                                className="d-flex align-items-center"
                              >
                                <div
                                  className="emp-image border p-0  bg-primary text-white
                                    d-flex align-items-center justify-content-center"
                                >
                                  <span className="font-weight-bold">
                                    {getInitials(item.employeeName)}
                                  </span>
                                </div>
                              </Col>
                              <Col
                                className="font-weight-bold"
                              >
                                <Row>
                                  <Col
                                    className="px-0"
                                  >
                                    {item.employeeName}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    xs="auto"
                                    className="pl-0 pr-2 pt-2 text-secondary fs-01
                                      d-flex align-items-center"
                                  >
                                    <TimingIcon />
                                    &nbsp;
                                    {item.pendingStores}
                                    &nbsp;
                                    Pending
                                  </Col>
                                  &nbsp;
                                  <Col
                                    xs="auto"
                                    className="pt-2 text-secondary fs-01 px-0
                                      d-flex align-items-center"
                                  >
                                    <CrossIcon />
                                    &nbsp;
                                    {item.rejectedStores}
                                    &nbsp;
                                    Rejected
                                  </Col>
                                </Row>
                              </Col>
                              <Col
                                xs="auto"
                                className="d-flex align-items-center justify-content-end"
                              >
                                <div className="font-weight-bold text-mid-gray">
                                  {item.onboardedStores}
                                  /
                                  {item.totalReferral}
                                  &nbsp;
                                  onboarded
                                </div>
                              </Col>
                            </Row>
                          ))}
                          {referrals.count === 0 && (
                            <div
                              className="d-flex align-items-center justify-content-center font-weight-bold"
                            >
                              You have not referred any shops
                            </div>
                          )}
                        </Col>
                      </Row>
                    );
                  }
                  return showcase;
                })()}
              </Col>
            </Row>
          </Col>

          <Col
            className="my-4"
          >
            <Row>
              <Col
                xs={22}
                className="bg-white leadership-board mx-3 mb-2"
              >
                <Row
                  className="py-3 px-2"
                >
                  <Col className="fs-2">
                    <b>My Referrals</b>
                  </Col>
                  <Col
                    className="d-flex justify-content-end"
                  >
                    <Button
                      variant="success"
                      className="fs-0"
                      onClick={() => {
                        this.setState({
                          referShopModal: true,
                        });
                      }}
                    >
                      <b>Refer a Shop</b>
                    </Button>
                  </Col>
                </Row>
                {/* Main Body */}
                <Row
                  className="py-3 px-2"
                >
                  <Col>
                    {(() => {
                      let showcase = null;
                      if (!myReferrals && myReferralsLoading) {
                        showcase = (
                          <div
                            className="h-100 d-flex align-items-center
                            justify-content-center"
                          >
                            <Spinner
                              animation="border"
                              variant="primary"
                            />
                          </div>
                        );
                      } else if (!myReferrals && !myReferralsLoading && myReferralsErrorMsg) {
                        showcase = (
                          <div
                            className="h-100 d-flex align-items-center
                            justify-content-center"
                          >
                            {myReferralsErrorMsg}
                          </div>
                        );
                      } else if (
                        !myReferrals && !myReferralsLoading
                        && !myReferralsErrorMsg && myReferralsError
                      ) {
                        showcase = (
                          <div
                            className="h-100 d-flex align-items-center
                            justify-content-center"
                          >
                            <ErrorHandler
                              retryLogic={() => this.retryMyReferral()}
                            />
                          </div>
                        );
                      } else if (myReferrals) {
                        showcase = (
                          <>
                            {myReferrals.results.map((item, index) => (
                              <Row
                                className="py-2"
                                key={index}
                              >
                                <Col>
                                  <Row>
                                    <Col
                                      className="font-weight-bold"
                                    >
                                      {item.shopName}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      className="pt-1 text-muted fs-0"
                                    >
                                      {getDateString(item.createdOn)}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col
                                  xs="auto"
                                  className="d-flex align-items-center justify-content-end"
                                >
                                  <div
                                    className={`${item.bgColor} ${item.textColor} py-1 px-2 order-button fs-0`}
                                  >
                                    {item.status}
                                  </div>
                                </Col>
                              </Row>
                            ))}
                            {myReferrals.count === 0 && (
                              <div
                                className="d-flex align-items-center justify-content-center font-weight-bold"
                              >
                                You have not referred any shops
                              </div>
                            )}
                            {
                              myReferrals.count > 3 && (
                                <div
                                  className="pt-3"
                                >
                                  <div className="border-bottom" />
                                  <div
                                    className="d-flex align-items-center justify-content-center pt-2"
                                  >
                                    <Button
                                      variant="link"
                                      className="fs-0 text-success font-weight-bold"
                                      onClick={() => {
                                        this.setState({
                                          viewReferralModal: true,
                                        });
                                      }}
                                    >
                                      View More
                                    </Button>
                                  </div>
                                </div>
                              )
                            }
                          </>
                        );
                      }
                      return showcase;
                    })()}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col
                xs={22}
                className="bg-white leadership-board my-4 mx-3"
              >
                <Row
                  className="py-3 px-2"
                >
                  <Col className="fs-2" xs="auto">
                    <b>My Recent Orders</b>
                  </Col>
                  <Col
                    className="d-flex justify-content-end"
                  >
                    <DatePicker
                      isDateRange
                      onApply={(dateRange) => {
                        this.handleMyOrderDateChange({
                          orderStartDate: new Date(dateRange.startDate).setHours(0, 0, 0),
                          orderEndDate: new Date(dateRange.endDate).setHours(23, 59, 59),
                        });
                      }}
                      startDate={orderStartDate}
                      endDate={orderEndDate}
                      onClear={() => {
                        this.setState(() => ({
                          orderStartDate: '',
                          orderEndDate: '',
                        }), () => {
                          this.handleMyOrderDateProcessing();
                        });
                      }}
                    />
                  </Col>
                </Row>
                {/* Main Body */}
                <Row
                  className="py-3 px-2"
                >
                  <Col>
                    {(() => {
                      let showcase = null;
                      if (!myRecentOrders && myRecentOrdersLoading) {
                        showcase = (
                          <div
                            className="h-100 d-flex align-items-center
                            justify-content-center"
                          >
                            <Spinner
                              animation="border"
                              variant="primary"
                            />
                          </div>
                        );
                      } else if (
                        !myRecentOrders && !myRecentOrdersLoading && myRecentOrdersErrorMsg
                      ) {
                        showcase = (
                          <div
                            className="h-100 d-flex align-items-center
                            justify-content-center text-danger"
                          >
                            {myRecentOrdersErrorMsg}
                          </div>
                        );
                      } else if (
                        !myRecentOrders && !myRecentOrdersLoading
                        && !myRecentOrdersErrorMsg && myRecentOrdersError
                      ) {
                        showcase = (
                          <div
                            className="h-100 d-flex align-items-center
                            justify-content-center"
                          >
                            <ErrorHandler
                              retryLogic={() => this.retryMyRecentOrders()}
                            />
                          </div>
                        );
                      } else if (myRecentOrders) {
                        showcase = (
                          <>
                            <ProcessingStatus
                              show={myRecentOrdersLoading || myRecentOrdersError}
                              loading={myRecentOrdersLoading}
                              error={myRecentOrdersError}
                              onRetry={this.retryMyRecentOrders}
                              onCancel={this.onCancelMyOrders}
                            />
                            {myRecentOrders.results.map((item, index) => (
                              <Row
                                className="py-2"
                                key={index}
                              >
                                <Col
                                  xs="auto"
                                  className="pr-0 d-flex align-items-center"
                                >
                                  <div
                                    className="emp-image border p-0 bg-primary text-white
                                      d-flex align-items-center justify-content-center"
                                  >
                                    <span className="font-weight-bold">
                                      {getInitials(item.storeName)}
                                    </span>
                                  </div>
                                </Col>
                                <Col>
                                  <Row>
                                    <Col
                                      className="font-weight-bold"
                                    >
                                      {item.storeName}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      className="pt-1 text-muted fs-0"
                                    >
                                      {getDateString(item.createdOn)}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col
                                  className="d-flex justify-content-center flex-column"
                                >
                                  <Row>
                                    <Col
                                      className="d-flex justify-content-end
                                      font-weight-bold text-mid-gray"
                                    >
                                      &#8377; &nbsp;
                                      {item.billTotal}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      className="pt-1 d-flex justify-content-end
                                      font-weight-bold text-mid-gray"
                                    >
                                      <div
                                        className={`${item.bgColor} ${item.textColor} py-1 px-2 order-button fs-0`}
                                      >
                                        {item.status}
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            ))}
                            {myRecentOrders.count === 0 && (
                              <div
                                className="d-flex align-items-center justify-content-center font-weight-bold"
                              >
                                You have not placed any order
                              </div>
                            )}
                            {
                              myRecentOrders.count > 3 && (
                                <div
                                  className="pt-3"
                                >
                                  <div className="border-bottom" />
                                  <div
                                    className="d-flex align-items-center justify-content-center pt-2"
                                  >
                                    <Button
                                      variant="link"
                                      className="fs-0 text-success font-weight-bold"
                                      onClick={() => {
                                        this.setState({
                                          viewOrdersModal: true,
                                        });
                                      }}
                                    >
                                      View More
                                    </Button>
                                  </div>
                                </div>
                              )
                            }
                          </>
                        );
                      }
                      return showcase;
                    })()}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

LeadershipBoard.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default LeadershipBoard;
