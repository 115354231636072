import React, { Component } from 'react';
import {
  Container, Row, Col,
  Button,
  Spinner,
  FormCheck,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Axios from 'axios';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import CustomerLocation from '../../layouts/CustomerLocation';
import ProductList from '../../layouts/place-order/ProductList';
import Stores from '../../layouts/place-order/Stores';
import Checkout from '../../layouts/checkout/Checkout';
import CustomModal from '../../component/common/CustomModal';
import {
  customer, customerAddress, cart, storeWithMatrics,
} from '../../assets/api/axios';
import { validateRegex } from '../../utilities/FormValidations';
import { primaryTagRange } from '../../utilities/Utils';
import { ProcessingStatus } from '../../component/derived/table-list';

const addressPayload = {
  addressText: '',
  location: null,
  isCompleted: false,
  isDefault: true,
  flatNumber: '',
  buildingName: '',
  streetName: '',
  localityName: '',
  landmark: '',
  addressType: 'Home',
};

const addressTypeLabels = [
  'Home',
  'Work',
  'Other',
];

const { CancelToken } = Axios;
let axiosController = null;

class PlaceOrder extends Component {
  constructor() {
    super();
    this.state = {
      selectedAddress: null,
      cartId: null,
      loader: true,
      searchText: '',
      activeTimeout: -1,
      customers: [],
      selectedCustomer: null,
      addressList: [],
      cartDetails: null,
      isShopping: true,
      storeList: null,
      selectedStore: null,
      isSearchingCustomer: false,
      addDetailsModal: '',
      phoneNumber: '',
      custName: '',
      address: '',
      addressText: '',
      addressTypeLabel: 'Home',
      location: null,
      error: null,
      locationSubmitStatus: '',
      newCustomerStatus: '',
      errorMsg: '',
      isNewShop: false,
      selectedPrimaryTag: null,
      storesOffset: 0,
      loadingStores: false,
      confirmedDeliveryAddress: false,
      storeSearch: '',
      searchLoading: false,
      searchError: false,
    };
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleCustomerSearch = this.handleCustomerSearch.bind(this);
    this.handleSelectCustomer = this.handleSelectCustomer.bind(this);
    this.handleUpdateCartDetails = this.handleUpdateCartDetails.bind(this);
    this.handleStoreSelection = this.handleStoreSelection.bind(this);
    this.getStoreList = this.getStoreList.bind(this);
    this.modifyCurrentState = this.modifyCurrentState.bind(this);
    this.createCart = this.createCart.bind(this);
    this.confirmDeliveryAddress = this.confirmDeliveryAddress.bind(this);
  }

  getStoreList(cartId, selectedAddress) {
    const {
      selectedCustomer, selectedPrimaryTag, isNewShop, storesOffset, storeList,
    } = this.state;
    const { lat, lng } = selectedAddress.location;
    storeWithMatrics(
      'get',
      {},
      selectedCustomer.id,
      {
        offset: `${storesOffset}`,
        limit: 10,
        location: `${lat},${lng}`,
        isNew: isNewShop,
        primaryTagRange: selectedPrimaryTag ? selectedPrimaryTag.range.join(',') : null,
      },
    ).then((res) => {
      if (res.status === 200) {
        const { data } = res;
        this.setState({
          cartId,
          selectedAddress,
          storeList: storesOffset > 0 && storeList ? {
            ...data,
            results: [...storeList.results, ...data.results],
          } : data,
          loader: false,
          loadingStores: false,
        });
      }
    }).catch(() => {
      this.setState({ loader: false });
    });
  }

  getNextStores = () => {
    const {
      storesOffset, cartId, selectedAddress,
    } = this.state;
    this.setState({
      loadingStores: true,
      storesOffset: storesOffset + 10,
    }, () => this.getStoreList(cartId, selectedAddress));
  }

  handleKeyPress = (event) => {
    const { customers } = this.state;
    if (event.key === 'Enter' || event.keyCode === 13) {
      if (customers.length > 0) {
        this.setState({
          selectedCustomer: customers[0],
          searchText: '',
          customers: [],
        }, () => {
          const { selectedCustomer } = this.state;
          customerAddress('GET', null, null, selectedCustomer.id)
            .then((res) => {
              if (res.status === 200) {
                this.setState({
                  addressList: res.data.results,
                  loader: false,
                });
              }
            });
        });
      }
    }
  }

  handleAddNewCustomer = (addType) => {
    const { searchText } = this.state;
    if (addType === 'customer') {
      if (Number(searchText)) {
        if (searchText.length > 10) {
          this.setState({
            phoneNumber: searchText.toString().substring(0, 10),
          });
        } else {
          this.setState({
            phoneNumber: searchText,
          });
        }
      }
    }
    this.setState({
      addDetailsModal: addType,
    });
  }

  handleChangeInput = (event) => {
    const { name, value } = event.target;
    if (name === 'phoneNumber') {
      if (!validateRegex('integerValue', value)) {
        return;
      }
    }
    this.setState({
      [name]: value,
    });
  }

  handleAddressTypeLabel = (label) => {
    this.setState({ addressTypeLabel: label });
  }

  handleChange = (address) => {
    this.setState({ address });
  }

  createCustomer = () => {
    const {
      phoneNumber, custName,
    } = this.state;
    this.setState({
      newCustomerStatus: 'loading',
      errorMsg: '',
    });
    customer(
      'post',
      {
        phoneNumber,
        name: custName,
      },
      null,
      null,
    ).then(() => {
      this.setState({
        searchText: phoneNumber,
        addDetailsModal: '',
        newCustomerStatus: '',
        phoneNumber: '',
        custName: '',
        errorMsg: '',
      }, () => {
        this.handleCustomerSearch();
      });
    }).catch((error) => {
      if (
        error
        && error.response
        && error.response.status === 401
        && error.response.data
        && error.response.data[0]
      ) {
        this.setState({
          newCustomerStatus: 'error',
          errorMsg: error.response.data[0].client_message,
        });
      } else {
        this.setState({
          newCustomerStatus: 'error',
          errorMsg: 'Oops, Something Broke!!',
        });
      }
    });
  }

  addNewAddress = () => {
    const {
      selectedCustomer, addressTypeLabel, addressText,
      location, addressList,
    } = this.state;
    let addressType = addressTypeLabel;
    if (addressTypeLabel === 'Other') {
      addressType = document.getElementById('input-address-type').value;
    }
    this.setState({
      locationSubmitStatus: 'loading',
    });
    customerAddress(
      'POST',
      {
        ...addressPayload,
        addressText,
        location,
        addressType,
      },
      null,
      selectedCustomer.id,
    ).then((res) => {
      this.setState({
        locationSubmitStatus: '',
        addDetailsModal: '',
        address: '',
        addressText: '',
        addressTypeLabel: 'Home',
        location: null,
        error: null,
        addressList: [
          ...addressList,
          res.data,
        ],
      });
    }).catch(() => {
      this.setState({
        locationSubmitStatus: 'error',
      });
    });
  }

  getAndUpdateCartDetails = (retry = 3) => {
    const {
      selectedCustomer, selectedAddress, cartId,
    } = this.state;
    cart(
      'GET',
      null,
      cartId,
      selectedCustomer.id,
      null,
      {
        location: `${selectedAddress.location.lat},${selectedAddress.location.lng}`,
      },
    ).then((res) => {
      const { data } = res;
      this.setState({
        cartDetails: data,
      });
    }).catch(() => {
      if (retry) {
        this.getAndUpdateCartDetails(retry - 1);
      }
    });
  }

  handleSelect = (address) => {
    this.setState({ address });
    geocodeByAddress(address)
      .then((results) => {
        this.setState({
          addressText: results[0].formatted_address,
        });
        return getLatLng(results[0]);
      })
      .then((location) => {
        this.setState({ location });
      })
      .catch(() => {
        this.setState({
          error: 'Oops Something Broke !!',
        });
      });
  }

  // eslint-disable-next-line react/sort-comp
  handleSearch(event) {
    const { value } = event.target;
    const { activeTimeout } = this.state;
    if (activeTimeout !== -1) {
      clearTimeout(activeTimeout);
    }
    if (axiosController) {
      axiosController.cancel();
    }
    if (value) {
      axiosController = CancelToken.source();
      this.setState({
        searchText: value,
        customers: [],
      }, () => {
        const timeOutId = setTimeout(() => {
          this.handleCustomerSearch();
          this.setState({ activeTimeout: -1 });
        }, 300);
        this.setState({ activeTimeout: timeOutId });
      });
      return;
    }
    this.setState({
      searchText: value,
      activeTimeout: -1,
      customers: [],
    });
  }

  handleCustomerSearch() {
    const { searchText } = this.state;
    this.setState({
      isSearchingCustomer: true,
    });
    customer('GET', null, searchText, axiosController.token)
      .then((res) => {
        this.setState({
          customers: res.data.results,
          selectedAddress: null,
          selectedCustomer: null,
          addressList: [],
          loader: true,
          isSearchingCustomer: false,
        });
      });
  }

  handleSelectCustomer(customerInfo) {
    this.setState({
      selectedCustomer: customerInfo,
      searchText: '',
      customers: [],
      confirmedDeliveryAddress: false,
    }, () => {
      const { selectedCustomer } = this.state;
      customerAddress('GET', null, null, selectedCustomer.id)
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              addressList: res.data.results,
              storesOffset: 0,
              storeList: null,
              loader: false,
            });
          }
        });
    });
  }

  handleStoreSearch = () => {
    this.setState({
      searchLoading: true,
      searchError: false,
    });
    const {
      selectedCustomer, selectedPrimaryTag, isNewShop,
      cartId, selectedAddress, storeSearch,
    } = this.state;
    const { lat, lng } = selectedAddress.location;
    storeWithMatrics(
      'GET',
      {},
      selectedCustomer.id,
      {
        offset: 0,
        location: `${lat},${lng}`,
        searchText: storeSearch,
        isNew: isNewShop,
        primaryTagRange: selectedPrimaryTag ? selectedPrimaryTag.range.join(',') : null,
      },
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          searchLoading: false,
          cartId,
          selectedAddress,
          storeList: res.data,
        });
      } else {
        throw new Error();
      }
    }).catch(() => {
      this.setState({
        searchLoading: false,
        searchError: true,
      });
    });
  }

  onCancel = () => {
    const { searchLoading } = this.state;
    if (searchLoading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source;
    }
    this.setState({
      searchLoading: false,
      searchError: false,
    });
  }

  createCart(selectedAddress) {
    const { selectedCustomer } = this.state;
    cart(
      'post',
      {
        customer: selectedCustomer.id,
      },
      null,
      selectedCustomer.id,
      null,
    ).then((res) => {
      if (res.status === 200) {
        const { data } = res;
        this.getStoreList(data.cartId, selectedAddress);
      }
    }).catch(() => {
      this.setState({
        loader: false,
        cartId: null,
        selectedAddress: null,
      });
    });
  }

  handleChangeAddress(selectedAddress, isUpdate = false) {
    if (selectedAddress && !isUpdate) {
      this.setState({
        loader: true,
        cartDetails: null,
        storeList: null,
        isNewShop: false,
        selectedPrimaryTag: null,
        selectedStore: null,
        storesOffset: 0,
      }, () => {
        this.createCart(selectedAddress);
      });
      return;
    }
    if (selectedAddress && isUpdate) {
      this.confirmDeliveryAddress();
      this.setState({ selectedAddress });
      return;
    }
    this.setState({
      selectedAddress: null,
      cartDetails: null,
      cartId: null,
      storeList: null,
      selectedStore: null,
      selectedPrimaryTag: null,
      storesOffset: 0,
    });
  }

  confirmDeliveryAddress() {
    this.setState({
      confirmedDeliveryAddress: true,
    });
  }

  handleUpdateCartDetails(cartDetails) {
    this.setState({
      cartDetails: { ...cartDetails },
    }, this.getAndUpdateCartDetails);
  }

  handleStoreSelection(data) {
    this.setState({
      selectedStore: data,
    });
  }

  modifyCurrentState(isShopping, reset = false) {
    if (reset) {
      this.setState({
        selectedAddress: null,
        cartId: null,
        loader: true,
        searchText: '',
        activeTimeout: -1,
        customers: [],
        selectedCustomer: null,
        addressList: [],
        cartDetails: null,
        isShopping: true,
        storeList: null,
        storesOffset: 0,
        selectedPrimaryTag: null,
        selectedStore: null,
        isSearchingCustomer: false,
        addDetailsModal: '',
        phoneNumber: '',
        custName: '',
        address: '',
        addressText: '',
        addressTypeLabel: 'Home',
        location: null,
        error: null,
        locationSubmitStatus: '',
        newCustomerStatus: '',
        errorMsg: '',
      });
      return;
    }
    this.setState({ isShopping });
  }

  render() {
    const {
      addressList,
      selectedAddress,
      searchText,
      customers,
      selectedCustomer,
      loader,
      cartId,
      cartDetails,
      isShopping,
      storeList,
      selectedStore,
      isSearchingCustomer,
      addDetailsModal,
      phoneNumber,
      custName,
      address,
      addressText,
      addressTypeLabel,
      location,
      error,
      locationSubmitStatus,
      newCustomerStatus,
      errorMsg,
      selectedPrimaryTag,
      isNewShop,
      loadingStores,
      confirmedDeliveryAddress,
      storeSearch,
      searchLoading,
      searchError,
    } = this.state;

    const { userPermission } = this.props;
    let addDetailsBody = null;
    switch (addDetailsModal) {
      case 'customer':
        addDetailsBody = (
          <Container
            className="p-3 bg-white"
          >
            <Row>
              <Col
                xs={24}
                className="pb-3"
              >
                <input
                  type="text"
                  className="form-control"
                  name="phoneNumber"
                  maxLength={10}
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={this.handleChangeInput}
                />
              </Col>
              <Col
                xs={24}
                className="pb-3"
              >
                <input
                  type="text"
                  className="form-control"
                  name="custName"
                  placeholder="Customer Name"
                  value={custName}
                  onChange={this.handleChangeInput}
                />
              </Col>
              {
                !!errorMsg && (
                  <Col
                    xs={24}
                    className="pb-3 text-danger"
                  >
                    {errorMsg}
                  </Col>
                )
              }
              <Col
                xs={24}
                className="pb-3 text-center"
              >
                {
                  newCustomerStatus === 'loading' ? (
                    <Spinner
                      variant="primary"
                      animation="border"
                    />
                  ) : (
                    <Button
                      block
                      variant="primary"
                      className="py-1"
                      disabled={
                        !phoneNumber
                        || !custName
                      }
                      onClick={() => {
                        this.createCustomer();
                      }}
                    >
                      Submit
                    </Button>
                  )
                }
              </Col>
            </Row>
          </Container>
        );
        break;
      case 'address':
        addDetailsBody = (
          <Container
            className="p-3 bg-white"
          >
            <Row>
              <Col
                xs={24}
                className="location-search"
              >
                <PlacesAutocomplete
                  value={address}
                  onChange={this.handleChange}
                  onSelect={this.handleSelect}
                  debounce={500}
                  shouldFetchSuggestions={address.length > 3}
                >
                  {({
                    getInputProps, suggestions, getSuggestionItemProps, loading,
                  }) => (
                    <div
                      className="position-relative"
                    >
                      <div
                        className={`form-control form-control-lg d-flex ${error
                          ? 'border-danger' : ''} align-items-center`}
                      >
                        <input
                          {...getInputProps({
                            ref: this.searchPlacesRef,
                            placeholder: 'Search for Area,Street,Landmark',
                            className: 'pl-2 location-search-input w-100 fs-1 border-0 shadow-none',
                          })}
                        />
                        <Button
                          variant="link"
                          className={address.length === 0 ? 'd-none' : 'text-secondary px-1 fs-1'}
                          onClick={() => {
                            this.setState({
                              address: '',
                              addressText: '',
                              location: null,
                              addressTypeLabel: 'Home',
                            });
                          }}
                        >
                          &times;
                        </Button>
                      </div>
                      {
                        error ? (
                          <div
                            className="fs-1 text-danger pt-1"
                          >
                            {error}
                          </div>
                        ) : ''
                      }
                      <div
                        className="autocomplete-dropdown-container position-absolute border"
                      >
                        {
                          loading && (
                            <div className="loading">Loading...</div>
                          )
                        }
                        {
                          suggestions.map((suggestion) => (
                            <div
                              {
                                ...getSuggestionItemProps(
                                  suggestion,
                                  {
                                    className: `suggestion-item ${suggestion.active ? ' active' : ''}`,
                                  },
                                )
                              }
                            >
                              <span>
                                {suggestion.description}
                              </span>
                            </div>
                          ))
                        }
                      </div>
                      <div
                        className={
                          !location || addressText === ''
                            ? 'd-none' : 'my-3'
                        }
                      >
                        <b>Your Selected Address</b>
                        <div
                          className="mb-3"
                        >
                          {addressText}
                        </div>
                        <div
                          className="d-flex flex-row mb-3"
                        >
                          {
                            addressTypeLabels.map((item, index) => (
                              addressTypeLabel === 'Other' && item === 'Other'
                                ? (
                                  <input
                                    key={item}
                                    type="text"
                                    id="input-address-type"
                                    placeholder="Enter label"
                                    className="ml-3 p-0 border-0 text-primary shadow-none"
                                    autoComplete="off"
                                  />
                                ) : (
                                  <FormCheck
                                    key={item}
                                    custom
                                    type="radio"
                                    id={item}
                                    label={item}
                                    checked={addressTypeLabel === item}
                                    className={index ? 'ml-3' : ''}
                                    onChange={() => this.handleAddressTypeLabel(item)}
                                  />
                                )
                            ))
                          }
                        </div>
                        {
                          locationSubmitStatus === 'error' && (
                            <div
                              className="text-danger fs-1 mb-2"
                            >
                              Oops Something Broke!!
                            </div>
                          )
                        }
                        <div
                          className="text-center"
                        >
                          {
                            locationSubmitStatus === 'loading' ? (
                              <Spinner
                                animation="border"
                                variant="primary"
                              />
                            ) : (
                              <Button
                                id="add-address-submit"
                                block
                                className="fs-1 py-1 w-100"
                                onClick={() => {
                                  this.addNewAddress();
                                }}
                              >
                                SAVE
                              </Button>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Col>
            </Row>
          </Container>
        );
        break;
      default:
        break;
    }

    return (
      <Container fluid className="my-2">
        {
          !!addDetailsModal && (
            <CustomModal
              show
              title={addDetailsModal === 'customer' ? 'Add New Customer' : 'Add Address'}
              closeButton
              autoSize
              onHide={() => {
                this.handleAddNewCustomer('');
                this.setState({
                  phoneNumber: '',
                  custName: '',
                  address: '',
                  addressText: '',
                  addressTypeLabel: 'Home',
                  location: null,
                  error: null,
                  locationSubmitStatus: '',
                  newCustomerStatus: '',
                  errorMsg: '',
                });
              }}
              body={addDetailsBody}
            />
          )
        }
        {
          isShopping ? (
            <>
              <Row>
                <Col xs={24}>
                  <CustomerLocation
                    handleChangeAddress={this.handleChangeAddress}
                    handleSearch={this.handleSearch}
                    handleSelectCustomer={this.handleSelectCustomer}
                    onKeyPress={this.handleKeyPress}
                    selectedAddress={selectedAddress}
                    searchText={searchText}
                    customers={customers}
                    selectedCustomer={selectedCustomer}
                    addressList={addressList}
                    loader={loader}
                    isSearchingCustomer={isSearchingCustomer}
                    handleAddNewCustomer={(addType) => {
                      this.handleAddNewCustomer(addType);
                    }}
                    userPermission={userPermission}
                  />
                </Col>
              </Row>
              <Row>
                {(!loader && storeList) || selectedPrimaryTag || isNewShop
                  || searchLoading || searchError ? (
                    <>
                      <Col lg={15} xs={24} className="pt-4 pb-2">
                        <Col
                          xs="auto"
                        >
                          Choose Shop Tag:
                        </Col>
                        <Col>
                          <Row>
                            {
                              primaryTagRange.map((item) => (
                                <Col xs="auto" className="d-flex align-items-center pr-4">
                                  <input
                                    type="checkbox"
                                    checked={
                                      selectedPrimaryTag
                                        ? selectedPrimaryTag.color === item.color : false
                                    }
                                    id="primaryTagRange"
                                    name="primaryTagRange"
                                    onChange={(event) => {
                                      this.setState({
                                        loader: true,
                                        isNewShop: false,
                                        storesOffset: 0,
                                        selectedPrimaryTag: event.target.checked ? item : null,
                                      }, () => { this.getStoreList(cartId, selectedAddress); });
                                    }}
                                  />
                                  <div className={`colored-circle ${item.color} ml-2`} />
                                </Col>
                              ))
                            }
                            <Col xs="auto" className="d-flex align-items-center pr-4">
                              <input
                                type="checkbox"
                                checked={isNewShop}
                                id="primaryTagRange"
                                name="primaryTagRange"
                                onChange={(event) => {
                                  this.setState({
                                    loader: true,
                                    storesOffset: 0,
                                    isNewShop: event.target.checked ? true : null,
                                    selectedPrimaryTag: null,
                                  }, () => { this.getStoreList(cartId, selectedAddress); });
                                }}
                              />
                              <div className="colored-circle bg-white ml-2" />
                            </Col>
                          </Row>
                        </Col>
                      </Col>
                      <Col lg={6} xs={24} className="pt-4 pb-2">
                        <Row>
                          <Col
                            xs={24}
                            className="pt-3"
                          >
                            <input
                              className="p-4 h-50 w-100 shadow-sm form-control text-primary"
                              value={storeSearch}
                              type="search"
                              onChange={(e) => {
                                this.setState({
                                  storeSearch: e.target.value,
                                });
                              }}
                              placeholder="Search Shop by Shop Name/EP Code"
                              autoComplete="off"
                              onKeyPress={(e) => {
                                if (e.which === 13) {
                                  this.handleStoreSearch();
                                }
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <ProcessingStatus
                        show={searchLoading || searchError}
                        loading={searchLoading}
                        error={searchError}
                        onRetry={() => this.handleStoreSearch()}
                        onCancel={this.onCancel}
                      />
                      { (!loader && storeList) ? (
                        <Col xs={24} className="d-flex align-items-center justify-content-between flex-wrap">
                          <Stores
                            selectedStore={selectedStore}
                            storeList={storeList}
                            handleStoreSelection={this.handleStoreSelection}
                            getNextStores={this.getNextStores}
                            loadingStores={loadingStores}
                          />
                        </Col>
                      ) : ''}
                    </>
                  ) : ''}
              </Row>
              {
                selectedAddress && (
                  <Row>
                    <Col xs={24} className="productcart-showcase">
                      <ProductList
                        lat={selectedAddress.location.lat}
                        lng={selectedAddress.location.lng}
                        customerId={selectedCustomer.id}
                        cartId={cartId}
                        cartDetails={cartDetails}
                        selectedStore={selectedStore}
                        updateCartDetails={this.handleUpdateCartDetails}
                        modifyCurrentState={this.modifyCurrentState}
                        getAndUpdateCartDetails={this.getAndUpdateCartDetails}
                      />
                    </Col>
                  </Row>
                )
              }
            </>
          ) : (
            <Row>
              <Col xs={24} className="p-0">
                {
                  selectedCustomer && selectedAddress && (
                    <Checkout
                      confirmedDeliveryAddress={confirmedDeliveryAddress}
                      cartDetails={cartDetails}
                      selectedAddress={selectedAddress}
                      selectedCustomer={selectedCustomer}
                      handleChangeAddress={this.handleChangeAddress}
                      handleUpdateCartDetails={this.handleUpdateCartDetails}
                      modifyCurrentState={this.modifyCurrentState}
                    />
                  )
                }
              </Col>
            </Row>
          )
        }
      </Container>
    );
  }
}

PlaceOrder.propTypes = {
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default PlaceOrder;
