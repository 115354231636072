import React from 'react';
import PropTypes from 'prop-types';

const TickIcon = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      fill="#29A62E"
      fillRule="evenodd"
      d="M1.333 8c0-3.667 3-6.667 6.667-6.667s6.667 3 6.667 6.667-3 6.667-6.667 6.667-6.667-3-6.667-6.667Zm9.727-2.947L6.667 9.447 4.94 7.727l-.94.94 2.667 2.666L12 6l-.94-.947Z"
      clipRule="evenodd"
    />
  </svg>
);

TickIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

TickIcon.defaultProps = {
  width: 16,
  height: 16,
};

export default TickIcon;
