import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import {
  Button, Container, Spinner, Row, Col,
  InputGroup, Form,
} from 'react-bootstrap';
import { productExports } from '../../assets/api/axios';
import {
  CustomDropdown, CustomTable, CustomModal, DatePicker,
} from '../../component/common';
import Svg from '../../component/common/Svg';
import Constant from '../../utilities/Constant';
import Permission from '../../access&permissions/permission';
import { dateString } from '../../utilities/Utils';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, startDate, endDate,
  } = param;
  const { sid = '', status = 'PENDING' } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  startDate = Number(startDate) || new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
  startDate = new Date(startDate).setHours(0, 0, 0, 0);
  endDate = Number(endDate) || new Date().setHours(23, 59, 59, 999);
  return ({
    l,
    p,
    startDate,
    endDate,
    sid,
    status,
  });
};
class RetailerCatalogueFiles extends Component {
  constructor(props) {
    super(props);
    const { history } = this.props;
    const params = getQueryParams(history.location.search);
    const { userPermission } = props;
    this.canIEdit = userPermission
      && userPermission.includes(Permission.LIBRARY_PRODUCTS_WRITE);
    this.state = {
      records: null,
      storeId: params.sid,
      rowsPerPage: params.l,
      page: params.p,
      params,
      stDate: params.startDate,
      eDate: params.endDate,
      loading: true,
      error: false,
      selectedRecord: null,
      submittingStatus: false,
      submittingStatusError: false,
      comment: '',
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount() {
    this.handleLoad();
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { params, stDate, eDate } = this.state;
    const newParams = {
      ...params,
      ...data,
      startDate: stDate,
      endDate: eDate,
    };
    const offset = (newParams.p - 1) * newParams.l;
    const { l, status, sid } = newParams;
    productExports(
      'get',
      {
        offset: `${offset}`,
        limit: l,
        status,
        storeId: sid,
        startDate: stDate,
        endDate: eDate,
      },
    ).then((res) => {
      this.setState({
        records: res.data,
        status: newParams.status,
        rowsPerPage: newParams.l,
        page: newParams.p,
        params: newParams,
        loading: false,
      }, () => {
        if (Object.keys(newParams).find((key) => newParams[key] !== params[key])) {
          Object.keys(newParams).forEach((key) => {
            if (!newParams[key]) {
              delete newParams[key];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParams),
          });
        }
      });
    }).catch(() => {
      this.setState({
        error: true,
      });
    });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad(newParams);
      });
    };
  }

  handleRequestProcessing = (data) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      loading: true,
      error: false,
    }, () => {
      this.handleLoad(data);
    });
  }

  onNext = () => {
    const { params } = this.state;
    this.handleRequestProcessing({ p: params.p + 1 });
  }

  onPrev = () => {
    const { params } = this.state;
    this.handleRequestProcessing({ p: params.p - 1 });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onSubmitPage = () => {
    const { params, page } = this.state;
    if (params.p !== page) {
      this.handleRequestProcessing({ p: page });
    }
  }

  onSubmitRowsPerPage = () => {
    const { params, rowsPerPage } = this.state;
    if (params.l !== rowsPerPage) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleDateChange = (startDate, endDate) => {
    this.setState({
      stDate: startDate,
      eDate: endDate,
    }, () => {
      this.handleRequestProcessing();
    });
  }

  onSubmitStoreId = () => {
    const { storeId, params } = this.state;
    if (storeId !== params.sid) {
      this.handleRequestProcessing({ sid: storeId });
    }
  }

  handlePatchStatus = () => {
    const { selectedRecord, comment } = this.state;
    productExports(
      'PATCH',
      {},
      {
        id: selectedRecord.id,
        status: selectedRecord.status,
        ...selectedRecord.status === 'REJECTED' ? { comment } : {},
      },
    ).then(() => {
      this.setState({
        selectedRecord: null,
        submittingStatus: false,
        loading: true,
        comment: '',
      }, () => {
        this.handleLoad();
      });
    }).catch(() => {
      this.setState({
        submittingStatus: false,
        submittingStatusError: true,
      });
    });
  }

  render() {
    const {
      records, rowsPerPage, page, params,
      loading, error, status, storeId, selectedRecord,
      submittingStatus, submittingStatusError,
      stDate, eDate, comment,
    } = this.state;
    const statusContent = {
      key: 'status',
      displayText: 'Status',
      options: [
        {
          label: 'PENDING',
          value: 'PENDING',
        },
        {
          label: 'PICKED',
          value: 'PICKED',
        },
        {
          label: 'DONE',
          value: 'DONE',
        },
        {
          label: 'REJECTED',
          value: 'REJECTED',
        },
      ],
    };
    const headers = [
      {
        key: 'id',
        displayText: 'Id',
      },
      {
        key: 'storeId',
        displayText: 'Store Id',
      },
      {
        key: 'link',
        displayText: 'File Link',
        renderer: (data) => (
          <div style={{ maxWidth: '700px' }}>
            {data.link ? (
              <a
                href={data.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.link}
              </a>
            ) : 'NA'}
          </div>
        ),
      },
      {
        key: 'comment',
        displayText: 'Comment',
      },
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => (
          <>{dateString(data.createdOn)}</>
        ),
      },
      {
        key: 'modifiedOn',
        displayText: 'Modified On',
        renderer: (data) => (
          <>{dateString(data.modifiedOn)}</>
        ),
      },
      ...this.canIEdit ? [{
        key: 'status',
        displayText: 'Status',
        renderer: (data) => (
          <select
            value={data.status}
            className="form-control fs-01 minw-120p"
            onChange={(event) => {
              if (event.target.value !== data.status) {
                this.setState({
                  selectedRecord: {
                    ...data,
                    status: event.target.value,
                  },
                });
              }
            }}
          >
            <option value="PENDING">PENDING</option>
            <option value="PICKED">PICKED</option>
            <option value="DONE">DONE</option>
            <option value="REJECTED">REJECTED</option>
          </select>
        ),
      }] : [],
    ];
    const confirmationBody = (
      <Container
        fluid
      >
        {selectedRecord && (
        <Row>
          <Col
            xs={24}
            className="pt-3"
          >
            Are you sure you want to change starus to&nbsp;
            <b>
              {selectedRecord.status}
            </b>
          </Col>
          {
            selectedRecord.status === 'REJECTED' && (
              <Col
                xs={24}
                className="pt-3"
              >
                <textarea
                  type="textarea"
                  name="comment"
                  rows={3}
                  maxLength={416}
                  value={comment}
                  onChange={this.handleOnChange}
                  className="w-75"
                />
              </Col>
            )
          }
          <Col
            xs={24}
            className="text-center py-3 d-flex flex-row-reverse"
          >
            <Button
              variant={selectedRecord.status === 'REJECTED' ? 'danger' : 'success'}
              className="py-1 px-3 mx-2"
              onClick={() => {
                this.setState({
                  submittingStatus: true,
                  submittingStatusError: false,
                }, () => {
                  this.handlePatchStatus();
                });
              }}
              disabled={submittingStatus || (selectedRecord.status === 'REJECTED' && !comment)}
            >
              Submit
            </Button>
            <Button
              variant="outline-primary"
              className="py-1 px-3 mx-2"
              onClick={() => {
                this.setState({
                  selectedRecord: null,
                  submittingStatusError: false,
                });
              }}
              disabled={submittingStatus}
            >
              Not Now
            </Button>
          </Col>
        </Row>
        )}
        {
          selectedRecord && submittingStatus && (
            <div
              className="py-3 text-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          )
        }
        {
          selectedRecord && submittingStatusError && (
            <div
              className="py-3 text-center"
            >
              <span
                className="text-danger"
              >
                Something Went Wrong. Please try again.
              </span>
            </div>
          )
        }
      </Container>
    );
    if (loading) {
      return (
        <div
          className="pt-3 text-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }
    if (error) {
      return (
        <div
          className="pt-3 text-center"
        >
          <span
            className="text-danger"
          >
            Something Went Wrong
          </span>
          <div>
            <Button
              variant="primary"
              onClick={() => this.retry()}
            >
              Retry
            </Button>
          </div>
        </div>
      );
    }
    return (
      <Container
        fluid
        className="bg-white"
      >
        <CustomModal
          show={!!selectedRecord}
          title="Updating Status"
          body={confirmationBody}
          closeButton
          autoSize
          onHide={() => {
            this.setState({
              selectedRecord: null,
              submittingStatus: false,
              submittingStatusError: false,
            });
          }}
        />
        <Row
          className="p-2"
        >
          <Col
            xs="auto"
            className="px-0"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Store Id"
                name="storeId"
                className="fs-01 rounded-0"
                value={storeId}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.onSubmitStoreId();
                  }
                }}
              />
            </InputGroup>
          </Col>
          <Col
            xs="auto"
            className="pl-2 pr-0"
          >
            <CustomDropdown
              item={statusContent}
              closeButton
              onChange={this.handleRequestProcessing}
              selectedVal={status}
            />
          </Col>
          <Col
            xs="auto"
            className="pl-2 pr-0"
          >
            <DatePicker
              isDateRange
              onApply={(dateRange) => {
                this.handleDateChange(
                  new Date(dateRange.startDate).getTime(),
                  new Date(dateRange.endDate).getTime(),
                );
              }}
              startDate={stDate}
              endDate={eDate}
              onClear={() => {
                this.handleDateChange(
                  '',
                  '',
                );
              }}
            />
          </Col>
        </Row>
        <Row>
          {
            records && (
              <CustomTable
                keyField="id"
                headers={headers}
                content={records.results}
                isPaginated
                totalItems={records.count}
                hasPrev={records.hasPrev}
                hasNext={records.hasNext}
                l={params.l}
                p={params.p}
                rowsPerPage={rowsPerPage}
                page={page}
                onNext={this.onNext}
                onPrev={this.onPrev}
                updateRowsPageInput={this.handleRowsPageInput}
                onSubmitPage={this.onSubmitPage}
                onSubmitRowsPerPage={this.onSubmitRowsPerPage}
              />
            )
          }
        </Row>
      </Container>
    );
  }
}
RetailerCatalogueFiles.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};
export default RetailerCatalogueFiles;
