import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal, ErrorHandler } from '../../component/common';
import { getUserName, getUserNumber } from '../../utilities/Storage';
import { exotelCallDetails, exotelCallInit } from '../../assets/api/axios';

const callCommentOptions = [
  {
    label: 'Switched Off',
    value: 'Switched_Off',
  },
  {
    label: 'Ringing Not Answered',
    value: 'Ringing_Not_Answered',
  },
  {
    label: 'Not Reachable',
    value: 'Not_Reachable',
  },
  {
    label: 'Details To be Updated by Retailer',
    value: 'Details_To_be_Updated_by_Retailer',
  },
  {
    label: 'Details Verified',
    value: 'Details_Verified',
  },
  {
    label: 'Details Not Verified',
    value: 'Details_Not_Verified',
  },
  {
    label: 'Call Back Requested',
    value: 'Call_Back_Requested',
  },
];

class BankDetailsVerificationCall extends Component {
  constructor() {
    super();
    this.state = {
      callLogId: '',
      callPurpose: '',
      callComment: '',
      processingCall: false,
      callError: false,
      processingPatch: false,
      patchError: false,
    };
  }

  handleChangeExotelDetails = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  initiateExotelCall = () => {
    const { phoneNumber, action } = this.props;
    const exotelCallMeta = {
      action,
      callFromUser: {
        adminUser: getUserName(),
        phoneNumber: getUserNumber(),
      },
      callToUser: {
        phoneNumber,
      },
    };
    exotelCallInit(
      'POST',
      exotelCallMeta,
    ).then((res) => {
      this.setState({
        callLogId: res.data.id,
        processingCall: false,
        callError: false,
      });
    }).catch(() => {
      this.setState({
        processingCall: false,
        callError: true,
      });
    });
  }

  patchCallDetails = () => {
    const { callPurpose, callComment, callLogId } = this.state;
    const { onHide } = this.props;
    exotelCallDetails(
      'PATCH',
      {
        callComments: callComment,
        callPurpose,
      },
      callLogId,
    ).then(() => {
      onHide();
    }).catch(() => {
      this.setState({
        processingPatch: false,
        patchError: true,
      });
    });
  }

  reset = () => {
    this.setState({
      callLogId: '',
      callPurpose: '',
      callComment: '',
      processingCall: false,
      callError: false,
      processingPatch: false,
      patchError: false,
    });
  }

  render() {
    const { show, onHide } = this.props;
    const {
      processingCall, callError, callLogId,
      callPurpose, callComment, processingPatch, patchError,
    } = this.state;

    let body = <div />;

    if (processingCall) {
      body = (
        <div
          className="text-center p-4"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!processingCall && callError) {
      body = (
        <ErrorHandler
          retryLogic={() => {
            this.setState({
              processingCall: true,
              callError: false,
            }, () => {
              this.initiateCall();
            });
          }}
        />
      );
    }

    if (!processingCall && !callError && !!callLogId) {
      body = (
        <Container>
          <Row>
            <Col
              xs={24}
              className="p-2 bg-success text-center"
            >
              <b>Your call has been initiated successfully</b>
            </Col>

            <Col xs={24} className="mt-4 p-2 fs-2 text-center">
              Please fill following details and submit
            </Col>
          </Row>
          <Row className="pt-4">
            <Col xs={24}>
              <b>1. Call Purpose</b>
              <span className="text-danger">*</span>
            </Col>
            <Col xs={24} className="pt-2">
              <select
                name="callPurpose"
                value={callPurpose}
                className="form-control"
                onChange={(e) => {
                  if (e.target.value !== '') {
                    this.handleChangeExotelDetails(e);
                  }
                }}
              >
                <option value="">Select</option>
                <option value="Bank_Details_Verification">Bank Details Verification</option>
              </select>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col xs={24}>
              <b>2. Call Comments</b>
              <span className="text-danger">*</span>
            </Col>
            <Col xs={24} className="pt-2">
              <select
                name="callComment"
                value={callComment}
                className="form-control"
                onChange={(e) => {
                  if (e.target.value !== '') {
                    this.handleChangeExotelDetails(e);
                  }
                }}
              >
                <option value="">Select</option>
                {callCommentOptions.map((comment) => (
                  <option value={comment.value}>{comment.label}</option>
                ))}
              </select>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col xs={24} className="text-center">
              {
                processingPatch
                  ? (
                    <Spinner
                      variant="primary"
                      animation="border"
                    />
                  ) : (
                    <>
                      <Button
                        variant="primary"
                        block
                        onClick={() => {
                          this.setState({
                            processingPatch: true,
                            patchError: false,
                          }, () => {
                            this.patchCallDetails();
                          });
                        }}
                        disabled={!callPurpose || !callComment}
                      >
                        Submit
                      </Button>
                      <div className="py-2 text-danger">
                        {!processingPatch && patchError
                          && (
                            <b>Oops Something went wrong!!</b>
                          )}
                      </div>
                    </>
                  )
              }
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <CustomModal
        show={show}
        body={body}
        onHide={onHide}
        onEnter={() => {
          this.setState({
            processingCall: true,
          }, () => {
            this.initiateExotelCall();
          });
        }}
        autoSize
        backdrop={processingCall
          || callError || 'static'}
        reset={this.reset}
      />
    );
  }
}

BankDetailsVerificationCall.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  phoneNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default BankDetailsVerificationCall;
