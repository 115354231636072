import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import ThumbnailSvg from '../../../assets/icon/thumbnail.svg';

export default function ValueOffer(props) {
  const { valueOfferApplied = {}, isValueOffer } = props;
  return (
    <Row className="mx-0 pt-4">
      <Col xs={5} className="d-flex">
        <img src={valueOfferApplied.image || ThumbnailSvg} alt="Offer Img" height={100} width={100} />
      </Col>
      <Col xs={19} className="fs-0 text-medium">
        <Row className="py-2">
          <span className="font-weight-bold">Offer Name :</span>
          {valueOfferApplied.name || 'NA'}
        </Row>
        <Row className="pb-2">
          <span className="font-weight-bold">Offer Title :</span>
          {valueOfferApplied.offerTitle || 'NA'}
        </Row>
        <Row className="pb-2">
          <span className="font-weight-bold">Spend Amount :</span>
          {valueOfferApplied.spendAmount || 'NA'}
        </Row>
        {
          isValueOffer && (
            <Row className="pb-2">
              <span className="font-weight-bold">Discount Amount :</span>
              {valueOfferApplied.discountAmount || 'NA'}
            </Row>
          )
        }
      </Col>
    </Row>
  );
}

ValueOffer.propTypes = {
  valueOfferApplied: PropTypes.bool.isRequired,
  isValueOffer: PropTypes.bool,
};

ValueOffer.defaultProps = {
  isValueOffer: false,
};
