import React from 'react';
import PropTypes from 'prop-types';

const ListIcon = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      stroke="#999"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10 3.167h2a.667.667 0 0 1 .667.666v10A.667.667 0 0 1 12 14.5H4a.666.666 0 0 1-.667-.667v-10A.667.667 0 0 1 4 3.167h2m0 2h4M6 8.5h4m-4 2.667h4M6.667 2.5v2.667h2.666V2.5H6.667Z"
    />
  </svg>
);

ListIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

ListIcon.defaultProps = {
  width: 16,
  height: 17,
};

export default ListIcon;
