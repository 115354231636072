import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';
import Select from 'react-select';
import moment from 'moment';
import {
  CustomDropdown,
  CustomModal, CustomTable, ErrorHandler,
} from '../../component/common';
import { getCategoryCityList, specialNotes } from '../../assets/api/axios';

function CreateSpecialNotes(props) {
  const {
    show, onHide, specialNoteId, editSpecialNotes, handleRequestProcessing,
  } = props;

  const [city, setCity] = useState('');
  const [storeType, setStoreType] = useState('');
  const [storeId, setStoreId] = useState('');
  const [storeIdType, setStoreIdType] = useState('single');
  const [storeList, setStoreList] = useState([]);
  const [hexIds, setHexIds] = useState([]);
  const [option, setOption] = useState('input');
  const [createdAt, setCreatedAt] = useState(new Date());
  const [message, setMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [cities, setCities] = useState([]);
  const [storeTypes, setStoreTypes] = useState([]);
  const [loadStatus, setLoadStatus] = useState('error');
  const [active, setActive] = useState('');
  const [specialNoteTarget, setSpecialNoteTarget] = useState('store');

  const activeOptions = {
    key: 'active',
    displayText: '',
    options: [
      {
        label: 'True',
        value: 'true',
      },
      {
        label: 'False',
        value: 'false',
      },
    ],
  };

  const onClose = () => {
    setCity(null);
    setStoreType(null);
    setStoreId('');
    setOption('input');
    setCreatedAt(new Date());
    setMessage('');
    setSubmitting(false);
    setSubmitError('');
    setLoadStatus('');
    onHide();
    handleRequestProcessing();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {};
    if (editSpecialNotes) {
      data = {
        specialNoteId,
        active,
      };
    }
    if (option === 'input' && storeList?.length > 0) {
      data = {
        ...data,
        storeIds: storeList.map((id) => parseInt(id, 10)),
      };
    }
    setSubmitting(true);
    setSubmitError('');
    specialNotes(
      editSpecialNotes ? 'PATCH' : 'POST',
      {
        ...data,
        message,
        cities: city.split(',').map((c) => c.trim()),
        storeTypes: storeType.split(',').map((type) => type.trim()),
        hexIds,
      },
      {},
    ).then((res) => {
      if (res.status === 200) {
        onClose();
      } else {
        throw new Error();
      }
    }).catch((err) => {
      let errorMsg = 'Oops something went wrong. Retry!';
      if (err?.response?.data?.errors[0]?.message) {
        errorMsg = err.response.data.errors[0].message;
      }
      setSubmitting(false);
      setSubmitError(errorMsg);
    });
  };

  const handleRadioBtn = (e, type) => {
    setOption(e.target.id);
    setStoreId();
    setStoreIdType(type);
  };

  const loadCityLocality = () => {
    getCategoryCityList(
      'GET',
    ).then((res) => {
      setCities(res.data.data.cities);
      setStoreTypes(res.data.data.storeTypes);
    }).catch(() => {});
  };

  const handleLoad = () => {
    setLoadStatus('loading');
    specialNotes(
      'GET',
      null,
      {
        specialNoteId,
      },
    ).then((res) => {
      const data = res?.data?.data[0];
      setCity(data?.cities?.join(', ') || '');
      setStoreType(data?.storeTypes?.join(', ') || '');
      setCreatedAt(data?.created_at);
      setMessage(data?.message);
      setStoreList(data?.storeIds);
      setHexIds(data?.hexIds);
      setActive(data?.active);
      setLoadStatus('');
    }).catch(() => {
      setLoadStatus('error');
    });
  };

  useEffect(() => {
    loadCityLocality();
    if (editSpecialNotes) {
      handleLoad();
    }
    // eslint-disable-next-line
  }, []);

  console.log('loggggg', storeType);
  const body = (
    <Container className="px-4 py-4">
      <Row>
        {(() => {
          let showcase = null;
          if (editSpecialNotes && (loadStatus === 'loading')) {
            showcase = (
              <Col>
                <div
                  className="h-100 d-flex align-items-center
                  justify-content-center"
                >
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                </div>
              </Col>
            );
          } else if (editSpecialNotes && (loadStatus === 'error')) {
            showcase = (
              <Col>
                <div
                  className="h-100 d-flex align-items-center
                  justify-content-center"
                >
                  <ErrorHandler
                    retryLogic={() => {
                      handleLoad();
                    }}
                  />
                </div>
              </Col>
            );
          } else {
            showcase = (
              <Col>
                <Form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  {
                    cities !== null && (
                      <>
                        <Form.Group as={Row}>
                          <Form.Label column sm={6} className="fs-1">
                            <b>Cities</b>
                          </Form.Label>
                          <Col sm={9}>
                            <Select
                              id="city"
                              isMulti
                              onChange={(selectedCities) => {
                                const selectedValues = selectedCities
                                  ? selectedCities.map((type) => type.value).join(', ')
                                  : '';
                                setCity(selectedValues);
                              }}
                              value={
                              city?.length > 0
                                ? city?.split(', ').map((type) => ({
                                  label: type,
                                  value: type,
                                }))
                                : []
                            }
                              options={Object.values(cities).map((item) => ({
                                label: item,
                                value: item,
                              }))}
                              className="fs-01"
                            />
                          </Col>
                        </Form.Group>
                      </>
                    )
                  }
                  {
                  storeTypes !== null && (
                    <>
                      <Form.Group as={Row}>
                        <Form.Label column sm={6} className="fs-1">
                          <b>StoreTypes</b>
                        </Form.Label>
                        <Col sm={9}>
                          <Select
                            id="storeType"
                            isMulti // Enables multiple selection
                            onChange={(selectedStoreTypes) => {
                              // Convert selected options to a comma-separated string
                              const selectedValues = selectedStoreTypes
                                ? selectedStoreTypes.map((type) => type.value).join(', ')
                                : '';
                              setStoreType(selectedValues);
                            }}
                            value={
                              storeType?.length > 0
                                ? storeType?.split(', ').map((type) => ({
                                  label: type,
                                  value: type,
                                }))
                                : []
                            }
                            options={Object.values(storeTypes).map((item) => ({
                              label: item,
                              value: item,
                            }))}
                            className="fs-01"
                          />
                        </Col>
                      </Form.Group>
                    </>
                  )
                  }
                  {
                    editSpecialNotes && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Active</b>
                        </Form.Label>
                        <Col sm={9}>
                          <CustomDropdown
                            item={activeOptions}
                            name="active"
                            onChange={(item) => setActive(item.active)}
                            selectedVal={active.toString()}
                            closeButton={false}
                          />
                        </Col>
                      </Form.Group>
                    )
                  }
                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Message</b>
                    </Form.Label>
                    <Col sm={9}>
                      <textarea
                        type="text"
                        placeholder="Enter Message"
                        value={message}
                        id="message"
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                        autoComplete="off"
                        className="form-control fs-01"
                      />
                    </Col>
                  </Form.Group>
                  {
                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Hex Ids</b>
                      </Form.Label>
                      <Col sm={9}>
                        <input
                          type="text"
                          placeholder="Enter Hex Ids"
                          value={hexIds.map((item) => item)}
                          id="hexIds"
                          onChange={(e) => (setHexIds(e.target.value.split(',').map((id) => id.trim())))}
                          autoComplete="off"
                          className="form-control fs-01"
                        />
                      </Col>
                    </Form.Group>
                  }
                  {
                    specialNoteTarget === 'store' && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6} className="fs-1">
                          <b>Store</b>
                        </Form.Label>
                        <Col sm={`${option === 'input' ? 9 : 12}`}>
                          <Row>
                            <Col className="d-flex align-items-center">
                              <input
                                type="radio"
                                id="input"
                                checked={storeIdType === 'single'}
                                onChange={(e) => handleRadioBtn(e, 'single')}
                              />
                              <span className="fs-01 pl-1">Single</span>
                            </Col>
                            <Col className="d-flex align-items-center">
                              <input
                                type="radio"
                                id="input"
                                checked={storeIdType === 'multiple'}
                                onChange={(e) => handleRadioBtn(e, 'multiple')}
                              />
                              <span className="fs-01 pl-1">Multiple</span>
                            </Col>
                          </Row>
                          <Row className="py-1">
                            <Col>
                              {
                                option === 'input' && (
                                  <div>
                                    <input
                                      type={storeIdType === 'single' ? 'number' : 'text'}
                                      placeholder={`Enter Store ${storeIdType === 'single' ? 'Id' : 'Ids'}`}
                                      value={storeIdType === 'single' ? storeId : storeList}
                                      id={storeIdType === 'single' ? 'storeId' : 'storeList'}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        if (storeIdType === 'single') {
                                          setStoreId(value);
                                          setStoreList([value]);
                                        } else {
                                          setStoreList(value.split(',').map((id) => id.trim()));
                                        }
                                      }}
                                      autoComplete="off"
                                      className="form-control fs-01"
                                    />
                                  </div>
                                )
                              }
                            </Col>
                          </Row>
                        </Col>
                      </Form.Group>
                    )
                  }
                  {
                    editSpecialNotes && storeList?.length > 0 && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6} />
                        <Col sm={15} className="overflow-y-scroll maxh-220p">
                          <CustomTable
                            headers={[
                              {
                                key: 'storeId',
                                displayText: 'StoreId',
                                renderer: (data) => (
                                  <>
                                    {data}
                                  </>
                                ),
                              },
                            ]}
                            content={storeList}
                            keyField="storeId"
                            totalItems={storeList.length}
                            isPaginated={false}
                          />
                        </Col>
                      </Form.Group>
                    )
                  }
                  {
                    submitting && (
                      <div
                        className="d-flex align-item-center justify-content-center"
                      >
                        <Col sm={6} />
                        <Spinner
                          animation="border"
                          variant="primary"
                        />
                      </div>
                    )
                  }
                  {
                    submitError && (
                      <div
                        className="d-flex align-item-center justify-content-center fs-0 py-2"
                      >
                        <Col sm={6} />
                        <span className="text-danger">
                          {submitError}
                        </span>
                      </div>
                    )
                  }
                  <Form.Group as={Row}>
                    <Form.Label column sm={6} />
                    <Col>
                      <Button
                        block
                        variant="primary"
                        type="submit"
                        // disabled={disabled}
                        className="fs-01"
                      >
                        {editSpecialNotes ? 'SAVE CHANGES' : 'CREATE'}
                      </Button>
                    </Col>
                  </Form.Group>

                </Form>
              </Col>
            );
          }
          return showcase;
        })()}
      </Row>
    </Container>
  );

  return (
    <CustomModal
      show={show}
      title={`${editSpecialNotes ? 'Edit' : 'Create'} Special Note`}
      onHide={() => onHide()}
      size="lg"
      body={body}
      closeButton
    />
  );
}

CreateSpecialNotes.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  specialNoteId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  editSpecialNotes: PropTypes.bool.isRequired,
  handleRequestProcessing: PropTypes.func.isRequired,
};

export default CreateSpecialNotes;
