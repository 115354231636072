/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Spinner, Card, Button, Form,
} from 'react-bootstrap';
import Select from 'react-select';
import moment from 'moment';
import Axios from 'axios';
import QueryString from 'query-string';
import {
  CustomDropdown, CustomModal, CustomPagination,
  DatePicker, ErrorHandler,
} from '../../component/common';
import { banners, uploadImage } from '../../assets/api/axios';
import { dateTimeOptions, languages } from '../../utilities/Utils';
import { ProcessingStatus } from '../../component/derived/table-list';
import Permission from '../../access&permissions/permission';

const customerTypes = [
  {
    value: 'GUESTS',
    label: 'GUESTS',
  },
  {
    value: '0_ORDER',
    label: '0_ORDER',
  },
  {
    value: 'RETURNING',
    label: 'RETURNING',
  },
];

function getOneMonthLaterDate() {
  const now = new Date();
  return new Date(now.setMonth(now.getMonth() + 1));
}

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, startDate, endDate,
  } = param;
  const {
    banner_type = '',
    status = '',
    language = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  startDate = Number(startDate) || '';
  endDate = Number(endDate) || '';
  return ({
    l,
    p,
    startDate,
    endDate,
    banner_type,
    status,
    language,
  });
};

class Banners extends React.Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    const { userPermission } = props;
    this.canIEdit = userPermission && userPermission.includes(Permission.BANNERS_WRITE);
    this.state = {
      bannersData: null,
      loading: true,
      error: false,
      addBanner: false,
      alteringBanner: false,
      alteringBannerError: false,
      title: '',
      bannerType: '',
      selectedCustomerTypes: [{ value: 'GUESTS', label: 'GUESTS' }],
      selectedLanguage: null,
      bannerImage: '',
      backgroundImage: '',
      deepLink: '',
      webDeepLink: '',
      priorityOrder: 1,
      storeIds: '',
      launchDate: new Date(),
      expiryDate: getOneMonthLaterDate(),
      bannerUidToDelete: null,
      bannerUidToEdit: null,
      invalidDateErrorMsg: '',
      rowsPerPage: param.l,
      page: param.p,
      param,
      stDate: param.startDate,
      eDate: param.endDate,
      newParam: param,
      uploadingImage: '',
      uploadingBackground: '',
      errorMsg: '',
    };
    this.source = Axios.CancelToken.source();
    this.hiddenFileInput1 = React.createRef();
    this.hiddenFileInput2 = React.createRef();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleLoad = (data = {}) => {
    const {
      param, stDate, eDate,
    } = this.state;
    const { history } = this.props;
    const { pathname } = history.location;
    const newParam = { ...param, ...data };
    const {
      l, p, banner_type, status, language,
    } = newParam;
    const offset = (p - 1) * l;
    banners(
      'GET',
      null,
      {
        banner_type,
        offset,
        limit: l,
        startDate: stDate,
        endDate: eDate,
        status,
        language,
      },
    ).then((res) => {
      this.setState({
        bannersData: res.data,
        loading: false,
        error: false,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad();
      });
    };
  }

  addBanner = (e) => {
    if (e) {
      e.preventDefault();
    }
    const {
      addBanner, bannerUidToEdit, title, bannerType,
      bannerImage, deepLink, webDeepLink, priorityOrder, backgroundImage,
      storeIds, launchDate, expiryDate, selectedCustomerTypes, selectedLanguage,
    } = this.state;

    if (launchDate > expiryDate) {
      this.setState({
        invalidDateErrorMsg: 'Please Check!! Launch Date is Greater than Expiry Date',
      });
      return;
    }

    this.setState({
      alteringBanner: true,
      invalidDateErrorMsg: '',
    });

    banners(
      `${addBanner ? 'POST' : 'PATCH'}`,
      {
        title,
        bannerType,
        customerBannerTypes: selectedCustomerTypes.map((item) => item.value),
        language: selectedLanguage.value,
        img: bannerImage,
        backgroundImage,
        deepLink,
        webDeepLink,
        priorityOrder,
        storeCodes: storeIds,
        isPublic: true,
        launchDate: launchDate.getTime(),
        expiryDate: expiryDate.getTime(),
      },
      {},
      `${addBanner ? '' : bannerUidToEdit}`,
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          addBanner: false,
          bannerUidToEdit: null,
          alteringBanner: false,
          alteringBannerError: false,
          title: '',
          bannerType: '',
          selectedCustomerTypes: [{ value: 'GUESTS', label: 'GUESTS' }],
          selectedLanguage: null,
          bannerImage: '',
          backgroundImage: '',
          deepLink: '',
          webDeepLink: '',
          priorityOrder: 1,
          storeIds: '',
          launchDate: new Date(),
          expiryDate: getOneMonthLaterDate(),
          invalidDateErrorMsg: '',
        }, () => {
          this.handleLoad();
        });
      } else {
        throw new Error();
      }
    }).catch((err) => {
      this.setState(() => {
        if (
          err
          && err.response
          && err.response.data
          && err.response.data[0].client_message
        ) {
          return {
            alteringBanner: false,
            errorMsg: err.response.data[0].client_message,
          };
        }
        return {
          alteringBanner: false,
          alteringBannerError: true,
        };
      });
      return false;
    });
  }

  deleteBanner = () => {
    const { bannerUidToDelete } = this.state;
    this.setState({
      alteringBanner: true,
    });
    banners(
      'DELETE',
      null,
      {},
      bannerUidToDelete,
    ).then(() => {
      this.setState({
        alteringBanner: false,
        bannerUidToDelete: null,
      }, () => {
        this.handleLoad();
      });
    }).catch(() => {
      this.setState({
        alteringBanner: false,
        alteringBannerError: true,
      });
    });
  }

  handleInputOnChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  handleChangeDate = (event) => {
    this.setState({
      [event.target.id]: new Date(event.target.value),
    });
  }

  handleImageOnChange = (event) => {
    this.setState({
      uploadingImage: 'loading',
    });
    // divide by 1000 means to convert bytes into KB
    const imageSize = (event.target.files[0].size) / 1000;
    // Image size is more than 75 KB is not allowed to upload
    if (imageSize > 75) {
      this.setState({
        uploadingImage: 'error',
        bannerImage: '',
      });
      return;
    }
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('img', file);

    uploadImage(
      'post',
      formData,
    ).then((res) => {
      this.setState({
        bannerImage: res.data.url,
        uploadingImage: '',
      });
    }).catch(() => {});
  }

  handleBackgroundImage = (event) => {
    this.setState({
      uploadingBackground: 'loading',
    });
    const imageSize = (event.target.files[0].size) / 1000;
    if (imageSize > 75) {
      this.setState({
        uploadingBackground: 'error',
        backgroundImage: '',
      });
      return;
    }
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('img', file);
    uploadImage(
      'post',
      formData,
    ).then((res) => {
      this.setState({
        backgroundImage: res.data.url,
        uploadingBackground: '',
      });
    }).catch(() => {
      this.setState({
        uploadingBackground: '',
      });
    });
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const {
      l, p,
    } = param;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source;
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
    });
  }

  handleDateChange = (startDate, endDate) => {
    this.setState({
      stDate: startDate,
      eDate: endDate,
    });
  }

  applyFilters = () => {
    const { newParam } = this.state;
    this.handleRequestProcessing({
      ...newParam,
      p: 1,
    });
  }

  resetFilters = () => {
    this.handleRequestProcessing({
      stDate: '',
      eDate: '',
      p: 1,
      banner_type: '',
      status: '',
      language: '',
    });
    this.setState((state) => ({
      stDate: '',
      eDate: '',
      newParam: {
        ...state.newParam,
        p: 1,
        banner_type: '',
        status: '',
        language: '',
      },
    }));
  }

  handleDropdownChange = (data) => {
    this.setState((state) => ({
      newParam: { ...state.newParam, ...data },
    }));
  }

  render() {
    const {
      bannersData, loading, uploadingImage, uploadingBackground,
      error, addBanner, alteringBanner, title,
      bannerType, bannerImage, deepLink, webDeepLink,
      priorityOrder, alteringBannerError, storeIds,
      bannerUidToDelete, bannerUidToEdit,
      launchDate, expiryDate, invalidDateErrorMsg,
      selectedCustomerTypes, selectedLanguage, backgroundImage,
      rowsPerPage, page, param, stDate, eDate, newParam, errorMsg,
    } = this.state;

    const filterConfForSingleSelect = [
      {
        key: 'banner_type',
        displayText: 'Banner Type',
        options: [
          {
            label: 'B2C Hero Banner',
            value: 'HERO_BANNER',
          },
          {
            label: 'B2C Home',
            value: 'HOME_PAGE',
          },
          {
            label: 'B2C Top Most',
            value: 'B2C_TOPMOST',
          },
          {
            label: 'B2B Dashboard',
            value: 'B2B_HOME',
          },
        ],
      },
      {
        key: 'status',
        displayText: 'Card Status',
        options: [
          {
            label: 'Scheduled',
            value: 'SCHEDULED',
          },
          {
            label: 'Active',
            value: 'ACTIVE',
          },
          {
            label: 'Completed',
            value: 'COMPLETED',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
    ];

    const filterConfForMultiSelect = [
      {
        key: 'language',
        displayText: 'Language',
        options: (
          Object.keys(languages).map((item) => ({
            label: languages[item],
            value: item,
          }))
        ),
      },
    ];

    if (!bannersData && loading) {
      return (
        <div
          className="h-100 d-flex align-items-center justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!bannersData && !loading && error) {
      return (
        <div
          className="h-100 d-flex align-items-center
               justify-content-center"
        >
          <ErrorHandler
            retryLogic={() => {
              this.setState({
                loading: true,
                error: false,
              }, () => {
                this.handleLoad();
              });
            }}
          />
        </div>
      );
    }

    const addOrEditBannerModalBody = (
      <Col>
        {
          alteringBannerError
          && (
            <ErrorHandler
              retryLogic={() => {
                this.addBanner();
              }}
            />
          )
        }
        {
          alteringBanner
          && (
            <div
              className="w-100 pt-3 d-flex align-items-center justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          )
        }
        {
          !alteringBannerError && !alteringBanner
          && (
          <Col
            className="mx-3 pt-3"
          >
            <Form
              onSubmit={(e) => {
                this.addBanner(e);
              }}
            >
              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                  Title:
                </Form.Label>
                <Col
                  sm={12}
                >
                  <Form.Control
                    value={title}
                    id="title"
                    onChange={this.handleInputOnChange}
                    autoComplete="off"
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                  Banner Type:
                </Form.Label>
                <Col
                  sm={12}
                >
                  <select
                    className="form-control"
                    id="bannerType"
                    onChange={this.handleInputOnChange}
                    value={bannerType}
                    required
                  >
                    <option value="">-- SELECT --</option>
                    <option value="HERO_BANNER">B2C Hero Banner</option>
                    <option value="HOME_PAGE">B2C Home</option>
                    <option value="B2C_TOPMOST">B2C Top Most</option>
                    <option value="B2B_HOME">B2B Dashboard</option>
                  </select>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                  Customer Type:
                </Form.Label>
                <Col
                  sm={12}
                >
                  <Select
                    id="selectedCustomerTypes"
                    onChange={(selectedTypes) => {
                      this.setState({
                        selectedCustomerTypes: selectedTypes,
                      });
                    }}
                    value={selectedCustomerTypes}
                    isMulti
                    options={customerTypes}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                  Language:
                </Form.Label>
                <Col
                  sm={12}
                >
                  <Select
                    id="selectedLanguage"
                    onChange={(selectedLang) => {
                      this.setState({
                        selectedLanguage: selectedLang,
                      });
                    }}
                    value={selectedLanguage}
                    options={Object.keys(languages)
                      .map((item) => ({ label: languages[item], value: item }))}
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                  Image:
                </Form.Label>
                <Col
                  sm={12}
                >
                  <input
                    type="file"
                    className="d-none"
                    accept="image/*"
                    ref={this.hiddenFileInput1}
                    onChange={(e) => {
                      this.handleImageOnChange(e);
                    }}
                  />
                  <Button
                    variant="primary"
                    className="fs-01 font-weight-bold"
                    onClick={() => {
                      this.hiddenFileInput1.current.click();
                    }}
                    disabled={uploadingImage === 'loading'}
                  >
                    UPLOAD IMAGE
                  </Button>
                  {
                    uploadingImage === 'loading' && (
                      <div>
                        <Spinner
                          animation="border"
                          variant="primary"
                        />
                      </div>
                    )
                  }
                  {
                    uploadingImage === 'error' && (
                      <div
                        className="mt-1 text-danger"
                      >
                        Please upload image with less than 75kb size.
                      </div>
                    )
                  }
                  {
                    uploadingImage !== 'error' && !bannerImage && (
                      <div
                        className="mt-1 text-medium"
                      >
                        The image aspect ratio should be 3:1
                      </div>
                    )
                  }
                  {
                    bannerImage && (
                      <img
                        className="py-2"
                        src={bannerImage}
                        alt="Share Card"
                        width="250px"
                      />
                    )
                  }
                </Col>
              </Form.Group>

              {/* Only For B2C Web Hero Banner */}
              {
                bannerType === 'HERO_BANNER' && (
                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      Background Image:
                    </Form.Label>
                    <Col
                      sm={12}
                    >
                      <input
                        type="file"
                        className="d-none"
                        accept="image/*"
                        ref={this.hiddenFileInput2}
                        onChange={(e) => {
                          this.handleBackgroundImage(e);
                        }}
                      />
                      <Button
                        variant="primary"
                        className="fs-01 font-weight-bold"
                        onClick={() => {
                          this.hiddenFileInput2.current.click();
                        }}
                        disabled={uploadingBackground === 'loading'}
                      >
                        UPLOAD BACKGROUND IMAGE
                      </Button>
                      {
                        uploadingBackground === 'loading' && (
                          <div>
                            <Spinner
                              animation="border"
                              variant="primary"
                            />
                          </div>
                        )
                      }
                      {
                        uploadingBackground === 'error' && (
                          <div
                            className="mt-1 text-danger"
                          >
                            Please upload background image with less than 75kb size.
                          </div>
                        )
                      }
                      {
                        uploadingBackground !== 'error' && !backgroundImage && (
                          <div
                            className="mt-1 text-medium"
                          >
                            The image aspect ratio should be 3:1
                          </div>
                        )
                      }
                      {
                        backgroundImage && (
                          <img
                            className="py-2"
                            src={backgroundImage}
                            alt="Share Card"
                            width="250px"
                          />
                        )
                      }
                    </Col>
                  </Form.Group>
                )
              }

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                  Deep Link:
                </Form.Label>
                <Col
                  sm={12}
                >
                  <Form.Control
                    value={deepLink}
                    id="deepLink"
                    onChange={this.handleInputOnChange}
                    autoComplete="off"
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                  Web Deep Link:
                </Form.Label>
                <Col
                  sm={12}
                >
                  <Form.Control
                    value={webDeepLink}
                    id="webDeepLink"
                    onChange={this.handleInputOnChange}
                    autoComplete="off"
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                  Launch Date:
                </Form.Label>
                <Col
                  sm={12}
                >
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={moment(launchDate).format('YYYY-MM-DDTHH:mm')}
                    id="launchDate"
                    onChange={this.handleChangeDate}
                    autoComplete="off"
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                  Expiry Date:
                </Form.Label>
                <Col
                  sm={12}
                >
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={moment(expiryDate).format('YYYY-MM-DDTHH:mm')}
                    id="expiryDate"
                    onChange={this.handleChangeDate}
                    autoComplete="off"
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                  Priority Order:
                </Form.Label>
                <Col
                  sm={12}
                >
                  <input
                    type="number"
                    className="form-control"
                    value={priorityOrder}
                    id="priorityOrder"
                    onChange={this.handleInputOnChange}
                    autoComplete="off"
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                  Store Ids:
                </Form.Label>
                <Col
                  sm={12}
                >
                  <Form.Control
                    value={storeIds}
                    id="storeIds"
                    onChange={this.handleInputOnChange}
                    autoComplete="off"
                  />
                </Col>
              </Form.Group>

              {
                !!invalidDateErrorMsg && (
                  <Col
                    xs={24}
                    className="mb-2 text-danger px-0"
                  >
                    {invalidDateErrorMsg}
                  </Col>
                )
              }

              <Form.Group as={Row}>
                <Form.Label column sm={6} />
                <Col sm={6}>
                  <Button
                    type="submit"
                    variant="outline-primary"
                    disabled={
                      !selectedCustomerTypes.length || !selectedLanguage || !bannerImage
                      || (bannerType === 'HERO_BANNER' && !backgroundImage)
                    }
                  >
                    SUBMIT
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </Col>
          )
        }
      </Col>
    );

    const deleteBannerConfirmationModalBody = (
      <Col>
        {
          alteringBannerError
          && (
            <ErrorHandler
              retryLogic={() => {
                this.deleteBanner();
              }}
            />
          )
        }
        {
          alteringBanner
          && (
            <div
              className="w-100 pt-3 d-flex align-items-center justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          )
        }
        {
          !alteringBannerError && !alteringBanner && bannerUidToDelete
          && (
            <Container
              className="justify-content-center"
            >
              <Row
                className="mx-auto mt-4"
              >
                <Col
                  xs={24}
                  className="text-center fs-3"
                >
                  Are you sure you want to&nbsp;
                  <span
                    className="text-danger"
                  >
                    DELETE
                  </span>
                  &nbsp;this banner ?
                </Col>
              </Row>
              <Row
                className="mt-4"
              >
                <Col
                  xs={12}
                  className="text-right"
                >
                  <Button
                    variant="outline-primary"
                    value="cancel"
                    className="py-1 px-3 fs-2"
                    disabled={alteringBanner}
                    onClick={() => {
                      this.setState({
                        bannerUidToDelete: null,
                      });
                    }}
                  >
                    CANCEL
                  </Button>
                </Col>
                <Col
                  xs={12}
                  className="text-left"
                >
                  <Button
                    variant="outline-danger"
                    value="submit"
                    className="py-1 px-3 fs-2 lg active"
                    disabled={alteringBanner}
                    onClick={() => {
                      this.deleteBanner();
                    }}
                  >
                    DELETE
                  </Button>
                </Col>
              </Row>
            </Container>
          )
        }
      </Col>
    );

    return (
      <Container
        fluid
        className="bg-white"
      >
        <CustomModal
          body={addOrEditBannerModalBody}
          closeButton
          size="lg"
          show={addBanner || !!bannerUidToEdit}
          title={`${addBanner ? 'Add Banner' : 'Edit Banner'}`}
          onHide={() => {
            this.setState({
              addBanner: false,
              bannerUidToEdit: null,
              alteringBanner: false,
              alteringBannerError: false,
              title: '',
              bannerType: '',
              selectedCustomerTypes: [{ value: 'GUESTS', label: 'GUESTS' }],
              selectedLanguage: null,
              bannerImage: '',
              backgroundImage: '',
              deepLink: '',
              webDeepLink: '',
              priorityOrder: 1,
              storeIds: '',
              launchDate: new Date(),
              expiryDate: getOneMonthLaterDate(),
              invalidDateErrorMsg: '',
              uploadingImage: '',
              uploadingBackground: '',
            });
          }}
        />
        <CustomModal
          body={deleteBannerConfirmationModalBody}
          closeButton
          size="lg"
          show={!!bannerUidToDelete}
          title="Delete Banner"
          onHide={() => {
            this.setState({
              bannerUidToDelete: null,
              alteringBanner: false,
              alteringBannerError: false,
            });
          }}
        />
        <CustomModal
          show={!!errorMsg}
          onHide={() => {
            this.setState({
              errorMsg: '',
            });
          }}
          title="Please Check launch/expiry date"
          autoSize
          closeButton
          border
          body={(
            <Container
              className="justify-content-center"
            >
              <Row
                className="mx-auto mt-4"
              >
                <Col
                  xs={24}
                  className="text-center"
                >
                  <span
                    className="text-danger"
                  >
                    {errorMsg}
                  </span>
                </Col>
              </Row>
              <Row className="mx-auto my-4">
                <Col
                  xs={24}
                  className="text-center"
                >
                  <Button
                    variant="primary"
                    className="py-1 px-3"
                    onClick={() => {
                      this.setState({
                        errorMsg: '',
                      });
                    }}
                  >
                    Okay
                  </Button>
                </Col>
              </Row>
            </Container>
          )}
        />
        <Row
          className="py-3 px-2"
        >
          <Col
            xs="auto"
            className="mt-3"
          >
            <Button
              variant="outline-primary"
              onClick={() => {
                this.setState({
                  addBanner: true,
                });
              }}
              disabled={!this.canIEdit}
            >
              ADD BANNERS
            </Button>
          </Col>
          <Row
            className="align-items-center"
          >
            <Col
              xs="auto"
              className="pl-4"
            >
              <div className="pb-1 fs-01">
                Launch Date:
              </div>
              <div>
                <DatePicker
                  isDateRange
                  onApply={(dateRange) => {
                    this.handleDateChange(
                      new Date(dateRange.startDate).getTime(),
                      new Date(dateRange.endDate).getTime(),
                    );
                  }}
                  startDate={stDate}
                  endDate={eDate}
                  onClear={() => {
                    this.setState({
                      stDate: '',
                      eDate: '',
                    });
                  }}
                />
              </div>
            </Col>
            {
              filterConfForSingleSelect.map((item) => (
                <Col
                  key={item.key}
                  xs="auto"
                  className="pl-3"
                >
                  <div className="pb-1 fs-01">
                    {`Select ${item.displayText}:`}
                  </div>
                  <div>
                    <CustomDropdown
                      item={item}
                      onChange={(data) => {
                        this.handleDropdownChange(data);
                      }}
                      selectedVal={newParam[item.key]}
                    />
                  </div>
                </Col>
              ))
            }
            {
              filterConfForMultiSelect.map((item) => (
                <Col
                  key={item.key}
                  xs="auto"
                  className="pl-3"
                >
                  <div className="pb-1 fs-01">
                    {`Select ${item.displayText}:`}
                  </div>
                  <div>
                    <Select
                      id={item.key}
                      placeholder={item.displayText}
                      onChange={(selectedItems) => {
                        const selectedItemsString = selectedItems.reduce(
                          (acc, selItem, i) => acc.concat(i === 0 ? '' : ',', selItem.value), '',
                        );
                        this.handleDropdownChange({ [item.key]: selectedItemsString });
                      }}
                      selectedVal={newParam[item.key]}
                      options={item.options}
                      isMulti
                      value={newParam[item.key]
                        ? (newParam[item.key].split(','))
                          .map((val) => ({ label: val, value: val }))
                        : []}
                      className="minw-150p"
                    />
                  </div>
                </Col>
              ))
            }
          </Row>
        </Row>
        <Row className="py-2 d-flex align-items-center flex-row-reverse">
          <Col
            xs="auto"
            className="px-2"
          >
            <Button
              variant="primary"
              className="fs-01 font-weight-bold"
              onClick={this.resetFilters}
            >
              RESET FILTERS
            </Button>
          </Col>
          <Col
            xs="auto"
            className="px-2"
          >
            <Button
              variant="success"
              className="fs-01 font-weight-bold"
              onClick={this.applyFilters}
            >
              APPLY FILTERS
            </Button>
          </Col>
        </Row>
        <Row
          className="banner-height"
        >
          {
            bannersData && (
              <Row
                className="m-0"
              >
                <Col
                  xs={20}
                  className="mx-auto"
                >
                  {
                    bannersData
                    && bannersData.results.map(
                      (banner) => (
                        <Container
                          className="my-3"
                          key={banner.uid}
                        >
                          <Card
                            border="secondary"
                            xs="auto"
                            className="mt-0 mx-auto pt-2"
                            key={banner.uid}
                          >
                            <Row
                              className={`mx-2 flex-row-reverse ${this.canIEdit ? 'd-flex' : 'd-none'}`}
                            >
                              <Col
                                xs="auto"
                                className="text-danger px-2 pb-2 cursor-pointer"
                                onClick={() => {
                                  this.setState({
                                    bannerUidToDelete: banner.uid,
                                  });
                                }}
                              >
                                DELETE
                              </Col>
                              <Col
                                xs="auto"
                                className="text-primary px-2 pb-2 cursor-pointer"
                                onClick={() => {
                                  this.setState({
                                    bannerUidToEdit: banner.uid,
                                    title: banner.title,
                                    bannerType: banner.banner_type,
                                    selectedCustomerTypes: (banner.customerBannerTypes)
                                      .map((item) => ({ value: item, label: item })),
                                    selectedLanguage: {
                                      label: languages[banner.language], value: banner.language,
                                    },
                                    bannerImage: banner.banner_image_url,
                                    backgroundImage: banner.backgroundImage,
                                    deepLink: banner.deep_link,
                                    webDeepLink: banner.web_deep_link,
                                    priorityOrder: banner.priority_order,
                                    storeIds: banner.storeCodes,
                                    launchDate: new Date(banner.launchDate),
                                    expiryDate: new Date(banner.expiryDate),
                                  });
                                }}
                              >
                                EDIT
                              </Col>
                            </Row>
                            <Row
                              className="m-1"
                            >
                              <Col
                                xs={6}
                                className="mb-2"
                              >
                                <Card.Img
                                  className="banner-image w-100"
                                  src={banner.banner_image_url}
                                />
                              </Col>
                              <Col
                                xs={18}
                              >
                                <Card.Body
                                  className="text-dark p-0"
                                >
                                  <Row className="mx-0">
                                    <h6
                                      className="card-text m-0 fs-02 font-weight-bold"
                                    >
                                      { banner.title }
                                    </h6>
                                  </Row>
                                  <Row className="mx-0">
                                    <h6
                                      className="card-text pt-2 m-0 fs-03"
                                    >
                                      { banner.text }
                                    </h6>
                                  </Row>
                                  <Row className="mx-0">
                                    <h6
                                      className="card-text pt-2 m-0 fs-03"
                                    >
                                      { banner.storeCodes }
                                    </h6>
                                  </Row>
                                  <Row className="mx-0">
                                    <Col
                                      className="d-flex px-0 py-2 m-0 fs-01"
                                    >
                                      <div>
                                        <b
                                          className="text-primary"
                                        >
                                          Launch Date:
                                        </b>
                                        &nbsp;
                                        {
                                          new Date(banner.launchDate).toLocaleDateString(
                                            'en',
                                            dateTimeOptions,
                                          )
                                        }
                                      </div>
                                      <div
                                        className="pl-3"
                                      >
                                        <b
                                          className="text-primary"
                                        >
                                          Expiry Date:
                                        </b>
                                        &nbsp;
                                        {
                                          new Date(banner.expiryDate).toLocaleDateString(
                                            'en',
                                            dateTimeOptions,
                                          )
                                        }
                                      </div>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Col>
                            </Row>
                          </Card>
                        </Container>
                      ),
                    )
                  }
                </Col>
              </Row>
            )
          }
        </Row>
        <Row className="text-center">
          {
            bannersData
            && bannersData.results.length === 0
            && (
              <h6
                className="p-3 text-danger"
              >
                Not Available any Banners
              </h6>
            )
          }
        </Row>
        {
          bannersData && (
            <Row>
              <ProcessingStatus
                show={loading || error}
                loading={loading}
                error={error}
                onRetry={() => this.retry()}
                onCancel={this.onCancel}
              />
              <Col
                xs={24}
                className="px-0"
              >
                <CustomPagination
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={bannersData.count}
                  hasPrev={bannersData.hasPrevious}
                  hasNext={bannersData.hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            </Row>
          )
        }
      </Container>
    );
  }
}

Banners.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default Banners;
