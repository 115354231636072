import React from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse } from 'react-bootstrap';
import '../../assets/scss/wrapper/Sidebar.scss';
import Priviledge from '../../access&permissions/priviledge';

class Sidebar extends React.Component {
  constructor(props) {
    const { match } = props;
    let selectedMgt = null;
    if (['/retailer', '/shop-allocation'].includes(match.path)) {
      selectedMgt = '1';
    }
    super();
    this.state = {
      selectedMgt,
    };
  }

  handleMgtSelection = (id) => {
    const { selectedMgt } = this.state;
    this.setState({
      selectedMgt: selectedMgt === id ? null : id,
    });
  }

  render() {
    const {
      onFade, history, match, userPriviledge,
    } = this.props;
    const { selectedMgt } = this.state;
    const {
      RETAILER,
      SHOP_ALLOCATION,
      ORDERS,
      PLACE_ORDER,
      DELIVERY_SETTING,
      BULK_COUPON_CREATION,
      REVIEW_PANEL,
      SHOP_REVIEW,
      CUSTOMERS_REVIEW,
      LL_DAILY,
      BANNERS,
      SHARE_OFFER_CARD,
      SMS_SENT,
      PENDING_SETTLEMENTS,
      RECONCILED_SETTLEMENTS,
      PENDING_SETTLEMENTS_STORE,
      FEEDS,
      COUPONS,
      COUPON_TRACKER,
      REFERRAL_CHECK,
      PRICE_UPDATE,
      PRODUCT_ADD,
      STORE_WISE_JOB_STATUS,
      SHOP_LOGO_REVIEW,
      FLASH_SALE_DETAILED,
      FLASH_SALE_AGGREGATED,
      STORIES,
      CUSTOMER,
      PRODUCT_BULK_UPLOAD,
      RETAILER_STORY_REVIEW,
      REFUND_DASHBOARD,
      QUERY_TRACKER,
      SMART_CATALOGUES,
      SMART_CATALOGUE_MAPPINGS,
      LIBRARY_PRODUCTS,
      SMART_CATALOGUES_STORE,
      BACKGROUND_JOBS,
      LEAD_MANAGEMENT,
      EXPORT_CLEANUP_TOOL,
      SHOP_CATALOGUE_PAGE,
      CUSTOMER_PANEL,
      FRAUD_MANAGEMENT,
      OM_STATUS_TRACKER,
      STORE_RESURRECTION,
      LEADERSHIP_BOARD,
      COUPON_ENGINE,
      RETAILER_CATALOGUE_FILES,
      CX_RX_CALL_MAPPING,
      BRAND_SAMPLING_CAMPAIGN,
      MARKETPLACE_BRANDS,
      MARKETPLACE_ORDERS,
      PLATFORM_FEE_DASHBOARD,
      LL_PLUS_PURCHASES,
      SPECIAL_NOTES,
    } = Priviledge;
    const conf = [
      {
        id: '1',
        label: 'Retailer Management',
        navItems: [
          {
            id: RETAILER,
            label: 'Retailer',
            link: '/retailer',
          },
          {
            id: SHOP_ALLOCATION,
            label: 'Allocation',
            link: '/shop-allocation',
          },
          {
            id: DELIVERY_SETTING,
            label: 'Retailer Settings',
            link: '/retailer-setting',
          },
        ],
      },
      {
        id: '2',
        label: 'Order Management',
        navItems: [
          {
            id: ORDERS,
            label: 'Orders',
            link: '/orders',
          },
          {
            id: PLACE_ORDER,
            label: 'Place Order',
            link: '/place-order',
          },
          {
            id: OM_STATUS_TRACKER,
            label: 'OM Status Tracker',
            link: '/om-status-tracker',
          },
        ],
      },
      {
        id: '3',
        label: 'Coupon Management',
        navItems: [
          {
            id: BULK_COUPON_CREATION,
            label: 'Coupon Creation',
            link: '/coupon-creation',
          },
          {
            id: COUPONS,
            label: 'Coupon',
            link: '/coupon',
          },
          {
            id: COUPON_TRACKER,
            label: 'Coupon Tracker',
            link: '/coupon-tracker',
          },
          {
            id: REFERRAL_CHECK,
            label: 'Referral Check',
            link: '/referral-check',
          },
        ],
      },
      {
        id: '4',
        label: 'Catalog Management',
        navItems: [
          {
            id: PRICE_UPDATE,
            label: 'Price Update',
            link: '/price-update',
          },
          {
            id: PRODUCT_ADD,
            label: 'Product Add',
            link: '/product-add',
          },
          {
            id: STORE_WISE_JOB_STATUS,
            label: 'Store Job Status',
            link: '/store-wise-job-status',
          },
          {
            id: PRODUCT_BULK_UPLOAD,
            label: 'Product Bulk Upload',
            link: '/product-bulk-upload',
          },
        ],
      },
      {
        id: '5',
        label: 'Smart Catalogue Management',
        navItems: [
          {
            id: SMART_CATALOGUES,
            label: 'Smart Catalogues',
            link: '/smart-catalogues',
          },
          {
            id: SMART_CATALOGUE_MAPPINGS,
            label: 'Smart Catalogue Mappings',
            link: '/smart-catalogue-map',
          },
          {
            id: SMART_CATALOGUES_STORE,
            label: 'Smart Catalogues Store',
            link: '/smart-catalogues-store',
          },
          {
            id: LIBRARY_PRODUCTS,
            label: 'Library Products',
            link: '/library-products',
          },
          {
            id: BACKGROUND_JOBS,
            label: 'Background Jobs',
            link: '/background-jobs',
          },
          {
            id: EXPORT_CLEANUP_TOOL,
            label: 'Export Cleanup Tool',
            link: '/export-cleanup-tool',
          },
          {
            id: SHOP_CATALOGUE_PAGE,
            label: 'Shop Catalogue Page',
            link: '/shop-catalogue-page',
          },
          {
            id: RETAILER_CATALOGUE_FILES,
            label: 'Product Exports',
            link: '/retailer-catalogue-files',
          },
        ],
      },
      {
        id: '6',
        label: 'Customer Management',
        navItems: [
          {
            id: CUSTOMER,
            label: 'Customer',
            link: '/customer',
          },
          {
            id: QUERY_TRACKER,
            label: 'Query Tracker',
            link: '/query-tracker',
          },
          {
            id: CUSTOMER_PANEL,
            label: 'Customer Panel',
            link: '/customer-panel',
          },
        ],
      },
      {
        id: '7',
        label: 'Lead Management',
        navItems: [
          {
            id: LEAD_MANAGEMENT,
            label: 'Lead Management',
            link: '/lead-management',
          },
        ],
      },
      {
        id: '8',
        label: 'Leadership Board',
        navItems: [
          {
            id: LEADERSHIP_BOARD,
            label: 'Leadership Board',
            link: '/leadership-board',
          },
        ],
      },
      {
        id: '9',
        label: 'Fraud Management',
        navItems: [
          {
            id: FRAUD_MANAGEMENT,
            label: 'Fraud Management',
            link: '/fraud-management',
          },
        ],
      },
      {
        id: '10',
        label: 'Coupon Engine',
        navItems: [
          {
            id: COUPON_ENGINE,
            label: 'Campaign',
            link: '/campaign',
          },
        ],
      },
      {
        id: '11',
        label: 'Store Resurrection',
        navItems: [
          {
            id: STORE_RESURRECTION,
            label: 'Store Resurrection',
            link: '/store-resurrection',
          },
        ],
      },
      {
        id: '12',
        label: 'Review Panel',
        navItems: [
          {
            id: REVIEW_PANEL,
            label: 'Review Panel',
            link: '/review-panel',
          },
          {
            id: SHOP_REVIEW,
            label: 'Shop Reviews',
            link: '/stores-review',
          },
          {
            id: CUSTOMERS_REVIEW,
            label: 'Customer Reviews',
            link: '/customers-review',
          },
          {
            id: SHOP_LOGO_REVIEW,
            label: 'Shop Logo Review',
            link: '/shop-logo-review',
          },
          {
            id: RETAILER_STORY_REVIEW,
            label: 'Retailer Story Review',
            link: '/retailer-story-review',
          },
        ],
      },
      {
        id: '13',
        label: 'LoveLocal',
        navItems: [
          {
            id: LL_DAILY,
            label: 'LoveLocal Daily',
            link: '/lovelocal-daily',
          },
          {
            id: BANNERS,
            label: 'Banners',
            link: '/banners',
          },
          {
            id: STORIES,
            label: 'Stories',
            link: '/stories',
          },
          {
            id: SHARE_OFFER_CARD,
            label: 'Share Offer Card',
            link: '/share-offer-card',
          },
          {
            id: SMS_SENT,
            label: 'Sms Sent',
            link: '/sms-sent',
          },
          {
            id: FLASH_SALE_AGGREGATED,
            label: 'Flash Sale (Aggregated)',
            link: '/flash-sale-aggregated',
          },
          {
            id: FLASH_SALE_DETAILED,
            label: 'Flash Sale (Detailed)',
            link: '/flash-sale-detailed',
          },
        ],
      },
      {
        id: '14',
        label: 'Settlements',
        navItems: [
          {
            id: PENDING_SETTLEMENTS,
            label: 'Pending Settlements',
            link: '/pending-settlements?status=PENDING',
          },
          {
            id: RECONCILED_SETTLEMENTS,
            label: 'Reconciled Settlements',
            link: '/reconciled-settlements',
          },
          {
            id: PENDING_SETTLEMENTS_STORE,
            label: 'Pending Settlements (Store)',
            link: '/pending-settlements-store',
          },
        ],
      },
      {
        id: '15',
        label: 'Refunds',
        navItems: [
          {
            id: REFUND_DASHBOARD,
            label: 'Refund Dashboard',
            link: '/refund-dashboard',
          },
        ],
      },
      {
        id: '16',
        label: 'Communications',
        navItems: [
          {
            id: CX_RX_CALL_MAPPING,
            label: 'CX / RX Call Mapping',
            link: '/cx-rx-call-mapping',
          },
        ],
      },
      {
        id: '17',
        label: 'Feeds',
        navItems: [
          {
            id: FEEDS,
            label: 'Retailer Feeds',
            link: '/feeds-section',
          },
        ],
      },
      {
        id: '18',
        label: 'Campaign Management',
        navItems: [
          {
            id: BRAND_SAMPLING_CAMPAIGN,
            label: 'Brand Sampling Campaign',
            link: '/brand-sampling-campaign-management',
          },
        ],
      },
      {
        id: '19',
        label: 'Marketplace',
        navItems: [
          {
            id: MARKETPLACE_BRANDS,
            label: 'Marketplace Brands',
            link: '/marketplace-brands',
          },
          {
            id: MARKETPLACE_ORDERS,
            label: 'Marketplace Orders',
            link: '/marketplace-orders',
          },
        ],
      },
      {
        id: '20',
        label: 'Revenue Tracker',
        navItems: [
          {
            id: PLATFORM_FEE_DASHBOARD,
            label: 'Platform Fee Tracker',
            link: '/platform-fee-dashboard',
          },
        ],
      },
      {
        id: '21',
        label: 'Special Notes',
        navItems: [
          {
            id: SPECIAL_NOTES,
            label: 'Special Notes',
            link: '/special-notes',
          },
        ],
      },
      {
        id: '2',
        label: 'LL Plus Management',
        navItems: [
          {
            id: LL_PLUS_PURCHASES,
            label: 'LL Plus Purchases',
            link: '/ll-plus-purchases',
          },
        ],
      },
    ];

    return (
      <div
        className="py-2 sidebar"
      >
        {
          conf.map((item) => {
            const navItems = item.navItems
              .filter((nav) => userPriviledge.includes(nav.id));
            return navItems.length > 0
              ? (
                <div
                  key={item.id}
                >
                  <Button
                    variant="link"
                    className="py-2 fs-0"
                    onClick={() => {
                      this.handleMgtSelection(item.id);
                    }}
                  >
                    {item.label}
                  </Button>
                  <Collapse
                    in={selectedMgt === item.id}
                  >
                    <div
                      id="collapsed-button"
                    >
                      {
                        navItems.map((nav) => (
                          <Button
                            key={nav.id}
                            variant="link"
                            className={`py-2 pl-4 fs-0 ${
                              match.path === nav.link ? 'text-primary' : ''
                            }`}
                            onClick={() => {
                              onFade();
                              history.push(nav.link);
                            }}
                          >
                            {nav.label}
                          </Button>
                        ))
                      }
                    </div>
                  </Collapse>
                </div>
              ) : '';
          })
        }
      </div>
    );
  }
}

Sidebar.propTypes = {
  onFade: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  userPriviledge: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default Sidebar;
