import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import {
  Spinner, Container, Row, Col,
} from 'react-bootstrap';
import Axios from 'axios';
import { ramStoreContext, storeJourney } from '../../assets/api/axios';
import {
  ErrorHandler, CustomTable,
} from '../../component/common';
import { ProcessingStatus } from '../../component/derived/table-list';
import { Utils } from '../../utilities';

function getStatusIcon(status) {
  return status
    ? <span className="text-success">&#10004;</span>
    : <span className="text-danger">&#10006;</span>;
}

function getJourneyDataField(data) {
  return data.map((item) => {
    let { value } = item;
    switch (item.value_type) {
      case 'datetime':
        value = Utils.dateString(item.value);
        break;
      case 'bool':
        value = item.value
          ? <span className="text-success">&#10004;</span>
          : <span className="text-danger">&#10004;</span>;
        break;
      default:
        return item.value;
    }
    return {
      header: item.label,
      content: value,
    };
  });
}

const getMetaData = (data) => (
  <Row
    className="mx-n2 py-n1"
  >
    {data.map((item) => (
      <Col
        key={item.header}
        xs={12}
        md={8}
        lg={4}
        className="px-2 py-1 text-medium"
      >
        <div
          className="p-2 fs-01 h-100 meta-data"
        >
          <div
            className="font-weight-bold fs-0"
          >
            {`${item.content}` || '---'}
          </div>
          <div>
            {item.header}
          </div>
        </div>
      </Col>
    ))}
  </Row>
);

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    ...param, l, p,
  });
};

class Overview extends React.Component {
  constructor(props) {
    const param = getQueryParams(props.history.location.search);
    super();
    this.state = {
      rowsPerPage: param.l,
      page: param.p,
      param,
      servicingLogs: null,
      journey: null,
      loading: true,
      error: false,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleJourneyLoad();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleJourneyLoad = () => {
    const { retailerDetails } = this.props;
    storeJourney('get', retailerDetails.code)
      .then((res) => {
        this.setState({
          journey: { ...res.data },
        });
        this.handleLoad();
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  handleLoad = (data = {}) => {
    const { history, retailerDetails } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p,
    } = newParam;
    const offset = (p - 1) * l;
    ramStoreContext('get', retailerDetails.code,
      {
        view: 'overview',
        offset: `${offset}`,
        limit: l,
      }, this.source.token)
      .then((res) => {
        this.setState({
          rowsPerPage: l,
          page: p,
          param: { ...newParam },
          loading: false,
          error: false,
          servicingLogs: { ...res.data },
        }, () => {
          if (
            Object.keys(newParam).find((key) => (newParam[key] !== param[key]))
          ) {
            Object.keys(newParam).forEach((item) => {
              if (!newParam[item]) {
                delete newParam[item];
              }
            });
            history.push({
              path: pathname,
              search: QueryString.stringify(newParam),
            });
          }
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const {
      l, p,
    } = param;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source;
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
    });
  }

  render() {
    const {
      loading, error, param, servicingLogs, rowsPerPage, page, journey,
    } = this.state;
    const headers = [
      {
        key: 'modifiedOn',
        displayText: 'Timestamp',
        renderer: (data) => Utils.dateString(data.modifiedOn),
      },
      {
        key: 'deliveryStatus',
        displayText: 'Status',
        renderer: (data) => getStatusIcon(data.deliveryStatus),
      },
      {
        key: 'user',
        displayText: 'Changed By',
      },
      {
        key: 'comment',
        displayText: 'Reason',
      },
    ];

    if ((!journey || !servicingLogs) && loading) {
      return (
        <div
          className="d-flex align-items-center
           justify-content-center h-100"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if ((!journey || !servicingLogs) && !loading && error) {
      return (
        <div
          className="d-flex align-items-center
           justify-content-center h-100"
        >
          <ErrorHandler
            retryLogic={() => {
              this.setState({
                loading: true,
                error: false,
              }, () => {
                this.handleJourneyLoad();
              });
            }}
          />
        </div>
      );
    }

    if (journey && servicingLogs) {
      return (
        <div
          id="retailer-overview"
          className="table-list"
        >
          <ProcessingStatus
            show={loading || error}
            loading={loading}
            error={error}
            onRetry={() => this.retry()}
            onCancel={this.onCancel}
          />
          <Container
            fluid
            className="mx-0 px-0"
          >
            <Row
              className="mx-0"
            >
              <Col
                xs={24}
                className="px-2 mb-4"
              >
                {getMetaData(getJourneyDataField(journey.data))}
              </Col>
              <Col
                xs={24}
                className="px-0"
              >
                <div
                  className="fs-1 mb-2 px-2"
                >
                  <b>Servicing ON/OFF Logs</b>
                </div>
                <div>
                  <CustomTable
                    headers={headers}
                    content={servicingLogs.results}
                    keyField="id"
                    l={param.l}
                    p={param.p}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    totalItems={servicingLogs.count}
                    hasPrev={servicingLogs.hasPrevious}
                    hasNext={servicingLogs.hasNext}
                    onNext={this.onNext}
                    onPrev={this.onPrev}
                    onSubmitPage={this.onSubmitPage}
                    onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                    updateRowsPageInput={this.handleRowsPageInput}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    return null;
  }
}

Overview.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
};

export default Overview;
