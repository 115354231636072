import React, { Component } from 'react';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import { registeredRegion, regionalLibrary as loadRegionalLibrary } from '../../assets/api/axios';
import {
  CustomTable, CustomModal, CustomDropdown, Svg,
} from '../../component/common';
import { dateString, getHoursDiff } from '../../utilities/Utils';
import { getRegesteredRegions, setRegesteredRegions } from '../../utilities/Storage';
import permission from '../../access&permissions/permission';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  const {
    regionIn = '',
    libraryProductId = '',
    lpname = '',
    addNewModal = 'false',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    ...param,
    l,
    p,
    regionIn,
    libraryProductId,
    lpname,
    addNewModal: addNewModal === 'true',
  });
};

class LibraryProductRegionalPrice extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    let regions = getRegesteredRegions();
    if (regions) {
      const hrDiff = getHoursDiff(regions.lastUpdatedTime, new Date());
      if (hrDiff > 24) {
        regions = null;
      }
    }
    const { userPermission } = this.props;
    this.canEdit = userPermission.includes(
      permission.LIBRARY_PRODUCT_REGIONAL_PRICE_WRITE_RETROACTIVE,
    ) || userPermission.includes(permission.LIBRARY_PRODUCT_REGIONAL_PRICE_WRITE);
    this.state = {
      regions: (regions && regions.regions) || null,
      regionalLibrary: [],
      regionalLibraryCount: 0,
      hasNext: false,
      hasPrevious: false,
      rowsPerPage: param.l,
      page: param.p,
      loading: true,
      error: false,
      submitting: false,
      submitError: false,
      submitErrorMsg: '',
      actionSuccessMsg: false,
      param,
      newParams: param,
      selectedRegLibProdToPerformAction: null,
      showPerformingActionModal: false,
      addingNew: false,
      syncTypeForNew: 'IGNORE',
      mrpForNew: '',
      regionForNew: '',
      sellingPriceForNew: '',
      submittingNewStatus: '',
      submittngNewErrorMsg: '',
      updatingRegionalPrices: false,
      syncType: 'IGNORE',
      spError: false,
      libraryProductId: param.libraryProductId,
      region: '',
      productInfo: {},
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    const { regions, param } = this.state;
    if (!regions) {
      registeredRegion('GET').then((res) => {
        this.setState({ regions: res.data.results });
        setRegesteredRegions({
          regions: res.data.results,
          lastUpdatedTime: new Date(),
        });
      }).catch(() => {});
    }
    if (param.addNewModal) {
      this.setState({
        addingNew: true,
        syncTypeForNew: 'IGNORE',
        regionForNew: '',
        mrpForNew: '',
        sellingPriceForNew: '',
      });
    }
    this.handleLoad();
  }

  applyFilters = () => {
    const { newParams } = this.state;
    this.handleRequestProcessing({
      ...newParams,
      p: 1,
    });
  }

  resetfilters = () => {
    this.handleRequestProcessing({
      regionIn: '',
      p: 1,
    });
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleDropdownChange = (data) => {
    this.setState((state) => ({
      newParams: { ...state.newParams, ...data },
    }));
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState((state) => ({
      newParams: { ...state.newParams, [name]: value },
    }));
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, regionIn, libraryProductId,
    } = newParam;
    const offset = (p - 1) * l;
    loadRegionalLibrary(
      'GET',
      {
        view: 'PRODUCT_LEVEL_REGIONAL',
        offset: `${offset}`,
        limit: l,
        regionIn,
        libraryProductId,
      },
      null,
      this.source.token,
    ).then((res) => {
      this.setState({
        regionalLibrary: res.data.results,
        productInfo: res.data.libraryProduct,
        loading: false,
        regionalLibraryCount: res.data.count,
        hasNext: res.data.hasNext,
        hasPrevious: res.data.hasPrevious,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
        newParams: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...name === 'sellingPrice' ? { spError: false } : {},
      [name]: value,
    });
  }

  handleRadioButtonChange = (event) => {
    const { name } = event.target;
    this.setState((state) => ({
      [name]: !state[name],
    }));
  }

  getPayloadToPerformAction = (action, editedRegionalPrices = []) => {
    const {
      reason, selectedRegLibProdToPerformAction, libraryProductId, syncType,
      regionForNew, mrpForNew, sellingPriceForNew, syncTypeForNew,
    } = this.state;
    let body = {};
    switch (action) {
      case 'ARCHIVE_REGIONAL_PRICE':
        body = {
          action,
          syncJobTriggerAt: syncType,
          body: { id: selectedRegLibProdToPerformAction.regionalProductMapId, reason },
        };
        break;
      case 'ADD_REGIONAL_PRICE':
        body = {
          body: {
            libraryProductId,
            region: regionForNew,
            mrp: mrpForNew,
            sellingPrice: sellingPriceForNew,
          },
          action,
          syncJobTriggerAt: syncTypeForNew,
        };
        break;
      case 'UPDATE_REGIONAL_PRICE':
        body = {
          action,
          syncJobTriggerAt: syncType,
          body: editedRegionalPrices.map((item) => ({
            id: item.regionalProductMapId,
            mrp: item.updatedValues.mrp,
            sellingPrice: item.updatedValues.sellingPrice,
          })),
        };
        break;
      default:
        break;
    }
    return body;
  }

  cancelAddNew = () => {
    this.setState({
      addingNew: false,
      submittingNewStatus: '',
    });
  }

  addNewRegionalPrice = () => {
    const {
      mrpForNew, sellingPriceForNew,
    } = this.state;
    if (mrpForNew < sellingPriceForNew) {
      this.setState({
        submittingNewStatus: 'error',
        submittngNewErrorMsg: '* Selling Price should be less than MRP.',
      });
      return;
    }
    this.setState({
      submittingNewStatus: 'submitting',
    });
    const body = this.getPayloadToPerformAction('ADD_REGIONAL_PRICE');
    loadRegionalLibrary(
      'POST',
      {},
      body,
      this.source.token,
    ).then((res) => {
      const { regionalLibrary: newregionalLibrary } = this.state;
      newregionalLibrary.splice(0, 0, res.data);
      if (res.status === 200) {
        this.setState({
          submittingNewStatus: 'success',
          regionalLibrary: newregionalLibrary,
        }, () => {
          setTimeout(() => {
            this.setState({
              addingNew: false,
              submittingNewStatus: '',
            });
          }, 5000);
        });
      } else {
        throw new Error();
      }
    }).catch((err) => {
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.server_response
      ) {
        this.setState({
          submittingNewStatus: 'error',
          submittngNewErrorMsg: err.response.data.server_response,
        });
        return;
      }
      this.setState({
        submittingNewStatus: 'error',
        submittngNewErrorMsg: 'Oops! Something went wrong!',
      });
    });
  }

  onUpdate = (record) => {
    const { regionalLibrary: newRegLib } = this.state;
    const updatingAt = newRegLib.findIndex((item) => (
      item.regionalProductMapId === record.regionalProductMapId
    ));
    const { updatedValues } = newRegLib[updatingAt];
    newRegLib[updatingAt] = {
      ...newRegLib[updatingAt],
      isEdited: newRegLib[updatingAt].isEdited || false,
      updatedValues: {
        mrp: newRegLib[updatingAt].mrp,
        sellingPrice: newRegLib[updatingAt].sellingPrice,
        ...updatedValues || {},
      },
    };
    this.setState({
      selectedRecordForEdit: record,
      updatingAt,
      regionalLibrary: newRegLib,
    });
  }

  onUpdateFields = (event) => {
    const { regionalLibrary: updatedRgionalLibrary, updatingAt } = this.state;
    const { updatedValues } = updatedRgionalLibrary[updatingAt];
    const newUpdatedVal = {
      ...updatedValues,
      [event.target.name]: event.target.value,
    };
    updatedRgionalLibrary[updatingAt] = {
      ...updatedRgionalLibrary[updatingAt],
      isEdited: newUpdatedVal.mrp !== updatedRgionalLibrary[updatingAt].mrp
        || newUpdatedVal.sellingPrice !== updatedRgionalLibrary[updatingAt].sellingPrice,
      spError: newUpdatedVal.mrp < newUpdatedVal.sellingPrice,
      updatedValues: newUpdatedVal,
    };
    this.setState({
      regionalLibrary: updatedRgionalLibrary,
    });
  }

  performAction = () => {
    const {
      selectedRegLibProdToPerformAction, action, mrp, sellingPrice,
    } = this.state;
    if (mrp < sellingPrice) {
      this.setState({ spError: true });
      return;
    }
    this.setState({
      submitting: true,
      submitError: false,
      submitErrorMsg: '',
      actionSuccessMsg: false,
    });

    const body = this.getPayloadToPerformAction(action);
    loadRegionalLibrary(
      'POST',
      {},
      body,
      this.source.token,
    ).then((res) => {
      const { regionalLibrary: newregionalLibrary } = this.state;
      const index = newregionalLibrary.findIndex((item) => (
        item.regionalProductMapId === selectedRegLibProdToPerformAction.regionalProductMapId
      ));
      newregionalLibrary.splice(index, 1);
      if (res.status === 200) {
        this.setState({
          submitting: false,
          regionalLibrary: newregionalLibrary,
          selectedRegLibProdToPerformAction: null,
          showPerformingActionModal: false,
          syncType: 'IGNORE',
          reason: '',
          action: '',
          actionSuccessMsg: true,
        }, () => {
          setTimeout(() => {
            this.setState({
              actionSuccessMsg: false,
            });
          }, 5000);
        });
      } else {
        throw new Error();
      }
    }).catch((err) => {
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.server_response
      ) {
        this.setState({
          submitError: true,
          submitting: false,
          submitErrorMsg: err.response.data.server_response,
        });
        return;
      }
      this.setState({
        submitError: true,
        submitting: false,
        submitErrorMsg: 'Oops! Something went wrong!',
      });
    });
  }

  updateRegionalPrices = () => {
    const {
      regionalLibrary,
    } = this.state;
    const editedRegionalPrices = regionalLibrary.filter(
      (item) => item.isEdited && !item.spError,
    );
    if (editedRegionalPrices.length === 0) {
      return;
    }
    this.setState({
      updatingRegionalPrices: true,
    });
    const body = this.getPayloadToPerformAction('UPDATE_REGIONAL_PRICE', editedRegionalPrices);
    loadRegionalLibrary(
      'POST',
      {},
      body,
      this.source.token,
    ).then((res) => {
      const { regionalLibrary: newregionalLibrary } = this.state;
      res.data.results.map((item) => {
        const index = newregionalLibrary.findIndex(
          (i) => item.regionalProductMapId === i.regionalProductMapId,
        );
        if (index !== -1) {
          newregionalLibrary[index] = item;
        }
        return 0;
      });
      if (res.status === 200) {
        this.setState({
          submitting: false,
          regionalLibrary: newregionalLibrary,
          selectedRecordForEdit: null,
          updatingRegionalPrices: false,
          selectedRegLibProdToPerformAction: null,
          showPerformingActionModal: false,
          syncType: 'IGNORE',
          actionSuccessMsg: true,
        }, () => {
          setTimeout(() => {
            this.setState({
              actionSuccessMsg: false,
            });
          }, 5000);
        });
      } else {
        throw new Error();
      }
    }).catch((err) => {
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.server_response
      ) {
        this.setState({
          submitError: true,
          submitting: false,
          submitErrorMsg: err.response.data.server_response,
        });
        return;
      }
      this.setState({
        submitError: true,
        submitting: false,
        submitErrorMsg: 'Oops! Something went wrong!',
      });
    });
  }

  render() {
    const {
      param, loading, error, regionalLibrary, actionSuccessMsg,
      rowsPerPage, page, submitting, submitError, regionalLibraryCount,
      hasNext, hasPrevious, newParams, updatingRegionalPrices,
      action, showPerformingActionModal, selectedRegLibProdToPerformAction,
      syncType, reason, regions, submitErrorMsg, selectedRecordForEdit,
      addingNew, syncTypeForNew, mrpForNew, submittngNewErrorMsg,
      sellingPriceForNew, regionForNew, submittingNewStatus,
      productInfo,
    } = this.state;

    const { userPermission } = this.props;

    const index = regions.findIndex((item) => item.region === 'INDIA');
    if (index !== -1) {
      regions.splice(index, 1);
    }

    const headers = [
      {
        key: 'regionalProductMapId',
        displayText: 'Regional Product Map Id',
      },
      {
        key: 'mrp',
        displayText: this.canEdit ? 'MRP. (Double Click to Edit)' : 'MRP.',
        renderer: (data) => (
          <Col
            xs="auto"
            onClick={() => this.onUpdate(data)}
          >
            {
              this.canEdit
              && !updatingRegionalPrices
              && selectedRecordForEdit
              && selectedRecordForEdit.regionalProductMapId === data.regionalProductMapId
                ? (
                  <input
                    type="text"
                    className={`form-control fs-01 ${data.spError ? 'border-danger' : ''}`}
                    name="mrp"
                    placeholder="MRP"
                    value={(data.updatedValues && data.updatedValues.mrp) || ''}
                    onChange={this.onUpdateFields}
                  />
                ) : (
                  <span>{data.isEdited ? data.updatedValues.mrp : data.mrp}</span>
                )
            }
          </Col>
        ),
      },
      {
        key: 'sellingPrice',
        displayText: this.canEdit ? 'Selling Price (Double Click to Edit)' : 'Selling Price',
        renderer: (data) => (
          <Col
            xs="auto"
            onClick={() => this.onUpdate(data)}
          >
            {
              this.canEdit
              && !updatingRegionalPrices
              && selectedRecordForEdit
              && selectedRecordForEdit.regionalProductMapId === data.regionalProductMapId
                ? (
                  <input
                    type="text"
                    className={`form-control fs-01 ${data.spError ? 'border-danger' : ''}`}
                    name="sellingPrice"
                    placeholder="Selling Price"
                    value={(data.updatedValues && data.updatedValues.sellingPrice) || ''}
                    onChange={this.onUpdateFields}
                  />
                ) : (
                  <span>{data.isEdited ? data.updatedValues.sellingPrice : data.sellingPrice}</span>
                )
            }
          </Col>
        ),
      },
      {
        key: 'region',
        displayText: 'Region',
      },
      {
        key: 'lastUpdatedBy',
        displayText: 'Last Updated By',
      },
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => (
          <>{dateString(data.createdOn)}</>
        ),
      },
      {
        key: 'modifiedOn',
        displayText: 'Modified On',
        renderer: (data) => (
          <>{dateString(data.modifiedOn)}</>
        ),
      },
      {
        key: 'previousJobDetail',
        displayText: 'Prev. Job Detail (Clickable Links)',
        renderer: (data) => (
          <>
            {
              (data.previousJobDetail).map((item) => (
                <div>
                  <a
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pb-2"
                    style={{
                      color: item.color,
                    }}
                  >
                    {item.text}
                  </a>
                </div>
              ))
            }
          </>
        ),
      },
      ...(this.canEdit) ? [
        {
          key: 'remove',
          displayText: 'Remove',
          renderer: (data) => (
            <Button
              variant="link"
              onClick={() => {
                this.setState({
                  selectedRegLibProdToPerformAction: data,
                  showPerformingActionModal: true,
                  action: 'ARCHIVE_REGIONAL_PRICE',
                  mrp: data.mrp,
                  sellingPrice: data.sellingPrice,
                  region: data.region,
                });
              }}
              className="py-0"
            >
              <Svg
                svg="trash"
                width="1.2rem"
              />
            </Button>
          ),
        },
        {
          key: 'spError',
          displayText: 'Form Errors',
          renderer: (data) => (
            <div
              className={data.spError ? 'text-danger' : 'text-medium'}
            >
              {data.spError ? '* Selling Price should be less than MRP.' : '---'}
            </div>
          ),
        },
      ] : [],
    ];

    const filterConfForMultiSelect = [
      {
        key: 'regionIn',
        displayText: 'Region',
        options: ((regions || []).map((item) => ({
          label: item.region,
          value: item.region,
        }))),
      },
    ];

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        <CustomModal
          show={showPerformingActionModal}
          title=""
          onHide={() => {
            this.setState({
              showPerformingActionModal: false,
              submitError: false,
              syncType: 'IGNORE',
              reason: '',
              action: '',
              spError: false,
            });
          }}
          autoSize
          closeButton
          body={(
            <Container>
              {
                selectedRegLibProdToPerformAction
                && (action === 'ARCHIVE_REGIONAL_PRICE')
                && (
                  <>
                    <Row
                      className="pt-3"
                    >
                      <Col
                        xs={24}
                      >
                        <b>Sync Trigger At:</b>
                      </Col>
                      <Col
                        className="px-3"
                        xs={24}
                      >
                        <select
                          name="syncType"
                          id="syncType"
                          className="form-control"
                          value={syncType}
                          onChange={this.handleChange}
                        >
                          <option value="IGNORE">IGNORE</option>
                          {
                            userPermission.includes(
                              permission.LIBRARY_PRODUCT_REGIONAL_PRICE_WRITE_RETROACTIVE,
                            ) && (
                              <>
                                <option value="NOW">NOW</option>
                                <option value="NIGHT">NIGHT</option>
                              </>
                            )
                          }
                        </select>
                      </Col>
                    </Row>

                    <Row
                      className="pt-3"
                    >
                      <Col
                        xs={24}
                      >
                        <b>Reason:</b>
                      </Col>
                      <Col
                        xs={24}
                        className="px-3"
                      >
                        <textarea
                          rows="3"
                          className="form-control"
                          name="reason"
                          value={reason}
                          onChange={this.handleChange}
                        />
                      </Col>
                    </Row>
                  </>
                )
              }
              <Row>
                <Col
                  xs={24}
                  className="text-center py-4 d-flex flex-row-reverse align-items-center"
                >
                  <Button
                    variant="primary"
                    className="px-3 py-2 fs-01 ml-2"
                    disabled={submitting}
                    onClick={this.performAction}
                  >
                    {
                      submitting && (
                        <Spinner
                          variant="light"
                          size="sm"
                          animation="border"
                        />
                      )
                    }
                    &nbsp;&nbsp;&nbsp;Submit&nbsp;&nbsp;&nbsp;
                  </Button>
                  <Button
                    variant="link"
                    className="text-primary font-weight-bold px-3 py-2 fs-01"
                    disabled={submitting}
                    onClick={() => {
                      this.setState({
                        showPerformingActionModal: false,
                        submitError: false,
                        syncType: 'IGNORE',
                        reason: '',
                        action: '',
                        spError: false,
                      });
                    }}
                  >
                    {
                      submitting && (
                        <Spinner
                          variant="light"
                          size="sm"
                          animation="border"
                        />
                      )
                    }
                    &nbsp;&nbsp;&nbsp;CANCEL&nbsp;&nbsp;&nbsp;
                  </Button>
                </Col>
                {submitError && (
                  <Col
                    xs="auto"
                    className="text-center text-danger pt-3"
                  >
                    <b>
                      {
                        submitErrorMsg || 'Oops Something went Wrong!!'
                      }
                    </b>
                  </Col>
                )}
              </Row>
            </Container>
          )}
        />
        <Row
          className="h-100 flex-column"
        >
          <Col
            className="flex-grow-0"
          >
            <Row
              className="align-items-center py-2"
            >
              {
                filterConfForMultiSelect.map((item) => (
                  <Col
                    xs="auto"
                    className="px-2 pt-2"
                  >
                    <Select
                      id={item.key}
                      placeholder={item.displayText}
                      onChange={(selectedItems) => {
                        const selectedItemsString = selectedItems.reduce(
                          (acc, selItem, i) => acc.concat(i === 0 ? '' : ',', selItem.value), '',
                        );
                        this.handleDropdownChange({ [item.key]: selectedItemsString });
                      }}
                      selectedVal={newParams[item.key]}
                      options={item.options}
                      isMulti
                      value={newParams[item.key]
                        ? (newParams[item.key].split(','))
                          .map((val) => ({ label: val, value: val }))
                        : []}
                      className="minw-150p"
                    />
                  </Col>
                ))
              }
            </Row>
            <Row className="p-2">
              <Col
                xs={12}
                md={8}
                lg={4}
                className="p-2 border mr-2"
              >
                <div className="text-truncate fs-0 font-weight-bold">{productInfo.id || param.libraryProductId}</div>
                <div className="fs-01">Product Id:</div>
              </Col>
              <Col
                xs={12}
                md={8}
                lg={4}
                className="p-2 border"
              >
                <div className="text-truncate fs-0 font-weight-bold">{productInfo.displayName || param.lpname}</div>
                <div className="fs-01">Product Name:</div>
              </Col>
            </Row>
            <Row className="py-2 d-flex flex-row-reverse">
              <Col
                xs="auto"
                className="px-2"
              >
                <Button
                  variant="primary"
                  className="fs-01 font-weight-bold"
                  onClick={this.resetfilters}
                >
                  RESET FILTERS
                </Button>
              </Col>
              <Col
                xs="auto"
                className="px-2"
              >
                <Button
                  variant="success"
                  className="fs-01 font-weight-bold"
                  onClick={this.applyFilters}
                >
                  APPLY FILTERS
                </Button>
              </Col>
              {
                actionSuccessMsg && (
                  <Col
                    xs="auto"
                    className="px-2 mr-auto"
                  >
                    <div className="text-success">
                      Action Performed SuccessFully!!
                    </div>
                  </Col>
                )
              }
              {
                this.canEdit && (
                  <Col
                    xs="auto"
                    className="px-2 mr-auto"
                  >
                    <Button
                      variant="primary"
                      className="fs-01"
                      onClick={() => {
                        this.setState({
                          addingNew: true,
                          syncTypeForNew: 'IGNORE',
                          regionForNew: '',
                          mrpForNew: '',
                          sellingPriceForNew: '',
                        });
                      }}
                    >
                      ADD NEW REGIONAL PRICE
                    </Button>
                  </Col>
                )
              }
            </Row>
            {
              addingNew && (
                <Row className="py-2 mb-4 d-flex align-items-center ">
                  <Col xs={24} md={12} lg={4}>
                    <input
                      type="number"
                      className="form-control"
                      name="mrpForNew"
                      placeholder="MRP"
                      value={mrpForNew}
                      disabled={submittingNewStatus === 'submitting'}
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col xs={24} md={12} lg={4}>
                    <input
                      type="number"
                      className="form-control"
                      name="sellingPriceForNew"
                      placeholder="Selling Price"
                      value={sellingPriceForNew}
                      disabled={submittingNewStatus === 'submitting'}
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col xs={24} md={12} lg={4}>
                    <select
                      name="regionForNew"
                      id="regionForNew"
                      className="form-control"
                      value={regionForNew}
                      onChange={this.handleChange}
                      disabled={submittingNewStatus === 'submitting'}
                    >
                      <option value="">--Select Region--</option>
                      {
                          (regions || []).map((item) => (
                            <option value={item.region}>{item.region}</option>
                          ))
                        }
                    </select>
                  </Col>
                  <Col xs={24} md={12} lg={4}>
                    <select
                      name="syncTypeForNew"
                      id="syncTypeForNew"
                      className="form-control"
                      value={syncTypeForNew}
                      onChange={this.handleChange}
                      disabled={submittingNewStatus === 'submitting'}
                    >
                      <option value="IGNORE">IGNORE</option>
                      {
                        userPermission.includes(
                          permission.LIBRARY_PRODUCT_REGIONAL_PRICE_WRITE_RETROACTIVE,
                        ) && (
                          <>
                            <option value="NOW">NOW</option>
                            <option value="NIGHT">NIGHT</option>
                          </>
                        )
                      }
                    </select>
                  </Col>
                  <Col
                    xs="auto"
                    className="px-2"
                  >
                    <Button
                      variant="link"
                      className="fs-01 font-weight-bold text-success"
                      onClick={this.addNewRegionalPrice}
                      disabled={submittingNewStatus === 'submitting'}
                    >
                      {
                        submittingNewStatus === 'submitting' && (
                          <Spinner
                            animation="border"
                            variant="medium"
                            className="mr-2"
                            size="sm"
                          />
                        )
                      }
                      SAVE
                    </Button>
                  </Col>
                  <Col
                    xs="auto"
                    className="px-2"
                  >
                    <Button
                      variant="link"
                      className="fs-01 font-weight-bold text-primary"
                      onClick={this.cancelAddNew}
                      disabled={submittingNewStatus === 'submitting'}
                    >
                      CANCEL
                    </Button>
                  </Col>
                  {
                    submittingNewStatus === 'success' && (
                      <Col
                        xs="auto"
                        className="px-2"
                      >
                        <div className="text-success">
                          Added Successfully!!
                        </div>
                      </Col>
                    )
                  }
                  {
                    submittingNewStatus === 'error'
                    && submittngNewErrorMsg
                    && (
                      <Col
                        xs="auto"
                        className="px-2 py-2"
                      >
                        <div className="text-danger">
                          {submittngNewErrorMsg}
                        </div>
                      </Col>
                    )
                  }
                </Row>
              )
            }
          </Col>
          {
            (loading || error) && (
              <div
                className="pt-3 text-center"
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                ) : (
                  <>
                    <span
                      className="text-danger"
                    >
                          Something Went Wrong
                    </span>
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => this.retry()}
                      >
                            Retry
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )
          }
          {
            (!loading && !error) && (
              <Col
                className="px-0 flex-grow-1 overflow-y"
              >
                <CustomTable
                  headers={headers}
                  content={regionalLibrary}
                  keyField="groupId"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={regionalLibraryCount}
                  hasPrev={hasPrevious}
                  hasNext={hasNext}
                  isPaginated={false}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
                {
                  this.canEdit
                  && regionalLibrary.length > 0
                  && (
                    <Col
                      xs={24}
                      className="px-4 flex-grow-0"
                    >
                      <Row className="py-2 d-flex flex-row-reverse">
                        <Col
                          xs="auto"
                          className="px-2"
                        >
                          <Button
                            variant="primary"
                            className="fs-01 font-weight-bold"
                            onClick={this.updateRegionalPrices}
                            disabled={updatingRegionalPrices}
                          >
                            UPDATE
                          </Button>
                        </Col>
                        <CustomDropdown
                          item={{
                            key: 'syncType',
                            displayText: 'Sync Type',
                            options: [
                              {
                                label: 'IGNORE',
                                value: 'IGNORE',
                              },
                              ...userPermission.includes(
                                permission.LIBRARY_PRODUCT_REGIONAL_PRICE_WRITE_RETROACTIVE,
                              ) ? [
                                  {
                                    label: 'NOW',
                                    value: 'NOW',
                                  },
                                  {
                                    label: 'NIGHT',
                                    value: 'NIGHT',
                                  },
                                ] : [],
                            ],
                          }}
                          onChange={(data) => {
                            this.handleDropdownChange(data);
                          }}
                          selectedVal={syncType}
                        />
                        {
                          actionSuccessMsg && (
                            <Col
                              xs="auto"
                              className="px-2"
                            >
                              <div className="text-success">
                                Updated Recorded SuccessFully!!
                              </div>
                            </Col>
                          )
                        }
                      </Row>
                    </Col>
                  )
                }
              </Col>
            )
          }
        </Row>
      </Container>
    );
  }
}

LibraryProductRegionalPrice.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default LibraryProductRegionalPrice;
