/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Spinner, Form, Button,
} from 'react-bootstrap';
import QueryString from 'query-string';
import Axios from 'axios';
import { priceUpdate, storeCategory } from '../../assets/api/axios';
import { CustomModal, CustomTable, ErrorHandler } from '../../component/common';
import { dateString } from '../../utilities/Utils';
import { ProcessingStatus } from '../../component/derived/table-list';
import Permission from '../../access&permissions/permission';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  const {
    t = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    l,
    p,
    t,
  });
};

class PriceUpdate extends React.Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    const { userPermission } = props;
    this.canIEdit = userPermission
      && userPermission.includes(Permission.PRICE_UPDATE_WRITE);
    this.state = {
      storeCategories: [],
      storeIds: '',
      productIds: '',
      storeTypeId: '',
      submitting: false,
      confirmation: false,
      response: null,
      selectedTab: !this.canIEdit ? 'list' : param.t || 'create',
      rowsPerPage: param.l,
      page: param.p,
      error: false,
      loading: true,
      priceUpdateData: null,
      selectedPriceUpdateRow: null,
      param,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    const { selectedTab } = this.state;
    this.getStoreType(3);
    if (selectedTab === 'list') {
      this.handleLoad();
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param, selectedTab } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
        && (selectedTab === 'list')
      ))
    ) {
      this.handleLoad({ ...newParam, t: selectedTab });
    }
  }

  getStoreType = (retry = 3) => {
    storeCategory('GET')
      .then((res) => {
        this.setState({
          storeCategories: res.data.results,
        });
      })
      .catch(() => {
        if (retry - 1) {
          this.getStoreType(retry - 1);
        }
      });
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p,
    } = newParam;
    const offset = (p - 1) * l;
    priceUpdate(
      'get',
      null,
      {
        offset: `${offset}`,
        limit: l,
      },
      this.source.token,
    ).then((res) => {
      this.setState({
        priceUpdateData: { ...res.data },
        loading: false,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (
          Object.keys(newParam).find((key) => (newParam[key] !== param[key]))
        ) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const {
      l, p,
    } = param;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source;
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }


  handleOnChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  updatePrice = () => {
    this.setState({
      submitting: true,
    });

    const { storeIds, productIds, storeTypeId } = this.state;

    priceUpdate(
      'POST',
      {
        storeIds,
        productIds,
        storeTypeId,
      },
    ).then((res) => {
      this.setState({
        response: {
          type: 'SUCCESS',
          message: res.data.message,
        },
        submitting: false,
        confirmation: false,
        productIds: '',
        storeIds: '',
        storeTypeId: '',
      });
    }).catch((error) => {
      this.setState({
        submitting: false,
        confirmation: false,
        response: {
          type: 'ERROR',
          message: (error && error.response && error.response.data && error.response.data.message)
            ? error.response.data.message : 'Oops Something went wrong!!',
        },
      });
    });
  }

  handleChangeTab = (currentTab) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param, selectedTab } = this.state;
    const newParam = { ...param, t: currentTab };
    if (currentTab !== selectedTab) {
      this.setState({
        selectedTab: currentTab,
      }, () => {
        history.push({
          path: pathname,
          search: QueryString.stringify(newParam),
        });
      });
    }
  }

  render() {
    const {
      submitting, storeIds, productIds,
      confirmation, storeTypeId, response,
      selectedTab, loading, error, priceUpdateData,
      rowsPerPage, page, param, selectedPriceUpdateRow,
      storeCategories,
    } = this.state;

    const headers = [
      {
        key: '_id',
        displayText: 'Id',
        renderer: (data) => (
          <Button
            variant="link"
            className="p-0 fs-01"
            onClick={() => {
              this.setState({
                selectedPriceUpdateRow: data,
              });
            }}
          >
            {data._id}
          </Button>
        ),
      },
      {
        key: 'status',
        displayText: 'Status',
      },
      {
        key: 'epoch',
        displayText: 'Created On',
        renderer: (data) => (
          <div>
            {
              data.epoch
                ? dateString(data.epoch * 1000)
                : 'NA'
            }
          </div>
        ),
      },
    ];

    let tableShowCase = null;
    if (!priceUpdateData && loading) {
      tableShowCase = (
        <Row>
          <Col
            xs={24}
            className="text-center pt-3"
          >
            <Spinner
              animation="border"
              variant="primary"
            />
          </Col>
        </Row>
      );
    }
    if (!priceUpdateData && !loading && error) {
      tableShowCase = (
        <Row>
          <Col
            xs={24}
            className="text-center pt-3"
          >
            <ErrorHandler
              retryLogic={() => this.retry()}
            />
          </Col>
        </Row>
      );
    }

    if (priceUpdateData) {
      tableShowCase = (
        <Row>
          <Col
            xs={24}
            className="px-0 table-section"
          >
            <ProcessingStatus
              show={loading || error}
              loading={loading}
              error={error}
              onRetry={() => this.retry()}
              onCancel={this.onCancel}
            />
            <CustomTable
              headers={headers}
              content={priceUpdateData.results}
              keyField="_id"
              l={param.l}
              p={param.p}
              rowsPerPage={rowsPerPage}
              page={page}
              totalItems={priceUpdateData.count}
              hasPrev={priceUpdateData.hasPrevious}
              hasNext={priceUpdateData.hasNext}
              onNext={this.onNext}
              onPrev={this.onPrev}
              onSubmitPage={this.onSubmitPage}
              onSubmitRowsPerPage={this.onSubmitRowsPerPage}
              updateRowsPageInput={this.handleRowsPageInput}
            />
          </Col>
        </Row>
      );
    }

    const confirmationModalBody = (
      <Col>
        <Container
          className="justify-content-center"
        >
          <Row
            className="mx-auto mt-4"
          >
            <Col
              xs={24}
              className="text-center fs-3"
            >
              Are you sure you want to&nbsp;
              <span
                className="text-success"
              >
                UPDATE
              </span>
              &nbsp;the price of products ?
            </Col>
          </Row>
          <Row
            className="mt-4"
          >
            <Col
              xs={12}
              className="text-right"
            >
              <Button
                variant="outline-primary"
                value="cancel"
                className="py-1 px-3 fs-2"
                disabled={submitting}
                onClick={() => {
                  this.setState({
                    confirmation: false,
                  });
                }}
              >
                CANCEL
              </Button>
            </Col>
            <Col
              xs={12}
              className="text-left"
            >
              <Button
                variant="outline-success"
                value="submit"
                className="py-1 px-3 fs-2 lg active"
                disabled={submitting}
                onClick={() => {
                  this.setState({
                    response: null,
                  }, this.updatePrice());
                }}
              >
                UPDATE
              </Button>
            </Col>
          </Row>
        </Container>
        {
          submitting
          && (
            <div
              className="w-100 pt-3 d-flex align-items-center justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          )
        }
      </Col>
    );

    return (
      <Container
        fluid
        className="bg-white h-100"
      >
        <CustomModal
          show={confirmation}
          body={confirmationModalBody}
          closeButton
          onHide={() => {
            this.setState({
              confirmation: false,
            });
          }}
        />
        {
          !!selectedPriceUpdateRow && (
            <CustomModal
              show={!!selectedPriceUpdateRow}
              closeButton
              title="Price Update Job Status"
              body={(
                <div
                  className="py-3"
                >
                  {
                    !selectedPriceUpdateRow.storeJobs
                      ? (
                        <div
                          className="text-center text-medium fs-03"
                        >
                          <b>-- NO JOBS FOUND --</b>
                        </div>
                      ) : (
                        <>
                          {Object.keys(selectedPriceUpdateRow.storeJobs).map((key) => (
                            <div
                              className="d-flex border p-1"
                            >
                              <div
                                className="px-3 border-right"
                              >
                                <b>{key}</b>
                              </div>
                              <div
                                className="px-3"
                              >
                                {selectedPriceUpdateRow.storeJobs[key].status}
                              </div>
                            </div>
                          ))}
                        </>
                      )
                  }
                </div>
              )}
              onHide={() => {
                this.setState({
                  selectedPriceUpdateRow: null,
                });
              }}
            />
          )
        }
        <Row>
          <Col
            xs={12}
            className={`text-center border p-2 cursor-pointer ${
              selectedTab === 'create'
                ? 'border-primary text-primary shadow-sm'
                : 'text-medium'
            } ${this.canIEdit ? '' : 'd-none'}`}
            onClick={() => this.handleChangeTab('create')}
          >
            <b>Price Update</b>
          </Col>
          <Col
            xs={12}
            className={`text-center border p-2 cursor-pointer ${
              selectedTab === 'list'
                ? 'border-primary text-primary shadow-sm'
                : 'text-medium'
            }`}
            onClick={() => this.handleChangeTab('list')}
          >
            <b>Price Update Jobs</b>
          </Col>
        </Row>
        {
          selectedTab === 'create'
            ? (
              <Row>
                <Col
                  xs={24}
                >
                  <Form>
                    <Form.Group
                      as={Row}
                      className="py-2"
                    >
                      <Col
                        xs={4}
                      >
                        <Form.Label>
                          Store Ids:
                        </Form.Label>
                      </Col>
                      <Col
                        xs={12}
                      >
                        <Form.Control
                          id="storeIds"
                          type="text"
                          value={storeIds}
                          onChange={(e) => this.handleOnChange(e)}
                          placeholder="Enter Store Ids"
                          autoComplete="off"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="pb-2"
                    >
                      <Col
                        xs={4}
                      >
                        <Form.Label>
                          Product Ids:
                        </Form.Label>
                      </Col>
                      <Col
                        xs={12}
                      >
                        <Form.Control
                          id="productIds"
                          type="text"
                          value={productIds}
                          onChange={(e) => this.handleOnChange(e)}
                          placeholder="Enter Product Ids"
                          autoComplete="off"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="pb-2"
                    >
                      <Col
                        xs={4}
                      >
                        <Form.Label>
                          <span
                            className="text-danger"
                          >
                            *&nbsp;
                          </span>
                          Store Type Id:
                        </Form.Label>
                      </Col>
                      <Col
                        xs={12}
                      >
                        <select
                          id="storeTypeId"
                          className="form-control"
                          value={storeTypeId}
                          onChange={(e) => this.handleOnChange(e)}
                        >
                          {
                            storeCategories.map((category) => (
                              <option
                                key={category.id}
                                value={category.id}
                              >
                                {`${category.id} - ${category.displayName}`}
                              </option>
                            ))
                          }
                          <option value="">NONE</option>
                        </select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column xs={4} />
                      <Col
                        xs={12}
                      >
                        <Button
                          variant="primary"
                          disabled={storeTypeId === ''}
                          onClick={() => {
                            this.setState({
                              confirmation: true,
                            });
                          }}
                        >
                          SUBMIT
                        </Button>
                      </Col>
                    </Form.Group>
                  </Form>
                  {
                    response
                    && (
                      <div
                        className={`${response.type === 'SUCCESS' ? 'text-success' : 'text-danger'} my-4`}
                      >
                        <b>
                          {response.type}
                          :&nbsp;&nbsp;
                          {response.message}
                        </b>
                      </div>
                    )
                  }
                </Col>
              </Row>
            ) : (
              <>
                {tableShowCase}
              </>
            )
        }
      </Container>
    );
  }
}

PriceUpdate.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default PriceUpdate;
