import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, ListGroup, Spinner,
} from 'react-bootstrap';
import Permission from '../access&permissions/permission';

const CustomerLocation = (props) => {
  const {
    selectedAddress, handleSearch, onKeyPress,
    handleChangeAddress, handleSelectCustomer,
    searchText, customers, selectedCustomer, addressList, loader,
    isSearchingCustomer, handleAddNewCustomer, userPermission,
  } = props;

  const canIEdit = userPermission && userPermission.includes(Permission.PLACE_ORDER_WRITE);
  let activeAddressList = [];
  if (addressList.length) {
    activeAddressList = addressList.filter((item) => item.status !== 'ARCHIVED');
  }

  return (
    <Container
      fluid
      className="bg-light"
    >
      <Row>
        <Col
          xs={24}
          className="pt-3"
        >
          <input
            className="p-4 h-50 w-100 shadow-sm form-control text-primary"
            value={searchText}
            type="search"
            onChange={handleSearch}
            onKeyPress={onKeyPress}
            placeholder="Search Customer by Name/Phone number"
            autoComplete="off"
            disabled={!canIEdit}
          />
        </Col>
      </Row>

      {customers.length > 0
        && (
          <Row
            className="position-absolute p-3 m-0 w-50 shadow-lg bg-white rounded search-customer-list"
          >
            <Col>
              {customers.map((customerInfo) => (
                <ListGroup
                  key={customerInfo.id}
                  onClick={() => handleSelectCustomer(customerInfo)}
                  variant="flush"
                >
                  <ListGroup.Item
                    action
                    variant="light"
                  >
                    <b>{customerInfo.phoneNumber}</b>
                    <b className={`${customerInfo.phoneNumber ? 'pl-3' : 'pl-0'}`}>{customerInfo.name}</b>
                  </ListGroup.Item>
                </ListGroup>
              ))}
            </Col>
          </Row>
        )}

      {
        (searchText && !customers.length && !isSearchingCustomer) && (
          <Row
            className="position-absolute p-3 m-0 shadow-lg bg-white rounded search-customer-list"
          >
            <Col
              xs={24}
              className="pb-2 fs-1 text-medium"
            >
              No Customer Found !!!
            </Col>
            <Col
              xs={24}
            >
              <Button
                variant="primary"
                className="py-1 px-3"
                onClick={() => {
                  handleAddNewCustomer('customer');
                }}
              >
                + Add Customer
              </Button>
            </Col>
          </Row>
        )
      }

      {selectedCustomer
        ? (
          <>
            <Row className="m-0 py-3 shadow-sm bg-white rounded">
              <Col className="">
                <b>{selectedCustomer.phoneNumber}</b>
                <b className={`${selectedCustomer.phoneNumber ? 'pl-3' : 'pl-0'}`}>{selectedCustomer.name}</b>
              </Col>
            </Row>
            {selectedAddress
              ? (
                <>
                  <Row className="mt-3">
                    <Col>
                      <h5 className="m-0 p-0">Selected Address</h5>
                    </Col>
                  </Row>
                  <Row className="bg-light rounded my-3 ml-1 mr-3">
                    <Col xs={24} sm={12} className="p-3 shadow-sm bg-white">
                      <b>{selectedAddress.addressType}</b>
                      <span className="ml-3">
                        <Button
                          onClick={() => handleChangeAddress()}
                          variant="outline-info"
                        >
                          Change
                        </Button>
                      </span>
                      <Row>
                        <Col>
                          <p className="m-0 p-0">{selectedAddress.addressText}</p>
                          <span>{selectedAddress.buildingName}</span>
                          <span className="pl-1">{selectedAddress.flatNumber}</span>
                          <span className="pl-1">{selectedAddress.landmark}</span>
                          <span className="pl-1">{selectedAddress.streetName}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )
              : (
                <>
                  {!loader
                    ? (
                      <>
                        {activeAddressList.length > 0
                          ? (
                            <>
                              <Row className="mt-3 ml-1">
                                <h5 className="m-0 p-0">
                                  Select Address
                                </h5>
                              </Row>
                              <Row className="w-100 rounded my-3 ml-1 mr-3">
                                {activeAddressList.map((address) => (
                                  <Col
                                    sm={8}
                                    className="p-3 shadow-sm bg-white address-list"
                                    key={address.locationId}
                                    onClick={() => handleChangeAddress(address)}
                                  >
                                    <b>{address.addressType}</b>
                                    <Row>
                                      <Col>
                                        <p className="m-0 p-0">{address.addressText}</p>
                                        <span>{address.buildingName}</span>
                                        <span className="pl-1">{address.flatNumber}</span>
                                        <span className="pl-1">{address.landmark}</span>
                                        <span className="pl-1">{address.streetName}</span>
                                      </Col>
                                    </Row>
                                  </Col>
                                ))}
                              </Row>
                            </>
                          ) : (
                            <Row
                              className="mt-3 ml-1 mb-3 p-3 w-100 text-danger text-center shadow-sm bg-white rounded"
                            >
                              <Col
                                xs={24}
                                className="pb-3"
                              >
                                <b>No Address Found !!!</b>
                              </Col>
                              <Col
                                xs={24}
                              >
                                <Button
                                  variant="primary"
                                  className="py-1 px-3"
                                  onClick={() => {
                                    handleAddNewCustomer('address');
                                  }}
                                >
                                  + Add Address
                                </Button>
                              </Col>
                            </Row>
                          )}
                      </>
                    ) : (
                      <Row
                        className="mt-3 ml-1 mb-3 w-100 p-3 justify-content-center shadow-sm bg-white rounded"
                      >
                        <Spinner
                          animation="border"
                          variant="info"
                        />
                      </Row>
                    )}
                </>
              )}
          </>
        ) : <Row>{null}</Row>}
    </Container>
  );
};

CustomerLocation.defaultProps = {
  selectedAddress: null,
  selectedCustomer: null,
};

CustomerLocation.propTypes = {
  selectedAddress: PropTypes.shape({
    addressType: PropTypes.string,
    addressText: PropTypes.string,
    buildingName: PropTypes.string,
    flatNumber: PropTypes.string,
    landmark: PropTypes.string,
    streetName: PropTypes.string,
  }),
  selectedCustomer: PropTypes.shape({
    phoneNumber: PropTypes.string,
    name: PropTypes.string,
  }),
  isSearchingCustomer: PropTypes.bool.isRequired,
  handleAddNewCustomer: PropTypes.func.isRequired,
  addressList: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChangeAddress: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleSelectCustomer: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  customers: PropTypes.arrayOf(PropTypes.object).isRequired,
  loader: PropTypes.bool.isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default CustomerLocation;
