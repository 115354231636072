import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Button, Container, Spinner, Row, Col,
  InputGroup, Form,
} from 'react-bootstrap';
import { shopLogoReviews } from '../../assets/api/axios';
import { CustomDropdown, CustomTable, CustomModal } from '../../component/common';
import Svg from '../../component/common/Svg';
import Constant from '../../utilities/Constant';
import Permission from '../../access&permissions/permission';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let { l, p } = param;
  const { sid = '', status = '' } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    l,
    p,
    sid,
    status,
  });
};

class ShopLogoReview extends Component {
  constructor(props) {
    super(props);
    const { history } = this.props;
    const params = getQueryParams(history.location.search);
    const { userPermission } = props;
    this.canIEdit = userPermission
      && userPermission.includes(Permission.SHOP_LOGO_REVIEW_WRITE);
    this.state = {
      ShopLogoReviews: null,
      storeId: params.sid,
      rowsPerPage: params.l,
      page: params.p,
      params,
      loading: true,
      error: false,
      selectedShopLogoReview: null,
      submittingStatus: false,
      submittingStatusError: false,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount() {
    this.handleLoad();
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { params } = this.state;
    const newParams = { ...params, ...data };
    const offset = (newParams.p - 1) * newParams.l;
    const { l, status, sid } = newParams;
    shopLogoReviews(
      'get',
      {
        offset: `${offset}`,
        limit: l,
        status,
        storeCodes: sid,
      },
      null,
      this.source.token,
    ).then((res) => {
      this.setState({
        ShopLogoReviews: res.data,
        status: newParams.status,
        rowsPerPage: newParams.l,
        page: newParams.p,
        params: newParams,
        loading: false,
      }, () => {
        if (Object.keys(newParams).find((key) => newParams[key] !== params[key])) {
          Object.keys(newParams).forEach((key) => {
            if (!newParams[key]) {
              delete newParams[key];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParams),
          });
        }
      });
    }).catch(() => {
      this.setState({
        error: true,
      });
    });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad(newParams);
      });
    };
  }

  handleRequestProcessing = (data) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      loading: true,
      error: false,
    }, () => {
      this.handleLoad(data);
    });
  }

  onNext = () => {
    const { params } = this.state;
    this.handleRequestProcessing({ p: params.p + 1 });
  }

  onPrev = () => {
    const { params } = this.state;
    this.handleRequestProcessing({ p: params.p - 1 });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onSubmitPage = () => {
    const { params, page } = this.state;
    if (params.p !== page) {
      this.handleRequestProcessing({ p: page });
    }
  }

  onSubmitRowsPerPage = () => {
    const { params, rowsPerPage } = this.state;
    if (params.l !== rowsPerPage) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onSubmitStoreId = () => {
    const { storeId, params } = this.state;
    if (storeId !== params.sid) {
      this.handleRequestProcessing({ sid: storeId });
    }
  }

  handlePatchStatus = () => {
    const { selectedShopLogoReview } = this.state;
    shopLogoReviews(
      'PATCH',
      {},
      {
        storeCodes: selectedShopLogoReview.storeCode,
        status: selectedShopLogoReview.status,
      },
      this.source.token,
    ).then(() => {
      this.setState({
        selectedShopLogoReview: null,
        submittingStatus: false,
        loading: true,
      }, () => {
        this.handleLoad();
      });
    }).catch(() => {
      this.setState({
        submittingStatus: false,
        submittingStatusError: true,
      });
    });
  }

  render() {
    const {
      ShopLogoReviews, rowsPerPage, page, params,
      loading, error, status, storeId, selectedShopLogoReview,
      submittingStatus, submittingStatusError,
    } = this.state;

    const statusContent = {
      key: 'status',
      displayText: 'Status',
      options: [
        {
          label: 'PENDING',
          value: 'PENDING',
        },
        {
          label: 'APPROVED',
          value: 'APPROVED',
        },
        {
          label: 'DISAPPROVED',
          value: 'DISAPPROVED',
        },
      ],
    };

    const headers = [
      {
        key: 'storeCode',
        displayText: 'Store Id',
        renderer: (data) => (
          <Link
            to={`/retailer/${data.storeCode}`}
            className="text-primary"
          >
            {data.storeCode}
          </Link>
        ),
      },
      {
        key: 'storeName',
        displayText: 'Shop Name',
      },
      {
        key: 'shopLogo',
        displayText: 'Shop Logo',
        renderer: (data) => (
          <img alt="" src={data.shopLogo} height="64" width="64" />
        ),
      },
      {
        key: 'shopLogo',
        displayText: 'Shop Logo URL',
        renderer: (data) => (
          <div>
            {data.shopLogo ? (
              <a
                href={data.shopLogo}
                target="_blank"
                rel="noopener noreferrer"
              >
                VIEW
              </a>
            ) : 'NA'}
          </div>
        ),
      },
      ...this.canIEdit ? [{
        key: 'status',
        displayText: 'Status',
        renderer: (data) => (
          <select
            value={data.status}
            className="form-control fs-01"
            onChange={(event) => {
              if (event.target.value !== data.status) {
                this.setState({
                  selectedShopLogoReview: {
                    ...data,
                    status: event.target.value,
                  },
                });
              }
            }}
          >
            {data.status === 'PENDING' && (
              <option value="PENDING">PENDING</option>
            )}
            <option value="APPROVED">APPROVED</option>
            <option value="DISAPPROVED">DISAPPROVED</option>
          </select>
        ),
      }] : [],
    ];

    const confirmationBody = (
      <Container
        fluid
      >
        {selectedShopLogoReview && (
        <Row>
          <Col
            xs={24}
            className="text-center pt-3"
          >
            <span
              className="fs-3"
            >
              Are you sure you want to&nbsp;
              <b>
                {selectedShopLogoReview.status === 'APPROVED' ? ('APPROVE') : ('DISAPPROVE')}
              </b>
              &nbsp;
              this logo ?
            </span>
          </Col>
          <Col
            xs={24}
            className="text-center py-3"
          >
            <Button
              variant="outline-primary"
              className="py-1 px-3"
              onClick={() => {
                this.setState({
                  selectedShopLogoReview: null,
                  submittingStatusError: false,
                });
              }}
              disabled={submittingStatus}
            >
              Not Now
            </Button>
            <span
              className="pl-3"
            >
              <Button
                variant={selectedShopLogoReview.status === 'APPROVED' ? 'success' : 'danger'}
                className="py-1 px-3"
                onClick={() => {
                  this.setState({
                    submittingStatus: true,
                    submittingStatusError: false,
                  }, () => {
                    this.handlePatchStatus();
                  });
                }}
                disabled={submittingStatus}
              >
                Submit
              </Button>
            </span>
          </Col>
        </Row>
        )}
        {
          selectedShopLogoReview && submittingStatus && (
            <div
              className="pt-3 text-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          )
        }
        {
          selectedShopLogoReview && submittingStatusError && (
            <div
              className="pt-3 text-center"
            >
              <span
                className="text-danger"
              >
                Something Went Wrong. Please try again.
              </span>
            </div>
          )
        }
      </Container>
    );

    if (loading) {
      return (
        <div
          className="pt-3 text-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (error) {
      return (
        <div
          className="pt-3 text-center"
        >
          <span
            className="text-danger"
          >
            Something Went Wrong
          </span>
          <div>
            <Button
              variant="primary"
              onClick={() => this.retry()}
            >
              Retry
            </Button>
          </div>
        </div>
      );
    }

    return (
      <Container
        fluid
        className="bg-white"
      >
        <CustomModal
          show={!!selectedShopLogoReview}
          body={confirmationBody}
          closeButton
          onHide={() => {
            this.setState({
              selectedShopLogoReview: null,
              submittingStatus: false,
              submittingStatusError: false,
            });
          }}
        />
        <Row
          fluid
          className="p-2"
        >
          <Col
            xs="auto"
            className="px-0"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Store Id"
                name="storeId"
                className="fs-01 rounded-0"
                value={storeId}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.onSubmitStoreId();
                  }
                }}
              />
            </InputGroup>
          </Col>
          <Col
            xs="auto"
            className="pl-2 pr-0"
          >
            <CustomDropdown
              item={statusContent}
              closeButton
              onChange={this.handleLoad}
              selectedVal={status}
            />
          </Col>
        </Row>
        <Row
          fluid
        >
          {
            ShopLogoReviews && (
              <CustomTable
                keyField="id"
                headers={headers}
                content={ShopLogoReviews.results}
                isPaginated
                totalItems={ShopLogoReviews.count}
                hasPrev={ShopLogoReviews.hasPrevious}
                hasNext={ShopLogoReviews.hasNext}
                l={params.l}
                p={params.p}
                rowsPerPage={rowsPerPage}
                page={page}
                onNext={this.onNext}
                onPrev={this.onPrev}
                updateRowsPageInput={this.handleRowsPageInput}
                onSubmitPage={this.onSubmitPage}
                onSubmitRowsPerPage={this.onSubmitRowsPerPage}
              />
            )
          }
        </Row>
      </Container>
    );
  }
}

ShopLogoReview.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default ShopLogoReview;
