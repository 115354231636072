import React, { Component } from 'react';
import QueryString from 'query-string';
import Axios from 'axios';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import { queryTracker } from '../../assets/api/axios';
import '../../assets/scss/Coupons/CouponTracker.scss';
import { CustomDropdown, CustomTable } from '../../component/common';
import { getLastQueryTypeQueried, setLastQueryTypeQueried } from '../../utilities/Storage';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  return (param);
};

class QueryTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialLoad: true,
      isSubmitting: false,
      isError: false,
      errorMsg: '',
      queryResults: null,
      page: 1,
      rowsPerPage: 10,
      param: {
        l: 10,
        p: 1,
      },
      queryTypes: [],
      selectedQueryType: null,
      inputFieldValues: {},
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.loadQueryTypes();
  }

  loadQueryTypes = () => {
    queryTracker(
      'GET',
      {
        schemaOnly: true,
      },
      this.source.token,
    ).then((res) => {
      const { history } = this.props;
      let selectedQueryType = null;
      let inputFieldValues = {};
      const params = getQueryParams(history.location.search);
      if ('queryType' in params) {
        const selectedQueryTypeIndex = (res.data.results).findIndex(
          (item) => item.queryType === params.queryType,
        );
        if (selectedQueryTypeIndex !== -1) {
          selectedQueryType = res.data.results[selectedQueryTypeIndex];
          inputFieldValues = selectedQueryType.inputParamsFields.reduce(
            (acc, item) => (
              { ...acc, [item.key]: item.key in params ? params[item.key] : '' }
            ), [],
          );
        }
      } else {
        const previouslySelectedQueryType = getLastQueryTypeQueried();
        if (previouslySelectedQueryType) {
          const selectedQueryTypeIndex = (res.data.results).findIndex(
            (item) => item.queryType === previouslySelectedQueryType,
          );
          if (selectedQueryTypeIndex !== -1) {
            selectedQueryType = res.data.results[selectedQueryTypeIndex];
            inputFieldValues = selectedQueryType.inputParamsFields.reduce((acc, item) => ({ ...acc, [item.key]: '' }), []);
          }
        }
      }
      this.setState({
        selectedQueryType,
        inputFieldValues,
        queryTypes: res.data.results,
        initialLoad: false,
      }, () => {
        if (
          selectedQueryType
          && (selectedQueryType.inputParamsFields.reduce(
            (acc, item) => acc && (item.optional || !!inputFieldValues[item.key]), true,
          ))) {
          this.handleLoad();
        }
      });
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong!! Please Try Again!!';
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.server_response
      ) {
        errorMsg = err.response.data.server_response;
      }
      this.setState({
        errorMsg,
        isSubmitting: false,
        isError: true,
        initialLoad: false,
      });
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const { inputFieldValues } = this.state;
    this.setState({
      inputFieldValues: {
        ...inputFieldValues,
        [name]: value,
      },
    });
  }

  handleLoad = (newParams = {}) => {
    const { history } = this.props;
    const {
      inputFieldValues, param, selectedQueryType,
    } = this.state;
    const { l = 10, p = 1 } = { ...param, ...newParams };
    const offset = (p - 1) * l;
    this.setState({
      isSubmitting: true,
      isError: false,
      queryResults: null,
    });
    queryTracker(
      'GET',
      {
        queryType: selectedQueryType.queryType,
        offset: `${offset}`,
        limit: l,
        ...inputFieldValues,
      },
      this.source.token,
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          isSubmitting: false,
          queryResults: res.data,
          param: { ...param, ...newParams },
          rowsPerPage: l,
          page: p,
        });
      } else {
        throw new Error(res);
      }
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong!! Please Try Again!!';
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.server_response
      ) {
        errorMsg = err.response.data.server_response;
      }
      this.setState({
        errorMsg,
        isSubmitting: false,
        isError: true,
      });
    }).finally(() => {
      setLastQueryTypeQueried(selectedQueryType.queryType);
      const searchParams = {
        queryType: selectedQueryType.queryType,
        ...inputFieldValues,
      };
      history.push({
        pathname: history.location.pathname,
        search: QueryString.stringify(searchParams),
      });
    });
  }

  onNext = () => {
    const { param } = this.state;
    this.handleLoad({ ...param, p: param.p + 1 });
  }

  onPrev = () => {
    const { param } = this.state;
    this.handleLoad({ ...param, p: param.p - 1 });
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleLoad({ ...param, l: rowsPerPage });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleLoad({ ...param, p: page });
    }
  }

  handleQueryTypeChange = (value) => {
    const { queryTypes, selectedQueryType: selectedQuery } = this.state;
    if (!!selectedQuery && selectedQuery.queryType === value) {
      return;
    }
    const selectedQueryType = queryTypes.find((item) => item.queryType === value);
    const inputFieldValues = selectedQueryType.inputParamsFields.reduce((acc, item) => ({ ...acc, [item.key]: '' }), []);
    this.setState({
      selectedQueryType,
      inputFieldValues,
    });
  }

  getDynamicKeyValuePairs = (item) => {
    if (typeof item.value === 'string' || typeof item.value === 'number') {
      return (
        <Col
          xs={24}
          className="px-0 break-word"
        >
          <li key={item.key}>
            <span className="text-medium">{item.key}</span>
            :&nbsp;
            {
              item.href ? (
                <a
                  href={item.href || ''}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: item.colour,
                  }}
                >
                  {item.value}
                </a>
              ) : (
                <span
                  style={{
                    color: item.colour,
                  }}
                >
                  {item.value}
                </span>
              )
            }
          </li>
        </Col>
      );
    }
    return (
      <Col xs={24} className="px-0 break-word">
        <ul>
          <li key={item.key}>
            <span className="text-medium">{item.key}</span>
            :&nbsp;
            <ul className="m-0 p-0">
              {this.getDynamicKeyValuePairs(item.value)}
            </ul>
          </li>
        </ul>
      </Col>
    );
  }

  render() {
    const {
      isSubmitting, isError, queryResults, initialLoad,
      param, rowsPerPage, page, errorMsg,
      queryTypes, selectedQueryType, inputFieldValues,
    } = this.state;

    const headers = queryResults ? queryResults.headers.map((item) => ({
      key: item,
      displayText: item,
      renderer: (data) => {
        if (data.href) {
          return (
            <a
              href={data[item].href || ''}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: data[item].colour,
              }}
            >
              {data[item].value}
            </a>
          );
        }
        return (
          <span
            style={{
              color: data[item].colour,
            }}
          >
            {data[item].value}
          </span>
        );
      },
    })) : [];

    const filterConf = [
      {
        key: 'queryType',
        displayText: 'Select Query Type',
        options: queryTypes.map((item) => ({
          label: item.queryType,
          value: item.queryType,
        })),
      },
    ];

    if (initialLoad) {
      return (
        <Row>
          <Col
            xs={24}
            className="text-center pt-3"
          >
            <Spinner
              animation="border"
              variant="primary"
            />
          </Col>
        </Row>
      );
    }

    return (
      <Container
        fluid
        className="bg-white h-100"
      >
        <Row>
          {filterConf.map((item) => (
            <Col
              key={item.key}
              xs="auto"
              className="px-2 py-2"
            >
              <CustomDropdown
                item={item}
                onChange={(data) => this.handleQueryTypeChange(data[item.key])}
                selectedVal={selectedQueryType ? selectedQueryType.queryType : ''}
                closeButton={false}
              />
            </Col>
          ))}
        </Row>
        {
          !!selectedQueryType && (
            <Row>
              {
                selectedQueryType.inputParamsFields.map((item) => (
                  <Col
                    xs="auto"
                    className="d-flex pt-3"
                  >
                    <div>
                      <b>
                        {
                          !item.optional && (
                            <span className="text-danger">
                              *&nbsp;
                            </span>
                          )
                        }
                        {item.label}
                        :
                      </b>
                    </div>
                    <div
                      className="pl-3 w-75"
                    >
                      <input
                        type="text"
                        className="form-control"
                        name={item.key}
                        placeholder={item.label}
                        value={inputFieldValues[item.key]}
                        onChange={this.handleChange}
                      />
                    </div>
                  </Col>
                ))
              }
              <Col
                xs="auto"
                className="d-flex justify-content-center pt-3"
              >
                <Button
                  variant="primary"
                  onClick={() => this.handleLoad({ l: 10, p: 1 })}
                  disabled={
                    isSubmitting
                    || (selectedQueryType.inputParamsFields.reduce(
                      (acc, item) => acc || (!item.optional && !inputFieldValues[item.key]), false,
                    ))
                  }
                >
                  Submit
                </Button>
              </Col>
            </Row>
          )
        }
        <Row className="my-4">
          {
            isSubmitting && (
              <Col
                className="p-3 mx-auto text-center"
                xs={24}
              >
                <Spinner
                  variant="primary"
                  animation="border"
                />
              </Col>
            )
          }
          {
            isError && (
              <Col
                className="p-3 mx-auto text-center text-danger"
                xs={24}
              >
                <b>
                  {errorMsg}
                </b>
              </Col>
            )
          }
          {
            (!queryResults && !isError && !isSubmitting) && (
              <Col
                className="p-3 text-secondary"
                xs={24}
              >
                Search result with above search terms will appear here.
              </Col>
            )
          }
          {
            queryResults && (
              <Col
                xs={24}
                className="bg-pink"
              >
                <Row className="d-flex">
                  {
                    queryResults.meta.map((item) => (
                      <Col xs={24} md={12} lg={6} className="">
                        { this.getDynamicKeyValuePairs(item)}
                      </Col>
                    ))
                  }
                </Row>
                <Row>
                  <CustomTable
                    isPaginated
                    headers={headers}
                    content={queryResults.results}
                    keyField={queryResults.headers[0]}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    l={param.l}
                    p={param.p}
                    totalItems={queryResults.count}
                    hasPrev={queryResults.hasPrev}
                    hasNext={queryResults.hasNext}
                    onNext={this.onNext}
                    onPrev={this.onPrev}
                    onSubmitPage={this.onSubmitPage}
                    onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                    updateRowsPageInput={this.handleRowsPageInput}
                  />
                </Row>
              </Col>
            )
          }
        </Row>
      </Container>
    );
  }
}

QueryTracker.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default QueryTracker;
