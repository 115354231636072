import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Button, Row, Col,
} from 'react-bootstrap';
import { CustomModal } from '../../component/common';

function UpdatDeliveryStatus(props) {
  const {
    show, onCancel, onUpdate, orderStatus,
    orderCancellationReasons,
  } = props;

  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');
  const [cancelReason, setCancelReason] = useState('');

  return (
    <CustomModal
      show={show}
      autoSize
      onHide={onCancel}
      body={(
        <Container className="p-4">
          <Row className="mx-0 py-2 fs-2">
            Are you sure you want to mark this order status as
            <b>
              &quot;
              {orderStatus}
              &quot;
            </b>
            ?
          </Row>
          {
            orderStatus === 'CANCELLED' && (
              <Row>
                <Col xs={24} className="py-2">Cancellation Reason:</Col>
                <Col xs={24} className="py-2">
                  <select
                    name="cars"
                    className="p-2 dropdown-border-none"
                    value={`${cancelReason},${reason}`}
                    onChange={(e) => {
                      if (e.target.value !== '') {
                        const reasons = e.target.value.split(',');
                        setCancelReason(reasons[0]);
                        setReason(reasons[1]);
                      }
                    }}
                  >
                    <option value="">Select</option>
                    {
                      orderCancellationReasons.map((item) => (
                        <optgroup label={item.subHeader} key={item}>
                          {
                            item.options.map((subItem) => (
                              <option
                                value={`${subItem.identifier},${subItem.text}`}
                                key={subItem.identifier}
                              >
                                {subItem.text}
                              </option>
                            ))
                          }
                        </optgroup>
                      ))
                    }
                  </select>
                </Col>
              </Row>
            )
          }
          <Row>
            <Col xs={24} className="py-2">Comment:</Col>
            <Col xs={24} className="py-2">
              <input
                type="text"
                value={comment}
                maxLength={1000}
                className="input-box"
                onChange={(e) => { setComment(e.target.value); }}
              />
            </Col>
          </Row>
          <Row
            className="mx-0 py-2 d-flex align-items-center flex-row-reverse"
          >
            <Button
              variant="outline-primary"
              className="font-weight-bold"
              onClick={() => { onUpdate(reason, comment, cancelReason); }}
              disabled={(orderStatus === 'CANCELLED' && (!reason || !comment)) || !comment}
            >
              UPDATE ORDER
            </Button>
            <Button
              variant="link"
              className="font-weight-bold text-black"
              onClick={onCancel}
            >
              NOT NOW
            </Button>
          </Row>
        </Container>
      )}
    />
  );
}

UpdatDeliveryStatus.propTypes = {
  orderStatus: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  orderCancellationReasons: PropTypes.arrayOf(PropTypes.shape({})),
};

UpdatDeliveryStatus.defaultProps = {
  orderCancellationReasons: [],
};

export default UpdatDeliveryStatus;
