import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Container, Row,
} from 'react-bootstrap';
import { CustomModal } from '../../../component/common';

function ConfirmationModal(props) {
  const {
    show, onHide, distance, onConfirm,
  } = props;

  const body = (
    <Container className="px-5 py-4">
      <Row className="justify-content-center text-center px-5">
        <h3>Are you sure you want to add this cluster?</h3>
      </Row>
      <Row className="justify-content-center text-center py-1">
        <span className="fs-2">
          {`It is ${distance} km from shop`}
        </span>
      </Row>
      <Row className="justify-content-center py-2">
        <Button
          onClick={onHide}
          className="mx-1"
        >
          NO
        </Button>
        <Button
          variant="success"
          className="mx-1"
          onClick={onConfirm}
        >
          YES
        </Button>
      </Row>
    </Container>
  );

  return (
    <CustomModal
      show={show}
      onHide={onHide}
      title=" "
      body={body}
      autoSize
      closeButton
      backdrop
    />
  );
}

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  distance: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmationModal;
