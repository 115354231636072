import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import {
  Button,
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal, ErrorHandler } from '../../component/common';
import ProductList from '../../layouts/place-order/ProductList';
import { cart, customerAddress } from '../../assets/api/axios';
import Checkout from '../../layouts/checkout/Checkout';
import Cart from '../../layouts/place-order/Cart';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  const {
    customerId = '',
    cartId = '',
    storeId = '',
  } = param;
  return ({
    customerId,
    cartId,
    storeId,
  });
};

function getCartItems(storeItems) {
  return storeItems.reduce((acc, store) => {
    const storeProduct = store.products.map((product) => ({
      productId: product.id,
      quantity: product.quantity,
      storeId: store.store.code,
    }));
    return [...acc, ...storeProduct];
  }, []);
}

class AbandonCartDetails extends React.Component {
  constructor(props) {
    super(props);
    const { history } = props;
    const param = getQueryParams(history.location.search);
    this.state = {
      customer: {
        id: param.customerId,
      },
      cartId: param.cartId,
      storeId: param.storeId,
      loading: true,
      error: false,
      cartDetails: null,
      isShopping: false,
      confirmedDeliveryAddress: false,
      address: null,
      openCart: false,
      failAddingProductToCart: '',
    };
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleLoad = () => {
    const { customer } = this.state;
    const { id } = customer;
    customerAddress(
      'GET',
      null,
      null,
      id,
      true,
    ).then((res) => {
      if (res.data.results[0]) {
        this.setState({
          address: res.data.results[0],
        }, () => this.loadCartDetails());
      } else {
        throw new Error();
      }
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad();
      });
    };
  }

  loadCartDetails = () => {
    const { address, cartId, customer } = this.state;
    const { id } = customer;
    cart(
      'GET',
      null,
      cartId,
      id,
      null,
      {
        location: `${address.location.lat},${address.location.lng}`,
      },
    ).then((res) => {
      const { storeItems } = res.data;
      const newCartItems = getCartItems(storeItems);
      this.setState({
        loading: false,
        cartItems: newCartItems,
        cartDetails: res.data,
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });
  }

  handleUpdateCartDetails = (cartDetails) => {
    this.setState({
      cartDetails: { ...cartDetails },
    }, this.getAndUpdateCartDetails);
  }

  getAndUpdateCartDetails = (retry = 3) => {
    const {
      customer, address, cartId,
    } = this.state;
    cart(
      'GET',
      null,
      cartId,
      customer.id,
      null,
      {
        location: `${address.location.lat},${address.location.lng}`,
      },
    ).then((res) => {
      const { data } = res;
      this.setState({
        cartDetails: data,
      });
    }).catch(() => {
      if (retry) {
        this.getAndUpdateCartDetails(retry - 1);
      }
    });
  }

  modifyCurrentState = (isShopping, reset = false) => {
    const { history } = this.props;
    if (reset) {
      history.push('/cart-abandon-users');
    } else {
      this.setState({ isShopping });
    }
  }

  handleOpenCartView = (status) => {
    this.setState({
      openCart: status,
    });
  }

  handleAddProductInCart = (productId, quantity, storeId) => {
    const { cartId, customer } = this.state;
    const { id } = customer;
    const data = {
      action: 'PATCH_PRODUCT',
      product: {
        productId,
        quantity,
        storeId,
      },
      resetDeliveryPreference: false,
    };
    cart('PATCH', data, cartId, id)
      .then((res) => {
        if (res.status === 200) {
          const { storeItems } = res.data;
          const newCartItems = getCartItems(storeItems);
          this.setState({
            cartItems: newCartItems,
          }, () => {
            this.handleUpdateCartDetails(res.data);
          });
        } else {
          throw new Error();
        }
      }).catch((error) => {
        this.setState(() => {
          if (
            error
            && error.response
            && error.response.status === 400
            && error.response.data
            && error.response.data[0]
          ) {
            return {
              failAddingProductToCart: error.response.data[0].client_message,
            };
          }
          return {
            failAddingProductToCart: 'Oops Something went wrong, Please try again!!',
          };
        });
      });
  }

  handleAddOrRemoveCustomProduct = (productId, quantity, storeId) => {
    const { cartId, customer } = this.state;
    const { id } = customer;
    const data = {
      action: 'PATCH_CUSTOM_PRODUCT',
      product: {
        productId,
        quantity,
        storeId,
      },
      resetDeliveryPreference: false,
    };
    cart('PATCH', data, cartId, id)
      .then((res) => {
        if (res.status === 200) {
          const { storeItems } = res.data;
          const newCartItems = getCartItems(storeItems);
          this.setState({
            cartItems: newCartItems,
          }, () => {
            this.handleUpdateCartDetails(res.data);
          });
        }
      });
  }

  handleChangeAddress = (address) => {
    this.setState({
      address,
      confirmedDeliveryAddress: true,
    });
  }

  render() {
    const {
      loading, error, address, customer, cartId, storeId,
      isShopping, cartDetails, openCart, cartItems,
      confirmedDeliveryAddress, failAddingProductToCart,
    } = this.state;
    return (
      <Container
        fluid
        className="h-100 mx-0"
      >
        <CustomModal
          show={!!failAddingProductToCart}
          title="Cart"
          onHide={() => {
            this.setState({
              failAddingProductToCart: '',
            });
          }}
          autoSize
          border
          closeButton
          body={(
            <Container>
              <Row>
                <Col
                  xs={24}
                  className="py-3 border"
                >
                  <Row
                    className="pt-2"
                  >
                    <Col
                      className="font-weight-bold text-danger text-center"
                    >
                      {failAddingProductToCart}
                    </Col>
                  </Row>
                  <Row
                    className="pt-4 text-center"
                  >
                    <Col>
                      <Button
                        variant="primary"
                        className="py-1"
                        onClick={() => {
                          this.setState({
                            failAddingProductToCart: '',
                          });
                        }}
                      >
                        Okay
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
        )}
        />
        {
          openCart && (
            <Row>
              <Col xs>
                <Cart
                  show={openCart}
                  cartItems={cartItems}
                  cartDetails={cartDetails}
                  handleAddProductInCart={this.handleAddProductInCart}
                  handleOpenCartView={this.handleOpenCartView}
                  handleAddOrRemoveCustomProduct={this.handleAddOrRemoveCustomProduct}
                  modifyCurrentState={() => {
                    this.handleOpenCartView(false);
                    this.modifyCurrentState();
                  }}
                />
              </Col>
            </Row>
          )
        }
        {(() => {
          let showcase = null;
          if (loading) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center justify-content-center"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (!loading && error) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center
                justify-content-center"
              >
                <ErrorHandler
                  retryLogic={() => this.retry()}
                />
              </div>
            );
          } else if (address && customer) {
            showcase = (
              <>
                {
                  isShopping ? (
                    <Row>
                      <Col xs={24} className="product-show-container">
                        <ProductList
                          lat={address.location.lat}
                          lng={address.location.lng}
                          customerId={customer.id}
                          cartId={Number(cartId)}
                          cartDetails={cartDetails}
                          selectedStore={storeId}
                          updateCartDetails={this.handleUpdateCartDetails}
                          modifyCurrentState={this.modifyCurrentState}
                          getAndUpdateCartDetails={this.getAndUpdateCartDetails}
                          showProducts
                          resetDeliveryPreference={false}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <Row>
                        <Col xs={24} className="p-0">
                          <Checkout
                            confirmedDeliveryAddress={confirmedDeliveryAddress}
                            cartDetails={cartDetails}
                            selectedAddress={address}
                            selectedCustomer={customer}
                            handleChangeAddress={this.handleChangeAddress}
                            handleUpdateCartDetails={this.handleUpdateCartDetails}
                            modifyCurrentState={this.modifyCurrentState}
                            handleOpenCartView={this.handleOpenCartView}
                            editCart
                          />
                        </Col>
                      </Row>
                    </>
                  )
                }
              </>
            );
          }
          return showcase;
        })()}
      </Container>
    );
  }
}

AbandonCartDetails.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default AbandonCartDetails;
