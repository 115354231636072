import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal, ErrorHandler } from '../../component/common';
import { exotelCallDetails, exotelCallInit } from '../../assets/api/axios';

const callPurposeOptions = [
  {
    label: 'Fraud Verification',
    value: 'Fraud_Verification',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

const callCommentOptions = [
  {
    label: 'Order Verified',
    value: 'Order_Verified',
  },
  {
    label: 'Order Not Verified',
    value: 'Order_Not_Verified',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

class CustomerCallPatch extends Component {
  constructor() {
    super();
    this.state = {
      callLogId: null,
      callPurpose: '',
      callComment: '',
      additionalComment: '',
      processingCall: false,
      callError: false,
      processingPatch: false,
      patchError: false,
    };
  }

  handleChangeCallDetails = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  initiateCall = () => {
    const { callFrom, callTOCustomer } = this.props;
    exotelCallInit(
      'POST',
      {
        type: 'CustomerCallRequest',
        callTOCustomer,
        callFrom,
      },
    ).then((res) => {
      this.setState({
        callLogId: res.data.id,
        processingCall: false,
        callError: false,
      });
    }).catch(() => {
      this.setState({
        processingCall: false,
        callError: true,
      });
    });
  }

  patchCallDetails = () => {
    const {
      callPurpose, callComment, additionalComment, callLogId,
    } = this.state;
    const { onHide } = this.props;
    exotelCallDetails(
      'PATCH',
      {
        callPurpose,
        callComment,
        additionalComment,
      },
      callLogId,
    ).then(() => {
      onHide();
    }).catch(() => {
      this.setState({
        processingPatch: false,
        patchError: true,
      });
    });
  }

  reset = () => {
    this.setState({
      callLogId: null,
      callPurpose: '',
      callComment: '',
      additionalComment: '',
      processingCall: false,
      callError: false,
      processingPatch: false,
      patchError: false,
    });
  }

  render() {
    const { show, onHide } = this.props;
    const {
      callPurpose, callComment, additionalComment,
      processingCall, callError, callLogId,
      processingPatch, patchError,
    } = this.state;

    let body = <div />;

    if (processingCall) {
      body = (
        <div
          className="text-center p-4"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }
    if (!processingCall && callError) {
      body = (
        <ErrorHandler
          retryLogic={() => {
            this.setState({
              processingCall: true,
              callError: false,
            }, () => {
              this.initiateCall();
            });
          }}
        />
      );
    }
    if (!processingCall && !callError && !!callLogId) {
      body = (
        <Container>
          <Row>
            <Col
              xs={24}
              className="p-2 bg-success text-center"
            >
              <b>Your call has been initiated successfully</b>
            </Col>

          </Row>
          <Row className="pt-2">
            <Col xs={24}>
              <b>Call Purpose</b>
              <span className="text-danger">*</span>
            </Col>
            <Col xs={24} className="pt-2">
              <select
                name="callPurpose"
                value={callPurpose}
                className="form-control"
                onChange={(event) => {
                  if (event.target.value !== '') {
                    this.handleChangeCallDetails(event);
                  }
                }}
              >
                <option value="">Select</option>
                {callPurposeOptions.map((purpose) => (
                  <option value={purpose.value} key={purpose.value}>{purpose.label}</option>
                ))}
              </select>
            </Col>
          </Row>

          <Row className="pt-4">
            <Col xs={24}>
              <b>Call Comment</b>
              <span className="text-danger">*</span>
            </Col>
            <Col xs={24} className="pt-2">
              <select
                name="callComment"
                value={callComment}
                className="form-control"
                onChange={(event) => {
                  if (event.target.value !== '') {
                    this.handleChangeCallDetails(event);
                  }
                }}
              >
                <option value="">Select</option>
                {callCommentOptions.map((comment) => (
                  <option value={comment.value} key={comment.value}>{comment.label}</option>
                ))}
              </select>
            </Col>
          </Row>

          <Row className="pt-4">
            <Col xs={24}>
              <b>Additional Comment</b>
            </Col>
            <Col xs={24} className="pt-2">
              <input
                type="text"
                name="additionalComment"
                value={additionalComment}
                placeholder="*Required if call comment selects Other"
                className="form-control"
                maxLength={100}
                onChange={this.handleChangeCallDetails}
              />
            </Col>
          </Row>

          <Row className="pt-4">
            <Col xs={24} className="text-center">
              {processingPatch
                ? (
                  <Spinner
                    variant="primary"
                    animation="border"
                  />
                ) : (
                  <>
                    <Button
                      variant="primary"
                      block
                      onClick={() => {
                        this.setState({
                          processingPatch: true,
                          patchError: false,
                        }, () => {
                          this.patchCallDetails();
                        });
                      }}
                      disabled={!callPurpose || !callComment || (callComment === 'Other' && !additionalComment)}
                    >
                      Submit
                    </Button>
                    <div className="py-2 text-danger">
                      {!processingPatch && patchError
                      && (
                        <b>Oops Something went wrong!!</b>
                      )}
                    </div>
                  </>
                )}
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <CustomModal
        show={show}
        body={body}
        onHide={onHide}
        onEnter={() => {
          this.setState({
            processingCall: true,
          }, () => {
            this.initiateCall();
          });
        }}
        autoSize
        backdrop={processingCall
          || callError || 'static'}
        reset={this.reset}
      />
    );
  }
}

CustomerCallPatch.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  callFrom: PropTypes.string.isRequired,
  callTOCustomer: PropTypes.string.isRequired,
};

export default CustomerCallPatch;
