import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal, CustomTable, ErrorHandler } from '../../../component/common';
import { getOrderTagStringFormat } from '../../../utilities/Utils';
import { orderProductHistory } from '../../../assets/api/axios';

function getStatusIcon(status) {
  return status
    ? <span className="text-success">&#10004;</span>
    : <span className="text-danger">&#10006;</span>;
}

function ViewProductHistory(props) {
  const { productId, orderId, onHide } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [productHistory, setProductHistory] = useState(null);

  function handleLoad() {
    setLoading(true);
    setError(false);
    orderProductHistory(
      'GET',
      {
        productId,
      },
      orderId,
    ).then((res) => {
      setProductHistory(res.data);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
      setError(true);
    });
  }

  const body = (
    <Container
      className="py-4"
    >
      <Row>
        <Col className="px-0">
          {(() => {
            let showcase = null;
            if (loading) {
              showcase = (
                <div
                  className="py-2 d-flex align-items-center
                  justify-content-center"
                >
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                </div>
              );
            } else if (!loading && error) {
              showcase = (
                <div
                  className="py-2 d-flex align-items-center
                  justify-content-center"
                >
                  <ErrorHandler
                    retryLogic={() => {
                      handleLoad();
                    }}
                  />
                </div>
              );
            } else if (productHistory) {
              showcase = (
                <CustomTable
                  headers={[
                    {
                      key: 'libraryProductId',
                      displayText: 'Id',
                    },
                    {
                      key: 'name',
                      displayText: 'Name',
                    },
                    {
                      key: 'mrp',
                      displayText: 'Mrp',
                    },
                    {
                      key: 'sellingPrice',
                      displayText: 'Selling Price',
                    },
                    {
                      key: 'imageUrl',
                      displayText: 'Image',
                      renderer: (data) => (
                        <div
                          className="product-image-div"
                        >
                          <img
                            src={data.imageUrl}
                            alt=""
                            className="w-100 h-100"
                          />
                        </div>
                      ),
                    },
                    {
                      key: 'fulfilmentStatus',
                      displayText: 'Fulfilment Status',
                      renderer: (data) => getStatusIcon(data.fulfilmentStatus),
                    },
                    {
                      key: 'tag',
                      displayText: 'Tag',
                      renderer: (data) => getOrderTagStringFormat(data.tag) || 'NA',
                    },
                    {
                      key: 'quantity',
                      displayText: 'QTY',
                    },
                  ]}
                  content={productHistory.data}
                  keyField="id"
                  totalItems={productHistory.count}
                  isPaginated={false}
                />
              );
            }
            return showcase;
          })()}
        </Col>
      </Row>
    </Container>
  );

  return (
    <CustomModal
      show={!!productId}
      onHide={onHide}
      size="lg"
      title="Product History"
      body={body}
      onEnter={() => {
        handleLoad();
      }}
      autoSize
      closeButton
      backdrop={!loading}
      border
    />
  );
}

ViewProductHistory.propTypes = {
  onHide: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  orderId: PropTypes.number.isRequired,
};

export default ViewProductHistory;
