import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Row, Form,
} from 'react-bootstrap';

export default function CreateNewProduct(props) {
  const {
    onCancel, handleAddProductInList, createMode, product,
  } = props;
  const fromLibraryProduct = (createMode === 'FROMLIBRARY' || createMode === 'UPDATECUSTOM') && !!product;
  const fromCustomProduct = createMode === 'UPDATECUSTOM' && !!product;
  const [displayName, setDisplayName] = useState(fromLibraryProduct ? product.displayName : '');
  const [mrp, setMRP] = useState(fromLibraryProduct ? product.mrp : 0);
  const [sp, setSP] = useState(fromLibraryProduct ? product.sellingPrice : 0);
  const [qty, setQTY] = useState(fromLibraryProduct ? product.quantity : 0);

  const [unit, setUnit] = useState(fromCustomProduct ? product.unitString : '');

  const [error, setError] = useState('');

  const onAddProduct = () => {
    handleAddProductInList({
      ...product || {},
      displayName,
      mrp: Number(mrp),
      sellingPrice: Number(sp),
      quantity: Number(qty),
      unitString: String(unit),
    });
  };

  const validateForm = () => {
    setError('');
    if (!displayName) {
      setError('Please enter product name');
      return;
    }
    if (mrp < 1) {
      setError('Invalid Product Mrp');
      return;
    }
    if (sp < 1) {
      setError('Invalid Product Selling Price');
      return;
    }
    if (Number(sp) > Number(mrp)) {
      setError('Selling Price must not be greater than mrp');
      return;
    }
    if (qty < 1) {
      setError('Invalid Product Quantity');
      return;
    }
    if (fromCustomProduct && !unit) {
      setError('Please enter unit of product');
      return;
    }
    onAddProduct();
  };
  return (
    <Row>
      <Col xs={24}>
        {error && (<div className="text-danger">{`*${error}`}</div>) }
        <Form>
          <Form.Group>
            <Form.Label>Product: </Form.Label>
            <Form.Control
              name="displayName"
              type="text"
              value={displayName}
              disabled={fromCustomProduct}
              onChange={(e) => setDisplayName(e.target.value)}
            />
          </Form.Group>
          {
            fromCustomProduct && (
              <Form.Group>
                <Form.Label>Unit String: </Form.Label>
                <Form.Control
                  name="unit"
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                />
              </Form.Group>
            )
          }
          {
            !fromCustomProduct && (
              <Form.Group>
                <Form.Label>Product MRP: </Form.Label>
                <Form.Control
                  name="mrp"
                  type="number"
                  value={mrp}
                  disabled={fromCustomProduct}
                  onChange={(e) => setMRP(e.target.value)}
                />
              </Form.Group>
            )
          }
          <Form.Group>
            <Form.Label>Product Selling Price: </Form.Label>
            <Form.Control
              name="sp"
              type="number"
              value={sp}
              onChange={(e) => setSP(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Quantity: </Form.Label>
            <Form.Control
              name="qty"
              type="number"
              value={qty}
              maxLength={2}
              onChange={(e) => {
                if (e.target.value <= 10) {
                  setQTY(e.target.value);
                }
              }}
            />
          </Form.Group>
        </Form>
      </Col>
      <Col
        xs={24}
        className="d-flex justify-content-center py-4"
      >
        <Button
          variant="outline-primary"
          onClick={validateForm}
          disabled={false} // if updates not avaalable
          className="mx-2"
        >
          ADD PRODUCT TO LIST
        </Button>
        <Button
          variant="outline-primary"
          onClick={onCancel}
          disabled={false} // if updates not avaalable
          className="mx-2"
        >
          CANCEL
        </Button>
      </Col>
    </Row>
  );
}

CreateNewProduct.propTypes = {
  handleAddProductInList: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  product: PropTypes.shape({
    displayName: PropTypes.string,
    mrp: PropTypes.number,
    sellingPrice: PropTypes.number,
    quantity: PropTypes.number,
    unitString: PropTypes.string,
  }),
  createMode: PropTypes.string,
};

CreateNewProduct.defaultProps = {
  product: {},
  createMode: '',
};
