import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 19 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M17.44 8.71997L1.96005 0.979973C1.75121 0.883219 1.5215 0.840233 1.29181 0.854928C1.06212 0.869623 0.839753 0.941533 0.644953 1.06411C0.450152 1.18669 0.289103 1.35604 0.176466 1.55676C0.0638299 1.75747 0.00318319 1.98317 4.68075e-05 2.21331V17.6866C-0.00190344 17.9229 0.05712 18.1556 0.171413 18.3624C0.285705 18.5691 0.451402 18.7429 0.652486 18.8669C0.853571 18.9909 1.08324 19.061 1.31931 19.0702C1.55537 19.0795 1.78984 19.0278 2.00005 18.92L17.44 11.18C17.6688 11.0652 17.861 10.889 17.9954 10.6712C18.1298 10.4534 18.2009 10.2026 18.2009 9.94664C18.2009 9.69073 18.1298 9.43986 17.9954 9.22206C17.861 9.00426 17.6688 8.82811 17.44 8.71331V8.71997Z" fill="#808080" />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default Svg;
