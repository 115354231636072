import React from 'react';
import Select from 'react-select';
import QueryString from 'query-string';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Spinner, Card, Button, Form, InputGroup,
} from 'react-bootstrap';
import moment from 'moment';
import Axios from 'axios';
import {
  CustomDropdown, CustomModal, CustomPagination, DatePicker, ErrorHandler, Svg,
} from '../../component/common';
import { retailerStory, retailerStoryIcon, uploadImage } from '../../assets/api/axios';
import { Utils, Constant } from '../../utilities';
import { ProcessingStatus } from '../../component/derived/table-list';
import Permission from '../../access&permissions/permission';

function getOneMonthLaterDate() {
  const now = new Date();
  return new Date(now.setMonth(now.getMonth() + 1));
}

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, startDate, endDate,
  } = param;
  const {
    storyType = '',
    storeCode = '',
    type = '',
    status = '',
    language = '',
  } = param;
  startDate = Number(startDate) || '';
  endDate = Number(endDate) || '';
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    ...param,
    storyType,
    storeCode,
    startDate,
    endDate,
    l,
    p,
    type,
    status,
    language,
  });
};

class Stories extends React.Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    const { userPermission } = props;
    this.canIEdit = userPermission
      && userPermission.includes(Permission.STORIES_WRITE);
    this.state = {
      param,
      storeCode: param.storeCode,
      stories: null,
      loading: true,
      error: false,
      addStory: false,
      alteringStory: false,
      alteringStoryError: false,
      description: '',
      title: '',
      heading: '',
      text: '',
      storyType: '',
      selectedLanguage: null,
      storyIcon: '',
      storyIconToUpload: '',
      submittingStoryIcon: false,
      storyIconUploadError: false,
      storyImage: '',
      videoUrl: '',
      storeIds: '',
      launchDate: new Date(),
      expiryDate: getOneMonthLaterDate(),
      storyIdToDelete: null,
      storyIdToEdit: null,
      invalidDateErrorMsg: '',
      deeplink: '',
      rowsPerPage: param.l,
      page: param.p,
      stDate: param.startDate,
      eDate: param.endDate,
      newParam: param,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
    this.getStoryIcon();
  }

  getStoryIcon = () => {
    retailerStoryIcon(
      'GET',
    ).then((res) => {
      this.setState({
        storyIcon: res.data.icon,
      });
    }).catch(() => {
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param, stDate, eDate } = this.state;
    const newParam = { ...param, ...data };
    const {
      storeCode, type, l, p, status, language,
    } = newParam;
    const offset = (p - 1) * l;
    retailerStory(
      'GET',
      null,
      '',
      {
        type,
        storeCode,
        startDate: stDate,
        endDate: eDate,
        offset,
        limit: l,
        status,
        language,
      },
    ).then((res) => {
      this.setState({
        stories: res.data,
        param: { ...newParam },
        loading: false,
        error: false,
        rowsPerPage: l,
        page: p,
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad();
      });
    };
  }

  addStory = (e) => {
    if (e) {
      e.preventDefault();
    }
    const {
      addStory, storyIdToEdit, description, storyType, storyImage, title,
      text, heading, storeIds, launchDate, expiryDate, videoUrl, selectedLanguage,
      deeplink,
    } = this.state;

    if (launchDate > expiryDate) {
      this.setState({
        invalidDateErrorMsg: 'Please Check!! Launch Date is Greater than Expiry Date',
      });
      return;
    }

    this.setState({
      alteringStory: true,
      invalidDateErrorMsg: '',
    });

    retailerStory(
      `${addStory ? 'POST' : 'PATCH'}`,
      {
        description,
        storyType,
        language: selectedLanguage.value,
        title,
        text,
        heading,
        image: storyImage,
        videoUrl,
        stores: storeIds,
        launchDate: launchDate.getTime(),
        expiryDate: expiryDate.getTime(),
        deeplink,
      },
      storyIdToEdit,
    ).then(() => {
      this.setState({
        addStory: false,
        storyIdToEdit: null,
        alteringStory: false,
        alteringStoryError: false,
        description: '',
        text: '',
        heading: '',
        title: '',
        storyType: '',
        selectedLanguage: null,
        storyImage: '',
        videoUrl: '',
        storeIds: '',
        launchDate: new Date(),
        expiryDate: getOneMonthLaterDate(),
        invalidDateErrorMsg: '',
        deeplink: '',
      }, () => {
        this.handleLoad();
      });
    }).catch(() => {
      this.setState({
        alteringStory: false,
        alteringStoryError: true,
      });
      return false;
    });
  }

  deleteStory = () => {
    const { storyIdToDelete } = this.state;
    this.setState({
      alteringStory: true,
    });
    retailerStory(
      'PATCH',
      {
        delete: true,
      },
      storyIdToDelete,
    ).then(() => {
      this.setState({
        alteringStory: false,
        storyIdToDelete: null,
      }, () => {
        this.handleLoad();
      });
    }).catch(() => {
      this.setState({
        alteringStory: false,
        alteringStoryError: true,
      });
    });
  }

  handleInputOnChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  handleChangeDate = (event) => {
    this.setState({
      [event.target.id]: new Date(event.target.value),
    });
  }

  handleImageOnChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('img', file);
    uploadImage(
      'post',
      formData,
    ).then((res) => {
      this.setState({
        storyImage: res.data.url,
      });
    }).catch(() => {});
  }

  handleStoryIconChange = (event) => {
    const { files, name } = event.target;
    this.setState({
      [name]: files[0],
    });
  }

  onSubmitStoryIcon = () => {
    const { storyIconToUpload } = this.state;
    const formData = new FormData();
    formData.append('img', storyIconToUpload);
    let imageUrl = '';
    this.setState({
      submittingStoryIcon: true,
      storyIconUploadError: false,
    });
    uploadImage(
      'post',
      formData,
    ).then((res) => {
      imageUrl = res.data.url;
      retailerStoryIcon(
        'POST',
        {
          icon: imageUrl,
        },
      ).then(() => {
        this.setState({
          storyIconToUpload: '',
          storyIcon: imageUrl,
          submittingStoryIcon: false,
        });
      }).catch(() => {
        this.setState({
          submittingStoryIcon: false,
          storyIconUploadError: true,
        });
        setTimeout(() => {
          this.setState({
            storyIconUploadError: false,
          });
        }, 5000);
      });
    }).catch(() => {
      this.setState({
        submittingStoryIcon: false,
        storyIconUploadError: true,
      });
      setTimeout(() => {
        this.setState({
          storyIconUploadError: false,
        });
      }, 5000);
    });
  }

  handleDateChange = (startDate, endDate) => {
    this.setState({
      stDate: startDate,
      eDate: endDate,
    });
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState((state) => ({
      [name]: value,
      newParam: {
        ...state.newParam,
        [name]: value,
      },
    }));
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const {
      l, p,
    } = param;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source;
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
    });
  }

  applyFilters = () => {
    const { newParam } = this.state;
    this.handleRequestProcessing({
      ...newParam,
      p: 1,
    });
  }

  resetFilters = () => {
    this.handleRequestProcessing({
      stDate: '',
      eDate: '',
      p: 1,
      type: '',
      storeCode: '',
      status: '',
      language: '',
    });
    this.setState((state) => ({
      stDate: '',
      eDate: '',
      storeCode: '',
      newParam: {
        ...state.newParam,
        p: 1,
        type: '',
        status: '',
        language: '',
      },
    }));
  }

  handleDropdownChange = (data) => {
    this.setState((state) => ({
      newParam: { ...state.newParam, ...data },
    }));
  }

  render() {
    const {
      stories, loading, param, storeCode, error, addStory, alteringStory,
      description, title, text, heading, storyType, storyImage, videoUrl,
      alteringStoryError, storeIds, storyIdToDelete, storyIdToEdit,
      launchDate, expiryDate, invalidDateErrorMsg, storyIcon, submittingStoryIcon,
      storyIconToUpload, storyIconUploadError, selectedLanguage, deeplink,
      rowsPerPage, page, stDate, eDate, newParam,
    } = this.state;

    const filterConfForSingleSelect = [
      {
        key: 'type',
        displayText: 'Story Type',
        options: [
          {
            label: 'SHARE OFFER',
            value: 'SHARE_OFFER',
          },
          {
            label: 'CREATE OFFER',
            value: 'CREATE_OFFER',
          },
          {
            label: 'VIDEO',
            value: 'VIDEO',
          },
          {
            label: 'KNOW MORE',
            value: 'KNOW_MORE',
          },
          {
            label: 'SHARE SHOP LINK',
            value: 'SHARE_SHOP_LINK',
          },
          {
            label: 'STORY WITHOUT CTA',
            value: 'STORY_WITHOUT_CTA',
          },
          {
            label: 'SHARE SFO OFFER',
            value: 'SHARE_SFO_OFFER',
          },
          {
            label: 'SHARE CATEGORY',
            value: 'SHARE_CATEGORY',
          },
          {
            label: 'SHARE FEEDS',
            value: 'SHARE_FEEDS',
          },
          {
            label: 'SHARE SWC',
            value: 'SHARE_SWC',
          },
          {
            label: 'SHARE SWC CUSTOM CARD',
            value: 'SHARE_SWC_CUSTOM_CARD',
          },
        ],
      },
      {
        key: 'status',
        displayText: 'Card Status',
        options: [
          {
            label: 'Scheduled',
            value: 'SCHEDULED',
          },
          {
            label: 'Active',
            value: 'ACTIVE',
          },
          {
            label: 'Completed',
            value: 'COMPLETED',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
    ];

    const filterConfForMultiSelect = [
      {
        key: 'language',
        displayText: 'Language',
        options: (
          Object.keys(Utils.languages).map((item) => ({
            label: Utils.languages[item],
            value: item,
          }))
        ),
      },
    ];

    if (!stories && loading) {
      return (
        <div
          className="h-100 d-flex align-items-center justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!stories && !loading && error) {
      return (
        <div
          className="h-100 d-flex align-items-center
               justify-content-center"
        >
          <ErrorHandler
            retryLogic={() => {
              this.setState({
                loading: true,
                error: false,
              }, () => {
                this.handleLoad();
              });
            }}
          />
        </div>
      );
    }

    const addOrEditStoryModalBody = (
      <Col>
        {
          alteringStoryError
          && (
            <ErrorHandler
              retryLogic={() => {
                this.addStory();
              }}
            />
          )
        }
        {
          alteringStory
          && (
            <div
              className="w-100 pt-3 d-flex align-items-center justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          )
        }
        {
          !alteringStoryError && !alteringStory
          && (
          <Col
            className="mx-3 pt-3"
          >
            <Form
              onSubmit={(e) => {
                this.addStory(e);
              }}
            >
              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                    Title:
                </Form.Label>
                <Col
                  sm={15}
                >
                  <Form.Control
                    value={title}
                    id="title"
                    onChange={this.handleInputOnChange}
                    autoComplete="off"
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                    Heading:
                </Form.Label>
                <Col
                  sm={15}
                >
                  <Form.Control
                    value={heading}
                    id="heading"
                    onChange={this.handleInputOnChange}
                    autoComplete="off"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                    Text:
                </Form.Label>
                <Col
                  sm={15}
                >
                  <Form.Control
                    value={text}
                    id="text"
                    onChange={this.handleInputOnChange}
                    autoComplete="off"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                    Description:
                </Form.Label>
                <Col
                  sm={15}
                >
                  <Form.Control
                    value={description}
                    id="description"
                    onChange={this.handleInputOnChange}
                    autoComplete="off"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                    Story Type:
                </Form.Label>
                <Col
                  sm={15}
                >
                  <select
                    className="form-control"
                    id="storyType"
                    onChange={this.handleInputOnChange}
                    value={storyType}
                    required
                  >
                    <option value="">-- NONE --</option>
                    <option value="SHARE_OFFER">SHARE OFFER</option>
                    <option value="CREATE_OFFER">CREATE OFFER</option>
                    <option value="VIDEO">VIDEO</option>
                    <option value="KNOW_MORE">KNOW MORE</option>
                    <option value="SHARE_SHOP_LINK">SHARE SHOP LINK</option>
                    <option value="STORY_WITHOUT_CTA">STORY WITHOUT CTA</option>
                    {
                      !addStory && (
                        <>
                          <option value="SHARE_SFO_OFFER">SHARE SFO OFFER</option>
                          <option value="SHARE_CATEGORY">SHARE CATEGORY</option>
                          <option value="SHARE_FEEDS">SHARE FEEDS</option>
                          <option value="SHARE_SWC">SHARE SWC</option>
                          <option value="SHARE_SWC_CUSTOM_CARD">SHARE SWC CUSTOM CARD</option>
                        </>
                      )
                    }
                  </select>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                    Language:
                </Form.Label>
                <Col
                  sm={15}
                >
                  <Select
                    id="selectedLanguage"
                    onChange={(selectedLang) => {
                      this.setState({
                        selectedLanguage: selectedLang,
                      });
                    }}
                    value={selectedLanguage}
                    options={Object.keys(Utils.languages)
                      .map((item) => ({ label: Utils.languages[item], value: item }))}
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                    Image:
                </Form.Label>
                <Col
                  sm={15}
                >
                  <input
                    name="storyImage"
                    type="file"
                    className="form-control"
                    onChange={this.handleImageOnChange}
                    {...`${addStory ? 'required' : ''}`}
                  />
                  {
                    !storyImage && (
                      <div
                        className="mt-1 text-medium"
                      >
                        The image aspect ratio should be 9:16
                      </div>
                    )
                  }
                  {
                    storyImage && (
                      <img
                        className="p-2"
                        src={storyImage}
                        alt="Story"
                        width="250px"
                      />
                    )
                  }
                </Col>
              </Form.Group>


              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                    Video URL:
                </Form.Label>
                <Col
                  sm={15}
                >
                  <Form.Control
                    value={videoUrl}
                    id="videoUrl"
                    onChange={this.handleInputOnChange}
                    autoComplete="off"
                    required={storyType === 'VIDEO'}
                  />
                  {
                    !videoUrl && (
                      <div
                        className="mt-1 text-medium"
                      >
                        Use the format: https://www.youtube.com/watch?v=&lt;video_id&gt;
                      </div>
                    )
                  }
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                    Deeplink:
                </Form.Label>
                <Col
                  sm={15}
                >
                  <Form.Control
                    value={deeplink}
                    id="deeplink"
                    onChange={this.handleInputOnChange}
                    autoComplete="off"
                    required={storyType === 'KNOW_MORE'}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                    Launch Date:
                </Form.Label>
                <Col
                  sm={15}
                >
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={moment(launchDate).format('YYYY-MM-DDTHH:mm')}
                    id="launchDate"
                    onChange={this.handleChangeDate}
                    autoComplete="off"
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                    Expiry Date:
                </Form.Label>
                <Col
                  sm={15}
                >
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={moment(expiryDate).format('YYYY-MM-DDTHH:mm')}
                    id="expiryDate"
                    onChange={this.handleChangeDate}
                    autoComplete="off"
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                    Store Ids:
                </Form.Label>
                <Col
                  sm={15}
                >
                  <Form.Control
                    value={storeIds}
                    id="storeIds"
                    onChange={this.handleInputOnChange}
                    autoComplete="off"
                  />
                </Col>
              </Form.Group>

              {
                !!invalidDateErrorMsg && (
                  <Col
                    xs={24}
                    className="mb-2 text-danger px-0"
                  >
                    {invalidDateErrorMsg}
                  </Col>
                )
              }

              <Form.Group as={Row}>
                <Form.Label column sm={6} />
                <Col sm={6}>
                  <Button
                    type="submit"
                    variant="outline-primary"
                    disabled={!selectedLanguage || !storyImage}
                  >
                      SUBMIT
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </Col>
          )
        }
      </Col>
    );

    const deleteStoryConfirmationModalBody = (
      <Col>
        {
          alteringStoryError
          && (
            <ErrorHandler
              retryLogic={() => {
                this.deleteStory();
              }}
            />
          )
        }
        {
          alteringStory
          && (
            <div
              className="w-100 pt-3 d-flex align-items-center justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          )
        }
        {
          !alteringStoryError && !alteringStory && storyIdToDelete
          && (
            <Container
              className="pb-4 justify-content-center"
            >
              <Row
                className="mx-auto mt-4"
              >
                <Col
                  xs={24}
                  className="text-center fs-3"
                >
                  Are you sure you want to&nbsp;
                  <span
                    className="text-danger"
                  >
                    DELETE
                  </span>
                  &nbsp;this story ?
                </Col>
              </Row>
              <Row
                className="mt-4"
              >
                <Col
                  xs={12}
                  className="text-right"
                >
                  <Button
                    variant="outline-primary"
                    value="cancel"
                    className="py-1 px-3 fs-2"
                    disabled={alteringStory}
                    onClick={() => {
                      this.setState({
                        storyIdToDelete: null,
                      });
                    }}
                  >
                    CANCEL
                  </Button>
                </Col>
                <Col
                  xs={12}
                  className="text-left"
                >
                  <Button
                    variant="outline-danger"
                    value="submit"
                    className="py-1 px-3 fs-2 lg active"
                    disabled={alteringStory}
                    onClick={() => {
                      this.deleteStory();
                    }}
                  >
                    DELETE
                  </Button>
                </Col>
              </Row>
            </Container>
          )
        }
      </Col>
    );

    return (
      <Container
        fluid
        className="bg-white"
      >
        <CustomModal
          body={addOrEditStoryModalBody}
          title={addStory ? 'Add Story' : 'Edit Story'}
          closeButton
          size="lg"
          show={addStory || !!storyIdToEdit}
          description={`${addStory ? 'Add Story' : 'Edit Story'}`}
          onHide={() => {
            this.setState({
              addStory: false,
              storyIdToEdit: null,
              alteringStory: false,
              alteringStoryError: false,
              description: '',
              title: '',
              text: '',
              heading: '',
              storyType: '',
              selectedLanguage: null,
              storyImage: '',
              videoUrl: '',
              storeIds: '',
              launchDate: new Date(),
              expiryDate: getOneMonthLaterDate(),
              invalidDateErrorMsg: '',
              deeplink: '',
            });
          }}
        />
        <CustomModal
          body={deleteStoryConfirmationModalBody}
          closeButton
          autoSize
          show={!!storyIdToDelete}
          description="Delete Story"
          onHide={() => {
            this.setState({
              storyIdToDelete: null,
              alteringStory: false,
              alteringStoryError: false,
            });
          }}
        />
        <Row
          className="py-3 px-2"
        >
          <Col
            xs="auto"
            className="mt-4"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Store Id"
                name="storeCode"
                className="fs-01 rounded-0"
                value={storeCode}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.handleRequestProcessing({ storeCode });
                  }
                }}
              />
            </InputGroup>
          </Col>
          <Row
            className="align-items-center"
          >
            <Col
              xs="auto"
              className="pl-4"
            >
              <div className="pb-1 fs-01 text-disabled">
                Launch Date:
              </div>
              <div>
                <DatePicker
                  isDateRange
                  onApply={(dateRange) => {
                    this.handleDateChange(
                      new Date(dateRange.startDate).getTime(),
                      new Date(dateRange.endDate).getTime(),
                    );
                  }}
                  startDate={stDate}
                  endDate={eDate}
                  onClear={() => {
                    this.setState({
                      stDate: '',
                      eDate: '',
                    });
                  }}
                />
              </div>
            </Col>
            {
              filterConfForSingleSelect.map((item) => (
                <Col
                  key={item.key}
                  xs="auto"
                  className="pl-3"
                >
                  <div className="pb-1 fs-01 text-disabled">
                    {`Select ${item.displayText}:`}
                  </div>
                  <div>
                    <CustomDropdown
                      item={item}
                      onChange={(data) => {
                        this.handleDropdownChange(data);
                      }}
                      selectedVal={newParam[item.key]}
                    />
                  </div>
                </Col>
              ))
            }
            {
              filterConfForMultiSelect.map((item) => (
                <Col
                  key={item.key}
                  xs="auto"
                  className="pl-3"
                >
                  <div className="pb-1 fs-01 text-disabled">
                    {`Select ${item.displayText}:`}
                  </div>
                  <div>
                    <Select
                      id={item.key}
                      placeholder={item.displayText}
                      onChange={(selectedItems) => {
                        const selectedItemsString = selectedItems.reduce(
                          (acc, selItem, i) => acc.concat(i === 0 ? '' : ',', selItem.value), '',
                        );
                        this.handleDropdownChange({ [item.key]: selectedItemsString });
                      }}
                      selectedVal={newParam[item.key]}
                      options={item.options}
                      isMulti
                      value={newParam[item.key]
                        ? (newParam[item.key].split(','))
                          .map((val) => ({ label: val, value: val }))
                        : []}
                      className="minw-150p"
                    />
                  </div>
                </Col>
              ))
            }
          </Row>
        </Row>
        <Row
          className="py-2 px-2 d-flex align-items-center"
        >
          <Col
            xs="auto"
          >
            <Button
              variant="outline-primary"
              onClick={() => {
                this.setState({
                  addStory: true,
                });
              }}
              disabled={!this.canIEdit}
            >
              ADD STORY
            </Button>
          </Col>
          <Col
            xs="auto"
          >
            {
              storyIcon && (
                <img
                  className="p-2 border-rounded"
                  src={storyIcon}
                  alt="Story"
                  width="60px"
                />
              )
            }
            <input
              name="storyIconToUpload"
              id="storyIconToUpload"
              type="file"
              onChange={this.handleStoryIconChange}
              className="w-210p"
              disabled={!this.canIEdit}
            />
            {
              !storyIcon && (
                <div
                  className="mt-1 text-medium"
                >
                  The image aspect ratio should be 1:1
                </div>
              )
            }
          </Col>
          <Col>
            {
              submittingStoryIcon ? (
                <Spinner
                  animation="border"
                  variant="primary"
                />
              ) : (
                <Button
                  variant="outline-primary"
                  className=""
                  onClick={() => this.onSubmitStoryIcon()}
                  disabled={!storyIconToUpload || !this.canIEdit}
                >
                  Change Icon
                </Button>
              )
            }
            {
              storyIconUploadError && (
                <span
                  className="text-danger pl-4"
                >
                  Error while uploading story icon
                </span>
              )
            }
          </Col>
          <Row className="d-flex align-items-center flex-row-reverse">
            <Col
              xs="auto"
              className="px-2"
            >
              <Button
                variant="primary"
                className="fs-01 font-weight-bold"
                onClick={this.resetFilters}
              >
                RESET FILTERS
              </Button>
            </Col>
            <Col
              xs="auto"
              className="px-2"
            >
              <Button
                variant="success"
                className="fs-01 font-weight-bold"
                onClick={this.applyFilters}
              >
                APPLY FILTERS
              </Button>
            </Col>
          </Row>
        </Row>
        <Row
          className="stories-container"
        >
          {
            stories && (
              <Row
                className="m-0"
              >
                <Col
                  xs={14}
                  className="mx-auto"
                >
                  {
                    stories
                    && stories.results.map(
                      (story) => (
                        <Container
                          className="my-3"
                          key={story.id}
                        >
                          <Card
                            border="secondary"
                            xs="auto"
                            className="mt-0 mx-auto pt-2"
                            key={story.id}
                          >
                            <Row
                              className={`mx-2 flex-row-reverse ${this.canIEdit ? 'd-flex' : 'd-none'}`}
                            >
                              <Col
                                xs="auto"
                                className="text-danger pb-2 px-2 cursor-pointer"
                                onClick={() => {
                                  this.setState({
                                    storyIdToDelete: story.id,
                                  });
                                }}
                              >
                                DELETE
                              </Col>
                              <Col
                                xs="auto"
                                className="text-primary pb-2 px-2 cursor-pointer"
                                onClick={() => {
                                  this.setState({
                                    storyIdToEdit: story.id,
                                    description: story.description,
                                    title: story.title,
                                    text: story.text,
                                    heading: story.heading,
                                    storyType: story.story_type,
                                    selectedLanguage: {
                                      label: Utils.languages[story.language], value: story.language,
                                    },
                                    storyImage: story.image,
                                    videoUrl: story.video_url,
                                    storeIds: story.store.join(', '),
                                    launchDate: new Date(story.launch_date),
                                    expiryDate: new Date(story.expiry_date),
                                    deeplink: story.deeplink,
                                  });
                                }}
                              >
                                EDIT
                              </Col>
                            </Row>
                            <Row
                              className="m-1"
                            >
                              <Col
                                xs={6}
                                className="mb-2 d-flex justify-content-center align-items-center text-secondary"
                              >
                                {
                                  story.image ? (
                                    <Card.Img
                                      className="story-image w-100"
                                      src={story.image}
                                    />
                                  ) : (
                                    <> Video Post </>
                                  )
                                }
                              </Col>
                              <Col
                                xs={18}
                              >
                                <Card.Body
                                  className="text-dark p-0"
                                >
                                  <Row className="mx-0">
                                    <h6
                                      className="card-text m-0 fs-02 font-weight-bold"
                                    >
                                      { story.title }
                                    </h6>
                                  </Row>
                                  <Row className="mx-0">
                                    <h6
                                      className="card-text pt-2 m-0 fs-03"
                                    >
                                      { story.description }
                                    </h6>
                                  </Row>
                                  <Row className="mx-0">
                                    <h6
                                      className="card-text pt-2 m-0 fs-03"
                                    >
                                      { (story.store.reduce((acc, storeId) => acc.concat(`${storeId}, `), '')).slice(0, -2) }
                                    </h6>
                                  </Row>
                                  <Row className="mx-0">
                                    <Col
                                      className="d-flex px-0 py-2 m-0 fs-01"
                                    >
                                      <div>
                                        <b
                                          className="text-primary"
                                        >
                                          Launch Date:
                                        </b>
                                        &nbsp;
                                        {
                                          new Date(story.launch_date).toLocaleDateString(
                                            'en',
                                            Utils.dateTimeOptions,
                                          )
                                        }
                                      </div>
                                      <div
                                        className="pl-3"
                                      >
                                        <b
                                          className="text-primary"
                                        >
                                          Expiry Date:
                                        </b>
                                        &nbsp;
                                        {
                                          new Date(story.expiry_date).toLocaleDateString(
                                            'en',
                                            Utils.dateTimeOptions,
                                          )
                                        }
                                      </div>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Col>
                            </Row>
                          </Card>
                        </Container>
                      ),
                    )
                  }
                </Col>
              </Row>
            )
          }
        </Row>
        <Row className="text-center">
          {
            stories
            && stories.results.length === 0
            && (
              <h6
                className="p-3 text-danger"
              >
                No Story Available
              </h6>
            )
          }
        </Row>
        {
          stories && (
            <Row>
              <ProcessingStatus
                show={loading || error}
                loading={loading}
                error={error}
                onRetry={() => this.retry()}
                onCancel={this.onCancel}
              />
              <Col
                xs={24}
                className="px-0"
              >
                <CustomPagination
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={stories.count}
                  hasPrev={stories.hasPrevious}
                  hasNext={stories.hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            </Row>
          )
        }
      </Container>
    );
  }
}

Stories.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default Stories;
