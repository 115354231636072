import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.786 15.167c-.745-1.407-3.33-2.933-3.444-3a2.054 2.054 0 0 0-1.003-.29c-.483 0-.878.222-1.117.624-.378.452-.847.98-.96 1.063-.882.598-1.571.53-2.334-.233L6.669 9.07c-.758-.758-.828-1.456-.234-2.332.083-.114.611-.584 1.064-.962.288-.172.486-.427.573-.74.115-.416.03-.906-.242-1.383-.064-.11-1.59-2.696-2.997-3.44a1.834 1.834 0 0 0-2.15.323l-.94.94C.254 2.967-.284 4.653.14 6.492c.354 1.532 1.387 3.162 3.07 4.845l5.452 5.452c2.13 2.13 4.162 3.21 6.037 3.21 1.38 0 2.666-.585 3.821-1.741l.941-.94a1.82 1.82 0 0 0 .324-2.15Z"
      fill="#292997"
    />
  </svg>
);

Svg.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default Svg;
