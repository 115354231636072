import React from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import QueryString from 'query-string';
import {
  Col, Container, InputGroup, Row, Spinner, Form,
  Button,
} from 'react-bootstrap';
import { CustomTable, ErrorHandler, Svg } from '../../component/common';
import { ProcessingStatus } from '../../component/derived/table-list';
import { couponOffer } from '../../assets/api/axios';
import { Constant, Utils } from '../../utilities';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, q,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  q = q || '';
  return ({
    ...param, l, p, q,
  });
};

class CouponListing extends React.Component {
  constructor(props) {
    super();
    const param = getQueryParams(props.history.location.search);
    this.state = {
      rowsPerPage: param.l,
      page: param.p,
      param,
      coupons: null,
      loading: true,
      error: false,
      searchText: param.q,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, q,
    } = newParam;
    const offset = (p - 1) * l;
    couponOffer(
      'get',
      null,
      null,
      {
        offset: `${offset}`,
        limit: l,
        searchText: q,
      },
      this.source.token,
    )
      .then((res) => {
        this.setState({
          rowsPerPage: l,
          page: p,
          param: { ...newParam },
          loading: false,
          error: false,
          coupons: res.data,
          searchText: q,
        }, () => {
          if (
            Object.keys(newParam).find((key) => (newParam[key] !== param[key]))
          ) {
            Object.keys(newParam).forEach((item) => {
              if (!newParam[item]) {
                delete newParam[item];
              }
            });
            history.push({
              path: pathname,
              search: QueryString.stringify(newParam),
            });
          }
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleOnChange = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  onSubmitSearchText = () => {
    const {
      searchText, param,
    } = this.state;
    if (searchText !== param.q) {
      this.handleRequestProcessing({ q: searchText, p: 1 });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  render() {
    const {
      loading, error, param, coupons, rowsPerPage, page,
      searchText,
    } = this.state;
    const { history } = this.props;
    const headers = [
      {
        key: 'couponCode',
        displayText: 'Coupon',
        renderer: (data) => (
          <Button
            variant="link"
            onClick={() => {
              const query = QueryString.stringify(
                Utils.deleteEmptyField(param),
              );
              history.push(`/coupon/${data.couponId}${
                query ? `?${query}` : ''}`);
            }}
            className="fs-01 p-0"
          >
            {data.couponCode}
          </Button>
        ),
      },
      {
        key: 'validFrom',
        displayText: 'Valid From',
        renderer: (data) => Utils.dateString(data.validFrom),
      },
      {
        key: 'validFrom',
        displayText: 'Valid Till',
        renderer: (data) => Utils.dateString(data.validTill),
      },
      {
        key: 'paymentMode',
        displayText: 'Payment Mode',
        renderer: (data) => {
          let string = '';
          for (let i = 0; i < data.paymentMode.length; i += 1) {
            string = string.concat(`${i === 0 ? '' : ', '}${data.paymentMode[i].code}`);
          }
          return string;
        },
      },
      {
        key: 'discountType',
        displayText: 'Dis. Type',
      },
      {
        key: 'discountValue',
        displayText: 'Dis. Value',
      },
      {
        key: 'minSpendAmount',
        displayText: 'Min Spend Amount',
      },
      {
        key: 'numberOfUsage',
        displayText: 'Usage',
      },
      {
        key: 'restrictedCustomerPhonenumber',
        displayText: 'Customer',
        renderer: (data) => data.restrictedCustomerPhoneNumber.join(', '),
      },
    ];

    if (!coupons && loading) {
      return (
        <div
          className="d-flex align-items-center
             justify-content-center h-100"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!coupons && !loading && error) {
      return (
        <div
          className="d-flex align-items-center
             justify-content-center h-100"
        >
          <ErrorHandler
            retryLogic={() => {
              this.setState({
                loading: true,
                error: false,
              }, () => {
                this.handleLoad();
              });
            }}
          />
        </div>
      );
    }

    if (coupons) {
      return (
        <div
          id="coupon-list"
          className="h-100"
        >
          <ProcessingStatus
            show={loading || error}
            loading={loading}
            error={error}
            onRetry={() => this.retry()}
            onCancel={this.onCancel}
          />
          <Container
            fluid
            className="px-0 h-100 bg-white"
          >
            <Row
              className="mx-0 flex-column h-100"
            >
              <Col
                className="px-0 flex-grow-0"
              >
                <Row
                  className="mx-0 px-2 py-2"
                >
                  <Col
                    xs={24}
                    sm="auto"
                    className="pl-0 py-1"
                  >
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          className="rounded-0"
                        >
                          <Svg
                            svg="search"
                            width="1rem"
                            fill={Constant.Color.DARK}
                          />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        placeholder="Search by coupon code"
                        className="fs-01 rounded-0"
                        value={searchText}
                        onChange={this.handleOnChange}
                        autoComplete="off"
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            this.onSubmitSearchText();
                          }
                        }}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
              <Col
                className="px-0 flex-grow-1 overflow-y"
              >
                <CustomTable
                  isPaginated
                  headers={headers}
                  content={coupons.results}
                  keyField="couponId"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={coupons.count}
                  hasPrev={coupons.hasPrev}
                  hasNext={coupons.hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    return null;
  }
}

CouponListing.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default CouponListing;
