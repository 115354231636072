import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal } from '../../component/common';
import { removeStore } from '../../assets/api/axios';

function RemoveStore(props) {
  const {
    show, onHide, storeId, handleRequest, flashSaleCampaignId,
  } = props;
  const [removeStatus, setRemoveStatus] = useState('');

  const handleRemoveStore = () => {
    setRemoveStatus('loading');
    removeStore(
      'PATCH',
      {
        flashSaleCampaignId,
        storeId,
      },
    ).then(() => {
      setRemoveStatus('success');
      onHide();
      handleRequest();
    }).catch(() => {
      setRemoveStatus('error');
    });
  };

  const body = (
    <Container className="px-5 py-4">
      <Row className="justify-content-center">
        <b>Are you sure you want to remove this store?</b>
      </Row>
      <Row className="justify-content-center py-2">
        <Button
          onClick={onHide}
          className="mx-1"
        >
          NO
        </Button>
        <Button
          variant="danger"
          className="mx-1"
          onClick={handleRemoveStore}
        >
          YES
        </Button>
      </Row>
      {(() => {
        let showcase = null;
        if (removeStatus === 'loading') {
          showcase = (
            <Row className="justify-content-center py-1">
              <Spinner
                animation="border"
                variant="primary"
              />
            </Row>
          );
        } else if (removeStatus === 'error') {
          showcase = (
            <Row className="justify-content-center py-1">
              <span className="text-danger fs-1">
                Something went wrong. Retry!
              </span>
            </Row>
          );
        }
        return showcase;
      })()}
    </Container>
  );

  return (
    <CustomModal
      show={show}
      onHide={onHide}
      title="Remove Store"
      body={body}
      autoSize
      closeButton
      border
    />
  );
}

RemoveStore.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  storeId: PropTypes.string.isRequired,
  handleRequest: PropTypes.func.isRequired,
  flashSaleCampaignId: PropTypes.number.isRequired,
};

export default RemoveStore;
