import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Form, InputGroup, Row, Spinner,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import QueryString from 'query-string';
import {
  CustomModal, CustomTable, DatePicker, ErrorHandler,
} from '../../component/common';
import { ProcessingStatus } from '../../component/derived/table-list';
import { abandonCustomers } from '../../assets/api/axios';
import Svg from '../../component/common/svg/Search';
import { Constant } from '../../utilities';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, startDate, endDate,
  } = param;
  const {
    searchText = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  startDate = Number(startDate) || new Date().setHours(0, 0, 0, 999);
  endDate = Number(endDate) || new Date().setHours(23, 59, 59, 999);
  return ({
    l,
    p,
    startDate,
    endDate,
    searchText,
  });
};

class CartAbandonUsers extends React.Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    this.state = {
      loading: true,
      error: false,
      searchText: param.searchText,
      abandonUsers: null,
      indexOfUpdatingCustomer: -1,
      selectedCustomerId: '',
      updateCustomerErrorMsg: '',
      performingAction: '',
      rowsPerPage: param.l,
      page: param.p,
      param,
    };
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, startDate, endDate, searchText,
    } = newParam;
    const offset = (p - 1) * l;

    abandonCustomers(
      'GET',
      null,
      '',
      {
        offset: `${offset}`,
        limit: l,
        startDate,
        endDate,
        searchText,
      },
    ).then((res) => {
      this.setState({
        loading: false,
        abandonUsers: res.data,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitPage = () => {
    const { page, param } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  onSubmitRowsPerPage = () => {
    const { rowsPerPage, param } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  handleRowPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  handleOnChange = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  }

  onSubmitSearchText = () => {
    const {
      searchText, param,
    } = this.state;
    if (searchText !== param.searchText) {
      this.handleRequestProcessing({ searchText, p: 1 });
    }
  }

  handleDateChange = (startDate, endDate) => {
    const { param } = this.state;
    if (startDate !== param.startDate
      || endDate !== param.endDate) {
      this.handleRequestProcessing({
        startDate,
        endDate,
        p: 1,
      });
    }
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const { l, p } = param;
    if (loading) {
      this.source.cancel();
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
    });
  }

  updateCustomerData = (field, value) => {
    const { indexOfUpdatingCustomer, selectedCustomerId, abandonUsers } = this.state;
    if (
      indexOfUpdatingCustomer >= 0
      && abandonUsers.results[indexOfUpdatingCustomer].customerId === selectedCustomerId
    ) {
      const updateCustomers = abandonUsers.results;
      updateCustomers[indexOfUpdatingCustomer][field] = value;
      this.setState({
        selectedCustomerId: '',
        indexOfUpdatingCustomer: -1,
        abandonUsers: {
          ...abandonUsers,
          results: updateCustomers,
        },
      });
    }
  }

  patchCustomer = (data, action) => {
    abandonCustomers(
      'PATCH',
      data,
      '',
      {},
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          performingAction: '',
        });
        this.updateCustomerData(action, data[action]);
      } else {
        throw new Error();
      }
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong!! Please Try Again!!';
      if (
        err
        && err.response
        && err.response.status === 400
        && err.response.data
        && err.response.data.errors
        && err.response.data.errors[0].message
      ) {
        errorMsg = err.response.data.errors[0].message;
      }
      this.setState({
        updateCustomerErrorMsg: errorMsg,
      });
    });

    this.retryPatchCustomer = () => {
      this.setState({
        updateCustomerErrorMsg: '',
      });
      this.patchCustomer(data, action);
    };
  }

  patchCustomerDetails = async (value, customerDetails, index, action) => {
    this.setState({
      indexOfUpdatingCustomer: index,
      selectedCustomerId: customerDetails.customerId,
      performingAction: action,
    }, () => {
      this.patchCustomer(
        {
          [action]: value,
          customerId: customerDetails.customerId,
        },
        action,
      );
    });
  }

  render() {
    const {
      loading, error, abandonUsers, searchText,
      param, rowsPerPage, page,
      performingAction, selectedCustomerId, updateCustomerErrorMsg,
    } = this.state;

    const headers = [
      {
        key: 'customerId',
        displayText: 'Customer ID',
      },
      {
        key: 'customerPhoneNumber',
        displayText: 'Customer Phone Number',
      },
      {
        key: 'customerName',
        displayText: 'Customer Name',
      },
      {
        key: 'storeId',
        displayText: 'Store ID',
      },
      {
        key: 'storeName',
        displayText: 'Store Name',
      },
      {
        key: 'storePinCode',
        displayText: 'Store PIN Code',
      },
      {
        key: 'itemTotal',
        displayText: 'Number of Items in Cart',
      },
      {
        key: 'orderType',
        displayText: 'Order Type',
      },
      {
        key: 'cartTotal',
        displayText: 'Cart Total',
      },
      {
        key: 'assignedTo',
        displayText: 'Assigned To',
        renderer: (data, index) => {
          if (
            performingAction === 'assignedTo'
            && selectedCustomerId === data.customerId
            && !updateCustomerErrorMsg
          ) {
            return (
              <div
                className="pt-1 d-flex align-item-center
                justify-content-center"
              >
                <Spinner
                  variant="primary"
                  animation="border"
                  size="sm"
                />
              </div>
            );
          }
          return (
            <select
              onChange={(e) => {
                if (e.target.value !== '') {
                  this.patchCustomerDetails(e.target.value, data, index, 'assignedTo');
                }
              }}
              value={data.assignedTo}
              className={`p-1 ${performingAction ? 'pointer-event-none' : ''}`}
            >
              <option value="">Select</option>
              <option value="OM1">OM1</option>
              <option value="OM2">OM2</option>
              <option value="OM3">OM3</option>
              <option value="OM4">OM4</option>
              <option value="OM5">OM5</option>
              <option value="OM6">OM6</option>
              <option value="OM7">OM7</option>
              <option value="OM8">OM8</option>
              <option value="OM9">OM9</option>
              <option value="OM10">OM10</option>
            </select>
          );
        },
      },
      {
        key: 'action',
        displayText: 'Action',
        renderer: (data) => (
          <Link
            to={`/cart-abandon-details?customerId=${data.customerId}&cartId=${data.cartId}&storeId=${data.storeId}`}
            target="_blank"
          >
            MODIFY
          </Link>
        ),
      },
    ];

    return (
      <Container
        fluid
        className="h-100 bg-white px-0"
      >
        <CustomModal
          show={!!updateCustomerErrorMsg}
          onHide={() => this.setState({ updateCustomerErrorMsg: '' })}
          autoSize
          backdrop
          body={(
            <Container className="p-4">
              <Row className="mx-0 py-2 text-danger">
                {updateCustomerErrorMsg}
              </Row>
              <Row
                className="mx-0 py-2 d-flex align-items-center flex-row-reverse"
              >
                <Button
                  variant="link"
                  className="font-weight-bold"
                  onClick={this.retryPatchCustomer}
                >
                  RETRY
                </Button>
                <Button
                  variant="link"
                  className="font-weight-bold text-danger"
                  onClick={() => {
                    this.setState({
                      updateCustomerErrorMsg: '',
                      performingAction: '',
                    });
                  }}
                >
                  CANCEL
                </Button>
              </Row>
            </Container>
          )}
        />
        {(() => {
          let showcase = null;
          if (!abandonUsers && loading) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center justify-content-center"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (!abandonUsers && !loading && error) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center
                justify-content-center"
              >
                <ErrorHandler
                  retryLogic={() => this.retry()}
                />
              </div>
            );
          } else if (abandonUsers) {
            showcase = (
              <>
                <ProcessingStatus
                  show={loading || error}
                  loading={loading}
                  error={error}
                  onRetry={() => this.retry()}
                  onCancel={this.onCancel}
                />
                <Row
                  className="mx-0 px-3 py-1 filter-section"
                >
                  <Col
                    xs={24}
                    sm={5}
                    lg={5}
                    className="px-2 py-2"
                  >
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          className="rounded-0"
                        >
                          <Svg
                            svg="search"
                            width="1rem"
                            fill={Constant.Color.DARK}
                          />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        placeholder="Search with Customer Phone No, Name, Store ID"
                        className="fs-01 rounded-0"
                        value={searchText}
                        onChange={this.handleOnChange}
                        autoComplete="off"
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            this.onSubmitSearchText();
                          }
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col
                    xs="auto"
                    className="px-2 py-2"
                  >
                    <div
                      className="bg-light d-flex align-items-center justify-content-center"
                    >
                      <div className="px-2 fs-1 text-muted">
                        Cart last updated on
                      </div>
                      <DatePicker
                        isDateRange
                        onApply={(dateRange) => {
                          this.handleDateChange(
                            new Date(dateRange.startDate).getTime(),
                            new Date(dateRange.endDate).getTime(),
                          );
                        }}
                        timePicker
                        startDate={param.startDate}
                        endDate={param.endDate}
                        onClear={() => {
                          this.handleRequestProcessing({
                            startDate: '', endDate: '',
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mx-0">
                  <Col
                    xs={24}
                    className="px-0 table-section"
                  >
                    <CustomTable
                      headers={headers}
                      content={abandonUsers.results}
                      keyField="customerId"
                      l={param.l}
                      p={param.p}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      totalItems={abandonUsers.count}
                      hasPrev={abandonUsers.hasPrev}
                      hasNext={abandonUsers.hasNext}
                      onNext={this.onNext}
                      onPrev={this.onPrev}
                      onSubmitPage={this.onSubmitPage}
                      onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                      updateRowsPageInput={this.handleRowPageInput}
                    />
                  </Col>
                </Row>
              </>
            );
          }
          return showcase;
        })()}
      </Container>
    );
  }
}

CartAbandonUsers.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default CartAbandonUsers;
