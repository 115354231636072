import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Spinner, Button,
} from 'react-bootstrap';
import { orderDeliveryLogs } from '../../assets/api/axios';
import { dateString } from '../../utilities/Utils';
import { CustomModal, CustomTable } from '../../component/common';

const DeliveryPartnerMeta = (props) => {
  const { orderId, show, toggle3PLMetaModal } = props;
  const [loadStatus, setLoadStatus] = useState('');
  const [delivery3PLDetailsLogs, setDelivery3PLDetailsLogs] = useState([]);

  const load3PLLogs = () => {
    setLoadStatus('LOADING');
    orderDeliveryLogs(
      'GET',
      orderId,
    ).then((res) => {
      setDelivery3PLDetailsLogs(res.data.results);
      setLoadStatus('SUCCESS');
    }).catch(() => {
      setLoadStatus('ERROR');
    });
  };

  useEffect(() => {
    if (!loadStatus) {
      load3PLLogs();
    }
  });

  const retryFetching3PLLogs = () => {
    setLoadStatus('');
  };

  const delivery3PLDetailsConfig = [
    {
      key: 'deliveryId',
      displayText: 'Delivery Id',
    },
    {
      key: 'actionBy',
      displayText: 'Action By',
    },
    {
      key: 'deliveryAgentName',
      displayText: 'Delivery Agent Name',
    },
    {
      key: 'deliveryAgentNumber',
      displayText: 'Delivery Agent Number',
    },
    {
      key: 'deliveryFee',
      displayText: 'Delivery Fee',
    },
    {
      key: 'deliveryStatus',
      displayText: 'Delivery Status',
    },
    {
      key: 'deliveryTAT',
      displayText: 'Delivery TAT (min.)',
    },
    {
      key: 'deliveryDropTAT',
      displayText: 'Delivery Drop TAT',
      renderer: (rowData) => dateString(rowData.deliveryDropTAT),
    },
    {
      key: 'deliveryPickUpTAT',
      displayText: 'Delivery PickUp TAT',
      renderer: (rowData) => dateString(rowData.deliveryPickUpTAT),
    },
    {
      key: 'reason',
      displayText: 'Reason',
    },
  ];

  return (
    <CustomModal
      show={show}
      size="lg"
      onHide={toggle3PLMetaModal}
      closeButton
      body={(
        <Container className="p-4">
          {
            loadStatus === 'LOADING' && (
              <Col
                xs={24}
                className="text-primary text-center"
              >
                <Row className="py-2 justify-content-center d-flex">
                  <Spinner
                    variant="primary"
                    animation="border"
                  />
                </Row>
                <Row className="py-2 justify-content-center">
                  Fetching Delivery Partner Details
                </Row>
              </Col>
            )
          }
          {
            loadStatus === 'SUCCESS' && (
              <CustomTable
                keyField="deliveryId"
                headers={delivery3PLDetailsConfig}
                content={delivery3PLDetailsLogs}
                isPaginated={false}
                totalItems={delivery3PLDetailsLogs.length}
              />
            )
          }
          {
            loadStatus === 'ERROR' && (
              <Col xs={24} className="text-danger text-center p-2">
                Oops Something went wrong while fetching Delivery Partner Details logs,
                Please try again!!
                &nbsp;&nbsp;
                <Button
                  variant="link"
                  className="text-primary font-weight-bold"
                  onClick={() => { retryFetching3PLLogs(); }}
                >
                  RETRY
                </Button>
              </Col>
            )
          }
          <Col
            xs={24}
            className="d-flex justify-content-center"
          >
            <Button
              variant="outline-primary"
              onClick={() => { toggle3PLMetaModal(); }}
            >
              EXIT
            </Button>
          </Col>
        </Container>
      )}
    />
  );
};

DeliveryPartnerMeta.propTypes = {
  orderId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  show: PropTypes.bool.isRequired,
  toggle3PLMetaModal: PropTypes.func.isRequired,
};

export default DeliveryPartnerMeta;
