import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal, CustomTable, ErrorHandler } from '../../component/common';
import { couponCampaigns } from '../../assets/api/axios';
import { dateString } from '../../utilities/Utils';

function renderStatus(status) {
  return status
    ? <span className="text-success">&#10004;</span>
    : <span className="text-danger">&#10006;</span>;
}

function ViewCouponUsage(props) {
  const { show, onHide, campaignId } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [couponUsageDetails, setCouponUsageDetails] = useState(null);

  function handleLoad() {
    setLoading(true);
    setError(false);
    couponCampaigns(
      'GET',
      {},
      {},
      campaignId,
      'couponusage',
    ).then((res) => {
      setLoading(false);
      setCouponUsageDetails(res.data);
    }).catch(() => {
      setLoading(false);
      setError(true);
    });
  }
  const body = (
    <Container>
      <Row>
        <Col className="px-0">
          {(() => {
            let showBody = null;
            if (loading) {
              showBody = (
                <div
                  className="py-2 d-flex align-items-center
                  justify-content-center"
                >
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                </div>
              );
            } else if (!loading && error) {
              showBody = (
                <div
                  className="py-2 d-flex align-items-center
                  justify-content-center"
                >
                  <ErrorHandler
                    retryLogic={() => {
                      handleLoad();
                    }}
                  />
                </div>
              );
            } else if (couponUsageDetails) {
              showBody = (
                <CustomTable
                  headers={[
                    {
                      key: 'campaignId',
                      displayText: 'Campaign Id',
                    },
                    {
                      key: 'campaignName',
                      displayText: 'Campaign Name',
                    },
                    {
                      key: 'couponId',
                      displayText: 'Coupon Id',
                    },
                    {
                      key: 'couponCode',
                      displayText: 'Coupon Code',
                    },
                    {
                      key: 'timestamp',
                      displayText: 'Used On',
                      renderer: (data) => dateString(data.timestamp),
                    },
                    {
                      key: 'name',
                      displayText: 'Customer Name',
                      renderer: (data) => <span>{data.customer.name}</span>,
                    },
                    {
                      key: 'phone',
                      displayText: 'Customer Phone No.',
                      renderer: (data) => <span>{data.customer.phone}</span>,
                    },
                    {
                      key: 'store',
                      displayText: 'Store',
                      renderer: (data) => (
                        <>
                          {data.stores.map((item) => (
                            <div>
                              <div>
                                {item.code}
                              </div>
                              <div>
                                {item.name}
                              </div>
                            </div>
                          ))}
                        </>
                      ),
                    },
                    {
                      key: 'success',
                      displayText: 'Status',
                      renderer: (data) => renderStatus(data.success),
                    },
                    {
                      key: 'discount',
                      displayText: 'Discount',
                    },
                    {
                      key: 'billAmount',
                      displayText: 'Bill Amount',
                    },
                  ]}
                  content={couponUsageDetails.results}
                  keyField="campaignId"
                  totalItems={couponUsageDetails.count}
                  isPaginated={false}
                />
              );
            }
            return showBody;
          })()}
        </Col>
      </Row>
    </Container>
  );

  return (
    <CustomModal
      show={show}
      onHide={onHide}
      title="Coupon Usage"
      size="xl"
      body={body}
      onEnter={() => {
        handleLoad();
      }}
      closeButton
      backdrop
    />
  );
}

ViewCouponUsage.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  campaignId: PropTypes.string.isRequired,
};

export default ViewCouponUsage;
