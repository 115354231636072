import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal } from '../../component/common';
import { freeSampleCampaign } from '../../assets/api/axios';
import { getUserName } from '../../utilities/Storage';

function getOneMonthLaterDate() {
  const now = new Date();
  return new Date(now.setMonth(now.getMonth() + 1));
}

function CreateBrandCampaign(props) {
  const { show, onHide, handleRequestProcessing } = props;
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [invalidDate, setInvalidDate] = useState('');
  const [campaign, setCampaign] = useState({
    campaignName: '',
    productId: '',
    launchDate: new Date(),
    validTill: getOneMonthLaterDate(),
    mov: 0,
    totalStock: '',
  });
  const {
    campaignName, productId, launchDate, validTill, mov, totalStock,
  } = campaign;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCampaign({
      ...campaign,
      [name]: value,
    });
  };

  const reset = () => {
    setCampaign({
      campaignName: '',
      productId: '',
      launchDate: new Date(),
      validTill: getOneMonthLaterDate(),
      mov: 0,
      totalStock: '',
    });
    setSubmitting(false);
    setSubmitError('');
  };

  const handleCreateCampaign = () => {
    if (validTill <= launchDate) {
      setInvalidDate('Please Check!! Valid Till Date is Less than Launch Date');
      return;
    }
    setInvalidDate('');
    setSubmitting(true);
    setSubmitError('');
    freeSampleCampaign(
      'POST',
      '',
      {
        name: campaignName,
        productId,
        mov: parseInt(mov, 10),
        launchDate: parseInt((launchDate.getTime() / 1000), 10),
        validTill: parseInt((validTill.getTime() / 1000), 10),
        userId: getUserName(),
        totalStock: parseInt(totalStock, 10),
      },
    ).then((res) => {
      if (res.status === 200) {
        setSubmitting(false);
        onHide();
        reset();
        handleRequestProcessing();
      } else {
        throw new Error();
      }
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong';
      if (
        err
        && err.response
        && err.response.status === 400
        && err.response.data
        && err.response.data.errors
        && err.response.data.errors[0].message
      ) {
        errorMsg = err.response.data.errors[0].message;
      }
      setSubmitting(false);
      setSubmitError(errorMsg);
    });
  };

  const disabled = !campaignName || !productId || !launchDate || !validTill
    || !totalStock || submitting;

  const body = (
    <Container className="px-5 py-4">
      <Row>
        <Col className="main-container py-4 px-5 fs-01">
          <Row className="text-mid-gray">
            Campaign Name
            <span className="text-danger">*</span>
          </Row>
          <Row>
            <input
              className="form-control fs-0"
              type="text"
              placeholder="Enter Campaign Name"
              name="campaignName"
              value={campaignName}
              onChange={handleChange}
              autoComplete="off"
            />
          </Row>
          <Row className="text-mid-gray pt-4">
            Product Id
            <span className="text-danger">*</span>
          </Row>
          <Row>
            <input
              className="form-control fs-0"
              type="number"
              placeholder="Enter Product Id"
              name="productId"
              value={productId}
              onChange={handleChange}
              autoComplete="off"
            />
          </Row>
          <Row className="text-mid-gray pt-4">
            Launch Date
            <span className="text-danger">*</span>
          </Row>
          <Row>
            <input
              type="datetime-local"
              className="form-control"
              value={moment(launchDate).format('YYYY-MM-DDTHH:mm')}
              id="launchDate"
              onChange={(e) => {
                setCampaign({
                  ...campaign,
                  launchDate: new Date(e.target.value),
                });
              }}
            />
          </Row>
          <Row className="text-mid-gray pt-4">
            Valid Till
            <span className="text-danger">*</span>
          </Row>
          <Row>
            <input
              type="datetime-local"
              className="form-control"
              value={moment(validTill).format('YYYY-MM-DDTHH:mm')}
              id="validTill"
              onChange={(e) => {
                setCampaign({
                  ...campaign,
                  validTill: new Date(e.target.value),
                });
              }}
            />
            {
              invalidDate && (
                <span className="p-2 text-danger">
                  {invalidDate}
                </span>
              )
            }
          </Row>
          <Row className="text-mid-gray pt-4">
            Total Stock
            <span className="text-danger">*</span>
          </Row>
          <Row>
            <input
              className="form-control fs-0"
              type="number"
              placeholder="Enter Total Stock"
              name="totalStock"
              value={totalStock}
              onChange={handleChange}
              maxLength={3}
              autoComplete="off"
            />
          </Row>
          <Row className="text-mid-gray pt-4">
            MOV
          </Row>
          <Row>
            <input
              className="form-control fs-0"
              type="number"
              placeholder="Enter MOV"
              name="mov"
              value={mov}
              onChange={handleChange}
              maxLength={3}
              autoComplete="off"
            />
          </Row>
        </Col>
      </Row>
      <Row className="pt-4 d-flex justify-content-center">
        <Button
          variant="primary"
          className="fs-1 font-weight-bold p-2 border-radius-8"
          disabled={disabled}
          onClick={() => {
            handleCreateCampaign();
          }}
        >
          CREATE
        </Button>
      </Row>
      {
        submitting && (
          <Row
            className="pt-2 d-flex align-item-center
              justify-content-center"
          >
            <Spinner
              variant="primary"
              animation="border"
            />
          </Row>
        )
      }
      {
        !submitting && submitError && (
          <Row
            className="pt-2 d-flex align-item-center
              justify-content-center text-danger"
          >
            {submitError}
          </Row>
        )
      }
    </Container>
  );

  return (
    <CustomModal
      show={show}
      title="Create Brand Sample Campaign"
      onHide={() => {
        onHide();
        reset();
      }}
      autoSize
      body={body}
      closeButton
    />
  );
}

CreateBrandCampaign.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  handleRequestProcessing: PropTypes.func.isRequired,
};

export default CreateBrandCampaign;
