import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import { CustomModal, ErrorHandler } from '../../component/common';
import { agentSms, leadMeta } from '../../assets/api/axios';

class SmsDetails extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      error: false,
      smsTemplate: '',
      smsContent: null,
      processingPatch: false,
      patchError: false,
    };
  }

  componentDidMount = () => {
    this.loadSmsContent();
  }

  loadSmsContent = () => {
    const { leadId } = this.props;
    leadMeta(
      'GET',
      {
        metaType: 'sms_template',
        leadId,
      },
    ).then((res) => {
      this.setState({
        smsContent: res.data,
        loading: false,
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });
  }

  handleSendSms = () => {
    const { onHide, leadId, handleRequestProcessing } = this.props;
    const { smsTemplate } = this.state;
    agentSms(
      'POST',
      {
        leadId,
        templateId: smsTemplate.header,
      },
    ).then(() => {
      this.setState({
        processingPatch: false,
      }, () => {
        onHide();
        handleRequestProcessing();
      });
    }).catch(() => {
      this.setState({
        processingPatch: false,
        patchError: true,
      });
    });
  }

  reset = () => {
    this.setState({
      loading: false,
      error: false,
      smsTemplate: '',
      smsContent: null,
      processingPatch: false,
      patchError: false,
    });
  }

  render() {
    const { show, onHide, border } = this.props;
    const {
      loading, error, smsTemplate, smsContent,
      processingPatch, patchError,
    } = this.state;

    const body = (
      <>
        {(() => {
          let showcase = null;
          if (!smsContent && loading) {
            showcase = (
              <div
                className="text-center p-4"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (!smsContent && !loading && error) {
            showcase = (
              <ErrorHandler
                retryLogic={() => {
                  this.setState({
                    loading: true,
                    error: false,
                  }, () => {
                    this.loadSmsContent();
                  });
                }}
              />
            );
          } else if (smsContent) {
            showcase = (
              <Container>
                <Row>
                  <Col xs={24} className="mt-2 p-2 fs-2 text-center">
                    <b>SMS</b>
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col xs={24}>
                    <b>Sms Template</b>
                    <span className="text-danger">*</span>
                  </Col>
                  <Col xs={24} className="pt-2">
                    <select
                      name="smsTemplate"
                      className="form-control"
                      onChange={(event) => {
                        if (event.target.value !== '') {
                          this.setState({
                            smsTemplate: JSON.parse(event.target.value),
                          });
                        }
                      }}
                    >
                      <option value="">Select</option>
                      {
                        smsContent.results.map((content) => (
                          <option value={JSON.stringify(content)}>{content.header}</option>
                        ))
                      }
                    </select>
                  </Col>
                </Row>

                <Row className="pt-4">
                  <Col xs={24}>
                    <b>Message</b>
                  </Col>
                  <Col xs={24} className="pt-2">
                    {
                      (smsTemplate && smsTemplate.message) || '--'
                    }
                  </Col>
                </Row>

                <Row className="pt-4">
                  <Col xs={24} className="text-center">
                    {processingPatch
                      ? (
                        <Spinner
                          variant="primary"
                          animation="border"
                        />
                      ) : (
                        <>
                          <Button
                            variant="primary"
                            block
                            onClick={() => {
                              this.setState({
                                processingPatch: true,
                                patchError: false,
                              }, () => {
                                this.handleSendSms();
                              });
                            }}
                            disabled={!smsTemplate}
                          >
                            Send
                          </Button>
                          <div className="py-2 text-danger">
                            {!processingPatch && patchError
                            && (
                              <b>Oops Something went wrong!!</b>
                            )}
                          </div>
                        </>
                      )}
                  </Col>
                </Row>
              </Container>
            );
          }
          return showcase;
        })()}
      </>
    );

    return (
      <CustomModal
        show={show}
        title=" "
        body={body}
        onHide={onHide}
        closeButton
        autoSize
        backdrop={loading
          || error || 'static'}
        reset={this.reset}
        border={border}
      />
    );
  }
}

SmsDetails.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  border: PropTypes.bool,
  leadId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleRequestProcessing: PropTypes.func.isRequired,
};

SmsDetails.defaultProps = {
  leadId: '',
  border: false,
};

export default SmsDetails;
