import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Row, Spinner, Col, Container,
} from 'react-bootstrap';
import Select from 'react-select';
import { Svg } from '../../component/common';
import { Constant } from '../../utilities';
import '../../assets/scss/RetailerManagement/PromotionBrandingActivity.scss';
import { getBDEViewVisitsLogs, getPromoterBrandingLogs, uploadImage } from '../../assets/api/axios';
import moment from 'moment';

class CreateActivity extends React.Component {
  constructor(props) {
    super();
    const { singleActivity } = props;
    this.state = {
      activity: singleActivity || {},
      imageData: singleActivity ? (singleActivity.images || []).reduce((acc, i, index) => ({ ...acc, [`${index}`]: { fileData: null, fileLink: i } }), {}) : {},
      submitting: false,
      errorMsg: '',
    };
  }

  handleChange = (event) => {
    const { activity } = this.state;
    const { name, value } = event.target;
    const updatedSingleActivity = {
      ...activity,
      [name]: value,
    };
    this.setState({
      activity: updatedSingleActivity,
    });
  };


  onChangeImage = (event, index) => {
    const { activity, imageData } = this.state;
    const { images = [] } = activity;
    images[index] = URL.createObjectURL(event.target.files[0]);
    this.setState({
      imageData: {
        ...imageData,
        [index]: { fileData: event.target.files[0], fileLink: '' },
      },
      activity: {
        ...activity,
        images,
      },
    });
  };

  createActivity = (allImagesKeys) => {
    const { imageData, activity } = this.state;
    const {
      storeId, onCreateSuccess, onCloseSingleView, activityType, isModalType,
    } = this.props;

    let apiBasedOnModal;

    if (isModalType === 'promoter') {
      apiBasedOnModal = getPromoterBrandingLogs(
        activity.id ? 'PATCH' : 'POST',
        activity.id || '',
        {
          activity_date: new Date(activity.activityDate).getTime(),
          branding_type: activity.brandingType || [],
          images: allImagesKeys.map((i) => imageData[i].fileLink),
          promoter_name: activity.promoterName || '',
          activity_type: activity.activityType === 'Branding' || activityType === 'BRANDING' ? 'Branding' : 'Promotion',
          store_id: storeId,
        },
        {},
      );
    } else if (isModalType === 'BDEvisit') {
      apiBasedOnModal = getBDEViewVisitsLogs(
        activity.id ? 'PATCH' : 'POST',
        activity.id || '',
        {
          visitTimeStamp: new Date(activity.visitTimeStamp).getTime(),
          timeTakenInVisit: activity.timeTakenInVisit || '',
          storeCode: storeId,
          bdeName: activity.bdeName || '',
          bdeCode: activity.bdeCode || '',
          feedback: activity.feedback || '',
          visitOutcome: activity.visitOutcome || '',
          revisitTimeStamp: new Date(activity.revisitTimeStamp).getTime() || '0',
          revisitRemarks: activity.revisitRemarks || '',
        },
        {},
      );
    }

    if (apiBasedOnModal) {
      apiBasedOnModal
        .then(() => {
          onCloseSingleView();
          onCreateSuccess();
        })
        .catch(() => {
          this.setState({
            errorMsg: 'Oops! Something went wrong, please try again.',
            submitting: false,
          });
        });
      onCreateSuccess();
    } else {
      console.error('Invalid modal type:', isModalType);
    }
  };

  prepareForSubmit = () => {
    this.setState({
      submitting: true,
      errorMsg: '',
    });
  };

  submitActivity = async (e) => {
    e.preventDefault();
    this.prepareForSubmit();
    const { imageData } = this.state;
    let imageDataUpdated = imageData;
    const allImagesKeys = Object.keys(imageData);
    for (let i = 0; i < allImagesKeys.length; i += 1) {
      if (!imageDataUpdated[allImagesKeys[i]].fileLink) {
        const formData = new FormData();
        const d = document.getElementById(`image-${allImagesKeys[i]}`);
        formData.append('img', d.files[0]);
        // eslint-disable-next-line no-await-in-loop
        await uploadImage(
          'POST',
          formData,
        // eslint-disable-next-line no-loop-func
        ).then((res) => {
          imageDataUpdated = {
            ...imageDataUpdated,
            [allImagesKeys[i]]: {
              ...imageDataUpdated[allImagesKeys[i]],
              fileLink: res.data.url,
            },
          };
        }).catch(() => {
          this.setState({
            submitting: false,
            errorMsg: 'Error occured while uploading images.',
          });
        });
      }
    }
    this.setState({
      imageData: imageDataUpdated,
    }, () => {
      this.createActivity(allImagesKeys);
    });
  };

  render() {
    const {
      activityType,
      isModalType,
      isEditing,
    } = this.props;
    const { activity, submitting, errorMsg } = this.state;

    const brandingTypes = [
      {
        label: 'Dealer Boards',
        value: 'Dealer Boards',
      },
      {
        label: 'T-Shirts',
        value: 'T-Shirts',
      },
      {
        label: 'Bags',
        value: 'Bags',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ];

    return (
      <>
        {
      isModalType === 'promoter' && (
        <Container className="py-4">
          <Form onSubmit={this.submitActivity}>
            <Form.Group className="d-flex mx-0" as={Row}>
              <Form.Label as={Col} xs={6}>Performed On</Form.Label>
              <Col xs={18}>
                <Form.Control
                  name="activityDate"
                  type="date"
                  placeholder="Activity Performed On"
                  value={activity.activityDate ? activity.activityDate.split('T')[0] : ''}
                  onChange={this.handleChange}
                  isRequired
                />
              </Col>
            </Form.Group>
            {activity.activityType === 'Branding' || activityType === 'BRANDING' ? (
              <Form.Group className="d-flex  mx-0" as={Row}>
                <Form.Label as={Col} xs={6}>Branding Type</Form.Label>
                <Col xs={18}>
                  <Select
                    id="brandingType"
                    placeholder="Branding Type"
                    onChange={(selectedItems) => {
                      const selectedBrandingType = selectedItems.reduce(
                        (acc, selItem) => [...acc, selItem.value], [],
                      );
                      this.setState({
                        activity: {
                          ...activity,
                          brandingType: selectedBrandingType,
                        },
                      });
                    }}
                    selectedVal={activity.brandingType || ''}
                    options={brandingTypes}
                    isMulti
                    value={(activity.brandingType || []).map((val) => ({ label: val, value: val }))}
                    className="minw-150p z-index1004"
                  />
                </Col>
              </Form.Group>
            ) : (
              <Form.Group className="d-flex  mx-0" as={Row}>
                <Form.Label as={Col} xs={6}>Promoter Name</Form.Label>
                <Col xs={18}>
                  <Form.Control
                    name="promoterName"
                    type="text"
                    placeholder="Activity Promoter Name"
                    value={activity.promoterName || ''}
                    onChange={this.handleChange}
                    isRequired
                  />
                </Col>
              </Form.Group>
            )}

            <Form.Group className="d-flex  mx-0" as={Row}>
              <Form.Label as={Col} xs={6}>Activity Type</Form.Label>
              <Col xs={18}>
                { isEditing ? activity.activityType === 'Promotion' ? 'Promotion' : 'Branding' : activityType === 'PROMOTION' ? 'Promotion' : 'Branding'}
              </Col>
            </Form.Group>
            <Form.Group className="d-flex  mx-0" as={Row}>
              <Form.Label as={Col} xs={6}>Images</Form.Label>
              <Col xs={18} className="d-flex">
                {
                (activityType === 'PROMOTION' ? [0, 1, 2] : [0, 1, 2, 3, 4]).map((index) => (
                  <div className="image-box">
                    <input
                      type="file"
                      name={`img-${index}`}
                      id={`image-${index}`}
                      className="input-img"
                      onChange={(e) => { this.onChangeImage(e, index); }}
                    />
                    <div className="camera-svg">
                      <Svg
                        svg="camera"
                        fill={Constant.Color.WHITE}
                        width="1.4rem"
                        classNam=""
                      />
                    </div>
                    {
                      activity.images && activity.images[index]
                      && <img className="bg-image" alt="Activity Img" src={activity.images[index]} height={100} width={100} />
                    }
                  </div>
                ))
            }
              </Col>
            </Form.Group>
            <Form.Group className="d-flex justify-content-center">
              <Button
                name="save"
                type="submit"
                placeholder=""
                disabled={
                submitting
                || (activityType === 'BRANDING' && (!activity.brandingType || !activity.activityDate))
                || (activityType === 'PROMOTION' && (!activity.promoterName || !activity.activityDate))
              }
              >
                {
                submitting && (
                  <Spinner
                    animation="border"
                    variant="light"
                    size="sm"
                  />
                )
              }
                <span className="pl-2">Save</span>
              </Button>
            </Form.Group>
            {
            errorMsg && (
              <Form.Group className="d-flex justify-content-center text-danger">
                <span>{errorMsg}</span>
              </Form.Group>
            )
          }
          </Form>
        </Container>
      )
    }
        {
      isModalType === 'BDEvisit' && (
        <Container className="py-4">
          <Form onSubmit={this.submitActivity}>
            <Form.Group className="d-flex  mx-0" as={Row}>
              <Form.Label as={Col} xs={6}>BDE Name</Form.Label>
              <Col xs={18}>
                <Form.Control
                  name="bdeName"
                  type="text"
                  placeholder="BDE Name"
                  value={activity.bdeName || ''}
                  onChange={this.handleChange}
                  isRequired
                />
              </Col>
            </Form.Group>
            <Form.Group className="d-flex mx-0" as={Row}>
              <Form.Label as={Col} xs={6}>Visit timestamp</Form.Label>
              <Col xs={18}>
                <Form.Control
                  name="visitTimeStamp"
                  type="datetime-local"
                  placeholder="Visit timestamp"
                  value={moment(activity.visitTimeStamp ? activity.visitTimeStamp : '').format('YYYY-MM-DDTHH:mm')}
                  onChange={this.handleChange}
                  isRequired
                />
              </Col>
            </Form.Group>
            <Form.Group className="d-flex mx-0" as={Row}>
              <Form.Label as={Col} xs={6}>Time taken in visit</Form.Label>
              <Col xs={18}>
                <Form.Control
                  name="timeTakenInVisit"
                  type="text"
                  placeholder="Time taken in visit"
                  value={activity.timeTakenInVisit || ''}
                  onChange={this.handleChange}
                  isRequired
                />
              </Col>
            </Form.Group>
            <Form.Group className="d-flex  mx-0" as={Row}>
              <Form.Label as={Col} xs={6}>BDE Code</Form.Label>
              <Col xs={18}>
                <Form.Control
                  name="bdeCode"
                  type="text"
                  placeholder="BDE Code"
                  value={activity.bdeCode || ''}
                  onChange={this.handleChange}
                  isRequired
                />
              </Col>
            </Form.Group>
            <Form.Group className="d-flex  mx-0" as={Row}>
              <Form.Label as={Col} xs={6}>Feedback</Form.Label>
              <Col xs={18}>
                <Form.Control
                  name="feedback"
                  type="text"
                  placeholder="Feedback"
                  value={activity.feedback || ''}
                  onChange={this.handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group className="d-flex  mx-0" as={Row}>
              <Form.Label as={Col} xs={6}>Visit Outcome</Form.Label>
              <Col xs={18}>
                <Form.Control
                  name="visitOutcome"
                  type="text"
                  placeholder="Visit Outcome"
                  value={activity.visitOutcome || ''}
                  onChange={this.handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group className="d-flex  mx-0" as={Row}>
              <Form.Label as={Col} xs={6}>Revisit remarks</Form.Label>
              <Col xs={18}>
                <Form.Control
                  name="revisitRemarks"
                  type="text"
                  placeholder="Revisit remarks"
                  value={activity.revisitRemarks || ''}
                  onChange={this.handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group className="d-flex mx-0" as={Row}>
              <Form.Label as={Col} xs={6}>Revisit date</Form.Label>
              <Col xs={18}>
                <Form.Control
                  name="revisitTimeStamp"
                  type="datetime-local"
                  placeholder="Revisit date"
                  value={moment(activity.revisitTimeStamp ? activity.revisitTimeStamp : '').format('YYYY-MM-DDTHH:mm')}
                  // value={activity.revisitTimeStamp ? activity.revisitTimeStamp.split('T')[0] : ''}
                  onChange={this.handleChange}
                  isRequired
                />
              </Col>
            </Form.Group>
            <Form.Group className="d-flex justify-content-center">
              <Button
                name="save"
                type="submit"
                placeholder=""
                disabled={!activity.bdeName}
              >
                {
                submitting && (
                  <Spinner
                    animation="border"
                    variant="light"
                    size="sm"
                  />
                )
              }
                <span className="pl-2">Save</span>
              </Button>
            </Form.Group>
            {
            errorMsg && (
              <Form.Group className="d-flex justify-content-center text-danger">
                <span>{errorMsg}</span>
              </Form.Group>
            )
          }
          </Form>
        </Container>
      )
      }
      </>
    );
  }
}

CreateActivity.propTypes = {
  singleActivity: PropTypes.shape({ images: PropTypes.arrayOf(PropTypes.string) }).isRequired,
  onCloseSingleView: PropTypes.func.isRequired,
  onCreateSuccess: PropTypes.func.isRequired,
  storeId: PropTypes.string.isRequired,
  activityType: PropTypes.string.isRequired,
  isModalType: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

export default CreateActivity;