import React from 'react';

const CrossIcon = () => (
  <svg
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0a7 7 0 1 0 0 14A7 7 0 0 0 7 0Zm0 12.6A5.6 5.6 0 1 1 7 1.401 5.6 5.6 0 0 1 7 12.6Z"
      fill="#788898"
    />
    <path
      d="M8.897 5.103a.7.7 0 0 0-.994 0L7 6.013l-.903-.91a.703.703 0 0 0-.994.994l.91.903-.91.903a.7.7 0 0 0 .228 1.147.7.7 0 0 0 .766-.153L7 7.987l.903.91a.7.7 0 0 0 1.147-.228.7.7 0 0 0-.153-.766L7.987 7l.91-.903a.7.7 0 0 0 0-.994Z"
      fill="#788898"
    />
  </svg>
);

export default CrossIcon;
