import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width, fill }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width }}
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
  >
    <g
      id="Frames-24px"
    >
      <rect fill="none" style={{ width }} />
    </g>
    <g id="Outline">
      <g>
        <path
          fill={fill}
          d="M15.414,2H6C4.897,2,4,2.898,4,4v16c0,1.103,0.897,2,2,2h12c1.103,0,2-0.897,2-2V6.586L15.414,2z
  M18.001,20H6V4h8v4h4L18.001,20z"
        />
        <rect x="8" y="11" fill={fill} width="6" height="2" />
        <rect x="8" y="15" fill={fill} width="8" height="2" />
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
