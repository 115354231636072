import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal, Svg } from '../../component/common';
import { addStoreOnCampaign } from '../../assets/api/axios';
import { Constant } from '../../utilities';

function AddStores(props) {
  const {
    show, onHide, campaignId, handleRequestProcessing, sampleCsv,
  } = props;
  const [stores, setStores] = useState([{
    storeId: '',
    totalStock: 0,
  }]);
  const [file, setFile] = useState('');
  const [errorField, setErrorField] = useState(false);
  const [option, setOption] = useState('box');
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const hiddenFileInput = React.createRef();

  const handleChangeData = (index, e) => {
    const { name, value } = e.target;
    const newStoresData = Object.assign(
      [...stores],
      {
        [index]: {
          ...stores[index],
          [name]: value,
        },
      },
    );
    setStores(newStoresData);
  };

  const reset = () => {
    setFile('');
    setOption('box');
    setSubmitting(false);
    setSubmitError('');
    setStores([{
      storeId: '',
      totalStock: 0,
    }]);
  };

  const checkStoresData = () => {
    setSubmitError(false);
    setErrorField(false);
    let flag = true;
    stores.forEach((store) => {
      if (!store.storeId || !store.totalStock) { flag = false; }
    });
    return flag;
  };

  const handleAddStores = () => {
    setSubmitting(true);
    setSubmitError('');
    let data = {};
    if (option === 'box') {
      data = {
        campaignId,
        storeDetails: stores,
      };
    } else {
      const formData = new FormData();
      formData.append('csv', file);
      formData.append('campaignId', campaignId.toString());
      data = formData;
    }
    addStoreOnCampaign(
      'POST',
      data,
    ).then((res) => {
      if (res.status === 200) {
        setSubmitting(false);
        onHide();
        reset();
        handleRequestProcessing();
      } else {
        throw new Error();
      }
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong';
      if (
        err
        && err.response
        && err.response.status === 400
        && err.response.data
        && err.response.data.errors
        && err.response.data.errors[0].message
      ) {
        errorMsg = err.response.data.errors[0].message;
      }
      setSubmitError(errorMsg);
      setSubmitting(false);
    });
  };

  const handleRadioBtn = (e) => {
    setOption(e.target.id);
    setSubmitError('');
    setErrorField('');
  };

  const body = (
    <Container className="px-5 py-4">
      <Row className="mb-2 fs-1">
        <Col>
          <input
            type="radio"
            id="box"
            checked={option === 'box'}
            onChange={handleRadioBtn}
          />
          <span>&nbsp;Using Text Box</span>
        </Col>
        <Col>
          <input
            type="radio"
            id="csv"
            checked={option === 'csv'}
            onChange={handleRadioBtn}
          />
          <span>&nbsp;Using CSV</span>
        </Col>
      </Row>
      <Row>
        <Col className="main-container py-4 px-3 fs-01">
          {
            option === 'box' ? (
              <>
                {stores.map((store, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Row className="my-1 align-items-center" key={index + 1}>
                    <Col xs={8}>
                      <b>{`Store ${index + 1}`}</b>
                    </Col>
                    <Col>
                      <div>
                        <input
                          className="form-control fs-0"
                          type="text"
                          placeholder="Store Id"
                          name="storeId"
                          onChange={(e) => handleChangeData(index, e)}
                          required
                        />
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <input
                          className="form-control fs-0"
                          type="number"
                          placeholder="Quantity"
                          name="totalStock"
                          onChange={(e) => handleChangeData(index, e)}
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                ))}
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    setStores([
                      ...stores,
                      {
                        storeId: '',
                        totalStock: 0,
                      },
                    ]);
                  }}
                  className="my-2 fs-01"
                  disabled={submitting}
                >
                  Add Another Store
                </Button>
              </>
            ) : (
              <>
                {
                  sampleCsv && (
                    <Row className="my-1 align-items-center">
                      <Col xs={8}>
                        <b>CSV Format</b>
                      </Col>
                      <Col>
                        <Button
                          variant="link"
                          className="p-0 fs-01"
                          onClick={() => {
                            window.open(sampleCsv, '_blank');
                          }}
                        >
                          <Svg
                            svg="file"
                            width="1.3rem"
                            fill={Constant.Color.PRIMARY}
                          />
                        </Button>
                      </Col>
                    </Row>
                  )
                }
                <Row className="my-2 align-items-center">
                  <Col xs={8}>
                    <b>Choose CSV File</b>
                  </Col>
                  <Col>
                    <div>
                      <input
                        type="file"
                        className="d-none"
                        accept=".xls,.xlsx,.csv"
                        ref={hiddenFileInput}
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                      />
                      <Button
                        variant="primary"
                        className="fs-01 font-weight-bold"
                        onClick={() => {
                          hiddenFileInput.current.click();
                        }}
                      >
                        UPLOAD CSV
                      </Button>
                      {
                        file && <span>{file.name}</span>
                      }
                    </div>
                  </Col>
                </Row>
              </>
            )
          }
        </Col>
      </Row>
      <Row className="pt-4 d-flex justify-content-center">
        <Button
          variant="primary"
          className="fs-1 font-weight-bold p-2 border-radius-8"
          disabled={(!file && option === 'csv') || submitting}
          onClick={() => {
            if (option === 'box') {
              if (checkStoresData()) handleAddStores();
              else setErrorField(true);
            } else {
              handleAddStores();
            }
          }}
        >
          Add Stores
        </Button>
      </Row>
      {
        errorField && (
          <Row
            className="pt-2 d-flex align-item-center
              justify-content-center text-danger"
          >
            Please add storeId / quantity of every store
          </Row>
        )
      }
      {
        submitting && (
          <Row
            className="pt-2 d-flex align-item-center
              justify-content-center"
          >
            <Spinner
              variant="primary"
              animation="border"
            />
          </Row>
        )
      }
      {
        !submitting && submitError && (
          <Row
            className="pt-2 d-flex align-item-center
              justify-content-center text-danger"
          >
            {submitError}
          </Row>
        )
      }
    </Container>
  );

  return (
    <CustomModal
      show={show}
      onHide={() => {
        onHide();
        reset();
      }}
      title="Add Stores to a Campaign"
      body={body}
      autoSize
      closeButton
      border
    />
  );
}

AddStores.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  campaignId: PropTypes.string.isRequired,
  handleRequestProcessing: PropTypes.func.isRequired,
  sampleCsv: PropTypes.string.isRequired,
};

export default AddStores;
