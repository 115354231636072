import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import {
  Col, Container, ListGroup, Row,
} from 'react-bootstrap';
import { storeList } from '../../assets/api/axios';

const { CancelToken } = Axios;
let axiosController = null;

class ShopCataloguePage extends Component {
  constructor() {
    super();
    this.state = {
      searchText: '',
      activeTimeout: -1,
      stores: [],
      isSearchingStore: false,
    };
  }

  handleSearch = (event) => {
    const { value } = event.target;
    const { activeTimeout } = this.state;
    if (activeTimeout !== -1) {
      clearTimeout(activeTimeout);
    }
    if (axiosController) {
      axiosController.cancel();
    }
    if (value) {
      axiosController = CancelToken.source();
      this.setState({
        searchText: value,
        stores: [],
      }, () => {
        const timeOutId = setTimeout(() => {
          this.handleStoreSearch();
          this.setState({ activeTimeout: -1 });
        }, 300);
        this.setState({ activeTimeout: timeOutId });
      });
      return;
    }
    this.setState({
      searchText: value,
      activeTimeout: -1,
      stores: [],
    });
  }

  handleStoreSearch = () => {
    const { searchText } = this.state;
    this.setState({
      isSearchingStore: true,
    });
    storeList(
      'GET',
      null,
      searchText,
      axiosController.token,
    ).then((res) => {
      this.setState({
        stores: res.data.results,
        isSearchingStore: false,
      });
    }).catch(() => {});
  }

  render() {
    const { searchText, stores, isSearchingStore } = this.state;
    const { history } = this.props;
    return (
      <Container
        fluid
        className="bg-light"
      >
        <Row>
          <Col
            xs={24}
            className="pt-3"
          >
            <input
              className="p-4 h-50 w-100 shadow-sm form-control text-primary"
              value={searchText}
              type="search"
              onChange={this.handleSearch}
              placeholder="Search Store by Store Id"
              autoComplete="off"
            />
          </Col>
        </Row>
        {
          stores && stores.length > 0
          && (
            <Row
              className="position-absolute p-3 m-0 w-50 shadow-lg bg-white rounded search-customer-list"
            >
              <Col>
                {stores.map((store) => (
                  <ListGroup
                    key={store.code}
                    onClick={() => {
                      history.push(`/retailer/${store.code}?view=product`);
                    }}
                    variant="flush"
                  >
                    <ListGroup.Item
                      action
                      variant="light"
                    >
                      <b>{store.code}</b>
                      <b className={`${store.code ? 'pl-3' : 'pl-0'}`}>{store.name}</b>
                    </ListGroup.Item>
                  </ListGroup>
                ))}
              </Col>
            </Row>
          )
        }
        {
          (searchText && !stores.length && !isSearchingStore)
          && (
            <Row
              className="position-absolute p-3 m-0 shadow-lg bg-white rounded search-customer-list"
            >
              <Col
                xs={24}
                className="pb-2 fs-1 text-medium"
              >
                No Store Found !!!
              </Col>
            </Row>
          )
        }
      </Container>
    );
  }
}

ShopCataloguePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default ShopCataloguePage;
