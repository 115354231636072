import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.26 4.69H.973A.973.973 0 0 0 0 5.664V21.71l3.452-3.452H16.26a.973.973 0 0 0 .972-.972V5.663a.973.973 0 0 0-.972-.972ZM3.668 13.119A1.468 1.468 0 0 1 2.2 11.652c0-.81.658-1.467 1.467-1.467s1.466.658 1.466 1.467c0 .808-.657 1.466-1.466 1.466Zm4.766 0a1.468 1.468 0 0 1-1.466-1.466c0-.81.657-1.467 1.466-1.467.81 0 1.467.658 1.467 1.467 0 .808-.658 1.466-1.467 1.466Zm4.767 0a1.468 1.468 0 0 1-1.467-1.466c0-.81.658-1.467 1.467-1.467s1.467.658 1.467 1.467c0 .808-.658 1.466-1.467 1.466Z"
      fill="#292997"
    />
    <path
      d="M21.028.29H4.639a.973.973 0 0 0-.972.973v2.694H16.26c.94 0 1.706.766 1.706 1.706v9.289H22V1.263a.973.973 0 0 0-.972-.972Z"
      fill="#292997"
    />
  </svg>
);

Svg.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default Svg;
