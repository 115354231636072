import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ width }}
    viewBox="0 0 40 24"
  >
    <g fill={fill} fillRule="evenodd">
      <rect width="40" height="24" fill="#B8238E" fillRule="nonzero" rx="2" />
      <text fill="#FFF" fontFamily="Lato-Bold, Lato" fontSize="12" fontWeight="bold">
        <tspan x="5.75" y="16">NEW</tspan>
      </text>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
