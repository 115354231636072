import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal, ErrorHandler } from '../../component/common';
import { getDateString, getInitials } from '../../utilities/Utils';
import { myOrders } from '../../assets/api/axios';

class ViewMyRecentOrders extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      error: false,
      orders: null,
    };
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleLoad = () => {
    const { startDate, endDate } = this.props;
    myOrders(
      'GET',
      {},
      {
        startDate,
        endDate,
      },
    ).then((res) => {
      this.setState({
        orders: res.data,
        loading: false,
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: false,
      });
    });
  }

  render() {
    const { show, onHide } = this.props;
    const { loading, error, orders } = this.state;

    let body = <div />;

    if (!orders && loading) {
      body = (
        <div
          className="text-center p-4"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!orders && !loading && error) {
      body = (
        <ErrorHandler
          retryLogic={() => {
            this.setState({
              loading: true,
              error: false,
            }, () => {
              this.handleLoad();
            });
          }}
        />
      );
    }
    if (orders) {
      body = (
        <Container
          className="px-4 py-3"
        >
          {orders.results.map((item, index) => (
            <Row
              className="py-2 justify-content-between"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <Col
                xs="auto"
                className="pr-0 d-flex align-items-center"
              >
                <div
                  className="emp-image border p-0 bg-primary text-white
                    d-flex align-items-center justify-content-center"
                >
                  <span className="font-weight-bold">
                    {getInitials(item.storeName)}
                  </span>
                </div>
              </Col>
              <Col>
                <Row>
                  <Col
                    className="font-weight-bold"
                  >
                    {item.storeName}
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="pt-1 text-muted fs-0"
                  >
                    {getDateString(item.createdOn)}
                  </Col>
                </Row>
              </Col>
              <Col
                xs="auto"
                className="d-flex align-items-center justify-content-end"
              >
                <div
                  className={`${item.bgColor} ${item.textColor} py-1 px-2 order-button fs-0`}
                >
                  {item.status}
                </div>
              </Col>
              <Col
                xs={4}
                className="d-flex align-items-center justify-content-end
                  font-weight-bold text-mid-gray"
              >
                &#8377; &nbsp;
                {item.billTotal}
              </Col>
            </Row>
          ))}
        </Container>
      );
    }

    return (
      <CustomModal
        show={show}
        onHide={onHide}
        title={(<b>My Orders</b>)}
        size="lg"
        closeButton
        backdrop
        body={body}
      />
    );
  }
}

ViewMyRecentOrders.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

ViewMyRecentOrders.defaultProps = {
  startDate: '',
  endDate: '',
};

export default ViewMyRecentOrders;
