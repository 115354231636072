import React, { Component } from 'react';
import {
  Col, Row, Container, Spinner,
  Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import { genericBulkUpload, productBulkUpload } from '../../assets/api/axios';
import { ProcessingStatus } from '../../component/derived/table-list';
import { CustomTable, ErrorHandler } from '../../component/common';
import Permission from '../../access&permissions/permission';
import { dateString } from '../../utilities/Utils';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  const {
    t = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    l,
    p,
    t,
  });
};

class ProductBulkUpload extends Component {
  constructor(props) {
    super(props);
    const { userPermission } = props;
    this.canIEdit = userPermission
      && userPermission.includes(Permission.PRODUCT_BULK_UPLOAD_WRITE);
    const param = getQueryParams(props.history.location.search);
    this.state = {
      selectedTab: !this.canIEdit ? 'list' : param.t || 'create',
      rowsPerPage: param.l,
      page: param.p,
      error: false,
      loading: true,
      productUploadJobs: null,
      param,
      file: null,
      uploadType: 'BulkAddProduct',
      uploadStatus: '',
      errorMessage: '',
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    const { selectedTab } = this.state;
    if (selectedTab === 'list') {
      this.handleLoad();
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param, selectedTab } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
        && (selectedTab === 'list')
      ))
    ) {
      this.handleLoad({ ...newParam, t: selectedTab });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  handleBulkUpload = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  }

  handleSubmit = () => {
    const { file, uploadType } = this.state;
    const formData = new FormData();
    formData.append('data', file);
    formData.append('uploadType', uploadType);
    this.setState({
      uploadStatus: 'loading',
    });
    genericBulkUpload(
      'POST',
      formData,
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState(
            {
              uploadStatus: 'success',
              errorMessage: '',
              file: null,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  uploadStatus: '',
                });
                this.handleChangeTab('list');
              }, 2000);
            },
          );
        }
      })
      .catch((err) => {
        if (
          err
          && err.response
          && err.response.data
          && err.response.data.server_response
        ) {
          this.setState({
            uploadStatus: 'error',
            errorMessage: err.response.data.server_response,
          });
          return;
        }
        this.setState({
          uploadStatus: 'error',
          errorMessage: 'Oops! Something went wrong.',
        });
      });
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p,
    } = newParam;
    const offset = (p - 1) * l;
    productBulkUpload(
      'get',
      null,
      {
        offset: `${offset}`,
        limit: l,
      },
      this.source.token,
    ).then((res) => {
      this.setState({
        productUploadJobs: { ...res.data },
        loading: false,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (
          Object.keys(newParam).find((key) => (newParam[key] !== param[key]))
        ) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleChangeTab = (currentTab) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param, selectedTab } = this.state;
    const newParam = { ...param, t: currentTab };
    if (currentTab !== selectedTab) {
      this.setState({
        selectedTab: currentTab,
      }, () => {
        history.push({
          path: pathname,
          search: QueryString.stringify(newParam),
        });
      });
    }
  }

  render() {
    const {
      selectedTab, loading, error, productUploadJobs,
      rowsPerPage, page, param, uploadType,
      uploadStatus, file, errorMessage,
    } = this.state;
    const { history } = this.props;

    const headers = [
      {
        key: 'id',
        displayText: 'Bulk Upload Id',
        renderer: (data) => (
          <Button
            variant="link"
            className="p-0 fs-01"
            onClick={() => {
              history.push(`/product-bulk-upload-details/${data.id}`);
            }}
          >
            {data.id}
          </Button>
        ),
      },
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => (
          <div>
            {
              data.createdOn
                ? dateString(data.createdOn)
                : 'NA'
            }
          </div>
        ),
      },
    ];

    let tableShowCase = null;
    if (!productUploadJobs && loading) {
      tableShowCase = (
        <Row>
          <Col
            xs={24}
            className="text-center pt-3"
          >
            <Spinner
              animation="border"
              variant="primary"
            />
          </Col>
        </Row>
      );
    }
    if (!productUploadJobs && !loading && error) {
      tableShowCase = (
        <Row>
          <Col
            xs={24}
            className="text-center pt-3"
          >
            <ErrorHandler
              retryLogic={() => this.retry()}
            />
          </Col>
        </Row>
      );
    }
    if (productUploadJobs) {
      tableShowCase = (
        <Row>
          <Col
            xs={24}
            className="px-0 table-section"
          >
            <ProcessingStatus
              show={loading || error}
              loading={loading}
              error={error}
              onRetry={() => this.retry()}
              onCancel={this.onCancel}
            />
            <CustomTable
              headers={headers}
              content={productUploadJobs.results}
              keyField="_id"
              l={param.l}
              p={param.p}
              rowsPerPage={rowsPerPage}
              page={page}
              totalItems={productUploadJobs.count}
              hasPrev={productUploadJobs.hasPrevious}
              hasNext={productUploadJobs.hasNext}
              onNext={this.onNext}
              onPrev={this.onPrev}
              onSubmitPage={this.onSubmitPage}
              onSubmitRowsPerPage={this.onSubmitRowsPerPage}
              updateRowsPageInput={this.handleRowsPageInput}
            />
          </Col>
        </Row>
      );
    }
    return (
      <Container
        fluid
        className="bg-white h-100"
      >
        <Row>
          <Col
            xs={12}
            className={`text-center border p-2 cursor-pointer ${
              selectedTab === 'create'
                ? 'border-primary text-primary shadow-sm'
                : 'text-medium'
            } ${this.canIEdit ? '' : 'd-none'}`}
            onClick={() => this.handleChangeTab('create')}
          >
            <b>Product Bulk Upload</b>
          </Col>
          <Col
            xs={12}
            className={`text-center border p-2 cursor-pointer ${
              selectedTab === 'list'
                ? 'border-primary text-primary shadow-sm'
                : 'text-medium'
            }`}
            onClick={() => this.handleChangeTab('list')}
          >
            <b>Product Upload Jobs</b>
          </Col>
        </Row>
        {
          selectedTab === 'create' ? (
            <Container
              className="p-3 mx-auto"
            >
              <Row>
                <Col
                  xs={12}
                >
                  <b>
                    Upload Type:&nbsp;
                  </b>
                  <span>
                    PRODUCT RECOMMENDATION UPLOAD
                  </span>
                </Col>
                <Col
                  xs={12}
                >
                  <b>Required Field:&nbsp;</b>
                  <span>
                    {`
                      'name', 'mrp', 'selling_price',
                      'product_image', 'barcode', 'category',
                      'measurement_value', 'measurement_unit'
                    `}
                  </span>
                </Col>
              </Row>
              <Row
                className="py-3"
              >
                <Col
                  xs={12}
                >
                  <input
                    type="file"
                    className="form-control"
                    accept={
                      `.csv,
                      application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                      application/vnd.ms-excel`
                    }
                    onChange={this.handleBulkUpload}
                  />
                </Col>
                <Col
                  xs={12}
                >
                  {
                    uploadStatus === 'loading' ? (
                      <Spinner
                        variant="primary"
                        animation="border"
                      />
                    ) : (
                      <Button
                        variant="primary"
                        className="py-1 px-3"
                        disabled={
                          !uploadType
                          || !file
                        }
                        onClick={() => {
                          this.handleSubmit();
                        }}
                      >
                        Submit
                      </Button>
                    )
                  }
                </Col>
              </Row>
              <Row>
                {
                  (uploadStatus === 'success') && (
                    <Col
                      xs={24}
                      className="text-green"
                    >
                      File Uploaded Successfully!!
                    </Col>
                  )
                }
                {
                  (!!errorMessage) && (
                    <Col
                      xs={24}
                      className="text-danger"
                    >
                      {errorMessage}
                    </Col>
                  )
                }
              </Row>
            </Container>
          ) : (
            <>
              {tableShowCase}
            </>
          )
        }
      </Container>
    );
  }
}

ProductBulkUpload.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default ProductBulkUpload;
