import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <g clipPath="url(#a)">
      <path
        fill="#DEDEDE"
        stroke="#5C5C5C"
        d="M.5 10.976V4.024L6 .589l5.5 3.435v6.952L6 14.41.5 10.977Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h12v15H0z" />
      </clipPath>
    </defs>
  </svg>
);

Svg.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Svg.defaultProps = {
  width: 12,
  height: 15,
};

export default Svg;
