import React, { Component } from 'react';
import {
  Container, Row, Col, Button, Spinner,
  InputGroup,
  Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import { llPlusPurchasesList } from '../../assets/api/axios';
import {
  CustomDropdown,
  CustomTable,
  DatePicker,
  Svg,
} from '../../component/common';
import { dateString } from '../../utilities/Utils';
import { Constant } from '../../utilities';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
    startDate,
    endDate,
  } = param;
  const {
    phoneNumber = '',
    paymentId = '',
    source,
    standalonePurchase,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  startDate = Number(startDate) || new Date().getTime() - (1 * 24 * 60 * 60 * 1000);
  startDate = new Date(startDate).setHours(0, 0, 0, 0);
  endDate = Number(endDate) || new Date().setHours(23, 59, 59, 999);
  return ({
    ...param,
    l,
    p,
    startDate,
    endDate,
    phoneNumber,
    paymentId,
    source,
    standalonePurchase,
  });
};

class LLPlusOrders extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    this.state = {
      hasNext: false,
      hasPrevious: false,
      rowsPerPage: param.l,
      page: param.p,
      loading: true,
      error: false,
      purchasesList: null,
      stDate: param.startDate,
      eDate: param.endDate,
      paymentId: param.paymentId,
      phoneNumber: param.phoneNumber,
      param,
      newParam: param,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  onSubmitSearchText = () => {
    const {
      searchText, param,
    } = this.state;
    if (searchText !== param.searchText) {
      this.handleRequestProcessing({ searchText, p: 1 });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const {
      param, stDate, eDate, paymentId, phoneNumber,
    } = this.state;
    const newParam = {
      ...param,
      ...data,
      startDate: stDate,
      endDate: eDate,
      paymentId,
      phoneNumber,
    };
    const {
      l, p, startDate, endDate, source, standalonePurchase,
    } = newParam;
    const offset = (p);
    llPlusPurchasesList(
      'GET',
      null,
      {
        page: `${offset}`,
        limit: l,
        paymentId,
        phoneNumber,
        source,
        standalonePurchase,
        startDate,
        endDate,
      },
      this.source.token,
    ).then((res) => {
      this.setState({
        purchasesList: res.data,
        loading: false,
        hasNext: !!res.data.next,
        hasPrevious: !!res.data.prev,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handlePaymentDateChange = (startDate, endDate) => {
    this.setState({
      stDate: startDate,
      eDate: endDate,
    });
    // this.applyFilters();
  }

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleDropdownChange = (data) => {
    this.setState((state) => ({
      newParam: { ...state.newParam, ...data },
    }));
  }

  onSubmitPhoneNumber = () => {
    const {
      phoneNumber, param,
    } = this.state;
    if (phoneNumber !== param.phoneNumber) {
      this.handleRequestProcessing({ phoneNumber, p: 1 });
    }
  }

  onSubmitPaymentId = () => {
    const {
      paymentId, param,
    } = this.state;
    if (paymentId !== param.paymentId) {
      this.handleRequestProcessing({ paymentId, p: 1 });
    }
  }

  applyFilters = () => {
    const { newParam, paymentId, phoneNumber } = this.state;
    this.handleRequestProcessing({
      ...newParam,
      paymentId,
      phoneNumber,
      p: 1,
    });
  }

  render() {
    const {
      param, loading, error, purchasesList,
      rowsPerPage, page, hasNext, hasPrevious,
      stDate, eDate, paymentId, phoneNumber,
      newParam,
    } = this.state;

    const headers = [
      {
        key: 'customerName',
        displayText: 'Customer Name',
      },
      {
        key: 'customerPhone',
        displayText: 'Customer Phone',
      },
      {
        key: 'paymentId',
        displayText: 'Payment Id',
      },
      {
        key: 'source',
        displayText: 'Source',
      },
      {
        key: 'standalonePurchase',
        displayText: 'Standalone Purchase',
        renderer: (data) => (
          <b className={data.standalonePurchase ? 'text-green' : 'text-danger'}>
            {data.standalonePurchase ? 'YES' : 'NO'}
          </b>
        ),
      },
      {
        key: 'status',
        displayText: 'Status',
      },
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => (
          <>{dateString(data.createdOn)}</>
        ),
      },
      {
        key: 'startDate',
        displayText: 'Valid From',
        renderer: (data) => (
          <>{dateString(data.startDate)}</>
        ),
      },
      {
        key: 'endDate',
        displayText: 'Valid To',
        renderer: (data) => (
          <>{dateString(data.endDate)}</>
        ),
      },
      {
        key: 'ctProfile',
        displayText: 'CT Profile',
      },
      {
        key: 'subscriptionAmount',
        displayText: 'Subscription Amount',
      },
    ];

    const filterConf = [
      {
        key: 'source',
        displayText: 'Source',
        options: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'WEB',
            value: 'WEB',
          },
          {
            label: 'ANDROID',
            value: 'APP',
          },
          {
            label: 'IOS',
            value: 'IOS_APP',
          },
        ],
      },
      {
        key: 'standalonePurchase',
        displayText: 'Standalone Purchase',
        options: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'TRUE',
            value: 'true',
          },
          {
            label: 'FALSE',
            value: 'false',
          },
        ],
      },
    ];

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        {/* Filters */}
        <Row
          className="py-2"
        >
          <Col
            xs={24}
          >
            <Row
              className="pb-3"
            >
              <Col
                xs={24}
                sm="auto"
                className="px-2 py-1"
              >
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className="rounded-0"
                    >
                      <Svg
                        svg="search"
                        width="1rem"
                        fill={Constant.Color.DARK}
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    placeholder="Phone Number"
                    className="fs-01 rounded-0"
                    value={phoneNumber}
                    name="phoneNumber"
                    onChange={this.handleOnChange}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        this.onSubmitPhoneNumber();
                      }
                    }}
                  />
                </InputGroup>
              </Col>
              <Col
                xs={24}
                sm="auto"
                className="px-2 py-1"
              >
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className="rounded-0"
                    >
                      <Svg
                        svg="search"
                        width="1rem"
                        fill={Constant.Color.DARK}
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    placeholder="Payment Id"
                    className="fs-01 rounded-0"
                    value={paymentId}
                    name="paymentId"
                    onChange={this.handleOnChange}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        this.onSubmitPaymentId();
                      }
                    }}
                  />
                </InputGroup>
              </Col>
              {filterConf.map((item) => (
                <Col
                  key={item.key}
                  xs={24}
                  sm="auto"
                  className="px-2 py-1"
                >
                  <CustomDropdown
                    item={item}
                    onChange={this.handleDropdownChange}
                    selectedVal={newParam[item.key]}
                  />
                </Col>
              ))}
              <Col
                xs="auto"
                className="d-flex align-items-center"
              >
                <div
                  className="fs-1 font-weight-bold"
                >
                  Date:
                  &nbsp;
                </div>
                <div>
                  <DatePicker
                    isDateRange
                    onApply={(dateRange) => {
                      this.handlePaymentDateChange(
                        new Date(dateRange.startDate).getTime(),
                        new Date(dateRange.endDate).getTime(),
                      );
                    }}
                    timePicker
                    startDate={stDate}
                    endDate={eDate}
                    maxDate={new Date().setHours(23, 59, 59, 999)}
                    onClear={() => {
                      this.setState({
                        stDate: '',
                        eDate: '',
                      });
                    }}
                  />
                </div>
              </Col>

              <Col
                xs="auto"
                className="d-flex align-items-center"
              >
                <Button
                  variant="primary"
                  className="py-1 fs-1 font-weight-bold"
                  onClick={() => {
                    this.applyFilters();
                  }}
                >
                  APPLY FILTERS
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row
          className="h-100 flex-column"
        >
          {
            (loading || error) && (
              <div
                className="pt-3 text-center"
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                ) : (
                  <>
                    <span
                      className="text-danger"
                    >
                      Something Went Wrong
                    </span>
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => this.retry()}
                      >
                        Retry
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )
          }
          {
            !!purchasesList && !loading && !error && (
              <Col
                className="px-0 flex-grow-1 overflow-y pb-3"
              >
                <CustomTable
                  headers={headers}
                  content={purchasesList.data}
                  keyField="id"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={purchasesList.count}
                  hasPrev={hasPrevious}
                  hasNext={hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            )
          }
        </Row>
      </Container>
    );
  }
}

LLPlusOrders.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default LLPlusOrders;
