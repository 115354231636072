import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';

const VerifyOnboardingDetails = (props) => {
  const {
    details, buttonTitle, showDetails, loading, error,
    isDisabled, handleGetDetails,
  } = props;
  return (
    <Container
      fluid
      className="p-0 m-0"
    >
      <Row>
        <Col
          xs={24}
          className="text-center py-3"
        >
          <Button
            disabled={isDisabled}
            onClick={() => {
              handleGetDetails();
            }}
          >
            {buttonTitle}
          </Button>
        </Col>
      </Row>
      {
        !showDetails && loading && (
          <div
            className="d-flex align-items-center justify-content-center pb-1"
          >
            <Spinner
              animation="border"
              variant="primary"
            />
          </div>
        )
      }
      {
        !showDetails && !loading && error && (
          <div
            className="text-danger text-center pb-1"
          >
            {error}
          </div>
        )
      }
      {
        showDetails && (
          details.map((item) => (
            <Row
              className="border-top py-1"
              key={item.id}
            >
              <Col
                xs={12}
                className="border-right text-medium fs-01"
              >
                <b>{item.label}</b>
              </Col>
              <Col
                xs={12}
                className="text-wrap break-word"
              >
                {item.value}
              </Col>
            </Row>
          ))
        )
      }
    </Container>
  );
};

VerifyOnboardingDetails.propTypes = {
  details: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  buttonTitle: PropTypes.string.isRequired,
  showDetails: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleGetDetails: PropTypes.func.isRequired,
};

export default VerifyOnboardingDetails;
