import React, { Component } from 'react';
import {
  Container, Row, Col, Spinner, Button, InputGroup, Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Axios from 'axios';
import QueryString from 'query-string';
import { customerOperation } from '../../assets/api/axios';
import { copyToClipboard, dateString } from '../../utilities/Utils';
import {
  CustomDropdown, CustomModal, CustomTable, DatePicker, Svg,
} from '../../component/common';
import { Constant } from '../../utilities';
import CustomerCallPatch from './CustomerCallPatch';
import Permission from '../../access&permissions/permission';
import SMSTemplate from '../../component/common/SMSTemplate';
import WhatAppTemplate from '../../component/common/WhatsAppTemplate';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, startDate, endDate,
  } = param;
  const {
    q = '',
    storeIds = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  startDate = Number(startDate) || '';
  endDate = Number(endDate) || '';
  return ({
    l,
    p,
    q,
    storeIds,
    startDate,
    endDate,
  });
};

class Customer extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    const { userPermission } = props;
    this.canIEdit = userPermission
      && userPermission.includes(Permission.CUSTOMER_WRITE);
    this.state = {
      rowsPerPage: param.l,
      page: param.p,
      customers: null,
      searchText: param.q,
      param,
      loading: true,
      error: false,
      flag: null,
      operation: '',
      submitting: false,
      submittingError: false,
      selectCustomer: '',
      selectedCustomerIds: [],
      performingAction: '',
      responseMessage: '',
      textCopied: '',
      showCallOptions: false,
      exotelNumberCode: '',
      customerNumber: '',
      showExotelCallModal: false,
      storeIds: param.storeIds,
      stDate: param.startDate,
      eDate: param.endDate,
      selectedNumber: '',
      smsModal: false,
      whatsappModal: false,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param, stDate, eDate } = this.state;
    const newParam = {
      ...param,
      ...data,
      startDate: stDate,
      endDate: eDate,
    };
    const {
      l, p, q, storeIds, startDate, endDate,
    } = newParam;
    const offset = (p - 1) * l;
    customerOperation(
      'GET',
      {
        offset,
        limit: l,
        phoneNumbers: q,
        storeIds,
        startDate,
        endDate,
      },
      null,
      this.source.token,
    ).then((res) => {
      this.setState({
        customers: res.data,
        loading: false,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
        selectCustomer: '',
        selectedCustomerIds: [],
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onSubmitSearchText = () => {
    const {
      searchText, param,
    } = this.state;
    if (searchText !== param.q) {
      this.handleRequestProcessing({ q: searchText, p: 1 });
    }
  }

  handleStoreIdSearch = () => {
    const {
      storeIds, param,
    } = this.state;
    if (storeIds !== param.storeIds) {
      this.handleRequestProcessing(
        {
          storeIds,
          p: 1,
        },
      );
    }
  }

  handleDateChange = (startDate, endDate) => {
    this.setState({
      stDate: startDate,
      eDate: endDate,
    }, () => {
      this.handleRequestProcessing({ p: 1 });
    });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleCustomerSelection = (e, id) => {
    const { checked } = e.target;
    const { selectedCustomerIds } = this.state;
    let newCustomerIds = selectedCustomerIds;
    if (checked) {
      newCustomerIds.push(id);
    } else {
      newCustomerIds = newCustomerIds.filter(
        (item) => item !== id,
      );
    }
    this.setState({
      selectedCustomerIds: newCustomerIds,
    });
  }

  handleSelectCustomer = (data) => {
    const { selectCustomer } = data;
    const { customers } = this.state;
    this.setState({
      selectedCustomerIds: selectCustomer === 'all'
        ? (customers.results).map((item) => item.id)
        : [],
      selectCustomer,
    });
  }

  handlePatchStatus = () => {
    const { selectedCustomerIds, operation, flag } = this.state;
    const data = {
      operation,
      body: {
        customerIds: selectedCustomerIds,
        flag,
      },
    };
    customerOperation(
      'POST',
      {},
      data,
      this.source.token,
    ).then((res) => {
      const { selectCustomer, customers } = this.state;
      let updatedCustomers = customers.results;
      if (!selectCustomer) {
        updatedCustomers = customers.results.map((item) => {
          if (item.id === selectedCustomerIds[0]) {
            return res.data;
          }
          return item;
        });
      }
      this.setState({
        customers: {
          ...customers,
          results: updatedCustomers,
        },
        selectedCustomerIds: [],
        operation: '',
        flag: null,
        performingAction: '',
        selectCustomer: '',
        submitting: false,
        loading: !!selectCustomer,
        responseMessage: res && res.data && res.data.server_response ? res.data.server_response : '',
      }, () => {
        if (selectCustomer) {
          this.handleLoad();
          setTimeout(() => {
            this.setState({
              responseMessage: '',
            });
          }, 5000);
        } else {
          const dropDown = document.getElementById(`select-${selectedCustomerIds[0]}`);
          dropDown.selectedIndex = 0;
        }
      });
    }).catch(() => {
      this.setState({
        submitting: false,
        submittingError: true,
      });
    });
  }

  copyText = (text) => {
    copyToClipboard(text);
    this.setState({
      textCopied: text,
    });
    setTimeout(() => {
      this.setState({
        textCopied: '',
      });
    }, 1000);
  }

  render() {
    const {
      param, page, customers, rowsPerPage, loading, error, searchText, submitting,
      submittingError, selectedCustomerIds, selectCustomer, performingAction, responseMessage,
      storeIds, stDate, eDate, textCopied, showCallOptions, customerNumber,
      exotelNumberCode, showExotelCallModal, selectedNumber, smsModal, whatsappModal,
    } = this.state;

    const customerBlockOptions = [
      {
        label: 'BLACKLIST FROM LL CAMPAIGN',
        value: '{"operation":"MARK_ALLOWED_FOR_LL_CAMPAIGN", "actionString": "BLACKLIST FROM LL CAMPAIGN", "flag":false}',
      },
      {
        label: 'WHITELIST FROM LL CAMPAIGN',
        value: '{"operation":"MARK_ALLOWED_FOR_LL_CAMPAIGN", "actionString": "WHITELIST FROM LL CAMPAIGN", "flag":true}',
      },
      {
        label: 'BLACKLIST FROM ORDER CHECKOUT',
        value: '{"operation":"MARK_ALLOWED_FOR_ORDER_CHECKOUT", "actionString": "BLACKLIST FROM ORDER CHECKOUT", "flag":false}',
      },
      {
        label: 'WHITELIST FROM ORDER CHECKOUT',
        value: '{"operation":"MARK_ALLOWED_FOR_ORDER_CHECKOUT", "actionString": "WHITELIST FROM ORDER CHECKOUT", "flag":true}',
      },
      {
        label: 'BLACKLIST FROM ORDER CHECKOUT AND LL CAMPAIGN',
        value: '{"operation":"MARK_ALLOWED_FOR_ORDER_CHECKOUT_AND_LL_CAMPAIGN", "actionString": "BLACKLIST FROM ORDER CHECKOUT AND LL CAMPAIGN", "flag":false}',
      },
      {
        label: 'WHITELIST FROM ORDER CHECKOUT AND LL CAMPAIGN',
        value: '{"operation":"MARK_ALLOWED_FOR_ORDER_CHECKOUT_AND_LL_CAMPAIGN", "actionString": "WHITELIST FROM ORDER CHECKOUT AND LL CAMPAIGN", "flag":true}',
      },
    ];

    let headers = [
      {
        key: 'phoneNumber',
        displayText: 'Phone Number',
        renderer: (data) => (
          <Row className="align-items-center min-w150">
            <Button
              variant="link"
              onClick={() => {
                this.setState({
                  showCallOptions: true,
                  customerNumber: data.phoneNumber,
                });
              }}
              className="flex-1 fs-01 py-0"
            >
              {data.phoneNumber}
            </Button>
            <Button
              variant="link"
              className="p-0"
              onClick={() => {
                this.copyText(data.phoneNumber);
              }}
            >
              {
                textCopied === data.phoneNumber
                  ? (
                    <Svg
                      svg="circleDone"
                      width={20}
                      fill={Constant.Color.GREEN}
                    />
                  ) : (
                    <Svg
                      svg="copy"
                      fill={Constant.Color.PRIMARY}
                      width={24}
                    />
                  )
              }
            </Button>
          </Row>
        ),
      },
      {
        key: 'action',
        displayText: 'Action',
        renderer: (data) => (
          <Row className="flex_no_wrap">
            <Button
              variant="link"
              onClick={() => {
                this.setState({
                  whatsappModal: true,
                  selectedNumber: data.phoneNumber,
                });
              }}
            >
              <Svg
                svg="whatsapp"
                width="24"
                height="24"
              />
            </Button>
            <Button
              variant="link"
              onClick={() => {
                this.setState({
                  smsModal: true,
                  selectedNumber: data.phoneNumber,
                });
              }}
            >
              <Svg
                svg="sms"
                width="24"
                height="24"
              />
            </Button>
          </Row>
        ),
      },
      {
        key: 'name',
        displayText: 'Customer Name',
      },
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => (
          dateString(data.createdOn)
        ),
      },
      {
        key: 'totalOrderCount',
        displayText: 'Total Order Count',
      },
      {
        key: 'deliveredOrderCount',
        displayText: 'Delivered Order Count',
      },
      {
        key: 'cancelledOrderCount',
        displayText: 'Cancelled Order Count',
      },
      {
        key: 'fromOrderBlacklisted',
        displayText: 'Allowed for Order',
        renderer: (data) => (
          <span className={`${data.fromOrderBlacklisted ? 'text-danger' : 'text-green'}`}>
            {data.fromOrderBlacklisted ? 'NO' : 'YES'}
          </span>
        ),
      },
      {
        key: 'isAllowedForLoveLocalCouponCampaign',
        displayText: 'Allowed For LoveLocal Coupon Campaign',
        renderer: (data) => (
          <span className={`${data.isAllowedForLoveLocalCouponCampaign ? 'text-green' : 'text-danger'}`}>
            {data.isAllowedForLoveLocalCouponCampaign ? 'YES' : 'NO'}
          </span>
        ),
      },
      ...this.canIEdit ? [
        {
          key: 'selectOperation',
          displayText: 'Select Operation',
          renderer: (data) => (
            <select
              id={`select-${data.id}`}
              className="form-control fs-01"
              onChange={(event) => {
                if (event.target.value) {
                  const values = JSON.parse(event.target.value);
                  this.setState({
                    selectedCustomerIds: [data.id],
                    flag: values.flag,
                    operation: values.operation,
                    performingAction: values.actionString,
                  });
                }
              }}
              defaultValue=""
            >
              <option value="">Select</option>
              {
                data.isAllowedForLoveLocalCouponCampaign
                  ? (
                    <option value='{"operation":"MARK_ALLOWED_FOR_LL_CAMPAIGN", "actionString": "BLACKLIST FROM LL CAMPAIGN", "flag":false}'>BLACKLIST FROM LL CAMPAIGN</option>
                  ) : (
                    <option value='{"operation":"MARK_ALLOWED_FOR_LL_CAMPAIGN", "actionString": "WHITELIST FROM LL CAMPAIGN", "flag":true}'>WHITELIST FROM LL CAMPAIGN</option>
                  )
              }
              {
                !data.fromOrderBlacklisted
                  ? (
                    <option value='{"operation":"MARK_ALLOWED_FOR_ORDER_CHECKOUT", "actionString": "BLACKLIST FROM ORDER CHECKOUT", "flag":false}'>BLACKLIST FROM ORDER CHECKOUT</option>
                  ) : (
                    <option value='{"operation":"MARK_ALLOWED_FOR_ORDER_CHECKOUT", "actionString": "WHITELIST FROM ORDER CHECKOUT", "flag":true}'>WHITELIST FROM ORDER CHECKOUT</option>
                  )
              }
              {
                !data.fromOrderBlacklisted && data.isAllowedForLoveLocalCouponCampaign
                  && (
                    <option value='{"operation":"MARK_ALLOWED_FOR_ORDER_CHECKOUT_AND_LL_CAMPAIGN", "actionString": "BLACKLIST FROM ORDER CHECKOUT AND LL CAMPAIGN", "flag":false}'>BLACKLIST FROM ORDER CHECKOUT AND LL CAMPAIGN</option>
                  )
              }
              {
                data.fromOrderBlacklisted && !data.isAllowedForLoveLocalCouponCampaign
                  && (
                    <option value='{"operation":"MARK_ALLOWED_FOR_ORDER_CHECKOUT_AND_LL_CAMPAIGN", "actionString": "WHITELIST FROM ORDER CHECKOUT AND LL CAMPAIGN", "flag":true}'>WHITELIST FROM ORDER CHECKOUT AND LL CAMPAIGN</option>
                  )
              }
            </select>
          ),
        },
      ] : [],
    ];

    if (selectCustomer) {
      headers = [
        {
          key: 'selectAction',
          displayText: (
            <CustomDropdown
              item={{
                key: 'action',
                displayText: 'Action',
                options: customerBlockOptions,
              }}
              onChange={(data) => {
                if (selectedCustomerIds.length > 0) {
                  const values = JSON.parse(data.action);
                  this.setState({
                    flag: values.flag,
                    operation: values.operation,
                    performingAction: values.actionString,
                  });
                }
              }}
              selectedVal=""
              disabled={submitting}
            />
          ),
          renderer: (data) => {
            const customer = selectedCustomerIds.find((item) => (
              item === data.id
            ));
            return (
              <Form.Check
                custom
                id={`${data.id}-checkbox`}
                label=""
                checked={!!customer}
                onChange={(e) => {
                  this.handleCustomerSelection(e, data.id);
                }}
              />
            );
          },
        },
      ].concat(headers);
    }

    if (loading || error) {
      return (
        <div
          className="pt-3 text-center"
        >
          {loading ? (
            <Spinner
              animation="border"
              variant="primary"
            />
          ) : (
            <>
              <span
                className="text-danger"
              >
                Something Went Wrong
              </span>
              <div>
                <Button
                  variant="primary"
                  onClick={() => this.retry()}
                >
                  Retry
                </Button>
              </div>
            </>
          )}
        </div>
      );
    }

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        <CustomModal
          show={selectedCustomerIds && !!performingAction}
          body={(
            <div
              className="p-3"
            >
              <b>Are you sure?</b>
              <div
                className="pt-1"
              >
                {`You are performing '${performingAction}' operation.`}
              </div>
            </div>
          )}
          footer={(
            <>
              {
                submittingError && (
                  <b
                    className="text-danger"
                  >
                    Error: Please try again!!
                  </b>
                )
              }
              <Button
                variant="link"
                className="text-black"
                disabled={submitting}
                onClick={() => {
                  this.setState({
                    performingAction: '',
                    submittingError: false,
                    submitting: false,
                  }, () => {
                    const dropDown = document.getElementById(`select-${selectedCustomerIds[0]}`);
                    dropDown.selectedIndex = 0;
                  });
                }}
              >
                <b>GO BACK</b>
              </Button>
              {
                selectedCustomerIds && submitting ? (
                  <Spinner
                    variant="danger-dark"
                    animation="border"
                    size="sm"
                  />
                ) : (
                  <Button
                    variant="link"
                    className="text-danger-dark"
                    onClick={() => {
                      this.setState({
                        submitting: true,
                        submittingError: false,
                      }, () => {
                        this.handlePatchStatus();
                      });
                    }}
                    disabled={submitting}
                  >
                    <b>SUBMIT</b>
                  </Button>
                )
              }
            </>
          )}
          onHide={() => {
            this.setState({
              performingAction: '',
              submittingError: false,
              submitting: false,
            });
          }}
          autoSize
        />
        <CustomModal
          show={showCallOptions}
          title="Call Initiate"
          onHide={() => {
            this.setState({
              showCallOptions: false,
            });
          }}
          autoSize
          backdrop
          closeButton
          body={(
            <Container className="p-4">
              <Row
                className="h-100 d-flex align-items-center
                justify-content-center"
              >
                <Button
                  variant="link"
                  className="font-weight-bold pl-5"
                  onClick={() => {
                    this.setState({
                      showCallOptions: false,
                      exotelNumberCode: 'CRM',
                      showExotelCallModal: true,
                    });
                  }}
                >
                  CRM
                </Button>

                <Button
                  variant="link"
                  className="font-weight-bold pl-5"
                  onClick={() => {
                    this.setState({
                      showCallOptions: false,
                      exotelNumberCode: 'OPS',
                      showExotelCallModal: true,
                    });
                  }}
                >
                  OPS
                </Button>
              </Row>
            </Container>
          )}
        />
        {
          showExotelCallModal && (
            <CustomerCallPatch
              show={showExotelCallModal}
              onHide={() => {
                this.setState({
                  showExotelCallModal: false,
                });
              }}
              callFrom={exotelNumberCode}
              callTOCustomer={customerNumber}
            />
          )
        }
        {
          whatsappModal && (
            <WhatAppTemplate
              show={whatsappModal}
              onHide={() => this.setState({ whatsappModal: false })}
              phoneNumber={selectedNumber}
              handleRequestProcessing={() => {}}
            />
          )
        }
        {
          smsModal && (
            <SMSTemplate
              show={smsModal}
              onHide={() => this.setState({ smsModal: false })}
              phoneNumber={selectedNumber}
              handleRequestProcessing={() => {}}
            />
          )
        }
        <Row
          className="py-2"
        >
          <Col
            xs={24}
            sm="auto"
            className="px-2"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Search Phone Numbers(Comma separated)"
                name="searchText"
                className="fs-01 rounded-0"
                value={searchText}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.onSubmitSearchText();
                  }
                }}
              />
            </InputGroup>
          </Col>
          <Col
            xs={24}
            sm="auto"
            className="px-2"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Search Store Id"
                name="storeIds"
                className="fs-01 rounded-0"
                value={storeIds}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.handleStoreIdSearch();
                  }
                }}
              />
            </InputGroup>
          </Col>
          {
            this.canIEdit && (
              <Col
                xs="auto"
              >
                <CustomDropdown
                  item={{
                    key: 'selectCustomer',
                    displayText: 'Select Customer',
                    options: [
                      {
                        label: 'All',
                        value: 'all',
                      },
                      {
                        label: 'Manually',
                        value: 'manually',
                      },
                      {
                        label: 'None',
                        value: '',
                      },
                    ],
                  }}
                  closeButton={false}
                  onChange={this.handleSelectCustomer}
                  selectedVal={selectCustomer}
                  disabled={!!performingAction}
                />
              </Col>
            )
          }
          <Col
            xs="auto"
            className="d-flex"
          >
            <div className="pt-1 pr-2 fs-1">
              Order Created On:
            </div>
            <div>
              <DatePicker
                isDateRange
                onApply={(dateRange) => {
                  this.handleDateChange(
                    new Date(dateRange.startDate).setHours(0, 0, 0, 0),
                    new Date(dateRange.endDate).setHours(23, 59, 59, 999),
                  );
                }}
                startDate={stDate}
                endDate={eDate}
                onClear={() => {
                  this.setState({
                    stDate: '',
                    eDate: '',
                  }, () => {
                    this.handleRequestProcessing({ p: 1 });
                  });
                }}
              />
            </div>
          </Col>
          {
            responseMessage && (
              <Col
                xs="auto"
                className="d-flex align-items-center fs-01"
              >
                <span className="text-primary">Server Response:</span>
                &nbsp;
                {responseMessage}
              </Col>
            )
          }
        </Row>
        <Row>
          <Col
            xs={24}
            className="px-0"
          >
            <CustomTable
              headers={headers}
              content={customers.results}
              keyField="id"
              l={param.l}
              p={param.p}
              rowsPerPage={rowsPerPage}
              page={page}
              totalItems={customers.count}
              hasPrev={customers.hasPrev}
              hasNext={customers.hasNext}
              onNext={this.onNext}
              onPrev={this.onPrev}
              onSubmitPage={this.onSubmitPage}
              onSubmitRowsPerPage={this.onSubmitRowsPerPage}
              updateRowsPageInput={this.handleRowsPageInput}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

Customer.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default Customer;
