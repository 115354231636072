import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Row, Form, Container, InputGroup,
  Spinner,
} from 'react-bootstrap';
import {
  CustomTable, ErrorHandler, Svg,
} from '../../../../component/common';
import { Constant } from '../../../../utilities';
import { addAlternateStoreProduct, retailerRequest } from '../../../../assets/api/axios';
import { getDefaultImageUrl } from '../../../../utilities/Utils';

export default function LibraryProduct(props) {
  const {
    onCancel, handleAddProductInList, orderItems,
    storeId, handleAddNewlyCreatedProduct,
  } = props;
  const [searchText, setSearchText] = useState('');
  const [loadingStatus, setLoadingStatus] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [l, setL] = useState(10);
  const [p, setP] = useState(1);

  const handleLoad = (params = {}) => {
    setLoadingStatus('loading');
    const newParams = { page, rowsPerPage, ...params };
    // const offset = ((newParams.page - 1) * newParams.rowsPerPage);
    addAlternateStoreProduct(
      'GET',
      {
        page: newParams.page,
        limit: l,
      },
      storeId,
      searchText,
    ).then((res) => {
      setPage(newParams.page);
      setRowsPerPage(newParams.rowsPerPage);
      setP(newParams.page);
      setL(newParams.rowsPerPage);
      setSearchResult(res.data);
      setLoadingStatus('success');
    }).catch(() => {
      setLoadingStatus('');
    });
  };

  function checkSearchProductExistance(list, element) {
    let flag = false;
    list.forEach((item) => {
      if (Number(item.id) === element.libraryProductId) { flag = true; }
    });
    return flag;
  }

  function renderStatus(status) {
    return status
      ? <span className="text-success">&#10004;</span>
      : <span className="text-danger">&#10006;</span>;
  }

  function handleCreateProductFromLibrary(product) {
    retailerRequest(
      'POST',
      {
        storeId,
        productIds: [product.libraryProductId],
      },
    ).then((res) => {
      const { results } = res.data;
      const { status } = results.length ? results[0] : '';
      const { StoreProductID, LibraryProductID } = results[0];
      if (status === 'APPROVED') {
        const newSearchResult = searchResult.data.map((item) => {
          if (item.libraryProductId === product.libraryProductId) {
            return { ...item, inInventory: true, inStock: true };
          }
          return { ...item };
        });
        setSearchResult({ ...searchResult, data: newSearchResult });
        handleAddNewlyCreatedProduct('FROMLIBRARY', {
          quantity: 1,
          displayName: product.name,
          mrp: product.mrp,
          sellingPrice: product.sellingPrice || product.mrp,
          id: product.libraryProductId,
          retailerProductId: StoreProductID.id,
          image: LibraryProductID.image,
        });
      } else {
        const newSearchResult = searchResult.data.map((item) => {
          if (item.libraryProductId === product.libraryProductId) {
            return {
              ...item, inInventory: true, inStock: true,
            };
          }
          return { ...item };
        });
        setSearchResult({ ...searchResult, data: newSearchResult });
        handleAddProductInList({
          id: product.libraryProductId,
          retailerProductId: StoreProductID.id,
          displayName: product.name,
          mrp: product.mrp,
          sellingPrice: product.sellingPrice || product.mrp,
          image: product.image,
          quantity: 1,
          isExisting: true,
        });
      }
    }).catch(() => { });
  }

  const onNext = () => {
    handleLoad({ page: page + 1 });
  };

  const onPrev = () => {
    handleLoad({ page: page - 1 });
  };

  const onSubmitRowsPerPage = () => {
    handleLoad();
  };

  const handleRowsPageInput = (value, field) => {
    if (field === 'rowsPerPage') { setRowsPerPage(value); } else { setPage(value); }
  };

  const onSubmitPage = () => {
    handleLoad();
  };

  const searchProductHeaders = [
    {
      key: 'name',
      displayText: 'Product Name',
    },
    {
      key: 'mrp',
      displayText: 'MRP',
    },
    {
      key: 'sellingPrice',
      displayText: 'SP',
      renderer: (product) => <span>{product.inInventory ? product.sellingPrice : ''}</span>,
    },
    {
      key: 'image',
      displayText: 'Image',
      renderer: (product) => (
        <img
          alt=""
          src={product.image ? product.image : getDefaultImageUrl()}
          width="50px"
          height="50px"
        />
      ),
    },
    {
      key: 'isInventory',
      displayText: 'In Inventory',
      renderer: (product) => renderStatus(product.inInventory),
    },
    {
      key: 'select',
      displayText: 'Add Product',
      renderer: (product) => (
        product.inInventory
          ? (
            <Button
              variant="link"
              disabled={checkSearchProductExistance(orderItems, product) || !product.inStock}
              onClick={() => handleAddProductInList(
                {
                  id: product.libraryProductId,
                  retailerProductId: product.retailerProductId,
                  displayName: product.name,
                  mrp: product.mrp,
                  sellingPrice: product.sellingPrice,
                  image: product.image,
                  quantity: 1,
                  isExisting: true,
                },
              )}
            >
              {checkSearchProductExistance(orderItems, product) ? 'Added' : 'Select'}
            </Button>
          )
          : (
            <Button
              variant="link"
              className={product.requested ? 'text-danger pointer-event-none' : ''}
              disabled={checkSearchProductExistance(orderItems, product)}
              onClick={() => handleCreateProductFromLibrary(product)}
            >
              {(() => {
                if (product.requested) { return 'Not In Stock'; }
                if (checkSearchProductExistance(orderItems, product)) {
                  return 'Added';
                }
                return '+Add';
              })()}
            </Button>
          )
      ),
    },
  ];

  return (
    <Container
      fluid
    >
      <Row>
        <Col
          className="px-0"
        >
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text
                className="rounded-0"
              >
                <Svg
                  svg="search"
                  width="1rem"
                  fill={Constant.Color.DARK}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="search"
              placeholder="search"
              autoFocus
              className="fs-1 rounded-0"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={(e) => {
                if (e.which === 13) {
                  handleLoad({ page: 1 });
                }
              }}
            />
          </InputGroup>
        </Col>
        <Col
          xs="auto"
          className="px-0 ml-2"
        >
          <Button
            className="rounded-0"
            onClick={onCancel}
          >
            <Svg
              svg="close"
              width="1rem"
              fill={Constant.Color.WHITE}
            />
          </Button>
        </Col>
      </Row>
      <Row>
        {
          loadingStatus === 'loading' && (
            <Col
              xs={24}
              className="w-100 pt-3 d-flex align-items-center justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </Col>
          )
        }
        {
          loadingStatus === 'error' && (
            <Col
              xs={24}
              className="h-100 d-flex align-items-center
                  justify-content-center"
            >
              <ErrorHandler
                retryLogic={() => {
                  handleLoad();
                }}
              />
            </Col>
          )
        }
        {
          loadingStatus === 'success' && (
            <Col
              xs={24}
              className="px-0"
            >
              <CustomTable
                headers={searchProductHeaders}
                content={searchResult.data}
                keyField="id"
                totalItems={searchResult.count}
                isPaginated
                rowsPerPage={rowsPerPage}
                page={page}
                l={l}
                p={p}
                hasPrev={searchResult.prev !== ''}
                hasNext={searchResult.next !== ''}
                onNext={onNext}
                onPrev={onPrev}
                onSubmitPage={onSubmitPage}
                onSubmitRowsPerPage={onSubmitRowsPerPage}
                updateRowsPageInput={handleRowsPageInput}
                pagiationPostion="position-sticky"
              />
            </Col>
          )
        }
      </Row>
    </Container>
  );
}

LibraryProduct.propTypes = {
  onCancel: PropTypes.func.isRequired,
  handleAddProductInList: PropTypes.func.isRequired,
  handleAddNewlyCreatedProduct: PropTypes.func.isRequired,
  storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  // orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  orderItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
