import React, { Component } from 'react';
import {
  Container, Row, Col, Spinner, Button, InputGroup, Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Axios from 'axios';
import MultiSelect from 'react-multiselect-checkboxes';
import QueryString from 'query-string';
import { Link } from 'react-router-dom';
import {
  order, orderCancellationReason, orderCount, kaeAsmFilter,
} from '../../assets/api/axios';
import {
  CustomTable, Svg, CustomDropdown, DatePicker, CustomModal,
} from '../../component/common';
import { Utils, Constant } from '../../utilities';
import ExotelCall from '../../layouts/order-management/ExotelCall';
import { dateString } from '../../utilities/Utils';
import DeliveryPartnerMeta from '../../layouts/order-management/DeliveryPartnerMeta';
import CustomerReport from '../../layouts/order-management/CustomerReport';
import UpdatDeliveryStatus from '../../layouts/order-management/UpdatDeliveryStatus';
import OrderTimer from './OrderTimer';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l,
    p,
    startDate,
    endDate,
  } = param;
  const {
    searchText = '',
    storeId = '',
    orderId,
    paymentStatus,
    paymentMode,
    deliveryType,
    prescriptionRequired,
    orderBy,
    orderManager,
    source,
    couponOrder,
    firstOrder,
    isDisputed,
    threePL,
    threePLDunzo,
    view,
    deliveryStatus,
    isRedirectionAttempted,
    isRedirectedOrder,
    selectedTagColor,
    pendingForLast30Minutes,
    retailerFirstOrder,
    city,
    orderAssignedOnFrom,
    orderAssignedOnTo,
    isSpecialInstructions,
    isLLSubscribedUser,
    kae,
    asm,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  startDate = Number(startDate) || new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
  startDate = new Date(startDate).setHours(0, 0, 0, 0);
  endDate = Number(endDate) || new Date().setHours(23, 59, 59, 999);
  return ({
    l,
    p,
    startDate,
    endDate,
    searchText,
    storeId,
    orderId,
    paymentStatus,
    paymentMode,
    deliveryType,
    prescriptionRequired,
    orderBy,
    orderManager,
    source,
    couponOrder,
    firstOrder,
    isDisputed,
    threePL,
    threePLDunzo,
    view,
    deliveryStatus,
    isRedirectionAttempted,
    isRedirectedOrder,
    selectedTagColor,
    pendingForLast30Minutes,
    retailerFirstOrder,
    city,
    orderAssignedOnFrom,
    orderAssignedOnTo,
    isSpecialInstructions,
    isLLSubscribedUser,
    kae,
    asm,
  });
};

function renderStatus(status) {
  return status
    ? <span className="text-success">&#10004;</span>
    : <span className="text-danger">&#10006;</span>;
}

class Orders extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    // if from retailer details page
    const { retailerDetails } = props;
    this.state = {
      searchText: param.searchText,
      storeId: retailerDetails ? retailerDetails.code : param.storeId,
      stDate: param.startDate,
      eDate: param.endDate,
      rowsPerPage: param.l,
      page: param.p,
      param,
      orders: null,
      newParam: param,
      loading: true,
      error: false,
      textCopied: '',
      showExotelCallModal: false,
      showCustomerReportModal: false,
      exotelCallDetails: null,
      initiateCallTo: '',
      show3PLMetaModal: false,
      selectedOrderId: '',
      selectedCustomerId: '',
      performingAction: '',
      deliveryStatus: '',
      exportOrderStatusMsg: '',
      orderAssignedOnFrom: '',
      orderAssignedOnTo: '',
      orderCancelReason: [],
      ordersCount: null,
      kaeFilter: [],
      asmFilter: [],

    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
    this.loadOrderCount();
    this.loadOrderCancelReason();
    this.kaeAsmFilterData();
    this.intervalId = setInterval(() => {
      this.handleLoad();
    }, 600000); // 10 min * 60 * 1000
  }

  componentWillUnmount = () => {
    clearInterval(this.intervalId);
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const {
      param, newParam: alteredParams, searchText, storeId,
      stDate, eDate,
    } = this.state;
    const newParam = {
      ...param,
      ...alteredParams,
      ...data,
      searchText,
      storeId,
      startDate: stDate,
      endDate: eDate,
    };
    const {
      l, p,
      deliveryStatus,
      paymentStatus,
      orderManager,
      source,
      startDate,
      endDate,
      paymentMode,
      deliveryType,
      orderBy,
      couponOrder,
      firstOrder,
      prescriptionRequired,
      isRedirectionAttempted,
      isRedirectedOrder,
      pendingForLast30Minutes,
      retailerFirstOrder,
      city,
      isDisputed,
      orderAssignedOnFrom,
      orderAssignedOnTo,
      isSpecialInstructions,
      isLLSubscribedUser,
      kae,
      asm,
    } = newParam;
    order(
      'GET',
      null,
      '',
      {
        page: `${p}`,
        limit: l,
        startDate,
        endDate,
        searchText,
        storeId,
        deliveryStatus,
        paymentStatus,
        orderManager,
        source,
        paymentMode,
        deliveryType,
        orderBy,
        couponOrder,
        firstOrder,
        prescriptionRequired,
        isRedirectionAttempted,
        isRedirectedOrder,
        pendingForLastNHours: pendingForLast30Minutes,
        retailerFirstOrder,
        city,
        isDisputed,
        orderAssignedOnFromTimeStamp: orderAssignedOnFrom,
        orderAssignedOnToTimeStamp: orderAssignedOnTo,
        isSpecialInstructions,
        isLLSubscribedUser,
        kae,
        asm,
      },
    ).then((res) => {
      this.setState({
        orders: res.data,
        loading: false,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  loadOrderCancelReason = (retry = 3) => {
    orderCancellationReason(
      'GET',
    ).then((res) => {
      this.setState({
        orderCancelReason: res.data.data.cancellationReasons,
      });
    }).catch(() => {
      if (retry) {
        this.loadOrderCancelReason(retry - 1);
      }
    });
  }

  kaeAsmFilterData = () => {
    kaeAsmFilter('GET').then((res) => {
      this.setState({
        asmFilter: res.data.data.asm || [],
        kaeFilter: res.data.data.kae || [],
      });
    });
  };

  loadOrderCount = (data = {}) => {
    const {
      param, newParam: alteredParams, searchText, storeId,
      stDate, eDate,
    } = this.state;
    const newParam = {
      ...param,
      ...alteredParams,
      ...data,
      searchText,
      storeId,
      startDate: stDate,
      endDate: eDate,
    };
    const {
      l, p,
      deliveryStatus,
      paymentStatus,
      orderManager,
      source,
      startDate,
      endDate,
      paymentMode,
      deliveryType,
      orderBy,
      couponOrder,
      firstOrder,
      prescriptionRequired,
      isRedirectionAttempted,
      isRedirectedOrder,
      pendingForLast30Minutes,
      retailerFirstOrder,
      city,
      isDisputed,
      orderAssignedOnFrom,
      orderAssignedOnTo,
      isSpecialInstructions,
      isLLSubscribedUser,
      kae,
      asm,
    } = newParam;
    orderCount(
      'GET',
      null,
      {
        page: `${p}`,
        limit: l,
        startDate,
        endDate,
        searchText,
        storeId,
        deliveryStatus,
        paymentStatus,
        orderManager,
        source,
        paymentMode,
        deliveryType,
        orderBy,
        couponOrder,
        firstOrder,
        prescriptionRequired,
        isRedirectionAttempted,
        isRedirectedOrder,
        pendingForLastNHours: pendingForLast30Minutes,
        retailerFirstOrder,
        city,
        isDisputed,
        orderAssignedOnFromTimeStamp: orderAssignedOnFrom,
        orderAssignedOnToTimeStamp: orderAssignedOnTo,
        isSpecialInstructions,
        isLLSubscribedUser,
        kae,
        asm,
      },
    ).then((res) => {
      this.setState({
        ordersCount: res.data.data,
        loading: false,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
      this.loadOrderCount(data);
    });
  }

  handleDropdownChange = (data) => {
    this.setState((state) => ({
      newParam: { ...state.newParam, ...data },
    }));
  }

  handleParamChange = (data) => {
    this.setState((state) => ({
      newParam: { ...state.newParam, ...data },
    }));
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleDateChange = (startDate, endDate) => {
    this.setState({
      stDate: startDate,
      eDate: endDate,
    });
    this.applyFilters();
  }

  handleOrderAssignedDateChange = (data) => {
    this.setState((state) => ({
      orderAssignedOnFrom: data.orderAssignedOnFrom,
      orderAssignedOnTo: data.orderAssignedOnTo,
      newParam: { ...state.newParam, ...data },
    }));
    this.applyFilters();
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  deliveryStatusColor = (status) => {
    let deliveryStatusColor = '';
    switch (status) {
      case 'CANCELLED':
        deliveryStatusColor = 'bg-danger text-white';
        break;
      case 'DELIVERED':
        deliveryStatusColor = 'bg-green text-white';
        break;
      case 'OUT_FOR_DELIVERY':
        deliveryStatusColor = 'bg-lime text-white';
        break;
      case 'MODIFIED':
        deliveryStatusColor = 'bg-orange text-white';
        break;
      case 'CHANGES_ACCEPTED':
        deliveryStatusColor = 'bg-green-yellow text-white';
        break;
      case 'CHANGES_REJECTED':
        deliveryStatusColor = 'bg-firebrick text-white';
        break;
      case 'READY_TO_SHIP':
        deliveryStatusColor = 'bg-olive text-white';
        break;
      case 'PENDING':
        deliveryStatusColor = 'bg-warning text-black';
        break;
      default:
        break;
    }
    return deliveryStatusColor;
  }

  copyText = (text) => {
    Utils.copyToClipboard(text);
    this.setState({
      textCopied: text,
    });
    setTimeout(() => {
      this.setState({
        textCopied: '',
      });
    }, 1000);
  }

  toggleExotelCallModal = (exotelCallDetails = null, initiateCallTo = '') => {
    this.setState((state) => ({
      showExotelCallModal: !state.showExotelCallModal,
      exotelCallDetails,
      initiateCallTo,
    }));
  }

  toggleCustomerReportModal = (customerId) => {
    this.setState((state) => ({
      showCustomerReportModal: !state.showCustomerReportModal,
      selectedCustomerId: customerId,
    }));
  }

  applyFilters = () => {
    const { newParam, searchText, storeId } = this.state;
    this.handleRequestProcessing({
      ...newParam,
      searchText,
      storeId,
      p: 1,
    });
  }

  updateOrder = (updatedOrder) => {
    const { indexOfUpdatingOder, selectedOrderId } = this.state;
    const { orders } = this.state;
    if (
      indexOfUpdatingOder >= 0
      && orders.data[indexOfUpdatingOder].orderId === selectedOrderId
    ) {
      const updatedOrders = orders.data;
      updatedOrders[indexOfUpdatingOder] = updatedOrder;
      this.setState({
        selectedOrderId: '',
        indexOfUpdatingOder: -1,
        deliveryStatus: '',
        deliveryStatusModal: false,
        orders: {
          ...orders,
          data: updatedOrders,
        },
      });
    }
  }

  patchOrder = (orderId, payload) => {
    order(
      'PATCH',
      payload,
      '', {},
      orderId,
    ).then((response) => {
      this.setState({
        performingAction: '',
        error: false,
        loading: false,
      });
      this.updateOrder(response.data.data);
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong!! Please Try Again!!';
      if (err?.response?.data?.errors[0]?.message) {
        errorMsg = err.response.data.errors[0].message;
      }
      this.setState({
        deliveryStatusModal: false,
        updateOrderErrorMsg: errorMsg,
        loading: false,
      });
    });
    this.retry = () => {
      this.setState({
        updateOrderErrorMsg: '',
        loading: true,
      });
      this.patchOrder(orderId, payload);
    };
  }

  patchDeliveryStatus = async (event, orderDetails, index) => {
    this.setState({
      deliveryStatus: event.target.value,
      indexOfUpdatingOder: index,
      selectedOrderId: orderDetails.orderId,
      performingAction: 'UPDATE_ORDER_DELIVERY_STATUS',
    }, () => {
      this.toggleDeliveryStatusConfirmationModal();
    });
  }

  patchOrderManager = async (event, orderDetails, index) => {
    this.setState({
      indexOfUpdatingOder: index,
      selectedOrderId: orderDetails.orderId,
      performingAction: 'UPDATE_ORDER_MANAGER',
    });
    this.patchOrder(
      orderDetails.orderId,
      {
        action: 'PATCH_OWNER',
        owner: event.target.value,
      },
    );
  }

  patchOMStatus = async (event, orderDetails, index) => {
    this.setState({
      indexOfUpdatingOder: index,
      selectedOrderId: orderDetails.orderId,
      performingAction: 'UPDATE_ORDER_MANAGER_STATUS',
    });
    this.patchOrder(
      orderDetails.orderId,
      {
        action: 'PATCH_OM_STATUS',
        status: event.target.value,
      },
    );
  }

  patchPaymentMode = async (event, orderDetails, index) => {
    this.setState({
      indexOfUpdatingOder: index,
      selectedOrderId: orderDetails.orderId,
      performingAction: 'UPDATE_PAYMENT_MODE',
    });
    this.patchOrder(
      orderDetails.orderId,
      {
        action: 'PATCH_PAYMENT_MODE',
        paymentMode: event.target.value,
      },
    );
  }

  patchTestOrder = async (event, orderDetails, index) => {
    this.setState({
      indexOfUpdatingOder: index,
      selectedOrderId: orderDetails.orderId,
      performingAction: 'MARK_TEST_ORDER',
    });
    this.patchOrder(
      orderDetails.orderId,
      {
        action: 'PATCH_IS_TEST_ORDER',
        isTestOrder: event.target.value,
      },
    );
  }

  toggleDeliveryStatusConfirmationModal = () => {
    const { deliveryStatusModal } = this.state;
    this.setState({
      deliveryStatusModal: !deliveryStatusModal,
      ...deliveryStatusModal ? {
        selectedOrderId: '',
        performingAction: '',
        indexOfUpdatingOder: -1,
        deliveryStatus: '',
      } : {},
    });
  }

  handleChangeDeliveryStatus = async (cancellationReason, comment, cancelReason = '') => {
    const { selectedOrderId, deliveryStatus } = this.state;
    this.patchOrder(
      selectedOrderId,
      {
        action: 'PATCH_DELIVERY_STATUS',
        deliveryStatus,
        comment: `${deliveryStatus === 'CANCELLED' ? `${cancellationReason} - ${comment}` : ''}`,
        orderStatusComment: comment,
        cancelReason: `${deliveryStatus === 'CANCELLED' ? `${cancelReason}` : ''}`,

      },
    );
  }

  resetFilters = () => {
    this.setState({
      searchText: '',
      storeId: '',
      stDate: '',
      eDate: '',
      newParam: {
        deliveryStatus: '',
        paymentStatus: '',
        orderManager: '',
        source: '',
        startDate: '',
        endDate: '',
        paymentMode: '',
        deliveryType: '',
        orderBy: '',
        couponOrder: '',
        firstOrder: '',
        prescriptionRequired: '',
        isRedirectionAttempted: '',
        isRedirectedOrder: '',
        selectedTagColor: '',
        pendingForLast30Minutes: '',
        retailerFirstOrder: '',
        location: '',
        city: '',
        isDisputed: '',
        isSpecialInstructions: '',
        isLLSubscribedUser: '',
        kae: '',
        asm: '',
      },
    });
    this.handleRequestProcessing({
      p: 1,
    });
  }

  getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
    if (value?.some((o) => o.value === '')) {
      return `${placeholderButtonLabel}: All`;
    }
    return `${placeholderButtonLabel}: ${value.length} selected`;
  }

  render() {
    const {
      param, page, orders, rowsPerPage,
      loading, error, searchText, storeId, stDate, eDate,
      textCopied, showExotelCallModal,
      exotelCallDetails, initiateCallTo,
      show3PLMetaModal, selectedOrderId, newParam,
      showCustomerReportModal, selectedCustomerId,
      performingAction, updateOrderErrorMsg, deliveryStatusModal,
      deliveryStatus, exportOrderStatusMsg,
      orderAssignedOnFrom, orderAssignedOnTo, orderCancelReason, ordersCount, kaeFilter, asmFilter,
    } = this.state;
    const { history } = this.props;
    const { pathname } = history.location;

    const headers = [
      {
        key: 'orderId',
        displayText: 'Id',
        renderer: (rowData) => (
          <Row className={`mx-0 align-items-center ${rowData.isLLSubscribedUser ? 'bg-warning pt-1 pb-2' : ''} border-radius-16 min-w100`}>
            <Col
              xs={24}
              className="d-flex align-items-center"
            >
              <Link
                to={
                param.view
                  ? `${pathname}?view=orderDetails&orderId=${rowData.orderId}`
                  : `/order-details/${rowData.orderId}`
              }
                target="_blank"
              >
                {rowData.orderId}
              </Link>
              <Button
                variant="link"
                className="py-0 pr-0"
                onClick={() => {
                  this.copyText(rowData.orderId);
                }}
              >
                {
                  textCopied === rowData.orderId ? (
                    <span className="fs-2">✓</span>
                  ) : (
                    <Svg
                      svg="copy"
                      fill={Constant.Color.PRIMARY}
                      width={24}
                    />
                  )
                }
              </Button>
            </Col>
            {
              rowData.isLLSubscribedUser && (
                <Col
                  xs={24}
                >
                  <img
                    src="/images/subscription-logo.svg"
                    width={117}
                    height={17}
                    alt=""
                  />
                </Col>
              )
            }
          </Row>
        ),
      },
      {
        key: 'owner',
        displayText: 'Order Manager',
        renderer: (rowData, index) => {
          if (performingAction === 'UPDATE_ORDER_MANAGER'
            && selectedOrderId === rowData.orderId
            && !updateOrderErrorMsg
          ) {
            return (
              <Spinner
                variant="primary"
                animation="border"
                size="sm"
              />
            );
          }
          return (
            <select
              onChange={(e) => { this.patchOrderManager(e, rowData, index); }}
              value={rowData.owner}
              className={`p-1 ${performingAction ? 'pointer-event-none' : ''}`}
            >
              <option value="">Select</option>
              <option value="OM1">OM1</option>
              <option value="OM2">OM2</option>
              <option value="OM3">OM3</option>
              <option value="OM4">OM4</option>
              <option value="OM5">OM5</option>
              <option value="OM6">OM6</option>
              <option value="OM7">OM7</option>
              <option value="OM8">OM8</option>
              <option value="OM9">OM9</option>
              <option value="OM10">OM10</option>
            </select>
          );
        },
      },
      {
        key: 'omStatus',
        displayText: 'Order Manager Status',
        renderer: (rowData, index) => {
          if (performingAction === 'UPDATE_ORDER_MANAGER_STATUS'
            && selectedOrderId === rowData.orderId
            && !updateOrderErrorMsg
          ) {
            return (
              <Spinner
                variant="primary"
                animation="border"
                size="sm"
              />
            );
          }
          return (
            <select
              onChange={(e) => { this.patchOMStatus(e, rowData, index); }}
              value={rowData.omStatus}
              className={`p-1 ${performingAction ? 'pointer-event-none' : ''}`}
            >
              <option value="PENDING">Pending</option>
              <option value="IN PROGRESS">In Progress</option>
              <option value="DONE">Done</option>
            </select>
          );
        },
      },
      {
        key: 'orderTimer',
        displayText: 'Order Timer',
        renderer: (rowData) => (
          <OrderTimer
            orderDetails={rowData}
          />
        ),
      },
      {
        key: 'deliveryStatus',
        displayText: 'Order Status',
        renderer: (rowData, index) => (
          <select
            onChange={(e) => { this.patchDeliveryStatus(e, rowData, index); }}
            value={rowData.deliveryStatus}
            className={`delivery-status ${performingAction ? 'pointer-event-none' : ''} ${this.deliveryStatusColor(rowData.deliveryStatus)}`}
          >
            <option value={rowData.deliveryStatus} hidden>{rowData.deliveryStatus}</option>
            {
              rowData.nextPossibleDeliveryStatus?.map((status) => (
                <option key={status} value={status} className="bg-white text-black">{status}</option>
              ))
            }
          </select>
        ),
      },
      {
        key: 'store',
        displayText: 'Store (E. Call)',
        renderer: (rowData) => (
          <div
            className="store-name-cover"
          >
            <Button
              variant="link"
              onClick={() => {
                this.toggleExotelCallModal(rowData, 'retailer');
              }}
              className="flex-1 text-black fs-01 text-uppercase"
            >
              {rowData?.store?.displayName || 'NA'}
            </Button>
            <Button
              variant="link"
              className="align-self-start text-black"
              onClick={() => {
                if (rowData?.store?.displayName) {
                  this.copyText(rowData?.store?.displayName);
                }
              }}
            >
              {
                // eslint-disable-next-line no-nested-ternary
                rowData.store.displayName && (
                  textCopied === rowData.store.displayName ? (
                    <span className="fs-4">✓</span>
                  ) : (
                    <Svg
                      svg="copy"
                      fill={Constant.Color.DARK}
                      width={28}
                    />
                  )
                )
              }
            </Button>
          </div>
        ),
      },
      {
        key: 'kae',
        displayText: 'KAE',
        renderer: (rowData) => rowData.kae || '--',
      },
      {
        key: 'asm',
        displayText: 'ASM',
        renderer: (rowData) => rowData.asm || '--',
      },
      {
        key: 'code',
        displayText: 'Store EP Code',
        renderer: (rowData) => (
          <Row className="mx-0 align-items-center min-w100">
            <Link to={`/retailer/${rowData.store.code}`} target="_blank">{rowData.store.code}</Link>
            <Button
              variant="link"
              className="py-0"
              onClick={() => {
                this.copyText(rowData.store.code);
              }}
            >
              {
                textCopied === rowData.store.code ? (
                  <span className="fs-2">✓</span>
                ) : (
                  <Svg
                    svg="copy"
                    fill={Constant.Color.PRIMARY}
                    width={24}
                  />
                )
              }
            </Button>
          </Row>
        ),
      },
      {
        key: 'isPremiumShop',
        displayText: 'Premium Shop ',
        renderer: (rowData) => (
          <div>
            {
              rowData.store.isPremiumShop
                ? (
                  <Svg
                    svg="premiumTag"
                    width="20"
                    height="20"
                  />
                )
                : '-- --'
            }
          </div>
        ),
      },
      {
        key: 'phoneNumber',
        displayText: 'Retailer Number',
        renderer: (rowData) => (
          <Row className="align-items-center min-w150">
            <Button
              variant="link"
              onClick={() => {
                this.toggleExotelCallModal(rowData, 'retailer');
              }}
              className="flex-1 fs-01 py-0"
            >
              {rowData.store.phoneNumber}
            </Button>
            <Button
              variant="link"
              className="p-0"
              onClick={() => {
                this.copyText(rowData.store.phoneNumber);
              }}
            >
              {
                textCopied === rowData.store.phoneNumber ? (
                  <span className="fs-2">✓</span>
                ) : (
                  <Svg
                    svg="copy"
                    fill={Constant.Color.PRIMARY}
                    width={24}
                  />
                )
              }
            </Button>
          </Row>
        ),
      },
      {
        key: 'alternatePhoneNumber',
        displayText: 'Retailer Secondary number',
        renderer: (rowData) => (
          <Row className="align-items-center min-w150">
            <Button
              variant="link"
              onClick={() => {
                if (rowData.store.alternatePhoneNumber !== null) {
                  this.toggleExotelCallModal(rowData, 'retailer');
                }
              }}
              className="flex-1 fs-01 py-0"
            >
              {rowData.store.alternatePhoneNumber === null ? '-- --' : rowData.store.alternatePhoneNumber}
            </Button>
            <Button
              variant="link"
              className="p-0"
              onClick={() => {
                this.copyText(rowData.store.alternatePhoneNumber);
              }}
            >
              {
                // eslint-disable-next-line no-nested-ternary
                textCopied === rowData.store.alternatePhoneNumber ? (
                  <span className="fs-2">✓</span>
                ) : (
                  rowData.store.alternatePhoneNumber === null ? '' : (
                    <Svg
                      svg="copy"
                      fill={Constant.Color.PRIMARY}
                      width={24}
                    />
                  )
                )
              }
            </Button>
          </Row>
        ),
      },
      {
        key: 'customerPhoneNumber',
        displayText: 'Customer (E. Call)',
        renderer: (rowData) => (
          <Row className="align-items-center min-w170">
            <Button
              variant="link"
              onClick={() => {
                this.toggleExotelCallModal(rowData, 'customer');
              }}
              className="flex-1 fs-01 py-0"
            >
              {rowData.customer.phoneNumber}
            </Button>
            <Button
              variant="link"
              className="py-0 px-1"
              onClick={() => {
                this.copyText(rowData.customer.phoneNumber);
              }}
            >
              {
                textCopied === rowData.customer.phoneNumber ? (
                  <span className="fs-2">✓</span>
                ) : (
                  <Svg
                    svg="copy"
                    fill={Constant.Color.PRIMARY}
                    width={24}
                  />
                )
              }
            </Button>
            {
              rowData.customer.isReported && (
                <Button
                  variant="link"
                  className="align-self-start pl-1 pr-2"
                  onClick={() => {
                    this.toggleCustomerReportModal(rowData.customer.id);
                  }}
                >
                  <Svg
                    svg="banned"
                    stroke={Constant.Color.RED}
                    width={16}
                  />
                </Button>
              )
            }
          </Row>
        ),
      },
      {
        key: 'LLHexId',
        displayText: 'Hex ID',
        renderer: (rowData) => rowData.LLHexId || '--',
      },
      {
        key: 'createdOn',
        displayText: 'Order Placed Timestamp',
        renderer: (rowData) => dateString(rowData.createdOn),
      },
      {
        key: 'modifiedOn',
        displayText: 'Order Modified Timestamp',
        renderer: (rowData) => dateString(rowData.modifiedOn),
      },
      {
        key: 'OrderAssignedOn',
        displayText: 'Order Assigned On Timestamp',
        renderer: (rowData) => (rowData.firstTimeAssignedOn ? dateString(rowData.firstTimeAssignedOn) : 'NA'),
      },
      {
        key: 'deliveryType',
        displayText: 'Delivery Type',
      },
      {
        key: 'source',
        displayText: 'Source',
        renderer: (rowData) => (
          <>
            {
              rowData.source === 'GSPOT' ? (
                <img
                  src="https://lovelocal-store-front.s3.ap-south-1.amazonaws.com/03262021131849028116_google_pay.png"
                  alt=""
                  height="30px"
                  width="30px"
                />
              ) : (
                <b>{rowData.source}</b>
              )
            }
          </>
        ),
      },
      {
        key: 'isDisputed',
        displayText: 'Disputed',
        renderer: (rowData) => renderStatus(rowData.isDisputed),
      },
      // {
      //   key: 'changedOn',
      //   displayText: 'Status Since',
      //   renderer: (rowData) => {
      //     const count = 0;
      //     const statusSinceTime = new Date(rowData.changedOn).getTime();
      //     const currentTime = new Date().getTime();
      //     (Add count in the line below)
      //     const delta = Math.abs(Math.round(((currentTime - statusSinceTime) / 1000) / 60));

      //     if (rowData.deliveryStatus === 'DELIVERED' || rowData.deliveryStatus === 'CANCELLED') {
      //       return <>--:--</>;
      //     }
      //     if (Math.floor(delta / 60) > 0) {
      //       return (
      //         <>
      //           {Math.floor(delta / 60)}
      //           {' '}
      //           Hrs
      //         </>
      //       );
      //     }
      //     return (
      //       <>
      //         {delta}
      //         {' '}
      //         Min
      //       </>
      //     );
      //   },
      // },
      {
        key: 'payableAmount',
        displayText: 'Total',
        renderer: (rowData) => (<span>{rowData.payment.payableAmount}</span>),
      },
      {
        key: 'status',
        displayText: 'Payment Status',
        renderer: (rowData) => {
          let styles = {};
          switch (rowData.payment.status) {
            case 'PAID':
              styles = {
                color: '#00a300',
              };
              break;
            case 'PENDING':
              styles = {
                color: '#ff0000',
              };
              break;
            default:
              break;
          }
          return (
            <b
              style={styles}
            >
              {rowData.payment.status || 'NA'}
            </b>
          );
        },
      },
      {
        key: 'mode',
        displayText: 'Payment Mode',
        renderer: (rowData, index) => {
          if (performingAction === 'UPDATE_PAYMENT_MODE'
            && selectedOrderId === rowData.orderId
            && !updateOrderErrorMsg
          ) {
            return (
              <Spinner
                variant="primary"
                animation="border"
                size="sm"
              />
            );
          }
          if (rowData.payment.status === 'PENDING') {
            return (
              <select
                id="paymentMode"
                value={rowData.payment.mode}
                className={`p-1 ${performingAction ? 'pointer-event-none' : ''}`}
                onChange={(e) => { this.patchPaymentMode(e, rowData, index); }}
              >
                <option value="CASH">CASH</option>
                <option value="DIGITAL">DIGITAL</option>
              </select>
            );
          }
          return rowData.payment.mode || 'NA';
        },
      },
      // {
      //   key: '3PLDelivery',
      //   displayText: '3PL Delivery',
      //   renderer: (rowData) => (
      //     rowData.isDeliveredByPartner ? (
      //       <Button
      //         variant="outline-primary"
      //         onClick={() => {
      //           this.toggle3PLMetaModal(rowData.orderId);
      //         }}
      //         className="fs-01 py-1 min-w80"
      //       >
      //         3PL Logs
      //       </Button>
      //     ) : '-- --'
      //   ),
      // },
      {
        key: 'couponAppliedStatus',
        displayText: 'Coupon Applied',
        renderer: (rowData) => renderStatus(rowData.coupon.couponAppliedStatus),
      },
      {
        key: 'couponCode',
        displayText: 'Coupon Code',
        renderer: (rowData) => (rowData.coupon.couponAppliedStatus ? rowData.coupon.couponApplied : '--'),
      },
      {
        key: 'cancelledBy',
        displayText: 'Cancelled By',
        renderer: (rowData) => (
          <div>
            {rowData.cancelledBy || 'NA'}
          </div>
        ),
      },
      {
        key: 'cancellationReason',
        displayText: 'Cancellation Reason',
        renderer: (rowData) => (
          <div>
            {rowData.cancellationReason || 'NA'}
          </div>
        ),
      },
      {
        key: 'comment',
        displayText: 'Comment',
        renderer: (rowData) => (
          <div>
            {rowData.comment || 'NA'}
          </div>
        ),
      },
      {
        key: 'redirectedFrom',
        displayText: 'Redirected from',
        renderer: (rowData) => (
          <Row>
            {rowData.redirectedFrom
              ? (
                <Link
                  to={
                    param.view
                      ? `${pathname}?view=orderDetails&orderId=${rowData.redirectedFrom}`
                      : `/order-details/${rowData.redirectedFrom}`
                  }
                  target="_blank"
                >
                  {rowData.redirectedFrom}
                </Link>
              )
              : 'NA'}
          </Row>
        ),
      },
      {
        key: 'isTestOrder',
        displayText: 'Mark Test Order',
        renderer: (rowData, index) => {
          if (performingAction === 'MARK_TEST_ORDER'
            && selectedOrderId === rowData.orderId
            && !updateOrderErrorMsg
          ) {
            return (
              <Spinner
                variant="primary"
                animation="border"
                size="sm"
              />
            );
          }
          return (
            <select
              id="isTestOrder"
              value={rowData.isTestOrder}
              className={`p-1 ${performingAction ? 'pointer-event-none' : ''}`}
              onChange={(e) => { this.patchTestOrder(e, rowData, index); }}
            >
              <option value="false">False</option>
              <option value="true">True</option>
            </select>
          );
        },
      },
      {
        key: 'isSpecialInstructions',
        displayText: 'Special Instruction',
        renderer: (data) => (data.isSpecialInstructions === true ? 'Yes' : 'No'),
      },
    ];

    const filterConf = [
      {
        key: 'paymentStatus',
        displayText: 'Payment Status',
        options: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'PENDING',
            value: 'PENDING',
          },
          {
            label: 'PAID',
            value: 'PAID',
          },
        ],
      },
      {
        key: 'paymentMode',
        displayText: 'Payment Mode',
        options: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'CASH',
            value: 'CASH',
          },
          {
            label: 'DIGITAL',
            value: 'DIGITAL',
          },
        ],
      },
      {
        key: 'deliveryType',
        displayText: 'Delivery Type',
        options: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'PICKUP_FROM_STORE',
            value: 'pickupfromstore',
          },
          {
            label: 'HOME_DELIVERY',
            value: 'homedelivery',
          },
        ],
      },
      {
        key: 'prescriptionRequired',
        displayText: 'Need Prescription',
        options: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'TRUE',
            value: 'true',
          },
          {
            label: 'FALSE',
            value: 'false',
          },
        ],
      },
      {
        key: 'orderBy',
        displayText: 'Order Sort By',
        options: [
          {
            label: 'NONE',
            value: '',
          },
          {
            label: 'Change Since (Max. First)',
            value: 'CHANGED_SINCE_DSC',
          },
          {
            label: 'Change Since (Min First)',
            value: 'CHANGED_SINCE_ASC',
          },
          {
            label: 'Order Date (Latest First)',
            value: 'CREATED_ON_DSC',
          },
          {
            label: 'Order Date (Oldest First)',
            value: 'CREATED_ON_ASC',
          },
        ],
      },
      {
        key: 'orderManager',
        displayText: 'Order Manager',
        options: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'OM1',
            value: 'om1',
          },
          {
            label: 'OM2',
            value: 'om2',
          },
          {
            label: 'OM3',
            value: 'om3',
          },
          {
            label: 'OM4',
            value: 'om4',
          },
          {
            label: 'OM5',
            value: 'om5',
          },
          {
            label: 'OM6',
            value: 'om6',
          },
          {
            label: 'OM7',
            value: 'om7',
          },
          {
            label: 'OM8',
            value: 'om8',
          },
          {
            label: 'OM9',
            value: 'om9',
          },
          {
            label: 'OM10',
            value: 'om10',
          },
        ],
      },
      {
        key: 'source',
        displayText: 'Order Source',
        options: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'WEB',
            value: 'WEB',
          },
          {
            label: 'APP',
            value: 'APP',
          },
          {
            label: 'ADMIN',
            value: 'ADMIN',
          },
          {
            label: 'GSPOT',
            value: 'GSPOT',
          },
        ],
      },
      {
        key: 'couponOrder',
        displayText: 'Coupon Order',
        options: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'TRUE',
            value: 'true',
          },
          {
            label: 'FALSE',
            value: 'false',
          },
        ],
      },
      {
        key: 'firstOrder',
        displayText: 'First Order',
        options: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'TRUE',
            value: 'true',
          },
          {
            label: 'FALSE',
            value: 'false',
          },
        ],
      },
      {
        key: 'isRedirectionAttempted',
        displayText: 'Redirection Attempted Orders',
        options: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'TRUE',
            value: 'true',
          },
          {
            label: 'FALSE',
            value: 'false',
          },
        ],
      },
      {
        key: 'isRedirectedOrder',
        displayText: 'Redirected Order',
        options: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'TRUE',
            value: 'true',
          },
          {
            label: 'FALSE',
            value: 'false',
          },
        ],
      },
      {
        key: 'pendingForLast30Minutes',
        displayText: 'Order Pending More Than 30 Minutes',
        options: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'TRUE',
            value: 'true',
          },
          {
            label: 'FALSE',
            value: 'false',
          },
        ],
      },
      {
        key: 'retailerFirstOrder',
        displayText: 'Retailer First Order',
        options: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'TRUE',
            value: 'true',
          },
          {
            label: 'FALSE',
            value: 'false',
          },
        ],
      },
      {
        key: 'isDisputed',
        displayText: 'Disputed Orders',
        options: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'TRUE',
            value: 'true',
          },
          {
            label: 'FALSE',
            value: 'false',
          },
        ],
      },
      {
        key: 'isSpecialInstructions',
        displayText: 'Special Instruction',
        options: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'TRUE',
            value: 'true',
          },
          {
            label: 'FALSE',
            value: 'false',
          },
        ],
      },
      {
        key: 'isLLSubscribedUser',
        displayText: 'LL Plus User',
        options: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'TRUE',
            value: 'true',
          },
          {
            label: 'FALSE',
            value: 'false',
          },
        ],
      },
      {
        key: 'kae',
        displayText: 'KAE Filter',
        options: kaeFilter.map((item) => ({
          label: item,
          value: item,
        })),
      },
      {
        key: 'asm',
        displayText: 'ASM Filter',
        options: asmFilter.map((item) => ({
          label: item,
          value: item,
        })),
      },
    ];

    if (loading || error) {
      return (
        <div
          className="pt-3 text-center"
        >
          {loading ? (
            <Spinner
              animation="border"
              variant="primary"
            />
          ) : (
            <>
              <span
                className="text-danger"
              >
                Something Went Wrong
              </span>
              <div>
                <Button
                  variant="primary"
                  onClick={() => this.retry()}
                >
                  Retry
                </Button>
              </div>
            </>
          )}
        </div>
      );
    }

    const filterConfForMultiSelect = [
      {
        key: 'deliveryStatus',
        displayText: 'Delivery Status',
        options: [
          { label: 'ALL', value: '' },
          { label: 'PENDING', value: 'pending' },
          { label: 'MODIFIED', value: 'modified' },
          { label: 'CHANGES_ACCEPTED', value: 'changes_accepted' },
          { label: 'CHANGES_REJECTED', value: 'changes_rejected' },
          { label: 'PACKED', value: 'packed' },
          { label: 'READY TO SHIP', value: 'ready_to_ship' },
          { label: 'OUT FOR DELIVERY', value: 'out_for_delivery' },
          { label: 'DELIVERED', value: 'delivered' },
          { label: 'CANCELLED', value: 'cancelled' },
        ],
      },
    ];

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        {
          !!updateOrderErrorMsg
          && (
            <CustomModal
              show={!!updateOrderErrorMsg}
              onHide={() => {
                this.setState({
                  updateOrderErrorMsg: '',
                });
              }}
              autoSize
              backdrop
              body={(
                <Container className="p-4">
                  <Row className="mx-0 py-2">
                    {updateOrderErrorMsg}
                  </Row>
                  <Row
                    className="mx-0 py-2 d-flex align-items-center flex-row-reverse"
                  >
                    <Button
                      variant="link"
                      className="font-weight-bold"
                      onClick={this.retry}
                    >
                      RETRY
                    </Button>
                    <Button
                      variant="link"
                      className="font-weight-bold text-danger"
                      onClick={() => {
                        this.setState({
                          updateOrderErrorMsg: '',
                          performingAction: '',
                        });
                      }}
                    >
                      CANCEL
                    </Button>
                  </Row>
                </Container>
              )}
            />
          )
        }
        {
          deliveryStatusModal && (
            <UpdatDeliveryStatus
              show={deliveryStatusModal}
              onCancel={this.toggleDeliveryStatusConfirmationModal}
              onUpdate={this.handleChangeDeliveryStatus}
              orderStatus={deliveryStatus}
              orderCancellationReasons={orderCancelReason}
              loading={loading}
            />
          )
        }
        {
          showExotelCallModal && (
            <ExotelCall
              show={showExotelCallModal}
              initiateCallTo={initiateCallTo}
              toggleExotelCallModal={this.toggleExotelCallModal}
              orderDetails={exotelCallDetails}
            />
          )
        }
        {
          show3PLMetaModal && (
            <DeliveryPartnerMeta
              show={show3PLMetaModal}
              toggle3PLMetaModal={this.toggle3PLMetaModal}
              orderId={selectedOrderId}
            />
          )
        }
        {
          showCustomerReportModal && (
            <CustomerReport
              show={showCustomerReportModal}
              toggleReportCustomerModal={this.toggleCustomerReportModal}
              customerId={selectedCustomerId}
            />
          )
        }
        <Row>
          <Col
            xs={24}
            sm={8}
            lg={6}
            className="px-2 py-2"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Search by Name/Mobile No./Order Id"
                name="searchText"
                className="fs-01 rounded-0"
                value={searchText}
                onChange={this.handleOnChange}
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.applyFilters();
                  }
                }}
                autoComplete="off"
              />
            </InputGroup>
          </Col>
          <Col
            xs={24}
            sm={8}
            lg={6}
            className="px-2 py-2"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Search by Store EP Code"
                name="storeId"
                className="fs-01 rounded-0"
                value={storeId}
                onChange={this.handleOnChange}
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.applyFilters();
                  }
                }}
                autoComplete="off"
                disabled={param.view && storeId && pathname.includes(`retailer/${storeId}`)}
              />
            </InputGroup>
          </Col>
          <Col
            xs={24}
            sm={8}
            lg={6}
            className="px-2 py-2"
          >
            <DatePicker
              isDateRange
              onApply={(dateRange) => {
                this.handleDateChange(
                  new Date(dateRange.startDate).getTime(),
                  new Date(dateRange.endDate).getTime(),
                );
              }}
              timePicker
              startDate={stDate}
              endDate={eDate}
              maxDate={new Date().setHours(23, 59, 59, 999)}
              onClear={() => {
                this.setState({
                  stDate: '',
                  eDate: '',
                });
              }}
            />
          </Col>
          <Col
            xs={24}
            sm={8}
            lg={6}
            className="px-2 py-2"
          >
            <div
              className="bg-light d-flex align-items-center justify-content-center"
            >
              <div className="px-1 fs-01">
                Order Assignment On
              </div>
              <DatePicker
                isDateRange
                onApply={(dateRange) => {
                  this.handleOrderAssignedDateChange({
                    orderAssignedOnFrom: new Date(dateRange.startDate).getTime(),
                    orderAssignedOnTo: new Date(dateRange.endDate).getTime(),
                  });
                }}
                timePicker
                timePickerIncrement={60}
                startDate={orderAssignedOnFrom}
                endDate={orderAssignedOnTo}
                maxDate={new Date().setHours(23, 59, 59, 999)}
                onClear={() => {
                  this.setState((state) => ({
                    orderAssignedOnFrom: '',
                    orderAssignedOnTo: '',
                    newParam: {
                      ...state.newParam,
                      orderAssignedOnFrom: '',
                      orderAssignedOnTo: '',
                    },
                  }));
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          {filterConf.map((item) => (
            <Col
              key={item.key}
              xs={24}
              sm={12}
              md={8}
              xl={6}
              className="px-2 py-2"
            >
              <CustomDropdown
                item={item}
                onChange={this.handleDropdownChange}
                selectedVal={newParam[item.key]}
              />
            </Col>
          ))}
          {
            filterConfForMultiSelect.map((item) => (
              <Col
                key={item.key}
                xs={24}
                sm={12}
                md={8}
                xl={6}
                className="px-2 py-2"
              >
                <div>
                  <MultiSelect
                    id={item.key}
                    placeholderButtonLabel={item.displayText}
                    getDropdownButtonLabel={this.getDropdownButtonLabel}
                    onChange={(value, event) => {
                      let selectedItemsString;
                      if (event.action === 'select-option' && event.option.value === '') {
                        selectedItemsString = item.options.reduce(
                          (acc, selItem, i) => acc.concat(i === 0 ? '' : ',', selItem.value), '',
                        );
                        this.handleDropdownChange({ [item.key]: selectedItemsString });
                      } else if (
                        event.action === 'deselect-option' && event.option.value === ''
                      ) {
                        this.handleDropdownChange({ [item.key]: '' });
                      } else {
                        selectedItemsString = value.reduce(
                          (acc, selItem, i) => acc.concat(i === 0 ? '' : ',', selItem.value), '',
                        );
                        this.handleDropdownChange({ [item.key]: selectedItemsString });
                      }
                    }}
                    selectedVal={newParam[item.key]}
                    options={item.options}
                    value={newParam[item.key]
                      ? (newParam[item.key].split(','))
                        .map((val) => ({ label: val, value: val }))
                      : []}
                    className="minw-200p  fs-02"
                  />
                </div>
              </Col>
            ))
          }
          <Col
            xs={24}
            sm={12}
            md={8}
            xl={6}
            className="px-2 py-2"
          >
            <div>
              <MultiSelect
                placeholderButtonLabel="Select Location"
                getDropdownButtonLabel={this.getDropdownButtonLabel}
                name="city"
                onChange={(value, event) => {
                  let selectedItemsString = '';
                  if (event?.name === 'city' && event?.option?.value === 'Others') {
                    if (event?.action === 'select-option') {
                      this.handleDropdownChange({ city: 'Others' });
                      return;
                    }
                    if (event?.action === 'deselect-option') {
                      this.handleDropdownChange({ city: '' });
                      return;
                    }
                  }
                  if (newParam?.city !== 'Others') {
                    selectedItemsString = value.reduce(
                      (acc, selItem, i) => acc.concat(i === 0 ? '' : ',', selItem.value), '',
                    );
                    this.handleDropdownChange({ city: selectedItemsString });
                  }
                }}
                selectedVal={newParam.city}
                options={[
                  {
                    label: 'DELHI',
                    value: 'Delhi',
                  },
                  {
                    label: 'MUMBAI',
                    value: 'Mumbai',
                  },
                  {
                    label: 'PUNE',
                    value: 'Pune',
                  },
                  {
                    label: 'OTHERS',
                    value: 'Others',
                  },
                ]}
                value={newParam?.city
                  ? (newParam.city.split(','))
                    .map((val) => ({ label: val, value: val }))
                  : []}
                className="minw-200p  fs-02"
              />
            </div>
          </Col>
        </Row>
        <Row className="py-2 d-flex align-items-center flex-row-reverse">
          <Col
            xs="auto"
            className="px-2"
          >
            {/* <Button
              variant="success"
              className="fs-01 font-weight-bold"
              onClick={this.exportOrder}
              disabled={exportingOrder}
            >
              EXPORT ORDER
            </Button> */}
          </Col>
          <Col
            xs="auto"
            className="px-2"
          >
            <Button
              variant="primary"
              className="fs-01 font-weight-bold"
              onClick={this.resetFilters}
            >
              RESET FILTERS
            </Button>
          </Col>
          <Col
            xs="auto"
            className="px-2"
          >
            <Button
              variant="success"
              className="fs-01 font-weight-bold"
              onClick={this.applyFilters}
            >
              APPLY FILTERS
            </Button>
          </Col>
        </Row>
        {
          exportOrderStatusMsg && (
            <div className="text-success text-right pb-4">
              {exportOrderStatusMsg}
            </div>
          )
        }
        <Row className="bg-white">
          <Col
            xs={24}
            className="px-0"
          >
            <CustomTable
              headers={headers}
              content={orders?.data}
              keyField="orderId"
              l={param.l}
              p={param.p}
              rowsPerPage={rowsPerPage}
              page={page}
              totalItems={ordersCount?.orderCount}
              hasPrev={ordersCount?.hasPrev}
              hasNext={ordersCount?.hasNext}
              onNext={this.onNext}
              onPrev={this.onPrev}
              onSubmitPage={this.onSubmitPage}
              onSubmitRowsPerPage={this.onSubmitRowsPerPage}
              updateRowsPageInput={this.handleRowsPageInput}
              recordLevelStyle={(data) => {
                if (data.deliveryStatus !== 'PENDING') {
                  return 'white';
                }
                const timeEllapsed = Math.floor(
                  (new Date().getTime() - new Date(data.createdOn).getTime()) / 1000,
                ) / 60; // minutes passed after order creation
                return timeEllapsed > 15 ? 'bg-danger-lighter' : 'white';
              }}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

Orders.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
  }),
};

Orders.defaultProps = {
  retailerDetails: null,
};

export default Orders;
