import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col, Container, Form, InputGroup, Row, Spinner,
} from 'react-bootstrap';
import {
  CustomModal, CustomTable, Svg,
} from '../../component/common';
import { Constant } from '../../utilities';
import { searchProduct } from '../../assets/api/axios';

function ProductSelection(props) {
  const { show, onHide, handleSelectedProduct } = props;

  const [products, setProducts] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [productsFailure, setProductsFailure] = useState('');

  const handleProductLoad = () => {
    setLoadingProducts(true);
    setProductsFailure('');
    searchProduct(
      'GET',
      searchText,
    ).then((res) => {
      if (res.status === 200) {
        setLoadingProducts(false);
        setProducts(res.data);
      } else {
        throw new Error();
      }
    }).catch((err) => {
      let errorMsg = 'Oops something went wrong. Retry!';
      if (err?.response?.data?.errors[0]?.message) {
        errorMsg = err.response.data.errors[0].message;
      }
      setLoadingProducts(false);
      setProductsFailure(errorMsg);
    });
  };

  const onClose = () => {
    setLoadingProducts(false);
    setProductsFailure('');
    setSearchText('');
    setProducts(null);
    onHide();
  };

  const searchProductsHeaders = [
    {
      key: 'select',
      displayText: 'Select',
      renderer: (data) => (
        <Form.Check
          type="radio"
          onChange={() => {
            onClose();
            handleSelectedProduct(data);
          }}
        />
      ),
    },
    {
      key: 'productId',
      displayText: 'Id',
    },
    {
      key: 'productName',
      displayText: 'Name',
    },
    {
      key: 'sellingPrice',
      displayText: 'Price',
    },
    {
      key: 'unit',
      displayText: 'Measurement',
      renderer: (data) => (
        <span>
          {data.measurementValue}
          {data.measurementUnit}
        </span>
      ),
    },
    {
      key: 'imageUrl',
      displayText: 'Image',
      renderer: (data) => (
        <img alt="" src={data.imageUrl} width="50px" height="50px" />
      ),
    },
  ];

  const body = (
    <Container>
      <Row>
        <Col
          className="px-0"
        >
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text
                className="rounded-0"
              >
                <Svg
                  svg="search"
                  width="1rem"
                  fill={Constant.Color.DARK}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              placeholder="Search Product by Id"
              autoFocus
              className="fs-1 rounded-0"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={(e) => {
                if (e.which === 13 && e.target.value !== '') {
                  handleProductLoad();
                }
              }}
            />
          </InputGroup>
        </Col>
        <Col
          xs="auto"
          className="px-0"
        >
          <Button
            className="rounded-0"
            onClick={onClose}
          >
            <Svg
              svg="close"
              width="1rem"
              fill={Constant.Color.WHITE}
            />
          </Button>
        </Col>
      </Row>
      <Row>
        {(() => {
          let showcase = null;
          if (loadingProducts) {
            showcase = (
              <div
                className="w-100 pt-3 d-flex align-items-center justify-content-center"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (!loadingProducts && !!productsFailure) {
            showcase = (
              <div
                className="w-100 pt-3 d-flex align-items-center justify-content-center"
              >
                <span className="text-danger">{productsFailure}</span>
              </div>
            );
          } else if (products) {
            showcase = (
              <>
                <Col
                  xs={24}
                  className="px-0"
                >
                  <CustomTable
                    headers={searchProductsHeaders}
                    content={[products.data]}
                    keyField="productId"
                    totalItems={[products.data].length}
                    isPaginated={false}
                  />
                </Col>
              </>
            );
          }
          return showcase;
        })()}
      </Row>
    </Container>
  );

  return (
    <CustomModal
      show={show}
      onHide={() => { onClose(); }}
      size="lg"
      body={body}
    />
  );
}

ProductSelection.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  handleSelectedProduct: PropTypes.func.isRequired,
};

export default ProductSelection;
