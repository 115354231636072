const isProdEnv = process.env.REACT_APP_ENV === 'production';
// const domainEnhancer = isProdEnv ? '' : '-staging';

const golbat = (path, data) => {
  let params = '';
  Object.keys(data).forEach((item, index) => {
    params = params.concat(`${index ? '&' : ''}${item}=${data[item]}`);
  });
  return isProdEnv
    ? `https://myshop.lovelocal.in${path}${params ? `?${params}` : ''}`
    : `https://retailer-staging.mpaani.com${path}${params ? `?${params}` : ''}`;
};

const a = '';

export default {
  golbat,
  a,
};
