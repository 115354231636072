import React, { Component } from 'react';
import {
  Button, Row, Spinner, InputGroup, Form, Col,
  Container,
} from 'react-bootstrap';
import QueryString from 'query-string';
import PropTypes from 'prop-types';
import Axios from 'axios';
import {
  CustomTable, CustomModal, DatePicker, CustomDropdown, Svg,
} from '../../component/common';
import { Constant } from '../../utilities';
import { storeSettlement, reconcile } from '../../assets/api/axios';
import Permission from '../../access&permissions/permission';

const isProdEnv = process.env.REACT_APP_ENV === 'production';
const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, createdOnFrom, createdOnTo,
  } = param;
  const {
    q = '',
    hasFundAccount = 'true',
    isUnderFraudReview = 'false',
    isFraudStore = 'false',
    firstOrderVerificationStatus = 'APPROVED',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  createdOnFrom = Number(createdOnFrom) || '';
  createdOnTo = Number(createdOnTo) || '';
  return ({
    l,
    p,
    q,
    createdOnFrom,
    createdOnTo,
    hasFundAccount,
    isUnderFraudReview,
    isFraudStore,
    firstOrderVerificationStatus,
  });
};

class PendingSettlementsStore extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    const { userPermission } = props;
    this.canIEdit = userPermission
      && userPermission.includes(Permission.PENDING_SETTLEMENTS_STORE_WRITE);
    this.state = {
      pendingSettlements: null,
      rowsPerPage: param.l,
      page: param.p,
      param,
      loading: true,
      error: false,
      storeCode: param.q,
      submitting: false,
      selectedSettlementToReconcile: null,
      selectSettlement: '',
      settlementIds: [],
      reconcilingSettlementsModal: false,
      reconcilingSettlements: false,
      exportStatus: '',
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    const { param } = this.state;
    const startDate = new Date();
    const endDate = new Date();
    startDate.setDate(startDate.getDate() - 1);
    endDate.setDate(endDate.getDate() - 1);
    startDate.setHours(0, 0, 0);
    endDate.setHours(23, 59, 59);
    this.handleLoad({
      createdOnFrom: Number(param.createdOnFrom) || startDate.getTime(),
      createdOnTo: Number(param.createdOnTo) || endDate.getTime(),
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, q, createdOnFrom, createdOnTo, hasFundAccount,
      isUnderFraudReview, isFraudStore, firstOrderVerificationStatus,
    } = newParam;
    const page = (p - 1) * l;
    storeSettlement(
      'GET',
      {
        page,
        limit: l,
        storeCodes: q,
        createdOnFrom,
        createdOnTo,
        hasFundAccount,
        isUnderFraudReview,
        isFraudStore,
        firstOrderVerificationStatus,
      },
      this.source.token,
    ).then((res) => {
      this.setState({
        pendingSettlements: res.data,
        loading: false,
        error: false,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
        selectSettlement: '',
        settlementIds: [],
        reconcilingSettlements: false,
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });
  }

  handleExportAllSettlements = () => {
    const { param } = this.state;
    const {
      createdOnFrom, createdOnTo, q: storeCodes, hasFundAccount,
      isUnderFraudReview, isFraudStore, firstOrderVerificationStatus,
    } = param;
    this.setState({
      exportStatus: 'loading',
    });
    storeSettlement(
      'GET',
      {
        page: '1',
        limit: 9999999,
        generateExport: true,
        createdOnFrom,
        createdOnTo,
        storeCodes,
        hasFundAccount,
        isUnderFraudReview,
        isFraudStore,
        firstOrderVerificationStatus,
      },
      null,
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          exportStatus: 'success',
        }, () => {
          setTimeout(() => {
            this.setState({
              exportStatus: '',
            });
          }, 5000);
        });
      } else {
        throw new Error();
      }
    }).catch(() => {
      this.setState({
        exportStatus: 'error',
      });
    });
  }

  handleMarkReconcile = () => {
    const {
      selectedSettlementToReconcile,
    } = this.state;
    this.setState({
      loading: true,
      error: false,
    });
    reconcile(
      'POST',
      {
        ...selectedSettlementToReconcile,
        settlementIds: selectedSettlementToReconcile.settlementIds,
      },
    ).then(() => {
      this.setState({
        selectedSettlementToReconcile: null,
        loading: false,
        submitting: false,
      }, () => {
        this.handleLoad();
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });
  }

  async handleAction() {
    const { settlementIds: ids } = this.state;
    for (let i = 0; i < ids.length; i += 1) {
      ids[i].statusMessage = 'PROCESSING';
      ids[i].statusTextColor = 'text-warning';
      this.setState({
        settlementIds: ids,
      });
      // eslint-disable-next-line no-await-in-loop
      await reconcile(
        'POST',
        {
          ...ids[i],
          settlementIds: ids[i].settlementIds,
        },
      ).then((res) => {
        if (res.status === 200) {
          const { pendingSettlements } = this.state;
          const newSettlements = pendingSettlements.data;
          const index = newSettlements.findIndex((item) => (
            item.storeCode === ids[i].storeCode
          ));
          if (index !== -1) {
            newSettlements[index].isMarkedReconciled = true;
          }
          ids[i].statusMessage = 'SUCCESS';
          ids[i].statusTextColor = 'text-green';
          this.setState({
            settlementIds: ids,
            pendingSettlements: {
              ...pendingSettlements,
              results: newSettlements,
            },
            reconcilingSettlements: false,
          });
        } else {
          throw new Error();
        }
      }).catch(() => {
        ids[i].statusMessage = 'ERROR';
        ids[i].statusTextColor = 'text-danger';
        this.setState({
          settlementIds: ids,
          reconcilingSettlements: false,
        });
      });
    }
  }

  retry = () => {
    this.setState({
      loading: true,
      error: false,
    }, () => {
      this.handleLoad();
    });
  }

  handleDateChange = (createdOnFrom, createdOnTo) => {
    const { param } = this.state;
    if (createdOnFrom !== param.createdOnFrom
      || createdOnTo !== param.createdOnTo) {
      this.handleRequestProcessing({
        createdOnFrom,
        createdOnTo,
        p: 1,
      });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  onSubmitSearchText = () => {
    const {
      storeCode, param,
    } = this.state;
    if (storeCode !== param.q) {
      this.handleRequestProcessing({ q: storeCode, p: 1 });
    }
  }

  handleSelectSettlement = (data) => {
    const { selectSettlement } = data;
    const { pendingSettlements } = this.state;
    this.setState({
      settlementIds: selectSettlement === 'all'
        ? (pendingSettlements.data).filter((item) => (
          !item.isMarkedReconciled
          && (
            ((item.totalAmount + item.discountAmount)
            - item.commission) >= item.totalRecoveryAmount
          )
          && item.bankDetailsVerified
        )).map((s) => ({
          ...s,
          statusMessage: 'NOT INITIATED',
        }))
        : [],
      selectSettlement,
    });
  }

  handleSettlementSelection = (e, s) => {
    const { checked } = e.target;
    const { settlementIds } = this.state;
    let newSettlementIds = settlementIds;

    if (checked) {
      newSettlementIds.push({
        ...s,
        statusMessage: 'NOT INITIATED',
      });
    } else {
      newSettlementIds = newSettlementIds.filter(
        (item) => item.storeCode !== s.storeCode,
      );
    }
    this.setState({
      settlementIds: newSettlementIds,
    });
  }

  render() {
    const {
      pendingSettlements, loading, error, rowsPerPage,
      page, param, storeCode, submitting, selectedSettlementToReconcile,
      selectSettlement, settlementIds, reconcilingSettlementsModal,
      reconcilingSettlements, exportStatus,
    } = this.state;

    let headers = [
      {
        key: 'storeCode',
        displayText: 'Store Code',
        renderer: (data) => (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://admin${
              isProdEnv ? '' : '-staging'
            }.mpaani.com/retailer/${data.storeCode}?${
              QueryString.stringify({
                ...param,
                view: 'pendingSettlement',
                status: 'PENDING',
                storeIds: data.storeCode,
                l: 10,
                p: 1,
              })
            }`}
          >
            { data.storeCode }
          </a>
        ),
      },
      {
        key: 'storeName',
        displayText: 'Store Name',
      },
      {
        key: 'orderCount',
        displayText: 'Order Count',
      },
      {
        key: 'totalAmount',
        displayText: 'Total Amount',
      },
      {
        key: 'totalRecoveryAmount',
        displayText: 'Total Recovery Amt.',
        renderer: (data) => (
          <span className={`${data.totalRecoveryAmount > 0 ? 'text-danger' : ''}`}>
            {data.totalRecoveryAmount}
          </span>
        ),
      },
      {
        key: 'digitalAmount',
        displayText: 'Digital Amount',
      },
      {
        key: 'discountAmount',
        displayText: 'Discount',
      },
      {
        key: 'commission',
        displayText: 'Commission',
      },
      // {
      //   key: 'totalSfxDeliveryAmount',
      //   displayText: '3PL Settlement Amount',
      // },
      // {
      //   key: 'deliveryBy3pl',
      //   displayText: 'Delivery By 3PL',
      //   renderer: (data) => (
      //     <div
      //       className={data.totalSfxDeliveryAmount ? 'text-green' : 'text-danger'}
      //     >
      //       {data.totalSfxDeliveryAmount ? 'YES' : 'NO'}
      //     </div>
      //   ),
      // },
      ...this.canIEdit
        ? [{
          key: 'markReconcile',
          displayText: 'Mark Reconcile',
          renderer: (data) => (
            <Button
              variant="outline-primary"
              className="py-1"
              onClick={() => {
                this.setState({
                  selectedSettlementToReconcile: data,
                });
              }}
              disabled={
                !this.canIEdit
                || data.isMarkedReconciled
                || !!selectSettlement
                || (
                  ((data.totalAmount + data.discountAmount)
                  - data.commission) < data.totalRecoveryAmount
                )
                || !data.bankDetailsVerified
              }
            >
              <span
                className="fs-01"
              >
                Mark Reconcile
              </span>
            </Button>
          ),
        }]
        : [],
      {
        key: 'reason',
        displayText: 'Reason',
        renderer: (data) => (
          <span>
            {data.bankDetailsVerified ? '--' : 'Bank Details not verified'}
          </span>
        ),
      },
      {
        key: 'firstOrderVerificationStatus',
        displayText: 'Is First Order Verified',
        renderer: (data) => (
          <div
            className={data.firstOrderVerificationStatus ? 'text-green' : 'text-danger'}
          >
            {data.firstOrderVerificationStatus ? 'YES' : 'NO'}
          </div>
        ),
      },
    ];

    let totalAmount = 0;
    let totalCommission = 0;
    let totalReconcile = 0;
    if (settlementIds.length) {
      settlementIds.forEach((item) => {
        totalAmount += item.totalAmount;
        totalCommission += item.commission;
      });
      totalReconcile = totalAmount - totalCommission;
    }

    const filtersConfig = [
      {
        key: 'hasFundAccount',
        displayText: 'Fund Account',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'ALL',
            value: 'all',
          },
        ],
      },
      {
        key: 'isUnderFraudReview',
        displayText: 'Under Fraud Review',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'ALL',
            value: 'all',
          },
        ],
      },
      {
        key: 'isFraudStore',
        displayText: 'Fraud Store',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'ALL',
            value: 'all',
          },
        ],
      },
      {
        key: 'firstOrderVerificationStatus',
        displayText: 'First Order Verification',
        options: [
          {
            label: 'PENDING',
            value: 'PENDING',
          },
          {
            label: 'APPROVED',
            value: 'APPROVED',
          },
          {
            label: 'REJECTED',
            value: 'REJECTED',
          },
        ],
      },
    ];

    if (selectSettlement) {
      headers = [
        {
          key: 'markReconcileDropdown',
          displayText: (
            <CustomDropdown
              item={{
                key: 'action',
                displayText: 'Action',
                options: [
                  {
                    label: 'Mark Reconcile',
                    value: 'MARK_RECONCILE',
                  },
                ],
              }}
              onChange={() => {
                if (settlementIds.length) {
                  this.setState({
                    reconcilingSettlementsModal: true,
                  });
                }
              }}
              selectedVal=""
              disabled={reconcilingSettlements}
            />
          ),
          renderer: (data) => {
            const settlement = settlementIds.find((item) => (
              item.storeCode === data.storeCode
            ));
            if (
              !settlement && (
                data.isMarkedReconciled
                || (((data.totalAmount + data.discountAmount)
                  - data.commission) < data.totalRecoveryAmount)
                || !data.bankDetailsVerified
                || !this.canIEdit
              )
            ) {
              return '';
            }
            if (!!settlement && settlement.statusMessage === 'SUCCESS') {
              return (
                <Svg
                  svg="circleDone"
                  width="15px"
                  fill={Constant.Color.GREEN}
                />
              );
            }
            return (
              <Form.Check
                custom
                id={`${data.storeCode}-checkbox`}
                label=""
                checked={!!settlement}
                onChange={(e) => {
                  this.handleSettlementSelection(e, data);
                }}
              />
            );
          },
        },
        {
          key: 'action-status',
          displayText: 'Reconcilation Status',
          renderer: (data) => {
            const obj = settlementIds.find((s) => s.storeCode === data.storeCode);
            if (obj) {
              return (
                <div
                  className={obj.statusTextColor}
                >
                  {obj.statusMessage}
                </div>
              );
            }
            return '';
          },
        },
      ].concat(headers);
    }

    if (loading || error) {
      return (
        <div
          className="pt-4 text-center"
        >
          {loading ? (
            <Spinner
              animation="border"
              variant="primary"
            />
          ) : (
            <>
              <span
                className="text-danger"
              >
                Something Went Wrong
              </span>
              <div
                className="pt-2"
              >
                <Button
                  variant="primary"
                  onClick={() => {
                    this.retry();
                  }}
                >
                  Retry
                </Button>
              </div>
            </>
          )}
        </div>
      );
    }

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        <CustomModal
          show={selectedSettlementToReconcile || reconcilingSettlementsModal}
          closeButton
          size="md"
          onHide={() => {
            this.setState({
              reconcilingSettlementsModal: false,
            });
          }}
          body={(
            <Container
              className="justify-content-center"
            >
              <Row
                className="mx-auto mt-4"
              >
                <Col
                  xs={24}
                  className="text-center fs-3"
                >
                  Are you sure you want to RECONCILE these settlements ?
                </Col>
              </Row>
              <Row
                className="mt-4"
              >
                <Col
                  xs={12}
                  className="text-right"
                >
                  <Button
                    variant="outline-primary"
                    value="notNow"
                    className="py-1 px-3 fs-2"
                    disabled={submitting}
                    onClick={() => {
                      this.setState({
                        selectedSettlementToReconcile: null,
                        submitting: false,
                        reconcilingSettlementsModal: false,
                      });
                    }}
                  >
                    NOT NOW
                  </Button>
                </Col>
                <Col
                  xs={12}
                  className="text-left"
                >
                  <Button
                    variant="outline-success"
                    value="submit"
                    className="py-1 px-3 fs-2 lg active"
                    disabled={submitting}
                    onClick={() => {
                      if (reconcilingSettlementsModal) {
                        this.setState({
                          reconcilingSettlementsModal: false,
                          reconcilingSettlements: true,
                        }, () => {
                          this.handleAction();
                        });
                      } else {
                        this.handleMarkReconcile();
                      }
                    }}
                  >
                    SUBMIT
                  </Button>
                </Col>
              </Row>
            </Container>
          )}
        />
        <Row
          className="h-100 flex-column"
        >
          <Col
            className="flex-grow-0"
          >
            <Row
              className="align-items-center"
            >
              <Col
                xs="auto"
                className="p-2"
              >
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className="rounded-0"
                    >
                      <Svg
                        svg="search"
                        width="1rem"
                        fill={Constant.Color.DARK}
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    placeholder="Store Code"
                    name="storeCode"
                    className="fs-01 rounded-0"
                    value={storeCode}
                    onChange={this.handleOnChange}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        this.onSubmitSearchText();
                      }
                    }}
                  />
                </InputGroup>
              </Col>
              <Col
                xs="auto"
                className="p-2"
              >
                <DatePicker
                  isDateRange
                  onApply={(dateRange) => {
                    if (dateRange && dateRange.startDate && dateRange.endDate) {
                      new Date(dateRange.startDate).setHours(0, 0, 0);
                      new Date(dateRange.endDate).setHours(23, 59, 59);
                    }
                    this.handleDateChange(
                      new Date(dateRange.startDate).getTime(),
                      new Date(dateRange.endDate).getTime(),
                    );
                  }}
                  startDate={param.createdOnFrom}
                  endDate={param.createdOnTo}
                  onClear={() => {
                    this.handleRequestProcessing({
                      createdOnFrom: '', createdOnTo: '',
                    });
                  }}
                />
              </Col>
              {
                filtersConfig.map((item) => (
                  <Col
                    xs="auto"
                    className="px-2 py-2"
                  >
                    <CustomDropdown
                      item={item}
                      closeButton={false}
                      onChange={this.handleDropdownChange}
                      selectedVal={param[item.key]}
                    />
                  </Col>
                ))
              }
              {
                this.canIEdit && (
                  <Col
                    xs="auto"
                    className="py-2"
                  >
                    <CustomDropdown
                      item={{
                        key: 'selectSettlement',
                        displayText: 'Select Settlement',
                        options: [
                          {
                            label: 'All',
                            value: 'all',
                          },
                          {
                            label: 'Manually',
                            value: 'manually',
                          },
                          {
                            label: 'None',
                            value: '',
                          },
                        ],
                      }}
                      closeButton={false}
                      onChange={this.handleSelectSettlement}
                      selectedVal={selectSettlement}
                      disabled={reconcilingSettlements}
                    />
                  </Col>
                )
              }
              <Col
                xs="auto"
                className="p-2 d-flex align-items-center justify-content-center"
              >
                {
                  exportStatus === 'loading'
                    ? (
                      <Spinner
                        variant="primary"
                        animation="border"
                      />
                    ) : (
                      <Button
                        className="px-2 fs-01 py-1 rounded-0"
                        onClick={this.handleExportAllSettlements}
                        variant={exportStatus === 'error' ? 'outline-danger' : 'outline-primary'}
                      >
                        {
                      exportStatus === 'error'
                        ? 'Retry (Export All Settlements)'
                        : 'Export All Settlements'
                    }
                      </Button>
                    )
                }
                {
                  exportStatus === 'success' && (
                  <div
                    className="fs-01 px-2 text-green"
                  >
                    Exported Successfully: You&apos;ll get a mail.
                  </div>
                  )
                }
              </Col>
            </Row>
            {selectSettlement && !!totalAmount && (
              <Row
                className="p-2 align-items-center fs-0"
              >
                <Col
                  xs="auto"
                  className="pl-0"
                >
                  Amount: &nbsp;
                  <span
                    className="text-secondary"
                  >
                    {totalAmount}
                  </span>
                </Col>
                <Col
                  xs="auto"
                  className="pl-0"
                >
                  Commission: &nbsp;
                  <span
                    className="text-secondary"
                  >
                    {totalCommission}
                  </span>
                </Col>
                <Col
                  xs="auto"
                  className="pl-0"
                >
                  Reconcile: &nbsp;
                  <span
                    className="text-secondary"
                  >
                    {totalReconcile}
                  </span>
                </Col>
              </Row>
            )}
          </Col>
          <Col
            className="px-0 flex-grow-1 overflow-y"
          >
            {pendingSettlements && (
              <CustomTable
                headers={headers}
                content={pendingSettlements.data}
                keyField="storeId"
                l={param.l}
                p={param.p}
                rowsPerPage={rowsPerPage}
                page={page}
                totalItems={pendingSettlements.count}
                hasPrev={pendingSettlements.prev !== ''}
                hasNext={pendingSettlements.next !== ''}
                onNext={this.onNext}
                onPrev={this.onPrev}
                onSubmitPage={this.onSubmitPage}
                onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                updateRowsPageInput={this.handleRowsPageInput}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

PendingSettlementsStore.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default PendingSettlementsStore;
