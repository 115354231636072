import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import QueryString from 'query-string';
import {
  Container, Row, Col, Spinner, Button,
} from 'react-bootstrap';
import { ErrorHandler, Svg, CustomTable } from '../../component/common';
import { orders } from '../../assets/api/axios';
import { Constant, Utils } from '../../utilities';
import '../../assets/scss/OrderManagment/OrderDetails.scss';
import Permission from '../../access&permissions/permission';

const isProdEnv = process.env.REACT_APP_ENV === 'production';

const getStringFormat = (item) => {
  switch (item) {
    case 'PENDING':
      return 'Pending';
    case 'DELIVERED':
      return 'Delivered';
    case 'MODIFIED':
      return 'Modified';
    case 'OUT_FOR_DELIVERY':
      return 'Out For Delivery';
    case 'READY_TO_SHIP':
      return 'Ready To Ship';
    case 'CHANGES_ACCEPTED':
      return 'CHANGES_ACCEPTED';
    case 'CHANGES_REJECTED':
      return 'CHANGES_REJECTED';
    case 'CANCELLED':
      return 'Cancelled';
    case 'CASH':
      return 'Cash';
    case 'DIGITAL':
      return 'Digital';
    case 'PAID':
      return 'Paid';
    case 'UNALTERED':
      return 'Unaltered';
    default:
      break;
  }
  return null;
};

function getStatusIcon(status) {
  return status
    ? <span className="text-success">&#10004;</span>
    : <span className="text-danger">&#10006;</span>;
}

const getTableLayout = (data, title) => (
  <Container
    className="mx-0 bg-white rounded h-100"
  >
    <Row>
      <Col
        xs={24}
        className="fs-1 py-3"
      >
        <b>{title}</b>
      </Col>
      <Col
        xs={24}
      >
        {data.map((item) => (
          <Row
            key={item.header}
            className="justify-content-between fs-0
            text-medium py-2 border-top flex-row"
          >
            <Col
              xs="auto"
              className="font-weight-bold"
            >
              {item.header}
            </Col>
            <Col
              xs="auto"
            >
              {item.content}
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
  </Container>
);

class OrderDetails extends React.Component {
  constructor(props) {
    super();
    const { match, history } = props;
    const param = QueryString.parse(history.location.search);
    this.state = {
      orderId: match.params.orderId,
      orderDetails: null,
      loading: true,
      error: false,
      param,
    };
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleLoad = () => {
    const { orderId } = this.state;
    orders('get', orderId)
      .then((res) => {
        this.setState({
          loading: false,
          orderDetails: { ...res.data },
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  render() {
    const {
      loading, error, orderDetails, orderId, param,
    } = this.state;
    const { userPermission } = this.props;
    const headers = [
      {
        key: 'displayName',
        displayText: 'Name',
      },
      {
        key: 'image',
        displayText: 'Image',
        renderer: (data) => (
          <div
            className="product-image-div"
          >
            <img
              src={data.image}
              alt=""
              className="w-100 h-100"
            />
          </div>
        ),
      },
      {
        key: 'mrp',
        displayText: 'Mrp',
      },
      {
        key: 'sellingPrice',
        displayText: 'Selling Price',
      },
      {
        key: 'quantity',
        displayText: 'Quantity',
      },
      {
        key: 'tag',
        displayText: 'Tag',
        renderer: (data) => getStringFormat(data.tag),
      },
    ];

    if (!orderDetails && loading) {
      return (
        <div
          className="h-100 d-flex align-items-center
               justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!orderDetails && !loading && error) {
      return (
        <div
          className="h-100 d-flex align-items-center
               justify-content-center"
        >
          <ErrorHandler
            retryLogic={() => {
              this.setState({
                loading: true,
                error: false,
              }, () => this.handleLoad());
            }}
          />
        </div>
      );
    }

    const {
      createdOn, deliveryStatus, pickupFromStore, payment, productCount,
      offersApplicable, coupon, orderItems, deliveryDetails, settlement,
      loyalty, customer, store,
    } = orderDetails;

    const couponDetails = [
      {
        header: 'Coupon Applied',
        content: getStatusIcon(coupon.couponAppliedStatus),
      },
    ];
    if (coupon.couponAppliedStatus) {
      couponDetails.push.apply(couponDetails, [
        {
          header: 'Coupon',
          content: coupon.couponApplied.couponCode,
        },
        {
          header: 'Discount Type',
          content: coupon.couponApplied.discountType,
        },
        {
          header: 'Discount Amount',
          content: coupon.discountAmount,
        }]);
    }

    const offerDetails = [
      {
        header: 'Offer Applied',
        content: getStatusIcon(offersApplicable.length),
      },
    ];
    let spendOffer = null;
    const bogoOffers = [];
    offersApplicable.forEach((offer) => {
      if (offer.offerType === 'SPECIAL_BOGO_OFFER') {
        bogoOffers.push(offer);
      }
      if (offer.offerType === 'SPECIAL_SPEND_OFFER') {
        spendOffer = offer;
      }
    });
    if (spendOffer) {
      offerDetails.push.apply(offerDetails, [{
        header: 'Spend Offer',
        content: getStatusIcon(true),
      }]);
    }
    if (bogoOffers.length) {
      offerDetails.push.apply(offerDetails, [{
        header: 'Bogo Offer',
        content: getStatusIcon(true),
      }]);
    }

    let backNavigation = '';
    if (param.view === 'order') {
      const urlParam = { ...param };
      delete urlParam.sid;
      backNavigation = `/retailer/${param.sid}?${QueryString.stringify(urlParam)}`;
    }

    return (
      <Container
        fluid
        className="mx-0"
        id="order-details"
      >
        <Row>
          <Col
            xs={24}
            className="d-flex align-items-center sticky-0 py-2 mb-2"
          >
            <Link
              to={backNavigation}
            >
              <Svg
                svg="arrow"
                width="1.2rem"
                fill={Constant.Color.PRIMARY}
              />
            </Link>
            <div
              className="fs-2 ml-3 font-weight-bold"
            >
              Order
              <span
                className="text-medium ml-3"
              >
                {orderId}
              </span>
            </div>
            <div
              className="py-1 ml-auto px-3"
            >
              <Button
                variant="primary"
                className="fs-01"
                onClick={() => {
                  window.open(`https://catadmin${isProdEnv ? '' : '-staging'}.mpaani.com/dashboard/order-management/order/${orderId}`, '_self');
                }}
                disabled={!userPermission.includes(Permission.EDIT_ORDER)}
              >
                Go To Order Dashboard
              </Button>
            </div>

          </Col>
          <Col
            xs={24}
            className="mb-3"
          >
            <Row
              className="bg-white mx-0 py-2 shadow justify-content-between"
            >
              {[{
                label: 'Order Placed',
                value: Utils.dateString(createdOn),
              },
              {
                label: 'Delivery Status',
                value: getStringFormat(deliveryStatus),
              },
              {
                label: 'Delivery Type',
                value: pickupFromStore
                  ? 'Store Pickup'
                  : 'Home Delivery',
              },
              {
                label: 'Payment Status',
                value: getStringFormat(payment.status),
              },
              ].map((item) => (
                <Col
                  xs={12}
                  md="auto"
                  key={item.label}
                  className="py-2"
                >
                  <div
                    className="text-medium fs-1 mb-1"
                  >
                    <b>{item.value}</b>
                  </div>
                  <div
                    className="text-secondary fs-0"
                  >
                    {item.label}
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
          <Col
            xs={24}
            md={12}
            className="mb-3"
          >
            {getTableLayout(couponDetails, 'Coupon')}
          </Col>
          <Col
            xs={24}
            md={12}
            className="mb-3"
          >
            {getTableLayout(offerDetails, 'Offers')}
          </Col>
          <Col
            xs={24}
            md={12}
            className="mb-3"
          >
            {getTableLayout([
              {
                header: 'Mode',
                content: orderDetails.payment.mode,
              },
              {
                header: 'Bill Amount',
                content: orderDetails.payment.billAmount,
              },
              {
                header: 'Delivery Amount',
                content: orderDetails.payment.deliveryAmount,
              },
              {
                header: 'App Exclusive Discount',
                content: orderDetails.payment.appExclusiveDiscount,
              },
              {
                header: 'Coupon Discount',
                content: orderDetails.payment.appExclusiveDiscount,
              },
              {
                header: 'Total Discount',
                content: orderDetails.payment.overallDiscount,
              },
              {
                header: 'Payable Amount',
                content: orderDetails.payment.payableAmount,
              },
            ], 'Payment')}
          </Col>
          <Col
            xs={24}
            md={12}
            className="mb-3"
          >
            {settlement && (
            <div
              className="mb-3"
            >
              {getTableLayout([
                {
                  header: 'Status',
                  content: getStatusIcon(settlement.Status),
                },
                {
                  header: 'Bill Amount',
                  content: settlement.billAmount,
                },
                {
                  header: 'Discount Amount',
                  content: settlement.discountAmount,
                },
              ], 'Settlement')}
            </div>
            )}
            {getTableLayout([
              {
                header: 'Points Used',
                content: loyalty.pointsUsed,
              },
              {
                header: 'Points Earned',
                content: loyalty.pointsEarned,
              },
              {
                header: 'Points Discount',
                content: loyalty.pointsUsed,
              },
            ], 'Loyalty')}
          </Col>
          <Col
            xs={24}
            md={12}
            className="mb-3"
          >
            {getTableLayout([
              {
                header: 'Name',
                content: customer.displayName,
              },
              {
                header: 'Phone',
                content: customer.phoneNumber,
              },
            ], 'Customer')}
          </Col>
          <Col
            xs={24}
            md={12}
            className="mb-3"
          >
            {getTableLayout([
              {
                header: 'Code',
                content: store.code,
              },
              {
                header: 'Name',
                content: store.displayName,
              },
            ], 'Store')}
          </Col>
          {deliveryDetails && (
            <>
              <Col
                xs={24}
                md={12}
                className="mb-3"
              >
                {getTableLayout([
                  {
                    header: 'Building Name',
                    content: deliveryDetails.deliveryAddress.buildingName,
                  },
                  {
                    header: 'Flat Number',
                    content: deliveryDetails.deliveryAddress.flatNumber,
                  },
                  {
                    header: 'Street Name',
                    content: deliveryDetails.deliveryAddress.streetName,
                  },
                  {
                    header: 'Locality',
                    content: deliveryDetails.deliveryAddress.localityName,
                  },
                  {
                    header: 'Landmark',
                    content: deliveryDetails.deliveryAddress.landmark,
                  },
                  {
                    header: 'Geo Location',
                    content: deliveryDetails.deliveryAddress.googleAddress,
                  },
                ], 'Delivery Address')}
              </Col>
              <Col
                xs={24}
                md={12}
                className="mb-3"
              >
                {getTableLayout([
                  {
                    header: 'Name',
                    content: deliveryDetails.deliveryBoy.name,
                  },
                  {
                    header: 'Phone',
                    content: deliveryDetails.deliveryBoy.phoneNumber,
                  },
                ], 'Delivery Boy')}
              </Col>
            </>
          )}
          {spendOffer
            && (
            <Col
              xs={24}
              md={12}
              className="mb-3"
            >
              {getTableLayout([
                {
                  header: 'Name',
                  content: spendOffer.name,
                },
                {
                  header: 'Min Spend Amount',
                  content: spendOffer.spendAmount,
                },
              ], 'Spend Offers')}
            </Col>
            )}
          {bogoOffers.length > 0 && (
            <Col
              xs={24}
              md={12}
              className="mb-3"
            >
              {getTableLayout(bogoOffers.map((offer) => (
                {
                  header: offer.name,
                  content: offer.retailerProduct.sellingStores[0].sellingPrice,
                }
              )), 'Bogo Offers')}
            </Col>
          )}
          <Col
            xs={24}
          >
            <Row
              className="bg-white mb-3 mx-0 rounded-0"
            >
              <Col
                xs={24}
                className="d-flex justify-content-between py-3"
              >
                <div
                  className="font-weight-bold"
                >
                  Order Items
                </div>
                <div>
                  {`${productCount} Items`}
                </div>
              </Col>
              <Col
                xs={24}
                className="px-0"
              >
                <CustomTable
                  keyField="id"
                  headers={headers}
                  content={orderItems}
                  isPaginated={false}
                  totalItems={orderItems.length}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

OrderDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default OrderDetails;
