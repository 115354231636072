import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';
import Axios from 'axios';
import { retailerProfileMetaData } from '../../utilities/Utils';
import { retailerProfile, uploadImage, uploadVideo } from '../../assets/api/axios';
import Permission from '../../access&permissions/permission';

class RetailerStoryForm extends React.Component {
  constructor(props) {
    super(props);
    const { userPermission } = this.props;
    this.checkPers = (pers) => pers.reduce(
      (acc, item) => acc || userPermission.includes(item), false,
    );
    this.state = {
      story: null,
      answers: null,
      profilePhoto: '',
      shopImages: [],
      shopVideos: [],
      actionStatus: null,
      errors: null,
      loadError: false,
      loading: true,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleLoad = () => {
    const { storeId } = this.props;
    retailerProfile(
      'GET',
      storeId,
      {},
      null,
      this.source.token,
    ).then((res) => {
      const answers = {};

      Object.keys(retailerProfileMetaData).forEach((qkey) => {
        answers[qkey] = res.data.data.isCreated && res.data.data[qkey] ? res.data.data[qkey].answer : '';
      });
      this.setState({
        story: res.data.data.isCreated ? res.data.data : null,
        loading: false,
        answers,
      });
    }).catch(() => {
      this.setState({
        loadError: true,
        loading: false,
      });
    });
  }

  retry = () => {
    this.setState({
      loading: true,
      loadError: false,
    }, () => {
      this.handleLoad();
    });
  };

  onSave = (questionKey, index = false) => {
    const { storeId } = this.props;
    const { answers, actionStatus } = this.state;
    const key = index === false ? questionKey : `${questionKey}-${index}`;
    this.setState({ actionStatus: { ...actionStatus, [key]: 'performing' } });
    retailerProfile(
      'PATCH',
      storeId,
      {},
      {
        question: questionKey,
        answer: answers[questionKey],
        status: 'APPROVED',
        client: 'ADMIN',
      },
      this.source.token,
      true,
    ).then((res) => {
      const { answers: ans, actionStatus: as } = this.state;
      this.setState({
        story: res.data.data,
        answers: {
          ...ans,
          [questionKey]: res.data.data[questionKey].answer,
        },
        actionStatus: {
          ...as,
          [key]: 'Saved Successfully!',
        },
      }, () => {
        setTimeout(() => {
          const { actionStatus: eas } = this.state;
          this.setState({
            actionStatus: {
              ...eas,
              [key]: '',
            },
          });
        }, 5000);
      });
    }).catch(() => {
      const { errors, actionStatus: eas } = this.state;
      this.setState({
        errors: {
          ...errors,
          [key]: 'Oops something went wrong!!',
        },
        actionStatus: {
          ...eas,
          [key]: '',
        },
      }, () => {
        setTimeout(() => {
          const { errors: errs } = this.state;
          this.setState({
            errors: {
              ...errs,
              [key]: '',
            },
          });
        }, 5000);
      });
    });
  }

  onApprove = (questionKey, status, index = false) => {
    const { storeId } = this.props;
    const { actionStatus, story } = this.state;
    const key = index === false ? questionKey : `${questionKey}-${index}`;
    this.setState({ actionStatus: { ...actionStatus, [key]: 'performing' } });
    const ans = story[questionKey].answer;
    if (index !== false) {
      ans[index].status = status;
    }
    retailerProfile(
      'PATCH',
      storeId,
      {},
      {
        question: questionKey,
        status,
        answer: ans,
        client: 'ADMIN',
      },
      this.source.token,
    ).then((res) => {
      const { answers, actionStatus: as } = this.state;
      this.setState({
        story: res.data.data,
        answers: {
          ...answers,
          [questionKey]: res.data.data[questionKey].answer,
        },
        actionStatus: {
          ...as,
          [key]: status === 'APPROVED' ? 'Approved Successfully!' : 'Request Declined!',
        },
      }, () => {
        setTimeout(() => {
          const { actionStatus: eas } = this.state;
          this.setState({
            actionStatus: {
              ...eas,
              [key]: '',
            },
          });
        }, 5000);
      });
    }).catch(() => {
      const { errors, actionStatus: erras } = this.state;
      this.setState({
        errors: {
          ...errors,
          [key]: 'Oops something went wrong!!',
        },
        actionStatus: {
          ...erras,
          [key]: '',
        },
      }, () => {
        setTimeout(() => {
          const { errors: errs } = this.state;
          this.setState({
            errors: {
              ...errs,
              [key]: '',
            },
          });
        }, 5000);
      });
    });
  }

  handleInputOnChange = (e) => {
    const { answers } = this.state;
    this.setState({
      answers: {
        ...answers,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleImageOnChange = (event, key, index) => {
    const { shopImages: s } = this.state;
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('img', file);
    switch (key) {
      case 'PROFILE_PHOTO':
        this.setState({
          profilePhoto: formData,
        });
        break;
      case 'SHOP_IMAGES':
        s[index] = formData;
        this.setState({
          shopImages: s,
        });
        break;
      default:
        break;
    }
  }

  handleVideoOnChange = (event, key, index) => {
    const { shopVideos: v } = this.state;
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('video', file);
    v[index] = formData;
    this.setState({
      shopVideos: v,
    });
  }

  onUploadImage = (key, index) => {
    const { profilePhoto, shopImages, actionStatus } = this.state;
    this.setState({ actionStatus: { ...actionStatus, [`${key}-${index}`]: 'performing' } });
    const formData = key === 'PROFILE_PHOTO' ? profilePhoto : shopImages[index];
    uploadImage(
      'post',
      formData,
    ).then((res) => {
      const img = { image: res.data.url, status: 'APPROVED' };
      const { answers, story } = this.state;
      const s = story && story[key] ? story[key].answer : [];
      s[index] = img;
      this.setState({
        answers: {
          ...answers,
          [key]: key === 'PROFILE_PHOTO' ? [img] : s,
        },
      }, () => { this.onSave(key, index); });
    }).catch(() => {
      const { errors, actionStatus: as } = this.state;
      this.setState({
        errors: {
          ...errors,
          [`${key}-${index}`]: 'Oops something went wrong!!',
        },
        actionStatus: {
          ...as,
          [`${key}-${index}`]: '',
        },
      }, () => {
        setTimeout(() => {
          const { errors: errs } = this.state;
          this.setState({
            errors: {
              ...errs,
              [`${key}-${index}`]: '',
            },
          });
        }, 5000);
      });
    });
  }

  onUploadVideo = (key, index) => {
    const { shopVideos, actionStatus } = this.state;
    this.setState({ actionStatus: { ...actionStatus, [`${key}-${index}`]: 'performing' } });
    const formData = shopVideos[index];
    uploadVideo(
      'post',
      formData,
    ).then((res) => {
      const vid = { image: res.data.url, status: 'APPROVED' };
      const { answers, story } = this.state;
      const s = story && story[key] ? story[key].answer : [];
      s[index] = vid;
      this.setState({
        answers: {
          ...answers,
          [key]: s,
        },
      }, () => { this.onSave(key, index); });
    }).catch(() => {
      const { errors, actionStatus: as } = this.state;
      this.setState({
        errors: {
          ...errors,
          [`${key}-${index}`]: 'Oops something went wrong!!',
        },
        actionStatus: {
          ...as,
          [`${key}-${index}`]: '',
        },
      }, () => {
        setTimeout(() => {
          const { errors: errs } = this.state;
          this.setState({
            errors: {
              ...errs,
              [`${key}-${index}`]: '',
            },
          });
        }, 5000);
      });
    });
  }

  render() {
    const {
      answers, errors, actionStatus, profilePhoto, shopImages, loadError, shopVideos,
      loading, story,
    } = this.state;
    const { approving, editing } = this.props;
    const QAElement = (key) => (
      <Row className="mb-4 mx-0">
        <Col xs={24}>
          <Form.Group as={Row}>
            <Form.Label column sm={24} className="px-0 d-flex">
              {retailerProfileMetaData[key].question}
              {
                editing && (
                  <div className="ml-auto text-secondary">
                    {`${answers[key].length}/${key === 'YEAR_OF_ESTABLISHMENT' ? 4 : 500}`}
                  </div>
                )
              }
            </Form.Label>
            <textarea
              value={answers[key]}
              id="number"
              name={key}
              maxLength={key === 'YEAR_OF_ESTABLISHMENT' ? 4 : 500}
              onChange={editing ? this.handleInputOnChange : null}
              autoComplete="off"
              rows={key === 'YEAR_OF_ESTABLISHMENT' ? 1 : 4}
              className="form-control"
              disabled={!editing}
              placeholder={editing ? retailerProfileMetaData[key].prefilledText : ''}
            />
          </Form.Group>
        </Col>
        <Col xs={24}>
          <Row
            className="d-fex flex-row-reverse align-items-center"
          >
            {
              actionStatus && actionStatus[key] === 'performing' && (
                <Spinner
                  animation="border"
                  variant="primary"
                  className="ml-4"
                />
              )
            }
            {
              editing && (
                <Col
                  xs="auto"
                  className="px-0"
                >
                  <Button
                    variant="primary"
                    onClick={() => this.onSave(key)}
                    disabled={
                      !answers[key]
                      || (story && story[key] && answers[key] === story[key].answer)
                      || (actionStatus && actionStatus[key] && actionStatus[key] === 'performing')
                      || !(this.checkPers([
                        Permission.ONBOARDING_EXPLICIT_PERMISSION_ON_STORY_SECTION,
                        Permission.RETAILER_WRITE,
                      ]))
                    }
                  >
                    SAVE
                  </Button>
                </Col>
              )
            }
            {
              approving && (
                <>
                  <Col
                    xs="auto"
                    className="px-0 pl-4"
                  >
                    <Button
                      variant="primary"
                      onClick={() => this.onApprove(key, 'DECLINED')}
                      disabled={
                        !story[key] || !story[key].answer || story[key].status !== 'PENDING'
                        || (actionStatus && actionStatus[key] && actionStatus[key] !== 'performing')
                      }
                    >
                      {story[key] && story[key].status === 'DECLINED'
                        ? 'DECLINED' : 'DECLINE'}
                    </Button>
                  </Col>
                  <Col
                    xs="auto"
                    className="px-0"
                  >
                    <Button
                      variant="primary"
                      onClick={() => this.onApprove(key, 'APPROVED')}
                      disabled={
                        !story[key] || !story[key].answer || story[key].status !== 'PENDING'
                        || (actionStatus && actionStatus[key] && actionStatus[key] !== 'performing')
                      }
                    >
                      {story[key] && story[key].status === 'APPROVED'
                        ? 'APPROVED' : 'APPROVE'}
                    </Button>
                  </Col>
                </>
              )
            }
            {
              actionStatus && actionStatus[key] && actionStatus[key] !== 'performing'
              && actionStatus[key] !== 'saving'
              && (
                <Col
                  xs="auto"
                  className="text-green"
                >
                  {actionStatus[key]}
                </Col>
              )
            }
            {
              errors && errors[key] && (
                <Col
                  xs="auto"
                  className="text-danger"
                >
                  {errors[key]}
                </Col>
              )
            }
          </Row>
        </Col>
      </Row>
    );

    const ImageElement = (key, index) => (
      <Row className="mx-0">
        <Col xs={8}>
          {
            story && story[key] && story[key].answer[index] && story[key].answer[index].image ? (
              <img
                className="py-2 retialer-image"
                src={story[key].answer[index].image}
                alt={key === 'PROFILE_PHOTO' ? 'Profile Pic' : 'Shop Pic\'s'}
              />
            ) : (
              <div className="py-2 text-secondary">Uploaded Image will appear here</div>
            )
          }
        </Col>
        <Col xs={16} className="d-flex flex-column justify-content-center p-2">
          {
            editing && (
              <input
                type="file"
                className="form-control input-file"
                onChange={(e) => { this.handleImageOnChange(e, key, index); }}
              />
            )
          }
          <Row
            className="mx-0 mt-2 d-flex align-items-center"
          >
            {
              editing && (
                <Col
                  xs="auto"
                  className="px-0"
                >
                  <Button
                    variant="primary"
                    onClick={() => this.onUploadImage(key, index)}
                    disabled={
                      (key === 'PROFILE_PHOTO' ? !profilePhoto : !shopImages[index])
                      || (actionStatus && actionStatus[`${key}-${index}`] && actionStatus[`${key}-${index}`] === 'performing')
                      || !(this.checkPers([
                        Permission.ONBOARDING_EXPLICIT_PERMISSION_ON_STORY_SECTION,
                        Permission.RETAILER_WRITE,
                      ]))
                    }
                  >
                    UPLOAD
                  </Button>
                </Col>
              )
            }
            {
              approving && (
                <>
                  <Col
                    xs="auto"
                  >
                    <Button
                      variant="primary"
                      onClick={() => this.onApprove(key, 'DECLINED', index)}
                      disabled={
                        !story[key] || !story[key].answer[index] || story[key].answer[index].status !== 'PENDING'
                        || (actionStatus && actionStatus[`${key}-${index}`] && actionStatus[`${key}-${index}`] !== 'performing')
                      }
                    >
                      {story[key] && story[key].answer[index] && story[key].answer[index].status === 'DECLINED'
                        ? 'DECLINED' : 'DECLINE'}
                    </Button>
                  </Col>
                  <Col
                    xs="auto"
                  >
                    <Button
                      variant="primary"
                      onClick={() => this.onApprove(key, 'APPROVED', index)}
                      disabled={
                        !story[key] || !story[key].answer[index]
                        || story[key].answer[index].status !== 'PENDING'
                        || (actionStatus && actionStatus[`${key}-${index}`] && actionStatus[`${key}-${index}`] !== 'performing')
                      }
                    >
                      {story[key] && story[key].answer[index] && story[key].answer[index].status === 'APPROVED'
                        ? 'APPROVED' : 'APPROVE'}
                    </Button>
                  </Col>
                </>
              )
            }
            {
              actionStatus && actionStatus[`${key}-${index}`] === 'performing' && (
                <Spinner
                  animation="border"
                  variant="primary"
                  className="ml-4"
                />
              )
            }
            {
              actionStatus && actionStatus[`${key}-${index}`]
              && actionStatus[`${key}-${index}`] !== 'performing'
              && actionStatus[`${key}-${index}`] !== 'saving' && (
                <Col
                  xs="auto"
                  className="text-green"
                >
                  {actionStatus[`${key}-${index}`]}
                </Col>
              )
            }
            {
              errors && errors[`${key}-${index}`] && (
                <Col
                  xs="auto"
                  className="text-danger"
                >
                  {errors[`${key}-${index}`]}
                </Col>
              )
            }
          </Row>
        </Col>
      </Row>
    );

    if (loading) {
      return (
        <div
          className="pt-3 text-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (loadError) {
      return (
        <div
          className="pt-3 text-center"
        >
          <span
            className="text-danger"
          >
            Something Went Wrong
          </span>
          <div>
            <Button
              variant="primary"
              onClick={() => this.retry()}
            >
              Retry
            </Button>
          </div>
        </div>
      );
    }

    const VideoElement = (key, index) => (
      <Row className="mx-0">
        <Col xs={8} className="">
          {
            story && story[key] && story[key].answer[index] && story[key].answer[index].image ? (
              <video className="py-2 video-container" src={story[key].answer[index].image} controls>
                <track kind="captions" srcLang="en" label="English" />
              </video>
            ) : (
              <div className="py-2 text-secondary">Uploaded Video will appear here</div>
            )
          }
        </Col>
        <Col xs={16} className="d-flex flex-column justify-content-center p-2">
          {
            editing && (
              <input
                type="file"
                accept="video/*"
                className="form-control input-file"
                onChange={(e) => { this.handleVideoOnChange(e, key, index); }}
              />
            )
          }
          <Row
            className="mx-0 mt-2 d-flex align-items-center"
          >
            {
              editing && (
                <Col
                  xs="auto"
                  className="px-0"
                >
                  <Button
                    variant="primary"
                    onClick={() => this.onUploadVideo(key, index)}
                    disabled={
                      (!shopVideos[index])
                      || (actionStatus && actionStatus[`${key}-${index}`] && actionStatus[`${key}-${index}`] === 'performing')
                      || !(this.checkPers([
                        Permission.ONBOARDING_EXPLICIT_PERMISSION_ON_STORY_SECTION,
                        Permission.RETAILER_WRITE,
                      ]))
                    }
                  >
                    UPLOAD
                  </Button>
                </Col>
              )
            }
            {
              approving && (
                <>
                  <Col
                    xs="auto"
                  >
                    <Button
                      variant="primary"
                      onClick={() => this.onApprove(key, 'DECLINED', index)}
                      disabled={
                        !story[key] || !story[key].answer[index] || story[key].answer[index].status !== 'PENDING'
                        || (actionStatus && actionStatus[`${key}-${index}`] && actionStatus[`${key}-${index}`] !== 'performing')
                      }
                    >
                      {story[key] && story[key].answer[index] && story[key].answer[index].status === 'DECLINED'
                        ? 'DECLINED' : 'DECLINE'}
                    </Button>
                  </Col>
                  <Col
                    xs="auto"
                  >
                    <Button
                      variant="primary"
                      onClick={() => this.onApprove(key, 'APPROVED', index)}
                      disabled={
                        !story[key] || !story[key].answer[index]
                        || story[key].answer[index].status !== 'PENDING'
                        || (actionStatus && actionStatus[`${key}-${index}`] && actionStatus[`${key}-${index}`] !== 'performing')
                      }
                    >
                      {story[key] && story[key].answer[index] && story[key].answer[index].status === 'APPROVED'
                        ? 'APPROVED' : 'APPROVE'}
                    </Button>
                  </Col>
                </>
              )
            }
            {
              actionStatus && actionStatus[`${key}-${index}`] === 'performing' && (
                <Spinner
                  animation="border"
                  variant="primary"
                  className="ml-4"
                />
              )
            }
            {
              actionStatus && actionStatus[`${key}-${index}`]
              && actionStatus[`${key}-${index}`] !== 'performing'
              && actionStatus[`${key}-${index}`] !== 'saving' && (
                <Col
                  xs="auto"
                  className="text-green"
                >
                  {actionStatus[`${key}-${index}`]}
                </Col>
              )
            }
            {
              errors && errors[`${key}-${index}`] && (
                <Col
                  xs="auto"
                  className="text-danger"
                >
                  {errors[`${key}-${index}`]}
                </Col>
              )
            }
          </Row>
        </Col>
      </Row>
    );

    return (
      <Container
        fluid
        className="retailer-story-form py-2"
      >
        {
          QAElement(
            'YEAR_OF_ESTABLISHMENT',
          )
        }
        {
          QAElement(
            'STORY',
          )
        }
        {
          QAElement(
            'IMPORTANCE',
          )
        }
        {
          QAElement(
            'GOALS',
          )
        }
        <Row className="mb-4 mx-0">
          <Col xs={24}>
            <Form.Group as={Row}>
              <Form.Label column sm={24} className="px-0">
                { retailerProfileMetaData.PROFILE_PHOTO.question }
              </Form.Label>
            </Form.Group>
          </Col>
          <Col xs={24} className="border border-radius-4 px-0 py-2 bg-white">
            {
              ImageElement(
                'PROFILE_PHOTO', 0,
              )
            }
          </Col>
        </Row>
        <Row className="mb-4 mx-0 mt-4">
          <Col xs={24}>
            <Form.Group as={Row}>
              <Form.Label column sm={24} className="px-0">
                { retailerProfileMetaData.SHOP_IMAGES.question }
              </Form.Label>
            </Form.Group>
          </Col>
          <Col xs={24} className="border border-radius-4 px-0 py-2 bg-white">
            {
              [0, 1, 2, 3, 4, 5].map((index) => (
                <>
                  {
                    ImageElement(
                      'SHOP_IMAGES', index,
                    )
                  }
                </>
              ))
            }
          </Col>
        </Row>
        <Row className="mb-4 mx-0 mt-4">
          <Col xs={24}>
            <Form.Group as={Row}>
              <Form.Label column sm={24} className="px-0">
                { retailerProfileMetaData.SHOP_VIDEOS.question }
              </Form.Label>
            </Form.Group>
          </Col>
          <Col xs={24} className="border border-radius-4 px-0 py-2 bg-white">
            {
                [0, 1, 2, 3, 4, 5].map((index) => (
                  <>
                    {
                      VideoElement(
                        'SHOP_VIDEOS', index,
                      )
                    }
                  </>
                ))
            }
          </Col>
        </Row>
      </Container>
    );
  }
}

RetailerStoryForm.propTypes = {
  storeId: PropTypes.string.isRequired,
  approving: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default RetailerStoryForm;
