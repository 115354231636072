import React, { Component } from 'react';
import {
  Container, Row, Col, Spinner, Button, InputGroup, Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import QueryString from 'query-string';
import { adminStoreCuratedGroup, registeredRegion, storeCategory } from '../../assets/api/axios';
import { dateString, getHoursDiff } from '../../utilities/Utils';
import {
  CustomModal, CustomTable, Svg,
} from '../../component/common';
import { Constant } from '../../utilities';
import {
  getRegesteredRegions, getStoreCategory, setRegesteredRegions, setStoreCategory,
} from '../../utilities/Storage';
import permission from '../../access&permissions/permission';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  const {
    searchText = '',
    sizeOfStore = '12',
    regionIn = '',
    storeTypeIds = '',
    curatedProductGrIds = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    l,
    p,
    searchText,
    sizeOfStore,
    regionIn,
    storeTypeIds,
    curatedProductGrIds,
  });
};

class SmartCataloguesStore extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    let storeCategories = getStoreCategory();
    if (storeCategories) {
      const hrDiff = getHoursDiff(storeCategories.lastUpdatedTime, new Date());
      if (hrDiff > 24) {
        storeCategories = null;
      }
    }
    let regions = getRegesteredRegions();
    if (regions) {
      const hrDiff = getHoursDiff(regions.lastUpdatedTime, new Date());
      if (hrDiff > 24) {
        regions = null;
      }
    }
    const { userPermission } = this.props;
    this.permissions = {
      ratroactive: userPermission.includes(permission.SMART_CATALOGUES_STORE_WRITE_RETROACTIVE),
      write: userPermission.includes(permission.SMART_CATALOGUES_STORE_WRITE),
    };
    this.state = {
      storeCategories: (storeCategories && storeCategories.categories) || null,
      regions: (regions && regions.regions) || null,
      rowsPerPage: param.l,
      page: param.p,
      stores: null,
      searchText: param.q,
      param,
      newParams: param,
      loading: true,
      error: false,
      storeSmartCatalogueMetaData: null,
      viewStoreSmartCatalogueMetaModal: false,
      storeSmartCatalogueMetaDataLoadStatus: '',
      storeIdForRollback: '',
      curatedGrIdForRollback: '',
      rollbacking: false,
      showRollbackingModal: false,
      rollbackError: false,
      rollbackErrorMsg: '',
      reason: '',
      actionSuccessMsg: '',
      syncAt: 'IGNORE',
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
    const { storeCategories, regions } = this.state;
    if (!storeCategories) {
      storeCategory('GET').then((res) => {
        this.setState({
          storeCategories: res.data.results,
        });
        setStoreCategory({
          categories: res.data.results,
          lastUpdatedTime: new Date(),
        });
      }).catch(() => {});
    }
    if (!regions) {
      registeredRegion('GET').then((res) => {
        this.setState({ regions: res.data.results });
        setRegesteredRegions({
          regions: res.data.results,
          lastUpdatedTime: new Date(),
        });
      }).catch(() => {});
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, searchText, storeTypeIds, curatedProductGrIds, regionIn, sizeOfStore,
    } = newParam;
    const offset = (p - 1) * l;
    adminStoreCuratedGroup(
      'GET',
      '',
      {
        offset: `${offset}`,
        limit: l,
        view: 'DEFAULT',
        storeIds: searchText,
        sizeOfStoreList: sizeOfStore,
        storeTypeIds,
        curatedProductGroupId: curatedProductGrIds,
        regionIn,
      },
    ).then((res) => {
      this.setState({
        stores: res.data,
        loading: false,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
        newParams: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  applyFilters = () => {
    const { newParams } = this.state;
    this.handleRequestProcessing({
      ...newParams,
      p: 1,
    });
  }

  resetfilters = () => {
    this.handleRequestProcessing({
      searchText: '',
      sizeOfStore: '12',
      storeTypeIds: '',
      curatedProductGrIds: '',
      regionIn: '',
      p: 1,
    });
  }

  handleDropdownChange = (data) => {
    this.setState((state) => ({
      newParams: { ...state.newParams, ...data },
    }));
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState((state) => ({
      newParams: { ...state.newParams, [name]: value },
    }));
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onSubmitSearchText = () => {
    const {
      searchText, param,
    } = this.state;
    if (searchText !== param.q) {
      this.handleRequestProcessing({ q: searchText, p: 1 });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  loadStoreSmartCatalogueMeta = (storeId) => {
    adminStoreCuratedGroup(
      'GET',
      storeId,
      {
        view: 'META',
      },
    ).then((res) => {
      this.setState({
        storeSmartCatalogueMetaDataLoadStatus: '',
        storeSmartCatalogueMetaData: res.data.results,
      });
    }).catch(() => {
      this.setState({
        storeSmartCatalogueMetaDataLoadStatus: 'error',
      });
    });
    this.retry = () => {
      this.setState({
        storeSmartCatalogueMetaDataLoadStatus: 'loading',
      }, () => {
        this.loadStoreSmartCatalogueMeta(storeId);
      });
    };
  }

  rollbackGroupMap = () => {
    this.setState({
      rollbacking: true,
      rollbackError: false,
      rollbackErrorMsg: '',
      actionSuccessMsg: false,
    });
    const {
      storeIdForRollback, curatedGrIdForRollback, reason, syncAt,
    } = this.state;
    const body = {
      action: 'ROLLBACK_CURATED_GROUP',
      body: {
        reason,
        curatedProductsGroupId: `${curatedGrIdForRollback}`,
        storeId: storeIdForRollback,
      },
      syncJobTriggerAt: syncAt,
    };
    adminStoreCuratedGroup(
      'POST',
      '',
      {},
      body,
      this.source.token,
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          rollbacking: false,
          storeIdForRollback: '',
          curatedGrIdForRollback: '',
          showRollbackingModal: false,
          reason: '',
          actionSuccessMsg: true,
        }, () => {
          setTimeout(() => {
            this.setState({
              actionSuccessMsg: false,
            });
          }, 5000);
        });
      } else {
        throw new Error();
      }
    }).catch((err) => {
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.server_response
      ) {
        this.setState({
          rollbackError: true,
          rollbacking: false,
          rollbackErrorMsg: err.response.data.server_response,
        });
        return;
      }
      this.setState({
        rollbackError: true,
        rollbacking: false,
        rollbackErrorMsg: 'Oops! Something went wrong!',
      });
    });
  }

  render() {
    const {
      param, page, stores, rowsPerPage, loading, error,
      storeSmartCatalogueMetaData, viewStoreSmartCatalogueMetaModal,
      storeSmartCatalogueMetaDataLoadStatus, showRollbackingModal,
      rollbacking, rollbackErrorMsg, reason, rollbackError,
      actionSuccessMsg, syncAt, storeCategories, regions, newParams,
    } = this.state;
    const { history } = this.props;

    const headers = [
      {
        key: 'storeId',
        displayText: 'Store Id',
        renderer: (data) => (
          <Link
            to={`/retailer/${data.storeId}?view=product`}
            className="fs-01"
          >
            {data.storeId}
          </Link>
        ),
      },
      {
        key: 'storeName',
        displayText: 'Store Name',
      },
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => (
          dateString(data.createdOn)
        ),
      },
      {
        key: 'smart-catalogues',
        displayText: 'Smart Catalogues',
        renderer: (data) => (
          <>
            {
              (data.curatedProductGroups).map((item, index) => (
                <div className="py-1">
                  <Link
                    to={`/smart-catalogues?curatedProductGroupId=${item.curatedProductsGroupId}`}
                    className="fs-01 text-medium on-hover-underline text-left"
                  >
                    {`${index + 1}. ${item.name}: `}
                    {item.status}
                  </Link>
                  {
                    item.canRollBack
                    && this.permissions
                    && (this.permissions.ratroactive
                      || this.permissions.write)
                    && (
                      <Button
                        variant="link"
                        onClick={() => {
                          this.setState({
                            showRollbackingModal: true,
                            storeIdForRollback: data.storeId,
                            curatedGrIdForRollback: item.curatedProductsGroupId,
                            reason: '',
                          });
                        }}
                        className="p-0 fs-01 text-danger"
                      >
                        &nbsp;&nbsp;
                        ROLLBACK
                      </Button>
                    )
                  }
                </div>
              ))
            }
          </>
        ),
      },
      {
        key: 'previousJobDetail',
        displayText: 'Prev. Job Detail (Clickable Links)',
        renderer: (data) => (
          <>
            {
              (data.previousJobDetail).map((item) => (
                <div>
                  <a
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pb-2"
                    style={{
                      color: item.color,
                    }}
                  >
                    {item.text}
                  </a>
                </div>
              ))
            }
          </>
        ),
      },
      {
        key: 'meta',
        displayText: 'View Meta',
        renderer: (data) => (
          <Button
            variant="link"
            onClick={() => {
              this.setState({
                storeSmartCatalogueMetaData: null,
                viewStoreSmartCatalogueMetaModal: true,
                storeSmartCatalogueMetaDataLoadStatus: 'loading',
              }, this.loadStoreSmartCatalogueMeta(data.storeId));
            }}
            className="fs-01 p-0"
          >
            META
          </Button>
        ),
      },
      {
        key: 'add',
        displayText: 'Add Smart Catalogue',
        renderer: (data) => (
          data.canAddNew
          && this.permissions
          && (this.permissions.ratroactive
            || this.permissions.write)
            ? (
              <Link
                to={`/retailer/${data.storeId}?view=bulkProductAddition`}
                className="fs-01"
              >
                ADD
              </Link>
            ) : (
              <span>---</span>
            )
        ),
      },
    ];

    const filterConfForMultiSelect = [
      {
        key: 'sizeOfStore',
        displayText: 'Size Of Store',
        options: [
          {
            label: '1',
            value: '1',
          },
          {
            label: '1, 2',
            value: '12',
          },
          {
            label: '1, 2, 3',
            value: '123',
          },
        ],
      },
      {
        key: 'storeTypeIds',
        displayText: 'Store Type',
        options: ((storeCategories || []).map((item) => ({
          label: item.description,
          value: (item.id).toString(),
        }))),
      },
      {
        key: 'regionIn',
        displayText: 'Region',
        options: ((regions || []).map((item) => ({
          label: item.region,
          value: item.region,
        }))),
      },
    ];

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        <CustomModal
          show={viewStoreSmartCatalogueMetaModal}
          onHide={() => {
            this.setState({
              viewStoreSmartCatalogueMetaModal: false,
              storeSmartCatalogueMetaData: null,
            });
          }}
          title="Curated Group Meta Data"
          closeButton
          size="lg"
          body={(
            <Container>
              {
                storeSmartCatalogueMetaDataLoadStatus === 'loading' && (
                  <div
                    className="pt-3 text-center"
                  >
                    <Spinner
                      animation="border"
                      variant="primary"
                    />
                  </div>
                )
              }
              {
                storeSmartCatalogueMetaDataLoadStatus === 'error' && (
                  <div
                    className="pt-3 text-center text-danger"
                  >
                    Something Went Wrong
                    <Button
                      variant="primary"
                      onClick={() => this.retry()}
                      className="ml-3"
                    >
                      Retry
                    </Button>
                  </div>
                )
              }
              {
                storeSmartCatalogueMetaData && (storeSmartCatalogueMetaData).map((item) => (
                  <Row>
                    <Col xs={8} className="border py-1">
                      {item.key}
                    </Col>
                    <Col xs={8} className="border py-1">
                      {item.value}
                    </Col>
                    <Col xs={8} className="border py-1 text-truncate-2">
                      {
                        item.href
                          ? (
                            <a
                              href={item.href || ''}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: item.color,
                              }}
                            >
                              {item.href}
                              <span className="pl-2">&#8599;</span>
                            </a>
                          ) : (
                            <span>NA</span>
                          )
                      }
                    </Col>
                  </Row>
                ))
              }
            </Container>
          )}
        />
        <CustomModal
          show={showRollbackingModal}
          title=""
          onHide={() => {
            this.setState({
              showRollbackingModal: false,
              storeIdForRollback: '',
              curatedGrIdForRollback: '',
              reason: '',
              rollbackError: false,
            });
          }}
          autoSize
          closeButton
          body={(
            <Container>
              <Row
                className="pt-3"
              >
                <Col
                  xs={24}
                  className="mb-2"
                >
                  <b>Sync At:</b>
                </Col>
                <Col
                  xs={24}
                  className="px-3"
                >
                  <select
                    name="syncAt"
                    id="syncAt"
                    className="form-control"
                    value={syncAt}
                    onChange={this.handleChange}
                  >
                    <option value="IGNORE">IGNORE</option>
                    {
                      this.permissions
                      && this.permissions.ratroactive && (
                        <>
                          <option value="NOW">NOW</option>
                          <option value="NIGHT">NIGHT</option>
                        </>
                      )
                    }
                  </select>
                </Col>
                <Col
                  xs={24}
                  className="my-2"
                >
                  <b>Reason:</b>
                </Col>
                <Col
                  xs={24}
                  className="px-3"
                >
                  <textarea
                    rows="3"
                    className="form-control"
                    name="reason"
                    value={reason}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col
                  xs={24}
                  className="text-center py-4 d-flex flex-row-reverse align-items-center"
                >
                  <Button
                    variant="primary"
                    className="px-3 py-2 fs-01 ml-2"
                    disabled={
                      rollbacking
                    }
                    onClick={this.rollbackGroupMap}
                  >
                    {
                      rollbacking && (
                        <Spinner
                          variant="light"
                          size="sm"
                          animation="border"
                        />
                      )
                    }
                    &nbsp;&nbsp;&nbsp;SUBMIT&nbsp;&nbsp;&nbsp;
                  </Button>
                  <Button
                    variant="link"
                    className="text-primary font-weight-bold px-3 py-2 fs-01"
                    disabled={rollbacking}
                    onClick={() => {
                      this.setState({
                        showRollbackingModal: false,
                        storeIdForRollback: '',
                        curatedGrIdForRollback: '',
                        reason: '',
                        rollbackError: false,
                      });
                    }}
                  >
                    {
                      rollbacking && (
                        <Spinner
                          variant="light"
                          size="sm"
                          animation="border"
                        />
                      )
                    }
                    &nbsp;&nbsp;&nbsp;CANCEL&nbsp;&nbsp;&nbsp;
                  </Button>
                  {rollbackError && (
                    <Col
                      xs="auto"
                      className="text-center text-danger"
                    >
                      <b>
                        {
                          rollbackErrorMsg || 'Oops Something went Wrong!!'
                        }
                      </b>
                    </Col>
                  )}
                </Col>
              </Row>
            </Container>
          )}
        />
        <Row
          className="h-100 flex-column"
        >
          <Col
            className="flex-grow-0"
          >
            <Row
              className="align-items-center py-2"
            >
              <Col
                xs="auto"
                className="px-2 pt-2"
              >
                <div className="pb-1 fs-01 text-disabled">
                  Search Store Id&apos;s (Comma separated):
                </div>
                <div>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        className="rounded-0"
                      >
                        <Svg
                          svg="search"
                          width="1rem"
                          fill={Constant.Color.DARK}
                        />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      placeholder="Search Store Id's"
                      name="searchText"
                      className="fs-01 rounded-0"
                      value={newParams.searchText}
                      onChange={this.handleOnChange}
                      autoComplete="off"
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          this.onSubmitSearchText();
                        }
                      }}
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col
                xs="auto"
                className="px-2 pt-2"
              >
                <div className="pb-1 fs-01 text-disabled">
                  Search Curated Product Group Id&apos;s:
                </div>
                <div>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        className="rounded-0"
                      >
                        <Svg
                          svg="search"
                          width="1rem"
                          fill={Constant.Color.DARK}
                        />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      placeholder="Curated Product Group Id's"
                      name="curatedProductGrIds"
                      className="fs-01 rounded-0"
                      value={newParams.curatedProductGrIds}
                      onChange={this.handleOnChange}
                      autoComplete="off"
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          this.onSubmitSearchText();
                        }
                      }}
                    />
                  </InputGroup>
                </div>
              </Col>
              {
                filterConfForMultiSelect.map((item) => (
                  <Col
                    key={item.key}
                    xs="auto"
                    className="px-2 pt-2"
                  >
                    <div className="pb-1 fs-01 text-disabled">
                      {`Select ${item.displayText}:`}
                    </div>
                    <div>
                      <Select
                        id={item.key}
                        placeholder={item.displayText}
                        onChange={(selectedItems) => {
                          const selectedItemsString = selectedItems.reduce(
                            (acc, selItem, i) => acc.concat(i === 0 ? '' : ',', selItem.value), '',
                          );
                          this.handleDropdownChange({ [item.key]: selectedItemsString });
                        }}
                        selectedVal={newParams[item.key]}
                        options={item.options}
                        isMulti
                        value={newParams[item.key]
                          ? (newParams[item.key].split(','))
                            .map((val) => ({ label: val, value: val }))
                          : []}
                        className="minw-150p"
                      />
                    </div>
                  </Col>
                ))
              }
            </Row>
            <Row className="py-2 d-flex align-items-center flex-row-reverse">
              <Col
                xs="auto"
                className="px-2"
              >
                <Button
                  variant="primary"
                  className="fs-01 font-weight-bold"
                  onClick={this.resetfilters}
                >
                  RESET FILTERS
                </Button>
              </Col>
              <Col
                xs="auto"
                className="px-2"
              >
                <Button
                  variant="success"
                  className="fs-01 font-weight-bold"
                  onClick={this.applyFilters}
                >
                  APPLY FILTERS
                </Button>
              </Col>
              {
                actionSuccessMsg && (
                  <Col
                    xs="auto"
                    className="px-2 mr-auto"
                  >
                    <div className="text-success">
                      Action Performed SuccessFully!!
                    </div>
                  </Col>
                )
              }
              <Col
                xs="auto"
                className="px-2 mr-auto"
              >
                <Button
                  variant="primary"
                  className="fs-01"
                  onClick={() => {
                    history.push({
                      pathname: '/generic-bulk-upload',
                      state: { selectedUploadType: 'BULK_SHOP_CURATED_GROUP_SYNC' },
                    });
                  }}
                >
                  UPDATE SMART CATALOGUES VIA CSV
                </Button>
              </Col>
            </Row>
          </Col>
          {
            (loading || error) && (
              <div
                className="pt-3 text-center"
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                ) : (
                  <>
                    <span
                      className="text-danger"
                    >
                      Something Went Wrong
                    </span>
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => this.retry()}
                      >
                        Retry
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )
          }
          {
            (!loading && !error) && (
              <Col
                className="pt-4 px-0 flex-grow-1 overflow-y"
              >
                <CustomTable
                  headers={headers}
                  content={stores.results}
                  keyField="id"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={stores.count}
                  hasPrev={stores.hasPrevious}
                  hasNext={stores.hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            )
          }
        </Row>
      </Container>
    );
  }
}

SmartCataloguesStore.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default SmartCataloguesStore;
