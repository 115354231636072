import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Button,
  Col, Container, Form, InputGroup, Row, Spinner,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import QueryString from 'query-string';
import {
  CustomDropdown, CustomModal, CustomTable, DatePicker, ErrorHandler,
} from '../../component/common';
import { ProcessingStatus } from '../../component/derived/table-list';
import { fraudStores } from '../../assets/api/axios';
import Svg from '../../component/common/svg/Search';
import { Constant } from '../../utilities';
import { dateString, getHoursDiff } from '../../utilities/Utils';
import FraudDetails from './FraudDetails';
import { getFraudAgents, setFraudAgents } from '../../utilities/Storage';
import MoreActions from './MoreActions';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, startDate, endDate,
  } = param;
  const {
    searchText = '',
    fraudStatus = 'under_review,flagged',
    hasPendingSettlement = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  startDate = Number(startDate) || '';
  endDate = Number(endDate) || '';
  return ({
    ...param,
    l,
    p,
    startDate,
    endDate,
    searchText,
    fraudStatus,
    hasPendingSettlement,
  });
};

class FraudManagement extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    let agentsList = getFraudAgents();
    if (agentsList) {
      const hrDiff = getHoursDiff(agentsList.lastModifiedTime, new Date());
      if (hrDiff > 24) {
        agentsList = null;
      }
    }
    this.state = {
      agentsList: (agentsList && agentsList.fraudAgents) || null,
      loading: true,
      error: false,
      stores: null,
      rowsPerPage: param.l,
      page: param.p,
      searchText: param.searchText,
      modifiedOn: '',
      showFraudModal: null,
      param,
      performingAction: '',
      updateFraudErrorMsg: '',
      selectedStoreId: '',
      indexOfUpdatingStore: -1,
      moreAction: null,
      downloading: false,
      downloadError: false,
    };
  }

  componentDidMount = () => {
    this.handleFraudAgents();
    this.handleLoad();
  }

  handleFraudAgents = () => {
    const { agentsList } = this.state;
    if (!agentsList) {
      fraudStores(
        'GET',
        '',
        {},
        {
          requestType: 'schema',
        },
      ).then((res) => {
        this.setState({
          agentsList: res.data,
        });
        setFraudAgents({
          lastModifiedTime: new Date(),
          fraudAgents: res.data,
        });
      }).catch(() => {});
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p,
      searchText, fraudStatus, hasPendingSettlement,
      startDate, endDate,
    } = newParam;
    const offset = (p - 1) * l;

    fraudStores(
      'GET',
      '',
      {},
      {
        offset: `${offset}`,
        limit: l,
        searchText,
        fraudStatus,
        hasPendingSettlement,
        startDate,
        endDate,
      },
    ).then((res) => {
      this.setState({
        loading: false,
        stores: res.data,
        searchText: newParam.searchText,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onSubmitSearchText = () => {
    const {
      searchText, param,
    } = this.state;
    if (searchText !== param.searchText) {
      this.handleRequestProcessing({ searchText, p: 1 });
    }
  }

  onCancel = () => {
    const {
      loading, param, modifiedOn,
    } = this.state;
    const {
      l, p, searchText,
    } = param;
    if (loading) {
      this.source.cancel();
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
      searchText,
      modifiedOn,
    });
  }

  handleDateChange = (startDate, endDate) => {
    const { param } = this.state;
    if (startDate !== param.startDate
      || endDate !== param.endDate) {
      this.handleRequestProcessing({
        startDate,
        endDate,
        p: 1,
      });
    }
  }

  updateFraudData = (storeData) => {
    const { indexOfUpdatingStore, selectedStoreId } = this.state;
    const { stores } = this.state;
    if (
      indexOfUpdatingStore >= 0
      && stores.results[indexOfUpdatingStore].storeId === selectedStoreId
    ) {
      const updatedStore = stores.results;
      updatedStore[indexOfUpdatingStore] = storeData;
      this.setState({
        selectedStoreId: '',
        indexOfUpdatingStore: -1,
        stores: {
          ...stores,
          results: updatedStore,
        },
      });
    }
  }

  patchStore = (storeId, data) => {
    const { param } = this.state;
    const {
      hasPendingSettlement,
    } = param;
    fraudStores(
      'PATCH',
      storeId,
      data,
      {
        hasPendingSettlement: hasPendingSettlement || 'true',
      },
    ).then((response) => {
      this.setState({
        performingAction: '',
        updateFraudErrorMsg: '',
      });
      this.updateFraudData(response.data);
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong!! Please Try Again!!';
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.server_response
      ) {
        errorMsg = err.response.data.server_response;
      }
      this.setState({
        updateFraudErrorMsg: errorMsg,
      });
    });

    this.retryPatchStore = () => {
      this.setState({
        updateFraudErrorMsg: '',
      });
      this.patchStore(storeId, data);
    };
  }

  patchFraudStatus = async (value, storeData, index, field, action) => {
    this.setState({
      indexOfUpdatingStore: index,
      selectedStoreId: storeData.storeId,
      performingAction: action,
    });
    await this.patchStore(
      storeData.storeId,
      {
        [field]: value,
      },
    );
  }

  downloadCSVFile = () => {
    const { param } = this.state;
    const {
      searchText, fraudStatus, hasPendingSettlement,
      startDate, endDate,
    } = param;
    fraudStores(
      'GET',
      '',
      {},
      {
        searchText,
        fraudStatus,
        hasPendingSettlement,
        startDate,
        endDate,
      },
    ).then((res) => {
      this.setState({
        downloading: false,
      });
      if (res && res.data && res.data.results && res.data.results.length > 0) {
        const headers = Object.keys(res.data.results[0]);
        const csv = [
          [headers],
          ...res.data.results.map((item) => [
            item.pendingSettlementAmount,
            item.storeName,
            item.storeId,
            item.agent,
            dateString(item.firstFraudCreatedOn),
            item.fraudReasons[0],
            item.retailerName,
            item.lastAssignedBy,
            item.retailerPhoneNumber,
            item.alternativePhoneNumber,
            item.lastAssignedTo,
            item.monitorFraud,
            dateString(item.settlementPendingSince),
            item.overallStatus,
          ]),
        ].map((e) => e.join(','))
          .join('\n');

        const hiddenElement = document.createElement('a');
        hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
        hiddenElement.target = '_blank';
        hiddenElement.download = 'fraud_stores.csv';
        hiddenElement.click();
      }
    }).catch(() => {
      this.setState({
        downloading: false,
        downloadError: true,
      });
    });
  }

  render() {
    const {
      loading, error, stores, searchText,
      param, rowsPerPage, page, showFraudModal, agentsList,
      selectedStoreId, performingAction, updateFraudErrorMsg,
      moreAction, downloading, downloadError,
    } = this.state;

    const headers = [
      {
        key: 'storeId',
        displayText: 'Store ID',
        renderer: (data) => (
          <Link
            to={`/retailer/${data.storeId}`}
            className="text-primary"
          >
            {data.storeId}
          </Link>
        ),
      },
      {
        key: 'storeName',
        displayText: 'Store Name',
      },
      {
        key: 'retailerPhoneNumber',
        displayText: 'Phone Number',
      },
      {
        key: 'alternativePhoneNumber',
        displayText: 'Alternative Phone Number',
      },
      {
        key: 'retailerName',
        displayText: 'Retailer Name',
      },
      {
        key: 'firstFraudCreatedOn',
        displayText: 'Fraud Flagged On',
        renderer: (data) => dateString(data.firstFraudCreatedOn),
      },
      {
        key: 'overallStatus',
        displayText: 'Fraud Status',
        renderer: (data) => (
          <Button
            variant="link"
            className="p-0 fs-01"
            onClick={() => {
              this.setState({
                showFraudModal: data,
              });
            }}
          >
            {data.overallStatus}
          </Button>
        ),
      },
      {
        key: 'fraudReasons',
        displayText: 'List of Fraud Reasons',
        renderer: (data) => (
          <>
            {data.fraudReasons.map((item) => (
              <div>
                <Button
                  variant="link"
                  className="p-0 fs-01"
                  onClick={() => {
                    this.setState({
                      showFraudModal: data,
                    });
                  }}
                >
                  {item}
                </Button>
              </div>
            ))}
          </>
        ),
      },
      {
        key: 'agent',
        displayText: 'Agent',
      },
      {
        key: 'lastAssignedTo',
        displayText: 'Last Assigned To',
        renderer: (data, index) => {
          if (data.lastAssignedTo) {
            return (
              <>
                {data.lastAssignedTo}
              </>
            );
          // eslint-disable-next-line no-else-return
          } else {
            if (performingAction === 'UPDATE_ASSIGN_TO_AGENT'
              && selectedStoreId === data.storeId
              && !updateFraudErrorMsg
            ) {
              return (
                <div
                  className="pt-1 d-flex align-item-center
                    justify-content-center"
                >
                  <Spinner
                    variant="primary"
                    animation="border"
                    size="sm"
                  />
                </div>
              );
            }
            return (
              <select
                onChange={(e) => {
                  if (e.target.value !== '') {
                    this.patchFraudStatus(e.target.value, data, index, 'assignedTo', 'UPDATE_ASSIGN_TO_AGENT');
                  }
                }}
                value={data.lastAssignedTo}
                className={`p-1 ${performingAction ? 'pointer-event-none' : ''}`}
              >
                <option value="">Select</option>
                {
                  ((agentsList && agentsList.assignmentAgents) || []).map((agent) => (
                    <option value={agent} key={agent}>{agent}</option>
                  ))
                }
              </select>
            );
          }
        },
      },
      {
        key: 'lastAssignedBy',
        displayText: 'Last Assigned By',
      },
      {
        key: 'pendingSettlementAmount',
        displayText: 'Settlement Amount Pending',
      },
      {
        key: 'settlementPendingSince',
        displayText: 'Settlement Pending Since',
        renderer: (data) => dateString(data.settlementPendingSince),
      },
      {
        key: 'monitorFraud',
        displayText: 'To be Monitored',
        renderer: (data, index) => {
          if (performingAction === 'UPDATE_FRAUD_MONITORED'
            && selectedStoreId === data.storeId
            && !updateFraudErrorMsg
          ) {
            return (
              <div
                className="pt-1 d-flex align-item-center
                  justify-content-center"
              >
                <Spinner
                  variant="primary"
                  animation="border"
                  size="sm"
                />
              </div>
            );
          }
          return (
            <CustomDropdown
              item={{
                key: 'monitorFraud',
                displayText: '',
                options: [
                  {
                    label: 'True',
                    value: 'true',
                  },
                  {
                    label: 'False',
                    value: 'false',
                  },
                ],
              }}
              onChange={() => {
                this.patchFraudStatus((data.monitorFraud === false), data, index, 'monitorFraud', 'UPDATE_FRAUD_MONITORED');
              }}
              selectedVal={(data.monitorFraud).toString()}
              placeholder="Select"
              closeButton={false}
            />
          );
        },
      },
      {
        key: 'moreAction',
        displayText: 'Action',
        renderer: (data) => (
          <Button
            variant="outline-primary"
            className="px-2 py-2 fs-01"
            onClick={() => this.setState({ moreAction: data })}
          >
            More Actions
          </Button>
        ),
      },
    ];

    const filterConfForMultiSelect = [
      {
        key: 'fraudStatus',
        displayText: 'Fraud Status',
        options: [
          {
            label: 'Flagged',
            value: 'flagged',
          },
          {
            label: 'Under Review',
            value: 'under_review',
          },
          {
            label: 'rejected',
            value: 'rejected',
          },
          {
            label: 'fraud',
            value: 'fraud',
          },
        ],
      },
    ];

    const filterConfForSingleSelect = [
      {
        key: 'hasPendingSettlement',
        displayText: 'Pending Settlement',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
        ],
      },
    ];

    return (
      <Container
        fluid
        className="h-100 bg-white px-0"
      >
        <CustomModal
          show={!!updateFraudErrorMsg}
          onHide={() => {
            this.setState({
              updateFraudErrorMsg: '',
              performingAction: '',
            });
          }}
          autoSize
          backdrop
          body={(
            <Container className="p-4">
              <Row className="mx-0 py-2 text-danger">
                {updateFraudErrorMsg}
              </Row>
              <Row
                className="mx-0 py-2 d-flex align-items-center flex-row-reverse"
              >
                <Button
                  variant="link"
                  className="font-weight-bold"
                  onClick={this.retryPatchStore}
                >
                  RETRY
                </Button>
                <Button
                  variant="link"
                  className="font-weight-bold text-danger"
                  onClick={() => {
                    this.setState({
                      updateFraudErrorMsg: '',
                      performingAction: '',
                    });
                  }}
                >
                  CANCEL
                </Button>
              </Row>
            </Container>
          )}
        />
        {
          !!showFraudModal && (
            <FraudDetails
              {...this.props}
              key={showFraudModal.storeId}
              show={!!showFraudModal}
              onHide={() => {
                this.setState({
                  showFraudModal: null,
                });
              }}
              storeId={showFraudModal.storeId}
            />
          )
        }
        {
          !!moreAction && (
            <MoreActions
              show={!!moreAction}
              onHide={() => {
                this.setState({
                  moreAction: null,
                });
              }}
              storeId={moreAction.storeId}
            />
          )
        }
        {(() => {
          let showcase = null;
          if (!stores && loading) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center
                justify-content-center"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (!stores && !loading && error) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center
                justify-content-center"
              >
                <ErrorHandler
                  retryLogic={() => this.retry()}
                />
              </div>
            );
          } else if (stores) {
            showcase = (
              <>
                <ProcessingStatus
                  show={loading || error}
                  loading={loading}
                  error={error}
                  onRetry={this.retry}
                  onCancel={this.onCancel}
                />
                <Row
                  className="mx-0"
                >
                  <Col
                    xs={24}
                    className="px-0 filter-section py-2 bg-white"
                  >
                    <Row
                      className="mx-0"
                    >
                      <Col
                        xs={24}
                        sm="auto"
                        className="px-2 py-1"
                      >
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text
                              className="rounded-0"
                            >
                              <Svg
                                svg="search"
                                width="1rem"
                                fill={Constant.Color.DARK}
                              />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            type="text"
                            placeholder="Search by Store Id, Store Name, Phone No."
                            className="fs-01 rounded-0"
                            value={searchText}
                            onChange={(e) => {
                              this.setState({
                                searchText: e.target.value,
                              });
                            }}
                            autoComplete="off"
                            onKeyPress={(e) => {
                              if (e.which === 13) {
                                this.onSubmitSearchText();
                              }
                            }}
                          />
                        </InputGroup>
                      </Col>
                      {
                        filterConfForMultiSelect.map((item) => (
                          <Col
                            key={item.key}
                            xs="auto"
                            className="pl-3"
                          >
                            <div>
                              <Select
                                id={item.key}
                                placeholder={item.displayText}
                                onChange={(selectedItems) => {
                                  const selectedItemsString = selectedItems.reduce(
                                    (acc, selItem, i) => acc.concat(i === 0 ? '' : ',', selItem.value), '',
                                  );
                                  this.handleDropdownChange({ [item.key]: selectedItemsString });
                                }}
                                selectedVal={param[item.key]}
                                options={item.options}
                                isMulti
                                value={param[item.key]
                                  ? (param[item.key].split(','))
                                    .map((val) => ({ label: val, value: val }))
                                  : []}
                                className="minw-150p"
                              />
                            </div>
                          </Col>
                        ))
                      }
                      {filterConfForSingleSelect.map((item) => (
                        <Col
                          key={item.key}
                          xs="auto"
                          className="px-2 py-1"
                        >
                          <CustomDropdown
                            item={item}
                            onChange={this.handleDropdownChange}
                            selectedVal={param[item.key]}
                          />
                        </Col>
                      ))}
                      <Col
                        xs="auto"
                        className="px-2 py-1 d-flex"
                      >
                        <div className="pt-1 pr-2 fs-0">
                          Modified On :
                        </div>
                        <DatePicker
                          isDateRange
                          onApply={(dateRange) => {
                            this.handleDateChange(
                              new Date(dateRange.startDate).setHours(0, 0, 0),
                              new Date(dateRange.endDate).setHours(23, 59, 59),
                            );
                          }}
                          startDate={param.startDate}
                          endDate={param.endDate}
                          onClear={() => {
                            this.handleRequestProcessing({
                              startDate: '', endDate: '',
                            });
                          }}
                        />
                      </Col>
                      <Col
                        xs="auto"
                        className="px-2 py-1"
                      >
                        <Button
                          variant="primary"
                          onClick={() => {
                            this.setState({
                              downloading: true,
                              downloadError: false,
                            }, this.downloadCSVFile());
                          }}
                          className="fs-1"
                          disabled={downloading}
                        >
                          Download Fraud Stores
                          &nbsp;
                          {
                            downloading && (
                              <Spinner
                                size="sm"
                                variant="light"
                                animation="border"
                              />
                            )
                          }
                        </Button>
                      </Col>
                      {
                        downloadError && (
                          <Col
                            xs="auto"
                            className="px-2 py-2 text-danger"
                          >
                            Something went wrong. Retry!
                          </Col>
                        )
                      }
                    </Row>
                  </Col>
                  <Col
                    xs={24}
                    className="px-0 pt-2 table-section"
                  >
                    <CustomTable
                      headers={headers}
                      content={stores.results}
                      keyField="storeId"
                      l={param.l}
                      p={param.p}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      totalItems={stores.count}
                      hasPrev={stores.hasPrevious}
                      hasNext={stores.hasNext}
                      onNext={this.onNext}
                      onPrev={this.onPrev}
                      onSubmitPage={this.onSubmitPage}
                      onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                      updateRowsPageInput={this.handleRowsPageInput}
                    />
                  </Col>
                </Row>
              </>
            );
          }
          return showcase;
        })()}
      </Container>
    );
  }
}

FraudManagement.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default FraudManagement;
