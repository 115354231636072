const Regex = {
  price: '[0-9]{0,7}([.][0-9]{0,2})?',
  integerValue: '[0-9]*',
  number: '[0-9]',
  alphaNumeric: '[0-9a-zA-Z]',
  mobileNumber: /[6-9]\d{9}$/,
  versionNumber: '[0-9]([.][0-9]{0,2})?([.][0-9]{0,2})?',
  drugLicenseNumber: [/^(\b\d{6}\b)$/, /^(\d{2}\/[A-Z]{2}\/[A-Z]{2}\/[A-Z]{3}\/\d{4}\/[A-Z])$/, /^[A-Z]{2}\/[A-Z]{3}\/\d{4}-\d{5}$/, /^[A-Z]{2}\d{11}$/, /^[A-Z]{3}\d{2}[A-Z]\d{4}[A-Z]{2}\d{5}$/, /^[A-Z]{3}-\d{4}\/\d{2}\/\d{6}$/, /^[A-Z]{2}\/[A-Z]{3}\/\d{4}-\d{5}$/, /^[A-Z]{2}-[A-Z]{3}-\d{4}-\d{5}$/, /^\d{4}\/\d{2}\/[A-Z]{3}\/\d{4}$/, /^[A-Z]{3}\d{2}[A-Z]{2}\d{10}$/, /^[A-Z]{2}-\d{2}-\d{4}-[A-Z]{2}\/[A-Z]$/, /^[A-Z]{2}-[A-Z]{2}\d-\d{6}$/, /^\d{4}-[A-Z]{1}$/],
};

export default Regex;
