import * as React from 'react';
import PropTypes from 'prop-types';

const LoveLocalIcon = (props) => {
  const {
    width, height, fill, stroke,
  } = props;
  return (
    <svg
      style={{
        width,
        height,
      }}
      viewBox="0 0 24 24"
    >
      <path
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20.762 14.104c-.92 4.057-4.59 6.896-8.75 6.896H3v-9.012c0-4.16 2.84-7.83 6.896-8.75a9.023 9.023 0 0110.866 10.866zM10.545 9.676H6.563v7.761h7.76v-3.982h-3.778V9.676z"
      />
    </svg>
  );
};

LoveLocalIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string.isRequired,
};

LoveLocalIcon.defaultProps = {
  fill: 'none',
};

export default LoveLocalIcon;
