import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import CheckoutStore from './CheckoutStore';

const CheckoutFromStores = ({
  cart, checkoutStores, updateCheckoutStore, createOrders, couponCode, modifyCurrentState,
  couponCodeApplied, handleChange, couponErrors, orderRequest,
  handleCouponApplication, deliveryOption, redirectedOrderId,
  redirectedOrderIdError,
}) => {
  const totalAmt = cart.storeItems.reduce((acc, cur) => acc + cur.bill.amount, 0);
  const totalDeliveryAmt = cart.storeItems.reduce((acc, cur) => acc + cur.bill.deliveryAmount, 0);
  return (
    <Row>
      <Col xs={24}>
        <div className="mt-2 mb-2"><b>Apply Coupon Code</b></div>
        <div className="form-control d-flex align-items-center">
          <img alt="" src="/images/offers.svg" className="w-20p" />
          <input
            name="couponCode"
            className="flex-grow-1 border-0 px-3"
            placeholder="Enter Coupon Code"
            value={couponCode}
            onChange={handleChange}
            autoComplete="off"
          />
          {
          couponCodeApplied ? (
            <div>
              <b className="text-success">Applied</b>
              <Button
                variant="link"
                onClick={() => handleCouponApplication()}
                className="fs-4 text-muted"
              >
                &times;
              </Button>
            </div>
          ) : (
            <Button
              variant="link"
              onClick={() => handleCouponApplication(true)}
              disabled={orderRequest || !couponCode}
            >
              <b>Apply</b>
            </Button>
          )
        }
        </div>
        <div className="text-center text-danger p-2">
          {
          couponErrors.map((error) => (
            <div key={error}>{error}</div>
          ))
        }
        </div>
      </Col>
      <Col xs={24} className="mt-3 d-flex align-items-center">
        <div>Redirected Order Id:&nbsp;</div>
        <div className="pl-4 pr-2">
          <input
            type="text"
            name="redirectedOrderId"
            value={redirectedOrderId}
            maxLength={10}
            onChange={handleChange}
          />
        </div>
        {
          redirectedOrderIdError && (
            <div className="text-center text-danger p-2 font-weight-bold">
              {`*${redirectedOrderIdError}`}
            </div>
          )
        }
      </Col>
      <Col xs={24} className="mt-3">
        <Row className="mx-0">
          <b>Total Bill Amount</b>
        </Row>
        <Row className="mx-0 mt-3 bg-white py-3 align-items-center border border-primary">
          <Col>
            <Row>
              <Col xs={16}>Bill Amount</Col>
              <Col xs={8} className="text-right">{`\u20B9 ${deliveryOption === 'PFS' ? totalAmt - totalDeliveryAmt : cart.cart.billAmount}`}</Col>
            </Row>

            <Row className="text-success">
              <Col xs={16}>Platform Fee</Col>
              <Col xs={8} className="text-right"><b>{`+ \u20B9 ${cart.cart.totalPlatformFee}`}</b></Col>
            </Row>
            <Row className="text-success">
              <Col xs={16}>Discount</Col>
              <Col xs={8} className="text-right"><b>{`- \u20B9 ${cart.cart.totalCouponAmount}`}</b></Col>
            </Row>
            <Row className="font-weight-bold fs-2">
              <Col xs={16}>Total Amount</Col>
              <Col xs={8} className="text-right">
                {`\u20B9 ${deliveryOption === 'PFS'
                  ? totalAmt - totalDeliveryAmt - cart.cart.totalCouponAmount : cart.cart.billAmount - cart.cart.totalCouponAmount}`}
              </Col>
            </Row>
          </Col>
          <Col xs={10} className="border-left px-4">
            <Button
              onClick={() => modifyCurrentState(true)}
              variant="outline-primary"
              block
              className="py-3"
              disabled={orderRequest}
            >
              <b>+ Add More Products</b>
            </Button>
            <Button
              onClick={() => createOrders()}
              disabled={orderRequest || checkoutStores.filter((store) => store.paymentPreference === '').length !== 0}
              block
              className="py-3"
            >
              <b>Checkout All</b>
            </Button>
          </Col>
        </Row>
      </Col>
      <Col xs={24} className="mt-3">
        <Row className="mx-0">
          <b>Checkout from Stores</b>
        </Row>
        <Row className="mx-0">
          {
          cart.storeItems.map((storeCart) => (
            <CheckoutStore
              key={storeCart.store.code}
              storeCart={storeCart}
              cartDetails={cart}
              checkoutStore={checkoutStores.find(
                (checkoutStore) => checkoutStore.storeId === storeCart.store.code,
              )}
              updateCheckoutStore={updateCheckoutStore}
              createOrders={createOrders}
              orderRequest={orderRequest}
              deliveryOption={deliveryOption}
            />
          ))
        }
        </Row>
      </Col>
    </Row>
  );
};

CheckoutFromStores.propTypes = {
  cart: PropTypes.shape({
    storeItems: PropTypes.arrayOf(PropTypes.shape({})),
    cart: PropTypes.shape({
      billAmount: PropTypes.number,
      totalCouponAmount: PropTypes.number,
      totalPlatformFee: PropTypes.number,
    }),
  }).isRequired,
  checkoutStores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateCheckoutStore: PropTypes.func.isRequired,
  createOrders: PropTypes.func.isRequired,
  couponCode: PropTypes.string.isRequired,
  couponCodeApplied: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  couponErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  orderRequest: PropTypes.bool.isRequired,
  modifyCurrentState: PropTypes.func.isRequired,
  handleCouponApplication: PropTypes.func.isRequired,
  deliveryOption: PropTypes.string.isRequired,
  redirectedOrderId: PropTypes.string.isRequired,
  redirectedOrderIdError: PropTypes.string.isRequired,
};

export default CheckoutFromStores;
