import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import DeleteSvg from '../../assets/icon/delete.svg';
import ThumbnailSvg from '../../assets/icon/thumbnail.svg';

function CustomProductView(props) {
  const {
    product,
    className,
    handleAddOrRemoveCustomProduct,
  } = props;
  return (
    <Container fluid className={`py-3 ${className}`}>
      <Row>
        <Col xs={4} className="image-container">
          <img src={product.url || ThumbnailSvg} alt="" className="product-image" />
        </Col>
        <Col>
          <h4 className="fs-1 mb-0">{product.displayName}</h4>
          <div
            className="pt-1"
          >
            <span
              className="text-medium"
            >
              {product.unitString}
            </span>
            <span
              className="text-primary"
            >
              &nbsp;-&nbsp;
              {product.store.displayName}
            </span>
          </div>
        </Col>
        <Col
          xs="auto"
          className="pl-0"
        >
          <div
            className="d-flex"
          >
            <Button
              variant="link"
              className="text-decoration-none"
              onClick={() => {
                handleAddOrRemoveCustomProduct(product.id, 0, product.storeId);
              }}
            >
              <img src={DeleteSvg} alt="" />
            </Button>
            <div className="d-flex border border-success">
              <Button
                variant="link"
                className="text-decoration-none text-success"
                onClick={() => {
                  handleAddOrRemoveCustomProduct(
                    product.id, Number(product.quantity) - 1, product.storeId,
                  );
                }}
              >
                -
              </Button>
              <h4 className="text-success m-auto mb-0">
                {product.quantity}
              </h4>
              <Button
                variant="link"
                className="text-decoration-none text-success"
                onClick={() => {
                  handleAddOrRemoveCustomProduct(
                    product.id, Number(product.quantity) + 1, product.storeId,
                  );
                }}
              >
                +
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

CustomProductView.propTypes = {
  product: PropTypes.shape({
    url: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    storeId: PropTypes.string.isRequired,
    unitString: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    store: PropTypes.shape({
      displayName: PropTypes.string,
    }),
  }).isRequired,
  className: PropTypes.string.isRequired,
  handleAddOrRemoveCustomProduct: PropTypes.func.isRequired,
};

export default CustomProductView;
