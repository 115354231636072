import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Spinner, Button,
} from 'react-bootstrap';
import { reportCustomer } from '../../assets/api/axios';
import { CustomModal, CustomTable } from '../../component/common';

const CustomerReport = (props) => {
  const { customerId, show, toggleReportCustomerModal } = props;
  const [loadStatus, setLoadStatus] = useState('');
  const [customerReportLogs, setCustomerReportLogs] = useState([]);

  const loadCustomerReportLogs = () => {
    setLoadStatus('LOADING');
    reportCustomer(
      'GET',
      customerId,
    ).then((res) => {
      setCustomerReportLogs(res.data.results);
      setLoadStatus('SUCCESS');
    }).catch(() => {
      setLoadStatus('ERROR');
    });
  };

  useEffect(() => {
    if (!loadStatus) {
      loadCustomerReportLogs();
    }
  });

  const retryFetchingCustomerReportLogs = () => {
    setLoadStatus('');
  };

  const headerConfig = [
    {
      key: 'orderId',
      displayText: 'Order Id',
    },
    {
      key: 'comment',
      displayText: 'Status',
    },
    {
      key: 'user',
      displayText: 'Commented By',
    },
  ];

  return (
    <CustomModal
      show={show}
      size="lg"
      onHide={toggleReportCustomerModal}
      closeButton
      body={(
        <Container className="p-4">
          {
            loadStatus === 'LOADING' && (
              <Col
                xs={24}
                className="text-primary text-center"
              >
                <Row className="py-2 justify-content-center d-flex">
                  <Spinner
                    variant="primary"
                    animation="border"
                  />
                </Row>
                <Row className="py-2 justify-content-center">
                  Fetching Customer Report Logs
                </Row>
              </Col>
            )
          }
          {
            loadStatus === 'SUCCESS' && (
              <CustomTable
                headers={headerConfig}
                keyField="orderId"
                content={customerReportLogs}
                isPaginated={false}
                totalItems={customerReportLogs.length}
              />
            )
          }
          {
            loadStatus === 'ERROR' && (
              <Col xs={24} className="text-danger text-center p-2">
                Oops Something went wrong while fetching Customer Report logs, Please try again!!
                &nbsp;&nbsp;
                <Button
                  variant="link"
                  className="text-primary font-weight-bold"
                  onClick={() => { retryFetchingCustomerReportLogs(); }}
                >
                  RETRY
                </Button>
              </Col>
            )
          }
          <Col
            xs={24}
            className="d-flex justify-content-center"
          >
            <Button
              variant="outline-primary"
              onClick={() => { toggleReportCustomerModal(); }}
            >
              EXIT
            </Button>
          </Col>
        </Container>
      )}
    />
  );
};

CustomerReport.propTypes = {
  customerId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  show: PropTypes.bool.isRequired,
  toggleReportCustomerModal: PropTypes.func.isRequired,
};

export default CustomerReport;
