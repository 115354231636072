import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';
import { CustomModal, CustomTable } from '../../component/common';
import { dateString } from '../../utilities/Utils';
import { retailerPayments } from '../../assets/api/axios';

function RetailerPayments(props) {
  const { show, onClose, storeId } = props;
  const [loadStatus, setLoadStatus] = useState('');
  const [data, setData] = useState(null);

  const handleLoad = () => {
    setLoadStatus('LOADING');
    retailerPayments(
      'GET',
      { storeCode: storeId },
    ).then((response) => {
      setData(response.data);
      setLoadStatus('SUCCESS');
    }).catch(() => {
      setLoadStatus('ERROR');
    });
  };

  useEffect(() => {
    if (!loadStatus) {
      handleLoad();
    }
  });

  let showcase = null;
  const headerConfig = [
    {
      key: 'id',
      displayText: 'Id',
    },
    {
      key: 'created_on',
      displayText: 'Created On',
      renderer: (rowData) => dateString(rowData.created_on),
    },
    {
      key: 'amount',
      displayText: 'Amount (Rupees)',
    },
    {
      key: 'status',
      displayText: 'Status',
    },
  ];

  switch (loadStatus) {
    case 'LOADING': {
      showcase = (
        <div className="d-flex m-4 justify-content-center align-items-center">
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
      break;
    }
    case 'ERROR': {
      showcase = (
        <div xs={24} className="m-4 text-danger text-center p-2">
          Oops! Something went wrong while fetching logs,
          Please try again!!
          &nbsp;&nbsp;
          <Button
            variant="primary"
            className="mt-3 font-weight-bold"
            onClick={() => { handleLoad(); }}
          >
            RETRY
          </Button>
        </div>
      );
      break;
    }
    case 'SUCCESS': {
      showcase = (
        <CustomTable
          keyField="id"
          headers={headerConfig}
          content={data.results}
          isPaginated={false}
          totalItems={data.count}
        />
      );
      break;
    }
    default:
      showcase = <></>;
  }

  return (
    <CustomModal
      show={show}
      autoSize
      title="Retailer Payments"
      onHide={onClose}
      body={showcase}
      closeButton
    />
  );
}

RetailerPayments.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  storeId: PropTypes.string.isRequired,
};

export default RetailerPayments;
