import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import {
  Button, Container, Spinner, Row, Col,
  InputGroup, Form,
} from 'react-bootstrap';
import { flashsalemeta } from '../../assets/api/axios';
import { CustomTable, CustomModal, CustomDropdown } from '../../component/common';
import Svg from '../../component/common/Svg';
import Constant from '../../utilities/Constant';
import { dateString } from '../../utilities/Utils';
import permission from '../../access&permissions/permission';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let { l, p, isActive = 'true' } = param;
  const {
    jobId = '', libraryProductIds = '', atleastOneOrder,
    typeofUser = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  isActive = isActive === 'true';
  return ({
    l,
    p,
    jobId,
    libraryProductIds,
    isActive,
    atleastOneOrder,
    typeofUser,
  });
};

class FlashSaleAggregated extends Component {
  constructor(props) {
    super(props);
    const { history } = this.props;
    const params = getQueryParams(history.location.search);
    this.state = {
      flashSaleRecords: null,
      jobId: params.jobId,
      libraryProductIds: params.libraryProductIds,
      isActive: params.isActive,
      atleastOneOrder: params.atleastOneOrder,
      rowsPerPage: params.l,
      page: params.p,
      params,
      loading: true,
      error: false,
      stopFlashSale: null,
      stopType: 'STOP_ONLY',
      reason: '',
      submitting: false,
      submitError: false,
      submitStatusMsg: '',
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount() {
    this.handleLoad();
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { params } = this.state;
    const newParams = { ...params, ...data };
    const offset = (newParams.p - 1) * newParams.l;
    const {
      l, jobId, libraryProductIds, isActive, atleastOneOrder,
      typeofUser,
    } = newParams;
    flashsalemeta(
      'GET',
      {
        offset: `${offset}`,
        limit: l,
        viewType: 'aggregated',
        jobId,
        libraryProductIds,
        onlyActive: isActive,
        leastOneOrder: atleastOneOrder,
        typeofUser,
      },
      null,
      this.source.token,
    ).then((res) => {
      this.setState({
        flashSaleRecords: res.data,
        rowsPerPage: newParams.l,
        page: newParams.p,
        params: newParams,
        loading: false,
      }, () => {
        if (Object.keys(newParams).find((key) => newParams[key] !== params[key])) {
          Object.keys(newParams).forEach((key) => {
            if (!newParams[key]) {
              delete newParams[key];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParams),
          });
        }
      });
    }).catch(() => {
      this.setState({
        error: true,
      });
    });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad(newParams);
      });
    };
  }

  handleRequestProcessing = (data) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      loading: true,
      error: false,
    }, () => {
      this.handleLoad(data);
    });
  }

  onNext = () => {
    const { params } = this.state;
    this.handleRequestProcessing({ p: params.p + 1 });
  }

  onPrev = () => {
    const { params } = this.state;
    this.handleRequestProcessing({ p: params.p - 1 });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onSubmitPage = () => {
    const { params, page } = this.state;
    if (params.p !== page) {
      this.handleRequestProcessing({ p: page });
    }
  }

  onSubmitRowsPerPage = () => {
    const { params, rowsPerPage } = this.state;
    if (params.l !== rowsPerPage) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onSubmitJobId = () => {
    const { jobId, params } = this.state;
    if (jobId !== params.jobId) {
      this.handleRequestProcessing({ jobId, p: 1 });
    }
  }

  onSubmitSearchText = () => {
    const { libraryProductIds, params } = this.state;
    if (libraryProductIds !== params.libraryProductIds) {
      this.handleRequestProcessing({ libraryProductIds, p: 1 });
    }
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  handleStopFlashSale = () => {
    const { stopFlashSale, stopType, reason } = this.state;
    flashsalemeta(
      'PATCH',
      {},
      {
        action: stopType,
        body: {
          jobId: stopFlashSale.jobId,
          libraryProductIds: [stopFlashSale.libraryProductId],
          reason,
        },
      },
      this.source.token,
    ).then((res) => {
      this.setState({
        ...(
          res && res.data && res.data.server_response
            ? { submitStatusMsg: res.data.server_response, submitting: false }
            : {}
        ),
      }, () => {
        setTimeout(() => {
          this.setState({
            stopFlashSale: null,
            submitStatusMsg: '',
            reason: '',
            stopType: 'STOP_ONLY',
            loading: true,
          }, () => {
            this.handleLoad();
          });
        }, 5000);
      });
    }).catch((err) => {
      this.setState({
        ...(
          err && err.response
          && err.response.data
          && err.response.data.server_response
            ? {
              submitStatusMsg: err.response.data.server_response,
              submitting: false,
              submitError: true,
            }
            : { submitting: false, submitError: true }
        ),
      }, () => {
        setTimeout(() => {
          this.setState({
            stopFlashSale: null,
            submitStatusMsg: '',
          });
        }, 5000);
      });
    });
  }

  render() {
    const {
      flashSaleRecords, rowsPerPage, page, params,
      loading, error, jobId, stopFlashSale,
      submitting, submitError, libraryProductIds,
      isActive, atleastOneOrder, reason, stopType,
      submitStatusMsg,
    } = this.state;

    const { history, userPermission } = this.props;
    const showStopFlashSale = userPermission.includes(permission.FLASH_SALE_AGGREGATED_WRITE);

    const headers = [
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => (
          <>{dateString(data.createdOn)}</>
        ),
      },
      {
        key: 'maxExpiryDate',
        displayText: 'Expiry Date',
        renderer: (data) => (
          <>{dateString(data.maxExpiryDate)}</>
        ),
      },
      {
        key: 'nonActiveCount',
        displayText: 'Non Active Count',
      },
      {
        key: 'activeCount',
        displayText: 'Active Count',
      },
      {
        key: 'jobId',
        displayText: 'Job Id',
      },
      {
        key: 'libraryProductId',
        displayText: 'Library Product Id',
      },
      {
        key: 'libraryProductName',
        displayText: 'Library Product Name',
      },
      {
        key: 'libraryProductMrp',
        displayText: 'Library Product Mrp',
      },
      {
        key: 'avgMinSpendAmount',
        displayText: 'Avg Min Spend Amt',
      },
      ...(
        showStopFlashSale
          ? [
            {
              key: 'stopFlashSale',
              displayText: 'Stop Falsh Sale',
              renderer: (data) => {
                if (data.activeCount > 0) {
                  return (
                    <Button
                      variant="link"
                      className="text-danger fs-01 p-0"
                      onClick={() => {
                        this.setState({ stopFlashSale: data });
                      }}
                    >
                      STOP ALL
                    </Button>
                  );
                }
                return (
                  <span>SEALED</span>
                );
              },
            },
          ] : []
      ),
      {
        key: 'view',
        displayText: 'View All',
        renderer: (data) => (
          <Button
            variant="link"
            className="fs-01 p-0"
            onClick={() => {
              history.push(`/flash-sale-detailed?jobId=${data.jobId}&libraryProductIds=${data.libraryProductId}`);
            }}
          >
            VIEW
          </Button>
        ),
      },
    ];

    const filterConf = [{
      key: 'typeofUser',
      displayText: 'Type of User',
      options: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'FIRST TIME USER',
          value: 'FIRST_TIME_USER',
        },
        {
          label: 'EXISTING USER',
          value: 'EXISTING_USER',
        },
        {
          label: 'GUEST USER',
          value: 'GUEST_USER',
        },
      ],
    },
    ];

    const confirmationBody = (
      <Container>
        <Row
          className="pt-3"
        >
          <Col
            xs={8}
          >
            <b>Stop Type:</b>
          </Col>
          <Col
            className="px-3"
            xs={16}
          >
            <select
              name="stopType"
              id="stopType"
              className="form-control"
              value={stopType}
              onChange={(e) => { this.setState({ stopType: e.target.value }); }}
            >
              <option value="STOP_AND_CLEAR_FROM_CART">STOP_AND_CLEAR_FROM_CART</option>
              <option value="STOP_ONLY">STOP_ONLY</option>
            </select>
          </Col>
        </Row>
        <Row
          className="pt-3"
        >
          <Col
            xs={8}
          >
            <b>Reason:</b>
          </Col>
          <Col
            className="px-3"
            xs={16}
          >
            <input
              type="text"
              className="form-control"
              name="reason"
              value={reason}
              onChange={this.handleOnChange}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={24}
            className="text-center pt-3"
          >
            <Button
              variant="primary"
              className="px-3 py-2"
              disabled={
                !stopType
                || submitting
                || !!submitStatusMsg
              }
              onClick={() => {
                this.setState({
                  submitting: true,
                }, () => { this.handleStopFlashSale(); });
              }}
            >
              {
                submitting && (
                  <Spinner
                    variant="light"
                    size="sm"
                    animation="border"
                  />
                )
              }
              &nbsp;&nbsp;&nbsp;Submit&nbsp;&nbsp;&nbsp;
            </Button>
          </Col>
          {submitError && (
            <Col
              xs={24}
              className="text-center text-danger pt-3"
            >
              <b>Oops Something went Wrong!!</b>
            </Col>
          )}
          {
            submitStatusMsg && (
              <Col
                xs={24}
                className="text-center pt-3"
              >
                Response message:&nbsp;
                <span className={`${submitError ? 'text-danger' : 'text-green'}`}>{submitStatusMsg}</span>
              </Col>
            )
          }
        </Row>
      </Container>
    );

    if (loading) {
      return (
        <div
          className="pt-3 text-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (error) {
      return (
        <div
          className="pt-3 text-center"
        >
          <span
            className="text-danger"
          >
            Something Went Wrong
          </span>
          <div>
            <Button
              variant="primary"
              onClick={() => this.retry()}
            >
              Retry
            </Button>
          </div>
        </div>
      );
    }

    return (
      <Container
        fluid
        className="bg-white"
      >
        <CustomModal
          show={!!stopFlashSale}
          body={confirmationBody}
          closeButton
          onHide={() => {
            this.setState({
              stopFlashSale: null,
              stopType: 'STOP_ONLY',
              reason: '',
              submitStatusMsg: '',
              submitting: false,
              submitError: false,
            });
          }}
        />
        <Row
          className="p-2"
        >
          <Col
            xs="auto"
            className="px-0"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Job Id"
                name="jobId"
                className="fs-01 rounded-0"
                value={jobId}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.onSubmitJobId();
                  }
                }}
              />
            </InputGroup>
          </Col>
          <Col
            xs="auto"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Library Product Ids"
                name="libraryProductIds"
                className="fs-01 rounded-0"
                value={libraryProductIds}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.onSubmitSearchText();
                  }
                }}
              />
            </InputGroup>
          </Col>
          {filterConf.map((item) => (
            <Col
              key={item.key}
              xs="auto"
              className="pl-0"
            >
              <CustomDropdown
                item={item}
                onChange={this.handleDropdownChange}
                selectedVal={params[item.key]}
              />
            </Col>
          ))}
          <Col
            xs="auto"
            className="d-flex justify-content-center align-items-center pl-0"
          >
            <Form.Check
              custom
              label="Active"
              type="checkbox"
              id="active"
              checked={isActive}
              onChange={() => {
                this.setState({
                  isActive: !isActive,
                }, () => {
                  this.handleRequestProcessing({ isActive: !isActive, p: 1 });
                });
              }}
              className="mr-4"
            />
            <Form.Check
              custom
              label="Atleast One Order"
              type="checkbox"
              id="atleastOneOrder"
              checked={atleastOneOrder}
              onChange={() => {
                this.setState({
                  atleastOneOrder: !atleastOneOrder,
                }, () => {
                  this.handleRequestProcessing({ atleastOneOrder: !atleastOneOrder, p: 1 });
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          {
            flashSaleRecords && (
              <CustomTable
                keyField="createdOn"
                headers={headers}
                content={flashSaleRecords.results}
                isPaginated
                totalItems={flashSaleRecords.count}
                hasPrev={flashSaleRecords.hasPrevious}
                hasNext={flashSaleRecords.hasNext}
                l={params.l}
                p={params.p}
                rowsPerPage={rowsPerPage}
                page={page}
                onNext={this.onNext}
                onPrev={this.onPrev}
                updateRowsPageInput={this.handleRowsPageInput}
                onSubmitPage={this.onSubmitPage}
                onSubmitRowsPerPage={this.onSubmitRowsPerPage}
              />
            )
          }
        </Row>
      </Container>
    );
  }
}

FlashSaleAggregated.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default FlashSaleAggregated;
