import React, { Component } from 'react';
import {
  Container, Row, Col, Spinner, Button, InputGroup, Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Axios from 'axios';
import QueryString from 'query-string';
import { Link } from 'react-router-dom';
import { ramStoresWithRating } from '../../assets/api/axios';
import { CustomTable, Svg, CustomDropdown } from '../../component/common';
import { Utils, Constant } from '../../utilities';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  const {
    q = '',
    sortBy = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    l,
    p,
    q,
    sortBy,
  });
};

class StoresReview extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    this.state = {
      rowsPerPage: param.l,
      page: param.p,
      searchText: param.q,
      shopReviews: null,
      param,
      loading: true,
      error: false,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, q, sortBy,
    } = newParam;
    const offset = (p - 1) * l;
    ramStoresWithRating(
      'GET', `${offset}`, l, q, sortBy, this.source.token,
    ).then((res) => {
      this.setState({
        shopReviews: res.data,
        loading: false,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onSubmitSearchText = () => {
    const {
      searchText, param,
    } = this.state;
    if (searchText !== param.q) {
      this.handleRequestProcessing({ q: searchText, p: 1 });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }


  render() {
    const {
      param, page, shopReviews, rowsPerPage,
      loading, error, searchText,
    } = this.state;

    const headers = [
      {
        key: 'code',
        displayText: 'Code',
        renderer: (data) => (
          <Link
            to={`/review-panel?storeId=${data.code}&${
              QueryString.stringify(Utils.deleteEmptyField(param))}`}
            className="text-primary"
          >
            {data.code}
          </Link>
        ),
      },
      {
        key: 'name',
        displayText: 'Shop Name',
      },
      {
        key: 'rating_count',
        displayText: 'Rating Count',
      },
      {
        key: 'avgRating',
        displayText: 'Avg. Rating',
      },
    ];

    const filterConf = [
      {
        key: 'sortBy',
        displayText: 'Sort By',
        options: [
          {
            label: 'RATING',
            value: 'rating',
          },
          {
            label: 'AVG_REVIEW',
            value: 'avg_review',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
    ];

    if (loading || error) {
      return (
        <div
          className="pt-3 text-center"
        >
          {loading ? (
            <Spinner
              animation="border"
              variant="primary"
            />
          ) : (
            <>
              <span
                className="text-danger"
              >
              Something Went Wrong
              </span>
              <div>
                <Button
                  variant="primary"
                  onClick={() => this.retry()}
                >
                Retry
                </Button>
              </div>
            </>
          )}
        </div>
      );
    }

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        <Row>
          <Col
            xs={24}
            sm="auto"
            className="px-2 py-2"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Search"
                name="searchText"
                className="fs-01 rounded-0"
                value={searchText}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.onSubmitSearchText();
                  }
                }}
              />
            </InputGroup>
          </Col>
          {filterConf.map((item) => (
            <Col
              key={item.key}
              xs="auto"
              className="px-2 py-2"
            >
              <CustomDropdown
                item={item}
                onChange={this.handleDropdownChange}
                selectedVal={param[item.key]}
              />
            </Col>
          ))}
        </Row>
        <Row>
          <Col
            xs={24}
            className="px-0"
          >
            <CustomTable
              headers={headers}
              content={shopReviews.results}
              keyField="code"
              l={param.l}
              p={param.p}
              rowsPerPage={rowsPerPage}
              page={page}
              totalItems={shopReviews.count}
              hasPrev={shopReviews.hasPrevious}
              hasNext={shopReviews.hasNext}
              onNext={this.onNext}
              onPrev={this.onPrev}
              onSubmitPage={this.onSubmitPage}
              onSubmitRowsPerPage={this.onSubmitRowsPerPage}
              updateRowsPageInput={this.handleRowsPageInput}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

StoresReview.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default StoresReview;
