import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import { exotelCallDetails } from '../../assets/api/axios';
import { CustomModal } from '../../component/common';
import CustomTable from '../../component/common/CustomTable';
import { dateString } from '../../utilities/Utils';
import PatchExotelCallDetails from './PatchExotelCallDetails';

const ExotelCall = (props) => {
  const {
    show, toggleExotelCallModal, initiateCallTo, orderDetails,
  } = props;
  const [initiateCall, toggleInitiateCall] = useState(false);
  const [fetchingPrevCallLogsStatus, setFetchingPrevCallLogsStatus] = useState('');
  const [exotelCallLogs, setPrevCallLogs] = useState(null);

  const fetchPrevCallLogs = () => {
    setFetchingPrevCallLogsStatus('LOADING');
    exotelCallDetails(
      'GET',
      null,
      '',
      {
        orderId: orderDetails.orderId,
        action: initiateCallTo === 'retailer' ? 'CALL_TO_RETAILER' : 'CALL_TO_CUSTOMER',
      },
    ).then((res) => {
      setFetchingPrevCallLogsStatus('SUCCESS');
      setPrevCallLogs(res.data.results);
    }).catch(() => {
      setFetchingPrevCallLogsStatus('ERROR');
    });
  };

  useEffect(() => {
    if (!fetchingPrevCallLogsStatus) {
      fetchPrevCallLogs();
    }
  });

  const retryFetchingCallLogs = () => {
    setFetchingPrevCallLogsStatus('');
  };

  const headersConfig = [
    {
      key: 'callDescription',
      displayText: 'Description',
      renderer: (rowData) => (<span className="font-weight-bold">{rowData.callDescription}</span>),
    },
    {
      key: 'callPurpose',
      displayText: 'Call Purpose',
      renderer: (rowData) => (rowData.callPurpose),
    },
    {
      key: 'callComment',
      displayText: 'Call Comment',
      // eslint-disable-next-line max-len
      renderer: (rowData) => ((rowData.callComment) ? (rowData.callComment) : (rowData.callDescription)),
    },

    {
      key: 'status',
      displayText: 'Status',
    },
    {
      key: 'callFrom',
      displayText: 'Call From',
    },
    {
      key: 'createdOn',
      displayText: 'Created On',
      renderer: (rowData) => dateString(rowData.createdOn),
    },
    {
      key: 'modifiedOn',
      displayText: 'Modified On',
      renderer: (rowData) => dateString(rowData.modifiedOn),
    },
  ];

  function exotelCallDetailsFunction() {
    if (initiateCall) {
      return (
        <PatchExotelCallDetails
          phoneNumber={
            initiateCallTo === 'retailer'
              ? orderDetails.store.phoneNumber
              : orderDetails.customer.phoneNumber
          }
          action={initiateCallTo === 'retailer' ? 'CALL_TO_RETAILER' : 'CALL_TO_CUSTOMER'}
          initiateCall={initiateCall}
          onHide={() => {
            toggleExotelCallModal();
            toggleInitiateCall(false);
          }}
          orderId={orderDetails.orderId}
        />
      );
    }
    return '';
  }
  return (
    <CustomModal
      show={show}
      onHide={() => {}}
      body={(
        <Container className="py-6">
          <Row>
            {
              fetchingPrevCallLogsStatus === 'SUCCESS'
              && !!exotelCallLogs
              && exotelCallLogs.length > 0
              && (
                <Col xs={24}>
                  <div className="text-center p-2">
                    <b>
                      This has been called before
                      &nbsp;
                      {exotelCallLogs.length}
                      &nbsp;
                      times
                    </b>
                  </div>
                  <CustomTable
                    headers={headersConfig}
                    isPaginated={false}
                    content={exotelCallLogs}
                    totalItems={exotelCallLogs.length}
                    keyField="id"
                  />
                </Col>
              )
            }
            {
              fetchingPrevCallLogsStatus === 'ERROR' && (
                <Col xs={24} className="text-danger text-center p-2">
                  Oops Something went wrong while fetching previous logs, Please try again!!
                  &nbsp;&nbsp;
                  <Button
                    variant="link"
                    className="text-primary font-weight-bold"
                    onClick={() => { retryFetchingCallLogs(); }}
                  >
                    RETRY
                  </Button>
                </Col>
              )
            }
            {
              fetchingPrevCallLogsStatus === 'LOADING' && (
                <Col
                  xs={24}
                  className="text-primary text-center"
                >
                  <Row className="py-2 justify-content-center d-flex">
                    <Spinner
                      variant="primary"
                      animation="border"
                    />
                  </Row>
                  <Row className="py-2 justify-content-center">
                    Fetching previous logs
                  </Row>
                </Col>
              )
            }
          </Row>
          {
            (!initiateCall)
              && (
              <Row>
                <Col
                  xs={24}
                  className="p-2 fs-3 text-center text-medium"
                >
                  Please press &#8217;CALL&#8217; button to initiate exotel call
                </Col>
                <Col
                  xs={24}
                  className="p-2 justify-content-center d-flex"
                >
                  <Button
                    variant="primary"
                    className="mx-2"
                    onClick={() => {
                      toggleInitiateCall(true);
                    }}
                  >
                    CALL
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="mx-2"
                    onClick={() => toggleExotelCallModal()}
                  >
                    CANCEL
                  </Button>
                </Col>
              </Row>
              )
          }
          <Row>
            {exotelCallDetailsFunction()}
          </Row>
        </Container>
      )}
      size="lg"
    />
  );
};

ExotelCall.propTypes = {
  show: PropTypes.bool.isRequired,
  toggleExotelCallModal: PropTypes.func.isRequired,
  orderDetails: PropTypes.shape({
    orderId: PropTypes.number.isRequired,
    customer: PropTypes.shape({
      phoneNumber: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }),
    store: PropTypes.shape({
      phoneNumber: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }),
  }).isRequired,
  initiateCallTo: PropTypes.string.isRequired,
};

export default ExotelCall;
