import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import { dateString } from '../../../../utilities/Utils';

function OtherLoveLocalBanner(props) {
  const { banner, onClickEdit } = props;
  return (
    <>
      <Row>
        <Col
          xs={24}
        >
          <Row>
            <Col>
              <span>
                <b>
                  Launch Date -
                </b>
                &nbsp;
                {banner.launchDate ? dateString(banner.launchDate) : 'No Launch Date'}
              </span>
              &nbsp;&nbsp;
              <span>
                <b>
                  Expiry Date -
                </b>
                &nbsp;
                {dateString(banner.expiryDate)}
              </span>
            </Col>
            <Col
              xs="auto"
            >
              <Button
                variant="outline-primary"
                className="py-0 px-2 border-radius-8"
                onClick={() => {
                  onClickEdit(
                    'OTHER_LL_BANNER',
                    {
                      title: banner.title,
                      bannerType: banner.bannerType,
                      brandImage: banner.image,
                      brandWebImage: banner.webImage,
                      appDeepLink: banner.appDeepLink,
                      webDeepLink: banner.webDeepLink,
                      priorityOrder: banner.priorityOrder,
                      // launchDate: new Date(banner.launchDate).toISOString().split('T')[0],
                      expiryDate: new Date(banner.expiryDate).toISOString().split('T')[0],
                      bannersType: 'OTHER_LL_BANNER',
                      bannerId: banner.bannerId,
                      iosImage: banner.iosImage,
                      iosDeepLink: banner.iosDeepLink,
                    },
                  );
                }}
              >
                <b>
                  EDIT
                </b>
              </Button>
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          className="pt-1"
        >
          <div>
            <b>
              Title -
            </b>
            <span>
              &nbsp;
              {banner.title}
            </span>
          </div>
          <div>
            {banner.text}
          </div>
        </Col>
      </Row>
      <Row
        className="border-bottom-1 pb-2"
      >
        <Col
          className="text-medium"
        >
          <b>
            Other LoveLocal Banner Details
          </b>
        </Col>
        <Col
          xs="auto"
          className="text-medium"
        >
          <b>
            {banner.type}
          </b>
        </Col>
      </Row>
      <Row
        className="pt-2"
      >
        <Col
          xs={8}
          className=""
        >
          <div>
            Android Image
          </div>
          <div>
            <img
              src={banner.image}
              alt={banner.title}
              className="mw-100 mh-100"
            />
          </div>
        </Col>
        <Col
          xs={8}
        >
          <div>
            iOS Image
          </div>
          <div>
            <img
              src={banner.iosImage}
              alt={banner.title}
              className="mw-100 mh-100"
            />
          </div>
        </Col>
        <Col
          xs={8}
        >
          <div>
            Web Image
          </div>
          <div>
            <img
              src={banner.webImage}
              alt={banner.title}
              className="mw-100 mh-100"
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

OtherLoveLocalBanner.propTypes = {
  banner: PropTypes.shape({
    launchDate: PropTypes.string,
    expiryDate: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.string,
    webImage: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    bannerType: PropTypes.string,
    appDeepLink: PropTypes.string,
    webDeepLink: PropTypes.string,
    priorityOrder: PropTypes.string,
    bannerId: PropTypes.string,
    iosImage: PropTypes.string,
    iosDeepLink: PropTypes.string,
  }).isRequired,
  onClickEdit: PropTypes.func.isRequired,
};

export default OtherLoveLocalBanner;
