import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

const Product = ({ product }) => (
  <Col xs={24}>
    <div className="py-2">
      <Row className="mx-0">
        <Col xs={4} className="p-1 text-center product-image-div">
          <img src={product.image === '' ? '/Assets/thumbnail.svg' : product.image} alt="" className="product-image" />
        </Col>
        <Col className="py-1 pl-1 pr-4">
          <div className="pb-1">{product.displayName}</div>
          <div className="pb-1">
            <b>
              <span className="text-success mr-4">{`\u20B9 ${product.sellingPrice}`}</span>
              <span className={`text-secondary${product.mrp === -1 || product.sellingPrice === product.mrp ? ' d-none' : ''}`}>
                <del>{`\u20B9 ${product.mrp}`}</del>
              </span>
            </b>
          </div>
        </Col>
        <Col xs="auto" className="px-0 pt-3">
          <div>
            &times;&nbsp;
            <span className="border border-primary text-primary py-1 px-2 rounded"><b>{product.quantity}</b></span>
          </div>
        </Col>
      </Row>
    </div>
  </Col>
);

Product.propTypes = {
  product: PropTypes.shape({
    image: PropTypes.string,
    displayName: PropTypes.string,
    sellingPrice: PropTypes.number,
    mrp: PropTypes.number,
    quantity: PropTypes.number,
  }).isRequired,
};

export default Product;
