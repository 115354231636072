import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Row, Spinner, Col, Container,
} from 'react-bootstrap';
import { CustomModal, CustomTable, Svg } from '../../component/common';
import { getBDEViewVisitsLogs, getPromoterBrandingLogs } from '../../assets/api/axios';
import { Constant } from '../../utilities';
import CreateActivity from './CreateActivity';
import { dateString, getDateString } from '../../utilities/Utils';

function PromoterBrandingActivity(props) {
  const {
    showPromoterActModal, showViewVisitsModal, onClose, storeId, isTabType,
  } = props;
  const [activityLogs, setActivityLogs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [singleActivity, setSingleActivity] = useState(null);
  const [isViewing, setIsViewing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [activityType, setActivityType] = useState(false);

  const prepareForLoading = () => {
    setLoading(true);
    setError(false);
  };

  const loadActivityLogs = () => {
    prepareForLoading();
    if (isTabType === 'promoter') {
      getPromoterBrandingLogs(
        'GET',
        '',
        null,
        {
          storeId,
          limit: 100,
          offset: 0,
        },
      ).then((res) => {
        setLoading(false);
        setActivityLogs(res.data.data);
      }).catch(() => {
        setLoading(false);
      });
    } else if (isTabType === 'bdeVisit') {
      getBDEViewVisitsLogs(
        'GET',
        '',
        null,
        {
          storeId,
          limit: 100,
          offset: 0,
        },
      ).then((res) => {
        setLoading(false);
        setActivityLogs(res.data.data);
      }).catch(() => {
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (!activityLogs) {
      loadActivityLogs();
    }
  }, []);

  const onCloseSingleView = () => {
    setIsCreating(false);
    setIsEditing(false);
    setIsViewing(false);
    setSingleActivity(null);
  };

  let bodyjsx = null;


  
  const headerType = () => {
    let headers;
    if (isTabType === 'promoter') {
      headers = [
        {
          key: 'activityDate',
          displayText: 'Performed On',
          renderer: (log) => getDateString(log.activityDate),
        },
        {
          key: 'addedBy',
          displayText: 'Added By',
        },
        {
          key: 'activityType',
          displayText: 'Activity Type',
        },
        {
          key: 'lastModifiedOn',
          displayText: 'Last Modified On',
          renderer: (log) => dateString(log.lastModifiedOn),
        },
        {
          key: 'details',
          displayText: 'View Details',
          renderer: (log) => (
            <Button
              variant="link"
              onClick={() => {
                setIsViewing(true);
                setSingleActivity(log);
              }}
              className="fs-01"
            >
              VIEW
            </Button>
          ),
        },
        {
          key: 'edit',
          displayText: 'Edit',
          renderer: (log) => (
            <Button
              variant="link"
              onClick={() => {
                setIsEditing(true);
                setSingleActivity(log);
              }}
              className="fs-01"
            >
              EDIT
            </Button>
          ),
        },
      ];
    } else if (isTabType === 'bdeVisit') {
      headers = [
        {
          key: 'bdeName',
          displayText: 'BDE name',
        },
        {
          key: 'bdeCode',
          displayText: 'BDE code',
        },
        {
          key: 'visitTimeStamp',
          displayText: 'Visit timestamp',
          renderer: (log) => dateString(log.visitTimeStamp),
        },
        {
          key: 'timeTakenInVisit',
          displayText: 'Time taken in visit',
        },
        {
          key: 'revisitTimeStamp',
          displayText: 'Revisit date',
          renderer: (log) => dateString(log.revisitTimeStamp),
        },
        {
          key: 'visitOutcome',
          displayText: 'Visit Outcome',
        },
        {
          key: 'details',
          displayText: 'View Details',
          renderer: (log) => (
            <Button
              variant="link"
              onClick={() => {
                setIsViewing(true);
                setSingleActivity(log);
              }}
              className="fs-01"
            >
              VIEW
            </Button>
          ),
        },
        {
          key: 'edit',
          displayText: 'Edit',
          renderer: (log) => (
            <Button
              variant="link"
              onClick={() => {
                setIsEditing(true);
                setSingleActivity(log);
              }}
              className="fs-01"
            >
              EDIT
            </Button>
          ),
        },
      ];
    }
    return headers;
  };

  const headers = headerType();

  if (error) {
    bodyjsx = (
      <div
        className="pt-4 text-center"
      >
        <span
          className="text-danger"
        >
          Oops! Something Went Wrong.
        </span>
        <div className="pt-2">
          <Button
            variant="primary"
            onClick={() => loadActivityLogs()}
          >
            Retry
          </Button>
        </div>
      </div>
    );
  }

  if (loading) {
    bodyjsx = (
      <div
        className="pt-3 text-center"
      >
        <Spinner
          animation="border"
          variant="primary"
        />
      </div>
    );
  }

  if (activityLogs && !isCreating && !isEditing && !isViewing) {
    bodyjsx = (
      <div>
        {isTabType === 'promoter' && (
          <div>
            <div className="d-flex">
              <Button
                variant="outline-primary m-2"
                onClick={() => {
                  setIsCreating(true);
                  setActivityType('PROMOTION');
                }}
              >
                Add Promoter Activity
              </Button>
              <Button
                variant="outline-primary m-2"
                onClick={() => {
                  setIsCreating(true);
                  setActivityType('BRANDING');
                }}
              >
                Add Branding Activity
              </Button>
            </div>
            <CustomTable
              headers={headers}
              content={activityLogs.results}
              keyField="id"
              isPaginated={false}
              totalItems={activityLogs.count}
            />
          </div>
        )}
        {isTabType === 'bdeVisit' && (
        <>
          <div className="d-flex">
            <Button
              variant="outline-primary m-2"
              onClick={() => {
                setIsCreating(true);
                setActivityType('BDEVISIT');
              }}
            >
              Add BDE Visits
            </Button>
          </div>
          <CustomTable
            headers={headers}
            content={activityLogs.results}
            keyField="id"
            isPaginated={false}
            totalItems={activityLogs.count}
          />
        </>
        )}
      </div>
    );
  }

  if (singleActivity && isViewing) {
    bodyjsx = (
      <>
        {isTabType === 'promoter' && (
        <Container className="p-2">
          <Row className="py-1">
            <Col xs={6} className="text-right">Activity Date :</Col>
            <Col xs={18} className="text-center font-weight-bold">{getDateString(singleActivity.activityDate)}</Col>
          </Row>
          {singleActivity.activityType === 'Promotion' ? (
            <Row className="py-1">
              <Col xs={6} className="text-right">Promoter Name :</Col>
              <Col xs={18} className="text-center font-weight-bold">{singleActivity.promoterName}</Col>
            </Row>
          ) : (
            <Row className="py-1">
              <Col xs={6} className="text-right">Branding Type :</Col>
              <Col xs={18} className="text-center font-weight-bold">{singleActivity.brandingType.reduce((acc, type) => `${acc} ${type},`, '')}</Col>
            </Row>
          )}
          <Row className="py-1">
            <Col xs={6} className="text-right">Last Modified On :</Col>
            <Col xs={18} className="text-center font-weight-bold">{dateString(singleActivity.lastModifiedOn)}</Col>
          </Row>
          <Row className="py-1">
            <Col xs={6} className="text-right">Added By :</Col>
            <Col xs={18} className="text-center font-weight-bold">{singleActivity.addedBy}</Col>
          </Row>
          <Row className="py-1">
            <Col xs={6} className="text-right">Activity Type :</Col>
            <Col xs={18} className="text-center font-weight-bold">{singleActivity.activityType}</Col>
          </Row>
          <Row className="py-1">
            <Col xs={6} className="text-right">Images :</Col>
            <Col xs={18} className="text-center font-weight-bold">
              {singleActivity.images.map((img, index) => (
                <div key={index}>
                  <img src={img} alt="Activity Img" width={400} height={150} className="p-2 " />
                </div>
              ))}
            </Col>
          </Row>
        </Container>
        )}

        {isTabType === 'bdeVisit' && (
        <Container className="p-2">
          <Row className="py-1">
            <Col xs={6} className="text-right">BDE Name :</Col>
            <Col xs={18} className="text-left font-weight-bold">{singleActivity.bdeName}</Col>
          </Row>
          <Row className="py-1">
            <Col xs={6} className="text-right">BDE Code :</Col>
            <Col xs={18} className="text-left font-weight-bold">{singleActivity.bdeCode}</Col>
          </Row>
          <Row className="py-1">
            <Col xs={6} className="text-right">Visit Timestamp :</Col>
            <Col xs={18} className="text-left font-weight-bold">{dateString(singleActivity.visitTimeStamp)}</Col>
          </Row>
          <Row className="py-1">
            <Col xs={6} className="text-right">Time Taken In Visit :</Col>
            <Col xs={18} className="text-left font-weight-bold">{singleActivity.timeTakenInVisit}</Col>
          </Row>
          <Row className="py-1">
            <Col xs={6} className="text-right">Feedback :</Col>
            <Col xs={18} className="text-left font-weight-bold break-word">{singleActivity.feedback}</Col>
          </Row>
          <Row className="py-1">
            <Col xs={6} className="text-right">Revisit Date :</Col>
            <Col xs={18} className="text-left font-weight-bold">{dateString(singleActivity.revisitTimeStamp)}</Col>
          </Row>
          <Row className="py-1">
            <Col xs={6} className="text-right">Revisit Remarks :</Col>
            <Col xs={18} className="text-left font-weight-bold">{singleActivity.revisitRemarks}</Col>
          </Row>
          <Row className="py-1">
            <Col xs={6} className="text-right">Visit Outcome :</Col>
            <Col xs={18} className="text-left font-weight-bold">{singleActivity.visitOutcome}</Col>
          </Row>
        </Container>
        )}
      </>
    );
  }

  if (isEditing) {
    bodyjsx = (
      <CreateActivity
        singleActivity={singleActivity}
        onCloseSingleView={onCloseSingleView}
        onCreateSuccess={loadActivityLogs}
        storeId={storeId}
        activityType={activityType}
        isModalType={isTabType === 'promoter' ? 'promoter' : 'BDEvisit'}
        isEditing={isEditing}
      />
    );
  }

  if (isCreating) {
    bodyjsx = (
      <CreateActivity
        singleActivity={null}
        onCloseSingleView={onCloseSingleView}
        onCreateSuccess={loadActivityLogs}
        storeId={storeId}
        activityType={activityType}
        isModalType={isTabType === 'promoter' ? 'promoter' : 'BDEvisit'}
      />
    );
  }

  const getTitle = () => {
    switch (true) {
      case isViewing:
        if (isTabType === 'promoter') {
          return 'Activity Log';
        } if (isTabType === 'bdeVisit') {
          return 'BDE Visits Log';
        }
        break;
      case isEditing:
        if (isTabType === 'promoter') {
          return 'Editing Activity Log';
        } if (isTabType === 'bdeVisit') {
          return 'Editing BDE Visits Log';
        }
        break;
      case isCreating:
        if (isTabType === 'promoter') {
          return 'Creating Activity Log';
        } if (isTabType === 'bdeVisit') {
          return 'Creating BDE Visits Log';
        }
        break;
      default:
        if (isTabType === 'promoter') {
          return 'Activity Logs';
        } if (isTabType === 'bdeVisit') {
          return 'BDE Visits Log';
        }
    }
  };

  return (
    <CustomModal
      size="lg"
      show={isTabType === 'promoter' ? showPromoterActModal : showViewVisitsModal}
      onHide={onClose}
      closeButton
      body={(
        <div>
          <div className="d-flex align-items-center p-2 border-bottom">
            {
              (isCreating
              || isEditing
              || isViewing) && (
                <Button
                  variant="link"
                  onClick={onCloseSingleView}
                >
                  <Svg
                    svg="arrow"
                    width="1.3rem"
                    fill={Constant.Color.PRIMARY}
                  />
                </Button>
              )
            }
            <b>{getTitle()}</b>
            <Button className="ml-auto font-weight-bold" variant="link" onClick={onClose}>
              X
            </Button>
          </div>
          <div>{bodyjsx}</div>
        </div>
      )}
    />
  );
}

PromoterBrandingActivity.propTypes = {
  onClose: PropTypes.func.isRequired,
  showPromoterActModal: PropTypes.bool.isRequired,
  storeId: PropTypes.string.isRequired,
  showViewVisitsModal: PropTypes.bool.isRequired,
  isTabType: PropTypes.string.isRequired,
};

export default PromoterBrandingActivity;