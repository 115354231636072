import React, { Component } from 'react';
import {
  Container, Row, Col, Button, Spinner, InputGroup, Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import { financeSettlementDashboard } from '../../assets/api/axios';
import { CustomTable, DatePicker, Svg } from '../../component/common';
import permission from '../../access&permissions/permission';
import { Constant } from '../../utilities';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l,
    p,
    startDatePayment,
    endDatePayment,
    startDateSettlement,
    endDateSettlement,
  } = param;
  const { q = '' } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  startDatePayment = Number(startDatePayment) || new Date().getTime() - (1 * 24 * 60 * 60 * 1000);
  startDatePayment = new Date(startDatePayment).setHours(0, 0, 0, 0);
  endDatePayment = Number(endDatePayment) || new Date().setHours(23, 59, 59, 999);
  startDateSettlement = (
    Number(startDateSettlement) || new Date().getTime() - (1 * 24 * 60 * 60 * 1000)
  );
  startDateSettlement = new Date(startDateSettlement).setHours(0, 0, 0, 0);
  endDateSettlement = Number(endDateSettlement) || new Date().setHours(23, 59, 59, 999);
  return ({
    ...param,
    q,
    l,
    p,
    startDatePayment,
    endDatePayment,
    startDateSettlement,
    endDateSettlement,
  });
};

class FinanceDashboard extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    const { userPermission } = this.props;
    this.canEdit = userPermission.includes(permission.BRAND_SAMPLING_CAMPAIGN_WRITE);
    this.state = {
      hasNext: false,
      hasPrevious: false,
      rowsPerPage: param.l,
      page: param.p,
      loading: true,
      error: false,
      errorMsg: '',
      settlements: null,
      storeId: param.q,
      payStDate: param.startDatePayment,
      payEndDate: param.endDatePayment,
      settledStDate: param.startDateSettlement,
      endSettledDate: param.endDateSettlement,
      param,
      newParam: param,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleOnChange = (e) => {
    this.setState({
      storeId: e.target.value,
    });
  }

  onSubmitSearchText = () => {
    const {
      storeId, param,
    } = this.state;
    if (storeId !== param.q) {
      this.handleRequestProcessing({ q: storeId, p: 1 });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const {
      param, payEndDate, payStDate,
      settledStDate, endSettledDate,
    } = this.state;
    const newParam = {
      ...param,
      ...data,
      startDatePayment: payStDate,
      endDatePayment: payEndDate,
      startDateSettlement: settledStDate,
      endDateSettlement: endSettledDate,
    };
    const {
      l,
      p,
      q,
      startDatePayment,
      endDatePayment,
      startDateSettlement,
      endDateSettlement,
    } = newParam;
    const offset = (p);
    financeSettlementDashboard(
      'GET',
      null,
      {
        page: `${offset}`,
        limit: l,
        startDatePayment,
        endDatePayment,
        startDateSettlement,
        endDateSettlement,
        storeId: q,
      },
      this.source.token,
    ).then((res) => {
      this.setState({
        settlements: res.data,
        loading: false,
        hasNext: !!res.data.next,
        hasPrevious: !!res.data.prev,
        rowsPerPage: l,
        page: p,
        storeId: q,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch((err) => {
      let errorMsg = '';
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.errors
        && err.response.data.errors[0]
      ) {
        errorMsg = err.response.data.errors[0].message;
      }
      this.setState({
        error: true,
        loading: false,
        errorMsg,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
        errorMsg: '',
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handlePaymentDateChange = (startDate, endDate) => {
    this.setState({
      payStDate: startDate,
      payEndDate: endDate,
    });
    // this.applyFilters();
  }

  handleSettledDateChange = (startDate, endDate) => {
    this.setState({
      settledStDate: startDate,
      endSettledDate: endDate,
    });
    // this.applyFilters();
  }

  applyFilters = () => {
    const { newParam, storeId } = this.state;
    this.handleRequestProcessing({
      ...newParam,
      q: storeId,
      p: 1,
    });
  }

  render() {
    const {
      param, loading, error, settlements,
      rowsPerPage, page, hasNext, hasPrevious,
      payEndDate, payStDate, settledStDate, endSettledDate,
      storeId, errorMsg,
    } = this.state;

    const headers = [
      {
        key: 'storeId',
        displayText: 'EP Code',
        renderer: (data) => (
          <a
            href={`/retailer/${data.storeId}`}
            target="_blank"
            rel="noreferrer"
            className={(data.total !== data.settled) ? 'text-danger' : 'text-primary'}
          >
            {data.storeId}
          </a>
        ),
      },
      {
        key: 'total',
        displayText: 'Total',
        renderer: (data) => (
          <a
            href={`/retailer/${data.storeId}?view=order&startDate=${payStDate}&endDate=${payEndDate}`}
            target="_blank"
            rel="noreferrer"
            className={(data.total !== data.settled) ? 'text-danger' : 'text-primary'}
          >
            {data.total}
          </a>
        ),
      },
      {
        key: 'digitalPayment',
        displayText: 'Digital',
      },
      {
        key: 'couponDiscount',
        displayText: 'LL Coupon',
      },
      {
        key: 'settled',
        displayText: 'Settled',
        renderer: (data) => (
          <a
            href={`/reconciled-settlements/?storeIds=${data.storeId}&createdOnFrom=${settledStDate}&createdOnTo=${endSettledDate}`}
            target="_blank"
            rel="noreferrer"
            className={(data.total !== data.settled) ? 'text-danger' : 'text-primary'}
          >
            {data.settled}
          </a>
        ),
      },
    ];

    const settlementAggregateData = (settlements) ? [
      {
        id: 1,
        name: 'Total',
        value: settlements.totalPaidAmount,
      },
      {
        id: 2,
        name: 'Digital',
        value: settlements.totalDigitalPaidAmount,
      },
      {
        id: 3,
        name: 'LL Coupon',
        value: settlements.totalCouponDiscount,
      },
      {
        id: 4,
        name: 'Settled',
        value: settlements.totalSettledAmount,
      },
    ] : [];

    return (
      <Container
        fluid
        className="h-100 bg-white table-list"
      >
        <Row
          className="py-2"
        >
          <Col
            xs={24}
          >
            <Row
              className="pb-3"
            >
              <Col
                xs={24}
                sm="auto"
                className="px-2 py-1"
              >
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className="rounded-0"
                    >
                      <Svg
                        svg="search"
                        width="1rem"
                        fill={Constant.Color.DARK}
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    className="fs-01 rounded-0"
                    value={storeId}
                    onChange={this.handleOnChange}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        this.onSubmitSearchText();
                      }
                    }}
                  />
                </InputGroup>
              </Col>
              <Col
                xs="auto"
                className="d-flex align-items-center"
              >
                <div
                  className="fs-1 font-weight-bold"
                >
                  Payment:
                  &nbsp;
                </div>
                <div>
                  <DatePicker
                    isDateRange
                    onApply={(dateRange) => {
                      this.handlePaymentDateChange(
                        new Date(dateRange.startDate).getTime(),
                        new Date(dateRange.endDate).getTime(),
                      );
                    }}
                    timePicker
                    startDate={payStDate}
                    endDate={payEndDate}
                    maxDate={new Date().setHours(23, 59, 59, 999)}
                    onClear={() => {
                      this.setState({
                        payStDate: '',
                        payEndDate: '',
                      });
                    }}
                  />
                </div>
              </Col>

              <Col
                xs="auto"
                className="d-flex align-items-center"
              >
                <div
                  className="fs-1 font-weight-bold"
                >
                  Settlement:
                  &nbsp;
                </div>
                <div>
                  <DatePicker
                    isDateRange
                    onApply={(dateRange) => {
                      this.handleSettledDateChange(
                        new Date(dateRange.startDate).getTime(),
                        new Date(dateRange.endDate).getTime(),
                      );
                    }}
                    timePicker
                    startDate={settledStDate}
                    endDate={endSettledDate}
                    maxDate={new Date().setHours(23, 59, 59, 999)}
                    onClear={() => {
                      this.setState({
                        settledStDate: '',
                        endSettledDate: '',
                      });
                    }}
                  />
                </div>
              </Col>

              <Col
                xs="auto"
                className="d-flex align-items-center"
              >
                <Button
                  variant="primary"
                  className="py-1 fs-1 font-weight-bold"
                  onClick={() => {
                    this.applyFilters();
                  }}
                >
                  APPLY FILTERS
                </Button>
              </Col>
            </Row>
            <Row
              className="align-items-center justify-content-center"
            >
              {!!settlementAggregateData.length && (
                settlementAggregateData.map((item) => (
                  <Col
                    key={item.id}
                    xs="auto"
                    className="fs-01 mx-1 p-2 border border-disabled"
                  >
                    <div>
                      {item.name}
                      :
                      &nbsp;
                      ₹
                      {item.value}
                    </div>
                  </Col>
                ))
              )}
            </Row>
          </Col>
        </Row>
        <Row
          className="justify-content-center"
        >
          {
            (loading || error) && (
              <div
                className="pt-3"
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                ) : (
                  <>
                    <span
                      className="text-danger"
                    >
                      {errorMsg || 'Something Went Wrong...!'}
                    </span>
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => this.retry()}
                      >
                        Retry
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )
          }
        </Row>
        <Row className="bg-white">
          {
            !!settlements && !loading && !error && (
              <Col
                xs={24}
                className="px-0 pt-2 table-section"
              >
                <CustomTable
                  headers={headers}
                  content={settlements.data}
                  keyField="storeId"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={settlements.count}
                  hasPrev={hasPrevious}
                  hasNext={hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            )
          }
        </Row>
      </Container>
    );
  }
}

FinanceDashboard.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default FinanceDashboard;
