import React from 'react';
import QueryString from 'query-string';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import BogoOffer from '../offer/BogoOffer';
import FreeGift from '../offer/FreeGift';
import ValueOffer from '../offer/ValueOffer';

class SpecialOffer extends React.Component {
  constructor(props) {
    super(props);
    const { history } = this.props;
    const { offerType } = QueryString.parse(history.location.search);
    this.state = {
      offerType: offerType || 'bogo',
    };
  }

  render() {
    const {
      offerType,
    } = this.state;
    const { retailerDetails, history } = this.props;

    let offersShowCase = null;
    switch (offerType) {
      default:
      case 'bogo':
        offersShowCase = (
          <Col
            xs={24}
          >
            <BogoOffer
              {...this.props}
            />
          </Col>
        );
        break;

      case 'freeGift':
        offersShowCase = (
          <Col
            xs={24}
          >
            <FreeGift
              {...this.props}
            />
          </Col>
        );
        break;

      case 'value':
        offersShowCase = (
          <Col
            xs={24}
          >
            <ValueOffer
              {...this.props}
            />
          </Col>
        );
        break;
    }

    return (
      <Container
        fluid
        className="bg-white"
      >
        <Row>
          <Col
            xs={8}
            className="p-0"
          >
            <Button
              variant="link"
              block
              className={`rounded-0 border py-2 ${
                offerType === 'bogo' ? 'border-primary' : 'border-secondary text-dark'
              }`}
              onClick={() => {
                this.setState({
                  offerType: 'bogo',
                }, () => {
                  history.replace(`/retailer/${retailerDetails.code}/?view=specialOffer&offerType=bogo`);
                });
              }}
            >
              <b>Buy 1 Get 1</b>
            </Button>
          </Col>
          <Col
            xs={8}
            className="p-0"
          >
            <Button
              variant="link"
              block
              className={`rounded-0 border py-2 ${
                offerType === 'freeGift' ? 'border-primary' : 'border-secondary text-dark'
              }`}
              onClick={() => {
                this.setState({
                  offerType: 'freeGift',
                }, () => {
                  history.replace(`/retailer/${retailerDetails.code}/?view=specialOffer&offerType=freeGift`);
                });
              }}
            >
              <b>Free Gift</b>
            </Button>
          </Col>
          <Col
            xs={8}
            className="p-0"
          >
            <Button
              variant="link"
              block
              className={`rounded-0 border py-2 ${
                offerType === 'value' ? 'border-primary' : 'border-secondary text-dark'
              }`}
              onClick={() => {
                this.setState({
                  offerType: 'value',
                }, () => {
                  history.replace(`/retailer/${retailerDetails.code}/?view=specialOffer&offerType=value`);
                });
              }}
            >
              <b>Value Offer</b>
            </Button>
          </Col>
        </Row>
        <Row>
          {offersShowCase}
        </Row>
      </Container>
    );
  }
}

SpecialOffer.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    replace: PropTypes.func,
  }).isRequired,
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
};

export default SpecialOffer;
