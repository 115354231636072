import React, { useRef } from 'react';
import 'react-h5-audio-player/lib/styles.css';
import '../../assets/scss/OrderManagment/OrderDetails.scss';
import AudioPlayer from 'react-h5-audio-player';
import Svg from './Svg';

const CustomAudioPlayer = ({ audioSrc }) => {
  const audioRef = useRef(null);

  const handleEnded = () => {
    if (audioRef.current) {
      audioRef.current.audio.current.currentTime = 0;
      audioRef.current.audio.current.pause();
    }
  };

  return (
    <div className="audio-player-container">
      <AudioPlayer
        ref={audioRef}
        autoPlay={false}
        src={audioSrc}
        onEnded={handleEnded}
        customIcons={{
          pause: <Svg svg="audioPauseIcon" width={16} />,
          play: <Svg svg="audioPlayIcon" width={16} />,
        }}
        customAdditionalControls={[]}
        customVolumeControls={[]}
      />
    </div>
  );
};

export default CustomAudioPlayer;
