import React, { Component } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import { couponTracker } from '../../assets/api/axios';
import '../../assets/scss/Coupons/CouponTracker.scss';
import { CustomTable } from '../../component/common';
import { dateString } from '../../utilities/Utils';
import { Utils } from '../../utilities';

class CouponTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponCode: '',
      storeId: '',
      phoneNumber1: '',
      phoneNumber2: '',
      isSubmitting: false,
      isError: false,
      errorMsg: '',
      coupons: null,
      view1: true,
      page: 1,
      rowsPerPage: 6,
      param: {
        l: 2,
        p: 1,
      },
    };
    this.source = Axios.CancelToken.source();
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleLoad = (newParams = {}) => {
    const {
      couponCode, storeId, phoneNumber1, phoneNumber2, param, view1,
    } = this.state;
    const { l = 6, p = 1 } = { ...param, ...newParams };
    const offset = (p - 1) * l;
    this.setState({
      isSubmitting: true,
      isError: false,
      coupons: null,
    });
    const params = view1 ? {
      storeCode: storeId,
      phoneNumber: phoneNumber1,
    } : {
      phoneNumber: phoneNumber2,
    };
    couponTracker(
      'GET',
      this.source.token,
      view1 ? couponCode : '',
      {
        ...params,
        offset: `${offset}`,
        limit: l,
      },
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          isSubmitting: false,
          coupons: res.data,
          param: { ...param, ...newParams },
          rowsPerPage: l,
          page: p,
        });
      } else {
        throw new Error(res);
      }
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong!! Please Try Again!!';
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.server_response
      ) {
        errorMsg = err.response.data.server_response;
      }
      this.setState({
        errorMsg,
        isSubmitting: false,
        isError: true,
      });
    });
  }

  onNext = () => {
    const { param } = this.state;
    this.handleLoad({ ...param, p: param.p + 1 });
  }

  onPrev = () => {
    const { param } = this.state;
    this.handleLoad({ ...param, p: param.p - 1 });
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleLoad({ ...param, l: rowsPerPage });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleLoad({ ...param, p: page });
    }
  }

  render() {
    const {
      couponCode, storeId, phoneNumber1, isSubmitting, isError, coupons,
      view1, phoneNumber2, param, rowsPerPage, page, errorMsg,
    } = this.state;
    const { history } = this.props;

    const headers = [
      {
        key: 'couponId',
        displayText: 'Coupon Id',
        renderer: (data) => (
          <Button
            variant="link"
            onClick={() => {
              const query = QueryString.stringify(
                Utils.deleteEmptyField(param),
              );
              history.push(`/coupon/${data.couponId}${
                query ? `?${query}` : ''}`);
            }}
            className="fs-01 p-0"
          >
            {data.couponId}
          </Button>
        ),
      },
      {
        key: 'couponCode',
        displayText: 'Coupon Code',
      },
      {
        key: 'campaignId',
        displayText: 'Campaign Id',
      },
      {
        key: 'canApply',
        displayText: 'Can Apply',
        renderer: (data) => (
          <div
            className={data.canApply ? 'text-green' : 'text-danger'}
          >
            {data.canApply ? 'YES' : 'NO'}
          </div>
        ),
      },
      {
        key: 'errorMsg',
        displayText: 'Reason',
        renderer: (data) => (
          <span>
            { data.errorMsg || 'NA' }
          </span>
        ),
      },
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => dateString(data.createdOn),
      },
      {
        key: 'validFrom',
        displayText: 'Valid From',
        renderer: (data) => dateString(data.validFrom),
      },
      {
        key: 'validTill',
        displayText: 'Valid Till',
        renderer: (data) => dateString(data.validTill),
      },
      {
        key: 'maxDiscount',
        displayText: 'Max. Discount',
      },
      {
        key: 'minSpendAmount',
        displayText: 'Min. Spend Amt.',
      },
      {
        key: 'numberOfUsage',
        displayText: 'Overall Usage Limit',
      },
      {
        key: 'paymentMode',
        displayText: 'Payment Modes',
        renderer: (data) => {
          const modes = (data.paymentMode).map((item) => item.name);
          return modes.join(', ');
        },
      },
    ];

    return (
      <Container
        fluid
        className="bg-white"
      >
        <Row>
          <Col
            xs={24}
            sm={12}
            md={7}
            className="d-flex pt-3"
          >
            <div>
              <b>
                <span className="text-danger">
                  *&nbsp;
                </span>
                Coupon Code:
              </b>
            </div>
            <div
              className="pl-3 w-75"
            >
              <input
                type="text"
                className="form-control"
                name="couponCode"
                placeholder="Coupon Code (Required*)"
                value={couponCode}
                onChange={this.handleChange}
              />
            </div>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={8}
            className="d-flex pt-3"
          >
            <div>
              <b>
                <span className="text-danger">
                  *&nbsp;
                </span>
                Customer Phone Number:
              </b>
            </div>
            <div
              className="pl-3 w-75"
            >
              <input
                type="text"
                maxLength={10}
                className="form-control"
                name="phoneNumber1"
                placeholder="Phone Number (Required*)"
                value={phoneNumber1}
                onChange={this.handleChange}
              />
            </div>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={6}
            className="d-flex pt-3"
          >
            <div>
              <b>Store Id:</b>
            </div>
            <div
              className="pl-3 w-75"
            >
              <input
                type="text"
                className="form-control"
                name="storeId"
                placeholder="Store Id (Optional)"
                value={storeId}
                onChange={this.handleChange}
              />
            </div>
          </Col>
          <Col
            xs="auto"
            className="d-flex justify-content-center pt-3"
          >
            <Button
              variant="primary"
              onClick={() => {
                this.setState({
                  view1: true,
                }, () => { this.handleLoad({ p: 1, l: 6 }); });
              }}
              disabled={
                isSubmitting
                || (!couponCode || !phoneNumber1)
              }
            >
              Submit
            </Button>
          </Col>
        </Row>
        <Row className="minh-100p border-bottom my-4">
          {
            view1 && isSubmitting && (
              <Col
                className="p-3 mx-auto text-center"
                xs={24}
              >
                <Spinner
                  variant="primary"
                  animation="border"
                />
              </Col>
            )
          }
          {
            view1 && isError && (
              <Col
                className="p-3 mx-auto text-center text-danger"
                xs={24}
              >
                <b>
                  {errorMsg}
                </b>
              </Col>
            )
          }
          {
            (!view1 || (!coupons && !isError && !isSubmitting)) && (
              <Col
                className="p-3 text-secondary"
                xs={24}
              >
                Search result with above search terms will appear here.
              </Col>
            )
          }
          {
            coupons && view1 && (
              <Col
                xs={24}
                className="bg-pink"
              >
                <CustomTable
                  isPaginated
                  headers={headers}
                  content={coupons.results}
                  keyField="couponId"
                  rowsPerPage={rowsPerPage}
                  page={page}
                  l={param.l}
                  p={param.p}
                  totalItems={coupons.count}
                  hasPrev={coupons.hasPrev}
                  hasNext={coupons.hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                  pagiationPostion="position-sticky"
                />
              </Col>
            )
          }
        </Row>


        <Row>
          <Col
            xs={24}
            sm={12}
            md={9}
            className="d-flex pt-4"
          >
            <div>
              <b>
                <span className="text-danger">
                  *&nbsp;
                </span>
                Customer Phone Number:
              </b>
            </div>
            <div
              className="pl-3 w-75"
            >
              <input
                type="text"
                maxLength={10}
                className="form-control"
                name="phoneNumber2"
                placeholder="Phone Number (Required*)"
                value={phoneNumber2}
                onChange={this.handleChange}
              />
            </div>
          </Col>
          <Col
            xs="auto"
            className="d-flex justify-content-center pt-4"
          >
            <Button
              variant="primary"
              onClick={() => {
                this.setState({
                  view1: false,
                }, () => { this.handleLoad({ p: 1, l: 6 }); });
              }}
              disabled={
                isSubmitting
                || (!phoneNumber2)
              }
            >
              Submit
            </Button>
          </Col>
        </Row>
        <Row className="minh-100p my-4">
          {
            !view1 && isSubmitting && (
              <Col
                className="p-3 mx-auto text-center"
                xs={24}
              >
                <Spinner
                  variant="primary"
                  animation="border"
                />
              </Col>
            )
          }
          {
            !view1 && isError && (
              <Col
                className="p-3 mx-auto text-center text-danger"
                xs={24}
              >
                <b>
                  {errorMsg}
                </b>
              </Col>
            )
          }
          {
            (view1 || (!coupons && !isError && !isSubmitting)) && (
              <Col
                className="p-3 text-secondary"
                xs={24}
              >
                Search result with above search terms will appear here.
              </Col>
            )
          }
          {
            !view1 && coupons && (
              <Col
                xs={24}
                className="bg-pink"
              >
                <CustomTable
                  isPaginated
                  headers={headers}
                  content={coupons.results}
                  keyField="couponId"
                  rowsPerPage={rowsPerPage}
                  page={page}
                  l={param.l}
                  p={param.p}
                  totalItems={coupons.count}
                  hasPrev={coupons.hasPrev}
                  hasNext={coupons.hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                  pagiationPostion="position-sticky"
                />
              </Col>
            )
          }
        </Row>
      </Container>
    );
  }
}

CouponTracker.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default CouponTracker;
