import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width, fill }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 11.8 11.8"
    xmlSpace="preserve"
    style={{ width }}
  >
    <path
      fill={fill}
      id="Retry"
      d="M5.9,0C4.3,0,2.8,0.6,1.7,1.7L0.6,0.6v3.5h3.5L2.6,2.6c1.8-1.8,4.8-1.8,6.6,0c1.8,1.8,1.8,4.8,0,6.6
   c-1.8,1.8-4.8,1.8-6.6,0C1.7,8.3,1.2,7.1,1.2,5.9H0c0,3.3,2.6,5.9,5.9,5.9c3.3,0,5.9-2.6,5.9-5.9C11.8,2.6,9.1,0,5.9,0
   C5.9,0,5.9,0,5.9,0z"
    />
  </svg>
);

Svg.propTypes = {
  width: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
};

export default Svg;
