import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal } from '../../component/common';
import { addStoreOnCampaign } from '../../assets/api/axios';

function RemoveStore(props) {
  const {
    show, onHide, id, handleRequestProcessing,
  } = props;
  const [removeStatus, setRemoveStatus] = useState({
    loading: false,
    error: '',
  });
  const { loading, error } = removeStatus;

  const handleRemoveStatus = () => {
    setRemoveStatus({
      loading: true,
      error: '',
    });
    addStoreOnCampaign(
      'DELETE',
      {
        id,
      },
    ).then((res) => {
      if (res.status === 200) {
        setRemoveStatus({
          loading: false,
          error: '',
        });
        onHide();
        handleRequestProcessing();
      }
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong';
      if (
        err
        && err.response
        && err.response.status === 400
        && err.response.data
        && err.response.data.errors
        && err.response.data.errors[0].message
      ) {
        errorMsg = err.response.data.errors[0].message;
      }
      setRemoveStatus({
        loading: false,
        error: errorMsg,
      });
    });
  };

  const body = (
    <Container className="px-5 py-4">
      <Row className="justify-content-center">
        <b>Are you sure you want to remove this store?</b>
      </Row>
      <Row className="justify-content-center py-2">
        <Button
          onClick={onHide}
          className="mx-1"
        >
          NO
        </Button>
        <Button
          variant="danger"
          className="mx-1"
          onClick={handleRemoveStatus}
        >
          YES
        </Button>
      </Row>
      {(() => {
        let showcase = null;
        if (loading) {
          showcase = (
            <Row className="justify-content-center py-1">
              <Spinner
                animation="border"
                variant="primary"
              />
            </Row>
          );
        } else if (!loading && !!error) {
          showcase = (
            <Row className="justify-content-center py-1">
              <span className="text-danger fs-1">
                {error}
              </span>
            </Row>
          );
        }
        return showcase;
      })()}
    </Container>
  );

  return (
    <CustomModal
      show={show}
      onHide={onHide}
      title="Remove Store"
      body={body}
      autoSize
      closeButton
    />
  );
}

RemoveStore.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  handleRequestProcessing: PropTypes.func.isRequired,
};

export default RemoveStore;
