import React, { useState } from 'react';
import PropTypes, { bool } from 'prop-types';
import {
  Form, Button, Container, Row, Col,
} from 'react-bootstrap';
import { reListStore } from '../../assets/api/axios';

const question = {
  'Store Image': 'storeImage',
  'Bank Account Details': 'bankAccountDetails',
  'Is the store marked as an outlier?': 'storeMarkedAsAnOutlier',
  "Does the owner's name match the bank account document holder's name?": 'ownerNameMatchedBankAccDoc',
  'Is the Secondary mobile number updated?': 'isSecondaryNumberUpdated',
  'Does the category match with the catalog-added product?': 'categoryMatchWithCatalogAddedProducts',
  'Has the retailer confirmed by picking up the call?': 'isRetailerConfirmedByCall',
  'Store Relisted': 'isStoreRelisted',
};

const RelistStoreForm = ({ storeId, onClose }) => {
  const [formData, setFormData] = useState({
    storeId,
    ...Object.fromEntries(Object.keys(question).map((key) => [question[key], ''])),
    comment: '',
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const onChangeFieldValue = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const emptyFields = Object.values(formData).filter((value) => value === '').length;
    if (emptyFields > 0) {
      setError('Please select an option for all fields');
      return;
    }
    const payload = { formData };
    reListStore('POST', payload.formData)
      .then(() => {
        setError('');
        setSuccess(true);
        setTimeout(() => {
          onClose(true);
        }, 2000);
        setFormData((prevData) => ({
          ...prevData,
          ...Object.fromEntries(Object.keys(question).map((key) => [question[key], ''])),
          comment: '',
        }));
      })
      .catch(() => {
        setError('Oops something went wrong');
      });
  };

  const options = ['Yes', 'No'];

  return (
    <Container>
      <Row className="justify-content-center p-4">
        <Col xs={24}>
          <Form onSubmit={handleFormSubmit}>
            {Object.entries(question).map(([questionText, key], index, array) => (
              <Form.Group key={key}>
                <Form.Label>{questionText}</Form.Label>
                <Form.Control
                  as="select"
                  name={key}
                  value={formData[key]}
                  onChange={onChangeFieldValue}
                >
                  <option value="">Select</option>
                  {options.map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </Form.Control>
                {index === array.length - 1 && (
                  <Form.Control
                    as="textarea"
                    name="comment"
                    placeholder="Add comments"
                    value={formData.comment}
                    onChange={onChangeFieldValue}
                    style={{ marginTop: '50px' }}
                  />
                )}
              </Form.Group>
            ))}
            <Row>
              <Col className="justify-content-center d-flex pt-4 pb-0" md={24}>
                <Button variant="primary" type="submit">Relist Store</Button>
              </Col>
            </Row>
            {success && (
              <Row>
                <Col className="text-success text-center pt-3" md={24}>
                  Form submitted successfully!
                </Col>
              </Row>
            )}
          </Form>
        </Col>
      </Row>
      <Row className="text-center pb-3">
        <Col>
          {error && <div className="text-danger">{error}</div>}
        </Col>
      </Row>
    </Container>
  );
};

RelistStoreForm.propTypes = {
  storeId: PropTypes.string.isRequired,
  onClose: PropTypes.bool.isRequired,
};

export default RelistStoreForm;
