import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width, stroke }) => (
  <svg
    width={width}
    height={width}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={stroke}
      d="M11 9V5H9v4H5v2h4v4h2v-4h4V9h-4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20z"
    />
  </svg>
);

Svg.propTypes = {
  width: PropTypes.number.isRequired,
  stroke: PropTypes.string.isRequired,
};

export default Svg;
