import React, { Component } from 'react';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import { marketplaceBrandProducts } from '../../assets/api/axios';
import { CustomSwitch, CustomTable, Svg } from '../../component/common';
import permission from '../../access&permissions/permission';
import { Constant } from '../../utilities';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    ...param,
    l,
    p,
  });
};

class BrandsProducts extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    const { userPermission } = this.props;
    this.canEdit = userPermission.includes(permission.BRAND_SAMPLING_CAMPAIGN_WRITE);
    this.state = {
      hasNext: false,
      hasPrevious: false,
      rowsPerPage: param.l,
      page: param.p,
      loading: true,
      error: false,
      products: null,
      param,
      patchItem: null,
      patchLoader: false,
      patchError: false,
    };
    this.source = Axios.CancelToken.source();
    this.persistedPatchItem = null;
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  onSubmitSearchText = () => {
    const {
      searchText, param,
    } = this.state;
    if (searchText !== param.searchText) {
      this.handleRequestProcessing({ searchText, p: 1 });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleLoad = (data = {}) => {
    const { history, match } = this.props;
    const { pathname } = history.location;
    const { brandId } = match.params;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p,
    } = newParam;
    const offset = (p);
    marketplaceBrandProducts(
      'GET',
      '',
      null,
      {
        brand_id: brandId,
        page: `${offset}`,
        limit: l,
      },
      this.source.token,
    ).then((res) => {
      this.setState({
        products: res.data,
        loading: false,
        hasNext: !!res.data.next,
        hasPrevious: !!res.data.prev,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  patchProductField = () => {
    const { patchItem, products } = this.state;
    const finalPatchItem = patchItem || this.persistedPatchItem;

    marketplaceBrandProducts(
      'PATCH',
      finalPatchItem.id,
      {
        [finalPatchItem.field]: finalPatchItem.value,
      },
      {},
    )
      .then(() => {
        this.persistedPatchItem = null;
        this.setState({
          patchItem: null,
          patchLoader: false,
          patchError: false,
          products: {
            ...products,
            data: products.data.map((product) => (
              product.id === finalPatchItem.id
                ? {
                  ...product,
                  [finalPatchItem.field]: finalPatchItem.value,
                }
                : product
            )),
          },
        });
      })
      .catch(() => {
        this.setState({
          patchLoader: false,
          patchError: true,
        });
      });
  }

  handleFieldUpdate = (continueAnyway = false) => {
    this.setState({
      patchLoader: true,
      patchError: false,
    }, () => {
      this.patchProductField(continueAnyway);
    });
  }

  render() {
    const {
      param, loading, error, products,
      rowsPerPage, page, hasNext, hasPrevious,
      patchItem, patchError, patchLoader,
    } = this.state;

    const getSwitch = (field, data, status) => (
      <div
        className="py-1"
      >
        <CustomSwitch
          id={`${data.id}${field}`}
          displayTextLeft=""
          displayTextRight=""
          onChange={(e) => {
            this.setState({
              patchItem: {
                field,
                id: data.id,
                value: e.target.checked,
              },
            }, () => {
              this.patchProductField();
            });
          }}
          checked={status}
        />
      </div>
    );

    const getTextEditShowCase = (data, field) => {
      if (patchItem && (patchItem.id === data.id)
      && patchItem.field === field) {
        let isValid = false;
        if (patchItem.value) {
          if (field === 'retailPrice' && (
            patchItem.value > data.wholeSalePrice)) {
            isValid = true;
          }
          if (field === 'wholeSalePrice' && (
            patchItem.value < data.retailPrice)) {
            isValid = true;
          }
          if (field === 'minimumOrderQuantity' && (
            patchItem.value !== 0
          )) {
            isValid = true;
          }
          if (
            field === 'name'
          ) {
            isValid = true;
          }
        }
        if (patchLoader) {
          return (
            <Spinner
              animation="border"
              variant="primary"
              size="sm"
            />
          );
        }
        if (patchError) {
          return (
            <>
              <Button
                className="fs-01 rounded-0 mr-2"
                size="sm"
                onClick={this.handleFieldUpdate}
              >
                <Svg
                  svg="retry"
                  width="0.8rem"
                  fill={Constant.Color.WHITE}
                />
              </Button>
              <Button
                variant="secondary"
                className="fs-01 rounded-0"
                size="sm"
                onClick={() => {
                  this.setState({
                    patchItem: null,
                    patchLoader: false,
                    patchError: false,
                  });
                }}
              >
                <Svg
                  svg="arrow"
                  width="0.8rem"
                  fill={Constant.Color.BLACK}
                  transform="rotate(-90deg)"
                />
              </Button>
            </>
          );
        }
        return (
          <input
            type="text"
            value={patchItem.value}
            size={patchItem.value.toString().length || 1}
            onChange={(e) => {
              if (field === 'name') {
                this.setState({
                  patchItem: {
                    id: data.id,
                    field,
                    value: e.target.value || '',
                  },
                });
              } else {
                const { value } = e.target;
                const numVal = Number(value);
                if (value === '' || (numVal && numVal > 0)) {
                  this.setState({
                    patchItem: {
                      id: data.id,
                      field,
                      value: numVal || '',
                    },
                  });
                }
              }
            }}
            onKeyPress={(e) => {
              if (e.which === 13 && isValid
                && patchItem.value !== data[field]) {
                this.handleFieldUpdate();
              }
            }}
            onBlur={() => {
              this.setState({
                patchItem: null,
              });
            }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            className={`fs-01 text-medium ${isValid
              ? '' : 'border-danger'}`}
          />
        );
      }
      return (
        <Button
          variant="link"
          className="fs-01 text-medium p-0"
          onClick={() => {
            this.setState({
              patchItem: {
                id: data.id,
                field,
                value: data[field],
              },
            });
          }}
        >
          {data[field] || '--'}
        </Button>
      );
    };

    const headers = [
      {
        key: 'id',
        displayText: 'Product Id',
      },
      {
        key: 'imageUrl',
        displayText: 'Img',
        renderer: (data) => (
          <div
            style={{
              width: 40,
              height: 40,
            }}
            className="d-flex align-items-center justify-content-center"
          >
            <img
              src={data.imageUrl}
              alt={data.name}
              className="mw-100 mh-100"
            />
          </div>
        ),
      },
      {
        key: 'name',
        displayText: 'Name',
        renderer: (data) => getTextEditShowCase(data, 'name'),
      },
      {
        key: 'brandName',
        displayText: 'Brand Name',
      },
      {
        key: 'minimumOrderQuantity',
        displayText: 'Min Order Quantity',
        renderer: (data) => getTextEditShowCase(data, 'minimumOrderQuantity'),
      },
      {
        key: 'inStock',
        displayText: 'InStock',
        renderer: (data) => (getSwitch('inStock', data, data.inStock)),
      },
      {
        key: 'retailPrice',
        displayText: 'Retail Price',
        renderer: (data) => getTextEditShowCase(data, 'retailPrice'),
      },
      {
        key: 'wholeSalePrice',
        displayText: 'Whole Sale Price',
        renderer: (data) => getTextEditShowCase(data, 'wholeSalePrice'),
      },
      {
        key: 'measurementUnit',
        displayText: 'Unit',
        renderer: (data) => (
          <span>
            {data.measurementValue}
            &nbsp;
            {data.measurementUnit}
          </span>
        ),
      },
    ];

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        <Row
          className="h-100 flex-column"
        >
          {
            (loading || error) && (
              <div
                className="pt-3 text-center"
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                ) : (
                  <>
                    <span
                      className="text-danger"
                    >
                      Something Went Wrong
                    </span>
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => this.retry()}
                      >
                        Retry
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )
          }
          {
            !!products && !loading && !error && (
              <Col
                className="px-0 flex-grow-1 overflow-y pt-2"
              >
                <CustomTable
                  headers={headers}
                  content={products.data}
                  keyField="id"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={products.count}
                  hasPrev={hasPrevious}
                  hasNext={hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            )
          }
        </Row>
      </Container>
    );
  }
}

BrandsProducts.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brandId: PropTypes.string,
    }),
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default BrandsProducts;
