import { useState } from 'react';
import * as Storage from './Storage';

export const shortDateOptions = { year: 'numeric', month: 'short', day: '2-digit' };

export const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };

export const dateTimeOptions = {
  year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true,
};

export function timeIn12HrFormat(time) {
  const parts = time.split(':');
  const hours = Number(parts[0]);
  let t = hours > 12 ? (hours - 12) : hours || 12;
  t = t < 10 ? `0${t}` : `${t}`;
  return hours < 12 ? `${t}:${parts[1]} AM` : `${t}:${parts[1]} PM`;
}

export function secondToTimeFormat(seconds) {
  const noOfSeconds = Math.floor(seconds);
  const noOfMinutes = Math.floor(noOfSeconds / 60);
  const noOfHrs = Math.floor(noOfMinutes / 60);

  const showHrs = noOfHrs > 9 ? noOfHrs : `0${noOfHrs}`;

  const showMin1 = noOfMinutes % 60;
  const showMin = showMin1 > 9 ? showMin1 : `0${showMin1}`;

  const showSec1 = noOfSeconds % 60;
  const showSec = showSec1 > 9 ? showSec1 : `0${showSec1}`;
  return (`${showHrs}:${showMin}:${showSec}`);
}

export function dateString(timeStamp) {
  const date = new Date(timeStamp);
  if (date.getTime()) {
    const list = date.toString().split(' ');
    return `${list[2]} ${list[1]} ${list[3]} ${timeIn12HrFormat(list[4])}`;
  }
  return null;
}
export function getDateTime(timestamp) {
  const date = new Date(timestamp);
  if (date.getTime()) {
    const parts = date.toLocaleString().split(' ');
    return `${parts[0].split(',')[0]} ${timeIn12HrFormat(parts[1])}`;
  }
  return null;
}

export function getFormDateFormat(timestamp) {
  if (timestamp) {
    const date = new Date(timestamp).toLocaleDateString();
    const dateStrings = date.split('/');
    return `${dateStrings[2]}-${dateStrings[1]}-${dateStrings[0]}`;
  }
  return '';
}

export function getHoursDiff(t1, t2) {
  let hrs1;
  let hrs2;
  try {
    hrs1 = new Date(t1).getHours();
    hrs2 = new Date(t2).getHours();
  } catch (err) {
    return 100000;
  }
  return hrs2 - hrs1;
}

export function isAuthenticated() {
  const {
    getUserName, getUserSession, getToken,
    getUserPermissions, getUser,
  } = Storage;
  return !!getToken() && !!getUserSession()
  && !!getUserName() && !!getUserPermissions() && !!getUser();
}

export function deleteEmptyField(data) {
  const fields = { ...data };
  Object.keys(fields).forEach((field) => {
    if (!fields[field]) {
      delete fields[field];
    }
  });
  return fields;
}

export const copyToClipboard = (text) => {
  const element = document.createElement('input');
  document.body.append(element);
  element.setAttribute('value', text);
  element.select();
  document.execCommand('copy');
  element.remove();
};

export const unitsMap = {
  GMs: 'g',
  G: 'g',
  KGs: 'kg',
  ML: 'ml',
  L: 'l',
  LTRs: 'l',
  CMS: 'cm',
  Inch: 'in',
  Mtrs: 'm',
  Feet: 'ft',
  Units: 'item',
  packet: 'pkt',
  bottle: 'btl',
  carton: 'crtn',
  canister: 'cnstr',
  '': '',
};

export function dataURLtoFile(dataUrl, filename) {
  const arr = dataUrl.split(',');
  const byteString = atob(arr[1]);
  const intArray = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    intArray[i] = byteString.charCodeAt(i);
  }
  return new File([intArray], filename);
}

export const stickersInfo = {
  positive_great_service_of_shop: {
    title: 'POSITIVE_FEEDBACK',
    text: 'Great Service of Shop',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Service_positive.png',
    textColor: 'BLACK',
    isNegativeFeedback: false,
  },
  positive_great_quality_of_products: {
    title: 'POSITIVE_FEEDBACK',
    text: 'Great Quality of Products',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Quality_positive.png',
    textColor: 'BLACK',
    isNegativeFeedback: false,
  },
  positive_super_fast_delivery: {
    title: 'POSITIVE_FEEDBACK',
    text: 'Superfast Delivery',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Delivery_positive.png',
    textColor: 'BLACK',
    isNegativeFeedback: false,
  },
  positive_super_fast_order_processing: {
    title: 'POSITIVE_FEEDBACK',
    text: 'Superfast Order Processing',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Processing_positive.png',
    textColor: 'BLACK',
    isNegativeFeedback: false,
  },
  positive_exciting_offers: {
    title: 'POSITIVE_FEEDBACK',
    text: 'Exciting Offers',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Offers_positive.png',
    textColor: 'BLACK',
    isNegativeFeedback: false,
  },
  positive_wide_variety_of_products: {
    title: 'POSITIVE_FEEDBACK',
    text: 'Wide Variety of Products',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Variety_positive.png',
    textColor: 'BLACK',
    isNegativeFeedback: false,
  },
  positive_great_prices_of_products: {
    title: 'POSITIVE_FEEDBACK',
    text: 'Great Prices of Products',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Pricing_positive.png',
    textColor: 'BLACK',
    isNegativeFeedback: false,
  },
  negative_bad_service_of_shop: {
    title: 'NEGATIVE_FEEDBACK',
    text: 'Bad Service of Shop',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Service_negative.png',
    textColor: 'RED',
    isNegativeFeedback: true,
  },
  negative_poor_quality_of_products: {
    title: 'NEGATIVE_FEEDBACK',
    text: 'Poor Quality of Products',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Quality_negative.png',
    textColor: 'RED',
    isNegativeFeedback: true,
  },
  negative_slow_order_processing_speed: {
    title: 'NEGATIVE_FEEDBACK',
    text: 'Slow Order Processing Speed',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Processing_negative.png',
    textColor: 'RED',
    isNegativeFeedback: true,
  },
  negative_no_availability_of_offers: {
    title: 'NEGATIVE_FEEDBACK',
    text: 'No Availability of Offers',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Offers_negative.png',
    textColor: 'RED',
    isNegativeFeedback: true,
  },
  negative_low_variety_of_products: {
    title: 'NEGATIVE_FEEDBACK',
    text: 'Low Variety of Products',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Variety_negative.png',
    textColor: 'RED',
    isNegativeFeedback: true,
  },
  negative_high_prices_of_products: {
    title: 'NEGATIVE_FEEDBACK',
    text: 'High Prices of Products',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Pricing_negative.png',
    textColor: 'RED',
    isNegativeFeedback: true,
  },
  negative_slow_order_delivery_speed: {
    title: 'NEGATIVE_FEEDBACK',
    text: 'Slow Order Delivery Speed',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Delivery_negative.png',
    textColor: 'RED',
    isNegativeFeedback: true,
  },
};

export const languages = {
  ALL: 'All',
  hi: 'Hindi',
  en: 'English',
  mr: 'Marathi',
  gu: 'Gujarati',
  kn: 'Kannada',
  te: 'Telugu',
};

export const primaryTagRange = [
  {
    color: 'bg-green-lighter',
    range: [90, 100],
  },
  {
    color: 'bg-warning-lighter',
    range: [75, 90],
  },
  {
    color: 'bg-danger-lighter',
    range: [0, 75],
  },
];

export function shopTags(storeMatrix, storeCreatedOn, shopRating) {
  const shoptags = {
    primaryTag: { color: ['', ''] },
    homeDeliveryTag: {
      shortHand: 'HD', fullForm: 'Home Delivery', matrics: storeMatrix.homeDeliverySuccessRate, color: ['', ''],
    },
    digitalPaymentTag: {
      shortHand: 'DIG', fullForm: 'Digital Payment Rate', matrics: storeMatrix.digitalOrderSuccessRate, color: ['', ''],
    },
    firstTimeShopperTag: {
      shortHand: '1st', fullForm: 'First Order', matrics: storeMatrix.firstTimeShopperRate, color: ['', ''],
    },
    slaAdherenceRateTag: {
      shortHand: 'SLA', fullForm: 'SLA Adherence Rate', matrics: storeMatrix.slaAdherenceRate, color: ['', ''],
    },
    shopRatingTag: {
      shortHand: 'RATE', fullForm: 'Rating', matrics: shopRating, color: ['', ''],
    },
  };

  switch (true) {
    case (new Date(storeCreatedOn) > (new Date() - 30 * 86400000)):
      shoptags.primaryTag.color[0] = 'bg-white';
      break;
    case (storeMatrix.orderFulfillmentRate >= 90):
      shoptags.primaryTag.color[0] = 'bg-green-lighter';
      break;
    case (storeMatrix.orderFulfillmentRate >= 75 && storeMatrix.orderFulfillmentRate < 90):
      shoptags.primaryTag.color[0] = 'bg-warning-lighter';
      break;
    case (storeMatrix.orderFulfillmentRate < 75):
      shoptags.primaryTag.color[0] = 'bg-danger-lighter';
      break;
    default:
      break;
  }

  switch (true) {
    case (storeMatrix.homeDeliverySuccessRate >= 85):
      shoptags.homeDeliveryTag.color[0] = 'bg-green'; shoptags.homeDeliveryTag.color[1] = 'text-green';
      break;
    case (storeMatrix.homeDeliverySuccessRate >= 70 && storeMatrix.homeDeliverySuccessRate < 85):
      shoptags.homeDeliveryTag.color[0] = 'bg-warning'; shoptags.homeDeliveryTag.color[1] = 'text-black';
      break;
    case (storeMatrix.homeDeliverySuccessRate < 70):
      shoptags.homeDeliveryTag.color[0] = 'bg-danger'; shoptags.homeDeliveryTag.color[1] = 'text-danger';
      break;
    default:
      break;
  }

  switch (true) {
    case (storeMatrix.digitalOrderSuccessRate >= 85):
      shoptags.digitalPaymentTag.color[0] = 'bg-green'; shoptags.digitalPaymentTag.color[1] = 'text-green';
      break;
    case (storeMatrix.digitalOrderSuccessRate >= 70 && storeMatrix.digitalOrderSuccessRate < 85):
      shoptags.digitalPaymentTag.color[0] = 'bg-warning'; shoptags.digitalPaymentTag.color[1] = 'text-black';
      break;
    case (storeMatrix.digitalOrderSuccessRate < 70):
      shoptags.digitalPaymentTag.color[0] = 'bg-danger'; shoptags.digitalPaymentTag.color[1] = 'text-danger';
      break;
    default:
      break;
  }

  switch (true) {
    case (storeMatrix.firstTimeShopperRate >= 85):
      shoptags.firstTimeShopperTag.color[0] = 'bg-green'; shoptags.firstTimeShopperTag.color[1] = 'text-green';
      break;
    case (storeMatrix.firstTimeShopperRate >= 70 && storeMatrix.firstTimeShopperRate < 85):
      shoptags.firstTimeShopperTag.color[0] = 'bg-warning'; shoptags.firstTimeShopperTag.color[1] = 'text-black';
      break;
    case (storeMatrix.firstTimeShopperRate < 70):
      shoptags.firstTimeShopperTag.color[0] = 'bg-danger'; shoptags.firstTimeShopperTag.color[1] = 'text-danger';
      break;
    default:
      break;
  }

  switch (true) {
    case (storeMatrix.slaAdherenceRate >= 60):
      shoptags.slaAdherenceRateTag.color[0] = 'bg-green'; shoptags.slaAdherenceRateTag.color[1] = 'text-green';
      break;
    case (storeMatrix.slaAdherenceRate >= 45 && storeMatrix.slaAdherenceRate < 60):
      shoptags.slaAdherenceRateTag.color[0] = 'bg-warning'; shoptags.slaAdherenceRateTag.color[1] = 'text-black';
      break;
    case (storeMatrix.slaAdherenceRate < 45):
      shoptags.slaAdherenceRateTag.color[0] = 'bg-danger'; shoptags.slaAdherenceRateTag.color[1] = 'text-danger';
      break;
    default:
      break;
  }

  switch (true) {
    case (shopRating >= 4):
      shoptags.shopRatingTag.color[0] = 'bg-green'; shoptags.shopRatingTag.color[1] = 'text-green';
      break;
    case (shopRating >= 3.5 && shopRating < 4):
      shoptags.shopRatingTag.color[0] = 'bg-warning'; shoptags.shopRatingTag.color[1] = 'text-black';
      break;
    case (shopRating < 3.5):
      shoptags.shopRatingTag.color[0] = 'bg-danger'; shoptags.shopRatingTag.color[1] = 'text-danger';
      break;
    default:
      break;
  }

  return shoptags;
}

export const retailerProfileMetaData = {
  YEAR_OF_ESTABLISHMENT: {
    prefilledText: 'YYYY',
    question: 'In which year did you establish your business?',
  },
  STORY: {
    prefilledText: 'My brother and I started this business in 1980 after I completed my 12th and my brother completed 11th. We couldn\'t study further due to my family\'s financial situation. Initially we started with small cart selling Fruits & Vegetables and today we are running a general store in Mumbai.',
    question: 'Story behind establishing your shop/business?',
  },
  IMPORTANCE: {
    prefilledText: 'Due to competition consumers look for discounts and better service. They explore reviews and rating of any service before making any purchase plus getting delivery at home on a fingertip saves their time. Having online store give us visibility on daily performance and also tips to grow our business',
    question: 'Why is it important to take you business online?',
  },
  GOALS: {
    prefilledText: 'Our mission is to serve our customers with all their daily requirements at better price. Being a local shop helps us connect with our customer well and understand their needs. We hope to grow our store to a departmental store in future.',
    question: 'Your future goals for your shop/ business?',
  },
  PROFILE_PHOTO: {
    prefilledText: '',
    question: 'Upload your photo showcasing products in the background',
  },
  SHOP_IMAGES: {
    prefilledText: '',
    question: 'Upload inside shop images showcasing products for your customers to identify your product range',
  },
  SHOP_VIDEOS: {
    prefilledText: '',
    question: 'Upload inside shop videos showcasing products for your customers to identify your product range',
  },
};

export const callStatusOptions = [
  {
    label: 'Non-Connect',
    value: 'Non_Connect',
  },
  {
    label: 'Callback',
    value: 'Callback',
  },
  {
    label: 'Document Pending',
    value: 'Document_Pending',
  },
  {
    label: 'Lost',
    value: 'Lost',
  },
  {
    label: 'Converted',
    value: 'Converted',
  },
];

export const getDay = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const getOrderTagStringFormat = (item) => {
  switch (item) {
    case 'PENDING':
      return 'Pending';
    case 'DELIVERED':
      return 'Delivered';
    case 'MODIFIED':
      return 'Modified';
    case 'OUT_FOR_DELIVERY':
      return 'Out For Delivery';
    case 'READY_TO_SHIP':
      return 'Ready To Ship';
    case 'CHANGES_ACCEPTED':
      return 'CHANGES_ACCEPTED';
    case 'CHANGES_REJECTED':
      return 'CHANGES_REJECTED';
    case 'CANCELLED':
      return 'Cancelled';
    case 'CASH':
      return 'Cash';
    case 'DIGITAL':
      return 'Digital';
    case 'PAID':
      return 'Paid';
    case 'UNALTERED':
      return 'Unaltered';
    case 'PRICE_ALTERED':
      return 'Price Altered';
    case 'QUANTITY_ALTERED':
      return 'Quantity Altered';
    case 'NEWLY_ADDED':
      return 'Newly Added';
    case 'PRODUCT_REPLACED':
      return 'Product Replaced';
    case 'MARKED_UNSERVICEABLE':
      return 'Marked Unserviceable';
    default:
      break;
  }
  return null;
};

export const getInitials = (text) => {
  const initials = text.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

export function getDateString(timeStamp) {
  const date = new Date(timeStamp);
  if (date.getTime()) {
    const list = date.toString().split(' ');
    return `${list[2]} ${list[1]} ${list[3]}`;
  }
  return null;
}

export const getDateRange = (days) => {
  const currentDateString = new Date();
  const time = currentDateString.getTime();
  const date = currentDateString.getDate();
  const day = currentDateString.getDay();
  switch (days) {
    case 'TODAY':
      return {
        startDate: time,
        endDate: time,
      };
    case 'THIS_WEEK':
      return {
        startDate: time - (day * 24 * 60 * 60 * 1000),
        endDate: time,
      };
    case 'THIS_MONTH':
      return {
        startDate: time - ((date - 1) * 24 * 60 * 60 * 1000),
        endDate: time,
      };
    default:
      break;
  }
  return {};
};

export const indianStatesAndTerritories = [
  {
    id: 'AN',
    displayName: 'Andaman and Nicobar Islands',
  },
  {
    id: 'AP',
    displayName: 'Andhra Pradesh',
  },
  {
    id: 'AR',
    displayName: 'Arunachal Pradesh',
  },
  {
    id: 'AS',
    displayName: 'Assam',
  },
  {
    id: 'BR',
    displayName: 'Bihar',
  },
  {
    id: 'CG',
    displayName: 'Chandigarh',
  },
  {
    id: 'CH',
    displayName: 'Chhattisgarh',
  },
  {
    id: 'DH',
    displayName: 'Dadra and Nagar Haveli',
  },
  {
    id: 'DD',
    displayName: 'Daman and Diu',
  },
  {
    id: 'DL',
    displayName: 'Delhi',
  },
  {
    id: 'GA',
    displayName: 'Goa',
  },
  {
    id: 'GJ',
    displayName: 'Gujarat',
  },
  {
    id: 'HR',
    displayName: 'Haryana',
  },
  {
    id: 'HP',
    displayName: 'Himachal Pradesh',
  },
  {
    id: 'JK',
    displayName: 'Jammu and Kashmir',
  },
  {
    id: 'JH',
    displayName: 'Jharkhand',
  },
  {
    id: 'KA',
    displayName: 'Karnataka',
  },
  {
    id: 'KL',
    displayName: 'Kerala',
  },
  {
    id: 'LD',
    displayName: 'Lakshadweep',
  },
  {
    id: 'MP',
    displayName: 'Madhya Pradesh',
  },
  {
    id: 'MH',
    displayName: 'Maharashtra',
  },
  {
    id: 'MN',
    displayName: 'Manipur',
  },
  {
    id: 'ML',
    displayName: 'Meghalaya',
  },
  {
    id: 'MZ',
    displayName: 'Mizoram',
  },
  {
    id: 'NL',
    displayName: 'Nagaland',
  },
  {
    id: 'OR',
    displayName: 'Odisha',
  },
  {
    id: 'PY',
    displayName: 'Puducherry',
  },
  {
    id: 'PB',
    displayName: 'Punjab',
  },
  {
    id: 'RJ',
    displayName: 'Rajasthan',
  },
  {
    id: 'SK',
    displayName: 'Sikkim',
  },
  {
    id: 'TN',
    displayName: 'Tamil Nadu',
  },
  {
    id: 'TS',
    displayName: 'Telangana',
  },
  {
    id: 'TR',
    displayName: 'Tripura',
  },
  {
    id: 'UK',
    displayName: 'Uttar Pradesh',
  },
  {
    id: 'UP',
    displayName: 'Uttarakhand',
  },
  {
    id: 'WB',
    displayName: 'West Bengal',
  },
];

export const getKycDocumentTypes = (kycType) => {
  switch (kycType) {
    case 'personalKyc': return ([
      {
        displayName: 'Aadhar Card',
        id: 'UDYOG_AADHAR_CERTIFICATE',
      },
      {
        displayName: 'Driving License',
        id: 'DRIVING_LICENCE',
      },
      {
        displayName: 'Voter Id Card',
        id: 'VOTER_ID',
      },
      {
        displayName: 'Pan Card',
        id: 'PAN_CARD',
      },
    ]);
    case 'businessKyc': return ([
      {
        displayName: 'Udyog Aadhar Certificate',
        id: 'UDYOG_AADHAR_CERTIFICATE',
      },
      {
        displayName: 'Shop Est. Gumsta License',
        id: 'SHOP_ESTABLISHMENT_GUMASTA_LICENSE',
      },
      {
        displayName: 'GST Certificate',
        id: 'GST_CERTIFICATE',
      },
      {
        displayName: 'Fssai Certificate',
        id: 'FSSAI_CERTIFICATE',
      },
      {
        displayName: 'Udyam Certificate',
        id: 'UDYAM_AADHAR_CERTIFICATE',
      },
    ]);
    default: return [];
  }
};

export const useStateWithCallback = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const setValueAndCallback = (newValue, callback) => {
    setValue((prevValue) => {
      if (callback) {
        callback(prevValue, newValue);
      }
      return newValue;
    });
  };

  return [value, setValueAndCallback];
};

export const getSOAErrorText = (error) => {
  let errorMsg = '';
  if (
    error
      && error.response
      && error.response.data
      && error.response.data.errors
      && error.response.data.errors[0].message
  ) {
    errorMsg = error.response.data.errors[0].message;
  }
  return errorMsg;
};

export const getMonolithErrorText = (error) => {
  let errorMsg = 'Oops Something went wrong!';
  if (
    error
      && error.response
      && error.response.data
      && error.response.data[0]
      && error.response.data[0].client_message
  ) {
    errorMsg = error.response.data[0].client_message;
  }
  return errorMsg;
};

export function getDefaultImageUrl() {
  return 'https://onlinedeliveryproducts.s3.amazonaws.com/product.png';
}
