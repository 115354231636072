import React, { useState } from 'react';
import {
  Container, Col, Row, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { orderComments, storecomments } from '../../../assets/api/axios';
import { CustomModal } from '../../../component/common';
import { getUserName } from '../../../utilities/Storage';

function AddComments(props) {
  const {
    showCommentsModal, toggleCommentsModal, onSuccess,
    orderId, storeId,
  } = props;
  const [comment, setComment] = useState('');
  const [addingCommentStatus, setAddingCommentStatus] = useState('');

  const addStoreComments = () => {
    storecomments(
      'POST',
      storeId,
      null, null,
      {
        comment,
        username: getUserName(),
      },
    ).then(() => {
      onSuccess('store');
      setAddingCommentStatus('');
    }).catch(() => {
      setAddingCommentStatus('error');
    });
  };

  const addOrderComments = () => {
    orderComments(
      'POST',
      orderId,
      {},
      {
        comment,
        username: getUserName(),
      },
    ).then(() => {
      onSuccess('order');
      setAddingCommentStatus('');
    }).catch(() => {
      setAddingCommentStatus('error');
    });
  };

  const addComment = () => {
    if (showCommentsModal === 'store') {
      addStoreComments();
    } else {
      addOrderComments();
    }
  };

  return (
    <CustomModal
      show
      autoSize
      onHide={() => toggleCommentsModal('')}
      body={(
        <Container className="p-4">
          <Row>
            <Col xs={24} className="py-2">Comment:</Col>
            <Col xs={24} className="py-2">
              <input
                type="text"
                name="comment"
                maxLength={1000}
                className="input-box"
                onChange={(e) => { setComment(e.target.value); }}
              />
              {
                addingCommentStatus === 'error' && (
                  <div className="text-danger">*Oops! Something went wrong!</div>
                )
              }
            </Col>
          </Row>
          <Row
            className="mx-0 py-2 d-flex align-items-center flex-row-reverse"
          >
            <Button
              variant="outline-primary"
              className="font-weight-bold"
              onClick={addComment}
              disabled={addingCommentStatus === 'loading'}
            >
              ADD COMMENT
            </Button>
            <Button
              variant="link"
              className="font-weight-bold text-black"
              onClick={() => toggleCommentsModal('')}
            >
              NOT NOW
            </Button>
          </Row>
        </Container>
      )}
    />
  );
}

AddComments.propTypes = {
  orderId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  showCommentsModal: PropTypes.string.isRequired,
  storeId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  toggleCommentsModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddComments;
