import Regex from './Regex';
import ErrorFeedback from './ErrorFeedback';

const validate = (field, value) => {
  switch (field) {
    case 'libraryVariants': {
      const errors = {};
      for (let index = 0; index < value.length; index += 1) {
        const item = value[index];
        if (
          item.mrp === ''
          || !parseFloat(item.mrp)
        ) {
          errors[item.id] = ErrorFeedback.mrp.empty;
        } else if (
          item.sellingPrice === ''
          || !parseFloat(item.sellingPrice)
        ) {
          errors[item.id] = ErrorFeedback.sellingPrice.empty;
        } else if (
          parseFloat(item.mrp) < parseFloat(item.sellingPrice)
        ) {
          errors[item.id] = ErrorFeedback.SPGreaterThanMRP.valid;
        }
      }
      if (Object.keys(errors).length) {
        return errors;
      }
      return null;
    }

    default: return null;
  }
};

export const validateRegex = (field, value) => {
  switch (field) {
    case 'mrp':
    case 'sellingPrice': {
      const regex = new RegExp(`^${Regex.price}$`);
      if (value.toString().match(regex) || value === '') {
        return true;
      }
      return false;
    }
    case 'integerValue': {
      const regex = new RegExp(`^${Regex.integerValue}$`);
      if (value.toString().match(regex) || value === '') {
        return true;
      }
      return false;
    }
    case 'accountNumber': {
      const regex = new RegExp(`^${Regex.number}*$`);
      if (value.toString().match(regex)) {
        return true;
      }
      return false;
    }
    case 'gstNumber':
    case 'ifscCode':
    case 'panNumber': {
      const regex = new RegExp(`^${Regex.alphaNumeric}*$`);
      if (value.toString().match(regex)) {
        return true;
      }
      return false;
    }
    case 'mobileNumber': {
      const regex = new RegExp(Regex.mobileNumber);
      if (value && !regex.test(value)) {
        return true;
      }
      return false;
    }
    case 'versionNumber': {
      const regex = new RegExp(`^${Regex.versionNumber}$`);
      if (value.toString().match(regex) || value === '') {
        return true;
      }
      return false;
    }
    case 'drugLicenseNumber': {
      const regex = Regex.drugLicenseNumber;
      // eslint-disable-next-line no-restricted-syntax
      for (const reg of regex) {
        if (value.match(reg) !== null) {
          return true;
        }
      }
      return false;
    }
    default: return true;
  }
};

export const validateForm = (currentErrors, fieldsObj) => {
  const errors = currentErrors;
  const fields = Object.keys(fieldsObj);
  fields.forEach((field) => {
    const error = validate(field, fieldsObj[field]);
    if (error) {
      errors[field] = error;
    } else {
      delete errors[field];
    }
  });
  return errors;
};
