import React from 'react';
import {
  Container, Button, Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { CustomModal } from '../../../component/common';

function ReconcileModal(props) {
  const {
    toggleReconciledModal, toggleDeliveryStatusConfirmationModal,
  } = props;

  return (
    <CustomModal
      show
      autoSize
      title="Are you sure you want to cancel this order?"
      onHide={() => toggleReconciledModal()}
      body={(
        <Container className="p-3">
          <Row className="px-3 py-2">
            <span>
              The settlement for this order has already been reconciled with the retailer.
              This will impact future settlements for the retailer.
            </span>
          </Row>
          <Col
            xs={24}
            className="d-flex align-items-center justify-content-center mt-4"
          >
            <Button
              variant="danger"
              onClick={() => toggleDeliveryStatusConfirmationModal('CANCELLED')}
              className="mx-2"
            >
              Cancel Order
            </Button>
            <Button
              variant="primary"
              onClick={() => toggleReconciledModal()}
              className="mx-2"
            >
              Go Back
            </Button>
          </Col>
        </Container>
      )}
    />
  );
}

ReconcileModal.propTypes = {
  toggleDeliveryStatusConfirmationModal: PropTypes.func.isRequired,
  toggleReconciledModal: PropTypes.func.isRequired,
};

export default ReconcileModal;
