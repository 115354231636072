import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Spinner, Button,
} from 'react-bootstrap';
import Axios from 'axios';
import { offerClass } from '../../assets/api/axios';
import { CustomTable, ErrorHandler } from '../../component/common';
import Permission from '../../access&permissions/permission';

class OfferClass extends React.Component {
  constructor(props) {
    super();
    const { userPermission } = props;
    this.canIEdit = userPermission
      && userPermission.includes(Permission.RETAILER_WRITE);
    this.state = {
      loading: true,
      error: false,
      submitting: false,
      submittingError: '',
      storeId: props.retailerDetails.code,
      offerClasses: null,
      activeOfferClassIds: [],
      inactiveOfferClassIds: [],
    };
    this.source = Axios.CancelToken.source();
    this.modifiedCount = 0;
  }

  componentDidMount = () => {
    this.loadOfferClasses();
  }

  loadOfferClasses = () => {
    const { storeId } = this.state;
    offerClass(
      'GET',
      storeId,
      null,
      this.source.token,
    ).then((res) => {
      this.modifiedCount = 0;
      const seggregatedIds = res.data.results.reduce((ids, item) => {
        ids[item.enabled ? 0 : 1].push(item.id);
        return ids;
      }, [[], []]);
      this.setState({
        offerClasses: res.data,
        loading: false,
        activeOfferClassIds: seggregatedIds[0],
        inactiveOfferClassIds: seggregatedIds[1],
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });
  }

  alterOfferClasses = () => {
    const { activeOfferClassIds, inactiveOfferClassIds, storeId } = this.state;
    this.setState({
      submitting: true,
      submittingError: false,
    });
    offerClass(
      'PATCH',
      storeId,
      {
        selectedIds: activeOfferClassIds,
        nonSelectedIds: inactiveOfferClassIds,
      },
      null,
    ).then(() => {
      this.setState({
        submitting: false,
        loading: true,
      }, this.loadOfferClasses);
    }).catch((error) => {
      this.setState({
        submittingError: (error && error.response
          && error.response.data && error.response.data.message)
          ? error.response.data.message : 'Oops Something went wrong!!',
        submitting: false,
      }, () => {
        setTimeout(() => {
          this.setState({
            submittingError: '',
          });
        }, 5000);
      });
    });
  }

  render() {
    const {
      loading, offerClasses, error, activeOfferClassIds, inactiveOfferClassIds, submitting,
      submittingError,
    } = this.state;

    const header = [
      {
        displayText: 'Id',
        key: 'id',
      },
      {
        displayText: 'Offer Type',
        key: 'offer_type',
      },
      {
        displayText: 'Offer Class',
        key: 'offer_class',
      },
      {
        displayText: 'Active Status',
        key: 'activeStatus',
        renderer: (data) => (
          <input
            type="checkbox"
            id={data.id}
            checked={activeOfferClassIds.includes(data.id)}
            disabled={submitting || !this.canIEdit}
            onChange={(e) => {
              if (data.enabled !== e.target.checked) {
                this.modifiedCount += 1;
              } else if (this.modifiedCount) {
                this.modifiedCount -= 1;
              }
              if (e.target.checked) {
                inactiveOfferClassIds.splice(inactiveOfferClassIds.indexOf(data.id), 1);
                activeOfferClassIds.push(data.id);
              } else {
                activeOfferClassIds.splice(activeOfferClassIds.indexOf(data.id), 1);
                inactiveOfferClassIds.push(data.id);
              }
              this.setState({
                activeOfferClassIds,
                inactiveOfferClassIds,
              });
            }}
          />
        ),
      },
    ];

    if (loading) {
      return (
        <div
          className="h-100 d-flex align-items-center
            justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (error) {
      return (
        <div
          className="h-100 d-flex align-items-center
             justify-content-center"
        >
          <ErrorHandler
            retryLogic={() => {
              this.setState({ loading: true, error: false }, this.loadOfferClasses());
            }}
          />
        </div>
      );
    }

    return (
      <Row
        className="mx-0"
      >
        <Col
          xs={24}
          className="pb-4 position-sticky bg-white"
        >
          <Row
            className=" d-flex align-items-center mx-0"
          >
            {
              this.modifiedCount > 0 && (
                <Col
                  xs="auto"
                  className=""
                >
                  <Button
                    variant="primary"
                    onClick={() => this.alterOfferClasses()}
                    disabled={submitting || !this.modifiedCount}
                  >
                    SAVE
                  </Button>
                </Col>
              )
            }
            {
              submitting && (
                <Col
                  xs="auto"
                  className="pl-0"
                >
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                </Col>
              )
            }
            {
              submittingError && (
                <Col
                  xs="auto"
                  className="pl-0 text-danger"
                >
                  {submittingError}
                </Col>
              )
            }
          </Row>
        </Col>
        <Col
          xs={24}
        >
          <CustomTable
            content={offerClasses.results}
            keyField="id"
            headers={header}
            totalItems={offerClasses.count}
            isPaginated={false}
          />
        </Col>
      </Row>
    );
  }
}

OfferClass.propTypes = {
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default OfferClass;
