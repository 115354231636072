import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Form, InputGroup, Button, Spinner, Collapse,
} from 'react-bootstrap';
import Axios from 'axios';
import { Svg, CustomModal } from '../../component/common';
import { Constant } from '../../utilities';
import { libraryProduct, retailerRequest, storeProduct } from '../../assets/api/axios';
import { Backdrop } from '../wrapper';
import '../../assets/scss/RetailerManagement/AddNewProduct.scss';
import permission from '../../access&permissions/permission';

const getvalidInput = (value) => {
  if (value === '') {
    return value;
  }
  const numVal = Number(value);
  if (numVal && numVal > 0) {
    return numVal;
  }
  return null;
};

class AddNewProduct extends React.Component {
  constructor(props) {
    super();
    const { userPermission } = props;
    this.state = {
      query: '',
      loading: false,
      error: false,
      limit: 10,
      offset: 0,
      selectedProduct: null,
      addedProducts: [],
      processingAdd: false,
      addError: null,
      mrp: '',
      sellingPrice: '',
      editProduct: null,
      products: null,
    };
    this.isForceAdd = userPermission.includes(permission.FORCE_ADD_BULK_UPLOAD);
    this.source = Axios.CancelToken.source();
  }

  onSubmitSearchText = (e) => {
    const { value } = e.target;
    const { loading, query } = this.state;
    if (value !== query) {
      if (loading) {
        this.source.cancel();
        this.source = Axios.CancelToken.source();
      }
      if (value) {
        this.setState({
          loading: true,
          query: value,
        }, () => {
          this.handleLoad();
        });
        return;
      }
      this.setState({
        loading: false,
        products: null,
      });
    }
  }

  handleLoad = (data = {}) => {
    const { limit, offset, query } = this.state;
    const newData = {
      ...data, offset, query,
    };
    const { retailerCode } = this.props;
    libraryProduct('get',
      null,
      null,
      {
        offset: `${newData.offset}`,
        limit: `${limit}`,
        searchText: `${query}`,
        storeId: retailerCode,
        ignoreAddedProduct: true,
      },
      this.source.token)
      .then((res) => {
        this.setState({
          loading: false,
          products: { ...res.data },
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
          products: null,
        });
      });
  }

  addProduct = () => {
    const { selectedProduct, addedProducts } = this.state;
    const { retailerCode } = this.props;
    retailerRequest('post', {
      storeId: retailerCode,
      productIds: [selectedProduct],
    })
      .then((res) => {
        const { results } = res.data;
        if (results.length && results[0].status === 'APPROVED') {
          const { StoreProductID } = results[0];
          const { id, mrp, sellingPrice } = StoreProductID;
          this.setState({
            addedProducts: [
              ...addedProducts,
              {
                libraryProductId: selectedProduct,
                id,
                mrp,
                sellingPrice,
              },
            ],
            selectedProduct: null,
            processingAdd: false,
            addError: null,
          });
          return;
        }
        this.setState({
          processingAdd: false,
          addError: {
            unApproved: true,
          },
        });
      })
      .catch(() => {
        this.setState({
          processingAdd: false,
          addError: {
            http: true,
          },
        });
      });
    this.retry = () => {
      this.setState({
        processingAdd: true,
        addError: null,
      }, () => {
        this.addProduct();
      });
    };
  }

  selectProduct = (productId) => {
    this.setState({
      selectedProduct: productId,
      processingAdd: true,
      addError: null,
    }, () => {
      this.addProduct();
    });
  }

  patchProduct = () => {
    const {
      mrp, sellingPrice, editProduct, addedProducts,
    } = this.state;
    storeProduct('patch', { mrp, sellingPrice, forceAdd: this.isForceAdd }, editProduct)
      .then(() => {
        const retailerProduct = addedProducts.map((item) => item.id === editProduct);
        this.setState({
          addedProducts:
            [...addedProducts,
              {
                ...retailerProduct,
                mrp,
                sellingPrice,
              }],
          patchProcessing: false,
          patchError: false,
        });
      })
      .catch(() => {
        this.setState({
          patchProcessing: false,
          patchError: true,
        });
      });
  }

  render() {
    const {
      products, loading, selectedProduct, processingAdd, addError, addedProducts,
      mrp, sellingPrice, editProduct, patchProcessing, patchError, error,
    } = this.state;
    const { show, toggleAddNewProductView } = this.props;
    const body = (
      <Container
        className="mx-0"
      >
        <Row>
          <Col
            xs={24}
            className="px-0 mb-2"
          >
            <Row
              className="mx-0"
            >
              <Col
                className="px-0"
              >
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className="rounded-0"
                    >
                      <Svg
                        svg="search"
                        width="1rem"
                        fill={Constant.Color.DARK}
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    className="fs-1 rounded-0"
                    autoComplete="off"
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        this.onSubmitSearchText(e);
                      }
                    }}
                  />
                </InputGroup>
              </Col>
              <Col
                xs="auto"
                className="px-0"
              >
                <Button
                  variant="dark"
                  className="rounded-0"
                  onClick={() => {
                    this.setState({
                      products: null,
                    }, toggleAddNewProductView);
                  }}
                >
                  <Svg
                    svg="close"
                    width="1rem"
                    fill={Constant.Color.WHITE}
                  />
                </Button>
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            className="px-2"
          >
            {products && products.results.map((product) => {
              const isSelected = selectedProduct === product.id;
              const retailerProduct = addedProducts.find((productItems) => (
                productItems.libraryProductId === product.id));
              return (
                <Row
                  key={product.id}
                  className="my-2 mx-0"
                >
                  <Col
                    xs={24}
                    className="px-0"
                  >
                    <Button
                      variant="white"
                      className="fs-0 d-block w-100 rounded-0 border text-left"
                      disabled={!!retailerProduct}
                      onClick={() => {
                        this.selectProduct(product.id);
                      }}
                    >
                      <Row
                        className="mx-0"
                      >
                        <Col
                          className="p-2 fs-0"
                        >
                          <div>
                            {product.displayName}
                          </div>
                          {retailerProduct && (
                          <div
                            className="mt-2"
                          >
                            <span
                              className="mr-2 text-primary"
                            >
                             &#8377;
                              {retailerProduct.sellingPrice}
                            </span>
                            <span>
                             &#8377;
                              {retailerProduct.mrp}
                            </span>
                          </div>
                          )}
                        </Col>
                        <Col
                          xs="auto"
                          className="p-2"
                        >
                          <div
                            className="product-image-div"
                          >
                            <img
                              src={product.image}
                              alt=""
                            />
                          </div>
                        </Col>
                      </Row>
                    </Button>
                    {isSelected && (
                    <Backdrop
                      show
                      onFade={() => {
                        this.setState({
                          selectedProduct: null,
                          processingAdd: false,
                          addError: null,
                        });
                      }}
                    />
                    )}
                    {isSelected && (
                      <div
                        className="add-product-status"
                      >
                        {processingAdd && (
                        <Spinner
                          animation="border"
                          variant="primary"
                        />
                        )}
                        {
                          addError && addError.http && (
                            <Button
                              variant="danger"
                              className="fs-01"
                              onClick={this.retry}
                            >
                              Retry
                            </Button>
                          )
                        }
                      </div>
                    )}
                  </Col>
                  {retailerProduct && (
                  <Col
                    xs={24}
                    className="px-0 bg-light"
                  >
                    {editProduct !== retailerProduct.id && (
                    <Button
                      className="fs-0 rounded-0 w-100"
                      onClick={() => {
                        this.setState({
                          editProduct: retailerProduct.id,
                          mrp: retailerProduct.mrp,
                          sellingPrice: retailerProduct.sellingPrice,
                        });
                      }}
                    >
                     Edit
                    </Button>
                    )}
                    <Collapse in={editProduct === retailerProduct.id}>
                      <Row
                        id="edit-product"
                        className="mx-0 fs-0"
                      >
                        <Col
                          xs={12}
                          className="pl-2"
                        >
                          <div>
                          MRP:
                          </div>
                          <div>
                            <Form.Control
                              type="text"
                              size="sm"
                              className="fs-0"
                              value={mrp}
                              onChange={(e) => {
                                const val = getvalidInput(e.target.value);
                                if (val !== null) {
                                  this.setState({
                                    mrp: val,
                                  });
                                }
                              }}
                            />
                          </div>
                        </Col>
                        <Col
                          xs={12}
                          className="pl-2"
                        >
                          <div>
                          Selling Price:
                          </div>
                          <div>
                            <Form.Control
                              type="text"
                              size="sm"
                              className="fs-0"
                              value={sellingPrice}
                              onChange={(e) => {
                                const val = getvalidInput(e.target.value);
                                if (val !== null) {
                                  this.setState({
                                    sellingPrice: val,
                                  });
                                }
                              }}
                            />
                          </div>
                        </Col>
                        <Col
                          xs={24}
                          className="px-0 pt-2"
                        >
                          {patchProcessing
                            ? (
                              <div
                                className="text-center"
                              >
                                <Spinner
                                  animation="border"
                                  variant="primary"
                                />
                              </div>
                            )
                            : (
                              <>
                                <Button
                                  variant="success"
                                  className="fs-0 rounded-0 w-50"
                                  disabled={
                                    !mrp || !sellingPrice || mrp < sellingPrice
                                   }
                                  onClick={() => {
                                    this.setState({
                                      patchProcessing: true,
                                      patchError: false,
                                    }, () => {
                                      this.patchProduct();
                                    });
                                  }}
                                >
                                Save
                                </Button>
                                <Button
                                  variant="secondary"
                                  className="fs-0 rounded-0 w-50"
                                  onClick={() => {
                                    this.setState({
                                      editProduct: null,
                                      mrp: '',
                                      sellingPrice: '',
                                      patchError: false,
                                      patchProcessing: false,
                                    });
                                  }}
                                >
                                cancel
                                </Button>
                              </>
                            )}
                        </Col>
                        {patchError && (
                        <Col
                          xs={24}
                          className="px-0 text-danger fs-0"
                        >
                          Something went wrong
                        </Col>
                        )}
                      </Row>
                    </Collapse>
                  </Col>
                  )}
                </Row>
              );
            })}
          </Col>
          <Col
            xs={24}
          >
            <div
              className="text-center"
            >
              {
                loading && (
                <Spinner
                  animation="border"
                  variant="primary"
                />
                )
              }
              {
                !loading && error && (
                  <div
                    className="pt-2 text-center text-danger"
                  >
                    <Col
                      xs={24}
                    >
                      Oops! Something went wrong.&nbsp;
                    </Col>
                    <Col
                      xs={24}
                      className="pt-2"
                    >
                      <Button
                        variant="outline-primary"
                        className="py-1"
                        onClick={() => {
                          this.setState({
                            loading: true,
                            error: false,
                          }, () => this.handleLoad());
                        }}
                      >
                        Retry
                      </Button>
                    </Col>
                  </div>
                )
              }
              {products && products.hasNext && (
              <Button
                className="fs-01"
                onClick={() => {}}
              >
                Load More
              </Button>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    );
    return (
      <CustomModal
        show={show}
        body={body}
        onHide={() => {
          this.setState({
            query: '',
            loading: false,
            error: false,
            products: null,
          });
        }}
      />
    );
  }
}

AddNewProduct.propTypes = {
  show: PropTypes.bool.isRequired,
  retailerCode: PropTypes.string.isRequired,
  toggleAddNewProductView: PropTypes.func.isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default AddNewProduct;
