import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Button,
} from 'react-bootstrap';
import { bulkAddProducts, bulkRemoveProducts, storeProfile } from '../../../assets/api/axios';
import { Svg } from '../../common';
import * as Utils from '../../../utilities/Utils';
import Constant from '../../../utilities/Constant';
import LibraryProductModal from './LibraryProductModal';
import { validateRegex, validateForm } from '../../../utilities/FormValidations';

class LibraryProduct extends React.Component {
  constructor(props) {
    super(props);
    const { product } = props;
    const variants = product.variants.length === 0
      ? [{ ...product }] : [...product.variants];
    this.state = {
      showAddModal: false,
      product,
      variants: variants.map((item) => ({
        ...item,
        mrp: item.mrp.toString(),
        sellingPrice: item.mrp.toString(),
        isEnabled: !item.alreadyMapped,
        toBeRemoved: false,
        toBeUpdated: false,
      })),
      isSubmitting: false,
      errors: {},
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    const { product } = props;
    if (JSON.stringify(product) !== JSON.stringify(state.product)) {
      const variants = product.variants.length === 0
        ? [{ ...product }] : [...product.variants];
      return ({
        product,
        variants: variants.map((item) => ({
          ...item,
          mrp: item.mrp.toString(),
          sellingPrice: item.mrp.toString(),
          isEnabled: !item.alreadyMapped,
          toBeRemoved: false,
          toBeUpdated: false,
        })),
      });
    }
    return null;
  }

  toggleModal = () => {
    this.setState((state) => ({
      showAddModal: !state.showAddModal,
    }));
  }

  toggleVariant = (isEnabled, index) => {
    this.setState((state) => {
      const { product } = this.props;
      const originalVariants = product.variants.length === 0
        ? [{ ...product }] : [...product.variants];
      let variant = { ...state.variants[index] };
      if (variant.alreadyMapped) {
        if (isEnabled) {
          variant = {
            ...originalVariants[index],
            mrp: originalVariants[index].mrp.toString(),
            sellingPrice: originalVariants[index].mrp.toString(),
            isEnabled: false,
            toBeRemoved: true,
            toBeUpdated: false,
          };
        } else {
          variant.toBeRemoved = isEnabled;
        }
      } else if (isEnabled) {
        variant.isEnabled = isEnabled;
      } else {
        variant = {
          ...originalVariants[index],
          mrp: originalVariants[index].mrp.toString(),
          sellingPrice: originalVariants[index].mrp.toString(),
          isEnabled: false,
          toBeRemoved: false,
          toBeUpdated: false,
        };
      }
      return ({
        variants: Object.assign(
          [...state.variants],
          {
            [index]: variant,
          },
        ),
      });
    });
  }

  handleChange = (name, text, index, alreadyMapped = false) => {
    if (text === '.' || !validateRegex(name, text)) {
      return;
    }
    this.setState((state) => {
      const { errors } = state;
      delete errors[state.variants[index].id];
      return ({
        variants: Object.assign(
          [...state.variants],
          {
            [index]: {
              ...state.variants[index],
              [name]: text,
              toBeUpdated: alreadyMapped,
            },
          },
        ),
        errors,
      });
    });
  }

  addProducts = (products) => {
    const { errors } = this.state;
    const { retailerDetails } = this.props;
    const newErrors = validateForm(
      errors,
      {
        libraryVariants: products,
      },
    );
    if (Object.keys(newErrors.libraryVariants || {}).length) {
      this.setState({
        errors: newErrors.libraryVariants,
      });
      return;
    }
    const { isSubmitting } = this.state;
    if (!isSubmitting) {
      this.setState({
        isSubmitting: true,
      });
    }
    bulkAddProducts(
      'POST',
      {
        products,
        storeCode: retailerDetails.code,
      },
    ).then(() => {
      const {
        reloadList,
      } = this.props;
      if (!retailerDetails.setupStages.productAdd) {
        storeProfile(
          'patch',
          retailerDetails.code,
          {
            setupStages: {
              productAdd: true,
            },
          },
        ).then(() => {
          retailerDetails.setupStages.productAdd = true;
        });
      }
      if (reloadList) {
        reloadList();
      }
      const { isSubmitting: submitting, showAddModal } = this.state;
      if (submitting || showAddModal) {
        this.setState({
          isSubmitting: false,
          showAddModal: false,
        });
      }
    }).catch(() => {
      this.setState({
        isSubmitting: false,
      });
    });
  }

  removeProducts = (products) => {
    const { isSubmitting } = this.state;
    const { retailerDetails } = this.props;
    if (!isSubmitting) {
      this.setState({
        isSubmitting: true,
      });
    }
    bulkRemoveProducts(
      'POST',
      {
        products: products.map((item) => item.id),
        storeCode: retailerDetails.code,
      },
    ).then(() => {
      const { reloadList } = this.props;
      if (!retailerDetails.setupStages.productAdd) {
        storeProfile(
          'patch',
          retailerDetails.code,
          {
            setupStages: {
              productAdd: true,
            },
          },
        ).then(() => {
          retailerDetails.setupStages.productAdd = true;
        });
      }
      if (reloadList) {
        reloadList();
      }
      const { isSubmitting: submitting, showAddModal } = this.state;
      if (submitting || showAddModal) {
        this.setState({
          isSubmitting: false,
          showAddModal: false,
        });
      }
    }).catch(() => {
      this.setState({
        isSubmitting: false,
      });
    });
  }

  onSubmit = (toBeAdded, toBeUpdated, toBeRemoved) => {
    const products = toBeAdded.concat(toBeUpdated).map(
      (product) => ({
        id: product.id,
        sellingPrice: product.sellingPrice,
        mrp: product.mrpApplicable ? product.mrp : product.sellingPrice,
      }),
    );
    if (products.length > 0) {
      this.addProducts(products);
    }
    if (toBeRemoved.length > 0) {
      this.removeProducts(toBeRemoved);
    }
  }

  render() {
    const { retailerDetails } = this.props;
    const {
      product, variants, showAddModal, isSubmitting, errors,
    } = this.state;
    let mappingStatus = 0;
    if (product.variants.length === 0) {
      if (product.alreadyMapped) {
        mappingStatus = 2;
      }
    } else {
      const mapppedVariants = product.variants
        .filter((variant) => variant.alreadyMapped);
      if (mapppedVariants.length === 0) {
        mappingStatus = 0;
      } else
      if (mapppedVariants.length === product.variants.length) {
        mappingStatus = 2;
      } else {
        mappingStatus = 1;
      }
    }
    return (
      <>
        {showAddModal && (
        <LibraryProductModal
          onClose={this.toggleModal}
          product={product}
          variants={variants}
          toggleVariant={this.toggleVariant}
          handleChange={this.handleChange}
          isSubmitting={isSubmitting}
          onSubmit={this.onSubmit}
          onDelivery={retailerDetails.onDelivery}
          mappingStatus={mappingStatus}
          errors={errors}
        />
        )}
        <Button
          variant="link"
          onClick={this.toggleModal}
          className="p-2 d-block text-dark w-100"
        >
          <Row
            className="mx-0"
          >
            <Col
              xs="auto"
              className="p-2"
            >
              <div
                className="product-image-container"
              >
                <img
                  src={product.imageUrl}
                  className="product-image"
                  alt=""
                />
              </div>
            </Col>
            <Col
              className="p-2 d-flex flex-column justify-content-between align-items-start"
            >
              <div
                className="fs-0 text-left"
              >
                {product.displayName}
              </div>
              <Row
                className="align-items-center"
              >
                <Col
                  xs="auto"
                >
                  <div
                    className="px-2 py-1 d-flex align-items-center justify-content-center border minw-80p"
                  >
                    <div
                      className="fs-01"
                    >
                      {`${product.unit} ${Utils.unitsMap[product.uom]}`}
                    </div>
                    {product.variants.length !== 0 && (
                    <div
                      className="ml-1"
                    >
                      <Svg
                        svg="arrowHead"
                        width="18"
                        stroke={Constant.Color.DARK}
                      />
                    </div>
                    )}
                  </div>
                </Col>
                {product.mrpApplicable && (
                <Col
                  xs="auto"
                  className={`fs-01 ${mappingStatus === 2 ? 'text-medium' : 'text-dark font-weight-bold'}`}
                >
                  {`MRP: ₹${product.mrp}`}
                </Col>
                )}
              </Row>
            </Col>
            <Col
              xs="auto"
              className="p-2"
            >
              <div
                className={`px-2 py-1 border fs-01 minw-80p  ${mappingStatus === 0
                  ? 'border-primary' : 'border-light'} ${mappingStatus === 2 ? 'text-secondary' : 'text-primary'}`}
              >
                {mappingStatus === 2 ? 'ADDED' : 'ADD'}
              </div>
            </Col>
          </Row>
        </Button>
      </>
    );
  }
}

LibraryProduct.propTypes = {
  product: PropTypes.shape({
    imageUrl: PropTypes.string,
    displayName: PropTypes.string,
    mrp: PropTypes.number,
    unit: PropTypes.number,
    uom: PropTypes.string,
    mrpApplicable: PropTypes.bool,
    alreadyMapped: PropTypes.bool,
    variants: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  retailerDetails: PropTypes.shape({
    onDelivery: PropTypes.bool,
    code: PropTypes.string,
    setupStages: PropTypes.shape({
      productAdd: PropTypes.bool,
    }),
  }).isRequired,
  reloadList: PropTypes.func.isRequired,
};

export default LibraryProduct;
