import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
  >
    <g id="Frames-24px">
      <rect fill="none" width="24" height="24" />
    </g>
    <g id="Outline">
      <polygon
        fill={fill}
        points="20.707,4.707 19.293,3.293 12,10.586 4.707,3.293 3.293,4.707 10.586,12 3.293,19.293
       4.707,20.707 12,13.414 19.293,20.707 20.707,19.293 13.414,12"
      />
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
