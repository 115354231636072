import { getMessaging, onMessage, deleteToken } from '@firebase/messaging';

export function unsubscribeFCM() {
  try {
    const messaging = getMessaging();
    if (messaging) {
      deleteToken(messaging);
    }
  } catch (e) {
    console.error('Error while unsubscribing fcm');
  }
}

export function iniializeFirebaseMessaging() {
  try {
    const messaging = getMessaging();

    onMessage(messaging, (payload) => {
      const { notification } = payload;
      const { title, body } = notification;
      const notificationTitle = title;
      const notificationOptions = { body };
      const noti = new Notification(notificationTitle, notificationOptions);
      noti.onclick = function (event) {
        event.preventDefault(); // prevent the browser from focusing the Notification's tab
        window.focus();
        noti.close();
      };
    });
  } catch (e) {
    console.error('Error - Firebase Messaging ');
  }
}
