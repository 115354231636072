import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Spinner, Button,
} from 'react-bootstrap';
import { transactionMeta } from '../../../assets/api/axios';
import { CustomModal, CustomTable } from '../../../component/common';
import { dateString } from '../../../utilities/Utils';

const TransactionMeta = (props) => {
  const { orderId, show, toggleModal } = props;
  const [loadStatus, setLoadStatus] = useState('');
  const [logs, setTransactionMetaLogs] = useState([]);

  const loadContent = () => {
    setLoadStatus('LOADING');
    transactionMeta(
      'GET', null, {}, orderId,
    ).then((res) => {
      setTransactionMetaLogs(res.data.data);
      setLoadStatus('SUCCESS');
    }).catch(() => {
      setLoadStatus('ERROR');
    });
  };

  useEffect(() => {
    if (!loadStatus) {
      loadContent();
    }
  });

  const retry = () => {
    setLoadStatus('');
  };

  const headerConfig = [
    {
      key: 'transactionId',
      displayText: 'Transaction Id',
    },
    {
      key: 'transactionChannel',
      displayText: 'Transaction Channel',
    },
    {
      key: 'status',
      displayText: 'Status',
    },
    {
      key: 'createdOn',
      displayText: 'Created On',
      renderer: (data) => dateString(data.createdOn),
    },
    {
      key: 'transactionFees',
      displayText: 'Transaction Fees',
    },
    {
      key: 'paidAmount',
      displayText: 'Paid Amt.',
    },
    {
      key: 'refundInFlow',
      displayText: 'Refund In Flow',
    },
    {
      key: 'totalRefundedAmount',
      displayText: 'Total Refund Amt.',
    },
  ];

  return (
    <CustomModal
      show={show}
      size="lg"
      onHide={toggleModal}
      closeButton
      body={(
        <Container className="p-4">
          {
            loadStatus === 'LOADING' && (
              <Col
                xs={24}
                className="text-primary text-center"
              >
                <Row className="py-2 justify-content-center d-flex">
                  <Spinner
                    variant="primary"
                    animation="border"
                  />
                </Row>
                <Row className="py-2 justify-content-center">
                  Fetching Trasaction Meta
                </Row>
              </Col>
            )
          }
          {
            loadStatus === 'SUCCESS' && (
              <CustomTable
                keyField="transactionId"
                headers={headerConfig}
                content={logs}
                isPaginated={false}
                totalItems={logs.length}
              />
            )
          }
          {
            loadStatus === 'ERROR' && (
              <Col xs={24} className="text-danger text-center p-2">
                Oops Something went wrong while fetching Transaction Meta,
                Please try again!!
                &nbsp;&nbsp;
                <Button
                  variant="link"
                  className="text-primary font-weight-bold"
                  onClick={() => { retry(); }}
                >
                  RETRY
                </Button>
              </Col>
            )
          }
          <Col
            xs={24}
            className="d-flex justify-content-center"
          >
            <Button
              variant="outline-primary"
              onClick={toggleModal}
            >
              EXIT
            </Button>
          </Col>
        </Container>
      )}
    />
  );
};

TransactionMeta.propTypes = {
  orderId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  show: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default TransactionMeta;
