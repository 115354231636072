import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Button,
} from 'react-bootstrap';
import { CustomModal } from '../../../../component/common';
import { uploadImage } from '../../../../assets/api/axios';
import brandBannersApis from '../../apis';

const bannerTypes = ['QUICK_CHECKOUT', 'REFER_AND_EARN'];

function OtherLoveLocalBannerCnE(props) {
  const {
    selectedBannerData, submitType,
    showEditModal, onClose, afterPatchAndPostSyncLocal,
  } = props;

  const otherLoveLocalBanner = {
    title: '',
    bannerType: 'QUICK_CHECKOUT',
    brandImage: '',
    brandWebImage: '',
    appDeepLink: '',
    webDeepLink: '',
    iosImage: '',
    iosDeepLink: '',
    priorityOrder: 1,
    bannersType: 'OTHER_LL_BANNER',
    expiryDate: new Date().toISOString().split('T')[0],
  };

  const [editData, setEditData] = useState(otherLoveLocalBanner);
  const [submitStatus, setSubmitStatus] = useState('');

  const isButtonDisabled = (
    !editData.title
    || !editData.brandImage
    || !editData.brandWebImage
    || !editData.appDeepLink
    || !editData.webDeepLink
    || !editData.priorityOrder
    || !editData.iosImage
    || !editData.iosDeepLink
    || submitStatus === 'loading'
  );

  useEffect(() => {
    if (submitType === 'EDIT') {
      setEditData({
        ...editData,
        ...selectedBannerData,
      });
    } else {
      setEditData({
        ...otherLoveLocalBanner,
      });
    }
  }, [selectedBannerData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditData({
      ...editData,
      [name]: value,
    });
  };

  const handleImageChange = (name, file) => {
    const formData = new FormData();
    formData.append('img', file);
    if (file) {
      uploadImage(
        'post',
        formData,
      ).then((res) => {
        setEditData({
          ...editData,
          [name]: res.data.url,
        });
      }).catch(() => {
      });
    }
  };

  const submitBrandSpace = () => {
    setSubmitStatus('loading');
    const bannerSubmitApi = submitType === 'EDIT' ? brandBannersApis.bannersPatch : brandBannersApis.bannersPost;
    bannerSubmitApi(
      {},
      {
        ...editData,
        // launchDate: `${new Date(editData.launchDate).getTime()}`,
        expiryDate: `${new Date(editData.expiryDate).getTime()}`,
      },
    ).then((res) => {
      afterPatchAndPostSyncLocal(res.data.data, submitType);
      setSubmitStatus('success');
      onClose();
    }).catch(() => {
      setSubmitStatus('error');
    });
  };

  return (
    <CustomModal
      show={!!showEditModal}
      size="lg"
      onHide={() => {
        onClose();
      }}
      body={(
        <Container
          className="p-4 border-radius-16"
        >
          <Row>
            {/* Banner Title */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Title
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="text"
                  value={editData.title}
                  name="title"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col>

            {/* Banner Priority Order */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Priority Order
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="text"
                  value={editData.priorityOrder}
                  name="priorityOrder"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col>

            {/* Banner App DeepLink */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner App DeepLink
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="text"
                  value={editData.appDeepLink}
                  name="appDeepLink"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col>

            {/* Banner iOS DeepLink */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner iOS DeepLink
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="text"
                  value={editData.iosDeepLink}
                  name="iosDeepLink"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col>

            {/* Banner Web DeepLink */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Web DeepLink
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="text"
                  value={editData.webDeepLink}
                  name="webDeepLink"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col>

            {/* Banner Launch Date */}
            {/* <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Launch Date
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="date"
                  value={editData.launchDate}
                  name="launchDate"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col> */}

            {/* Banner expiryDate */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Expiry Date
              </div>
              <div
                className="w-100"
              >
                <input
                  className="form-control"
                  type="date"
                  value={editData.expiryDate}
                  name="expiryDate"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </Col>

            {/* Banner Type */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <div
                className="fs-01"
              >
                Banner Type
              </div>
              <div
                className="w-100"
              >
                <select
                  className="form-control"
                  value={editData.bannerType}
                  name="bannerType"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                >
                  {
                    bannerTypes.map((item) => (
                      <option
                        key={item}
                        value={item}
                      >
                        {item}
                      </option>
                    ))
                  }
                </select>
              </div>
            </Col>

            {/* Banner Images */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <Row>
                <Col
                  xs={8}
                >
                  <div
                    className="fs-01"
                  >
                    Banner Image
                  </div>
                  <div
                    className="w-100"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        handleImageChange('brandImage', e.target.files[0]);
                      }}
                    />
                  </div>
                  {
                    editData.brandImage && (
                      <div>
                        <img src={editData.brandImage} alt="" className="mw-100 mh-100" />
                      </div>
                    )
                  }
                </Col>
                <Col
                  xs={8}
                >
                  <div
                    className="fs-01"
                  >
                    iOS Banner Image
                  </div>
                  <div
                    className="w-100"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        handleImageChange('iosImage', e.target.files[0]);
                      }}
                    />
                  </div>
                  {
                    editData.iosImage && (
                      <div>
                        <img src={editData.iosImage} alt="" className="mw-100 mh-100" />
                      </div>
                    )
                  }
                </Col>
                <Col
                  xs={8}
                >
                  <div
                    className="fs-01"
                  >
                    Web Brand Image
                  </div>
                  <div
                    className="w-100"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        handleImageChange('brandWebImage', e.target.files[0]);
                      }}
                    />
                  </div>
                  {
                    editData.brandWebImage && (
                      <div>
                        <img src={editData.brandWebImage} alt="" className="mw-100 mh-100" />
                      </div>
                    )
                  }
                </Col>
              </Row>
            </Col>

            {/* Submit Banner */}
            <Col
              xs={24}
              className="pb-2 font-weight-bold"
            >
              <Button
                variant="primary"
                block
                className="font-weight-bold"
                onClick={() => {
                  submitBrandSpace();
                }}
                disabled={isButtonDisabled}
              >
                SUBMIT
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    />
  );
}

OtherLoveLocalBannerCnE.defaultProps = {
  submitType: 'ADD',
  selectedBannerData: {},
  showEditModal: false,
  onClose: () => {},
  afterPatchAndPostSyncLocal: () => {},
};

OtherLoveLocalBannerCnE.propTypes = {
  submitType: PropTypes.string,
  selectedBannerData: PropTypes.shape({}),
  showEditModal: PropTypes.bool,
  onClose: PropTypes.func,
  afterPatchAndPostSyncLocal: PropTypes.func,
};

export default OtherLoveLocalBannerCnE;
