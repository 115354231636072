import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';

const OnboardingDetails = (props) => {
  const {
    details, title, submitMessage, showButton, resetList,
    handleSubmit, submitLoading, modifyStatus,
    rejectionReason, comment, tab,
    createFundAccount, isDisabled, submitError, bankAccountStatus,
    switchSettlementMode, accountDetails, updatedVpaStatus,
    upiComment,
  } = props;

  const { vpaDetails, defaultAccountType } = accountDetails || {};
  let isDefaultAccountType = false;
  if (tab === 'UPI_DETAILS' && defaultAccountType === 'VPA') {
    isDefaultAccountType = true;
  } else if (tab === 'BANK_DETAILS' && defaultAccountType === 'BANK') {
    isDefaultAccountType = true;
  }

  return (
    <Container
      fluid
      className="py-0 m-0"
    >
      <Row>
        <Col
          className="text-center border-bottom py-2"
        >
          <b>{title}</b>
        </Col>
      </Row>
      {
        details.map((item) => (
          <Row
            className="border-bottom py-1"
            key={item.id}
          >
            <Col
              xs={12}
              className="border-right text-medium fs-01"
            >
              <b>{item.label}</b>
            </Col>
            <Col
              xs={12}
              className="text-wrap break-word"
            >
              {item.value}
            </Col>
          </Row>
        ))
      }
      {
        showButton && (
          <>
            <Row>
              <Col
                xs={24}
                className="text-center py-3"
              >
                {
                  (tab === 'UPI_DETAILS'
                  || tab === 'BANK_DETAILS')
                  && accountDetails
                  && (
                    <>
                      {isDefaultAccountType ? (
                        <Button
                          disabled
                          className="mr-2 text-green bg-white pointer-events-none"
                        >
                          Default settlement mode
                        </Button>
                      ) : (
                        <Button
                          onClick={switchSettlementMode}
                          disabled={(tab === 'BANK_DETAILS' && bankAccountStatus !== 'APPROVED')
                          || (tab === 'UPI_DETAILS' && vpaDetails.crmStatus !== 'APPROVED')
                          || submitLoading}
                          className="mr-2"
                        >
                          Mark as default
                        </Button>
                      )}
                    </>
                  )
                }
                <Button
                  variant="outline-primary"
                  disabled={
                    submitLoading || (modifyStatus === '' && tab !== 'UPI_DETAILS')
                    || (modifyStatus === 'RESET' && resetList.length <= 0 && tab !== 'UPI_DETAILS')
                    || (
                      (modifyStatus === 'WAITLIST' || modifyStatus === 'DENIED')
                      && (rejectionReason === '' || (rejectionReason === 'Others' && comment === ''))
                      && tab !== 'UPI_DETAILS'
                    )
                    || (tab === 'KYC_DETAILS' && modifyStatus === 'APPROVED' && comment === '')
                    || isDisabled
                    || (tab === 'FIRST_ORDER_STATUS' && comment === '')
                    || (tab === 'UPI_DETAILS' && updatedVpaStatus === 'DENIED' && upiComment === '')
                  }
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
            {
              tab === 'FIRST_ORDER_STATUS' && isDisabled
              && (
                <Row>
                  <Col
                    xs={24}
                    className="text-center text-muted"
                  >
                    (KYC details not verified)
                  </Col>
                </Row>
              )
            }
          </>
        )
      }
      {
        createFundAccount && (
          <>
            <Row>
              <Col
                xs={24}
                className="text-center pt-3 pb-1"
              >
                <Button
                  variant="outline-primary"
                  disabled={isDisabled || submitLoading}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Generate Fund Account
                </Button>
              </Col>
            </Row>
            {
              (bankAccountStatus !== 'APPROVED'
              && (vpaDetails && vpaDetails.crmStatus !== 'APPROVED'))
              && (
                <Row>
                  <Col
                    xs={24}
                    className="text-center text-muted"
                  >
                    (Settlement details not verified)
                  </Col>
                </Row>
              )
            }
          </>
        )
      }
      {
        submitLoading && (
          <div
            className="d-flex align-items-center justify-content-center"
          >
            <Spinner
              animation="border"
              variant="primary"
            />
          </div>
        )
      }
      {
        !submitLoading && submitMessage && (
        <div
          className="text-success text-center"
        >
          Request Successful!
        </div>
        )
      }
      {
        !submitLoading && !submitMessage && submitError && (
          <div
            className="text-danger text-center"
          >
            {submitError}
          </div>
        )
      }
    </Container>
  );
};

OnboardingDetails.propTypes = {
  title: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showButton: PropTypes.bool,
  submitMessage: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submitLoading: PropTypes.bool,
  modifyStatus: PropTypes.string,
  rejectionReason: PropTypes.string,
  comment: PropTypes.string,
  resetList: PropTypes.arrayOf(PropTypes.shape({})),
  createFundAccount: PropTypes.bool,
  isDisabled: PropTypes.bool,
  submitError: PropTypes.string,
  bankAccountStatus: PropTypes.bool,
  tab: PropTypes.string,
  switchSettlementMode: PropTypes.func,
  accountDetails: PropTypes.shape({}),
  updatedVpaStatus: PropTypes.func,
  upiComment: PropTypes.string,
};

OnboardingDetails.defaultProps = {
  handleSubmit: () => {},
  showButton: false,
  submitMessage: false,
  submitLoading: false,
  modifyStatus: '',
  rejectionReason: '',
  comment: '',
  resetList: [],
  createFundAccount: false,
  isDisabled: false,
  submitError: '',
  bankAccountStatus: false,
  tab: '',
  switchSettlementMode: () => {},
  accountDetails: {},
  updatedVpaStatus: () => {},
  upiComment: '',
};

export default OnboardingDetails;
