/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Button,
  Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';
import moment from 'moment';
import { CustomDropdown, CustomModal, ErrorHandler } from '../../component/common';
import { couponCampaigns, uploadImage } from '../../assets/api/axios';
import { validateRegex } from '../../utilities/FormValidations';
import ViewCityRewards from './ViewCityRewards';

const trimArrayElts = (inputArray) => inputArray.map((el) => el.trim());

function CreateCampaign(props) {
  const {
    show, onHide, handleRequestProcessing, editCampaign,
    upiOptions,
  } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [campaignName, setCampaignName] = useState('');
  const [campaignType, setCampaignType] = useState('single');
  const [fundedBy, setFundedBy] = useState('mpaani');
  const [isHomeDeliveryAllowed, setIsHomeDeliveryAllowed] = useState(true);
  const [isPickupFromStoreAllowed, setIsPickupFromStoreAllowed] = useState(true);
  const [isPublic, setIsPublic] = useState(true);
  const [description, setDescription] = useState('');
  const [deeplink, setDeeplink] = useState('');
  const [priority, setPriority] = useState(1);
  const [couponPrefix, setCouponPrefix] = useState('');
  const [totalCouponCodes, setTotalCouponCodes] = useState(1);
  const [maxUsagePerUser, setMaxUsagePerUser] = useState(1);
  const [validOnTransactions, setValidOnTransactions] = useState([]);
  const [validOnTransaction, setValidOnTransaction] = useState(-1);
  const [campaignApplicableOn, setCampaignApplicableOn] = useState('all');
  const [customerList, setCustomerList] = useState('');
  const [storeList, setStoreList] = useState('');
  const [isAllStoreExceptRestrictedStore, setIsAllStoreExceptRestrictedStore] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [imageUploadStatus, setImageUploadStatus] = useState('');
  const [validFrom, setValidFrom] = useState(new Date());
  const [validTill, setValidTill] = useState(new Date());
  const [invalidDateErrorMsg, setInvalidDateErrorMsg] = useState('');
  const [paymentMode, setPaymentMode] = useState(['cash', 'digital']);
  const [campaignPaymentMode, setCampaignPaymentMode] = useState('all');
  const [discountType, setDiscountType] = useState('percentage');
  const [discountValue, setDiscountValue] = useState(0);
  const [minSpendAmount, setMinSpendAmount] = useState(0);
  const [maxDiscount, setMaxDiscount] = useState(0);
  const [couponApplicableOn, setCouponApplicableOn] = useState('both');
  const [appVersion, setAppVersion] = useState('all');
  const [appVersionNumber, setAppVersionNumber] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const hiddenFileInput = React.createRef();
  const [isBrandCampaign, setIsBrandCampaign] = useState(false);
  const [isCategoryCampaign, setIsCategoryCampaign] = useState(false);
  const [isSubCategoryCampaign, setIsSubCategoryCampaign] = useState(false);
  const [isProductCampaign, setIsProductCampaign] = useState(false);
  const [brandId, setBrandId] = useState('');
  const [primaryCategoryCodes, setPrimaryCategoryCodes] = useState([]);
  const [subCategoryCodes, setSubCategoryCodes] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [citySpecificRewardsModal, setCitySpecificRewardsModal] = useState(false);
  const [showCitySpecificRewards, setShowCitySpecificRewards] = useState(true);
  const [selectedUPI, setSelectedUPI] = useState('');
  const [terms, setTerms] = useState([]);
  const [newTerm, setNewTerm] = useState('');

  const disabled = submitting || !campaignName || !couponPrefix
    || (couponApplicableOn === 'app' && appVersion === 'specific' && appVersionNumber === '')
    || (campaignPaymentMode === 'digital' && !selectedUPI);

  const handleLoad = () => {
    const { campaignId } = props;
    setLoading(true);
    setError(false);
    couponCampaigns(
      'GET',
      {},
      {},
      campaignId,
    ).then((res) => {
      const { data } = res;
      setCampaignName(data.campaignName);
      setCampaignType(data.campaignType);
      setFundedBy(data.fundedBy);
      setIsHomeDeliveryAllowed(data.isHomeDeliveryAllowed);
      setIsPickupFromStoreAllowed(data.isPickupFromStoreAllowed);
      setIsPublic(data.isPublic);
      setDescription(data.description);
      setDeeplink(data.deeplink);
      setPriority(data.priority);
      setCouponPrefix(data.couponPrefix);
      setTotalCouponCodes(data.totalCouponCodes);
      setMaxUsagePerUser(data.maxUsagePerUser);
      setValidOnTransactions(data.validOnTransactions);
      setValidOnTransaction(data.validOnTransaction);
      if (data.customerList.length > 0) {
        setCampaignApplicableOn('customer');
      }
      if (data.storeList.length > 0) {
        setCampaignApplicableOn('store');
      }
      setCustomerList((data.customerList).join(', '));
      setStoreList((data.storeList).join(', '));
      setIsAllStoreExceptRestrictedStore(data.isAllStoreExceptRestrictedStore);
      setImageUrl(data.imageUrl);
      setValidFrom(data.validFrom);
      setValidTill(data.validTill);
      setPaymentMode(data.paymentMode);
      setTerms(data.terms);
      if (data.paymentMode.length && data.paymentMode.length < 2) {
        setCampaignPaymentMode(data.paymentMode[0].toLowerCase());
      }
      setSelectedUPI(data.applicableDigitalPaymentSource);
      setDiscountType(data.discountType);
      setDiscountValue(data.discountValue);
      setMinSpendAmount(data.minSpendAmount);
      setMaxDiscount(data.maxDiscount);
      setCouponApplicableOn(data.couponApplicableOn);
      setAppVersion(data.appVersion);
      setAppVersionNumber(data.appVersionNumber);
      setShowCitySpecificRewards(data.showCitySpecificReward);
      if (data.brandIds && data.brandIds.some((el) => el !== null)) {
        setBrandId(data.brandIds.join());
        setIsBrandCampaign(true);
      }
      if (data.primaryCategoryCodes && data.primaryCategoryCodes.some((el) => el !== null)) {
        setPrimaryCategoryCodes(data.primaryCategoryCodes);
        setIsCategoryCampaign(true);
      }
      if (data.subCategoryCodes && data.subCategoryCodes.some((el) => el !== null)) {
        setSubCategoryCodes(data.subCategoryCodes);
        setIsSubCategoryCampaign(true);
      }
      if (data.productIds && data.productIds.some((el) => el !== null)) {
        setProductIds(data.productIds);
        setIsProductCampaign(true);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
      setError(true);
    });
  };

  useEffect(() => {
    if (editCampaign) {
      handleLoad();
    }
    // eslint-disable-next-line
  }, []);

  // Handle adding a new term to the array
  const addTerm = () => {
    if (newTerm.trim()) {
      setTerms([...terms, newTerm]);
      setNewTerm('');
    }
  };

  // Handle removing the latest term from the array
  const removeLastTerm = () => {
    setTerms(terms.slice(0, -1));
  };

  const reset = () => {
    setCampaignName('');
    setCampaignType('single');
    setFundedBy('mpaani');
    setIsHomeDeliveryAllowed(true);
    setIsPickupFromStoreAllowed(true);
    setIsPublic(true);
    setDescription('');
    setDeeplink('');
    setPriority(1);
    setCouponPrefix('');
    setTotalCouponCodes(1);
    setMaxUsagePerUser(1);
    setValidOnTransaction(-1);
    setValidOnTransactions([]);
    setCampaignApplicableOn('all');
    setCustomerList('');
    setStoreList('');
    setIsAllStoreExceptRestrictedStore(false);
    setImageUrl('');
    setImageUploadStatus('');
    setValidFrom(new Date());
    setValidTill(new Date());
    setInvalidDateErrorMsg('');
    setPaymentMode(['cash', 'digital']);
    setCampaignPaymentMode('all');
    setDiscountType('percentage');
    setDiscountValue(0);
    setMinSpendAmount(0);
    setMaxDiscount(0);
    setCouponApplicableOn('both');
    setAppVersion('all');
    setAppVersionNumber('');
    setSubmitting(false);
    setSubmitError('');
    setSelectedUPI('');
    setTerms([]);
    setNewTerm('');
  };

  const resetBrandCampaigningInputs = () => {
    setIsBrandCampaign(false);
    setIsCategoryCampaign(false);
    setIsSubCategoryCampaign(false);
    setIsProductCampaign(false);
    setBrandId('');
    setPrimaryCategoryCodes([]);
    setSubCategoryCodes([]);
    setProductIds([]);
    setSelectedUPI('');
  };

  function handleSubmit(e) {
    const { campaignId } = props;
    e.preventDefault();
    if (validFrom > validTill) {
      setInvalidDateErrorMsg('Please Check!! Valid From Date is Greater than Valid Till Date');
      return;
    }
    setInvalidDateErrorMsg('');
    setSubmitting(true);
    setSubmitError('');
    if (!isPublic) {
      setDescription('');
      setDeeplink('');
    }
    let customers = [];
    let stores = [];
    if (customerList) {
      customers = customerList.split(/[,\s\n]+/).filter((item) => item.trim());
    }
    if (storeList) {
      stores = storeList.split(/[,\s\n]+/).filter((item) => item.trim());
    }
    let data = {
      campaignName,
      campaignType,
      fundedBy,
      isHomeDeliveryAllowed,
      isPickupFromStoreAllowed,
      isPublic,
      description,
      deeplink,
      priority,
      couponPrefix,
      totalCouponCodes,
      maxUsagePerUser,
      validOnTransaction,
      validOnTransactions,
      customerList: customers,
      storeList: stores,
      isAllStoreExceptRestrictedStore,
      imageUrl,
      validFrom: `${moment(validFrom).format('YYYY-MM-DD')}`,
      validTill: `${moment(validTill).format('YYYY-MM-DD')}`,
      paymentMode,
      discountType,
      discountValue,
      maxDiscount,
      minSpendAmount,
      couponApplicableOn,
      brandId,
      primaryCategoryCodes,
      subCategoryCodes,
      productIds,
      applicableDigitalPaymentSource: selectedUPI,
      terms,
    };
    if (couponApplicableOn === 'app') {
      data = {
        ...data,
        appVersion,
      };
    }
    if (couponApplicableOn === 'app' && appVersion === 'specific') {
      data = {
        ...data,
        appVersionNumber,
      };
    }

    couponCampaigns(
      editCampaign ? 'PATCH' : 'POST',
      {},
      {
        ...data,
      },
      editCampaign ? campaignId : '',
    ).then((res) => {
      if (res.status === 200) {
        setSubmitting(false);
        reset();
        onHide();
        handleRequestProcessing();
      } else {
        throw new Error();
      }
      resetBrandCampaigningInputs();
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong';
      if (err.response.status === 400 && err.response.data.error) {
        errorMsg = err.response.data.error;
      }
      setSubmitting(false);
      setSubmitError(errorMsg);
      // resetBrandCampaigningInputs()
    });
  }

  function handleImageOnChange(event) {
    setImageUploadStatus('loading');
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('img', file);
    if (file) {
      uploadImage(
        'POST',
        formData,
      ).then((res) => {
        setImageUploadStatus('');
        setImageUrl(res.data.url);
      }).catch(() => {
        setImageUploadStatus('error');
      });
    }
  }

  const discountOptions = {
    key: 'discount',
    displayText: '',
    options: [
      {
        label: 'Percentage',
        value: 'percentage',
      },
      {
        label: 'Amount',
        value: 'amount',
      },
    ],
  };

  const body = (
    <Container>
      <Row>
        {(() => {
          let showcase = null;
          if (editCampaign && loading) {
            showcase = (
              <Col>
                <div
                  className="h-100 d-flex align-items-center
                  justify-content-center"
                >
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                </div>
              </Col>
            );
          } else if (editCampaign && !loading && error) {
            showcase = (
              <Col>
                <div
                  className="h-100 d-flex align-items-center
                  justify-content-center"
                >
                  <ErrorHandler
                    retryLogic={() => {
                      handleLoad();
                    }}
                  />
                </div>
              </Col>
            );
          } else {
            showcase = (
              <Col
                className="mx-3 py-3"
              >
                <Form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Campaign Name:</b>
                    </Form.Label>
                    <Col
                      sm={12}
                    >
                      <Form.Control
                        value={campaignName}
                        id="campaignName"
                        onChange={(e) => {
                          setCampaignName(e.target.value);
                        }}
                        autoComplete="off"
                        maxLength={128}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Campaign Type:</b>
                    </Form.Label>
                    <Col
                      xs={5}
                    >
                      <Form.Check
                        inline
                        label="Single"
                        type="radio"
                        id="single"
                        checked={campaignType === 'single'}
                        onChange={(e) => {
                          setCampaignType(e.target.id);
                          setTotalCouponCodes(1);
                        }}
                        className="fs-0"
                      />
                    </Col>
                    <Col
                      xs={5}
                    >
                      <Form.Check
                        inline
                        label="Multi"
                        type="radio"
                        id="multi"
                        checked={campaignType === 'multi'}
                        onChange={(e) => {
                          setCampaignType(e.target.id);
                          setIsPublic(false);
                        }}
                        className="fs-0"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Funded By:</b>
                    </Form.Label>
                    <Col
                      sm={12}
                    >
                      <Form.Control
                        value={fundedBy}
                        id="fundedBy"
                        onChange={(e) => {
                          setFundedBy(e.target.value);
                        }}
                        autoComplete="off"
                        maxLength={128}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Allowed for Home Delivery:</b>
                    </Form.Label>
                    <Col
                      sm={12}
                    >
                      <div
                        className="custom-control custom-switch"
                      >
                        <input
                          id="isHomeDeliveryAllowed"
                          type="checkbox"
                          className="custom-control-input"
                          checked={isHomeDeliveryAllowed}
                          onChange={() => {
                            setIsHomeDeliveryAllowed(!isHomeDeliveryAllowed);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="isHomeDeliveryAllowed"
                        />
                      </div>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Allowed for Pickup From Store:</b>
                    </Form.Label>
                    <Col
                      sm={12}
                    >
                      <div
                        className="custom-control custom-switch"
                      >
                        <input
                          id="isPickupFromStoreAllowed"
                          type="checkbox"
                          className="custom-control-input"
                          checked={isPickupFromStoreAllowed}
                          onChange={() => {
                            setIsPickupFromStoreAllowed(!isPickupFromStoreAllowed);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="isPickupFromStoreAllowed"
                        />
                      </div>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Is Public:</b>
                    </Form.Label>
                    <Col
                      sm={12}
                    >
                      <div
                        className="custom-control custom-switch"
                      >
                        <input
                          id="isPublic"
                          type="checkbox"
                          className="custom-control-input"
                          checked={isPublic}
                          onChange={() => {
                            if (campaignType === 'single') {
                              setIsPublic(!isPublic);
                            }
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="isPublic"
                        />
                      </div>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Product Campaign Type:</b>
                    </Form.Label>
                    <Col
                      xs={4}
                    >
                      <Form.Check
                        inline
                        label="Brand"
                        type="checkbox"
                        id="brand"
                        checked={isBrandCampaign}
                        onChange={(e) => {
                          setIsBrandCampaign(e.target.checked);
                          setBrandId('');
                        }}
                        className="fs-0"
                      />
                    </Col>
                    <Col
                      xs={4}
                    >
                      <Form.Check
                        inline
                        label="Categories"
                        type="checkbox"
                        id="categories"
                        checked={isCategoryCampaign}
                        onChange={(e) => {
                          setIsCategoryCampaign(e.target.checked);
                          setPrimaryCategoryCodes([]);
                        }}
                        className="fs-0"
                      />
                    </Col>
                    <Col
                      xs={4}
                    >
                      <Form.Check
                        inline
                        label="Sub Categories"
                        type="checkbox"
                        id="subcategories"
                        checked={isSubCategoryCampaign}
                        onChange={(e) => {
                          setIsSubCategoryCampaign(e.target.checked);
                          setSubCategoryCodes([]);
                        }}
                        className="fs-0"
                      />
                    </Col>
                    <Col
                      xs={4}
                    >
                      <Form.Check
                        inline
                        label="Products"
                        type="checkbox"
                        id="products"
                        checked={isProductCampaign}
                        onChange={(e) => {
                          setIsProductCampaign(e.target.checked);
                          setProductIds([]);
                        }}
                        className="fs-0"
                      />
                    </Col>
                  </Form.Group>

                  {
                    isBrandCampaign && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Brand Id:</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <Form.Control
                            type="number"
                            value={brandId}
                            id="brandId"
                            onChange={(e) => {
                              setBrandId(e.target.value);
                            }}
                            autoComplete="off"
                            placeholder="only one brand id"
                          />
                        </Col>
                      </Form.Group>
                    )
                  }

                  {
                    isCategoryCampaign && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Category Id's / Name:</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <Form.Control
                            value={primaryCategoryCodes}
                            id="categories"
                            onChange={(e) => {
                              setPrimaryCategoryCodes(trimArrayElts(e.target.value.split(',')));
                            }}
                            autoComplete="off"
                            placeholder="comma separated category id's"
                          />
                        </Col>
                      </Form.Group>
                    )
                  }

                  {
                    isSubCategoryCampaign && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Sub-Category Id's / Name:</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <Form.Control
                            value={subCategoryCodes}
                            id="subcategories"
                            onChange={(e) => {
                              setSubCategoryCodes(trimArrayElts(e.target.value.split(',')));
                            }}
                            autoComplete="off"
                            placeholder="comma separated sub-category id's"
                          />
                        </Col>
                      </Form.Group>
                    )
                  }

                  {
                    isProductCampaign && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Product Id's:</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <Form.Control
                            value={productIds}
                            id="category"
                            onChange={(e) => {
                              setProductIds(trimArrayElts(e.target.value.split(',')));
                            }}
                            autoComplete="off"
                            placeholder="comma separated product id's"
                          />
                        </Col>
                      </Form.Group>
                    )
                  }

                  {
                    isPublic && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Description:</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <Form.Control
                            value={description}
                            id="description"
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                            autoComplete="off"
                          />
                        </Col>
                      </Form.Group>
                    )
                  }

                  {
                    isPublic && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Deeplink:</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <Form.Control
                            value={deeplink}
                            id="deeplink"
                            onChange={(e) => {
                              setDeeplink(e.target.value);
                            }}
                            autoComplete="off"
                          />
                        </Col>
                      </Form.Group>
                    )
                  }

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Campaign Priority:</b>
                    </Form.Label>
                    <Col
                      sm={12}
                    >
                      <input
                        type="number"
                        value={priority}
                        id="priority"
                        onChange={(e) => {
                          setPriority(e.target.value);
                        }}
                        autoComplete="off"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Coupon Prefix:</b>
                    </Form.Label>
                    <Col
                      sm={12}
                    >
                      <Form.Control
                        value={couponPrefix}
                        id="couponPrefix"
                        onChange={(e) => {
                          setCouponPrefix(e.target.value);
                        }}
                        autoComplete="off"
                        maxLength={128}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>No. of Coupon:</b>
                    </Form.Label>
                    <Col
                      sm={12}
                    >
                      <input
                        type="number"
                        value={totalCouponCodes}
                        id="totalCouponCodes"
                        onChange={(e) => {
                          setTotalCouponCodes(e.target.value);
                        }}
                        autoComplete="off"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Max Usage Per User:</b>
                    </Form.Label>
                    <Col
                      sm={12}
                    >
                      <input
                        type="number"
                        value={maxUsagePerUser}
                        id="maxUsagePerUser"
                        onChange={(e) => {
                          setMaxUsagePerUser(e.target.value);
                        }}
                        autoComplete="off"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Valid On Transactions:</b>
                    </Form.Label>
                    <Col
                      sm={12}
                    >
                      <input
                        type="text"
                        value={validOnTransactions}
                        id="validOnTransactions"
                        onChange={(e) => {
                          setValidOnTransactions(trimArrayElts(e.target.value.split(',')));
                        }}
                        autoComplete="off"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Valid On Transaction:</b>
                    </Form.Label>
                    <Col
                      sm={12}
                    >
                      <input
                        type="number"
                        value={validOnTransaction}
                        id="validOnTransaction"
                        onChange={(e) => {
                          setValidOnTransaction(e.target.value);
                        }}
                        autoComplete="off"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Campaign For:</b>
                    </Form.Label>
                    <Col
                      xs={5}
                    >
                      <Form.Check
                        inline
                        label="All"
                        type="radio"
                        id="all"
                        checked={campaignApplicableOn === 'all'}
                        onChange={(e) => {
                          setCampaignApplicableOn(e.target.id);
                          setStoreList('');
                          setCustomerList('');
                        }}
                        className="fs-0"
                      />
                    </Col>
                    <Col
                      xs={5}
                    >
                      <Form.Check
                        inline
                        label="Customer"
                        type="radio"
                        id="customer"
                        checked={campaignApplicableOn === 'customer'}
                        onChange={(e) => {
                          setCampaignApplicableOn(e.target.id);
                          setStoreList('');
                        }}
                        className="fs-0"
                      />
                    </Col>
                    <Col
                      xs={5}
                    >
                      <Form.Check
                        inline
                        label="Retailer"
                        type="radio"
                        id="store"
                        checked={campaignApplicableOn === 'store'}
                        onChange={(e) => {
                          setCampaignApplicableOn(e.target.id);
                          setCustomerList('');
                        }}
                        className="fs-0"
                      />
                    </Col>
                  </Form.Group>

                  {
                    campaignApplicableOn === 'customer' && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Customer: </b>
                        </Form.Label>
                        <Col
                          sm={5}
                        >
                          <textarea
                            value={customerList}
                            id="customerList"
                            onChange={(e) => {
                              setCustomerList(e.target.value);
                            }}
                            autoComplete="off"
                            rows={4}
                            className="form-control"
                          />
                        </Col>
                      </Form.Group>
                    )
                  }

                  {
                    campaignApplicableOn === 'store' && (
                      <>
                        <Form.Group as={Row}>
                          <Form.Label column sm={6}>
                            <b>Store: </b>
                          </Form.Label>
                          <Col
                            sm={5}
                          >
                            <textarea
                              value={storeList}
                              id="storeList"
                              onChange={(e) => {
                                setStoreList(e.target.value);
                              }}
                              autoComplete="off"
                              rows={4}
                              className="form-control"
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                          <Form.Label column sm={6}>
                            <b>Added Stores:</b>
                          </Form.Label>
                          <Col
                            xs={5}
                          >
                            <Form.Check
                              inline
                              label="EXCLUDE"
                              type="radio"
                              checked={isAllStoreExceptRestrictedStore}
                              onChange={() => {
                                setIsAllStoreExceptRestrictedStore(true);
                              }}
                              className="fs-0"
                            />
                          </Col>
                          <Col
                            xs={5}
                          >
                            <Form.Check
                              inline
                              label="INCLUDE"
                              type="radio"
                              checked={!isAllStoreExceptRestrictedStore}
                              onChange={() => {
                                setIsAllStoreExceptRestrictedStore(false);
                              }}
                              className="fs-0"
                            />
                          </Col>
                        </Form.Group>
                      </>
                    )
                  }

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>
                        Upload Image:
                      </b>
                    </Form.Label>
                    <Col
                      sm={12}
                    >
                      <div>
                        <input
                          type="file"
                          className="d-none"
                          accept="image/*"
                          ref={hiddenFileInput}
                          onChange={(e) => {
                            handleImageOnChange(e);
                          }}
                        />
                        <Button
                          variant="primary"
                          className="fs-01 font-weight-bold"
                          onClick={() => {
                            hiddenFileInput.current.click();
                          }}
                          disabled={imageUploadStatus === 'loading'}
                        >
                          ADD IMAGE
                        </Button>
                      </div>
                      {
                        imageUploadStatus === 'loading' && (
                          <div className="pl-3 mt-1">
                            <Spinner
                              animation="border"
                              variant="primary"
                            />
                          </div>
                        )
                      }
                      {
                        imageUploadStatus === 'error' && (
                          <div className="text-danger mt-1">
                            Oops something went wrong
                          </div>
                        )
                      }
                      {
                        imageUrl && (
                          <img
                            src={imageUrl}
                            alt="Coupon"
                            width="316px"
                            className="py-1"
                          />
                        )
                      }
                    </Col>

                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Expiry Date:</b>
                    </Form.Label>
                    <Col
                      sm={7}
                    >
                      <div className="d-flex">
                        <span className="fs-0">Valid From</span>
                        <input
                          type="datetime-local"
                          className="form-control"
                          value={moment(validFrom).format('YYYY-MM-DDTHH:mm')}
                          id="validFrom"
                          onChange={(e) => {
                            setValidFrom(new Date(e.target.value));
                          }}
                          autoComplete="off"
                        />
                      </div>
                    </Col>
                    <Col
                      sm={7}
                    >
                      <div className="d-flex">
                        <span className="fs-0">Valid Till</span>
                        <input
                          type="datetime-local"
                          className="form-control"
                          value={moment(validTill).format('YYYY-MM-DDTHH:mm')}
                          id="validTill"
                          onChange={(e) => {
                            setValidTill(new Date(e.target.value));
                          }}
                          autoComplete="off"
                        />
                      </div>
                    </Col>
                  </Form.Group>
                  {
                    !!invalidDateErrorMsg && (
                      <div
                        className="d-flex align-item-center justify-content-center"
                      >
                        <Col sm={6} />
                        <span className="text-danger mt-n3 mb-2">
                          {invalidDateErrorMsg}
                        </span>
                      </div>
                    )
                  }

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Payment Mode:</b>
                    </Form.Label>
                    <Col
                      xs={5}
                    >
                      <Form.Check
                        inline
                        label="All Payment Modes"
                        type="radio"
                        id="all"
                        checked={campaignPaymentMode === 'all'}
                        onChange={(e) => {
                          setCampaignPaymentMode(e.target.id);
                          setPaymentMode(['cash', 'digital']);
                          setSelectedUPI('');
                        }}
                        className="fs-0"
                      />
                    </Col>
                    <Col
                      xs={5}
                    >
                      <Form.Check
                        inline
                        label="Digital Payment"
                        type="radio"
                        id="digital"
                        checked={campaignPaymentMode === 'digital'}
                        onChange={(e) => {
                          setCampaignPaymentMode(e.target.id);
                          setPaymentMode(['digital']);
                        }}
                        className="fs-0"
                      />
                    </Col>
                    <Col
                      xs={5}
                    >
                      <Form.Check
                        inline
                        label="Cash on Delivery"
                        type="radio"
                        id="cash"
                        checked={campaignPaymentMode === 'cash'}
                        onChange={(e) => {
                          setCampaignPaymentMode(e.target.id);
                          setPaymentMode(['cash']);
                          setSelectedUPI('');
                        }}
                        className="fs-0"
                      />
                    </Col>
                  </Form.Group>

                  {
                    campaignPaymentMode === 'digital' && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Set UPI Mode</b>
                        </Form.Label>
                        <Col sm={12}>
                          <Select
                            id="upiOptions"
                            onChange={(selectedUPIs) => {
                              setSelectedUPI(selectedUPIs.value);
                            }}
                            value={{
                              label: selectedUPI,
                              value: selectedUPI,
                            }}
                            options={[
                              ...((upiOptions || []).map((item) => ({
                                label: item.appName,
                                value: item.slug,
                              }))),
                            ]}
                          />
                        </Col>
                      </Form.Group>
                    )
                  }

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Terms & Conditions:</b>
                    </Form.Label>
                    <Col
                      sm={12}
                    >
                      {
                        terms?.length ? (
                          <Row>
                            <Col
                              sm={24}
                              className="mb-2"
                            >
                              {
                                terms.map((term, index) => (
                                  <Row>
                                    <Col
                                      sm={18}
                                      key={term}
                                      className="mb-1 fs-1 font-weignt-bold"
                                    >
                                      {`${index + 1} - ${term}`}
                                    </Col>
                                    {
                                      (terms.length === index + 1) && (
                                        <Col
                                          sm={6}
                                        >
                                          <Button
                                            variant="warning"
                                            block
                                            className="py-1 fs-0 font-weight-bold text-danger"
                                            onClick={() => {
                                              removeLastTerm();
                                            }}
                                          >
                                            Remove
                                          </Button>
                                        </Col>
                                      )
                                    }
                                  </Row>
                                ))
                              }
                            </Col>
                          </Row>
                        ) : ('')
                      }
                      <Row>
                        <Col
                          sm={18}
                        >
                          <Form.Control
                            value={newTerm}
                            id="newTerm"
                            onChange={(e) => {
                              setNewTerm(e.target.value);
                            }}
                            autoComplete="off"
                          />
                        </Col>
                        <Col
                          sm="auto"
                        >
                          <Button
                            variant="primary"
                            block
                            className="fs-0 py-1 font-weight-bold"
                            onClick={() => {
                              addTerm();
                            }}
                          >
                            ADD
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Set Discount:</b>
                    </Form.Label>
                    <Col
                      sm={6}
                    >
                      <CustomDropdown
                        item={discountOptions}
                        onChange={(item) => {
                          setDiscountType(item.discount);
                          if (item.discount === 'amount') {
                            setMaxDiscount(99999);
                            setDiscountValue(0);
                          } else {
                            setMaxDiscount(0);
                            setDiscountValue(0);
                          }
                        }}
                        selectedVal={discountType}
                        closeButton={false}
                      />
                    </Col>
                    <Col
                      sm={6}
                    >
                      <input
                        type="number"
                        value={discountValue}
                        id="discountValue"
                        onChange={(e) => {
                          setDiscountValue(e.target.value);
                        }}
                        autoComplete="off"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Min Spend Amount:</b>
                    </Form.Label>
                    <Col
                      sm={12}
                    >
                      <input
                        type="number"
                        value={minSpendAmount}
                        id="minSpendAmount"
                        onChange={(e) => {
                          setMinSpendAmount(e.target.value);
                        }}
                        autoComplete="off"
                      />
                    </Col>
                  </Form.Group>

                  {
                    discountType === 'percentage' && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>Max Discount:</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <input
                            type="number"
                            value={maxDiscount}
                            id="maxDiscount"
                            onChange={(e) => {
                              setMaxDiscount(e.target.value);
                            }}
                            autoComplete="off"
                          />
                        </Col>
                      </Form.Group>
                    )
                  }

                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Coupon For:</b>
                    </Form.Label>
                    <Col
                      xs={5}
                    >
                      <Form.Check
                        inline
                        label="Both"
                        type="radio"
                        id="both"
                        checked={couponApplicableOn === 'both'}
                        onChange={(e) => {
                          setCouponApplicableOn(e.target.id);
                        }}
                        className="fs-0"
                      />
                    </Col>
                    <Col
                      xs={5}
                    >
                      <Form.Check
                        inline
                        label="Web"
                        type="radio"
                        id="web"
                        checked={couponApplicableOn === 'web'}
                        onChange={(e) => {
                          setCouponApplicableOn(e.target.id);
                        }}
                        className="fs-0"
                      />
                    </Col>
                    <Col
                      xs={5}
                    >
                      <Form.Check
                        inline
                        label="App"
                        type="radio"
                        id="app"
                        checked={couponApplicableOn === 'app'}
                        onChange={(e) => {
                          setCouponApplicableOn(e.target.id);
                        }}
                        className="fs-0"
                      />
                    </Col>
                  </Form.Group>
                  {
                    showCitySpecificRewards && (
                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>City Specific Rewards:</b>
                      </Form.Label>
                      <Col sm={12}>
                        <Button
                          variant="primary"
                          className="fs-01 font-weight-bold"
                          onClick={() => {
                            setCitySpecificRewardsModal(true);
                          }}
                        >
                          VIEW
                        </Button>
                      </Col>
                    </Form.Group>
                    )
                  }
                  {
                    couponApplicableOn === 'app' && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>App Version:</b>
                        </Form.Label>
                        <Col
                          xs={4}
                        >
                          <Form.Check
                            inline
                            label="All"
                            type="radio"
                            id="all"
                            checked={appVersion === 'all'}
                            onChange={(e) => {
                              setAppVersion(e.target.id);
                            }}
                            className="fs-0"
                          />
                        </Col>
                        <Col
                          xs={4}
                        >
                          <Form.Check
                            inline
                            label="Specific"
                            type="radio"
                            id="specific"
                            checked={appVersion === 'specific'}
                            onChange={(e) => {
                              setAppVersion(e.target.id);
                            }}
                            className="fs-0"
                          />
                        </Col>
                      </Form.Group>
                    )
                  }

                  {
                    couponApplicableOn === 'app' && appVersion === 'specific' && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b>App Version Number:</b>
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <input
                            type="text"
                            value={appVersionNumber}
                            id="appVersionNumber"
                            onChange={(e) => {
                              if (validateRegex('versionNumber', e.target.value)) {
                                setAppVersionNumber(e.target.value);
                              }
                            }}
                            autoComplete="off"
                            maxLength={8}
                          />
                        </Col>
                      </Form.Group>
                    )
                  }

                  <Form.Group as={Row}>
                    <Form.Label column sm={6} />
                    <Col>
                      <Button
                        block
                        type="submit"
                        variant="primary"
                        disabled={disabled}
                      >
                        {editCampaign ? 'UPDATE' : 'CREATE'}
                      </Button>
                    </Col>
                  </Form.Group>

                  {
                    submitting && (
                      <div
                        className="d-flex align-item-center justify-content-center"
                      >
                        <Col sm={6} />
                        <Spinner
                          animation="border"
                          variant="primary"
                        />
                      </div>
                    )
                  }

                  {
                    !!submitError && (
                      <div
                        className="d-flex align-item-center justify-content-center"
                      >
                        <Col sm={6} />
                        <span className="text-danger">
                          {submitError}
                        </span>
                      </div>
                    )
                  }

                </Form>
              </Col>
            );
          }
          return showcase;
        })()}
      </Row>
    </Container>
  );

  return (
    <>
      { citySpecificRewardsModal && (
      <ViewCityRewards
        show
        onHide={() => {
          setCitySpecificRewardsModal(false);
          onHide();
        }}
      />
      )}
      <CustomModal
        show={show}
        title={editCampaign ? 'Edit Campaign' : 'Generate Campaign'}
        size="xl"
        body={body}
        onHide={() => {
          onHide();
          reset();
          resetBrandCampaigningInputs();
        }}
        closeButton
        backdrop
      />
    </>
  );
}

CreateCampaign.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  handleRequestProcessing: PropTypes.func.isRequired,
  editCampaign: PropTypes.bool,
  campaignId: PropTypes.string,
  upiOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

CreateCampaign.defaultProps = {
  editCampaign: false,
  campaignId: '',
};

export default CreateCampaign;
