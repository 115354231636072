import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal } from '../../../component/common';
import { rerouteOrderDisposition } from '../../../assets/api/axios';

const dispositions = [
  {
    label: 'Products out of stock',
    value: 'Products out of stock',
  },
  {
    label: 'Store not serviceable',
    value: 'Store not serviceable',
  },
  {
    label: 'Retailer not responding',
    value: 'Retailer not responding',
  },
  {
    label: 'Delivery boy is not available',
    value: 'Delivery boy is not available',
  },
  {
    label: 'Customer location is far away',
    value: 'Customer location is far away',
  },
  {
    label: 'Doesnt want to continue with LL',
    value: 'Doesnt want to continue with LL',
  },
  {
    label: 'Doesnt want to accept digital payment',
    value: 'Doesnt want to accept digital payment',
  },
  {
    label: 'Not ready to deliver',
    value: 'Not ready to deliver',
  },
  {
    label: 'Settlement Issues',
    value: 'Settlement Issues',
  },
  {
    label: 'Bill amount is very Less',
    value: 'Bill amount is very Less',
  },
  {
    label: 'Retailer not ready to accept the order',
    value: 'Retailer not ready to accept the order',
  },
  {
    label: 'Retailer out of town',
    value: 'Retailer out of town',
  },
  {
    label: 'Customer and Retailer locations are different',
    value: 'Customer and Retailer locations are different',
  },
  {
    label: 'Others',
    value: 'Others',
  },
];

function RerouteDisposition(props) {
  const {
    show, onHide, openReroutePage, orderId,
  } = props;
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const handleSubmit = () => {
    setSubmitting(true);
    const rerouteReason = otherReason ? `${reason}-${otherReason}` : reason;
    rerouteOrderDisposition(
      'POST',
      {
        referenceOrderId: orderId,
        disposition: `Reroute - ${rerouteReason}`,
      },
    ).then(() => {
      setSubmitting(false);
      onHide();
      openReroutePage();
    }).catch(() => {
      setSubmitting(false);
      setSubmitError('Oops something went wrong');
    });
  };

  const body = (
    <Container>
      <Row className="pt-2">
        <Col xs={24}>
          <b>Select Reroute Disposition</b>
        </Col>
        <Col xs={24} className="py-2">
          <select
            name="reason"
            className="form-control fs-1"
            onChange={(e) => {
              if (e.target.value !== '') {
                setReason(e.target.value);
                setOtherReason('');
                setSubmitError('');
              }
            }}
            value={reason}
          >
            <option value="">Select</option>
            {dispositions.map((disposition) => (
              <option key={disposition.value} value={disposition.value}>
                {disposition.label}
              </option>
            ))}
          </select>
        </Col>
        {
          reason === 'Others' && (
            <Col xs={24} className="py-2">
              <input
                type="text"
                value={otherReason}
                placeholder="Enter Other Reroute Reason"
                name="otherReason"
                className="form-control fs-1"
                onChange={(e) => setOtherReason(e.target.value)}
              />
            </Col>
          )
        }
      </Row>
      <Row className="py-2">
        <Col xs={24} className="d-flex">
          <Button
            variant="primary"
            block
            onClick={() => {
              handleSubmit();
            }}
            disabled={!reason || (reason === 'Others' && !otherReason) || submitting}
          >
            {
              submitting && (
                <>
                  <Spinner
                    variant="primary"
                    animation="border"
                    size="sm"
                  />
                  &nbsp;
                </>
              )
            }
            Submit
          </Button>
        </Col>
        {
          !!submitError && (
            <Col xs={24} className="text-center py-2">
              <span className="text-danger">
                {submitError}
              </span>
            </Col>
          )
        }
      </Row>
    </Container>
  );

  return (
    <CustomModal
      show={show}
      onHide={onHide}
      title="Reasons for Diverting Order"
      body={body}
      closeButton
      autoSize
    />
  );
}

RerouteDisposition.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  orderId: PropTypes.number.isRequired,
  openReroutePage: PropTypes.func.isRequired,
};

export default RerouteDisposition;
