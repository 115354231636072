/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Form, Button,
} from 'react-bootstrap';
import {
  customerAddress, order, cart as axiosCart, rewards,
  validateOrderId,
} from '../../assets/api/axios';
import Product from './Product';
import AddressForm from './AddressForm';
import CheckoutFromStores from './CheckoutFromStores';
import CustomProduct from './CustomProduct';
import Svg from '../../component/common/svg/EditIcon';
import { Constant } from '../../utilities';

const getCouponCode = (storeItems) => {
  let couponCode = '';
  for (let index = 0; index < storeItems.length; index += 1) {
    const element = storeItems[index];
    if (element.coupon.couponAppliedStatus) {
      couponCode = element.coupon.couponApplied.couponCode;
      break;
    }
  }
  return couponCode;
};

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    const { cartDetails, selectedAddress } = props;
    const couponCode = getCouponCode(cartDetails.storeItems);
    const isAddressTypeOther = selectedAddress.addressType !== 'Home' && selectedAddress.addressType !== 'Work';
    this.state = {
      deliveryOption: '',
      flatNumber: selectedAddress.flatNumber,
      buildingName: selectedAddress.buildingName,
      streetName: selectedAddress.streetName,
      localityName: selectedAddress.localityName,
      landmark: selectedAddress.landmark,
      addressType: isAddressTypeOther ? 'Other' : selectedAddress.addressType,
      otherAddressType: isAddressTypeOther ? selectedAddress.addressType : '',
      checkoutStores: cartDetails.storeItems.map((storeItem) => ({
        storeId: storeItem.store.code,
        paymentPreference: '',
        description: '',
      })),
      couponCode,
      couponApplied: couponCode !== '',
      couponErrors: [],
      orderRequest: false,
      errorMsg: '',
      deliveryOptionError: '',
      scCreationStatusMsg: '',
      redirectedOrderId: '',
      redirectedOrderIdError: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeAddressType = this.handleChangeAddressType.bind(this);
    this.handleSubmitAddress = this.handleSubmitAddress.bind(this);
    this.updateCheckoutStore = this.updateCheckoutStore.bind(this);
    this.createOrders = this.createOrders.bind(this);
    this.handleCouponApplication = this.handleCouponApplication.bind(this);
    this.handleChangeDeliveryOption = this.handleChangeDeliveryOption.bind(this);
    this.validateRedirectedOrderId = this.validateRedirectedOrderId.bind(this);
  }

  componentDidMount = () => {
    const { cartDetails } = this.props;
    this.setState(() => {
      if (
        cartDetails && cartDetails.cart
        && cartDetails.cart.deliveryPreference === 'PICKUP_FROM_STORE'
      ) {
        return {
          deliveryOption: 'PFS',
        };
      }
      return {
        deliveryOption: cartDetails.cart.deliveryPreference === 'HOME_DELIVERY' ? 'ADDRESS' : '',
      };
    });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { cartDetails } = nextProps;
    const newCouponCode = getCouponCode(cartDetails.storeItems);
    const { couponApplied, couponCode } = this.state;
    this.setState(() => {
      if (
        cartDetails && cartDetails.cart
        && cartDetails.cart.deliveryPreference === 'PICKUP_FROM_STORE'
      ) {
        return {
          deliveryOption: 'PFS',
        };
      }
      return {
        deliveryOption: cartDetails.cart.deliveryPreference === 'HOME_DELIVERY' ? 'ADDRESS' : '',
      };
    });
    if (couponApplied && couponCode === newCouponCode) {
      return;
    }
    this.setState({
      couponCode: newCouponCode,
      couponApplied: newCouponCode !== '',
    });
  }

  handleChange(event) {
    const { name, value } = event.target;
    const isCouponCode = name === 'couponCode';
    this.setState((state) => ({
      [name]: isCouponCode ? value.toUpperCase() : value,
      couponErrors: isCouponCode ? [] : state.couponErrors,
    }));
  }

  handleChangeAddressType(event) {
    this.setState({ addressType: event.target.id });
  }

  handleChangeDeliveryOption(event) {
    const {
      cartDetails, selectedCustomer, handleUpdateCartDetails,
    } = this.props;
    const { deliveryOption, orderRequest } = this.state;
    if (deliveryOption === event.target.id || orderRequest) {
      return;
    }
    this.setState({
      deliveryOptionError: '',
    });

    axiosCart(
      'patch',
      {
        action: 'PATCH_DELIVERY_PREFERENCE',
        body: {
          deliveryPreference: event.target.id === 'PFS'
            ? 'PICKUP_FROM_STORE'
            : 'HOME_DELIVERY',
        },
      },
      cartDetails.cart.id,
      selectedCustomer.id,
    )
      .then((res) => {
        handleUpdateCartDetails(res.data);
      })
      .catch(() => {
        this.setState({
          deliveryOptionError: 'Oops something went wrong!!',
        });
      });
  }

  handleSubmitAddress() {
    const {
      flatNumber, buildingName, streetName, localityName,
      landmark, addressType, otherAddressType,
    } = this.state;
    const { selectedAddress, handleChangeAddress } = this.props;
    customerAddress(
      'put',
      {
        ...selectedAddress,
        isCompleted: true,
        flatNumber,
        buildingName,
        streetName,
        localityName,
        landmark,
        addressType: addressType === 'Other' ? otherAddressType : addressType,
      },
      selectedAddress.locationId,
    ).then((res) => {
      handleChangeAddress(res.data, true);
    });
  }

  handleCouponApplication(apply = false) {
    const { couponCode, orderRequest } = this.state;
    if (orderRequest) {
      return;
    }
    this.setState({ orderRequest: true });
    const { cartDetails, selectedCustomer, handleUpdateCartDetails } = this.props;
    axiosCart(
      'patch',
      {
        action: apply ? 'PATCH_COUPON_APPLIED' : 'REMOVE_COUPON_APPLIED',
        body: apply ? ({
          couponCode,
        }) : ({}),
      },
      cartDetails.cart.id,
      selectedCustomer.id,
    ).then((res) => {
      this.setState({ orderRequest: false });
      handleUpdateCartDetails(res.data);
    }).catch((error) => {
      this.setState({
        orderRequest: false,
        couponErrors: error.response && error.response.data && error.response.data[0]
          ? error.response.data[0].server_message.split('|') : ['Error Occurred'],
      });
    });
  }

  earnScratchCard = (orderSessionId, customerId) => {
    const { modifyCurrentState } = this.props;
    let statusMsg = '';
    rewards(
      'post',
      {
        orderSessionId,
      },
      customerId,
    ).then((res) => {
      const { data } = res;
      statusMsg = data.isScratchCardMade
        ? 'Scratch Card generated successfully!'
        : 'Failed to generate scratch card!';
    }).catch(() => {
      statusMsg = 'Error while creating scratch card!';
    }).finally(() => {
      this.setState({
        orderRequest: true,
        scCreationStatusMsg: statusMsg,
      }, () => {
        setTimeout(() => {
          modifyCurrentState(true, true);
        }, 3000);
      });
    });
  }

  updateCheckoutStore(checkoutStore) {
    const { checkoutStores } = this.state;
    const storeIndex = checkoutStores.findIndex((store) => store.storeId === checkoutStore.storeId);
    this.setState((state) => ({
      checkoutStores: Object.assign([...state.checkoutStores], { [storeIndex]: checkoutStore }),
    }));
  }

  async validateRedirectedOrderId() {
    const { redirectedOrderId } = this.state;
    const { selectedCustomer } = this.props;
    let isValid = false;
    await validateOrderId(
      'GET',
      redirectedOrderId,
      {
        customerId: selectedCustomer.id,
      },
    ).then((res) => { isValid = res.data.isValid; });
    return isValid;
  }

  async createOrders(checkoutStore) {
    const {
      orderRequest, checkoutStores, deliveryOption,
      redirectedOrderId,
    } = this.state;
    if (orderRequest || !deliveryOption) {
      if (!deliveryOption) {
        this.setState({
          deliveryOptionError: 'No delivery option selected',
        });
      }
      return;
    }
    this.setState({
      redirectedOrderIdError: '',
      orderRequest: true,
    });
    const {
      cartDetails, selectedAddress, selectedCustomer,
      handleUpdateCartDetails,
    } = this.props;
    const {
      flatNumber, buildingName, streetName, localityName,
      landmark, addressType, addressText,
    } = selectedAddress;
    if (redirectedOrderId) {
      try {
        const isValidOrderId = await this.validateRedirectedOrderId(redirectedOrderId);
        if (!isValidOrderId) {
          this.setState({
            redirectedOrderIdError: 'Invalid Order Id.',
            orderRequest: false,
          });
          return;
        }
      } catch (e) {
        this.setState({
          redirectedOrderIdError: 'Error while validating order id.',
          orderRequest: false,
        });
        return;
      }
    }
    order(
      'post',
      {
        address: deliveryOption === 'PFS' ? null : {
          uid: selectedAddress.locationId,
          location: selectedAddress.location,
          label: addressType,
          googleAddress: addressText,
          flatNumber,
          buildingName,
          streetName,
          localityName,
          landmark,
        },
        body: checkoutStore ? [{ ...checkoutStore, pickupFromStore: deliveryOption === 'PFS' }] : checkoutStores.map((store) => ({
          ...store,
          pickupFromStore: deliveryOption === 'PFS',
        })),
        orderSessionId: cartDetails.cart.orderSessionId,
        source: 'ADMIN',
        previousOrderId: redirectedOrderId,
      },
      selectedCustomer.id,
    ).then((resp) => {
      if (resp.status === 200) {
        this.setState({ orderRequest: false });
        axiosCart('get', null, cartDetails.cart.id)
          .then((res) => {
            const cart = res.data;
            handleUpdateCartDetails(cart);
            if (cart.cart.productCount < 1) {
              this.earnScratchCard(cartDetails.cart.orderSessionId, selectedCustomer.id);
            }
          });
      } else {
        throw resp;
      }
    }).then(() => {
      this.setState({ orderRequest: false });
    }).catch((error) => {
      if (typeof error.text === 'function') {
        error.text().then((errorMessage) => {
          this.setState({
            errorMsg: errorMessage,
            orderRequest: false,
          });
        });
      } else if (error && error.text) {
        this.setState({
          errorMsg: error.text,
          orderRequest: false,
        });
      } else if (error.response) {
        this.setState({
          errorMsg: error.response.data[0].client_message,
          orderRequest: false,
        });
      } else {
        this.setState({
          errorMsg: 'Something went wrong',
          orderRequest: false,
        });
      }
    });
  }

  render() {
    const {
      cartDetails, selectedAddress, modifyCurrentState,
      confirmedDeliveryAddress, editCart, handleOpenCartView,
    } = this.props;
    const {
      checkoutStores, couponCode, couponApplied, couponErrors, orderRequest,
      deliveryOption, errorMsg, deliveryOptionError, scCreationStatusMsg,
      redirectedOrderIdError,
    } = this.state;
    return (
      <Row className="mx-0 py-3">
        <>
          {errorMsg
          && (
          <Col xs={24} lg={16} className="text-center p-3 mb-3 bg-danger">
            <b><i className="text-dark">{errorMsg}</i></b>
          </Col>
          )}
          {
            scCreationStatusMsg && (
              <Col xs={24} lg={16} className="text-green p-3 mb-3">
                <b><i className="text-green">{scCreationStatusMsg}</i></b>
              </Col>
            )
          }
          <Col xs={24} lg={16} className="d-flex">
            <div>
              <Form.Group controlId="addressType">
                <Form.Check
                  custom
                  inline
                  label={(<b>Deliver To Address</b>)}
                  type="radio"
                  id="ADDRESS"
                  checked={deliveryOption === 'ADDRESS'}
                  onChange={this.handleChangeDeliveryOption}
                />
              </Form.Group>
            </div>
            <div className="pl-3">
              <Form.Group controlId="addressType">
                <Form.Check
                  custom
                  inline
                  label={(<b>Store Pickup</b>)}
                  type="radio"
                  id="PFS"
                  checked={deliveryOption === 'PFS'}
                  onChange={this.handleChangeDeliveryOption}
                />
              </Form.Group>
            </div>
          </Col>
          {deliveryOptionError && (
            <Col
              xs={24}
              lg={16}
              className="text-danger"
            >
              <b>{deliveryOptionError}</b>
            </Col>
          )}
          <Col xs={24} lg={16}>
            {
            deliveryOption === 'PFS'
            || (selectedAddress.isCompleted && confirmedDeliveryAddress)
              ? (
                <CheckoutFromStores
                  cart={cartDetails}
                  checkoutStores={checkoutStores}
                  updateCheckoutStore={this.updateCheckoutStore}
                  createOrders={this.createOrders}
                  couponCodeApplied={couponApplied}
                  couponCode={couponCode}
                  handleChange={this.handleChange}
                  couponErrors={couponErrors}
                  redirectedOrderIdError={redirectedOrderIdError}
                  orderRequest={orderRequest}
                  modifyCurrentState={modifyCurrentState}
                  handleCouponApplication={this.handleCouponApplication}
                  deliveryOption={deliveryOption}
                />
              ) : (
                <AddressForm
                  handleChange={this.handleChange}
                  handleChangeAddressType={this.handleChangeAddressType}
                  handleSubmitAddress={this.handleSubmitAddress}
                  selectedAddress={selectedAddress}
                  {...this.state}
                />
              )
            }
          </Col>
          <Col
            xs={24}
            lg={8}
            className="pl-lg-0 py-3"
          >
            <div className="shadow bg-white p-3">
              <Row>
                <Col>
                  <h5>Your Cart</h5>
                </Col>
                {
                  editCart && (
                    <Col className="d-flex justify-content-end">
                      <Button
                        variant="success"
                        className="py-1 fs-1"
                        onClick={() => { handleOpenCartView(true); }}
                      >
                        Edit
                        &nbsp;
                        <Svg
                          svg="editIcon"
                          height={22}
                          width={22}
                          stroke={Constant.Color.WHITE}
                        />
                      </Button>
                    </Col>
                  )
                }
              </Row>
              <hr />
              <Row>
                {
                  cartDetails.storeItems.map((storeItem) => (
                    <Col key={storeItem.store.code} xs={24} className="mt-2">
                      <b className="fs-2">{storeItem.store.displayName}</b>
                      <Row>
                        {
                          storeItem.products.map((product) => (
                            <Product key={product.id} product={product} />
                          ))
                        }
                      </Row>
                      {
                        !!storeItem.customProducts.length && (
                          <Row
                            className="bg-warning-lighter py-3"
                          >
                            <Col
                              xs={24}
                              className="text-success text-center font-weight-bold"
                            >
                              Custom Item(s)
                            </Col>
                            {
                              storeItem.customProducts.map((product) => (
                                <CustomProduct key={product.id} product={product} />
                              ))
                            }
                          </Row>
                        )
                      }
                    </Col>
                  ))
                }
              </Row>
            </div>
          </Col>
        </>
      </Row>
    );
  }
}

Checkout.propTypes = {
  cartDetails: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    storeItems: PropTypes.array,
    cart: PropTypes.shape({
      id: PropTypes.number,
      orderSessionId: PropTypes.number,
      deliveryPreference: PropTypes.string,
    }),
  }).isRequired,
  selectedAddress: PropTypes.shape({
    locationId: PropTypes.number,
    isCompleted: PropTypes.bool,
    location: PropTypes.shape({}),
    flatNumber: PropTypes.string,
    buildingName: PropTypes.string,
    streetName: PropTypes.string,
    localityName: PropTypes.string,
    landmark: PropTypes.string,
    addressType: PropTypes.string,
    addressText: PropTypes.string,
  }).isRequired,
  selectedCustomer: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  confirmedDeliveryAddress: PropTypes.bool.isRequired,
  handleChangeAddress: PropTypes.func.isRequired,
  handleUpdateCartDetails: PropTypes.func.isRequired,
  modifyCurrentState: PropTypes.func.isRequired,
  editCart: PropTypes.bool,
  handleOpenCartView: PropTypes.func,
};

Checkout.defaultProps = {
  editCart: false,
  handleOpenCartView: () => {},
};

export default Checkout;
