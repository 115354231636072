import React from 'react';
import Axios from 'axios';
import QueryString from 'query-string';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
  Form, InputGroup,
} from 'react-bootstrap';
import {
  CustomDropdown, CustomModal, CustomTable, DatePicker, Svg,
} from '../../component/common';
import { curatedGroupBackgroundJobs } from '../../assets/api/axios';
import '../../assets/scss/SmartCatalogueManagement/BackgroundJobs.scss';
import { Constant } from '../../utilities';
import { dateString } from '../../utilities/Utils';
import permission from '../../access&permissions/permission';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    startDate,
    endDate,
    l,
    p,
  } = param;
  const {
    selectedJobType = '',
    mainJobId = '',
    parentJobType = '',
    parentJobId = '',
    searchText = '',
    searchJobId = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  startDate = Number(startDate) || '';
  endDate = Number(endDate) || '';
  return ({
    ...param,
    l,
    p,
    selectedJobType,
    mainJobId,
    parentJobType,
    parentJobId,
    startDate,
    endDate,
    searchText,
    searchJobId,
  });
};

class BackgroundJobs extends React.Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    const { userPermission } = this.props;
    this.canEdit = userPermission.includes(
      permission.BACKGROUND_JOBS_WRITE,
    );
    this.state = {
      jobTypes: [],
      jobs: [],
      param,
      hasNext: false,
      hasPrevious: false,
      rowsPerPage: param.l,
      page: param.p,
      urlParams: param,
      loading: true,
      error: false,
      jobsMetaData: null,
      viewJobsMetaDataModal: false,
      jobsMetaDataLoadStatus: '',
      submitting: false,
      submitError: false,
      submitErrorMsg: '',
      actionSuccessMsg: false,
      selectedJobToPerformAction: null,
      showPerformingActionModal: false,
      action: '',
      reason: '',
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.loadJobTypes();
  }

  componentDidUpdate = () => {
    const { history } = this.props;
    const { urlParams, param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if ((Object.keys(newParam).find((key) => (
      newParam[key]
      && (urlParams[key] !== newParam[key])
      && (param[key] === urlParams[key])
    )))
    ) {
      this.handleLoad(newParam);
    }
  }

  loadJobTypes = () => {
    curatedGroupBackgroundJobs(
      'GET',
      {
        view: 'JOB_TYPES_LIST',
      },
    ).then((res) => {
      const { param } = this.state;
      const { selectedJobType } = param;
      this.setState({
        jobTypes: res.data.results,
      }, this.handleLoad({
        selectedJobType: selectedJobType || res.data.results[0].code || '',
      }));
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.loadJobTypes();
      });
    };
  }

  handleLoad = (data = {}) => {
    const { param } = this.state;
    const { history } = this.props;
    const { pathname } = history.location;
    const newParams = { ...param, ...data };
    const {
      l, p, selectedJobType, mainJobId, startDate, endDate,
      searchText, searchJobId,
    } = newParams;
    const offset = (p - 1) * l;
    curatedGroupBackgroundJobs(
      'GET',
      {
        view: 'DEFAULT',
        offset: `${offset}`,
        limit: l,
        jobType: selectedJobType,
        mainJobId,
        startDate,
        endDate,
        searchText,
        searchJobId,
      },
      null,
      this.source.token,
    ).then((res) => {
      this.setState({
        jobs: res.data.results,
        jobsCount: res.data.count,
        hasNext: res.data.hasNext,
        hasPrevious: res.data.hasPrev,
        rowsPerPage: l,
        page: p,
        param: newParams,
        loading: false,
      }, () => {
        Object.keys(newParams).forEach((item) => {
          if (!newParams[item]) {
            delete newParams[item];
          }
        });
        history.replace({
          path: pathname,
          search: QueryString.stringify(newParams),
        });
        this.setState({
          urlParams: newParams,
        });
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad(data);
      });
    };
  }

  handleProcessing = (data) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      loading: true,
      error: false,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleDropdownChange = (data) => {
    this.handleProcessing(data);
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleOnChange = (name, value) => {
    this.setState((state) => ({
      param: {
        ...state.param,
        [name]: value,
      },
    }));
  }

  handleSubmitSearchText = (name, value) => {
    const { history } = this.props;
    const { param } = this.state;
    const params = getQueryParams(history.location.search);
    if (params[name] !== value) {
      this.handleProcessing({
        ...param,
        [name]: value,
      });
    }
  }

  handleDateChange = (startDate, endDate) => {
    const { param } = this.state;
    if (
      param.startDate !== startDate
      || param.endDate !== endDate
    ) {
      this.handleProcessing({
        startDate,
        endDate,
        p: 1,
      });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleProcessing({ p: page });
    }
  }

  loadJobMetaData = (mainJobId, jobType) => {
    curatedGroupBackgroundJobs(
      'GET',
      {
        jobType,
        mainJobId,
        view: 'META',
      },
      null,
      this.source.token,
    ).then((res) => {
      this.setState({
        jobsMetaDataLoadStatus: '',
        jobsMetaData: res.data.results,
      });
    }).catch(() => {
      this.setState({
        jobsMetaDataLoadStatus: 'error',
      });
    });
    this.retry = () => {
      this.setState({
        jobsMetaDataLoadStatus: 'loading',
      }, () => {
        this.loadJobMetaData(mainJobId, jobType);
      });
    };
  }

  getDynamicUL = (item) => {
    if (typeof item.value === 'string' || typeof item.value === 'number') {
      return (
        <li key={item.key}>
          <span className="text-medium">{item.key}</span>
          :&nbsp;
          {item.value}
        </li>
      );
    }
    return (
      <ul>
        <li key={item.key}>
          <span className="text-medium">{item.key}</span>
          :&nbsp;
          <ul className="m-0 p-0">
            {(item.value).map((i) => this.getDynamicUL(i))}
          </ul>
        </li>
      </ul>
    );
  }


  performAction = () => {
    this.setState({
      submitting: true,
      submitError: false,
      submitErrorMsg: '',
      actionSuccessMsg: false,
    });
    const {
      selectedJobToPerformAction, action, reason,
    } = this.state;
    const body = {
      action,
      reason,
      jobId: selectedJobToPerformAction.mainJobId,
      subJobId: selectedJobToPerformAction.subJobId,
      jobType: selectedJobToPerformAction.jobType,
    };
    curatedGroupBackgroundJobs(
      'POST',
      {},
      body,
      this.source.token,
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          submitting: false,
          selectedJobToPerformAction: null,
          showPerformingActionModal: false,
          reason: '',
          action: '',
          actionSuccessMsg: true,
        }, () => {
          setTimeout(() => {
            this.setState({
              actionSuccessMsg: false,
            });
          }, 5000);
        });
      } else {
        throw new Error();
      }
    }).catch((err) => {
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.server_response
      ) {
        this.setState({
          submitError: true,
          submitting: false,
          submitErrorMsg: err.response.data.server_response,
        });
        return;
      }
      this.setState({
        submitError: true,
        submitting: false,
        submitErrorMsg: 'Oops! Something went wrong!',
      });
    });
  }

  getCellData = (field, value) => {
    if (field.renderer) {
      return field.renderer(value);
    }
    return value;
  }

  render() {
    const {
      jobTypes, param, jobs, loading, error,
      jobsMetaData, viewJobsMetaDataModal, jobsMetaDataLoadStatus,
      submitting, submitError, submitErrorMsg,
      actionSuccessMsg, selectedJobToPerformAction,
      showPerformingActionModal, action, reason,
      urlParams, hasNext, hasPrevious, rowsPerPage, page,
      jobsCount,
    } = this.state;

    const { history } = this.props;
    const { pathname } = history.location;

    const filterConf = [
      {
        key: 'selectedJobType',
        displayText: 'Select Job Type',
        options: (
          jobTypes.map((item) => ({
            label: item.jobTitle,
            value: item.code,
          }))
        ),
      },
    ];

    const showFields = [
      {
        key: 'jobTitle',
        displayText: 'Job Title',
      },
      {
        key: 'jobCreatedBy',
        displayText: 'Created By',
      },
      {
        key: 'currentStatus',
        displayText: 'Current Status',
        renderer: (value) => (
          <span className={`${value === 'FAILED' ? 'text-danger' : ''}${value === 'PROCESSING' || value === 'CHILD_JOB_PROCESSING' ? 'text-green' : ''}`}>
            {value}
          </span>
        ),
      },
      {
        key: 'mainJobId',
        displayText: 'Main Job ID',
      },
      {
        key: 'jobCreatedOn',
        displayText: 'Created On',
        renderer: (value) => (
          dateString(value)
        ),
      },
      {
        key: 'jobLastUpdatedAt',
        displayText: 'Last Updated On',
        renderer: (value) => (
          dateString(value)
        ),
      },
      {
        key: 'lastSyncType',
        displayText: 'Last Sync Type',
      },
    ];


    if (loading || error) {
      return (
        <div
          className="pt-3 text-center"
        >
          {loading ? (
            <Spinner
              animation="border"
              variant="primary"
            />
          ) : (
            <>
              <span
                className="text-danger"
              >
                Something Went Wrong
              </span>
              <div>
                <Button
                  variant="primary"
                  onClick={() => this.retry()}
                >
                  Retry
                </Button>
              </div>
            </>
          )}
        </div>
      );
    }
    const seachBars = [
      (
        <Form.Control
          type="text"
          placeholder="Search text"
          name="searchText"
          className="fs-01 rounded-0"
          value={param.searchText}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              this.handleSubmitSearchText(event.target.name, event.target.value);
            }
          }}
          onChange={(e) => { this.handleOnChange(e.target.name, e.target.value); }}
          autoComplete="off"
        />
      ),
      (
        <Form.Control
          type="text"
          placeholder="Job Id"
          name="searchJobId"
          className="fs-01 rounded-0"
          value={param.searchJobId}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              this.handleSubmitSearchText(event.target.name, event.target.value);
            }
          }}
          onChange={(e) => { this.handleOnChange(e.target.name, e.target.value); }}
          autoComplete="off"
        />
      ),
    ];

    const headers = [
      {
        key: 'mainJobId',
        displayText: '',
        renderer: (item) => (
          <Col
            key={item.mainJobId}
            xs={24}
            className="border border-black border-radius-4 my-2 p-2"
          >
            <Row className="mb-2 mx-0">
              <Col xs={12} className="px-2">
                <Row className="fs-0 font-weight-bold mx-0 mb-2">
                      Job Details:
                </Row>
                <Row>
                  {
                    showFields.map((field) => (
                      <Col key={field.key} xs={24} lg={12} className="fs-0 pb-2">
                        <span className="text-medium">{field.displayText}</span>
                        :&nbsp;
                        {this.getCellData(field, item[field.key])}
                      </Col>
                    ))
                  }
                </Row>
              </Col>
              <Col
                xs={12}
                className=""
              >
                <Row className="fs-0 font-weight-bold mx-0 mb-2">
                      Children Jobs:
                </Row>
                <Row className="mx-0 cursor-pointer text-primary">
                  {
                    item.childrenJobs.map((child) => (
                      <Col
                        xs={24}
                        key={child.mainJobId}
                        className="fs-0 pb-2"
                        onClick={() => {
                          this.setState({
                            jobs: null,
                            loading: true,
                            error: false,
                          }, () => {
                            history.push({
                              path: pathname,
                              search: QueryString.stringify({
                                ...urlParams,
                                selectedJobType: child.jobType,
                                mainJobId: child.mainJobId,
                                parentJobType: item.jobType,
                                parentJobId: item.mainJobId,
                              }),
                            });
                          });
                        }}
                      >
                        --&nbsp;
                        <span className="">{child.jobTitle}</span>
                        :&nbsp;
                        {child.Status}
                      </Col>
                    ))
                  }
                </Row>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col
                xs={24}
                md={12}
                className="p-2 border"
              >
                <Row className="font-weight-bold mx-0">
                      Inputs:
                </Row>
                <Row className="mx-0">
                  <ul className="mx-0">
                    {
                          item.input.map((ip) => (
                            this.getDynamicUL(ip)
                          ))
                        }
                  </ul>
                </Row>
              </Col>
              <Col
                xs={24}
                md={12}
                className=" p-2 border"
              >
                <Row className="font-weight-bold mx-0">
                      Feedbacks:
                </Row>
                <Row className="mx-0">
                  <ul className="mx-0">
                    {
                          item.output.map((ip) => (
                            this.getDynamicUL(ip)
                          ))
                        }
                  </ul>
                </Row>
              </Col>
            </Row>
            <Row className="mx-0 mb-2 d-flex flex-row-reverse align-items-center">
              <Col
                xs="auto"
                className="px-2 py-2"
              >
                {
                  this.canEdit && (
                    <CustomDropdown
                      className="form-control fs-01 minw-120p"
                      onChange={(data) => {
                        if (!data.action) {
                          return;
                        }
                        this.setState({
                          selectedJobToPerformAction: item,
                          showPerformingActionModal: true,
                          action: data.action,
                          reason: '',
                        });
                      }}
                      selectedVal={
                        selectedJobToPerformAction
                        && selectedJobToPerformAction.mainJobId === item.mainJobId
                          ? action : ''
                      }
                      disabled={!item.allowedAction.length}
                      item={{
                        key: 'action',
                        displayText: 'Other Actions',
                        options: item.allowedAction.map((allowedact) => ({
                          label: allowedact,
                          value: allowedact,
                        })),
                      }}
                    />
                  )
                }
              </Col>
              <Col
                xs="auto"
                className="px-0 py-2"
              >
                <Button
                  variant="primary"
                  className="fs-01 font-weight-bold"
                  onClick={() => {
                    this.setState({
                      jobsMetaData: null,
                      viewJobsMetaDataModal: true,
                      jobsMetaDataLoadStatus: 'loading',
                    }, this.loadJobMetaData(item.mainJobId, item.jobType));
                  }}
                >
                      View Meta
                </Button>
              </Col>
              {
                actionSuccessMsg && (
                  <Col
                    xs="auto"
                    className="px-4 py-2"
                  >
                    <div className="text-success">
                      Action Performed SuccessFully!!
                    </div>
                  </Col>
                )
              }
            </Row>
          </Col>

        ),
      },
    ];

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        <CustomModal
          show={viewJobsMetaDataModal}
          onHide={() => {
            this.setState({
              viewJobsMetaDataModal: false,
              jobsMetaData: null,
            });
          }}
          title="Background Job Meta Data"
          closeButton
          size="lg"
          body={(
            <Container>
              {
                jobsMetaDataLoadStatus === 'loading' && (
                  <div
                    className="pt-3 text-center"
                  >
                    <Spinner
                      animation="border"
                      variant="primary"
                    />
                  </div>
                )
              }
              {
                jobsMetaDataLoadStatus === 'error' && (
                  <div
                    className="pt-3 text-center text-danger"
                  >
                    Something Went Wrong
                    <Button
                      variant="primary"
                      onClick={() => this.retry()}
                      className="ml-3"
                    >
                      Retry
                    </Button>
                  </div>
                )
              }
              {
                jobsMetaData && (jobsMetaData).map((item) => (
                  <Row key={item.key}>
                    <Col xs={8} className="border py-1">
                      {item.key}
                    </Col>
                    <Col xs={8} className="border py-1">
                      {item.value}
                    </Col>
                    <Col xs={8} className="border py-1 text-truncate-2">
                      {
                        item.href
                          ? (
                            <a
                              href={item.href || ''}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: item.color,
                              }}
                            >
                              {item.href}
                              <span className="pl-2">&#8599;</span>
                            </a>
                          ) : (
                            <span>NA</span>
                          )
                      }
                    </Col>
                  </Row>
                ))
              }
            </Container>
          )}
        />
        <CustomModal
          show={showPerformingActionModal}
          title=""
          onHide={() => {
            this.setState({
              showPerformingActionModal: false,
              selectedJobToPerformAction: null,
              submitError: false,
              reason: '',
              action: '',
            });
          }}
          autoSize
          closeButton
          body={(
            <Container>
              <Row
                className="pt-3"
              >
                <Col
                  xs={24}
                  className="mb-2"
                >
                  <b>Reason:</b>
                </Col>
                <Col
                  xs={24}
                  className="px-3"
                >
                  <textarea
                    rows="3"
                    className="form-control"
                    name="reason"
                    value={reason}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col
                  xs={24}
                  className="text-center py-4 d-flex flex-row-reverse align-items-center"
                >
                  <Button
                    variant="primary"
                    className="px-3 py-2 fs-01 ml-2"
                    disabled={
                      submitting
                      || !reason
                    }
                    onClick={this.performAction}
                  >
                    {
                      submitting && (
                        <Spinner
                          variant="light"
                          size="sm"
                          animation="border"
                        />
                      )
                    }
                    &nbsp;&nbsp;&nbsp;SUBMIT&nbsp;&nbsp;&nbsp;
                  </Button>
                  <Button
                    variant="link"
                    className="text-primary font-weight-bold px-3 py-2 fs-01"
                    disabled={submitting}
                    onClick={() => {
                      this.setState({
                        showPerformingActionModal: false,
                        selectedJobToPerformAction: null,
                        submitError: false,
                        reason: '',
                        action: '',
                      });
                    }}
                  >
                    {
                      submitting && (
                        <Spinner
                          variant="light"
                          size="sm"
                          animation="border"
                        />
                      )
                    }
                    &nbsp;&nbsp;&nbsp;CANCEL&nbsp;&nbsp;&nbsp;
                  </Button>
                  {submitError && (
                    <Col
                      xs="auto"
                      className="text-center text-danger"
                    >
                      <b>
                        {
                          submitErrorMsg || 'Oops Something went Wrong!!'
                        }
                      </b>
                    </Col>
                  )}
                </Col>
              </Row>
            </Container>
          )}
        />
        <Row className="mx-0 mb-3">
          {
            seachBars.map((item) => (
              <Col
                xs="auto"
                className="px-2 pt-2"
              >
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className="rounded-0"
                    >
                      <Svg
                        svg="search"
                        width="1rem"
                        fill={Constant.Color.DARK}
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  {item}
                </InputGroup>
              </Col>
            ))
          }
          <Col
            xs="auto"
            className="px-2 pt-2"
          >
            <DatePicker
              isDateRange
              onApply={(dateRange) => {
                this.handleDateChange(
                  new Date(dateRange.startDate).getTime(),
                  new Date(dateRange.endDate).getTime(),
                );
              }}
              startDate={param.startDate}
              endDate={param.endDate}
              onClear={() => {
                this.handleProcessing({
                  startDate: '', endDate: '',
                });
              }}
            />
          </Col>
          {
            filterConf.map((item) => (
              <Col
                key={item.key}
                xs="auto"
                className="px-2 pt-2"
              >
                <CustomDropdown
                  item={item}
                  onChange={this.handleDropdownChange}
                  selectedVal={param[item.key]}
                />
              </Col>
            ))
          }
        </Row>
        {
          !!param.parentJobId && (
            <Row className="px-2">
              <Col xs={24}>
                <Row
                  className="fs-0 font-weight-bold mx-0 mb-2 cursor-pointer"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <Svg
                    svg="arrow"
                    width="1rem"
                    fill={Constant.Color.PRIMARY}
                  />
                  <span className="pl-3">
                    Parent Job Details:
                  </span>
                </Row>
                <Row>
                  <Col xs={24} md={12} lg={6} className="fs-0 pb-2">
                    <span className="text-medium">Job Type</span>
                    :&nbsp;
                    {param.parentJobType}
                  </Col>
                  <Col xs={24} md={12} lg={6} className="fs-0 pb-2">
                    <span className="text-medium">Main Job Id</span>
                    :&nbsp;
                    {param.parentJobId}
                  </Col>
                </Row>
              </Col>
            </Row>
          )
        }
        <Row
          className="mx-0 jobs-div my-2"
        >
          <CustomTable
            headers={headers}
            content={jobs}
            keyField="mainJobId"
            l={param.l}
            p={param.p}
            rowsPerPage={rowsPerPage}
            page={page}
            totalItems={jobsCount}
            hasPrev={hasPrevious}
            hasNext={hasNext}
            showHeader={false}
            onNext={this.onNext}
            onPrev={this.onPrev}
            onSubmitPage={this.onSubmitPage}
            onSubmitRowsPerPage={this.onSubmitRowsPerPage}
            updateRowsPageInput={this.handleRowsPageInput}
          />
        </Row>
      </Container>
    );
  }
}

BackgroundJobs.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
    replace: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default BackgroundJobs;
