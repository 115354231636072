import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import moment from 'moment';
import { CustomModal } from '../../component/common';
import { resurrectionStores } from '../../assets/api/axios';
import { validateRegex } from '../../utilities/FormValidations';

function CreateChurn(props) {
  const { show, onHide, handleRequestProcessing } = props;
  const [storeId, setStoreId] = useState('');
  const [turnOnDate, setTurnOnDate] = useState('');
  const [turnOffType, setTurnOffType] = useState('');
  const [turnOffReason, setTurnOffReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const disabled = !storeId || storeId.length < 4 || !turnOffType || !turnOffReason
    || (turnOffType === 'TEMPORARY' && !turnOnDate) || loading;

  function handleSubmit() {
    setLoading(true);
    setError('Oops Something Went Wrong!! Please Try Again!!');
    let data = {
      storeCode: storeId,
      turnOffType,
      comment: turnOffReason,
      source: 'CRM',
    };
    if (turnOnDate) {
      data = {
        ...data,
        expectedTurnOnDate: turnOnDate,
      };
    }
    resurrectionStores(
      'POST',
      data,
    ).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        onHide();
        handleRequestProcessing();
      } else {
        throw new Error();
      }
    }).catch((err) => {
      if (
        err
        && err.response
        && err.response.status === 400
        && err.response.data
        && err.response.data.errors
        && err.response.data.errors[0].message
      ) {
        setErrorMsg(err.response.data.errors[0].message);
      }
      setLoading(false);
      setError(true);
    });
  }

  const body = (
    <Container>
      <Row>
        <Col
          xs={24}
          className="py-3 border"
        >
          <Row>
            <Col xs={8}>
              Store ID
              <span className="text-danger">*</span>
            </Col>
            <Col xs={16}>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Store ID"
                value={storeId}
                onChange={(event) => {
                  if (validateRegex('integerValue', event.target.value)) {
                    setStoreId(event.target.value);
                  }
                }}
                maxLength="6"
              />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col xs={8}>
              Turn On Date
              {
                turnOffType === 'TEMPORARY' && (
                  <span className="text-danger">*</span>
                )
              }
            </Col>
            <Col xs={16}>
              <input
                className="form-control rounded-0 fs-1 bg-light"
                type="datetime-local"
                name="nextCallOn"
                onChange={(event) => {
                  setTurnOnDate(new Date(event.target.value).getTime() / 1000);
                }}
                min={moment(new Date()).format('YYYY-MM-DDThh:mm')}
              />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col xs={8}>
              Turn Off Type
              <span className="text-danger">*</span>
            </Col>
            <Col xs={16}>
              <select
                onChange={(e) => {
                  if (e.target.value !== '') {
                    setTurnOffType(e.target.value);
                  }
                }}
                value={turnOffType}
                className="form-control"
              >
                <option value="">Select</option>
                <option value="TEMPORARY">Temporary</option>
                <option value="PERMANENT">Permanent</option>
              </select>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col xs={8}>
              Turn Off Reason
              <span className="text-danger">*</span>
            </Col>
            <Col xs={16}>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Turn Off Reason"
                value={turnOffReason}
                onChange={(event) => {
                  setTurnOffReason(event.target.value);
                }}
              />
            </Col>
          </Row>
          <Row
            className="pt-4 d-flex align-item-center
              justify-content-center"
          >
            <Col
              xs="auto"
            >
              <Button
                variant="primary"
                className="py-1 px-4"
                disabled={disabled}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
          {
            loading && (
              <Row
                className="pt-2 d-flex align-item-center
                  justify-content-center"
              >
                <Spinner
                  variant="primary"
                  animation="border"
                />
              </Row>
            )
          }
          {
            !loading && error && (
              <Row
                className="pt-2 d-flex align-item-center
                  justify-content-center text-danger"
              >
                {errorMsg}
              </Row>
            )
          }
        </Col>
      </Row>
    </Container>
  );
  return (
    <CustomModal
      show={show}
      title="Add Churn"
      size="lg"
      body={body}
      onHide={onHide}
      closeButton
      backdrop
    />
  );
}

CreateChurn.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  handleRequestProcessing: PropTypes.func.isRequired,
};

export default CreateChurn;
