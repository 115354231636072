import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Button,
  Col, Container, Form, InputGroup, Row, Spinner,
} from 'react-bootstrap';
import QueryString from 'query-string';
import { Link } from 'react-router-dom';
import {
  CustomDropdown, CustomModal, CustomTable, DatePicker, ErrorHandler, Svg,
} from '../../component/common';
import { ProcessingStatus } from '../../component/derived/table-list';
import { Constant } from '../../utilities';
import { dateString } from '../../utilities/Utils';
import { exportResurrectionStores, getPayload, resurrectionStores } from '../../assets/api/axios';
import CreateChurn from './CreateChurn';
import ExotellCallPatch from './ExotellCallPatch';

const assignedToOptions = [
  {
    label: 'RAM1',
    value: 'RAM1',
  },
  {
    label: 'RAM5',
    value: 'RAM5',
  },
];

const statusOptions = [
  {
    label: 'PENDING',
    value: 'PENDING',
  },
  {
    label: 'NON-CONNECT',
    value: 'NON-CONNECT',
  },
  {
    label: 'LOST',
    value: 'LOST',
  },
  {
    label: 'REACTIVATED',
    value: 'REACTIVATED',
  },
  {
    label: 'RETAINED',
    value: 'RETAINED',
  },
  {
    label: 'IN-PROGRESS',
    value: 'IN-PROGRESS',
  },
];

const callPurposeOptions = [
  {
    label: 'Store Turn Off',
    value: 'Store Turn Off',
  },
];

const callCommentOptions = [
  {
    label: 'Non Connect',
    value: 'Non Connect',
  },
  {
    label: 'Not Interested',
    value: 'Not Interested',
  },
  {
    label: 'Call Back',
    value: 'Call Back',
  },
  {
    label: 'Reactivated',
    value: 'Reactivated',
  },
  {
    label: 'Others',
    value: 'Others',
  },
];

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, startDate, endDate,
  } = param;
  const {
    searchText = '',
    status = 'PENDING,IN-PROGRESS',
    deliveryStatus = '',
    turnOffType = '',
    isRetailerTurnOff = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  startDate = Number(startDate) || new Date().getTime() - (1 * 24 * 60 * 60 * 1000);
  startDate = new Date(startDate).setHours(0, 0, 0, 0);
  endDate = Number(endDate) || new Date().setHours(23, 59, 59, 999);
  return ({
    l,
    p,
    searchText,
    startDate,
    endDate,
    status,
    deliveryStatus,
    turnOffType,
    isRetailerTurnOff,
  });
};

class StoreResurrection extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    this.state = {
      loading: true,
      error: false,
      stores: null,
      rowsPerPage: param.l,
      page: param.p,
      searchText: param.searchText,
      param,
      indexOfUpdatingStore: -1,
      selectedStoreId: '',
      updateStoreErrorMsg: '',
      performingAction: '',
      phoneNumber: '',
      storeId: '',
      showCallModal: false,
      createChurnModal: false,
      downloading: false,
      downloadStatus: '',
    };
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, searchText, startDate, endDate,
      status, deliveryStatus, turnOffType, isRetailerTurnOff,
    } = newParam;
    const offset = (p - 1) * l;

    resurrectionStores(
      'GET',
      {},
      {
        offset: `${offset}`,
        limit: l,
        format: 'table',
        searchText,
        status,
        deliveryStatus,
        turnOffType,
        isRetailerTurnOff,
        startDate,
        endDate,
      },
    ).then((res) => {
      this.setState({
        loading: false,
        stores: res.data.data,
        searchText: newParam.searchText,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  };

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onSubmitSearchText = () => {
    const {
      searchText, param,
    } = this.state;
    if (searchText !== param.searchText) {
      this.handleRequestProcessing({ searchText, p: 1 });
    }
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const {
      l, p, searchText,
    } = param;
    if (loading) {
      this.source.cancel();
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
      searchText,
    });
  }

  handleOnChange = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  }

  handleDateRange = (startDate, endDate) => {
    const { param } = this.state;
    if (
      startDate !== param.startDate
      || endDate !== param.endDate
    ) {
      this.handleRequestProcessing({
        startDate,
        endDate,
        p: 1,
      });
    }
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  updateStoreData = (field, value) => {
    const { indexOfUpdatingStore, selectedStoreId, stores } = this.state;
    if (
      indexOfUpdatingStore >= 0
      && stores.results[indexOfUpdatingStore].storeCode === selectedStoreId
    ) {
      const updateStores = stores.results;
      updateStores[indexOfUpdatingStore][field] = value;
      this.setState({
        selectedStoreId: '',
        indexOfUpdatingStore: -1,
        stores: {
          ...stores,
          results: updateStores,
        },
      });
    }
  }

  patchStore = (storeId, data, action) => {
    resurrectionStores(
      'PATCH',
      data,
      {},
      storeId,
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          performingAction: '',
        });
        this.updateStoreData(action, data[action]);
      } else {
        throw new Error();
      }
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong!! Please Try Again!!';
      if (
        err
        && err.response
        && err.response.status === 400
        && err.response.data
        && err.response.data.errors
        && err.response.data.errors[0].message
      ) {
        errorMsg = err.response.data.errors[0].message;
      }
      this.setState({
        updateStoreErrorMsg: errorMsg,
      });
    });
    this.retryPatchStore = () => {
      this.setState({
        updateStoreErrorMsg: '',
      });
      this.patchStore(storeId, data, action);
    };
  }

  patchStoreDetails = async (value, storeDetails, index, action) => {
    this.setState({
      indexOfUpdatingStore: index,
      selectedStoreId: storeDetails.storeCode,
      performingAction: action,
    }, () => {
      this.patchStore(
        storeDetails.storeCode,
        {
          [action]: value,
        },
        action,
      );
    });
  }

  handleExportData = () => {
    const { param } = this.state;
    const {
      searchText, status, deliveryStatus, turnOffType, isRetailerTurnOff,
      startDate, endDate,
    } = param;
    let data = getPayload({
      searchText,
      status,
      turnOffType,
      startDate: startDate.toString(),
      endDate: endDate.toString(),
    });
    if (deliveryStatus) {
      data = {
        ...data,
        deliveryStatus: deliveryStatus === 'true',
      };
    }
    if (isRetailerTurnOff) {
      data = {
        ...data,
        isRetailerTurnOff: isRetailerTurnOff === 'true',
      };
    }

    exportResurrectionStores(
      'POST',
      data,
    ).then(() => {
      this.setState({
        downloading: false,
        downloadStatus: 'success',
      });
    }).catch(() => {
      this.setState({
        downloading: false,
        downloadStatus: 'failed',
      });
    });
  }

  render() {
    const {
      loading, error, stores, param, searchText, rowsPerPage, page,
      selectedStoreId, performingAction, updateStoreErrorMsg,
      createChurnModal, phoneNumber, showCallModal, storeId,
      downloading, downloadStatus,
    } = this.state;

    const headers = [
      {
        key: 'storeCode',
        displayText: 'EP Code',
        renderer: (data) => (
          <Link
            to={`/retailer/${data.storeCode}`}
            className="text-primary"
          >
            {data.storeCode}
          </Link>
        ),
      },
      {
        key: 'retailerPhoneNumber',
        displayText: 'Phone Number',
        renderer: (data) => (
          <Row className="d-flex align-items-center min-w150">
            <Col>
              {data.retailerPhoneNumber}
            </Col>
            <Col className="pl-0">
              <Button
                variant="link"
                onClick={() => {
                  this.setState({
                    phoneNumber: data.retailerPhoneNumber,
                    storeId: data.storeCode,
                    showCallModal: true,
                  });
                }}
                className="p-0"
              >
                <Svg
                  svg="call"
                  width="24"
                  height="24"
                />
              </Button>
            </Col>
          </Row>
        ),
      },
      {
        key: 'storeName',
        displayText: 'Store Name',
      },
      {
        key: 'storeTurnedOffAt',
        displayText: 'Turn Off Date',
        renderer: (data) => dateString(data.storeTurnedOffAt) || '--',
      },
      {
        key: 'storeTurnOffType',
        displayText: 'Turn Off Type',
      },
      {
        key: 'status',
        displayText: 'Status',
        renderer: (data, index) => {
          if (
            performingAction === 'status'
            && selectedStoreId === data.storeCode
            && !updateStoreErrorMsg
          ) {
            return (
              <div
                className="pt-1 d-flex align-item-center
                justify-content-center"
              >
                <Spinner
                  variant="primary"
                  animation="border"
                  size="sm"
                />
              </div>
            );
          }
          return (
            <select
              onChange={(e) => {
                if (e.target.value !== '') {
                  this.patchStoreDetails(e.target.value, data, index, 'status');
                }
              }}
              value={data.status}
              className={`p-1 ${performingAction ? 'pointer-event-none' : ''}`}
            >
              <option value="">Select</option>
              {statusOptions.map((status) => (
                <option value={status.value} key={status.value}>{status.label}</option>
              ))}
            </select>
          );
        },
      },
      {
        key: 'source',
        displayText: 'Source',
      },
      {
        key: 'expectedTurnOnDate',
        displayText: 'Expected Turn On Date',
        renderer: (data) => dateString(data.expectedTurnOnDate) || '--',
      },
      {
        key: 'storeTurnOffBy',
        displayText: 'Added By',
      },
      {
        key: 'comment',
        displayText: 'Comment',
      },
      {
        key: 'assignedTo',
        displayText: 'Assigned To',
        renderer: (data, index) => {
          if (
            performingAction === 'assignedTo'
            && selectedStoreId === data.storeCode
            && !updateStoreErrorMsg
          ) {
            return (
              <div
                className="pt-1 d-flex align-item-center
                justify-content-center"
              >
                <Spinner
                  variant="primary"
                  animation="border"
                  size="sm"
                />
              </div>
            );
          }
          return (
            <select
              onChange={(e) => {
                if (e.target.value !== '') {
                  this.patchStoreDetails(e.target.value, data, index, 'assignedTo');
                }
              }}
              value={data.assignedTo}
              className={`p-1 ${performingAction ? 'pointer-event-none' : ''}`}
            >
              <option value="">Select</option>
              {assignedToOptions.map((assigned) => (
                <option value={assigned.value} key={assigned.value}>{assigned.label}</option>
              ))}
            </select>
          );
        },
      },
      {
        key: 'calledOn',
        displayText: 'Last Call On',
        renderer: (data) => dateString(data.calledOn) || '--',
      },
      {
        key: 'callReason',
        displayText: 'Last Call Reason',
      },
      {
        key: 'callOutcome',
        displayText: 'Last Call Comment',
      },
    ];

    const filterConfForSingleSelect = [
      {
        key: 'deliveryStatus',
        displayText: 'Delivery Status',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
      {
        key: 'turnOffType',
        displayText: 'Turn Off Type',
        options: [
          {
            label: 'Temporary',
            value: 'temporary',
          },
          {
            label: 'Permanent',
            value: 'permanent',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
      {
        key: 'isRetailerTurnOff',
        displayText: 'Is Retailer Turn Off',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
    ];
    const filterConfForMultiSelect = [
      {
        key: 'status',
        displayText: 'Status',
        options: [
          {
            label: 'Pending',
            value: 'PENDING',
          },
          {
            label: 'In-Progress',
            value: 'IN-PROGRESS',
          },
          {
            label: 'Non-Connect',
            value: 'NON-CONNECT',
          },
          {
            label: 'Lost',
            value: 'LOST',
          },
          {
            label: 'Reactivated',
            value: 'REACTIVATED',
          },
          {
            label: 'Retained',
            value: 'RETAINED',
          },
        ],
      },
    ];

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        <CustomModal
          show={!!updateStoreErrorMsg}
          onHide={() => this.setState({ updateStoreErrorMsg: '' })}
          autoSize
          backdrop
          body={(
            <Container className="p-4">
              <Row className="mx-0 py-2 text-danger">
                {updateStoreErrorMsg}
              </Row>
              <Row
                className="mx-0 py-2 d-flex align-items-center flex-row-reverse"
              >
                <Button
                  variant="link"
                  className="font-weight-bold"
                  onClick={this.retryPatchStore}
                >
                  RETRY
                </Button>
                <Button
                  variant="link"
                  className="font-weight-bold text-danger"
                  onClick={() => {
                    this.setState({
                      updateStoreErrorMsg: '',
                      performingAction: '',
                    });
                  }}
                >
                  CANCEL
                </Button>
              </Row>
            </Container>
          )}
        />
        {
          showCallModal && (
            <ExotellCallPatch
              show={showCallModal}
              onHide={() => {
                this.setState({
                  showCallModal: false,
                  phoneNumber: '',
                });
              }}
              phoneNumber={phoneNumber}
              storeId={storeId}
              action="CALL_TO_RETAILER"
              callPurposeOptions={callPurposeOptions}
              callCommentOptions={callCommentOptions}
              handleRequestProcessing={this.handleRequestProcessing}
            />
          )
        }
        {
          createChurnModal && (
            <CreateChurn
              show={createChurnModal}
              onHide={() => this.setState({ createChurnModal: false })}
              handleRequestProcessing={this.handleRequestProcessing}
            />
          )
        }
        {(() => {
          let showcase = null;
          if (!stores && loading) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center justify-content-center"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (!stores && !loading && error) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center
                justify-content-center"
              >
                <ErrorHandler
                  retryLogic={() => this.retry()}
                />
              </div>
            );
          } else if (stores) {
            showcase = (
              <>
                <ProcessingStatus
                  show={loading || error}
                  loading={loading}
                  error={error}
                  onRetry={() => this.retry()}
                  onCancel={this.onCancel}
                />
                <Row>
                  <Col
                    xs={24}
                    className="px-0 filter-section py-2 bg-white"
                  >
                    <Row
                      className="mx-0"
                    >
                      <Col
                        xs={24}
                        sm="auto"
                        className="px-2 py-2"
                      >
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text
                              className="rounded-0"
                            >
                              <Svg
                                svg="search"
                                width="1rem"
                                fill={Constant.Color.DARK}
                              />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            type="text"
                            placeholder="Search"
                            className="fs-01 rounded-0"
                            value={searchText}
                            onChange={this.handleOnChange}
                            onKeyPress={(e) => {
                              if (e.which === 13) {
                                this.onSubmitSearchText();
                              }
                            }}
                          />
                        </InputGroup>
                      </Col>
                      {filterConfForMultiSelect.map((item) => (
                        <Col
                          key={item.key}
                          xs="auto"
                          className="px-2 py-2"
                        >
                          <div>
                            <Select
                              id={item.key}
                              placeholder={item.displayText}
                              onChange={(selectedItems) => {
                                const selectedItemsString = selectedItems.reduce(
                                  (acc, selItem, i) => acc.concat(i === 0 ? '' : ',', selItem.value), '',
                                );
                                this.handleDropdownChange({ [item.key]: selectedItemsString });
                              }}
                              selectedVal={param[item.key]}
                              options={item.options}
                              isMulti
                              value={param[item.key]
                                ? (param[item.key].split(','))
                                  .map((val) => ({ label: val, value: val }))
                                : []}
                              className="minw-150p"
                            />
                          </div>
                        </Col>
                      ))}
                      {filterConfForSingleSelect.map((item) => (
                        <Col
                          key={item.key}
                          xs="auto"
                          className="px-2 py-2"
                        >
                          <CustomDropdown
                            item={item}
                            onChange={this.handleDropdownChange}
                            selectedVal={param[item.key]}
                          />
                        </Col>
                      ))}
                      <Col
                        xs="auto"
                        className="px-2 py-2"
                      >
                        <div
                          className="bg-light d-flex align-items-center justify-content-center"
                        >
                          <div className="px-2 fs-01">
                            Turn Off Date
                          </div>
                          <DatePicker
                            isDateRange
                            onApply={(dateRange) => {
                              this.handleDateRange(
                                new Date(dateRange.startDate).setHours(0, 0, 0, 0),
                                new Date(dateRange.endDate).setHours(23, 59, 59, 999),
                              );
                            }}
                            startDate={param.startDate}
                            endDate={param.endDate}
                            maxDate={new Date().getTime()}
                            onClear={() => {
                              this.handleRequestProcessing({
                                startDate: '',
                                endDate: '',
                              });
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row
                      className="mx-0 py-1"
                    >
                      <Col
                        xs="auto"
                        className="px-2"
                      >
                        <Button
                          variant="primary"
                          className="fs-1"
                          onClick={() => {
                            this.setState({
                              createChurnModal: true,
                            });
                          }}
                        >
                          Add Churn Entry
                        </Button>
                      </Col>
                      <Col
                        xs="auto"
                        className="px-2"
                      >
                        <Button
                          variant="primary"
                          className="fs-1"
                          onClick={() => {
                            this.setState({
                              downloading: true,
                              downloadStatus: '',
                            }, this.handleExportData());
                          }}
                          disabled={downloading || stores.results.length === 0}
                        >
                          Export Data
                          &nbsp;
                          {
                            downloading && (
                              <Spinner
                                size="sm"
                                variant="light"
                                animation="border"
                              />
                            )
                          }
                        </Button>
                      </Col>
                    </Row>
                    {
                      downloadStatus === 'success' && (
                        <Row
                          className="mx-0"
                        >
                          <Col
                            xs="auto"
                            className="px-2 text-success"
                          >
                            File sent on your mail
                          </Col>
                        </Row>
                      )
                    }
                    {
                      downloadStatus === 'failed' && (
                        <Row
                          className="mx-0"
                        >
                          <Col
                            xs="auto"
                            className="px-2 text-danger"
                          >
                            Something went wrong. Retry!
                          </Col>
                        </Row>
                      )
                    }
                  </Col>
                  <Col
                    xs={24}
                    className="px-0 table-section"
                  >
                    <CustomTable
                      headers={headers}
                      content={stores.results}
                      keyField="storeCode"
                      l={param.l}
                      p={param.p}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      totalItems={stores.count}
                      hasPrev={stores.hasPrevious}
                      hasNext={stores.hasNext}
                      onNext={this.onNext}
                      onPrev={this.onPrev}
                      onSubmitPage={this.onSubmitPage}
                      onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                      updateRowsPageInput={this.handleRowsPageInput}
                    />
                  </Col>
                </Row>
              </>
            );
          }
          return showcase;
        })()}
      </Container>
    );
  }
}

StoreResurrection.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default StoreResurrection;
