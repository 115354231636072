import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <g clipPath="url(#a)">
      <mask
        id="b"
        width={width}
        height={height}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path fill="#fff" d="M12 0H0v15h12V0Z" />
      </mask>
      <g stroke="#138800" mask="url(#b)">
        <path
          fill="#138800"
          fillOpacity={0.1}
          d="M.625 10.963V4.287L6 .984l5.375 3.303v6.676L6 14.266.625 10.963Z"
        />
        <path strokeWidth={1.5} d="M3.5 7.333 5.286 9 8.5 6" />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h12v15H0z" />
      </clipPath>
    </defs>
  </svg>
);

Svg.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Svg.defaultProps = {
  width: 12,
  height: 15,
};

export default Svg;
