import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal, CustomTable, ErrorHandler } from '../../component/common';
import { couponCode } from '../../assets/api/axios';
import { dateString } from '../../utilities/Utils';

function ViewCouponCode(props) {
  const { show, onHide, campaignId } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [couponCodeDetails, setCouponCodeDetails] = useState(null);

  function handleLoad() {
    setLoading(true);
    setError(false);
    couponCode(
      'GET',
      {
        campaignId,
      },
    ).then((res) => {
      setLoading(false);
      setCouponCodeDetails(res.data);
    }).catch(() => {
      setLoading(false);
      setError(true);
    });
  }
  const body = (
    <Container>
      <Row>
        <Col className="px-0">
          {(() => {
            let showBody = null;
            if (loading) {
              showBody = (
                <div
                  className="py-2 d-flex align-items-center
                  justify-content-center"
                >
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                </div>
              );
            } else if (!loading && error) {
              showBody = (
                <div
                  className="py-2 d-flex align-items-center
                  justify-content-center"
                >
                  <ErrorHandler
                    retryLogic={() => {
                      handleLoad();
                    }}
                  />
                </div>
              );
            } else if (couponCodeDetails) {
              showBody = (
                <CustomTable
                  headers={[
                    {
                      key: 'campaignId',
                      displayText: 'Campaign Id',
                    },
                    {
                      key: 'campaignName',
                      displayText: 'Campaign Name',
                    },
                    {
                      key: 'couponId',
                      displayText: 'Coupon Id',
                    },
                    {
                      key: 'couponCode',
                      displayText: 'Coupon Code',
                    },
                    {
                      key: 'createdOn',
                      displayText: 'Created On',
                      renderer: (data) => dateString(data.createdOn),
                    },
                  ]}
                  content={couponCodeDetails.results}
                  keyField="campaignId"
                  totalItems={couponCodeDetails.count}
                  isPaginated={false}
                />
              );
            }
            return showBody;
          })()}
        </Col>
      </Row>
    </Container>
  );

  return (
    <CustomModal
      show={show}
      onHide={onHide}
      title="Coupon Code"
      size="xl"
      body={body}
      onEnter={() => {
        handleLoad();
      }}
      closeButton
      backdrop
    />
  );
}

ViewCouponCode.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  campaignId: PropTypes.string.isRequired,
};

export default ViewCouponCode;
