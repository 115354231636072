import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Modal, Col, Button,
} from 'react-bootstrap';
import ProductView from './ProductView';
import ArrowSvg from '../../assets/icon/arrow.svg';
import CustomProductView from './CustomProductView';

const productInViewStructure = (storeItems) => storeItems.reduce((acc, store) => {
  const productItems = store.products.map((product) => {
    const sellingStores = [];
    const sellingStore = {
      mrp: product.mrp,
      sellingPrice: product.sellingPrice,
      store: {
        id: store.store.code,
        displayName: store.store.displayName,
      },
    };
    sellingStores.push(sellingStore);
    return { ...product, sellingStores };
  });
  return [...acc, ...productItems];
}, []);

const customProductInViewStructure = (storeItems) => storeItems.reduce((acc, store) => {
  const productItems = store.customProducts.map((product) => (
    {
      ...product,
      store: {
        id: store.store.code,
        displayName: store.store.displayName,
      },
    }
  ));
  return [...acc, ...productItems];
}, []);

const Cart = (props) => {
  const {
    show,
    cartItems,
    cartDetails,
    handleAddProductInCart,
    handleOpenCartView,
    modifyCurrentState,
    // storeCodeHavingCustomProduct,
    handleAddOrRemoveCustomProduct,
  } = props;
  let billAmount = 0;
  let productCount = 0;
  let items = [];
  let customItems = [];
  if (cartDetails) {
    billAmount = cartDetails.cart.billAmount;
    productCount = cartDetails.cart.productCount;
    items = productInViewStructure(cartDetails.storeItems);
    customItems = customProductInViewStructure(cartDetails.storeItems);
  }

  return (
    <Modal show={show} onHide={() => handleOpenCartView(false)}>
      <Modal.Header closeButton>
        <Modal.Title className="d-flex justify-content-between w-100">
          <h4 className="fs-2 mb-0">Shopping Cart</h4>
          <h4 className="fs-2 text-danger mb-0">
            <span className="mr-2">
              {productCount}
            </span>
            Items
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="bg-white m-auto">
          {
            !!customItems.length && (
              <Row
                className="py-3 bg-warning-lighter"
              >
                <Col
                  xs={24}
                  className="text-center font-weight-bold text-success pb-1"
                >
                  Cart Custom Item(s)
                </Col>
                {
                  customItems.map((item) => (
                    <Col xs={24} key={item.id}>
                      <CustomProductView
                        product={item}
                        handleAddOrRemoveCustomProduct={handleAddOrRemoveCustomProduct}
                        className=""
                        isCart
                      />
                    </Col>
                  ))
                }
              </Row>
            )
          }
          <Row
            className="py-3"
          >
            <Col
              xs={24}
              className="text-center font-weight-bold text-success pb-1"
            >
              Cart Products
            </Col>
            {items.map((item) => (
              <Col xs={24} key={item.id}>
                <ProductView
                  product={item}
                  cartItems={cartItems}
                  handleAddProductInCart={handleAddProductInCart}
                  className=""
                />
              </Col>
            ))}
            <Col
              xs={24}
              className={
                (!!items.length || !!customItems.length)
                  ? 'd-none' : 'fs-1 py-2 text-center'
              }
            >
              Your shopping cart is empty
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="p-0 border-0">
        <Button
          size="lg"
          variant="success"
          disabled={!items.length}
          className="w-100 rounded-0 d-flex justify-content-between align-items-center"
          onClick={() => modifyCurrentState(false)}
        >
          <div className="d-flex flex-column">
            <h4 className="fs-1 font-weight-bold my-1">Total Amount</h4>
            <h4 className="fs-1 font-weight-bold mr-auto mb-0">
              &#8377;
              {billAmount}
            </h4>
          </div>
          <h4 className="fs-1 font-weight-bold mb-0">
            Proceed to Buy
            <span className="ml-2"><img src={ArrowSvg} alt="" /></span>
          </h4>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

Cart.propTypes = {
  show: PropTypes.bool.isRequired,
  cartItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cartDetails: PropTypes.shape({
    cart: PropTypes.shape({
      billAmount: PropTypes.number.isRequired,
      productCount: PropTypes.number.isRequired,
    }).isRequired,
    storeItems: PropTypes.arrayOf(PropTypes.shape({
      customProducts: PropTypes.arrayOf(PropTypes.shape({})),
    })).isRequired,
  }),
  handleAddProductInCart: PropTypes.func.isRequired,
  handleOpenCartView: PropTypes.func.isRequired,
  modifyCurrentState: PropTypes.func.isRequired,
  // storeCodeHavingCustomProduct: PropTypes.string.isRequired,
  handleAddOrRemoveCustomProduct: PropTypes.func.isRequired,
};

Cart.defaultProps = {
  cartDetails: null,
};

export default Cart;
