import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import routes from './routes';
import Wrapper from './Wrapper';
import './assets/scss/theme.scss';
import userAccess from './access&permissions/userAccess';
import { isAuthenticated } from './utilities/Utils';
import initiateFirebase from './integrations/firebase/Firebase';
import { iniializeFirebaseMessaging } from './integrations/firebase/Messaging';

class App extends React.Component {
  constructor() {
    super();
    const { priviledge, permission } = userAccess();
    this.state = {
      userPriviledge: priviledge,
      userPermission: permission,
    };
  }

  componentDidMount = () => {
    if (isAuthenticated()) {
      initiateFirebase();
      iniializeFirebaseMessaging();
    }
  }

  onUserLogin = () => {
    const { priviledge, permission } = userAccess();
    this.setState({
      userPriviledge: priviledge,
      userPermission: permission,
    });
  }

  render() {
    const { userPriviledge, userPermission } = this.state;
    return (
      <BrowserRouter>
        <Switch>
          {routes.reduce((acc, route) => {
            const { path, component, key } = route;
            if (userPriviledge.includes(key)) {
              return ([
                ...acc,
                <Route
                  key={key}
                  path={path}
                  render={(props) => (
                    <Wrapper
                      {...props}
                      Component={component}
                      userPriviledge={userPriviledge}
                      userPermission={userPermission}
                      updateUserAuthorization={this.onUserLogin}
                    />
                  )}
                />,
              ]);
            }
            return acc;
          }, [])}
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
