import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import {
  Button,
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { agentsData, executors } from '../../../assets/api/axios';
import { CustomModal, CustomTable, ErrorHandler } from '../../../component/common';
import { ProcessingStatus } from '../../../component/derived/table-list';
import AddNewExecutor from './AddNewExecutor';
import DeleteExecutor from './DeleteExecutor';
import permission from '../../../access&permissions/permission';
import { getAgentsList, setAgentsList } from '../../../utilities/Storage';
import { getHoursDiff } from '../../../utilities/Utils';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    l,
    p,
  });
};

class CallMapping extends React.Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    let agentsList = getAgentsList();
    if (agentsList) {
      const hrDiff = getHoursDiff(agentsList.lastUpdatedTime, new Date());
      if (hrDiff > 24) {
        agentsList = null;
      }
    }
    const { userPermission } = this.props;
    this.canEdit = userPermission.includes(permission.CX_RX_CALL_MAPPING_WRITE);

    this.state = {
      agentsList: (agentsList && agentsList.agents) || null,
      loading: true,
      error: false,
      executorsData: null,
      rowsPerPage: param.l,
      page: param.p,
      param,
      executorModal: '',
      executorDetails: null,
      performingAction: '',
      selectedAgentId: '',
      updateAgentErrorMsg: '',
      indexOfUpdatingAgent: -1,
    };
  }

  componentDidMount = () => {
    const { agentsList } = this.state;
    if (!agentsList) {
      agentsData(
        'GET',
      ).then((res) => {
        this.setState({
          agentsList: res.data,
        });
        setAgentsList({
          lastUpdatedTime: new Date(),
          agents: res.data,
        });
      }).catch(() => {});
    }
    this.handleLoad();
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p,
    } = newParam;
    const offset = (p - 1) * l;
    executors(
      'GET',
      {},
      '',
      {
        offset: `${offset}`,
        limit: l,
      },
    ).then((res) => {
      this.setState({
        loading: false,
        executorsData: res.data,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const {
      l, p,
    } = param;
    if (loading) {
      this.source.cancel();
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  updateAgentData = (agentData) => {
    const { indexOfUpdatingAgent, selectedAgentId, executorsData } = this.state;
    if (
      indexOfUpdatingAgent >= 0
      && executorsData.results[indexOfUpdatingAgent].executorId === selectedAgentId
    ) {
      const updateAgent = executorsData.results;
      updateAgent[indexOfUpdatingAgent] = agentData;
      this.setState({
        selectedAgentId: '',
        indexOfUpdatingAgent: -1,
        executorsData: {
          ...executorsData,
          results: updateAgent,
        },
      });
    }
  }

  patchAgent = (agentId, data, action) => {
    executors(
      'PATCH',
      data,
      agentId,
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          performingAction: '',
        });
        this.updateAgentData(res.data.results);
      } else {
        throw new Error();
      }
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong!! Please Try Again!!';
      if (
        err
        && err.response
        && err.response.status === 400
        && err.response.data
        && err.response.data.errors
        && err.response.data.errors[0].message
      ) {
        errorMsg = err.response.data.errors[0].message;
      }
      this.setState({
        updateAgentErrorMsg: errorMsg,
      });
    });

    this.retryPatchAgent = () => {
      this.setState({
        updateAgentErrorMsg: '',
      });
      this.patchAgent(agentId, data, action);
    };
  }

  patchAgentDetails = async (value, agentDetails, index, action) => {
    this.setState({
      indexOfUpdatingAgent: index,
      selectedAgentId: agentDetails.executorId,
      performingAction: action,
    }, () => {
      this.patchAgent(
        agentDetails.executorId,
        {
          executorId: agentDetails.executorId,
          [action]: value,
        },
        action,
      );
    });
  }

  render() {
    const {
      loading, error, executorsData, param, rowsPerPage, page,
      executorModal, executorDetails, agentsList,
      performingAction, selectedAgentId, updateAgentErrorMsg,
    } = this.state;

    const headers = [
      {
        key: 'executorName',
        displayText: 'Executor Name',
      },
      {
        key: 'executorNumber',
        displayText: 'Executor Number',
      },
      {
        key: 'executorId',
        displayText: 'Executor Mail Id',
      },
      {
        key: 'exotelnumber',
        displayText: 'Exotel Number',
      },
      {
        key: 'process',
        displayText: 'Process',
        renderer: (data, index) => {
          if (
            performingAction === 'process'
            && selectedAgentId === data.executorId
            && !updateAgentErrorMsg
          ) {
            return (
              <div
                className="pt-1 d-flex align-item-center
                justify-content-center"
              >
                <Spinner
                  variant="primary"
                  animation="border"
                  size="sm"
                />
              </div>
            );
          }
          return (
            <select
              onChange={(e) => {
                if (e.target.value !== '') {
                  this.patchAgentDetails(e.target.value, data, index, 'process');
                }
              }}
              value={data.process}
              className={`p-1 ${performingAction ? 'pointer-event-none' : ''} fs-0`}
              disabled={!this.canEdit}
            >
              <option value="">Select</option>
              <option value="ordermanagement">Order Management</option>
              <option value="Appreview_SendBird_SocialMedia_FFR_customercall">App review / Send Bird / Social Media / FFR Customer Call</option>
              <option value="Retention">Retention</option>
              <option value="welcomecall">Welcome Call</option>
              <option value="riskmanagement">Risk Management</option>
              <option value="storeturnoff">Store Turn Off</option>
              <option value="qc3team">QC 3 Team</option>
              <option value="remotetraining">Remote Training</option>
              <option value="telesales">Telesales</option>
              <option value="customercalling">Customer Calling</option>
            </select>
          );
        },
      },
      {
        key: 'action',
        displayText: 'Action',
        renderer: (data) => (
          <Button
            onClick={() => {
              this.setState({
                executorModal: 'delete',
                executorDetails: data,
              });
            }}
            className="fs-0 py-1"
            disabled={!this.canEdit}
          >
            Delete
          </Button>
        ),
      },
    ];

    return (
      <Container
        fluid
        className="h-100 bg-white px-0"
      >
        <CustomModal
          show={!!updateAgentErrorMsg}
          onHide={() => this.setState({ updateAgentErrorMsg: '' })}
          autoSize
          backdrop
          body={(
            <Container className="p-4">
              <Row className="mx-0 py-2 text-danger">
                {updateAgentErrorMsg}
              </Row>
              <Row
                className="mx-0 py-2 d-flex align-items-center flex-row-reverse"
              >
                <Button
                  variant="link"
                  className="font-weight-bold"
                  onClick={this.retryPatchAgent}
                >
                  RETRY
                </Button>
                <Button
                  variant="link"
                  className="font-weight-bold text-danger"
                  onClick={() => {
                    this.setState({
                      updateAgentErrorMsg: '',
                      performingAction: '',
                    });
                  }}
                >
                  CANCEL
                </Button>
              </Row>
            </Container>
          )}
        />
        {
          executorModal === 'add' && (
            <AddNewExecutor
              show
              onHide={() => this.setState({ executorModal: '' })}
              handleRequestProcessing={this.handleRequestProcessing}
              agentsList={agentsList}
            />
          )
        }
        {
          executorModal === 'edit' && (
            <AddNewExecutor
              show
              onHide={() => this.setState({ executorModal: '' })}
              executorDetails={executorDetails}
              handleRequestProcessing={this.handleRequestProcessing}
              editDetails
            />
          )
        }
        {
          executorModal === 'delete' && (
            <DeleteExecutor
              show
              onHide={() => this.setState({ executorModal: '' })}
              executorDetails={executorDetails}
              handleRequestProcessing={this.handleRequestProcessing}
            />
          )
        }
        {(() => {
          let showcase = null;
          if (!executorsData && loading) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center justify-content-center"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (!executorsData && !loading && error) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center
                justify-content-center"
              >
                <ErrorHandler
                  retryLogic={() => this.retry()}
                />
              </div>
            );
          } else if (executorsData) {
            showcase = (
              <>
                <ProcessingStatus
                  show={loading || error}
                  loading={loading}
                  error={error}
                  onRetry={() => this.retry()}
                  onCancel={this.onCancel}
                />
                <Row
                  className="mx-0"
                >
                  <Col
                    xs={24}
                    className="px-0 py-2"
                  >
                    <Row
                      className="mx-0"
                    >
                      <Col
                        xs={24}
                        sm="auto"
                        className="px-2 py-2"
                      >
                        <Button
                          onClick={() => {
                            this.setState({
                              executorModal: 'add',
                            });
                          }}
                          disabled={!this.canEdit}
                        >
                          Add Executor
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={24}
                    className="px-0 table-section"
                  >
                    <CustomTable
                      headers={headers}
                      content={executorsData.results}
                      keyField="executorId"
                      l={param.l}
                      p={param.p}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      totalItems={executorsData.count}
                      hasPrev={executorsData.hasPrev}
                      hasNext={executorsData.hasNext}
                      onNext={this.onNext}
                      onPrev={this.onPrev}
                      onSubmitPage={this.onSubmitPage}
                      onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                      updateRowsPageInput={this.handleRowsPageInput}
                    />
                  </Col>
                </Row>
              </>
            );
          }
          return showcase;
        })()}
      </Container>
    );
  }
}

CallMapping.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default CallMapping;
