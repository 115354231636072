import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import QueryString from 'query-string';
import { CustomTable, ErrorHandler } from '../../component/common';
import { ProcessingStatus } from '../../component/derived/table-list';
import CreateFlashSale from './CreateFlashSale';
import permission from '../../access&permissions/permission';
import { flashSaleCampaignList } from '../../assets/api/axios';
import { Utils } from '../../utilities';
import { useStateWithCallback } from '../../utilities/Utils';

function renderStatus(status) {
  return status
    ? <span className="text-success">&#10004;</span>
    : <span className="text-danger">&#10006;</span>;
}

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 15;
  p = (p && p > 0) ? p : 1;
  return ({
    l, p,
  });
};

function FlashSaleCampaign(props) {
  const { userPermission, history } = props;
  const { pathname } = history.location;
  const param = getQueryParams(history.location.search);

  const [flashSales, setFlashSales] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(param?.l);
  const [page, setPage] = useState(param?.p);
  const [params, setParams] = useStateWithCallback(param);
  const [newParams] = useState(param);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [flashSaleModal, setFlashSaleModal] = useState(false);
  const [editFlashSale, setEditFlashSale] = useState(false);
  const [selectedId, setSelectedId] = useState('');

  const canCreate = userPermission.includes(permission.FLASH_SALE_CAMPAIGN_WRITE);
  const handleLoad = (data = {}) => {
    setLoading(true);
    setError(false);
    const updatedParams = {
      ...params,
      ...newParams,
      ...data,
    };
    const {
      l, p,
    } = updatedParams;

    flashSaleCampaignList(
      'GET',
      null,
      {
        page: p,
        limit: l,
      },
    ).then((res) => {
      setFlashSales(res.data);
      setLoading(false);
      setParams({
        ...updatedParams,
      }, () => {
        if (Object.keys(updatedParams).find((key) => (updatedParams[key] !== params[key]))) {
          Object.keys(updatedParams).forEach((item) => {
            if (!updatedParams[item]) {
              delete updatedParams[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(updatedParams),
          });
        }
      });
    }).catch(() => {
      setLoading(false);
      setError(true);
    });
  };

  const handleRequestProcessing = (data = {}) => {
    handleLoad(data);
  };

  useEffect(() => {
    handleLoad();
    // eslint-disable-next-line
  }, []);

  const retry = () => {
    handleRequestProcessing();
  };

  const cancel = () => {
    setLoading(false);
    setError(false);
  };

  const onNext = () => {
    if (params.p + 1 !== params.p) {
      handleRequestProcessing({ p: params.p + 1 });
    }
  };

  const onPrev = () => {
    if (params.p - 1 !== params.p) {
      handleRequestProcessing({ p: params.p - 1 });
    }
  };

  const handleRowsPageInput = (value, field) => {
    if (field === 'page') {
      setPage(value);
    } else {
      setRowsPerPage(value);
    }
  };

  const onSubmitRowsPerPage = () => {
    if (rowsPerPage !== params.l) {
      handleRequestProcessing({ l: rowsPerPage });
    }
  };

  const onSubmitPage = () => {
    if (page !== params.p) {
      handleRequestProcessing({ p: page });
    }
  };

  const headers = [
    {
      key: 'flashSaleCampaignId',
      displayText: 'Id',
      renderer: (data) => (
        <Button
          variant="link"
          onClick={() => {
            setEditFlashSale(true);
            setSelectedId(data.flashSaleCampaignId);
          }}
          className="fs-01 p-0"
          disabled={!canCreate}
        >
          {data.flashSaleCampaignId}
        </Button>
      ),
    },
    {
      key: 'libraryProductId',
      displayText: 'Product Id',
    },
    {
      key: 'productName',
      displayText: 'Product Name',
    },
    {
      key: 'mrp',
      displayText: 'Product MRP',
    },
    {
      key: 'discount',
      displayText: 'Discount Amount',
    },
    {
      key: 'isActive',
      displayText: 'Active',
      renderer: (data) => renderStatus(data.isActive),
    },
    {
      key: 'startDate',
      displayText: 'Start Date',
      renderer: (data) => Utils.dateString(data.startDate),
    },
    {
      key: 'endDate',
      displayText: 'End Date',
      renderer: (data) => Utils.dateString(data.endDate),
    },
    {
      key: 'city',
      displayText: 'City',
    },
    {
      key: 'area',
      displayText: 'Locality',
    },
    {
      key: 'pinCode',
      displayText: 'PinCode',
    },
  ];

  return (
    <Container
      fluid
      className="h-100 bg-white"
    >
      {
        (flashSaleModal || editFlashSale) && (
          <CreateFlashSale
            show={flashSaleModal || editFlashSale}
            onHide={() => {
              setFlashSaleModal(false);
              setEditFlashSale(false);
            }}
            handleRequestProcessing={handleRequestProcessing}
            flashSaleId={selectedId}
            editFlashSale={editFlashSale}
          />
        )
      }
      <Row
        className="h-100 flex-column"
      >
        <Col
          className="flex-grow-0"
        >
          <Row
            className="align-items-center py-2"
          >
            <Col
              className="d-flex justify-content-end mt-2"
            >
              <Button
                variant="outline-primary"
                className="fs-1"
                onClick={() => {
                  setFlashSaleModal(true);
                }}
                disabled={!canCreate}
              >
                CREATE FLASH SALE
              </Button>
            </Col>
          </Row>
        </Col>
        {(() => {
          let showcase = null;
          if (!flashSales && loading) {
            showcase = (
              <div
                className="d-flex align-items-center
                justify-content-center"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (!flashSales && !loading && error) {
            showcase = (
              <div
                className="d-flex align-items-center
                justify-content-center"
              >
                <ErrorHandler
                  retryLogic={() => retry()}
                />
              </div>
            );
          } else if (flashSales) {
            showcase = (
              <>
                <ProcessingStatus
                  show={loading || error}
                  loading={loading}
                  error={error}
                  onRetry={() => retry()}
                  onCancel={() => cancel()}
                />
                <Col
                  className="px-0 flex-grow-1 overflow-y pt-2"
                >
                  <CustomTable
                    headers={headers}
                    content={flashSales.data}
                    keyField="flashSaleCampaignId"
                    l={params.l}
                    p={params.p}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    totalItems={flashSales?.count}
                    hasPrev={!!flashSales?.prev}
                    hasNext={!!flashSales?.next}
                    onNext={onNext}
                    onPrev={onPrev}
                    onSubmitPage={onSubmitPage}
                    onSubmitRowsPerPage={onSubmitRowsPerPage}
                    updateRowsPageInput={handleRowsPageInput}
                  />
                </Col>
              </>
            );
          }
          return showcase;
        })()}
      </Row>
    </Container>
  );
}

FlashSaleCampaign.propTypes = {
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default FlashSaleCampaign;
