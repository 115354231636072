import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width, fill }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
  >
    <g id="Frames-24px">
      <rect fill="none" style={{ width }} />
    </g>
    <g id="Outline">
      <g>
        <path
          fill={fill}
          d="M20,0h-7c-1.103,0-2,0.898-2,2v9c0,1.103,0.897,2,2,2h7c1.103,0,2-0.897,2-2V2C22,0.898,21.103,0,20,0z
M13,11V2h4v3h3l0.001,6H13z"
        />
        <path
          fill={fill}
          d="M20,17h-7.586l0.293-0.293c0.304-0.304,0.38-0.769,0.188-1.154l-1-2c-0.222-0.444-0.739-0.659-1.211-0.501
          l-4.757,1.585C5.781,14.266,5.423,14,5,14H1c-0.552,0-1,0.448-1,1v8c0,0.552,0.448,1,1,1h4c0.552,0,1-0.448,1-1h11
c0.315,0,0.611-0.148,0.8-0.4l3-4c0.228-0.303,0.264-0.708,0.095-1.047C20.725,17.215,20.379,17,20,17z M2,22v-6h2v6H2z M16.5,21
H6v-4.279l4.494-1.497l0.29,0.579l-1.49,1.49c-0.286,0.286-0.372,0.716-0.217,1.09S9.596,19,10,19h8L16.5,21z"
        />
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
