import React, { Component } from 'react';
import {
  Container, Row, Col, Button, Spinner, InputGroup, Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { adminCuratedLibraryGdroup } from '../../assets/api/axios';
import {
  CustomTable, CustomModal, CustomDropdown,
  Svg,
} from '../../component/common';
import { Constant } from '../../utilities';
import { dateString } from '../../utilities/Utils';
import permission from '../../access&permissions/permission';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  const {
    q = '',
    isEnabled = '',
    curatedProductGroupMapId = '',
    curatedProductGroupId = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    ...param,
    l,
    p,
    q,
    isEnabled,
    curatedProductGroupMapId,
    curatedProductGroupId,
  });
};

class SmartCatalogues extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    const { userPermission } = this.props;
    this.permissions = {
      ratroactive: userPermission.includes(permission.SMART_CATALOGUES_WRITE_RETROACTIVE),
      write: userPermission.includes(permission.SMART_CATALOGUES_WRITE),
    };
    this.state = {
      curatedGroups: [],
      curatedGroupsCount: 0,
      hasNext: false,
      hasPrevious: false,
      rowsPerPage: param.l,
      page: param.p,
      curatedGroupName: param.q,
      loading: true,
      error: false,
      submitting: false,
      submitError: false,
      submitErrorMsg: '',
      actionSuccessMsg: false,
      param,
      viewCuratedGroupMetaModal: false,
      curatedGroupMetaData: null,
      curatedGroupMetaDataLoadStatus: '',
      selectedCuratedGrToPerformAction: null,
      showPerformingActionModal: false,
      groupName: '',
      syncType: 'IGNORE',
      reason: '',
      isEnabled: false,
      libraryProductIds: '',
      method: 'SET_AS_CURRENT_LIST',
      productsSyncType: '',
      csvFileError: false,
      showDeleteCuratedGroupInfo: null,
      deleteCuratedGroupStatus: '',
      deleteBackendRespStatus: '',
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onSubmitSearchText = () => {
    const {
      curatedGroupName, param,
    } = this.state;
    if (curatedGroupName !== param.q) {
      this.handleRequestProcessing({ q: curatedGroupName, p: 1 });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onfilechanged = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const rows = reader.result.split('\r\n');
        if (rows.length > 0
          && (rows[0] === 'internal sku code'
          || rows[0] === 'product ids')) {
          this.setState({
            libraryProductIds: rows.splice(1).join(', '),
          });
        } else {
          this.setState({
            csvFileError: true,
          });
        }
      };
      reader.readAsText(file);
    }
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, q, isEnabled, curatedProductGroupMapId, curatedProductGroupId,
    } = newParam;
    const offset = (p - 1) * l;
    adminCuratedLibraryGdroup(
      'GET',
      '',
      {
        view: 'DEFAULT',
        offset: `${offset}`,
        limit: l,
        searchText: q,
        isEnabled,
        curatedProductGroupMapId,
        curatedProductGroupId,
      },
      null,
      this.source.token,
    ).then((res) => {
      this.setState({
        curatedGroups: res.data.results,
        loading: false,
        curatedGroupsCount: res.data.count,
        hasNext: res.data.hasNext,
        hasPrevious: res.data.hasPrevious,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleRadioButtonChange = (event) => {
    const { name } = event.target;
    this.setState((state) => ({
      [name]: !state[name],
    }));
  }

  getPayloadToPerformAction = (action) => {
    const {
      reason, selectedCuratedGrToPerformAction, method, isEnabled,
      syncType, libraryProductIds, groupName, productsSyncType,
    } = this.state;
    switch (action) {
      case 'UPDATE_ENABLED_STATE':
        return ({
          action,
          body: { groupId: selectedCuratedGrToPerformAction.groupId, reason, isEnabled },
        });
      case 'ARCHIVE_CURATED_GROUP':
        return ({
          action,
          body: { groupId: selectedCuratedGrToPerformAction.groupId, reason },
          syncJobTriggerAt: syncType,
        });
      case 'ADD_CURATED_GROUP':
        return ({
          action,
          body: { groupName },
          syncJobTriggerAt: syncType,
        });
      case 'UPDATE_LIBRARY_PRODUCTS':
        return ({
          action,
          body: {
            groupId: selectedCuratedGrToPerformAction.groupId,
            method,
            libraryProductIds: libraryProductIds.split(','),
            syncType: productsSyncType,
          },
          syncJobTriggerAt: syncType,
        });
      default:
        return ({
          action,
          body: {
            groupId: selectedCuratedGrToPerformAction.groupId,
            reason,
          },
          syncJobTriggerAt: syncType,
        });
    }
  }

  performAction = () => {
    this.setState({
      submitting: true,
      submitError: false,
      submitErrorMsg: '',
      actionSuccessMsg: false,
    });
    const {
      selectedCuratedGrToPerformAction, action,
    } = this.state;
    const body = this.getPayloadToPerformAction(action);
    adminCuratedLibraryGdroup(
      'POST',
      '',
      {},
      body,
      this.source.token,
    ).then((res) => {
      const {
        curatedGroups: newCuratedGroups,
      } = this.state;
      let {
        curatedGroupsCount: newCuratedGroupsCount,
      } = this.state;
      if (
        action === 'UPDATE_ENABLED_STATE'
        || action === 'ARCHIVE_CURATED_GROUP'
        || action === 'UPDATE_LIBRARY_PRODUCTS'
      ) {
        const index = newCuratedGroups.findIndex((item) => (
          item.groupId === selectedCuratedGrToPerformAction.groupId
        ));
        if (action === 'ARCHIVE_CURATED_GROUP') {
          newCuratedGroups.splice(index, 1);
          newCuratedGroupsCount -= 1;
        } else {
          newCuratedGroups[index] = {
            ...newCuratedGroups[index],
            ...res.data,
          };
        }
      } else {
        newCuratedGroups.splice(0, 0, res.data);
        newCuratedGroupsCount += 1;
      }
      if (res.status === 200) {
        this.setState({
          submitting: false,
          curatedGroups: newCuratedGroups,
          curatedGroupsCount: newCuratedGroupsCount,
          selectedCuratedGrToPerformAction: null,
          showPerformingActionModal: false,
          syncType: 'IGNORE',
          reason: '',
          isEnabled: false,
          groupName: '',
          action: '',
          libraryProductIds: '',
          actionSuccessMsg: true,
        }, () => {
          setTimeout(() => {
            this.setState({
              actionSuccessMsg: false,
            });
          }, 5000);
        });
      } else {
        throw new Error();
      }
    }).catch((err) => {
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.server_response
      ) {
        this.setState({
          submitError: true,
          submitting: false,
          submitErrorMsg: err.response.data.server_response,
        });
        return;
      }
      this.setState({
        submitError: true,
        submitting: false,
        submitErrorMsg: 'Oops! Something went wrong!',
      });
    });
  }

  loadCuratedGroupMeta = (curatedGroupId) => {
    adminCuratedLibraryGdroup(
      'GET',
      curatedGroupId,
      {
        view: 'META',
      },
      null,
      this.source.token,
    ).then((res) => {
      this.setState({
        curatedGroupMetaDataLoadStatus: '',
        curatedGroupMetaData: res.data.results,
      });
    }).catch(() => {
      this.setState({
        curatedGroupMetaDataLoadStatus: 'error',
      });
    });
    this.retry = () => {
      this.setState({
        curatedGroupMetaDataLoadStatus: 'loading',
      }, () => {
        this.loadCuratedGroupMeta(curatedGroupId);
      });
    };
  }

  getTitleForAction = (action) => {
    switch (action) {
      case 'UPDATE_ENABLED_STATE':
        return 'Altering Curated Group Enable Status';
      case 'ADD_CURATED_GROUP':
        return 'Add New Curated Group';
      case 'ARCHIVE_CURATED_GROUP':
        return 'Archive Curated Group';
      case 'UPDATE_LIBRARY_PRODUCTS':
        return 'Update Library Product Ids';
      case 'DELETE_CURATED_GROUP':
        return 'Delete Curated Group';
      default:
        return `Performing action - ${action}`;
    }
  }

  getGroupStatus = (modalActionableData = {}, isAlert = false) => {
    const {
      selectedCuratedGrToPerformAction, action, deleteBackendRespStatus,
    } = modalActionableData;
    this.setState({
      showDeleteCuratedGroupInfo: modalActionableData,
      action,
      selectedCuratedGrToPerformAction,
      deleteBackendRespStatus,
      deleteCuratedGroupStatus: 'LOADING',
    }, () => {
      const body = {
        action: 'DELETE_CURATED_GROUP',
        body: {
          id: selectedCuratedGrToPerformAction.groupId,
          isAlert,
        },
      };
      adminCuratedLibraryGdroup(
        'POST',
        '',
        {},
        body,
        this.source.token,
      ).then((res) => {
        this.setState({
          deleteBackendRespStatus: res.data.status,
          deleteCuratedGroupStatus: isAlert ? 'FIRST_SUCCESS' : 'FINAL_SUCCESS',
        }, () => {
          if (!isAlert) {
            setTimeout(() => {
              this.closeDeleteCuratedAlert();
              window.location.reload();
            }, 1000);
          }
        });
      }).catch(() => {
        this.setState({
          deleteCuratedGroupStatus: 'ERROR',
        });
      });
    });
  }

  closeDeleteCuratedAlert = () => {
    this.setState({
      showDeleteCuratedGroupInfo: null,
      deleteCuratedGroupStatus: '',
      deleteBackendRespStatus: '',
      action: '',
      selectedCuratedGrToPerformAction: null,
    });
  }

  render() {
    const {
      param, loading, error, curatedGroups, curatedGroupName,
      rowsPerPage, page, submitting, submitError, curatedGroupsCount,
      hasNext, hasPrevious, curatedGroupMetaData, groupName,
      viewCuratedGroupMetaModal, curatedGroupMetaDataLoadStatus,
      action, showPerformingActionModal, selectedCuratedGrToPerformAction,
      syncType, reason, isEnabled, submitErrorMsg, method, libraryProductIds,
      actionSuccessMsg, productsSyncType, csvFileError,
      showDeleteCuratedGroupInfo, deleteCuratedGroupStatus, deleteBackendRespStatus,
    } = this.state;

    const { history } = this.props;

    const headers = [
      {
        key: 'groupId',
        displayText: 'Group Id',
      },
      {
        key: 'groupName',
        displayText: 'Group Name',
      },
      {
        key: 'lastUpdatedBy',
        displayText: 'Last Updated By',
      },
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => (
          <>{dateString(data.createdOn)}</>
        ),
      },
      {
        key: 'modifiedOn',
        displayText: 'Modified On',
        renderer: (data) => (
          <>{dateString(data.modifiedOn)}</>
        ),
      },
      {
        key: 'previousJobDetail',
        displayText: 'Prev. Job Detail (Clickable Links)',
        renderer: (data) => (
          <>
            {
              (data.previousJobDetail).map((item) => (
                <div key={item.text}>
                  <a
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pb-2"
                    style={{
                      color: item.color,
                    }}
                  >
                    {item.text}
                  </a>
                </div>
              ))
            }
          </>
        ),
      },
      {
        key: 'groupIsEnabled',
        displayText: 'Is Enabled',
        renderer: (data) => (
          <div
            className={data.groupIsEnabled ? 'text-green' : 'text-danger'}
          >
            {data.groupIsEnabled ? 'YES' : 'NO'}
          </div>
        ),
      },
      {
        key: 'meta',
        displayText: 'View Meta',
        renderer: (data) => (
          <Button
            variant="link"
            onClick={() => {
              this.setState({
                curatedGroupMetaData: null,
                viewCuratedGroupMetaModal: true,
                curatedGroupMetaDataLoadStatus: 'loading',
              }, this.loadCuratedGroupMeta(data.groupId));
            }}
            className="fs-01 p-0"
          >
            META
          </Button>
        ),
      },
      ...(
        this.permissions
        && (
          this.permissions.ratroactive
          || this.permissions.write
        ) ? [
            {
              key: 'other-action',
              displayText: 'Other Action',
              renderer: (data) => (
                <select
                  className="form-control fs-01 minw-120p"
                  onChange={(event) => {
                    if (!event.target.value) {
                      return;
                    }
                    const modalActionableData = {
                      selectedCuratedGrToPerformAction: data,
                      showPerformingActionModal: true,
                      action: event.target.value,
                      isEnabled: data.groupIsEnabled,
                    };
                    if (event.target.value === 'DELETE_CURATED_GROUP') {
                      this.getGroupStatus(
                        {
                          ...modalActionableData,
                          deleteBackendRespStatus: '',
                        },
                        true,
                      );
                      return;
                    }
                    this.setState({
                      ...modalActionableData,
                    });
                  }}
                  value={
                  selectedCuratedGrToPerformAction
                  && selectedCuratedGrToPerformAction.groupId === data.groupId
                    ? action : ''
                }
                >
                  <option value="">--SELECT ACTION--</option>
                  {
                  data.allowedActions.map((item) => (
                    <option value={item} key={item}>{item}</option>
                  ))
                }
                </select>
              ),
            },
          ] : []
      ),
      {
        key: 'productCount',
        displayText: 'Products Count',
        renderer: (data) => (
          <div className="text-center">{data.productCount}</div>
        ),
      },
      {
        key: 'view-products',
        displayText: 'View Products',
        renderer: (data) => (
          <Button
            variant="link"
            onClick={() => {
              if (
                data.productCount === 0
                && this.permissions
                && (
                  this.permissions.ratroactive
                  || this.permissions.write
                )) {
                this.setState({
                  selectedCuratedGrToPerformAction: data,
                  showPerformingActionModal: true,
                  action: 'UPDATE_LIBRARY_PRODUCTS',
                  isEnabled: data.groupIsEnabled,
                });
                return;
              }
              history.push(`/library-products?curatedProductGroupId=${data.groupId}`);
            }}
            className="p-0 fs-01"
          >
            {
              data.productCount === 0
              && this.permissions
              && (
                this.permissions.ratroactive
                || this.permissions.write
              ) ? 'ADD' : 'VIEW'
            }
          </Button>
        ),
      },
      {
        key: 'curated-gr-map',
        displayText: 'Curated Group Map',
        renderer: (data) => (
          <Button
            variant="link"
            onClick={() => {
              history.push(`/smart-catalogue-map?curatedProductGroupId=${data.groupId}${
                !data.hasCuratedGroupMap
                && this.permissions
                && (
                  this.permissions.ratroactive
                  || this.permissions.write
                ) ? '&showPerformingActionModal=true' : ''}`);
            }}
            className="p-0 fs-01"
          >
            {!data.hasCuratedGroupMap
            && this.permissions
            && (
              this.permissions.ratroactive
              || this.permissions.write
            ) ? 'ADD' : 'VIEW'}
          </Button>
        ),
      },
    ];

    if (loading || error) {
      return (
        <div
          className="pt-3 text-center"
        >
          {loading ? (
            <Spinner
              animation="border"
              variant="primary"
            />
          ) : (
            <>
              <span
                className="text-danger"
              >
                Something Went Wrong
              </span>
              <div>
                <Button
                  variant="primary"
                  onClick={() => this.retry()}
                >
                  RETRY
                </Button>
              </div>
            </>
          )}
        </div>
      );
    }

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        <CustomModal
          show={!!deleteCuratedGroupStatus}
          onHide={() => {
            if (deleteCuratedGroupStatus !== 'LOADING') {
              this.closeDeleteCuratedAlert();
            }
          }}
          title={this.getTitleForAction(action)}
          closeButton
          autoSize
          size="md"
          body={(
            <Container
              className="py-3"
            >
              {(() => {
                let deleteActionJSX = null;
                switch (deleteCuratedGroupStatus) {
                  case 'LOADING':
                    deleteActionJSX = (
                      <Row
                        className="justify-content-center"
                      >
                        <Spinner
                          animation="border"
                          variant="primary"
                        />
                      </Row>
                    );
                    break;

                  case 'ERROR':
                    deleteActionJSX = (
                      <Row>
                        <Col
                          xs={24}
                          className="text-danger text-center"
                        >
                          Oops, Something went wrong. Please Try Again!
                        </Col>
                        <Col
                          xs={24}
                          className="d-flex justify-content-center pt-3"
                        >
                          <Button
                            variant="primary"
                            onClick={() => {
                              this.getGroupStatus(showDeleteCuratedGroupInfo, true);
                            }}
                          >
                            RETRY
                          </Button>
                        </Col>
                      </Row>
                    );
                    break;

                  case 'FIRST_SUCCESS':
                  case 'FINAL_SUCCESS':
                    deleteActionJSX = (
                      <Row>
                        <Col
                          xs={24}
                          className="text-center"
                        >
                          {deleteBackendRespStatus}
                        </Col>
                        {
                          deleteCuratedGroupStatus === 'FIRST_SUCCESS' && (
                            <Col
                              xs={24}
                              className="d-flex justify-content-end pt-3"
                            >
                              <Button
                                variant="danger"
                                onClick={() => {
                                  this.getGroupStatus(showDeleteCuratedGroupInfo, false);
                                }}
                              >
                                DELETE
                              </Button>
                              <Button
                                variant="primary"
                                className="ml-3"
                                onClick={() => {
                                  this.closeDeleteCuratedAlert();
                                }}
                              >
                                CLOSE
                              </Button>
                            </Col>
                          )
                        }
                      </Row>
                    );
                    break;
                  default:
                    break;
                }
                return deleteActionJSX;
              })()}
            </Container>
          )}
        />
        <CustomModal
          show={viewCuratedGroupMetaModal}
          onHide={() => {
            this.setState({
              viewCuratedGroupMetaModal: false,
              curatedGroupMetaData: null,
            });
          }}
          title="Curated Group Meta Data"
          closeButton
          autoSize
          size="lg"
          body={(
            <Container
              className="pb-3"
            >
              {
                curatedGroupMetaDataLoadStatus === 'loading' && (
                  <div
                    className="pt-3 text-center"
                  >
                    <Spinner
                      animation="border"
                      variant="primary"
                    />
                  </div>
                )
              }
              {
                curatedGroupMetaDataLoadStatus === 'error' && (
                  <div
                    className="pt-3 text-center text-danger"
                  >
                    Something Went Wrong
                    <Button
                      variant="primary"
                      onClick={() => this.retry()}
                      className="ml-3"
                    >
                      RETRY
                    </Button>
                  </div>
                )
              }
              {
                curatedGroupMetaData && (curatedGroupMetaData).map((item) => (
                  <Row key={item.key}>
                    <Col xs={8} className="border py-1">
                      {item.key}
                    </Col>
                    <Col xs={8} className="border py-1">
                      {item.value}
                    </Col>
                    <Col xs={8} className="border py-1 text-truncate-2">
                      {
                        item.href
                          ? (
                            <a
                              href={item.href || ''}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: item.color,
                              }}
                            >
                              {item.href}
                              <span className="pl-2">&#8599;</span>
                            </a>
                          ) : (
                            <span>NA</span>
                          )
                      }
                    </Col>
                  </Row>
                ))
              }
            </Container>
          )}
        />
        <CustomModal
          show={showPerformingActionModal}
          title={this.getTitleForAction(action)}
          onHide={() => {
            this.setState({
              showPerformingActionModal: false,
              submitError: false,
              syncType: 'IGNORE',
              groupName: '',
              reason: '',
              isEnabled: false,
              action: '',
              libraryProductIds: '',
            });
          }}
          size="lg"
          closeButton
          body={(
            <Container>
              {
                selectedCuratedGrToPerformAction
                && (action !== 'ADD_CURATED_GROUP')
                && (
                  <>
                    <Row
                      className="pt-3"
                    >
                      <Col xs={8}>
                        <b>Group Id:</b>
                      </Col>
                      <Col
                        xs={16}
                        className="px-3"
                      >
                        {selectedCuratedGrToPerformAction.groupId}
                      </Col>
                    </Row>
                    <Row
                      className="pt-3"
                    >
                      <Col xs={8}>
                        <b>Group Name:</b>
                      </Col>
                      <Col
                        xs={16}
                        className="px-3 text-truncate"
                      >
                        {selectedCuratedGrToPerformAction.groupName}
                      </Col>
                    </Row>
                  </>
                )
              }
              {
                action === 'ADD_CURATED_GROUP' && (
                  <Row
                    className="pt-3"
                  >
                    <Col
                      xs={8}
                    >
                      <b>Group Name:</b>
                    </Col>
                    <Col
                      xs={16}
                      className="px-3"
                    >
                      <input
                        type="text"
                        className="form-control"
                        name="groupName"
                        value={groupName}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                )
              }
              {
                action === 'UPDATE_LIBRARY_PRODUCTS' && (
                  <>
                    <Row
                      className="pt-3"
                    >
                      <Col
                        xs={8}
                      >
                        <b>Library Product Ids:</b>
                      </Col>
                      <Col
                        xs={16}
                        className="px-3"
                      >
                        <textarea
                          rows={3}
                          className="form-control"
                          name="libraryProductIds"
                          value={libraryProductIds}
                          onChange={this.handleChange}
                        />
                      </Col>
                    </Row>
                    <Row
                      className="pt-3"
                    >
                      <Col
                        xs={8}
                      >
                        <b>Upload Library Product Ids via CSV</b>
                      </Col>
                      <Col xs="auto">
                        <input
                          type="file"
                          accept=".csv"
                          onChange={this.onfilechanged}
                        />
                        {
                          csvFileError && (
                            <div className="text-danger fs-01 pt-2">
                              *Upload Valid CSV file
                            </div>
                          )
                        }
                      </Col>
                      <Col xs={4} className="ml-auto">
                        <Link
                          to={{ pathname: 'https://apionlinedelivery-crm.mpaani.com/sample_group_product_add_sample.csv' }}
                          target="_blank"
                        >
                          <Button variant="primary" className="fs-01 py-1">
                            SAMPLE CSV
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                    <Row
                      className="pt-3"
                    >
                      <Col
                        xs={8}
                      >
                        <b>Method:</b>
                      </Col>
                      <Col
                        className="px-3"
                        xs={16}
                      >
                        <select
                          name="method"
                          id="method"
                          className="form-control"
                          value={method}
                          onChange={this.handleChange}
                        >
                          <option value="">--Select Method--</option>
                          <option value="ADD">ADD</option>
                          <option value="REMOVE">REMOVE</option>
                          <option value="SET_AS_CURRENT_LIST">SET_AS_CURRENT_LIST</option>
                        </select>
                      </Col>
                    </Row>
                    <Row
                      className="pt-3"
                    >
                      <Col
                        xs={8}
                      >
                        <b>Products Sync Type:</b>
                      </Col>
                      <Col
                        className="px-3"
                        xs={16}
                      >
                        <select
                          name="productsSyncType"
                          id="productsSyncType"
                          className="form-control"
                          value={productsSyncType}
                          onChange={this.handleChange}
                        >
                          <option value="">--Select Products Sync Type--</option>
                          <option value="APPEND">APPEND PRODUCTS</option>
                          <option value="REPLACE">REPLACE PRODUCTS</option>
                        </select>
                      </Col>
                    </Row>
                  </>
                )
              }
              {
                action !== 'ADD_CURATED_GROUP'
                && action !== 'UPDATE_ENABLED_STATE'
                && (
                  <Row
                    className="pt-3"
                  >
                    <Col
                      xs={8}
                    >
                      <b>Sync Trigger At:</b>
                    </Col>
                    <Col
                      className="px-3"
                      xs={16}
                    >
                      <select
                        name="syncType"
                        id="syncType"
                        className="form-control"
                        value={syncType}
                        onChange={this.handleChange}
                      >
                        <option value="IGNORE">IGNORE</option>
                        {
                          this.permissions && this.permissions.ratroactive && (
                            <>
                              <option value="NOW">NOW</option>
                              <option value="NIGHT">NIGHT</option>
                            </>
                          )
                        }
                      </select>
                    </Col>
                  </Row>
                )
              }
              {
                action === 'UPDATE_ENABLED_STATE' && (
                  <Row
                    className="pt-3"
                  >
                    <Col
                      xs={8}
                    >
                      <b>Enabled:</b>
                    </Col>
                    <Col
                      xs={4}
                      className="px-3"
                    >
                      <Form.Check
                        inline
                        label="Yes"
                        name="isEnabled"
                        type="radio"
                        checked={isEnabled}
                        onChange={this.handleRadioButtonChange}
                      />
                    </Col>
                    <Col
                      xs={4}
                      className="px-3"
                    >
                      <Form.Check
                        inline
                        label="No"
                        name="isEnabled"
                        type="radio"
                        checked={!isEnabled}
                        onChange={this.handleRadioButtonChange}
                      />
                    </Col>
                  </Row>
                )
              }
              {
                (action === 'ARCHIVE_CURATED_GROUP' || action === 'UPDATE_ENABLED_STATE')
                && (
                  <Row
                    className="pt-3"
                  >
                    <Col
                      xs={8}
                    >
                      <b>Reason:</b>
                    </Col>
                    <Col
                      xs={16}
                      className="px-3"
                    >
                      <textarea
                        rows="3"
                        className="form-control"
                        name="reason"
                        value={reason}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                )
              }
              <Row>
                <Col
                  xs={24}
                  className="text-center pt-5"
                >
                  <Button
                    variant="primary"
                    className="px-3 py-2"
                    disabled={
                      submitting
                      || (!reason && ['ARCHIVE_CURATED_GROUP', 'UPDATE_ENABLED_STATE'].includes(action))
                      || (!groupName && action === 'ADD_CURATED_GROUP')
                      || (action === 'UPDATE_ENABLED_STATE'
                          && selectedCuratedGrToPerformAction.groupIsEnabled === isEnabled)
                      || ((!method || !libraryProductIds) && action === 'UPDATE_LIBRARY_PRODUCTS')
                    }
                    onClick={this.performAction}
                  >
                    {
                      submitting && (
                        <Spinner
                          variant="light"
                          size="sm"
                          animation="border"
                        />
                      )
                    }
                    &nbsp;&nbsp;&nbsp;Submit&nbsp;&nbsp;&nbsp;
                  </Button>
                </Col>
                {submitError && (
                  <Col
                    xs={24}
                    className="text-center text-danger pt-3"
                  >
                    <b>
                      {
                        submitErrorMsg || 'Oops Something went Wrong!!'
                      }
                    </b>
                  </Col>
                )}
              </Row>
            </Container>
          )}
        />
        <Row
          className="h-100 flex-column"
        >
          <Col
            className="flex-grow-0"
          >
            <Row
              className="align-items-center py-2"
            >
              <Col
                xs="auto"
                className="px-2 pt-2"
              >
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className="rounded-0"
                    >
                      <Svg
                        svg="search"
                        width="1rem"
                        fill={Constant.Color.DARK}
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    placeholder="Group Name"
                    name="curatedGroupName"
                    className="fs-01 rounded-0"
                    value={curatedGroupName}
                    onChange={this.handleOnChange}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        this.onSubmitSearchText();
                      }
                    }}
                  />
                </InputGroup>
              </Col>
              <Col
                xs="auto"
                className="px-2 pt-2"
              >
                <CustomDropdown
                  item={{
                    key: 'isEnabled',
                    displayText: 'Enabled',
                    options: [
                      {
                        label: 'True',
                        value: 'true',
                      },
                      {
                        label: 'False',
                        value: 'false',
                      },
                      {
                        label: 'None',
                        value: '',
                      },
                    ],
                  }}
                  onChange={(data) => {
                    this.handleDropdownChange(data);
                  }}
                  selectedVal={param.isEnabled}
                />
              </Col>
              {
                this.permissions
                && (this.permissions.ratroactive
                || this.permissions.write)
                && (
                  <Col
                    xs="auto"
                    className="px-2 pt-2"
                  >
                    <Button
                      variant="primary"
                      className="fs-01"
                      onClick={() => {
                        this.setState({
                          showPerformingActionModal: true,
                          action: 'ADD_CURATED_GROUP',
                          groupName: '',
                          syncType: 'IGNORE',
                        });
                      }}
                    >
                      ADD NEW GROUP
                    </Button>
                  </Col>
                )
              }
              {
                actionSuccessMsg && (
                  <Col
                    xs="auto"
                    className="px-2 pt-2"
                  >
                    <div className="text-success">
                      Action Performed SuccessFully!!
                    </div>
                  </Col>
                )
              }
            </Row>
          </Col>
          <Col
            className="px-0 flex-grow-1 overflow-y"
          >
            <CustomTable
              headers={headers}
              content={curatedGroups}
              keyField="groupId"
              l={param.l}
              p={param.p}
              rowsPerPage={rowsPerPage}
              page={page}
              totalItems={curatedGroupsCount}
              hasPrev={hasPrevious}
              hasNext={hasNext}
              onNext={this.onNext}
              onPrev={this.onPrev}
              onSubmitPage={this.onSubmitPage}
              onSubmitRowsPerPage={this.onSubmitRowsPerPage}
              updateRowsPageInput={this.handleRowsPageInput}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

SmartCatalogues.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default SmartCatalogues;
