import React, { Component } from 'react';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { customerDetails } from '../../assets/api/axios';
import { CustomModal, CustomTable, ErrorHandler } from '../../component/common';

const getStatusIcon = (status) => (
  status
    ? <span className="text-success">&#10004;</span>
    : <span className="text-danger">&#10006;</span>
);

function getCellValue(item) {
  if (item.renderer) {
    return item.renderer();
  }
  return item.value;
}

class CustomerPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      customerId: '',
      couponCode: '',
      storeId: '',
      customerInfo: null,
      couponDetails: null,
      couponLogs: null,
      orderLogs: null,
      errorMessage: '',
      couponLoading: false,
      couponError: false,
      logLoading: true,
      logError: false,
      showCouponLogs: false,
      showOrderLogs: false,
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleCustomer = (coupon = false) => {
    const { customerId, couponCode, storeId } = this.state;
    const params = coupon && {
      couponCode,
      storeId,
    };

    customerDetails(
      'GET',
      {
        ...params,
        customerId,
      },
    ).then((res) => {
      if (res.status === 200) {
        this.setState(() => {
          if (couponCode && coupon) {
            return {
              couponDetails: res.data,
              couponLoading: false,
            };
          }
          return {
            customerInfo: res.data,
            couponDetails: null,
            couponCode: '',
            storeId: '',
            loading: false,
          };
        });
      } else {
        throw new Error();
      }
    }).catch((err) => {
      let errorMessage = 'Oops Something Went Wrong!! Please Try Again!!';
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.server_response
      ) {
        errorMessage = err.response.data.server_response;
      }
      this.setState(() => {
        if (couponCode && coupon) {
          return {
            errorMessage,
            couponLoading: false,
            couponError: true,
          };
        }
        return {
          errorMessage,
          loading: false,
          error: true,
        };
      });
    });
  }

  handleLogs = (action) => {
    const { customerId } = this.state;
    customerDetails(
      'GET',
      {
        customerId,
        action,
      },
    ).then((res) => {
      if (res.status === 200) {
        this.setState(() => {
          if (action === 'Orders_Log') {
            return {
              orderLogs: res.data,
              logLoading: false,
            };
          }
          return {
            couponLogs: res.data,
            logLoading: false,
          };
        });
      } else throw new Error();
    }).catch(() => {
      this.setState({
        logLoading: false,
        logError: true,
      });
    });
  }

  render() {
    const {
      loading, error, customerId, customerInfo,
      errorMessage, couponCode, storeId,
      couponDetails, showCouponLogs, showOrderLogs,
      couponLogs, orderLogs, logLoading, logError,
      couponLoading, couponError,
    } = this.state;

    const flashSaleHeaders = [
      {
        key: 'appExId',
        displayText: 'Flash Sale Id',
        renderer: (data) => (
          <Link
            to="/flash-sale-detailed"
            className="text-primary"
          >
            {data.appExId}
          </Link>
        ),
      },
      {
        key: 'displayName',
        displayText: 'Product Name',
      },
      {
        key: 'mrp',
        displayText: 'MRP',
      },
      {
        key: 'appExOfferAmount',
        displayText: 'Offer Amount',
      },
      {
        key: 'expiryDate',
        displayText: 'Valid Till',
      },
    ];

    const CouponHeaders = [
      {
        key: 'couponCode',
        displayText: 'Coupon Code',
        renderer: (data) => (
          <Link
            to={`/coupon/${data.couponId}`}
            className="text-primary"
          >
            {data.couponCode}
          </Link>
        ),
      },
      {
        key: 'description',
        displayText: 'Description',
      },
      {
        key: 'canApply',
        displayText: 'Can Apply',
        renderer: (data) => (
          <div
            className={data.canApply ? 'text-green' : 'text-danger'}
          >
            {data.canApply ? 'YES' : 'NO'}
          </div>
        ),
      },
      {
        key: 'errorMsg',
        displayText: 'Reason',
        renderer: (data) => (
          <span>
            { data.errorMsg || 'NA' }
          </span>
        ),
      },
      {
        key: 'validTill',
        displayText: 'Valid Till',
      },
      {
        key: 'discountValue',
        displayText: 'Discount',
      },
    ];

    const loader = () => (
      <Col
        className="p-3 mx-auto text-center"
        xs={24}
      >
        <Spinner
          variant="primary"
          animation="border"
        />
      </Col>
    );

    const showError = (errorMsg = 'Oops Something Went Wrong!! Please Try Again!!') => (
      <Col
        className="p-3 mx-auto text-center text-danger"
        xs={24}
      >
        <b>
          {errorMsg}
        </b>
      </Col>
    );

    const showMessage = () => (
      <Col
        className="p-4 text-secondary"
        xs={24}
      >
        Search result with above search terms will appear here.
      </Col>
    );

    const list = [
      {
        key: 'column1',
        content: [
          {
            label: 'Name : ',
            value: (customerInfo && customerInfo.name) || '--',
          },
          {
            label: 'Phone Number : ',
            value: (customerInfo && customerInfo.phoneNumber) || '--',
          },
          {
            label: 'Default Address : ',
            value: (customerInfo && customerInfo.defaultAddress) || '--',
          },
        ],
      },
      {
        key: 'column2',
        content: [
          {
            label: 'First Order Date : ',
            value: (customerInfo && customerInfo.firstOrderDate) || '--',
          },
          {
            label: 'First Order Coupon Code : ',
            value: (customerInfo && customerInfo.firstOrderCouponCode) || '--',
          },
          {
            label: 'First Order Store ID : ',
            value: (customerInfo && customerInfo.firstOrderStoreId) || '--',
            renderer: () => (
              <Link
                to={`/retailer/${customerInfo.firstOrderStoreId}`}
                className="text-primary"
              >
                {customerInfo.firstOrderStoreId}
              </Link>
            ),
          },
        ],
      },
      {
        key: 'column3',
        content: [
          {
            label: 'Daily FS Limit Reached : ',
            value: (customerInfo && customerInfo.dailyFlashSaleLimitReached) ? 'Yes' : 'No',
          },
          {
            label: 'Weekly FS Limit Reached : ',
            value: (customerInfo && customerInfo.weeklyFlashSaleLimitReached) ? 'Yes' : 'No',
          },
          {
            label: 'Monthly FS Limit Reached : ',
            value: (customerInfo && customerInfo.monthlyFlashSaleLimitReached) ? 'Yes' : 'No',
          },
        ],
      },
    ];

    const showCustomerDetails = () => (
      <Container
        fluid
        className="p-3"
      >
        <Row
          className="px-4 border-bottom-green pb-3"
        >
          {
            list.map((column) => (
              <Col
                md={8}
                key={column.key}
              >
                {
                  column.content.map((item) => (
                    <Row>
                      <div>
                        <b>
                          {item.label}
                        </b>
                      </div>
                      <div>
                        {getCellValue(item)}
                      </div>
                    </Row>
                  ))
                }
              </Col>
            ))
          }
        </Row>
        {
          customerInfo && customerInfo.eligibleFlashsales && (
            <Row
              className="border-bottom-green pb-3"
            >
              <Col
                xs={24}
                className="d-flex align-items-center justify-content-center pt-3 fs-3"
              >
                Eligible Flash Sales
              </Col>
              <Col
                xs={24}
                className="overflow-y-scroll"
              >
                <CustomTable
                  headers={flashSaleHeaders}
                  content={customerInfo.eligibleFlashsales}
                  keyField="flashSaleId"
                  totalItems={customerInfo.eligibleFlashsales.length}
                  isPaginated={false}
                />
              </Col>
            </Row>
          )
        }
        {
          customerInfo && customerInfo.eligibleCoupons && (
            <Row
              className="border-bottom-green py-3"
            >
              <Col
                xs={24}
                className="d-flex align-items-center
                  justify-content-center fs-3"
              >
                Eligible Coupons
              </Col>
              <Col
                xs={24}

              >
                <CustomTable
                  headers={CouponHeaders}
                  content={customerInfo.eligibleCoupons}
                  totalItems={customerInfo.eligibleCoupons.length}
                  keyField="couponId"
                  isPaginated={false}
                />
              </Col>
            </Row>
          )
        }

        <Row
          className="pt-2"
        >
          <Col
            xs={24}
            className="d-flex align-items-center justify-content-center pb-3 fs-3"
          >
            Check Coupon Eligibility
          </Col>
          <Col
            xs={24}
            sm={12}
            md={10}
            className="d-flex"
          >
            <div>
              <b>
                <span className="text-danger">
                  *&nbsp;
                </span>
                Coupon Code:
              </b>
            </div>
            <div
              className="pl-3 w-75"
            >
              <input
                type="text"
                className="form-control"
                name="couponCode"
                placeholder="Enter the Coupon Code"
                value={couponCode}
                onChange={this.handleChange}
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.setState({
                      couponLoading: true,
                      couponDetails: null,
                    }, this.handleCustomer(true));
                  }
                }}
              />
            </div>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={6}
            className="d-flex"
          >
            <div>
              <b>Store Id:</b>
            </div>
            <div
              className="pl-3 w-75"
            >
              <input
                type="text"
                className="form-control"
                name="storeId"
                placeholder="Store Id (Optional)"
                value={storeId}
                onChange={this.handleChange}
                onKeyPress={(e) => {
                  if (couponCode && e.which === 13) {
                    this.setState({
                      couponLoading: true,
                      couponDetails: null,
                    }, this.handleCustomer(true));
                  }
                }}
              />
            </div>
          </Col>
          <Col
            xs="auto"
            className="d-flex justify-content-center"
          >
            <Button
              variant="primary"
              onClick={() => {
                this.setState({
                  couponLoading: true,
                  couponDetails: null,
                }, this.handleCustomer(true));
              }}
              disabled={!couponCode || !customerId || couponLoading}
            >
              Submit
            </Button>
          </Col>
        </Row>
        <Row
          className="border-bottom-green pb-2"
        >
          {(() => {
            let showcase = null;
            if (!couponDetails && couponLoading) {
              showcase = (
                <>
                  {loader()}
                </>
              );
            } else if (!couponDetails && !couponLoading && couponError) {
              showcase = (
                <>
                  {showError(errorMessage)}
                </>
              );
            } else if (!couponDetails && !couponLoading && !couponError) {
              showcase = (
                <>
                  {showMessage()}
                </>
              );
            } else if (couponDetails) {
              showcase = (
                <Row
                  className="pt-4 px-3"
                >
                  <Col
                    className={`${couponDetails.canApply ? 'text-green' : 'text-danger'}`}
                  >
                    <b>
                      {!couponDetails.canApply && 'Not'}
                      &nbsp;
                      Eligible
                    </b>
                  </Col>
                  {
                    !couponDetails.canApply && (
                      <Col
                        xs="auto"
                      >
                        <b>Reason : &nbsp;</b>
                        <span
                          className="text-danger"
                        >
                          {couponDetails.errorMsg || ''}
                        </span>
                      </Col>
                    )
                  }
                </Row>
              );
            }
            return showcase;
          })()}
        </Row>
        <Row
          className="p-3 border-bottom"
        >
          <Col
            className="d-flex justify-content-end"
          >
            <Button
              variant="primary"
              onClick={() => {
                this.setState({
                  logLoading: true,
                  showCouponLogs: true,
                }, this.handleLogs('Coupons_Attempts_Log'));
              }}
              disabled={!customerId}
            >
              View Coupon Attempts Log
            </Button>
          </Col>
          <Col>
            <Button
              variant="primary"
              onClick={() => {
                this.setState({
                  logLoading: true,
                  showOrderLogs: true,
                }, this.handleLogs('Orders_Log'));
              }}
              disabled={!customerId}
            >
              View Orders Log
            </Button>
          </Col>
        </Row>

      </Container>
    );

    return (
      <Container
        fluid
        className="bg-white"
      >
        <CustomModal
          show={showCouponLogs}
          title="Coupon Attempts Log"
          size="xl"
          onHide={() => {
            this.setState({
              showCouponLogs: false,
            });
          }}
          body={(
            <>
              {(() => {
                let showBody = null;
                if (!couponLogs && logLoading) {
                  showBody = (
                    <>
                      {loader()}
                    </>
                  );
                } else if (!couponLogs && !logLoading && logError) {
                  showBody = (
                    <div
                      className="d-flex align-items-center
                        justify-content-center"
                    >
                      <ErrorHandler
                        retryLogic={() => {
                          this.setState({
                            logLoading: true,
                            logError: false,
                          }, this.handleLogs('Coupons_Attempts_Log'));
                        }}
                      />
                    </div>
                  );
                } else if (couponLogs) {
                  showBody = (
                    <Container>
                      <Row>
                        <Col>
                          <CustomTable
                            headers={[
                              {
                                key: 'coupon_code',
                                displayText: 'Coupon Code',
                              },
                              {
                                key: 'timestamp',
                                displayText: 'Timestamp',
                              },
                              {
                                key: 'response',
                                displayText: 'Response',
                              },
                              {
                                key: 'detailedResponce',
                                displayText: 'Response Details',
                              },
                            ]}
                            content={couponLogs.results}
                            keyField="couponCode"
                            totalItems={couponLogs.count}
                            isPaginated={false}
                          />
                        </Col>
                      </Row>
                    </Container>
                  );
                }
                return showBody;
              })()}
            </>
          )}
          closeButton
        />
        <CustomModal
          show={showOrderLogs}
          title="Orders Log"
          size="xl"
          onHide={() => {
            this.setState({
              showOrderLogs: false,
            });
          }}
          body={(
            <>
              {(() => {
                let showBody = null;
                if (!orderLogs && logLoading) {
                  showBody = (
                    <>
                      {loader()}
                    </>
                  );
                } else if (!orderLogs && !logLoading && logError) {
                  showBody = (
                    <div
                      className="d-flex align-items-center
                        justify-content-center"
                    >
                      <ErrorHandler
                        retryLogic={() => {
                          this.setState({
                            logLoading: true,
                            logError: false,
                          }, this.handleLogs('Orders_Log'));
                        }}
                      />
                    </div>
                  );
                } else if (orderLogs) {
                  showBody = (
                    <Container>
                      <Row>
                        <Col>
                          <CustomTable
                            headers={[
                              {
                                key: 'orderId',
                                displayText: 'Order Id',
                              },
                              {
                                key: 'storeId',
                                displayText: 'Store Id',
                              },
                              {
                                key: 'storeName',
                                displayText: 'Store Name',
                              },
                              {
                                key: 'billAmount',
                                displayText: 'Bill Amount',
                              },
                              {
                                key: 'offerApplied',
                                displayText: 'LL Funded Offer Applied',
                              },
                              {
                                key: 'discountAmount',
                                displayText: 'Discount Offer',
                              },
                              {
                                key: 'sfoApplied',
                                displayText: 'SFO Applied',
                                renderer: (data) => getStatusIcon(data.sfoApplied),
                              },
                              {
                                key: 'deliveryStatus',
                                displayText: 'Delivery Status',
                              },
                            ]}
                            content={orderLogs.results}
                            keyField="orderId"
                            totalItems={orderLogs.count}
                            isPaginated={false}
                          />
                        </Col>
                      </Row>
                    </Container>
                  );
                }
                return showBody;
              })()}
            </>
          )}
          closeButton
        />
        <Row
          className="pt-3"
        >
          <Col
            md={10}
            className="d-flex"
          >
            <div>
              <b>
                <span className="text-danger">
                  *&nbsp;
                </span>
                Customer ID / Phone Number
              </b>
            </div>
            <div
              className="pl-3 w-50"
            >
              <input
                type="text"
                className="form-control"
                name="customerId"
                placeholder="Customer ID / Phone Number (Required*)"
                value={customerId}
                onChange={this.handleChange}
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.setState({
                      loading: true,
                      customerInfo: null,
                    }, this.handleCustomer());
                  }
                }}
              />
            </div>
          </Col>
          <Col
            xs="auto"
            className="d-flex justify-content-center"
          >
            <Button
              variant="primary"
              onClick={() => {
                this.setState({
                  loading: true,
                  customerInfo: null,
                }, this.handleCustomer());
              }}
              disabled={!customerId || loading}
            >
              Submit
            </Button>
          </Col>
        </Row>
        <Row
          className="my-4"
        >
          {(() => {
            let showCase = null;
            if (!customerInfo && loading) {
              showCase = (
                <>
                  {loader()}
                </>
              );
            } else if (!customerInfo && !loading && error) {
              showCase = (
                <>
                  {showError(errorMessage)}
                </>
              );
            } else if (!customerInfo && !loading && !error) {
              showCase = (
                <>
                  {showMessage()}
                </>
              );
            } else if (customerInfo) {
              showCase = (
                <>
                  {showCustomerDetails()}
                </>
              );
            }
            return showCase;
          })()}
        </Row>
      </Container>
    );
  }
}

export default CustomerPanel;
