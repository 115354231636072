import React from 'react';
import PropTypes from 'prop-types';
import { Button, Badge } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import Svg from './Svg';
import { Constant } from '../../utilities';

function getTwoDigitFormat(digit) {
  if (digit < 10) {
    return `0${digit}`;
  }
  return digit;
}

function getDateFormat(date) {
  const parts = new Date(date);
  return `${parts.getMonth() + 1}/${parts.getDate()}/${parts.getFullYear()}`;
}

function dateShowcase(date, timePicker) {
  const parts = new Date(date);
  if (timePicker) {
    if (parts.getTime()) {
      return `${getTwoDigitFormat(parts.getDate())}/${getTwoDigitFormat(parts.getMonth() + 1)}/${parts.getFullYear()} ${getTwoDigitFormat(parts.getHours())}:${getTwoDigitFormat(parts.getMinutes())}`;
    }
    return 'DD/MM/YY HH:MM';
  }
  if (parts.getTime()) {
    return `${getTwoDigitFormat(parts.getDate())}/${getTwoDigitFormat(parts.getMonth() + 1)}/${parts.getFullYear()}`;
  }
  return 'DD/MM/YY';
}

function DatePicker(props) {
  const {
    isDateRange, onApply, maxDate, minDate, startDate,
    endDate, autoApply, onClear, timePicker, clearDateSymbol, timePickerIncrement,
  } = props;
  const datePickerProps = {};
  if (maxDate) {
    datePickerProps.maxDate = getDateFormat(maxDate);
  }
  if (minDate) {
    datePickerProps.minDate = getDateFormat(minDate);
  }
  datePickerProps.startDate = getDateFormat(startDate
    || new Date());
  datePickerProps.endDate = datePickerProps.startDate;
  if (isDateRange) {
    datePickerProps.endDate = getDateFormat(endDate
      || new Date());
  }
  if (timePicker) {
    if (maxDate) {
      datePickerProps.maxDate = moment(maxDate);
    }
    if (minDate) {
      datePickerProps.minDate = moment(minDate);
    }
    datePickerProps.startDate = startDate ? new Date(startDate)
      : moment(new Date().setHours(0, 0, 0, 0));
    if (isDateRange) {
      datePickerProps.endDate = endDate ? new Date(endDate)
        : moment(new Date(datePickerProps.startDate).setHours(23, 59, 59, 999));
    }
  }
  return (
    <div
      className="bg-light d-flex align-items-center justify-content-center"
    >
      <DateRangePicker
        singleDatePicker={!isDateRange}
        {...datePickerProps}
        timePicker={timePicker}
        timePickerIncrement={timePickerIncrement}
        autoApply={autoApply}
        onEvent={(event, picker) => {
          switch (event.type) {
            case 'apply':
              if (onApply) {
                onApply(picker);
              }
              break;
            case 'show':
              break;
            case 'cancel':
              break;
            case 'hide':
              break;
            default:
              break;
          }
        }}
      >
        <Button
          variant="light"
          className="fs-01 d-flex align-items-center
           rounded-0 text-medium"
        >
          <Svg
            svg="calendar"
            width="1rem"
            fill={Constant.Color.MEDIUM}
          />
          &nbsp;
          &nbsp;
          {dateShowcase(startDate, timePicker)}
          {isDateRange && (
            <>
              &nbsp;
              &ndash;
              &nbsp;
              {dateShowcase(endDate, timePicker)}
            </>
          )}
        </Button>
      </DateRangePicker>
      {(startDate || endDate) && (clearDateSymbol) && (
        <Badge
          className="p-0 pr-2"
          onClick={onClear}
        >
          <Svg
            svg="circleClose"
            width="0.9rem"
            circleFill={Constant.Color.MEDIUM}
            pathFill={Constant.Color.WHITE}
          />
        </Badge>
      )}
    </div>
  );
}

DatePicker.propTypes = {
  isDateRange: PropTypes.bool,
  timePicker: PropTypes.bool,
  timePickerIncrement: PropTypes.number,
  onApply: PropTypes.func.isRequired,
  startDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  autoApply: PropTypes.bool,
  onClear: PropTypes.func.isRequired,
  clearDateSymbol: PropTypes.bool,
};

DatePicker.defaultProps = {
  isDateRange: false,
  timePicker: false,
  timePickerIncrement: 1,
  startDate: '',
  endDate: '',
  maxDate: new Date().getTime(),
  minDate: '',
  autoApply: true,
  clearDateSymbol: true,
};

export default DatePicker;
