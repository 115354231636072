import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/scss/wrapper/SideOverlay.scss';

function SideOverlay(props) {
  const {
    show,
    toggleSideOverlay,
    ChildComponent,
  } = props;
  return (
    <div
      className={`side-overlay ${show ? '' : 'hide-sideoverlay'}`}
    >
      <ChildComponent
        onFade={toggleSideOverlay}
        {...props}
      />
    </div>
  );
}

SideOverlay.propTypes = {
  show: PropTypes.bool.isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  ChildComponent: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.func.isRequired,
  ]).isRequired,
};

export default SideOverlay;
