import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import {
  Spinner, Container, Row, Col, Button, Form, InputGroup,
} from 'react-bootstrap';
import Axios from 'axios';
import {
  storeProduct, storeProductBulkEdit, productCategories as loadProductCategories, uploadImage,
} from '../../assets/api/axios';
import {
  ErrorHandler, CustomTable, CustomSwitch, Svg, CustomDropdown, CustomModal,
} from '../../component/common';
import { ProcessingStatus } from '../../component/derived/table-list';
import { Constant } from '../../utilities';
import AddNewProduct from '../../layouts/retailer-management/AddNewProduct';
import Permission from '../../access&permissions/permission';
import { getProductCategories, setProductCategories } from '../../utilities/Storage';
import { dateString, getHoursDiff, getMonolithErrorText } from '../../utilities/Utils';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, searchText, inStock, isCurated,
  } = param;
  const { internalSkuCodes, curatedProductGroupId } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  searchText = searchText || '';
  inStock = ['true', 'false'].includes(inStock) ? inStock : '';
  isCurated = ['true', 'false'].includes(isCurated) ? isCurated : '';
  return ({
    ...param, l, p, searchText, inStock, isCurated, internalSkuCodes, curatedProductGroupId,
  });
};

class Product extends React.Component {
  constructor(props) {
    const param = getQueryParams(props.history.location.search);
    let productCategories = getProductCategories();
    const { userPermission } = props;
    if (productCategories) {
      const hrDiff = getHoursDiff(productCategories.lastModifiedTime, new Date());
      if (hrDiff > 24) {
        productCategories = null;
      }
    }
    super();
    this.isForceAdd = userPermission.includes(Permission.FORCE_ADD_BULK_UPLOAD);
    this.state = {
      productCategories: (productCategories?.categories) || null,
      rowsPerPage: param.l,
      page: param.p,
      param,
      products: null,
      loading: true,
      error: false,
      searchText: param.searchText,
      selectProduct: '',
      productIds: [],
      addNewProduct: false,
      patchLoader: false,
      patchError: false,
      patchItem: null,
      showLesserSPAlert: false,
      selectImageId: '',
      downloadProduct: false,
      downloadProductStatusMsg: '',
      patchErrorMsg: '',
    };
    this.source = Axios.CancelToken.source();
    this.retry = () => {};
    this.persistedPatchItem = null;
    this.hiddenFileInput = React.createRef();
  }

  componentDidMount = () => {
    const { productCategories } = this.state;
    if (!productCategories) {
      loadProductCategories('GET', { requiredKeys: 'displayName,id,children' }).then((res) => {
        setProductCategories({
          lastModifiedTime: new Date(),
          categories: res.data.results,
        });
        this.setState({
          productCategories: res.data.results,
        });
      }).catch(() => {});
    }
    this.handleLoad();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleLoad = (data = {}) => {
    const { history, retailerDetails } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, searchText, inStock, isCurated, internalSkuCodes, curatedProductGroupId,
      productCategories,
    } = newParam;
    const offset = (p - 1) * l;
    storeProduct('get',
      null,
      null,
      {
        storeId: retailerDetails.code,
        offset: `${offset}`,
        limit: l,
        searchText,
        inStock,
        isCurated,
        internalSkuCodes,
        curatedProductGroupId,
        categoryIds: productCategories,
      }, this.source.token)
      .then((res) => {
        this.setState({
          rowsPerPage: l,
          page: p,
          param: { ...newParam },
          loading: false,
          error: false,
          products: { ...res.data },
          searchText,
          internalSkuCodes,
          curatedProductGroupId,
          productIds: [],
          selectProduct: '',
        }, () => {
          if (
            Object.keys(newParam).find((key) => (newParam[key] !== param[key]))
          ) {
            Object.keys(newParam).forEach((item) => {
              if (!newParam[item]) {
                delete newParam[item];
              }
            });
            history.push({
              path: pathname,
              search: QueryString.stringify(newParam),
            });
          }
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  handleSelectProduct = (data) => {
    const { products } = this.state;
    const { selectProduct } = data;
    this.setState({
      selectProduct,
      productIds: selectProduct === 'all'
        ? products.results.map((product) => product.id)
        : [],
    });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  onSubmitSearchText = (e) => {
    const {
      param,
    } = this.state;
    if (e.target.value !== param[e.target.name]) {
      this.handleRequestProcessing({ [e.target.name]: e.target.value, p: 1 });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const {
      l, p,
    } = param;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source;
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
    });
  }

  patchProductField = (continueAnyway = false) => {
    const { patchItem, products } = this.state;
    const finalPatchItem = patchItem || this.persistedPatchItem;
    const selectedData = products.results.find((item) => item.id === finalPatchItem.id);
    if ((finalPatchItem.field === 'sellingPrice') && !continueAnyway && (
      (((selectedData.mrp - finalPatchItem.value) * 100) / selectedData.mrp) >= 65
    )) {
      this.persistedPatchItem = finalPatchItem;
      this.setState({
        showLesserSPAlert: true,
        patchLoader: false,
      });
      return;
    }

    storeProduct(
      'patch',
      {
        [finalPatchItem.field]: finalPatchItem.value,
        ...(finalPatchItem.field === 'isDeleted' && { inStock: false }),
        forceAdd: this.isForceAdd,
      },
      finalPatchItem.id,
    )
      .then(() => {
        this.persistedPatchItem = null;
        this.setState({
          patchItem: null,
          patchLoader: false,
          patchError: false,
          patchErrorMsg: '',
          products: {
            ...products,
            results: products.results.map((product) => (
              product.id === finalPatchItem.id
                ? {
                  ...product,
                  [finalPatchItem.field]: finalPatchItem.value,
                  ...(finalPatchItem.field === 'isDeleted' && { inStock: false }),
                }
                : product
            )),
          },
        });
      })
      .catch((error) => {
        const patchErrorMsg = getMonolithErrorText(error);
        this.setState({
          patchErrorMsg,
          patchLoader: false,
          patchError: true,
        });
      });
  }

  handleMrpSpUpdate = (continueAnyway = false) => {
    this.setState({
      patchLoader: true,
      patchError: false,
      patchErrorMsg: '',
    }, () => {
      this.patchProductField(continueAnyway);
    });
  }

  handleProductSelection = (e) => {
    const { value, checked } = e.target;
    const { productIds } = this.state;
    let newProductIds = [...productIds];
    if (checked) {
      newProductIds.push(Number(value));
    } else {
      newProductIds = newProductIds.filter((id) => id !== Number(value));
    }
    this.setState({
      productIds: newProductIds,
    });
  }

  handleAction = (action) => {
    const { products, productIds } = this.state;
    let data = {};
    if (action === 'OUT_OF_STOCK' || action === 'IN_STOCK') {
      data = {
        productIds,
        action: 'UPDATE_STOCK_STATUS',
        field: action === 'IN_STOCK',
      };
    }
    storeProductBulkEdit('post', data)
      .then((res) => {
        const { results } = res.data;
        const newProductItems = [...products.results];
        results.forEach((item) => {
          const index = newProductItems.findIndex((product) => product.id === item.id);
          newProductItems[index] = {
            ...newProductItems[index],
            inStock: item.inStock,
          };
        });
        this.setState({
          loading: false,
          products: {
            ...products,
            results: newProductItems,
          },
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleAction(action);
      });
    };
  }

  toggleAddNewProductView = () => {
    const { addNewProduct } = this.state;
    this.setState({ addNewProduct: !addNewProduct });
  }

  handleImageOnChange = (event) => {
    const { selectImageId } = this.state;
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('img', file);
    uploadImage(
      'POST',
      formData,
    ).then((res) => {
      this.setState({
        patchItem: {
          id: selectImageId,
          field: 'imageUrl',
          value: res.data.url,
        },
      }, this.handleMrpSpUpdate);
    }).catch(() => {});
  }

  handleDownloadProduct = () => {
    const { retailerDetails } = this.props;
    const { param } = this.state;
    const {
      searchText, inStock, isCurated, internalSkuCodes, curatedProductGroupId,
      productCategories,
    } = param;
    this.setState({
      downloadProduct: true,
      downloadProductStatusMsg: '',
    });
    storeProduct(
      'GET',
      null,
      null,
      {
        storeId: retailerDetails.code,
        searchText,
        inStock,
        isCurated,
        internalSkuCodes,
        curatedProductGroupId,
        categoryIds: productCategories,
        downloadProduct: true,
      },
      this.source.token,
    ).then((res) => {
      this.setState({
        downloadProductStatusMsg: res.data.server_response,
        downloadProduct: false,
      });
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong!! Please Try Again!!';
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.server_response
      ) {
        errorMsg = err.response.data.server_response;
      }
      this.setState({
        downloadProductStatusMsg: errorMsg,
        downloadProduct: false,
      });
    });
  }

  addLibraryProduct = (libraryProductId) => {
    const { retailerDetails } = this.props;
    this.setState({
      patchLoader: true,
      patchError: false,
      patchErrorMsg: '',
    });
    storeProduct(
      'PATCH',
      {
        action: 'ADD_PRODUCT',
        storeId: retailerDetails.code,
        libraryProductId,
        forceAdd: this.isForceAdd,
      },
      libraryProductId,
    ).then(() => {
      this.setState({
        patchItem: null,
        patchLoader: false,
        patchError: false,
        patchErrorMsg: '',
      }, this.handleRequestProcessing);
    }).catch((error) => {
      const patchErrorMsg = getMonolithErrorText(error);
      this.setState({
        patchErrorMsg,
        patchLoader: false,
        patchError: true,
      });
    });
  }

  render() {
    const {
      loading, error, param, products, rowsPerPage, page, selectProduct,
      patchItem, patchLoader, patchError, searchText, addNewProduct,
      productIds, showLesserSPAlert, internalSkuCodes, curatedProductGroupId,
      productCategories, downloadProduct, downloadProductStatusMsg, patchErrorMsg,
    } = this.state;
    const { retailerDetails, userPermission } = this.props;

    const isPermittedToEdit = userPermission.includes(Permission.EDIT_PRODUCT);
    const isPermittedToAdd = userPermission.includes(Permission.ADD_PRODUCT);

    const getMrpSpShowcase = (data, field) => {
      if (patchItem && (patchItem.id === data.id)
      && patchItem.field === field) {
        let isValid = false;
        if (patchItem.value) {
          if (field === 'mrp' && (
            patchItem.value > data.sellingPrice - 1)) {
            isValid = true;
          }
          if (field === 'sellingPrice' && (
            patchItem.value < data.mrp + 1)) {
            isValid = true;
          }
          if (
            field === 'displayName'
            || field === 'unit'
            || field === 'uom'
          ) {
            isValid = true;
          }
        }
        if (patchLoader) {
          return (
            <Spinner
              animation="border"
              variant="primary"
              size="sm"
            />
          );
        }
        if (patchError) {
          return (
            <>
              <div>
                <Button
                  className="fs-01 rounded-0 mr-2"
                  size="sm"
                  onClick={this.handleMrpSpUpdate}
                >
                  <Svg
                    svg="retry"
                    width="0.8rem"
                    fill={Constant.Color.WHITE}
                  />
                </Button>
                <Button
                  variant="secondary"
                  className="fs-01 rounded-0"
                  size="sm"
                  onClick={() => {
                    this.setState({
                      patchItem: null,
                      patchLoader: false,
                      patchError: false,
                      patchErrorMsg: '',
                    });
                  }}
                >
                  <Svg
                    svg="arrow"
                    width="0.8rem"
                    fill={Constant.Color.BLACK}
                    transform="rotate(-90deg)"
                  />
                </Button>
              </div>
              <div
                className="fs-01 text-danger"
              >
                {patchErrorMsg}
              </div>
            </>
          );
        }
        return (
          <input
            type="text"
            value={patchItem.value}
            size={patchItem.value.toString().length || 1}
            onChange={(e) => {
              if (field === 'displayName' || field === 'uom') {
                this.setState({
                  patchItem: {
                    id: data.id,
                    field,
                    value: e.target.value || '',
                  },
                });
              } else {
                const { value } = e.target;
                const numVal = Number(value);
                if (value === '' || (numVal && numVal > 0)) {
                  this.setState({
                    patchItem: {
                      id: data.id,
                      field,
                      value: numVal || '',
                    },
                  });
                }
              }
            }}
            onKeyPress={(e) => {
              if (e.which === 13 && isValid
                && patchItem.value !== data[field]) {
                this.handleMrpSpUpdate();
              }
            }}
            onBlur={() => {
              this.setState({
                patchItem: null,
              });
            }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            className={`fs-01 text-medium ${isValid
              ? '' : 'border-danger'}`}
          />
        );
      }
      return (
        <Button
          variant="link"
          className="fs-01 text-medium p-0"
          onClick={() => {
            this.setState({
              patchItem: {
                id: data.id,
                field,
                value: data[field],
              },
            });
          }}
          disabled={!isPermittedToEdit}
        >
          {data[field] || '--'}
        </Button>
      );
    };

    const getImageShowCase = (data, field) => {
      if (patchItem && (patchItem.id === data.id)
      && (patchItem.field === field)) {
        if (patchLoader) {
          return (
            <div
              className="d-flex justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
                size="sm"
              />
            </div>
          );
        }
      }
      return (
        <div
          className="edit-product-image-div"
        >
          <Button
            variant="link"
            onClick={() => {
              this.setState({
                selectImageId: data.id,
              }, this.hiddenFileInput.current.click());
            }}
            disabled={!isPermittedToEdit}
          >
            <img
              src={data.imageUrl}
              alt=" "
            />
          </Button>
          <input
            type="file"
            className="d-none"
            ref={this.hiddenFileInput}
            onChange={(event) => {
              this.handleImageOnChange(event);
            }}
          />
        </div>
      );
    };

    const getSwitch = (field, data, status) => (
      <div
        className="py-1"
      >
        <CustomSwitch
          id={`${data.id}${field}`}
          displayTextLeft=""
          displayTextRight=""
          onChange={(e) => {
            this.setState({
              patchItem: {
                field,
                id: data.id,
                value: e.target.checked,
              },
            }, () => {
              this.patchProductField();
            });
          }}
          checked={status}
          disabled={!isPermittedToEdit}
        />
      </div>
    );

    const headers = [
      {
        key: 'id',
        displayText: 'Id',
      },
      {
        key: 'displayName',
        displayText: 'Name',
        renderer: (data) => getMrpSpShowcase(data, 'displayName'),
      },
      {
        key: 'approxGrammage',
        displayText: 'Approx Grammage',
        renderer: (data) => data?.approxGrammage || '--',
      },
      {
        key: 'mrp',
        displayText: 'MRP',
        renderer: (data) => getMrpSpShowcase(data, 'mrp'),
      },
      {
        key: 'sellingPrice',
        displayText: 'SP',
        renderer: (data) => getMrpSpShowcase(data, 'sellingPrice'),
      },
      {
        key: 'inStock',
        displayText: 'InStock',
        renderer: (data) => (data.isDeleted
          ? (
            <div
              className="py-1"
            >
              <CustomSwitch
                id={`${data.id}`}
                checked={data.inStock}
                disabled
                onChange={() => {}}
              />
            </div>
          )
          : getSwitch('inStock', data, data.inStock)
        ),
      },
      {
        key: 'isDeleted',
        displayText: 'IsDeleted',
        renderer: (data) => getSwitch(
          'isDeleted', data, data.isDeleted,
        ),
      },
      {
        key: 'imageUrl',
        displayText: 'Image',
        renderer: (data) => getImageShowCase(data, 'imageUrl'),
      },
      {
        key: 'primaryCategory',
        displayText: 'Primary Category',
      },
      {
        key: 'secondaryCategory',
        displayText: 'Sec Category',
      },
      {
        key: 'uom',
        displayText: 'UOM',
        renderer: (data) => {
          if (
            patchItem && (patchItem.id === data.id)
            && (patchItem.field === 'uom')
          ) {
            if (patchLoader) {
              return (
                <Spinner
                  animation="border"
                  variant="primary"
                  size="sm"
                />
              );
            }
            if (patchError) {
              return (
                <>
                  <div
                    className="d-flex"
                  >
                    <Button
                      className="fs-01 rounded-0 mr-2"
                      size="sm"
                      onClick={this.handleMrpSpUpdate}
                    >
                      <Svg
                        svg="retry"
                        width="0.8rem"
                        fill={Constant.Color.WHITE}
                      />
                    </Button>
                    <Button
                      variant="secondary"
                      className="fs-01 rounded-0"
                      size="sm"
                      onClick={() => {
                        this.setState({
                          patchItem: null,
                          patchLoader: false,
                          patchError: false,
                          patchErrorMsg: '',
                        });
                      }}
                    >
                      <Svg
                        svg="arrow"
                        width="0.8rem"
                        fill={Constant.Color.BLACK}
                        transform="rotate(-90deg)"
                      />
                    </Button>
                  </div>
                  <div
                    className="fs-01 text-danger"
                  >
                    {patchErrorMsg}
                  </div>
                </>
              );
            }
          }
          return (
            <select
              value={data.uom}
              className="p-1"
              onChange={(e) => {
                if (e.target.value !== '') {
                  this.setState({
                    patchItem: {
                      id: data.id,
                      field: 'uom',
                      value: e.target.value,
                    },
                  }, () => {
                    this.handleMrpSpUpdate();
                  });
                }
              }}
              disabled
            >
              <option value="">Select</option>
              <option value="Units">Units</option>
              <option value="GMs">GMs</option>
              <option value="KGs">KGs</option>
              <option value="ML">ML</option>
              <option value="L">L</option>
            </select>
          );
        },
      },
      {
        key: 'unit',
        displayText: 'Unit',
        // renderer: (data) => getMrpSpShowcase(data, 'unit'),
      },
      {
        key: 'isRetailerProduct',
        displayText: 'Created By',
        renderer: (data) => (
          data.isRetailerProduct ? 'RETAILER' : 'ADMIN'
        ),
      },
      {
        key: 'lastUpdatedBy',
        displayText: 'Last Modified By',
      },
      {
        key: 'modifiedOn',
        displayText: 'Last Modified On',
        renderer: (data) => dateString(data.modifiedOn),
      },
      {
        key: 'canAddProduct',
        displayText: 'Add Product',
        renderer: (data) => {
          if (
            patchItem && (patchItem.id === data.id)
            && (patchItem.field === 'add_product')
          ) {
            if (patchLoader) {
              return (
                <Spinner
                  animation="border"
                  variant="primary"
                  size="sm"
                />
              );
            }
            if (patchError) {
              return (
                <>
                  <div
                    className="d-flex"
                  >
                    <Button
                      className="fs-01 rounded-0 mr-2"
                      size="sm"
                      onClick={() => {
                        this.addLibraryProduct(data.id);
                      }}
                    >
                      <Svg
                        svg="retry"
                        width="0.8rem"
                        fill={Constant.Color.WHITE}
                      />
                    </Button>
                    <Button
                      variant="secondary"
                      className="fs-01 rounded-0"
                      size="sm"
                      onClick={() => {
                        this.setState({
                          patchItem: null,
                          patchLoader: false,
                          patchError: false,
                          patchErrorMsg: '',
                        });
                      }}
                    >
                      <Svg
                        svg="arrow"
                        width="0.8rem"
                        fill={Constant.Color.BLACK}
                        transform="rotate(-90deg)"
                      />
                    </Button>
                  </div>
                  <div
                    className="fs-01 text-danger"
                  >
                    {patchErrorMsg}
                  </div>
                </>
              );
            }
          }
          return (
            <Button
              variant="outline-primary"
              className="px-1 py-1 fs-01"
              onClick={() => {
                this.setState({
                  patchItem: {
                    id: data.id,
                    field: 'add_product',
                    value: data.id,
                  },
                }, () => {
                  this.addLibraryProduct(data.id);
                });
              }}
              disabled={!isPermittedToEdit || !data.canAddProduct}
            >
              {data.canAddProduct ? 'Add Product' : 'Added'}
            </Button>
          );
        },
      },
    ];

    const seachBars = [
      (
        <Form.Control
          type="text"
          placeholder="Search"
          name="searchText"
          className="fs-01 rounded-0"
          value={searchText}
          onChange={this.handleOnChange}
          autoComplete="off"
          onKeyPress={(e) => {
            if (e.which === 13) {
              this.onSubmitSearchText(e);
            }
          }}
        />
      ),
      (
        <Form.Control
          type="text"
          placeholder="SKU Codes"
          className="fs-01 rounded-0"
          value={internalSkuCodes}
          name="internalSkuCodes"
          onChange={this.handleOnChange}
          autoComplete="off"
          onKeyPress={(e) => {
            if (e.which === 13) {
              this.onSubmitSearchText(e);
            }
          }}
        />
      ),
      (
        <Form.Control
          type="text"
          placeholder="Smart Catalogue Map Id"
          className="fs-01 rounded-0"
          value={curatedProductGroupId}
          name="curatedProductGroupId"
          onChange={this.handleOnChange}
          autoComplete="off"
          onKeyPress={(e) => {
            if (e.which === 13) {
              this.onSubmitSearchText(e);
            }
          }}
        />
      ),
    ];

    const filterConfig = [
      {
        key: 'productCategories',
        displayText: 'Category',
        options: ((productCategories || []).map((item) => ({
          label: item.displayName,
          value: (item.id).toString(),
        }))),
      },
      {
        key: 'inStock',
        displayText: 'InStock',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
      {
        key: 'isCurated',
        displayText: 'isCurated',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
    ];

    if (selectProduct) {
      headers.unshift({
        key: 'action-dropdown',
        displayText: (
          <CustomDropdown
            item={{
              key: 'action',
              displayText: 'Action',
              options: [
                {
                  label: 'Mark Out of Stock',
                  value: 'OUT_OF_STOCK',
                },
                {
                  label: 'Mark InStock',
                  value: 'IN_STOCK',
                },
              ],
            }}
            onChange={(data) => {
              if (productIds.length) {
                this.setState({
                  loading: true,
                  error: false,
                }, () => {
                  this.handleAction(data.action);
                });
              }
            }}
            selectedVal=""
          />),
        renderer: (data) => (
          <div
            className="text-center"
          >
            <Form.Check
              custom
              id={`${data.id}-checkbox`}
              label=""
              value={data.id}
              checked={productIds.includes(data.id)}
              onChange={this.handleProductSelection}
            />
          </div>
        ),
      });
    }

    if (!products && loading) {
      return (
        <div
          className="d-flex align-items-center
           justify-content-center h-100"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!products && !loading && error) {
      return (
        <div
          className="d-flex align-items-center
           justify-content-center h-100"
        >
          <ErrorHandler
            retryLogic={() => {
              this.setState({
                loading: true,
                error: false,
              }, () => {
                this.handleLoad();
              });
            }}
          />
        </div>
      );
    }

    if (products) {
      return (
        <div
          id="retailer-product"
          className="table-list"
        >
          <CustomModal
            show={showLesserSPAlert}
            onHide={() => {
              this.setState({
                showLesserSPAlert: false,
              });
            }}
            autoSize
            body={(
              <div
                className="p-3"
              >
                <b>Are you sure?</b>
                <div
                  className="pt-1"
                >
                  The selling price you have entered is much lower than MRP.
                  Are you sure you want to go ahead?
                </div>
              </div>
            )}
            footer={(
              <>
                <Button
                  variant="link"
                  className="text-black"
                  onClick={() => {
                    this.persistedPatchItem = null;
                    this.setState({
                      showLesserSPAlert: false,
                    });
                  }}
                >
                  <b>GO BACK</b>
                </Button>
                <Button
                  variant="link"
                  onClick={() => {
                    this.handleMrpSpUpdate(true);
                    this.setState({
                      showLesserSPAlert: false,
                    });
                  }}
                >
                  <b>UPDATE</b>
                </Button>
              </>
            )}
          />
          <ProcessingStatus
            show={loading || error}
            loading={loading}
            error={error}
            onRetry={() => this.retry()}
            onCancel={this.onCancel}
          />
          <AddNewProduct
            show={addNewProduct}
            retailerCode={retailerDetails.code}
            toggleAddNewProductView={this.toggleAddNewProductView}
            userPermission={userPermission}
          />
          <Container
            fluid
            className="mx-0 px-0"
          >
            <Row
              className="mx-0"
            >
              <Col
                xs={24}
                className="px-0 filter-section mb-2 bg-white"
              >
                <Row
                  className="mx-0"
                >
                  {
                    seachBars.map((item) => (
                      <Col
                        xs="auto"
                        className="px-2 py-1"
                      >
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text
                              className="rounded-0"
                            >
                              <Svg
                                svg="search"
                                width="1rem"
                                fill={Constant.Color.DARK}
                              />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          {item}
                        </InputGroup>
                      </Col>
                    ))
                  }
                  <>
                    {filterConfig.map((item) => (
                      <Col
                        key={item.key}
                        xs="auto"
                        className="px-2 py-1"
                      >
                        <CustomDropdown
                          item={item}
                          onChange={this.handleDropdownChange}
                          selectedVal={param[item.key]}
                        />
                      </Col>
                    ))}
                  </>
                  <Col
                    xs="auto"
                    className="px-2 py-1"
                  >
                    <CustomDropdown
                      item={{
                        key: 'selectProduct',
                        displayText: 'Select Product',
                        options: [
                          {
                            label: 'All',
                            value: 'all',
                          },
                          {
                            label: 'Manually',
                            value: 'manually',
                          },
                          {
                            label: 'None',
                            value: '',
                          },
                        ],
                      }}
                      onChange={this.handleSelectProduct}
                      selectedVal={selectProduct}
                      disabled={!isPermittedToEdit}
                    />
                  </Col>
                  <Col
                    xs="auto"
                    className="px-2 py-1"
                  >
                    <Button
                      className="fs-01 font-weight-bold"
                      onClick={this.handleDownloadProduct}
                      disabled={downloadProduct}
                    >
                      Download Products
                    </Button>
                  </Col>
                  {
                    downloadProductStatusMsg && (
                      <Col
                        xs="auto"
                        className="px-2 py-2 text-success"
                      >
                        {downloadProductStatusMsg}
                      </Col>
                    )
                  }
                  <Col
                    xs="auto"
                    className="px-2 py-1 ml-auto"
                  >
                    <Button
                      className="fs-01 font-weight-bold"
                      onClick={this.toggleAddNewProductView}
                      disabled={!isPermittedToAdd}
                    >
                      Add New Product
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={24}
                className="px-0"
              >
                <CustomTable
                  headers={headers}
                  content={products.results}
                  keyField="id"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={products.count}
                  hasPrev={products.hasPrevious}
                  hasNext={products.hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    return null;
  }
}

Product.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default Product;
