import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomDropdown, CustomModal, ErrorHandler } from '../../component/common';
import { myReferral } from '../../assets/api/axios';
import { getDateString } from '../../utilities/Utils';

class ViewMyReferrals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      referrals: null,
      status: 'all',
    };
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleLoad = () => {
    const { status } = this.state;
    myReferral(
      'GET',
      {},
      {
        status,
      },
    ).then((res) => {
      this.setState({
        referrals: res.data,
        loading: false,
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleDropdownChange = (data) => {
    this.setState({
      status: data.status,
      loading: true,
      error: false,
    }, this.handleLoad);
  }

  render() {
    const { show, onHide } = this.props;
    const {
      loading, error, referrals, status,
    } = this.state;

    const filterConf = [
      {
        key: 'status',
        displayText: 'Status',
        options: [
          {
            label: 'Pending',
            value: 'Waiting',
          },
          {
            label: 'Rejected',
            value: 'Rejected',
          },
          {
            label: 'Onboarded',
            value: 'Approved',
          },
          {
            label: 'All',
            value: 'all',
          },
        ],
      },
    ];

    const body = (
      <>
        {(() => {
          let showcase = null;
          if (loading) {
            showcase = (
              <div
                className="text-center p-4"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (!loading && error) {
            showcase = (
              <ErrorHandler
                retryLogic={() => {
                  this.setState({
                    loading: true,
                    error: false,
                  }, () => {
                    this.handleLoad();
                  });
                }}
              />
            );
          } else if (referrals) {
            showcase = (
              <Container
                className="px-4 py-3"
              >
                {referrals.results.map((item, index) => (
                  <Row
                    className="py-2"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    <Col>
                      <Row>
                        <Col
                          className="font-weight-bold"
                        >
                          {item.shopName}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          className="pt-1 text-muted fs-0"
                        >
                          {getDateString(item.createdOn)}
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs="auto"
                      className="d-flex align-items-center justify-content-end"
                    >
                      <div
                        className={`${item.bgColor} ${item.textColor} py-1 px-2 order-button fs-0`}
                      >
                        {item.status}
                      </div>
                    </Col>
                  </Row>
                ))}
                {referrals.count === 0 && (
                  <div
                    className="d-flex align-items-center justify-content-center font-weight-bold"
                  >
                    Not Available any Referral
                  </div>
                )}
              </Container>
            );
          }
          return showcase;
        })()}
      </>
    );

    const title = (
      <Container className="px-4">
        <Row>
          <b>My Referrals</b>
        </Row>
        <Row className="py-3">
          {filterConf.map((item) => (
            <Col
              key={item.key}
              xs="auto"
              className="px-0"
            >
              <CustomDropdown
                item={item}
                onChange={this.handleDropdownChange}
                selectedVal={status}
                closeButton={false}
              />
            </Col>
          ))}
        </Row>
      </Container>
    );

    return (
      <CustomModal
        show={show}
        onHide={onHide}
        title={title}
        body={body}
        closeButton
        backdrop
      />
    );
  }
}

ViewMyReferrals.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default ViewMyReferrals;
