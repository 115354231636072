import React, { useEffect, useState } from 'react';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { marketplaceBrandOrderDetails } from '../../assets/api/axios';
import { dateString } from '../../utilities/Utils';

function BrandOrderDetails(props) {
  const { match } = props;
  const { orderId } = match.params;
  const [order, setOrder] = useState({
    loading: true,
    error: false,
    data: null,
  });

  const getOrderDetails = () => {
    marketplaceBrandOrderDetails(
      'GET',
      orderId,
      null,
      {},
    ).then((res) => {
      setOrder({
        ...order,
        loading: false,
        error: false,
        data: res.data.data,
      });
    }).catch(() => {
      setOrder({
        ...order,
        loading: false,
        error: true,
        data: null,
      });
    });
  };

  const onModifyOrder = (data = {}) => {
    marketplaceBrandOrderDetails('PATCH', orderId, data, {}).then((res) => {
      setOrder({
        ...order,
        data: res.data.data,
      });
    }).catch(() => {
      //
    });
  };

  const deliveryStatusStyle = (status) => {
    let deliveryStatusColor = '';
    switch (status) {
      case 'CANCELLED':
        deliveryStatusColor = 'bg-danger text-light border-red';
        break;
      case 'DELIVERED':
        deliveryStatusColor = 'bg-green text-light border-green';
        break;
      case 'OUT_FOR_DELIVERY':
        deliveryStatusColor = 'bg-lime text-primary border-lime';
        break;
      case 'MODIFIED':
        deliveryStatusColor = 'bg-orange text-light border-orange';
        break;
      case 'CHANGES_ACCEPTED':
        deliveryStatusColor = 'bg-green-yellow text-primary border-green-yellow';
        break;
      case 'CHANGES_REJECTED':
        deliveryStatusColor = 'bg-firebrick text-primary border-firebrick';
        break;
      case 'READY_TO_SHIP':
      case 'ACCEPTED':
        deliveryStatusColor = 'bg-olive text-light border-olive';
        break;
      case 'PENDING':
        deliveryStatusColor = 'bg-warning text-primary border-warning';
        break;
      default:
        break;
    }
    return deliveryStatusColor;
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  const orderDetailsData = order.data;

  return (
    <Container
      fluid
    >
      <Container
        className="mb-4 h-100 overflow-y-scroll"
      >
        <Row
          className="align-items-center justify-content-center"
        >
          {
            (order.loading || order.error) && (
              <div
                className="pt-3 text-center"
              >
                {order.loading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                ) : (
                  <>
                    <span
                      className="text-danger"
                    >
                      Something Went Wrong
                    </span>
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => {
                          getOrderDetails();
                        }}
                      >
                        Retry
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )
          }
        </Row>
        {
          orderDetailsData && (
            <>
              <Row
                className="p-2 mt-3 mx-3 bg-white border-radius-16"
              >
                <div
                  className="w-100"
                >
                  <select
                    id="order-status"
                    value={orderDetailsData.brandStatus}
                    className={`w-100 py-2 border-radius-8 ${deliveryStatusStyle(orderDetailsData.brandStatus)}`}
                    onChange={(event) => {
                      onModifyOrder({
                        orderStatus: event.target.value,
                      });
                    }}
                  >
                    <option value="PENDING">
                      PENDING
                    </option>
                    <option value="ACCEPTED">
                      ACCEPTED
                    </option>
                    <option value="READY_TO_SHIP">
                      READY TO SHIP
                    </option>
                    <option value="OUT_FOR_DELIVERY">
                      OUT FOR DELIVERY
                    </option>
                    <option value="DELIVERED">
                      DELIVERED
                    </option>
                    <option value="CANCELLED">
                      CANCELLED
                    </option>
                  </select>
                </div>
                <div
                  className="pt-1"
                >
                  Modified At -
                  &nbsp;
                  {dateString(orderDetailsData.modifiedAt)}
                </div>
              </Row>
              <Row
                className="p-2 mt-3 mx-3 bg-white border-radius-16"
              >
                <Col
                  xs={12}
                >
                  <div>
                    {orderDetailsData.store.name}
                  </div>
                  <div>
                    {orderDetailsData.store.phoneNumber}
                  </div>
                  <div>
                    <span>
                      {orderDetailsData.productCount}
                      &nbsp;
                      Items
                    </span>
                    <span>
                      &nbsp;·
                      ₹
                      {orderDetailsData.payment.amountPayable}
                      &nbsp;
                    </span>
                    <span>
                      ·&nbsp;
                      {orderDetailsData.payment.mode}
                    </span>
                  </div>
                  <div>
                    <span>
                      #
                      {orderDetailsData.orderId}
                    </span>
                    <span>
                      &nbsp;·&nbsp;
                      Delivery
                    </span>
                  </div>
                </Col>
                <Col
                  xs={12}
                >
                  <div
                    className="text-right"
                  >
                    {dateString(orderDetailsData.createdAt)}
                  </div>
                  <div
                    className={`fs-1 text-right font-weight-bold ${
                      orderDetailsData.brandStatus === 'CANCELLED' && 'text-red'
                    }
                    ${
                      orderDetailsData.brandStatus === 'DELIVERED' && 'text-green'
                    }
                    `}
                  >
                    {orderDetailsData.brandStatus}
                  </div>
                  {
                    (
                      orderDetailsData.storeStatus === 'ACCEPTED'
                      || orderDetailsData.storeStatus === 'REJECTED'
                    ) && (
                      <div
                        className="fs-1 text-right"
                      >
                        Marked by Retailer&nbsp;
                        <b
                          className="text-pink"
                        >
                          {orderDetailsData.storeStatus}
                        </b>
                      </div>
                    )
                  }
                  {
                    orderDetailsData.orderMessage && (
                      <div
                        className="fs-1 text-right"
                      >
                        Order Message
                        &nbsp;-&nbsp;
                        {orderDetailsData.orderMessage}
                      </div>
                    )
                  }
                </Col>
              </Row>

              <Row
                className="p-2 mt-3 mx-3 bg-white border-radius-16"
              >
                <Col
                  xs={24}
                  className="mb-3 font-weight-bold"
                >
                  Order Items
                  &nbsp;
                  (
                  {orderDetailsData.productCount}
                  )
                </Col>

                <Col
                  xs={24}
                >
                  {
                    orderDetailsData.retailerProducts.map((item) => (
                      <Row
                        key={item.productId}
                        className="align-items-center justify-content-center"
                      >
                        <Col
                          xs="auto"
                          style={{
                            width: '80px',
                            height: '80px',
                          }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <img
                            src={item.image}
                            alt={item.name}
                            className="mw-100 mh-100"
                          />
                        </Col>
                        <Col>
                          <div>
                            {item.name}
                          </div>
                          <div>
                            {item.measurementDisplayText}
                          </div>
                        </Col>
                        <Col
                          xs="auto"
                        >
                          <div>
                            QTY:&nbsp;
                            {item.quantity}
                          </div>
                          <div>
                            ₹
                            {item.totalPrice}
                          </div>
                        </Col>
                      </Row>
                    ))
                  }
                  {
                    !!(
                      orderDetailsData.appliedOffers
                      && orderDetailsData.appliedOffers.length
                    ) && (
                      orderDetailsData.appliedOffers.map((item) => (
                        <Row
                          key={item.id}
                          className="py-2 mb-1 border border-green border-radius-16 align-items-center justify-content-center"
                        >
                          <Col
                            xs="auto"
                            style={{
                              width: '80px',
                              height: '80px',
                            }}
                            className="d-flex align-items-center justify-content-center"
                          >
                            <img
                              src={item.image}
                              alt={item.name}
                              className="mw-100 mh-100"
                            />
                          </Col>
                          <Col>
                            <div>
                              {item.name}
                            </div>
                            <div>
                              {item.description}
                            </div>
                          </Col>
                        </Row>
                      ))
                    )
                  }
                </Col>
              </Row>

              <Row
                className="p-2 mt-3 mx-3 bg-white border-radius-16"
              >
                <Col
                  xs={24}
                  className="mb-3 font-weight-bold"
                >
                  Bill Details
                </Col>
                <Col
                  xs={24}
                >
                  {
                    [
                      {
                        id: 1,
                        name: 'Item Total',
                        value: orderDetailsData.payment.billBeforeDiscount,
                      },
                      {
                        id: 2,
                        name: 'Overall Discount',
                        value: orderDetailsData.payment.overallDiscount,
                      },
                      {
                        id: 3,
                        name: 'Total Paid',
                        value: orderDetailsData.payment.amountPaid,
                      },
                      {
                        id: 4,
                        name: 'Pending Payment',
                        value: orderDetailsData.payment.amountPending,
                      },
                      {
                        id: 5,
                        name: 'Total Bill',
                        value: orderDetailsData.payment.amountPayable,
                      },
                    ].map((item) => (
                      <Row
                        key={item.id}
                        className="align-items-center justify-content-center"
                      >
                        <Col>
                          {item.name}
                        </Col>
                        <Col
                          xs="auto"
                          className="text-right"
                        >
                          ₹
                          {item.value}
                        </Col>
                      </Row>
                    ))
                  }
                </Col>
              </Row>
            </>
          )
        }
      </Container>
    </Container>
  );
}

BrandOrderDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderId: PropTypes.string,
    }),
  }).isRequired,
};

export default BrandOrderDetails;
