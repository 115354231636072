import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 14.399 12"
  >
    <path
      d="M13.957 1.821l-6.6-1.8a.6.6 0 0 0-.316 0l-6.6 1.8a.6.6 0 0 0-.442.579v1.2a.6.6 0 0 0 .6.6V12h1.2V4.2H12.6V12h1.2V4.2a.6.6 0 0 0 .6-.6V2.4a.6.6 0 0 0-.443-.579zM1.2 2.858l6-1.636 6 1.636V3h-12z"
      fill={fill}
    />
    <path
      d="M10.8 7.8H9.6V5.4a.6.6 0 0 0-.6-.6H5.4a.6.6 0 0 0-.6.6v2.4H3.6a.6.6 0 0 0-.6.6v3a.6.6 0 0 0 .6.6h7.2a.6.6 0 0 0 .6-.6v-3a.6.6 0 0 0-.6-.6zM6 6h.6v1.2h1.2V6h.6v1.8H6zM4.2 9h.6v1.2H6V9h.6v1.8H4.2zm6 1.8H7.8V9h.6v1.2h1.2V9h.6z"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
