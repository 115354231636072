/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  withScriptjs, withGoogleMap, GoogleMap, Marker,
  InfoWindow,
} from 'react-google-maps';
import Hex from './Hex';

const customMarker = '/images/shopIcon.png';

function Cluster(props) {
  const {
    deliveryHexList, storeLocation, handleHex, modalView,
  } = props;

  return (
    <GoogleMap
      defaultZoom={15}
      zoom={15}
      defaultCenter={storeLocation}
      options={{
        // minZoom: 12,
        // maxZoom: 12,
        // fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
      }}
    >
      <Marker
        position={storeLocation}
        icon={customMarker}
      >
        <InfoWindow>
          <b className="fs-01">Shop</b>
        </InfoWindow>
      </Marker>
      {
        deliveryHexList.map(
          (hexPoints, index) => (
            <Hex
              key={`${hexPoints}-${index}`}
              hexPoints={hexPoints}
              handleHex={handleHex}
              modalView={modalView}
            />
          ),
        )
      }
    </GoogleMap>
  );
}

Cluster.propTypes = {
  storeLocation: PropTypes.shape({}).isRequired,
  deliveryHexList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleHex: PropTypes.func.isRequired,
  modalView: PropTypes.string.isRequired,
};

export default withScriptjs(withGoogleMap(Cluster));
