import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ circleFill, pathFill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
    }}
    viewBox="0 0 18.723 18.723"
  >
    <g id="Group_5445" data-name="Group 5445" transform="translate(-311 -99)">
      <circle
        id="Ellipse_121"
        data-name="Ellipse 121"
        cx="9.362"
        cy="9.362"
        r="9.362"
        transform="translate(311 99)"
        fill={circleFill}
        opacity="0.82"
      />
      <path
        id="Path_954"
        data-name="Path 954"
        d="M7.812-13.219,7.031-14,3.906-10.875.781-14,0-13.219l3.125,3.125L0-6.969l.781.781L3.906-9.313,7.031-6.188l.781-.781L4.687-10.094Z"
        transform="translate(316.461 118.461)"
        fill={pathFill}
        fillRule="evenodd"
      />
    </g>
  </svg>

);

Svg.propTypes = {
  pathFill: PropTypes.string.isRequired,
  circleFill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
