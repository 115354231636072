import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal } from '../../component/common';
import '../../assets/scss/LeadershipBoard/LeadershipBoard.scss';
import { validateRegex } from '../../utilities/FormValidations';
import { myReferral } from '../../assets/api/axios';

function ReferAShop(props) {
  const { show, onHide, handleRequestProcessing } = props;
  const [storeName, setStoreName] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [ownerContact, setOwnerContact] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [inValidPhoneMsg, setInvalidPhoneMsg] = useState('');

  const disabled = !storeName || !ownerContact || ownerContact.length !== 10
    || !pinCode || pinCode.length !== 6 || loading;

  function handleReferNow() {
    const inValidPhone = validateRegex('mobileNumber', ownerContact);
    if (inValidPhone) {
      setInvalidPhoneMsg(true);
      return;
    }
    setLoading(true);
    setErrorMsg('Oops Something Went Wrong!! Please Try Again!!');
    myReferral(
      'POST',
      {
        storeName,
        ownerName,
        ownerContact,
        pinCode,
      },
    ).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        onHide();
        handleRequestProcessing();
      } else {
        throw new Error();
      }
    }).catch((err) => {
      if (
        err
        && err.response
        && err.response.data
        && err.response.status === 400
        && err.response.data[0].server_message
      ) {
        setErrorMsg(err.response.data[0].server_message);
      }
      setLoading(false);
      setError(true);
    });
  }

  const body = (
    <Container className="px-5 py-4">
      <Row>
        <Col className="main-container py-4 px-5 fs-01">
          <Row className="text-mid-gray">
            Store Name
            <span className="text-danger">*</span>
          </Row>
          <Row>
            <input
              className="form-control fs-0"
              type="text"
              placeholder="Store Name"
              value={storeName}
              onChange={(event) => {
                setStoreName(event.target.value);
              }}
              maxLength="50"
            />
          </Row>
          <Row className="text-mid-gray pt-4">
            Owner Name
          </Row>
          <Row>
            <input
              className="form-control fs-0"
              type="text"
              placeholder="Owner Name"
              value={ownerName}
              onChange={(event) => {
                setOwnerName(event.target.value);
              }}
              maxLength="50"
            />
          </Row>
          <Row className="text-mid-gray pt-4">
            {'Owner\'s Contact Number'}
            <span className="text-danger">*</span>
          </Row>
          <Row>
            <input
              className="form-control fs-0"
              type="tel"
              pattern="^[6-9][0-9]{9}"
              placeholder={'Owner\'s Contact Number'}
              value={ownerContact}
              onChange={(event) => {
                if (validateRegex('integerValue', event.target.value)) {
                  setInvalidPhoneMsg('');
                  setOwnerContact(event.target.value);
                }
              }}
              maxLength="10"
            />
            {
              inValidPhoneMsg && (
                <span className="p-2 text-danger">
                  Please enter valid contact number
                </span>
              )
            }
          </Row>
          <Row className="text-mid-gray pt-4">
            Pincode
            <span className="text-danger">*</span>
          </Row>
          <Row>
            <input
              className="form-control fs-0"
              type="text"
              placeholder="Pincode"
              value={pinCode}
              onChange={(event) => {
                if (validateRegex('integerValue', event.target.value)) {
                  setPinCode(event.target.value);
                }
              }}
              maxLength="6"
            />
          </Row>
        </Col>
      </Row>
      <Row className="pt-4 d-flex justify-content-center">
        <Button
          variant="success"
          className="fs-1 font-weight-bold p-3 border-radius-8"
          disabled={disabled}
          onClick={() => {
            handleReferNow();
          }}
        >
          REFER NOW
        </Button>
      </Row>
      {
        loading && (
          <Row
            className="pt-2 d-flex align-item-center
              justify-content-center"
          >
            <Spinner
              variant="primary"
              animation="border"
            />
          </Row>
        )
      }
      {
        !loading && error && (
          <Row
            className="pt-2 d-flex align-item-center
              justify-content-center text-danger"
          >
            {errorMsg}
          </Row>
        )
      }
    </Container>
  );

  return (
    <CustomModal
      show={show}
      onHide={onHide}
      title={(<b>Refer a Shop</b>)}
      body={body}
      autoSize
      closeButton
      backdrop
    />
  );
}

ReferAShop.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  handleRequestProcessing: PropTypes.func.isRequired,
};

export default ReferAShop;
