import React from 'react';
import PropTypes from 'prop-types';

const WinningIcon = ({
  width, height, fill, stroke,
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx={15}
      cy={15}
      r={13.5}
      fill={fill}
      stroke={stroke}
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="0.5 6"
    />
  </svg>
);

WinningIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
};

export default WinningIcon;
