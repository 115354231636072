import Axios from 'axios';
import getBaseURL from '../../../assets/api/config';
import { getHeaders, getParams } from '../../../assets/api/axios';

const brandBannersApis = {
  bannersGet: (params = {}, nextUrl = '') => {
    const apiUrl = `${getBaseURL('NEW_OO')}${nextUrl || '/crm/banner/get'}`;
    return Axios({
      method: 'GET',
      url: apiUrl,
      headers: getHeaders(),
      params: getParams(params),
    });
  },

  bannersPost: (params = {}, data = {}) => {
    const bannersPost = `${getBaseURL('NEW_OO')}/crm/banner/add`;
    return Axios({
      method: 'POST',
      url: bannersPost,
      headers: getHeaders(),
      data,
      params: getParams(params),
    });
  },

  bannersPatch: (params = {}, data = {}) => {
    const bannersPatch = `${getBaseURL('NEW_OO')}/crm/banner/patch`;
    return Axios({
      method: 'PATCH',
      url: bannersPatch,
      headers: getHeaders(),
      data,
      params: getParams(params),
    });
  },

  bannerPages: (params = {}, data = {}) => {
    const bannerPages = `${getBaseURL('NEW_OO')}/crm/banner/pages`;
    return Axios({
      method: 'GET',
      url: bannerPages,
      headers: getHeaders(),
      data,
      params: getParams(params),
    });
  },
};

export default brandBannersApis;
