import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal } from '../../component/common';
import { getLeadsSchema } from '../../utilities/Storage';
import { lead } from '../../assets/api/axios';
import { validateRegex } from '../../utilities/FormValidations';

function CreateLead(props) {
  const { show, onHide, handleRequestProcessing } = props;
  const getLeadSchema = getLeadsSchema();
  const getSchema = getLeadSchema.schema;

  const [phoneNumber, setPhoneNumber] = useState('');
  const [leadAssign, setLeadAssign] = useState('');
  const [leadSource, setLeadSource] = useState('');
  const [retailerName, setRetailerName] = useState('');
  const [storeName, setStoreName] = useState('');
  const [city, setCity] = useState('');
  const [leadType, setLeadType] = useState({ label: 'Hot', value: 'Hot' });
  const [leadStatus, setLeadStatus] = useState({ label: 'Pending', value: 'Pending' });
  const [comment, setComment] = useState('');
  const [nextCallOn, setNextCallOn] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const disabled = !phoneNumber || phoneNumber.length !== 10 || !leadAssign || !leadSource
    || !retailerName || !storeName || !city || !leadType || !leadStatus || loading;

  function handleSubmit() {
    setLoading(true);
    setError('Oops Something Went Wrong!! Please Try Again!!');
    lead(
      'POST',
      '',
      {
        phoneNumber,
        assignedTo: leadAssign.value,
        leadSource: leadSource.value,
        retailerName,
        storeName,
        city: city.value,
        leadType: leadType.value,
        leadStatus: leadStatus.value,
        comment,
        nextCallOn: `${moment(nextCallOn).format('DD-MM-YYYY hh:mm')}`,
      },
      {},
    ).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        onHide();
        handleRequestProcessing();
      } else {
        throw new Error();
      }
    }).catch((err) => {
      if (
        err
        && err.response
        && err.response.data
        && err.response.status === 401
        && err.response.data[0].server_message
      ) {
        setErrorMsg(err.response.data[0].server_message);
      }
      setLoading(false);
      setError(true);
    });
  }

  const body = (
    <Container>
      <Row>
        <Col
          xs={24}
          className="py-3 border"
        >
          <Row>
            <Col
              xs={8}
            >
              Phone Number
            </Col>
            <Col
              xs={16}
            >
              <input
                className="form-control"
                type="text"
                placeholder="Enter Phone Number"
                value={phoneNumber}
                onChange={(event) => {
                  if (validateRegex('integerValue', event.target.value)) {
                    setPhoneNumber(event.target.value);
                  }
                }}
                maxLength="10"
              />
            </Col>
          </Row>
          <Row
            className="pt-4"
          >
            <Col
              xs={8}
            >
              Lead Assign
            </Col>
            <Col
              xs={16}
            >
              <Select
                id="leadAssign"
                onChange={(selectedAssign) => {
                  setLeadAssign(selectedAssign);
                }}
                value={leadAssign}
                options={
                  ((getSchema && getSchema.agents) || []).map((item) => (
                    ({ label: item, value: item })
                  ))
                }
                required
              />
            </Col>
          </Row>
          <Row
            className="pt-4"
          >
            <Col
              xs={8}
            >
              Lead Source
            </Col>
            <Col
              xs={16}
            >
              <Select
                id="leadSource"
                onChange={(selectedSource) => {
                  setLeadSource(selectedSource);
                }}
                value={leadSource}
                options={
                  ((getSchema && getSchema.leadSources) || []).map((item) => (
                    ({ label: item, value: item })
                  ))
                }
                required
              />
            </Col>
          </Row>
          <Row
            className="pt-4"
          >
            <Col
              xs={8}
            >
              Retailer Name
            </Col>
            <Col
              xs={16}
            >
              <input
                className="form-control"
                type="text"
                placeholder="Enter Retailer Name"
                value={retailerName}
                onChange={(event) => {
                  setRetailerName(event.target.value);
                }}
                maxLength="25"
              />
            </Col>
          </Row>
          <Row
            className="pt-4"
          >
            <Col
              xs={8}
            >
              Store Name
            </Col>
            <Col
              xs={16}
            >
              <input
                className="form-control"
                type="text"
                placeholder="Enter Store Name"
                value={storeName}
                onChange={(event) => {
                  setStoreName(event.target.value);
                }}
                maxLength="25"
              />
            </Col>
          </Row>
          <Row
            className="pt-4"
          >
            <Col
              xs={8}
            >
              City
            </Col>
            <Col
              xs={16}
            >
              <Select
                id="city"
                onChange={(selectedCity) => {
                  setCity(selectedCity);
                }}
                value={city}
                options={
                  ((getSchema && getSchema.cities) || []).map((item) => (
                    ({ label: item, value: item })
                  ))
                }
                required
              />
            </Col>
          </Row>
          <Row
            className="pt-4"
          >
            <Col
              xs={8}
            >
              Lead Type
            </Col>
            <Col
              xs={16}
            >
              <Select
                id="leadType"
                onChange={(selectedType) => {
                  setLeadType(selectedType);
                }}
                value={leadType}
                options={
                  ((getSchema && getSchema.leadTypes) || []).map((item) => (
                    ({ label: item, value: item })
                  ))
                }
                required
              />
            </Col>
          </Row>
          <Row
            className="pt-4"
          >
            <Col
              xs={8}
            >
              Lead Status
            </Col>
            <Col
              xs={16}
            >
              <Select
                id="leadStatus"
                onChange={(selectedStatus) => {
                  setLeadStatus(selectedStatus);
                }}
                value={leadStatus}
                options={
                  ((getSchema && getSchema.statuses) || []).map((item) => (
                    ({ label: item, value: item })
                  ))
                }
                required
              />
            </Col>
          </Row>
          <Row
            className="pt-4"
          >
            <Col
              xs={8}
            >
              Comment
            </Col>
            <Col
              xs={16}
            >
              <input
                className="form-control"
                type="text"
                placeholder="Enter Comment (Optional)"
                value={comment}
                onChange={(event) => {
                  setComment(event.target.value);
                }}
                maxLength="100"
              />
            </Col>
          </Row>
          <Row
            className="pt-4"
          >
            <Col
              xs={8}
            >
              Call Back Date
            </Col>
            <Col
              xs={16}
            >
              <input
                className="form-control rounded-0 fs-1 bg-light"
                type="datetime-local"
                name="nextCallOn"
                onChange={(event) => {
                  setNextCallOn(new Date(event.target.value));
                }}
              />
            </Col>
          </Row>
          <Row
            className="pt-4 d-flex align-item-center
              justify-content-center"
          >
            <Col
              xs="auto"
            >
              <Button
                variant="primary"
                className="py-1 px-4"
                disabled={disabled}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
          {
            loading && (
              <Row
                className="pt-2 d-flex align-item-center
                  justify-content-center"
              >
                <Spinner
                  variant="primary"
                  animation="border"
                />
              </Row>
            )
          }
          {
            !loading && error && (
              <Row
                className="pt-2 d-flex align-item-center
                  justify-content-center text-danger"
              >
                {errorMsg}
              </Row>
            )
          }
        </Col>
      </Row>
    </Container>
  );

  return (
    <CustomModal
      show={show}
      title="Create Lead"
      size="lg"
      body={body}
      onHide={onHide}
      closeButton
      backdrop
    />
  );
}

CreateLead.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  handleRequestProcessing: PropTypes.func.isRequired,
};

export default CreateLead;
