import React, { useState } from 'react';
import {
  Container, Col, Row, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { CustomModal } from '../../../component/common';
import { orderDispute } from '../../../assets/api/axios';

function ResolveDispute(props) {
  const {
    onDisputeResolved, toggleResolveDispute, orderId,
  } = props;
  const [comment, setComment] = useState('');
  const [deliveryStatus, setDeliveryStatus] = useState('DELIVERED');
  const [resolvingStatus, setResolvingStatus] = useState('');
  const [errorMessage, setErrorMsg] = useState('');

  const handleDispute = () => {
    setResolvingStatus('loading');
    orderDispute(
      'PATCH',
      orderId,
      {
        comment,
        deliveryStatus,
      },
    ).then(() => {
      setResolvingStatus('success');
      onDisputeResolved();
      toggleResolveDispute();
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong!! Please Try Again!!';
      if (err?.response?.data?.errors[0]?.message) {
        errorMsg = err.response.data.errors[0].message;
      }
      setErrorMsg(errorMsg);
      setResolvingStatus('error');
    });
  };

  return (
    <CustomModal
      show
      autoSize
      onHide={() => toggleResolveDispute()}
      body={(
        <Container className="p-4">
          <Row>
            <Col xs={24} className="py-2">
              <span className="text-danger">* </span>
              Comment:
            </Col>
            <Col xs={24} className="py-2">
              <input
                type="text"
                name="comment"
                maxLength={1000}
                className="input-box"
                onChange={(e) => { setComment(e.target.value); }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} className="py-2">Delivery Status:</Col>
            <Col xs={24} className="pt-2 pb-4">
              <select
                id="deliveryStatus"
                value={deliveryStatus}
                className="p-1 w-100 border-0"
                onChange={(e) => { setDeliveryStatus(e.target.value); }}
              >
                <option value="DELIVERED">DELIVERED</option>
                <option value="CANCELLED">CANCELLED</option>
                <option value="PENDING">PENDING</option>
              </select>
            </Col>
          </Row>
          {
            errorMessage && (
              <Row className="text-danger mx-0 mb-2">
                {errorMessage}
              </Row>
            )
          }
          <Row
            className="mx-0 py-2 d-flex align-items-center flex-row-reverse"
          >
            <Button
              variant="primary"
              className="font-weight-bold"
              onClick={handleDispute}
              disabled={!comment || resolvingStatus === 'loading'}
            >
              Resolve Dispute
            </Button>
            <Button
              variant="link"
              className="font-weight-bold text-black"
              onClick={() => toggleResolveDispute()}
            >
              NOT NOW
            </Button>
          </Row>
        </Container>
      )}
    />
  );
}

ResolveDispute.propTypes = {
  orderId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onDisputeResolved: PropTypes.func.isRequired,
  toggleResolveDispute: PropTypes.func.isRequired,
};

export default ResolveDispute;
