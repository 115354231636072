import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '../../utilities';

function Analytics(props) {
  const { retailerDetails } = props;
  return (
    <div
      className="h-100"
    >
      <iframe
        title="Analytics"
        width="100%"
        height="100%"
        frameBorder="0"
        src={Link.golbat(`/performance-report/${retailerDetails.code}`, {
          token: 'e5ad88aa-d0c0-4fcb-952c-22a661bb865b',
          'is-admin': true,
          'hide-header': true,
        })}
      />
    </div>
  );
}

Analytics.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }).isRequired,
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
};

export default Analytics;
