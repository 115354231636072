import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width, stroke }) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={width}
    height={width}
    viewBox="0 0 24 24"
    enableBackground="new 0 0 122.88 122.88"
    xmlSpace="preserve"
  >
    <g>
      <path
        fill={stroke}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0c6.626 0 12 5.374 12 12c0 6.626 -5.374 12 -12 12C5.374 24 0 18.626 0 12C0 5.374 5.374 0 12 0L12 0zM19.239 7.236L7.237 19.238c1.367 0.902 3.003 1.427 4.763 1.427c4.786 0 8.666 -3.88 8.666 -8.666C20.666 10.24 20.14 8.603 19.239 7.236L19.239 7.236L19.239 7.236zM4.762 16.763l12.002 -12.002c-1.366 -0.902 -3.004 -1.426 -4.764 -1.426c-4.786 0 -8.666 3.881 -8.666 8.666C3.334 13.76 3.86 15.397 4.762 16.763L4.762 16.763L4.762 16.763z"
      />
    </g>
  </svg>

);

Svg.propTypes = {
  width: PropTypes.number.isRequired,
  stroke: PropTypes.string.isRequired,
};

export default Svg;
