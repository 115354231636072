import React from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import {
  Container, Row, Col, Button, Spinner, InputGroup, Form,
} from 'react-bootstrap';
import QueryString from 'query-string';
import {
  recommendedCategory, recommendedProducts, bulkAddRecommendedCatagory, storeProfile,
} from '../../assets/api/axios';
import { Svg, ErrorHandler } from '../../component/common';
import { Constant } from '../../utilities';
import LibraryProduct from '../../component/derived/inventory/LibraryProduct';

class SmartCatalogue extends React.Component {
  constructor(props) {
    super(props);
    const { history } = props;
    const param = QueryString.parse(history.location.search);
    this.state = {
      category: null,
      primaryCategoryId: param.primaryCategoryId || null,
      secondaryCategory: null,
      productOverview: null,
      products: null,
      searchResults: null,
      searchText: '',
      loading: true,
      loadingProductOverview: true,
      loadingProducts: false,
      loadingSearchResults: false,
      enableSearch: false,
    };
    this.source = Axios.CancelToken.source();
    this.activeTimeout = null;
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleLoad = (retry = 3) => {
    let { primaryCategoryId } = this.state;
    const { retailerDetails, history } = this.props;
    recommendedCategory(
      'get',
      null,
      retailerDetails.code,
    )
      .then((res) => {
        primaryCategoryId = primaryCategoryId || ((
          res.data.categories[0] && res.data.categories[0].categoryId
        ) || null);
        this.setState({
          error: false,
          loading: false,
          category: res.data,
          primaryCategoryId,
        }, () => {
          history.push(`/retailer/${retailerDetails.code
          }?view=smartcatalogue&primaryCategoryId=${primaryCategoryId}`);
          this.loadProductOverview();
        });
      })
      .catch(() => {
        if (retry - 1) {
          this.handleLoad(retry - 1);
          return;
        }
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  handleCategory = (categoryId) => {
    const { loadingProductOverview } = this.state;
    if (loadingProductOverview) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      primaryCategoryId: categoryId,
      productOverview: null,
      loadingProductOverview: true,
    }, () => {
      this.loadProductOverview();
    });
  }

  handleSecondaryCategory = (categoryId, displayName) => {
    this.setState({
      secondaryCategory: {
        categoryId,
        displayName,
      },
      loadingProducts: true,
      products: null,
    }, () => {
      this.loadProducts();
    });
  }

  handleChange = (e) => {
    const { value } = e.target;
    const { loadingSearchResults } = this.state;
    if (loadingSearchResults) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    if (this.activeTimeout) {
      clearTimeout(this.activeTimeout);
      this.activeTimeout = null;
    }
    this.setState({
      searchResults: null,
      loadingSearchResults: !!value,
      searchText: value,
    }, () => {
      if (value) {
        this.activeTimeout = setTimeout(() => {
          this.loadSearchedProducts();
        }, 200);
      }
    });
  }

  toggleSearch = () => {
    const { enableSearch } = this.state;
    this.setState({
      enableSearch: !enableSearch,
      loadingSearchResults: false,
      searchResults: null,
    });
  }

  loadProductOverview = () => {
    const { primaryCategoryId } = this.state;
    const { retailerDetails } = this.props;
    recommendedCategory(
      'get',
      primaryCategoryId,
      retailerDetails.code,
      this.source.token,
    )
      .then((res) => {
        this.setState({
          productOverview: res.data,
          loadingProductOverview: false,
        });
      })
      .catch(() => {
        this.setState({
          loadingProductOverview: false,
        });
      });
  }

  loadProducts = (offset = 0) => {
    const { secondaryCategory, products } = this.state;
    const { retailerDetails } = this.props;
    recommendedProducts(
      'GET',
      {
        offset: offset.toString(),
        limit: 20,
        categoryId: secondaryCategory.categoryId,
        storeCode: retailerDetails.code,
      },
    ).then((res) => {
      this.setState({
        products: offset
          ? {
            ...products,
            results: [
              ...products.results,
              ...res.data.results,
            ],
          }
          : res.data,
        loadingProducts: false,
      });
    }).catch(() => {
      this.setState({
        loadingProducts: false,
      });
    });
  }

  productsLazyLoad = (e) => {
    const {
      offsetHeight, scrollTop, scrollHeight,
    } = e.target;
    const { products, loadingProducts } = this.state;
    const offset = products.results.length;
    if (
      (scrollHeight - scrollTop === offsetHeight)
      && products.count > offset
      && !loadingProducts
    ) {
      this.setState({
        loadingProducts: true,
      }, () => {
        this.loadProducts(offset);
      });
    }
  }

  loadSearchedProducts = (offset = 0) => {
    const { searchResults, searchText } = this.state;
    const { retailerDetails } = this.props;
    recommendedProducts(
      'GET',
      {
        offset: offset.toString(),
        limit: 20,
        searchText,
        storeCode: retailerDetails.code,
      },
    ).then((res) => {
      this.setState({
        searchResults: offset
          ? {
            ...searchResults,
            results: [
              ...searchResults.results,
              ...res.data.results,
            ],
          }
          : res.data,
        loadingSearchResults: false,
      });
    }).catch(() => {
      this.setState({
        loadingSearchResults: false,
      });
    });
  }

  searchResultsLazyLoad = (e) => {
    const {
      offsetHeight, scrollTop, scrollHeight,
    } = e.target;
    const { searchResults, loadingSearchResults } = this.state;
    const offset = searchResults.results.length;
    if (
      (scrollHeight - scrollTop === offsetHeight)
      && searchResults.count > offset
      && !loadingSearchResults
    ) {
      this.setState({
        loadingSearchResults: true,
      }, () => {
        this.loadSearchedProducts(offset);
      });
    }
  }

  addAllProducts = () => {
    const { secondaryCategory } = this.state;
    const { retailerDetails } = this.props;
    bulkAddRecommendedCatagory(
      'POST',
      {
        categoryId: secondaryCategory.categoryId,
        storeCode: retailerDetails.code,
      },
    ).then(() => {
      if (!retailerDetails.setupStages.productAdd) {
        storeProfile(
          'patch',
          retailerDetails.code,
          {
            setupStages: {
              productAdd: true,
            },
          },
        ).then(() => {
          retailerDetails.setupStages.productAdd = true;
        });
      }
      this.setState({
        secondaryCategory: null,
        products: null,
      });
    });
  }

  render() {
    const {
      category, primaryCategoryId, secondaryCategory, productOverview, products,
      loading, loadingProductOverview, loadingProducts, error, searchText, enableSearch,
      searchResults, loadingSearchResults,
    } = this.state;
    const { retailerDetails } = this.props;
    if (loading) {
      return (
        <div
          className="h-100 d-flex align-items-center
             justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!loading && error) {
      return (
        <div
          className="h-100 d-flex align-items-center
             justify-content-center"
        >
          <ErrorHandler
            retryLogic={() => this.handleLoad()}
          />
        </div>
      );
    }
    return (
      <div
        className="bg-light h-100"
      >
        <Container
          className="h-100"
        >
          <Row
            className="flex-column h-100"
          >
            {
              !!secondaryCategory && !enableSearch ? (
                <Col
                  className="flex-grow-0"
                >
                  <Row
                    className="align-items-center justify-content-between pt-2"
                  >
                    <Col
                      xs="auto"
                      className="d-flex align-items-center"
                    >
                      <Button
                        variant="link"
                        className="p-0 text-dark font-weight-bold"
                        onClick={() => {
                          this.setState({
                            secondaryCategory: null,
                            products: null,
                          });
                        }}
                      >
                        {
                          category.categories.find((item) => (
                            item.categoryId === primaryCategoryId
                          )).displayName
                        }
                      </Button>
                      &nbsp;
                      &nbsp;
                      <div
                        className="p-0 text-dark"
                      >
                        {`${
                          secondaryCategory.displayName
                        }  ${
                          products && products.count
                            ? `(${products.count})` : ''
                        }`}
                      </div>
                    </Col>
                    {
                      products && products.results.length ? (
                        <Col
                          xs="auto"
                          className="d-flex align-items-center"
                        >
                          <Button
                            onClick={this.addAllProducts}
                            className="fs-01 px-3 font-weight-bold"
                            disabled={products.count === products.addedProductCount}
                          >
                            {
                              products.count === products.addedProductCount
                                ? 'ADDED' : 'ADD ALL'
                            }
                          </Button>
                        </Col>
                      ) : ''
                    }
                  </Row>
                </Col>
              ) : ''
            }
            <Col
              className="flex-grow-0 mb-2 py-2"
            >
              {
                enableSearch ? (
                  <div
                    className="d-flex align-items-center"
                  >
                    <Button
                      variant="link"
                      onClick={this.toggleSearch}
                      className="p-0 mr-3"
                    >
                      <Svg
                        svg="arrow"
                        width="1.3rem"
                        fill={Constant.Color.PRIMARY}
                      />
                    </Button>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <Svg
                            svg="search"
                            width="18"
                            fill={Constant.Color.MEDIUM}
                          />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        value={searchText}
                        onChange={this.handleChange}
                        placeholder="Search all products in smart catalogue"
                        className="fs-0 py-4"
                        autoFocus
                      />
                    </InputGroup>
                  </div>
                ) : (
                  <Button
                    variant="white"
                    className="border p-3 rounded-0 fs-0 w-100 text-left"
                    onClick={this.toggleSearch}
                  >
                    <Svg
                      svg="search"
                      width="18"
                      fill={Constant.Color.MEDIUM}
                    />
                    &nbsp;
                    &nbsp;
                    Search all products in smart catalogue
                  </Button>
                )
              }
            </Col>
            {
              enableSearch ? (
                <Col
                  className="flex-grow-1"
                >
                  <Row
                    className="h-100 flex-column mx-0"
                  >
                    <Col
                      className="flex-grow-1 bg-white d-flex flex-wrap overflow-y px-0"
                      onScroll={this.searchResultsLazyLoad}
                    >
                      {
                        searchResults ? (
                          searchResults.results.map((item) => (
                            <Col
                              key={item.id}
                              xs={24}
                              md={12}
                              className="py-2 px-0"
                            >
                              <LibraryProduct
                                {...this.props}
                                product={item}
                                reloadList={this.loadSearchedProducts}
                                retailerDetails={retailerDetails}
                              />
                            </Col>
                          ))
                        ) : ''
                      }
                      {
                        loadingSearchResults && (
                          <Col
                            xs={24}
                            className="d-flex justify-content-center py-4"
                          >
                            <Spinner
                              variant="primary"
                              animation="border"
                            />
                          </Col>
                        )
                      }
                    </Col>
                  </Row>
                </Col>
              ) : (
                <Col
                  classsName="flex-grow-1"
                >
                  {
                    secondaryCategory ? (
                      <Row
                        className="h-100 flex-column mx-0"
                      >
                        <Col
                          className="flex-grow-1 overflow-y d-flex flex-wrap bg-white px-0"
                          onScroll={this.productsLazyLoad}
                        >
                          {
                            products ? (
                              products.results.map((item) => (
                                <Col
                                  key={item.id}
                                  xs={24}
                                  md={12}
                                  className="py-2 px-0"
                                >
                                  <LibraryProduct
                                    {...this.props}
                                    product={item}
                                    reloadList={this.loadProducts}
                                    retailerDetails={retailerDetails}
                                  />
                                </Col>
                              ))
                            ) : ''
                          }
                          {
                            loadingProducts && (
                              <Col
                                xs={24}
                                className="d-flex justify-content-center py-4"
                              >
                                <Spinner
                                  variant="primary"
                                  animation="border"
                                />
                              </Col>
                            )
                          }
                        </Col>
                      </Row>
                    ) : (
                      <Row
                        className="h-100"
                      >
                        <Col
                          xs={6}
                          className="pb-3"
                        >
                          <Row
                            className="flex-column h-100 mx-0 bg-white"
                          >
                            <Col
                              className="flex-grow-0 bg-primary font-weight-bold
                              text-center text-white py-3"
                            >
                              Categories
                            </Col>
                            <Col
                              className="flex-grow-1 overflow-y px-0 border"
                            >
                              {
                                category ? (
                                  category.categories.map((item) => (
                                    <Button
                                      key={item.categoryId}
                                      variant="link"
                                      className={`${item.categoryId === primaryCategoryId
                                        ? 'font-weight-bold text-primary' : 'text-dark'} w-100 text-left fs-0`}
                                      onClick={() => {
                                        this.handleCategory(item.categoryId);
                                      }}
                                    >
                                      {item.displayName}
                                    </Button>
                                  ))
                                ) : ''
                              }
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xs={18}
                        >
                          <Row
                            className="flex-column h-100"
                          >
                            <Col
                              className="overflow-y"
                            >
                              {
                                loadingProductOverview && (
                                  <div
                                    className="d-flex justify-content-center py-2"
                                  >
                                    <Spinner
                                      variant="primary"
                                      animation="border"
                                    />
                                  </div>
                                )
                              }
                              {
                                productOverview ? (
                                  productOverview.results.map((item) => (
                                    <div
                                      key={item.categoryId}
                                      className="py-3 bg-white mb-3"
                                    >
                                      <Row
                                        className="align-items-center justify-content-between mx-0"
                                      >
                                        <Col
                                          xs="auto"
                                        >
                                          <div
                                            className="font-weight-bold"
                                          >
                                            {item.displayName}
                                          </div>
                                          <div
                                            className="fs-01"
                                          >
                                            {`${item.productCount} Products`}
                                          </div>
                                        </Col>
                                        <Col
                                          xs="auto"
                                        >
                                          <Button
                                            variant="link"
                                            className="text-primary fs-01 d-flex align-items-center"
                                            onClick={() => {
                                              this.handleSecondaryCategory(
                                                item.categoryId,
                                                item.displayName,
                                              );
                                            }}
                                          >
                                            SEE ALL
                                            <span>
                                              <Svg
                                                svg="circleArrowHead"
                                                width="24"
                                                stroke={Constant.Color.WHITE}
                                                fill={Constant.Color.PRIMARY}
                                              />
                                            </span>
                                          </Button>
                                        </Col>
                                      </Row>
                                      <Row>
                                        {
                                          item.products.map((product) => (
                                            <Col
                                              key={product.id}
                                              xs={24}
                                              className="py-2"
                                            >
                                              <LibraryProduct
                                                {...this.props}
                                                product={product}
                                                reloadList={() => this.loadProductOverview()}
                                                retailerDetails={retailerDetails}
                                              />
                                            </Col>
                                          ))
                                        }
                                      </Row>
                                    </div>
                                  ))
                                ) : ''
                              }
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )
                  }
                </Col>
              )
            }
          </Row>
        </Container>
      </div>
    );
  }
}

SmartCatalogue.propTypes = {
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
    setupStages: PropTypes.shape({
      productAdd: PropTypes.bool,
    }),
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default SmartCatalogue;
