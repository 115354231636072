const ErrorFeedback = {
  mrp: {
    empty: 'MRP is required*',
  },
  sellingPrice: {
    empty: 'Selling Price is required*',
  },
  SPGreaterThanMRP: {
    valid: 'Selling Price should not be greater than MRP',
  },
  userName: {
    missing: 'Please enter username',
  },
  passMiss: {
    missing: 'Please enter username',
  },
};

export default ErrorFeedback;
