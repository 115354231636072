import Axios from 'axios';
import React, { Component } from 'react';
import {
  Container, Row, Col, Card, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ErrorHandler, Svg } from '../../component/common';
import { eventFeed } from '../../assets/api/axios';
import { Storage, Constant } from '../../utilities';

const { CancelToken } = Axios;
const limit = 50;
let axiosController = null;

function dateString(timeStamp) {
  const date = new Date(timeStamp);
  return (`${date.toLocaleTimeString()} ${date.toLocaleDateString()}`);
}

class Feeds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feeds: null,
      loading: true,
      error: false,
      offset: 0,
      storeCode: '',
      lastFeedsCount: 0,
    };
  }

  componentDidMount = () => {
    const { match } = this.props;
    const { code } = match.params;
    const count = Storage.getFeedsCount(code);
    this.setState({
      storeCode: code,
      lastFeedsCount: count,
    }, () => {
      this.handleLoad();
    });
  }

  componentWillUnmount = () => {
    if (this.refreshFeedsList) {
      clearInterval(this.refreshFeedsList);
    }
  }

  handleLoad = () => {
    const { storeCode } = this.state;
    if (axiosController) {
      axiosController.cancel();
    }
    axiosController = CancelToken.source();
    const offset = 0;
    eventFeed(
      'GET', `${offset}`, limit, storeCode, axiosController.token,
    ).then((res) => {
      this.refreshFeedsList = setInterval(() => {
        this.handleRefresh();
      }, 15000);
      axiosController = null;
      this.setState({
        feeds: res.data,
        loading: false,
        error: false,
        offset: res.data.results.length,
      });
      Storage.setFeedsCount(storeCode, res.data.count);
    }).catch((err) => {
      if (Axios.isCancel(err)) {
        return;
      }
      this.setState({
        loading: false,
        error: true,
      });
    });
  }

  handleRefresh = () => {
    if (axiosController) {
      return;
    }
    axiosController = CancelToken.source();
    const { storeCode } = this.state;
    const offset = 0;
    eventFeed(
      'GET', `${offset}`, limit, storeCode, axiosController.token,
    ).then((res) => {
      axiosController = null;
      const newFeeds = res.data;
      const { feeds } = this.state;
      const startIndexOfNewFeeds = (newFeeds.results).findIndex(
        (feed) => feed.id === feeds.results[0].id,
      );
      if (startIndexOfNewFeeds !== -1 || startIndexOfNewFeeds === 0) {
        newFeeds.results = newFeeds.results.concat(
          feeds.results.slice(newFeeds.results.length - startIndexOfNewFeeds),
        );
      }
      this.setState({
        feeds: newFeeds,
        offset: feeds.results.length,
      });
      Storage.setFeedsCount(storeCode, res.data.count);
    }).catch(() => { });
  }

  appendFeeds = () => {
    if (axiosController) {
      axiosController.cancel();
    }
    axiosController = CancelToken.source();
    const { offset, storeCode } = this.state;
    this.setState({
      loading: true,
    });
    eventFeed(
      'GET', `${offset}`, limit, storeCode, axiosController.token,
    ).then((res) => {
      axiosController = null;
      const { feeds } = this.state;
      const newFeedsList = [...feeds.results, ...res.data.results];
      this.setState({
        feeds: {
          ...feeds,
          results: newFeedsList,
          count: res.data.count,
        },
        offset: newFeedsList.length,
        loading: false,
        error: false,
      });
      Storage.setFeedsCount(storeCode, res.data.count);
    }).catch((err) => {
      if (Axios.isCancel(err)) {
        return;
      }
      this.setState({
        loading: false,
        error: true,
      });
    });
  }

  handleLazyLoad = (event) => {
    const { feeds, offset, loading } = this.state;
    if (loading) return;
    const { scrollHeight, scrollTop, offsetHeight } = event.target;
    if (scrollHeight - offsetHeight - scrollTop < 100
      || scrollHeight === scrollTop + offsetHeight) {
      if (offset < feeds.count) {
        this.appendFeeds();
      }
    }
  }

  render() {
    const {
      feeds, loading, error, lastFeedsCount, offset,
    } = this.state;

    const cards = (
      <Row>
        <Col
          xs={18}
          className="mx-auto"
        >
          { feeds && ((feeds.results).map((feed, index) => (
            <Card
              border="secondary"
              xs="auto"
              className="mt-2 mx-auto"
              style={{ width: '100%' }}
              key={feed.id}
            >
              <Row
                className="m-3"
              >
                <Col
                  xs={4}
                  className="mt-3"
                >
                  <Card.Img
                    src={feed.icon}
                  />
                </Col>
                <Col
                  xs={20}
                >
                  <Card.Body
                    className="text-dark"
                  >
                    <Row className="mx-0">
                      <h6
                        className="card-text mb-2 fs-02 font-weight-bold"
                      >
                        { dateString(feed.datetime) }
                          &nbsp;&nbsp;
                        Store Code:
                        { feed.storeCode}
                      </h6>
                      {(feeds.count - lastFeedsCount) - index > 0
                        && (
                          <Col
                            xs="auto"
                          >
                            <Svg
                              svg="new"
                              width="40px"
                              height="28px"
                              fill={Constant.Color.GREEN}
                            />
                          </Col>
                        )}
                      <Button
                        className="ml-auto"
                        href={feed.deeplink}
                        target="_blank"
                        variant="link"
                      >
                        <Svg
                          svg="arrow"
                          width="24px"
                          transform="rotate(180deg)"
                          fill={Constant.Color.DARK}
                        />
                      </Button>
                    </Row>
                    <h6
                      className="card-title pt-2 mb-1 fs-03 font-weight-bold"
                    >
                      { feed.heading }
                    </h6>
                    <h6
                      className="card-text pt-2 mb-2 fs-03"
                    >
                      { feed.message }
                    </h6>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          ))) }
        </Col>
      </Row>
    );

    return (
      <Container
        className="h-100"
      >
        <Row
          className="mx-0 pt-2 h-50p"
        >
          <h6
            className="text-info"
          >
            New Feeds Count:
            &nbsp;
            {feeds ? feeds.count - lastFeedsCount : 0}
          </h6>
        </Row>
        <Row
          onScroll={this.handleLazyLoad}
          className="feed-container mt-0"
        >
          <Container
            fluid
            className="pb-3"
          >
            <Row>
              {feeds && (
                <Col>
                  {cards}
                </Col>
              )}
            </Row>
            <Row className="text-center">
              {feeds && feeds.results.length === 0 && (
                <h6
                  className="text-secondary"
                >
                  No matching Records
                </h6>
              )}
            </Row>
            <Row
              className={loading ? 'justify-content-center py-4' : 'd-none'}
            >
              <Col
                xs="auto"
                className="spinner-border text-primary p-0"
                role="status"
              >
                <span
                  className="sr-only"
                >
                  Loading...
                </span>
              </Col>
            </Row>
            <Row>
              <Col
                className={error ? 'justify-content-center py-4' : 'd-none'}
              >
                <ErrorHandler
                  retryLogic={() => {
                    this.setState({
                      loading: true,
                      error: false,
                    }, () => {
                      if (offset) {
                        this.appendFeeds();
                      } else {
                        this.handleLoad();
                      }
                    });
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    );
  }
}

Feeds.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

export default Feeds;
