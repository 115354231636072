import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.464 13.382a.355.355 0 0 0-.351.324 3.13 3.13 0 0 1-3.117 2.81 3.136 3.136 0 0 1-3.117-2.81.358.358 0 0 0-.351-.324H2.274c-.892 0-1.616.724-1.616 1.616v3.386c0 .892.724 1.616 1.616 1.616h15.452c.892 0 1.616-.724 1.616-1.616v-3.382c0-.892-.724-1.616-1.616-1.616h-4.262v-.004Z"
      fill="#fff"
    />
    <path
      d="M12.413 8.123 11.15 9.387V1.149a1.15 1.15 0 0 0-2.298 0v8.238L7.587 8.123c-.389-.389-1.01-.487-1.468-.18a1.15 1.15 0 0 0-.184 1.775l3.386 3.386a.963.963 0 0 0 1.362 0l3.386-3.386a1.15 1.15 0 0 0-.184-1.775c-.462-.311-1.083-.213-1.472.18Z"
      fill="#fff"
    />
  </svg>
);

Svg.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default Svg;
