import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import { exotelCallInit, exotelCallDetails } from '../../../assets/api/axios';
import { CustomModal, ErrorHandler } from '../../common';
import { Storage } from '../../../utilities';

class ExotelCallPatchDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      currentExotelCallId: null,
      callPurpose: 'Welcome',
      additionalCallPurpose: '',
      secDescription: '',
      openComment: '',
      callComments: 'Switched off',
      processingCall: false,
      callError: false,
      processingPatch: false,
      patchError: false,
    };
  }

  handleChangeExotelDetails = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  initiateExotelCall = () => {
    const { phoneNumber, action, orderId } = this.props;
    const exotelCallMeta = {
      action,
      meta: { ...orderId ? { orderId } : {} },
      callFromUser: {
        adminUser: Storage.getUserName(),
        phoneNumber: Storage.getUserNumber(),
      },
      callToUser: {
        phoneNumber,
      },
    };
    exotelCallInit('post', exotelCallMeta)
      .then((res) => {
        this.setState({
          currentExotelCallId: res.data.id,
          processingCall: false,
          callError: false,
        });
      })
      .catch(() => {
        this.setState({
          processingCall: false,
          callError: true,
        });
      });
  }

  patchExotelCallDetails = () => {
    const {
      secDescription, openComment, callComments,
      callPurpose, currentExotelCallId, additionalCallPurpose,
    } = this.state;
    const { onHide } = this.props;
    exotelCallDetails(
      'PATCH',
      {
        callDescription: openComment,
        secDescription,
        openComment,
        callComments,
        callLocation: 'Retailer_Profile',
        callPurpose: `${
          callPurpose}${additionalCallPurpose
          ? ` - ${additionalCallPurpose}` : ''
        }`,
      },
      currentExotelCallId,
    ).then(() => {
      onHide();
    })
      .catch(() => {
        this.setState({
          processingPatch: false,
          patchError: true,
        });
      });
  }

  reset = () => {
    this.setState({
      currentExotelCallId: null,
      callPurpose: 'Welcome',
      additionalCallPurpose: '',
      secDescription: '',
      openComment: '',
      callComments: 'Switched off',
      processingCall: false,
      callError: false,
      processingPatch: false,
      patchError: false,
    });
  }

  render() {
    const { show, onHide } = this.props;
    const {
      openComment, callComments,
      callPurpose, patchError, processingPatch, additionalCallPurpose,
      processingCall, callError, currentExotelCallId,
    } = this.state;

    let body = <div />;

    if (processingCall) {
      body = (
        <div
          className="text-center p-4"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!processingCall && callError) {
      body = (
        <ErrorHandler
          retryLogic={() => {
            this.setState({
              processingCall: true,
              callError: false,
            }, () => {
              this.initiateExotelCall();
            });
          }}
        />
      );
    }

    if (!processingCall && !callError && currentExotelCallId) {
      body = (
        <Container>
          <Row>
            <Col
              xs={24}
              className="p-2 bg-success text-center"
            >
              <b>Your call has been initiated successfully</b>
            </Col>

            <Col xs={24} className="mt-4 p-2 fs-2 text-center">
              Please fill following details and submit
            </Col>
          </Row>
          <Row className="pt-4">
            <Col xs={24}>
              <b>1. Call Purpose</b>
              <span className="text-danger">*</span>
            </Col>
            <Col xs={24} className="pt-2">
              <select
                name="callPurpose"
                value={callPurpose}
                className="form-control"
                onChange={this.handleChangeExotelDetails}
              >
                <option value="Welcome">Welcome</option>
                <option value="Onboarding">Onboarding</option>
                <option value="Regular/courtesy">Regular/courtesy</option>
                <option value="Marketing">Marketing</option>
                <option value="Order">Order</option>
                <option value="Training">Training</option>
                <option value="Focused Calling">Focused Calling</option>
                <option value="Growth">Growth</option>
                <option value="Service">Service</option>
                <option value="Churn">Churn</option>
                <option value="Fraud Calling">Fraud Calling</option>
                <option value="Bank Details Update/Verification">Bank Details Update/Verification</option>
              </select>
            </Col>
            {
              (callPurpose === 'Marketing'
              || callPurpose === 'Order'
              || callPurpose === 'Training'
              || callPurpose === 'Focused Calling')
              && (
                <Col xs={24} className="pt-2">
                  <input
                    type="text"
                    name="additionalCallPurpose"
                    placeholder="Add additional details*"
                    value={additionalCallPurpose}
                    className="form-control"
                    onChange={this.handleChangeExotelDetails}
                  />
                </Col>
              )
            }
          </Row>

          <Row className="pt-4">
            <Col xs={24}>
              <b>2. Call Comments</b>
              <span className="text-danger">*</span>
            </Col>
            <Col xs={24} className="pt-2">
              <select
                name="callComments"
                value={callComments}
                className="form-control"
                onChange={this.handleChangeExotelDetails}
              >
                <option value="Switched off">Switched off</option>
                <option value="Not answered">Not answered</option>
                <option value="Call back later">Call back later</option>
                <option value="Interested">Interested</option>
                <option value="Gmeet Done">Gmeet Done</option>
                <option value="Call Done">Call Done</option>
                <option value="Reactivation Call">Reactivation Call</option>
                <option value="Shop closed permanently">Shop closed permanently</option>
                <option value="Not-interested">Not-interested</option>
                <option value="Temporarily closed">Temporarily closed</option>
                <option value="Attempted to fraud">Attempted to fraud</option>
                <option value="Not Attempted to fraud">Not Attempted to fraud</option>
                <option value="Interested - Shop catalog update issue">Interested - Shop catalog update issue</option>
                <option value="Interested - LL catalog update issue">Interested - LL catalog update issue</option>
                <option value="Interested - LL settlement issue">Interested - LL settlement issue</option>
                <option value="Interested - Shop staff issue">Interested - Shop staff issue</option>
                <option value="Interested - Shop stock issue">Interested - Shop stock issue</option>
                <option value="Interested - Order issue">Interested - Order issue</option>
                <option value="Interested - bank details update">Interested - bank details update</option>
                <option value="Not-interested - Order issue">Not-interested - Order issue</option>
                <option value="Not interested - Service issue">Not interested - Service issue</option>
                <option value="Not-interested - Shop issue">Not-interested - Shop issue</option>
                <option value="Not-interested - bank details update">Not-interested - bank details update</option>
              </select>
            </Col>
          </Row>

          {/* <Row className="pt-4">
            <Col xs={24}>
              <b>3. Secondary Description</b>
              <span className="text-danger">*</span>
            </Col>
            <Col xs={24} className="pt-2">
              <select
                name="secDescription"
                value={secDescription}
                className="form-control"
                onChange={this.handleChangeExotelDetails}
              >
                <option value="Shop catalog issue">Shop catalog issue</option>
                <option value="LL catalog issue">LL catalog issue</option>
                <option value="LL settlement issue">LL settlement issue</option>
                <option value="LL service issue">LL service issue</option>
                <option value="Shop staff issue">Shop staff issue</option>
                <option value="Shop stock issue">Shop stock issue</option>
                <option value="LL order issue">LL order issue</option>
                <option value="Shop order processing issue">Shop order processing issue</option>
              </select>
            </Col>
          </Row> */}

          <Row className="pt-4">
            <Col xs={24}>
              <b>3. Open Comment</b>
              <span className="text-secondary"> (optional)</span>
            </Col>
            <Col xs={24} className="pt-2">
              <input
                type="text"
                name="openComment"
                value={openComment}
                className="form-control"
                onChange={this.handleChangeExotelDetails}
              />
            </Col>
          </Row>

          {/* <Row className="pt-4">
            <Col xs={24}>
              <b>5. Call Description</b>
              <span className="text-secondary"> (optional)</span>
            </Col>
            <Col xs={24} className="pt-2">
              <input
                type="text"
                name="callDescription"
                value={callDescription}
                className="form-control"
                onChange={this.handleChangeExotelDetails}
              />
            </Col>
          </Row> */}

          <Row className="pt-4">
            <Col xs={24} className="text-center">
              {processingPatch
                ? (
                  <Spinner
                    variant="primary"
                    animation="border"
                  />
                ) : (
                  <>
                    <Button
                      variant="primary"
                      block
                      onClick={() => {
                        this.setState({
                          processingPatch: true,
                          patchError: false,
                        }, () => {
                          this.patchExotelCallDetails();
                        });
                      }}
                      disabled={
                        !callComments
                        || (
                          (
                            callPurpose === 'Marketing'
                            || callPurpose === 'Order'
                            || callPurpose === 'Training'
                            || callPurpose === 'Focused Calling'
                          ) && !additionalCallPurpose)
                      }
                    >
                      Submit
                    </Button>
                    <div className="py-2 text-danger">
                      {!processingPatch && patchError
                    && (
                      <b>Oops Something went wrong!!</b>
                    )}
                    </div>
                  </>
                )}
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <CustomModal
        show={show}
        body={body}
        onHide={onHide}
        onEnter={() => {
          this.setState({
            processingCall: true,
          }, () => {
            this.initiateExotelCall();
          });
        }}
        autoSize
        backdrop={processingCall
          || callError || 'static'}
        reset={this.reset}
      />
    );
  }
}

ExotelCallPatchDetails.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  orderId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  action: PropTypes.string.isRequired,
  phoneNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

ExotelCallPatchDetails.defaultProps = {
  orderId: '',
};

export default ExotelCallPatchDetails;
