import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import QueryString from 'query-string';
import {
  Button,
  Col, Container, Form, InputGroup, Row, Spinner,
} from 'react-bootstrap';
import {
  CustomModal, CustomTable, DatePicker, ErrorHandler, Svg,
} from '../../component/common';
import { Constant } from '../../utilities';
import { getLeadsSchema, setLeadsSchema } from '../../utilities/Storage';
import { callStatusOptions, dateString, getHoursDiff } from '../../utilities/Utils';
import ExotelCallPatchDetails from './ExotelCallPatchDetails';
import SmsDetails from './SmsDetails';
import ViewLeadDetails from './ViewLeadDetails';
import {
  agentMeta, lead, leadBulkUpload, leadMeta,
} from '../../assets/api/axios';
import CreateLead from './CreateLead';
import { ProcessingStatus } from '../../component/derived/table-list';
import permission from '../../access&permissions/permission';
import WhatsAppDetails from './WhatsAppDetails';

const getQueryParams = (data) => {
  const leadSchema = getLeadsSchema();
  const schema = leadSchema && leadSchema.schema;
  const param = QueryString.parse(data);
  let {
    l, p, createdOnFrom, createdOnTill,
  } = param;
  const {
    searchText = '',
    agents = (schema && schema.defaultAgent) || '',
    leadSources = '',
    leadStatuses = '',
    leadTypes = '',
    cities = '',
    lastCallStatuses = '',
    bdeLeads = '',
    kycStatues = '',
    bankStatues = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  createdOnFrom = Number(createdOnFrom)
    || (new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0));
  createdOnTill = Number(createdOnTill) || (new Date().setHours(23, 59, 59, 999));
  return ({
    l,
    p,
    createdOnFrom,
    createdOnTill,
    searchText,
    agents,
    leadSources,
    leadStatuses,
    leadTypes,
    cities,
    lastCallStatuses,
    bdeLeads,
    kycStatues,
    bankStatues,
  });
};

const getLeadMetaData = (data) => (
  <Row
    className="mx-n2 py-n1"
  >
    {data.map((item) => (
      <Col
        key={item.header}
        xs={12}
        md={8}
        lg={4}
        className="px-2 py-1 text-medium"
      >
        <div
          className="p-2 fs-01 h-100 meta-data"
        >
          <div
            className="font-weight-bold fs-0"
          >
            {`${item.content}` || '---'}
          </div>
          <div>
            {item.header}
          </div>
        </div>
      </Col>
    ))}
  </Row>
);

class LeadManagement extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    let leadSchema = getLeadsSchema();
    if (leadSchema) {
      const hrDiff = getHoursDiff(leadSchema.lastModifiedTime, new Date());
      if (hrDiff > 24) {
        leadSchema = null;
      }
    }
    this.state = {
      leadSchema: (leadSchema && leadSchema.schema) || null,
      loading: true,
      error: false,
      searchText: param.searchText,
      rowsPerPage: param.l,
      page: param.p,
      param,
      newParam: param,
      leads: null,
      leadStatusData: null,
      agentPerformance: null,
      leadStDate: param.createdOnFrom,
      leadEDate: param.createdOnTill,
      callbackStart: '',
      callbackEnd: '',
      file: null,
      uploadStatus: '',
      uploadErrorMessage: '',
      downloadLeads: false,
      downLoadStatusMsg: '',
      indexOfUpdatingLead: -1,
      selectedLeadId: '',
      updateLeadErrorMsg: '',
      performingAction: '',
      createLeadModal: false,
      showExotelCallModal: false,
      exotelCallDetails: null,
      showSmsModal: false,
      smsDetails: null,
      showDetailsModal: false,
      viewDetails: null,
      showCallOption: false,
      exotelNumberCode: '',
      missCallBack: false,
      whatsappModal: false,
      whatsappDetails: null,
    };
  }

  componentDidMount = () => {
    const { leadSchema, newParam } = this.state;
    if (leadSchema) {
      this.setState({
        newParam: {
          ...newParam,
          agents: newParam.agents || leadSchema.defaultAgent,
        },
      });
    } else {
      leadMeta(
        'GET',
        {
          metaType: 'schema',
        },
      ).then((res) => {
        this.setState({
          leadSchema: res.data,
          newParam: {
            ...newParam,
            agents: res.data.defaultAgent,
          },
        });
        setLeadsSchema({
          lastModifiedTime: new Date(),
          schema: res.data,
        });
      }).catch(() => {});
    }
    this.handleLoad();
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const {
      param, newParam: alteredParams,
      leadStDate, leadEDate, callbackStart, callbackEnd,
      searchText, missCallBack,
    } = this.state;
    const newParam = {
      ...param,
      ...alteredParams,
      ...data,
      searchText,
      createdOnFrom: Number(leadStDate),
      createdOnTill: Number(leadEDate),
    };
    const {
      l, p,
      createdOnFrom,
      createdOnTill,
      agents,
      leadSources,
      leadStatuses,
      leadTypes,
      cities,
      lastCallStatuses,
      bdeLeads,
      kycStatues,
      bankStatues,
    } = newParam;
    const offset = (p - 1) * l;

    lead(
      'GET',
      '',
      null,
      {
        view: 'list',
        offset: `${offset}`,
        limit: l,
        createdOnFrom,
        createdOnTill,
        searchText,
        agents,
        leadSources,
        leadStatuses,
        leadTypes,
        cities,
        lastCallStatuses,
        bdeLeads,
        kycStatues,
        bankStatues,
        callbackStart,
        callbackEnd,
        missCallBack,
      },
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            leads: res.data,
            loading: false,
            rowsPerPage: l,
            page: p,
            param: { ...newParam },
            missCallBack: false,
          }, () => {
            if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
              Object.keys(newParam).forEach((item) => {
                if (!newParam[item]) {
                  delete newParam[item];
                }
              });
              history.push({
                path: pathname,
                search: QueryString.stringify(newParam),
              });
            }
          });
        } else throw new Error();
      }).catch(() => {
        this.setState({
          loading: false,
          error: true,
          missCallBack: false,
        });
      });

    this.handleAgentPerformance();
    this.handleLeadStatusData();

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleAgentPerformance = (data = {}) => {
    const {
      param, newParam: alteredParams,
      leadStDate, leadEDate, callbackStart, callbackEnd,
      searchText, missCallBack,
    } = this.state;
    const newParam = {
      ...param,
      ...alteredParams,
      ...data,
      searchText,
      createdOnFrom: Number(leadStDate),
      createdOnTill: Number(leadEDate),
    };
    const {
      l, p,
      createdOnFrom,
      createdOnTill,
      agents,
      leadSources,
      leadStatuses,
      leadTypes,
      cities,
      lastCallStatuses,
      bdeLeads,
      kycStatues,
      bankStatues,
    } = newParam;
    const offset = (p - 1) * l;

    agentMeta(
      'GET',
      {
        metaType: 'performance',
        offset: `${offset}`,
        limit: l,
        createdOnFrom,
        createdOnTill,
        searchText,
        agents,
        leadSources,
        leadStatuses,
        leadTypes,
        cities,
        lastCallStatuses,
        bdeLeads,
        kycStatues,
        bankStatues,
        callbackStart,
        callbackEnd,
        missCallBack,
      },
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            agentPerformance: res.data,
          });
        }
      }).catch(() => {});
  }

  handleLeadStatusData = (data = {}) => {
    const {
      param, newParam: alteredParams,
      leadStDate, leadEDate, callbackStart, callbackEnd,
      searchText, missCallBack,
    } = this.state;
    const newParam = {
      ...param,
      ...alteredParams,
      ...data,
      searchText,
      createdOnFrom: Number(leadStDate),
      createdOnTill: Number(leadEDate),
    };
    const {
      l, p,
      createdOnFrom,
      createdOnTill,
      agents,
      leadSources,
      leadStatuses,
      leadTypes,
      cities,
      lastCallStatuses,
      bdeLeads,
      kycStatues,
      bankStatues,
    } = newParam;
    const offset = (p - 1) * l;

    lead(
      'GET',
      '',
      null,
      {
        view: 'aggregated',
        offset: `${offset}`,
        limit: l,
        createdOnFrom,
        createdOnTill,
        searchText,
        agents,
        leadSources,
        leadStatuses,
        leadTypes,
        cities,
        lastCallStatuses,
        bdeLeads,
        kycStatues,
        bankStatues,
        callbackStart,
        callbackEnd,
        missCallBack,
      },
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            leadStatusData: res.data,
          });
        }
      }).catch(() => {});
  }

  handleDownloadLeads = (data = {}) => {
    const {
      param, newParam: alteredParams,
      leadStDate, leadEDate, callbackStart, callbackEnd,
      searchText,
    } = this.state;
    const newParam = {
      ...param,
      ...alteredParams,
      ...data,
      searchText,
      createdOnFrom: Number(leadStDate),
      createdOnTill: Number(leadEDate),
    };
    const {
      l, p,
      createdOnFrom,
      createdOnTill,
      agents,
      leadSources,
      leadStatuses,
      leadTypes,
      cities,
      lastCallStatuses,
      bdeLeads,
      kycStatues,
      bankStatues,
    } = newParam;
    const offset = (p - 1) * l;

    lead(
      'GET',
      '',
      null,
      {
        view: 'download',
        offset: `${offset}`,
        limit: l,
        createdOnFrom,
        createdOnTill,
        searchText,
        agents,
        leadSources,
        leadStatuses,
        leadTypes,
        cities,
        lastCallStatuses,
        bdeLeads,
        kycStatues,
        bankStatues,
        callbackStart,
        callbackEnd,
      },
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            downLoadStatusMsg: res.data,
            downloadLeads: false,
          });
        } else throw new Error();
      }).catch((err) => {
        let error = 'Something went wrong.Retry';
        if (
          err
          && err.response
          && err.response.data
          && err.response.data.server_response
        ) {
          error = err.response.data.server_response;
        }
        this.setState({
          downloadLeads: false,
          downLoadStatusMsg: { message: error },
        });
      });
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const {
      l, p, searchText,
    } = param;
    if (loading) {
      this.source.cancel();
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
      searchText,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  handleDropdownChange = (data) => {
    this.setState((state) => ({
      newParam: { ...state.newParam, ...data },
    }));
  }

  handleOnChange = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  }

  handleDateChange = (startDate, endDate) => {
    this.setState({
      leadStDate: startDate,
      leadEDate: endDate,
    });
    this.applyFilters();
  }

  handleLeadUpload = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  }

  handleFileSubmit = () => {
    const { file } = this.state;
    const formData = new FormData();
    formData.append('data', file);
    this.setState({
      uploadStatus: 'loading',
    });
    leadBulkUpload(
      'POST',
      formData,
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          uploadStatus: 'success',
          uploadErrorMessage: '',
          file: null,
        }, () => {
          setTimeout(() => {
            this.setState({
              uploadStatus: '',
            });
          }, 3000);
        });
      } else {
        throw new Error();
      }
    }).catch((err) => {
      this.setState(() => {
        if (
          err
          && err.response
          && err.response.data
          && err.response.data[0].server_message
        ) {
          return {
            uploadStatus: 'error',
            uploadErrorMessage: err.response.data[0].server_message,
          };
        }
        return {
          uploadStatus: 'error',
          uploadErrorMessage: 'Oops! Something went wrong.',
        };
      }, () => {
        setTimeout(() => {
          this.setState({
            uploadStatus: '',
            uploadErrorMessage: '',
          });
        }, 5000);
      });
    });
  }

  updateLeadsData = (leadData) => {
    const { indexOfUpdatingLead, selectedLeadId } = this.state;
    const { leads } = this.state;
    if (
      indexOfUpdatingLead >= 0
      && leads.results[indexOfUpdatingLead].leadId === selectedLeadId
    ) {
      const updatedLeads = leads.results;
      updatedLeads[indexOfUpdatingLead] = leadData;
      this.setState({
        selectedLeadId: '',
        indexOfUpdatingLead: -1,
        leads: {
          ...leads,
          results: updatedLeads,
        },
      });
    }
  }

  patchLeads = (leadId, data) => {
    lead(
      'PATCH',
      leadId,
      data,
      {},
    ).then((response) => {
      this.setState({
        performingAction: '',
      });
      this.updateLeadsData(response.data);
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong!! Please Try Again!!';
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.server_response
      ) {
        errorMsg = err.response.data.server_response;
      }
      this.setState({
        updateLeadErrorMsg: errorMsg,
      });
    });
    this.retryPatchLead = () => {
      this.setState({
        updateLeadErrorMsg: '',
      });
      this.patchLeads(leadId, data);
    };
  }

  patchLeadStatus = async (event, leadDetails, index) => {
    this.setState({
      indexOfUpdatingLead: index,
      selectedLeadId: leadDetails.leadId,
      performingAction: 'UPDATE_LEAD_STATUS',
    });
    await this.patchLeads(
      leadDetails.leadId,
      {
        leadStatus: event.target.value,
      },
    );
  }

  patchLeadAssign = async (event, leadDetails, index) => {
    this.setState({
      indexOfUpdatingLead: index,
      selectedLeadId: leadDetails.leadId,
      performingAction: 'UPDATE_LEAD_ASSIGN',
    });
    await this.patchLeads(
      leadDetails.leadId,
      {
        assignedTo: event.target.value,
      },
    );
  }

  applyFilters = () => {
    const { newParam, searchText } = this.state;
    this.handleRequestProcessing({
      ...newParam,
      searchText,
      p: 1,
    });
  }

  resetFilters = () => {
    this.setState({
      searchText: '',
      callbackStart: '',
      callbackEnd: '',
      leadStDate: (new Date().setDate(new Date().getDate() - 1)),
      leadEDate: new Date(),
      newParam: {
        agents: '',
        leadSources: '',
        leadStatuses: '',
        leadTypes: '',
        cities: '',
        lastCallStatuses: '',
        bdeLeads: '',
        kycStatues: '',
        bankStatues: '',
      },
    });
    this.handleRequestProcessing({
      p: 1,
    });
  }

  toggleModal = (modalName, modalDetails, data = null) => {
    this.setState((state) => ({
      [modalName]: !state[modalName],
      [modalDetails]: data,
    }));
  }

  render() {
    const {
      loading, error, leads, page, rowsPerPage, param,
      searchText, leadStDate, leadEDate, callbackStart, callbackEnd,
      newParam, file, performingAction, selectedLeadId, updateLeadErrorMsg,
      showExotelCallModal, exotelCallDetails, showSmsModal, smsDetails,
      showDetailsModal, viewDetails, leadSchema, createLeadModal,
      leadStatusData, agentPerformance, uploadStatus, uploadErrorMessage,
      downloadLeads, downLoadStatusMsg, showCallOption, exotelNumberCode,
      whatsappModal, whatsappDetails,
    } = this.state;
    const { userPermission } = this.props;

    const filterConfForMultiSelect = [
      {
        key: 'agents',
        displayText: 'Agent View',
        options: (
          [
            {
              label: 'ALL', value: 'all',
            },
            {
              label: 'None', value: 'none',
            },
            ...(((leadSchema && leadSchema.agents) || []).map((item) => ({
              label: item,
              value: item,
            }))),
          ]
        ),
      },
      {
        key: 'leadSources',
        displayText: 'Lead Sources',
        options: (((leadSchema && leadSchema.leadSources) || []).map((item) => ({
          label: item,
          value: item,
        }))),
      },
      {
        key: 'leadStatuses',
        displayText: 'Lead Status',
        options: (((leadSchema && leadSchema.statuses) || []).map((item) => ({
          label: item,
          value: item,
        }))),
      },
      {
        key: 'leadTypes',
        displayText: 'Lead Type',
        options: (((leadSchema && leadSchema.leadTypes) || []).map((item) => ({
          label: item,
          value: item,
        }))),
      },
      {
        key: 'cities',
        displayText: 'City',
        options: (((leadSchema && leadSchema.cities) || []).map((item) => ({
          label: item,
          value: item,
        }))),
      },
      {
        key: 'lastCallStatuses',
        displayText: 'Call Status',
        options: ((callStatusOptions.map((call) => ({
          label: call.label,
          value: call.value,
        })))),
      },
      {
        key: 'bdeLeads',
        displayText: 'BDE Code',
        options: (((leadSchema && leadSchema.bdeLeads) || []).map((item) => ({
          label: item,
          value: item,
        }))),
      },
      {
        key: 'kycStatues',
        displayText: 'KYC Status',
        options: (((leadSchema && leadSchema.kycStatues) || []).map((item) => ({
          label: item,
          value: item,
        }))),
      },
      {
        key: 'bankStatues',
        displayText: 'Bank Status',
        options: (((leadSchema && leadSchema.bankStatues) || []).map((item) => ({
          label: item,
          value: item,
        }))),
      },
    ];
    const headers = [
      {
        key: 'leadId',
        displayText: 'Lead Id',
      },
      {
        key: 'details',
        displayText: 'Details',
        renderer: (data) => (
          <Button
            variant="outline-primary"
            onClick={() => {
              this.toggleModal('showDetailsModal', 'viewDetails', data);
            }}
            className="py-1 px-2 fs-01"
          >
            View
          </Button>
        ),
      },
      {
        key: 'phoneNumber',
        displayText: 'Phone No.',
        renderer: (data) => (
          <Row className="align-items-center minw-200p">
            {data.phoneNumber}
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="link"
              onClick={() => {
                this.toggleModal('showCallOption', 'exotelCallDetails', data);
              }}
              className="p-0"
            >
              <Svg
                svg="call"
                width="24"
                height="24"
              />
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="link"
              onClick={() => {
                this.toggleModal('showSmsModal', 'smsDetails', data);
              }}
              className="p-0"
            >
              <Svg
                svg="sms"
                width="24"
                height="24"
              />
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="link"
              onClick={() => {
                this.toggleModal('whatsappModal', 'whatsappDetails', data);
              }}
              className="p-0"
            >
              <Svg
                svg="whatsapp"
                width="24"
                height="24"
              />
            </Button>
          </Row>
        ),
      },
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => dateString(data.createdOn) || '--',
      },
      {
        key: 'leadSource',
        displayText: 'Lead Source',
      },
      {
        key: 'retailerName',
        displayText: 'Retailer Name',
      },
      {
        key: 'shopName',
        displayText: 'Shop Name',
      },
      {
        key: 'kycStatus',
        displayText: 'KYC Status',
      },
      {
        key: 'bankStatus',
        displayText: 'Bank Status',
      },
      {
        key: 'city',
        displayText: 'City',
      },
      {
        key: 'leadType',
        displayText: 'Lead Type',
      },
      {
        key: 'leadStatus',
        displayText: 'Lead Status',
        renderer: (data, index) => {
          if (performingAction === 'UPDATE_LEAD_STATUS'
            && selectedLeadId === data.leadId
            && !updateLeadErrorMsg
          ) {
            return (
              <div
                className="pt-1 d-flex align-item-center
                  justify-content-center"
              >
                <Spinner
                  variant="primary"
                  animation="border"
                  size="sm"
                />
              </div>
            );
          }
          return (
            <select
              onChange={(e) => {
                if (e.target.value !== '') {
                  this.patchLeadStatus(e, data, index);
                }
              }}
              value={data.leadStatus}
              className={`p-1 ${performingAction ? 'pointer-event-none' : ''}`}
              disabled={data.leadStatus === 'Lost' && !userPermission.includes(permission.LEAD_MANAGEMENT_ADMIN)}
            >
              <option value="">Select</option>
              {
                ((leadSchema && leadSchema.statuses) || []).map((status) => (
                  <option value={status} key={status}>{status}</option>
                ))
              }
            </select>
          );
        },
      },
      {
        key: 'storeId',
        displayText: 'EP Code',
        renderer: (data) => (
          <>
            {data.storeId ? (
              <Link
                to={`/retailer/${data.storeId}?view=onboarding`}
                className="text-primary"
              >
                {data.storeId}
              </Link>
            ) : (
              '--'
            )}
          </>
        ),
      },
      {
        key: 'comment',
        displayText: 'Comment',
      },
      {
        key: 'bdeCode',
        displayText: 'BDE Code',
      },
      {
        key: 'leadAssignment',
        displayText: 'Lead Assign',
        renderer: (data, index) => {
          if (
            performingAction === 'UPDATE_LEAD_ASSIGN'
            && selectedLeadId === data.leadId
            && !updateLeadErrorMsg
          ) {
            return (
              <div
                className="pt-1 d-flex align-item-center
                  justify-content-center"
              >
                <Spinner
                  variant="primary"
                  animation="border"
                  size="sm"
                />
              </div>
            );
          }
          return (
            <select
              onChange={(e) => {
                if (e.target.value !== '') {
                  this.patchLeadAssign(e, data, index);
                }
              }}
              value={data.leadAssignment}
              className={`p-1 ${performingAction ? 'pointer-event-none' : ''}`}
              disabled={!userPermission.includes(permission.LEAD_MANAGEMENT_ADMIN)}
            >
              <option value="">Select</option>
              {
                ((leadSchema && leadSchema.agents) || []).map((assign) => (
                  <option value={assign} key={assign}>{assign}</option>
                ))
              }
            </select>
          );
        },
      },
      {
        key: 'lastCallStatus',
        displayText: 'Call Status',
      },
      {
        key: 'disposition',
        displayText: 'Disposition',
      },
      {
        key: 'lastRemark',
        displayText: 'Remark',
      },
      {
        key: 'modifiedOn',
        displayText: 'Modified On',
        renderer: (data) => dateString(data.modifiedOn) || '--',
      },
      {
        key: 'nextCallDate',
        displayText: 'Next Call On',
        renderer: (data) => dateString(data.nextCallDate) || '--',
      },
    ];

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        <CustomModal
          show={!!updateLeadErrorMsg}
          onHide={() => {
            this.setState({
              updateLeadErrorMsg: '',
            });
          }}
          autoSize
          backdrop
          body={(
            <Container className="p-4">
              <Row className="mx-0 py-2 text-danger">
                {updateLeadErrorMsg}
              </Row>
              <Row
                className="mx-0 py-2 d-flex align-items-center flex-row-reverse"
              >
                <Button
                  variant="link"
                  className="font-weight-bold"
                  onClick={this.retryPatchLead}
                >
                  RETRY
                </Button>
                <Button
                  variant="link"
                  className="font-weight-bold text-danger"
                  onClick={() => {
                    this.setState({
                      updateLeadErrorMsg: '',
                      performingAction: '',
                    });
                  }}
                >
                  CANCEL
                </Button>
              </Row>
            </Container>
          )}
        />
        <CustomModal
          show={showCallOption}
          title="Call Initiate"
          onHide={() => {
            this.setState({
              showCallOption: false,
            });
          }}
          autoSize
          backdrop
          closeButton
          body={(
            <Container className="p-4">
              <Row
                className="h-100 d-flex align-items-center
                justify-content-center"
              >
                {
                  leadSchema && leadSchema.exotelNumberCodes
                  && leadSchema.exotelNumberCodes.map((item) => (
                    <Button
                      key={item}
                      variant="link"
                      className="font-weight-bold pl-5"
                      onClick={() => {
                        this.setState({
                          showCallOption: false,
                          exotelNumberCode: item,
                          showExotelCallModal: true,
                        });
                      }}
                    >
                      {item}
                    </Button>
                  ))
                }
              </Row>
            </Container>
          )}
        />
        {
          showExotelCallModal && (
            <ExotelCallPatchDetails
              show={showExotelCallModal}
              onHide={() => {
                this.toggleModal('showExotelCallModal', 'exotelCallDetails');
              }}
              leadId={exotelCallDetails.leadId}
              exotelNumberCode={exotelNumberCode}
              handleRequestProcessing={this.handleRequestProcessing}
            />
          )
        }
        {
          showSmsModal && (
            <SmsDetails
              show={showSmsModal}
              onHide={() => {
                this.toggleModal('showSmsModal', 'smsDetails');
              }}
              leadId={smsDetails.leadId}
              handleRequestProcessing={this.handleRequestProcessing}
            />
          )
        }
        {
          whatsappModal && (
            <WhatsAppDetails
              show={whatsappModal}
              onHide={() => {
                this.toggleModal('whatsappModal', 'whatsappDetails');
              }}
              leadId={whatsappDetails.leadId}
              handleRequestProcessing={this.handleRequestProcessing}
            />
          )
        }
        {
          showDetailsModal && (
            <ViewLeadDetails
              show={showDetailsModal}
              onHide={() => {
                this.toggleModal('showDetailsModal', 'viewDetails');
              }}
              leadId={viewDetails.leadId}
              userPermission={userPermission}
            />
          )
        }
        {
          createLeadModal && (
            <CreateLead
              show={createLeadModal}
              onHide={() => {
                this.setState({
                  createLeadModal: false,
                });
              }}
              handleRequestProcessing={this.handleRequestProcessing}
            />
          )
        }

        {(() => {
          let showcase = null;
          if (!leads && loading) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center
                justify-content-center"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (!leads && !loading && error) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center
                justify-content-center"
              >
                <ErrorHandler
                  retryLogic={() => this.retry()}
                />
              </div>
            );
          } else if (leads) {
            showcase = (
              <>
                <ProcessingStatus
                  show={loading || error}
                  loading={loading}
                  error={error}
                  onRetry={this.retry}
                  onCancel={this.onCancel}
                />
                <Row
                  className="fs-0"
                >
                  <Col
                    xs={24}
                    sm={8}
                    lg={8}
                    className="px-2 py-2"
                  >
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          className="rounded-0"
                        >
                          <Svg
                            svg="search"
                            width="1rem"
                            fill={Constant.Color.DARK}
                          />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        placeholder="Search with Lead Id, Phone No., Retailer Name, Shop Name, Retailer EP Code"
                        className="fs-01 rounded-0"
                        value={searchText}
                        onChange={this.handleOnChange}
                        autoComplete="off"
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            this.applyFilters();
                          }
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col
                    xs={24}
                    sm={8}
                    lg={4}
                    className="px-2 py-2 d-flex"
                  >
                    <div className="pt-1 pr-2">
                      Created On Start :
                    </div>
                    <div>
                      <DatePicker
                        onApply={(dateRange) => {
                          this.setState({
                            leadStDate: new Date(dateRange.startDate).setHours(0, 0, 0, 0),
                          });
                        }}
                        startDate={leadStDate}
                        onClear={() => {
                          this.setState({
                            leadStDate: '',
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    sm={8}
                    lg={4}
                    className="px-2 py-2 d-flex"
                  >
                    <div className="pt-1 pr-2">
                      Created On End :
                    </div>
                    <div>
                      <DatePicker
                        onApply={(dateRange) => {
                          this.setState({
                            leadEDate: new Date(dateRange.startDate).setHours(23, 59, 59, 999),
                          });
                        }}
                        startDate={leadEDate}
                        onClear={() => {
                          this.setState({
                            leadEDate: '',
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    sm={8}
                    lg={4}
                    className="px-2 py-2 d-flex"
                  >
                    <div className="pt-1 pr-2">
                      Call Back Start :
                    </div>
                    <div>
                      <DatePicker
                        onApply={(dateRange) => {
                          this.setState({
                            callbackStart: new Date(dateRange.startDate).setHours(0, 0, 0, 0),
                          });
                        }}
                        startDate={callbackStart}
                        onClear={() => {
                          this.setState({
                            callbackStart: '',
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    sm={8}
                    lg={4}
                    className="px-2 py-2 d-flex"
                  >
                    <div className="pt-1 pr-2">
                      Call Back End :
                    </div>
                    <div>
                      <DatePicker
                        onApply={(dateRange) => {
                          this.setState({
                            callbackEnd: new Date(dateRange.startDate).setHours(23, 59, 59, 999),
                          });
                        }}
                        startDate={callbackEnd}
                        onClear={() => {
                          this.setState({
                            callbackEnd: '',
                          });
                        }}
                      />
                    </div>
                  </Col>
                  {
                    filterConfForMultiSelect.map((item) => (
                      <Col
                        key={item.key}
                        xs={24}
                        sm={12}
                        md={8}
                        xl={6}
                        className="px-2 pt-3 d-flex"
                      >
                        <div>
                          <Select
                            id={item.key}
                            placeholder={item.displayText}
                            onChange={(selectedItems) => {
                              const selectedItemsString = selectedItems.reduce(
                                (acc, selItem, i) => acc.concat(i === 0 ? '' : ',', selItem.value), '',
                              );
                              this.handleDropdownChange({ [item.key]: selectedItemsString });
                            }}
                            selectedVal={newParam[item.key]}
                            options={item.options}
                            isMulti
                            value={newParam[item.key]
                              ? (newParam[item.key].split(','))
                                .map((val) => ({ label: val, value: val }))
                              : []}
                            className="minw-150p"
                          />
                        </div>
                      </Col>
                    ))
                  }
                </Row>
                <Row
                  className="pt-4 pb-2 d-flex bg-white"
                >
                  <Col
                    xs="auto"
                    className="px-2"
                  >
                    <Button
                      variant="primary"
                      className="fs-01 font-weight-bold"
                      onClick={() => {
                        this.setState({
                          createLeadModal: true,
                        });
                      }}
                    >
                      <Svg
                        svg="add"
                        width="20"
                        height="20"
                      />
                      &nbsp;
                      CREATE LEAD
                    </Button>
                  </Col>
                  <Col
                    xs="auto"
                    className="px-2"
                  >
                    <Button
                      variant="primary"
                      className="fs-01 font-weight-bold"
                      onClick={() => {
                        this.setState({
                          downloadLeads: true,
                          downLoadStatusMsg: '',
                        }, this.handleDownloadLeads());
                      }}
                      disabled={downloadLeads}
                    >
                      <Svg
                        svg="download"
                        width="20"
                        height="20"
                      />
                      &nbsp;&nbsp;DOWNLOAD LEADS &nbsp;&nbsp;
                      {
                        downloadLeads && (
                          <Spinner
                            variant="primary"
                            animation="border"
                            size="sm"
                          />
                        )
                      }
                    </Button>
                  </Col>
                  <Col
                    xs="auto"
                    className="px-3 d-flex"
                  >
                    <div
                      className="pt-1"
                    >
                      <b>Upload File :</b>
                      &nbsp;&nbsp;
                    </div>
                    <div>
                      <input
                        type="file"
                        accept={
                          `.csv,
                              application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                              application/vnd.ms-excel`
                        }
                        onChange={this.handleLeadUpload}
                      />
                      <Button
                        variant="primary"
                        className="border border-dark py-1"
                        disabled={uploadStatus === 'loading' || !file}
                        onClick={this.handleFileSubmit}
                      >
                        {
                          uploadStatus === 'loading' && (
                            <Spinner
                              variant="secondary"
                              animation="border"
                              size="sm"
                            />
                          )
                        }
                        &nbsp;&nbsp;SUBMIT&nbsp;&nbsp;
                      </Button>
                    </div>
                    {
                      uploadStatus === 'success' && (
                        <div
                          className="text-center pl-2 fs-2 text-success"
                        >
                          <b>File Uploaded Successfully!!</b>
                        </div>
                      )
                    }
                  </Col>
                  <div className="ml-auto d-flex">
                    <Col
                      xs="auto"
                      className="px-2"
                    >
                      <Button
                        variant="outline-primary"
                        className="fs-01 font-weight-bold"
                        onClick={() => {
                          this.setState({
                            searchText: '',
                            leadStDate: '',
                            leadEDate: '',
                            callbackStart: new Date().setHours(0, 0, 0, 0),
                            callbackEnd: new Date().setHours(23, 59, 59, 999),
                            newParam: {
                              agents: newParam.agents,
                              leadSources: '',
                              leadStatuses: '',
                              leadTypes: '',
                              cities: '',
                              lastCallStatuses: '',
                              bdeLeads: '',
                              kycStatues: '',
                              bankStatues: '',
                            },
                          }, this.handleRequestProcessing);
                        }}
                      >
                        {'Today\'s call back'}
                      </Button>
                    </Col>
                    <Col
                      xs="auto"
                      className="px-2"
                    >
                      <Button
                        variant="outline-primary"
                        className="fs-01 font-weight-bold"
                        onClick={() => {
                          this.setState({
                            callbackStart: '',
                            callbackEnd: '',
                            missCallBack: true,
                          }, this.handleRequestProcessing);
                        }}
                      >
                        View missed call backs
                      </Button>
                    </Col>
                    <Col
                      xs="auto"
                      className="px-2"
                    >
                      <Button
                        variant="success"
                        className="fs-01 font-weight-bold"
                        onClick={this.applyFilters}
                      >
                        APPLY FILTERS
                      </Button>
                    </Col>
                    <Col
                      xs="auto"
                      className="px-2"
                    >
                      <Button
                        variant="primary"
                        className="fs-01 font-weight-bold"
                        onClick={this.resetFilters}
                      >
                        RESET FILTERS
                      </Button>
                    </Col>
                  </div>
                </Row>
                {
                  downLoadStatusMsg && (
                    <Row
                      className="pl-6 fs-1 text-success bg-white"
                    >
                      {downLoadStatusMsg.message || ''}
                    </Row>
                  )
                }
                {
                  uploadStatus === 'error' && (
                    <div
                      className="text-center pl-2 fs-2 text-danger"
                    >
                      <b>{uploadErrorMessage}</b>
                    </div>
                  )
                }
                <Row
                  className="py-3 bg-white"
                >
                  <Col
                    xs={24}
                    md={10}
                    lg={12}
                    className="px-2"
                  >
                    <div>
                      <b>Agent Performance</b>
                    </div>
                    {getLeadMetaData([
                      {
                        header: 'No. of Attempts',
                        content: (agentPerformance && agentPerformance.totalCallAttempts) || '--',
                      },
                      {
                        header: 'Total Connects',
                        content: (agentPerformance && agentPerformance.totalConnectsCount) || '--',
                      },
                      {
                        header: 'Talk Time',
                        content: (agentPerformance && agentPerformance.talkTime) || '--',
                      },
                    ])}
                  </Col>
                  <Col
                    xs={24}
                    md={14}
                    lg={12}
                    className="px-2"
                  >
                    <div>
                      <b>Lead Status</b>
                    </div>
                    {getLeadMetaData([
                      {
                        header: 'No. of Lost Leads',
                        content: (leadStatusData && leadStatusData.lostLeadCount) || '--',
                      },
                      {
                        header: 'Converted',
                        content: (leadStatusData && leadStatusData.converted) || '--',
                      },
                      {
                        header: 'No Connected',
                        content: (leadStatusData && leadStatusData.noConnect) || '--',
                      },
                      {
                        header: 'Callbacks',
                        content: (leadStatusData && leadStatusData.callbacks) || '--',
                      },
                    ])}
                  </Col>
                </Row>
                <Row
                  className="bg-white"
                >
                  <Col
                    xs={24}
                    className="px-0"
                  >
                    <CustomTable
                      headers={headers}
                      content={leads.results}
                      keyField="leadId"
                      l={param.l}
                      p={param.p}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      totalItems={leads.count}
                      hasPrev={leads.hasPrev}
                      hasNext={leads.hasNext}
                      onNext={this.onNext}
                      onPrev={this.onPrev}
                      onSubmitPage={this.onSubmitPage}
                      onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                      updateRowsPageInput={this.handleRowsPageInput}
                    />
                  </Col>
                </Row>
              </>
            );
          }
          return showcase;
        })()}
      </Container>
    );
  }
}

LeadManagement.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default LeadManagement;
