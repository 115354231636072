import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import QueryString from 'query-string';
import { catalogBulkUpload } from '../../assets/api/axios';
import {
  CustomTable, ErrorHandler, Svg,
} from '../../component/common';
import { Constant } from '../../utilities';

const getFileIcon = (link) => (link
  ? (
    <Button
      variant="link"
      className="p-0 fs-01"
      onClick={() => {
        window.open(link, '_blank');
      }}
    >
      <Svg
        svg="file"
        width="1.3rem"
        fill={Constant.Color.PRIMARY}
      />
    </Button>
  )
  : null
);

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    l, p,
  });
};

class ExportCleanupTool extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    this.state = {
      loading: true,
      error: false,
      storeId: '',
      file: null,
      rowsPerPage: param.l,
      page: param.p,
      param,
      exportCatalogHistory: null,
      uploadStatus: '',
      errorMessage: '',
    };
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p,
    } = newParam;
    const offset = (p - 1) * l;

    catalogBulkUpload(
      'GET',
      null,
      null,
      {
        offset: `${offset}`,
        limit: l,
      },
    ).then((res) => {
      this.setState({
        loading: false,
        exportCatalogHistory: res.data,
        error: false,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleFileUpload = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  }

  handleFileSubmit = () => {
    const { file, storeId } = this.state;
    const formData = new FormData();
    formData.append('exportFile', file);
    formData.append('store_id', storeId);
    this.setState({
      uploadStatus: 'loading',
    });
    catalogBulkUpload(
      'POST',
      formData,
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          uploadStatus: 'success',
          errorMessage: '',
          file: null,
          storeId: '',
        }, () => {
          setTimeout(() => {
            this.setState({
              uploadStatus: '',
            });
          }, 3000);
          this.handleLoad();
        });
      } else {
        throw new Error();
      }
    }).catch((err) => {
      this.setState(() => {
        if (
          err
          && err.response
          && err.response.data
          && err.response.data[0].server_message
        ) {
          return {
            uploadStatus: 'error',
            errorMessage: err.response.data[0].server_message,
          };
        }
        return {
          uploadStatus: 'error',
          errorMessage: 'Oops! Something went wrong.',
        };
      });
    });
  }

  render() {
    const {
      storeId, file, uploadStatus, errorMessage,
      loading, error, exportCatalogHistory,
      rowsPerPage, page, param,
    } = this.state;
    const { history } = this.props;

    const headers = [
      {
        key: 'storeId',
        displayText: 'Store Id',
        renderer: (data) => (
          <Button
            variant="link"
            className="p-0 fs-01"
            onClick={() => {
              history.push({
                pathname: `/export-cleanup-tool/${data.storeId}`,
                state: {
                  storeId: data.storeId,
                  exportId: data.id,
                },
              });
            }}
            disabled={data.state !== 'PROCESSED'}
          >
            {data.storeId}
          </Button>
        ),
      },
      {
        key: 'created_on',
        displayText: 'Uploaded on',
        renderer: (data) => data.createdOn && new Date(data.createdOn).toLocaleString(),
      },
      {
        key: 'user',
        displayText: 'User',
      },
      {
        key: 'fileLink',
        displayText: 'Uploaded File',
        renderer: (data) => getFileIcon(data.fileLink),
      },
      {
        key: 'state',
        displayText: 'Status',
      },
    ];

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        <Row>
          <Col
            xs={24}
            sm={12}
            md={7}
            className="d-flex pt-3"
          >
            <div>
              <b>
                <span className="text-danger">
                  *&nbsp;
                </span>
                Store Id:
              </b>
            </div>
            <div
              className="pl-3 w-75"
            >
              <input
                type="text"
                className="form-control"
                name="storeId"
                placeholder="EP Code (Required*)"
                value={storeId}
                onChange={this.handleChange}
              />
            </div>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={8}
            className="d-flex pt-3"
          >
            <div>
              <b>
                <span className="text-danger">
                  *&nbsp;
                </span>
                Upload CSV File:
              </b>
            </div>
            <div
              className="pl-3 w-75"
            >
              <input
                type="file"
                accept={
                  `.csv,
                  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                  application/vnd.ms-excel`
                }
                onChange={this.handleFileUpload}
              />
            </div>
          </Col>
          <Col
            xs="auto"
            className="d-flex justify-content-center pt-3 py-2"
          >
            <Button
              variant="primary"
              disabled={uploadStatus === 'loading' || !storeId || !file}
              onClick={this.handleFileSubmit}
            >
              {
                uploadStatus === 'loading' && (
                  <>
                    <Spinner
                      variant="secondary"
                      animation="border"
                      size="sm"
                    />
                    &nbsp;&nbsp;
                  </>
                )
              }
              SUBMIT
            </Button>
          </Col>
        </Row>
        <Row
          className="d-flex justify-content-center"
        >
          {
            uploadStatus === 'loading' && (
              <div
                className="text-center pt-3 fs-2"
              >
                <b>Wait for few minutes...</b>
              </div>
            )
          }
          {
            uploadStatus === 'error' && (
              <div
                className="text-center pt-3 fs-2 text-danger"
              >
                <b>{errorMessage}</b>
              </div>
            )
          }
          {
            uploadStatus === 'success' && (
              <div
                className="text-center pt-3 fs-2 text-success"
              >
                <b>File Uploaded Successfully!!</b>
              </div>
            )
          }
        </Row>
        <Row>
          <Col
            xs={24}
            className="pt-5 pb-2"
          >
            <b>Catalog Cleanup History</b>
          </Col>
        </Row>
        {(() => {
          let showcase = null;
          if (loading) {
            showcase = (
              <div
                className="d-flex align-items-center
                justify-content-center pt-3"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (error) {
            showcase = (
              <div
                className="d-flex align-items-center
                justify-content-center pt-3"
              >
                <ErrorHandler
                  retryLogic={() => this.retry()}
                />
              </div>
            );
          } else {
            showcase = (
              <Row>
                <Col
                  xs={24}
                  className="px-0 table-section"
                >
                  <CustomTable
                    headers={headers}
                    content={exportCatalogHistory.results}
                    keyField="id"
                    l={param.l}
                    p={param.p}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    totalItems={exportCatalogHistory.count}
                    hasPrev={exportCatalogHistory.hasPrevious}
                    hasNext={exportCatalogHistory.hasNext}
                    onNext={this.onNext}
                    onPrev={this.onPrev}
                    onSubmitPage={this.onSubmitPage}
                    onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                    updateRowsPageInput={this.handleRowsPageInput}
                  />
                </Col>
              </Row>
            );
          }
          return showcase;
        })()}
      </Container>
    );
  }
}

ExportCleanupTool.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default ExportCleanupTool;
