/* eslint-disable no-empty */
// Import the functions you need from the SDKs you need
import { initializeApp } from '@firebase/app';
import { getMessaging, getToken } from '@firebase/messaging';
import { getAnalytics } from '@firebase/analytics';
import { setFCMToken } from '../../utilities/Storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: 'AIzaSyA-bEHbpS1_u2jYirPREocclg9CAqu7VG8',
  authDomain: 'crm-internal-team.firebaseapp.com',
  projectId: 'crm-internal-team',
  storageBucket: 'crm-internal-team.appspot.com',
  messagingSenderId: '1072060762017',
  appId: '1:1072060762017:web:c6479d941a130ba2992de0',
  measurementId: 'G-LB9WR3P50G',
};

function getFCMToken(messaging) {
  if (Notification.permission === 'granted') {
    getToken(
      messaging,
      {
        vapidKey: 'BEX9OVScrUwcLVaO8PHpggaWTQCsg2RoLuV8TdifP7SUMgOzAXV_srb132IKiuSQMeCX8qTjbq17Ilu86nCP9bQ',
      },
    ).then((currentToken) => {
      setFCMToken(currentToken);
    }).catch(() => {});
  } else {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        getToken(
          messaging,
          {
            vapidKey: 'BEX9OVScrUwcLVaO8PHpggaWTQCsg2RoLuV8TdifP7SUMgOzAXV_srb132IKiuSQMeCX8qTjbq17Ilu86nCP9bQ',
          },
        ).then((currentToken) => {
          setFCMToken(currentToken);
        }).catch(() => {});
      }
    });
  }
}

export default function initiateFirebase() {
  try {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    const messaging = getMessaging(app);
    getAnalytics(app);

    getFCMToken(messaging);
  } catch (e) {}
}
