import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, FormControl, InputGroup, Spinner, Button, Form,
} from 'react-bootstrap';
import Axios from 'axios';
import {
  productSearch, cart, customProduct, uploadImage,
} from '../../assets/api/axios';
import ProductView from './ProductView';
import Cart from './Cart';
import CustomModal from '../../component/common/CustomModal';
import ThumbnailSvg from '../../assets/icon/camera.svg';
import { Constant } from '../../utilities';
import Svg from '../../component/common/Svg';

const { CancelToken } = Axios;
let axiosController = null;

function getCartItems(storeItems) {
  return storeItems.reduce((acc, store) => {
    const storeProduct = store.products.map((product) => ({
      productId: product.id,
      quantity: product.quantity,
      storeId: store.store.code,
    }));
    return [...acc, ...storeProduct];
  }, []);
}

class ProductList extends Component {
  constructor() {
    super();
    this.state = {
      searchText: '',
      offset: 0,
      limit: 20,
      products: [],
      productCount: 0,
      cartItems: [],
      activeTimeout: -1,
      loader: false,
      openCart: false,
      showAddCustomProduct: false,
      productName: '',
      unit: '',
      quantity: '',
      productUrl: '',
      uploadingImg: false,
      addingCustomProductToCart: false,
      failAddingCustomProductToCart: false,
      failAddingProductToCart: '',
    };
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.handleProductSearch = this.handleProductSearch.bind(this);
    this.handleAddProductInCart = this.handleAddProductInCart.bind(this);
    this.handleLazyLoad = this.handleLazyLoad.bind(this);
    this.handleOpenCartView = this.handleOpenCartView.bind(this);
  }

  componentDidMount() {
    const { cartDetails, showProducts, selectedStore } = this.props;
    if (cartDetails) {
      this.setState({ cartItems: getCartItems(cartDetails.storeItems) });
    }
    if (showProducts && selectedStore) {
      this.handleSelectedStoreProducts(selectedStore);
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { selectedStore } = this.props;
    if (nextProps.selectedStore) {
      this.setState({
        loader: true,
      }, () => {
        this.handleSelectedStoreProducts(nextProps.selectedStore);
      });
    }
    if ((selectedStore !== nextProps.selectedStore)) {
      this.setState({
        searchText: '',
        offset: 0,
        limit: 20,
        products: [],
        productCount: 0,
        cartItems: [],
        activeTimeout: -1,
        loader: false,
        openCart: false,
        showAddCustomProduct: false,
        productName: '',
        unit: '',
        quantity: '',
        productUrl: '',
        addingCustomProductToCart: false,
        failAddingCustomProductToCart: false,
        uploadingImg: false,
        failAddingProductToCart: '',
      });
    }
  }

  handleSelectedStoreProducts = (selectedStore) => {
    const {
      lat, lng, customerId, cartId,
    } = this.props;
    this.setState({
      loader: true,
    });
    productSearch(
      'GET',
      {},
      customerId,
      `${lat},${lng}`,
      selectedStore,
      cartId,
    ).then((res) => {
      this.setState({
        products: res.data.results,
        loader: false,
      });
    }).catch(() => {
      this.setState({
        loader: false,
      });
    });
  }

  // eslint-disable-next-line react/sort-comp
  toggleAddCustomProduct = () => {
    this.setState((state) => ({
      showAddCustomProduct: !state.showAddCustomProduct,
    }));
  }

  exitAddCustomProductModal = () => {
    this.toggleAddCustomProduct();
    this.setState({
      productName: '',
      unit: '',
      quantity: '',
      productUrl: '',
      uploadingImg: false,
      addingCustomProductToCart: false,
      failAddingCustomProductToCart: false,
    });
  }

  handleChangeCustomProductDetails = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  addCustomProduct = () => {
    const {
      productName, unit, quantity,
      productUrl,
    } = this.state;
    const {
      cartId, lat, lng, getAndUpdateCartDetails, selectedStore,
    } = this.props;
    this.setState({
      addingCustomProductToCart: true,
    });
    customProduct(
      'POST',
      null,
      {
        name: productName,
        unitString: unit,
        quantity: parseInt(quantity, 10),
        url: productUrl,
        storeId: selectedStore,
        cartId,
      },
      {
        location: `${lat},${lng}`,
      },
    ).then(() => {
      this.exitAddCustomProductModal();
      getAndUpdateCartDetails();
    }).catch(() => {
      this.setState({
        addingCustomProductToCart: false,
        failAddingCustomProductToCart: true,
      });
    });
  }

  handleAddOrRemoveCustomProduct = (productId, quantity, storeId) => {
    const {
      cartId,
      customerId,
      updateCartDetails,
    } = this.props;
    const data = {
      action: 'PATCH_CUSTOM_PRODUCT',
      product: {
        productId,
        quantity,
        storeId,
      },
    };
    cart('PATCH', data, cartId, customerId)
      .then((res) => {
        if (res.status === 200) {
          const { storeItems } = res.data;
          const newCartItems = getCartItems(storeItems);
          this.setState({
            cartItems: newCartItems,
          }, () => {
            updateCartDetails(res.data);
          });
        }
      });
  }

  uploadProductImageUrl = (event) => {
    this.setState({
      uploadingImg: true,
    });
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('img', file);
    if (file) {
      uploadImage(
        'post',
        formData,
      ).then((res) => {
        this.setState({
          uploadingImg: false,
          productUrl: res.data.url,
        });
      }).catch(() => {
        this.setState({
          uploadingImg: false,
        });
      });
    }
  }

  handleSearchTextChange(event) {
    const { value } = event.target;
    const { activeTimeout, loader } = this.state;
    const { selectedStore } = this.props;
    if (activeTimeout !== -1) {
      clearTimeout(activeTimeout);
    }
    if (loader) {
      axiosController.cancel();
    }
    if (value) {
      axiosController = CancelToken.source();
      this.setState({
        searchText: value,
        offset: 0,
        products: [],
        productCount: 0,
        loader: true,
      }, () => {
        const timeOutId = setTimeout(() => {
          this.setState({ activeTimeout: -1 }, () => {
            this.handleProductSearch();
          });
        }, 300);
        this.setState({ activeTimeout: timeOutId });
      });
      return;
    }
    this.setState({
      searchText: value,
      activeTimeout: -1,
      offset: 0,
      products: [],
      productCount: 0,
      loader: false,
    });
    if (selectedStore) {
      this.handleSelectedStoreProducts(selectedStore);
    }
  }

  handleProductSearch() {
    const {
      lat,
      lng,
      customerId,
      cartId,
      selectedStore,
    } = this.props;
    const {
      products,
      searchText,
      offset,
      limit,
    } = this.state;
    productSearch('get', {}, customerId, `${lat},${lng}`, selectedStore, cartId, searchText,
      offset, limit, axiosController.token)
      .then((res) => {
        const { data } = res;
        const { results, count } = data;
        this.setState({
          products: [...products, ...results],
          productCount: count,
          loader: false,
        });
      })
      .catch((error) => {
        if (!Axios.isCancel(error)) {
          this.setState({
            loader: false,
          });
        }
      });
  }

  handleAddProductInCart(productId, quantity, storeId) {
    const {
      cartId,
      customerId,
      updateCartDetails,
      resetDeliveryPreference,
    } = this.props;
    const data = {
      action: 'PATCH_PRODUCT',
      product: {
        productId,
        quantity,
        storeId,
      },
      resetDeliveryPreference,
    };
    cart('PATCH', data, cartId, customerId)
      .then((res) => {
        if (res.status === 200) {
          const { storeItems } = res.data;
          const newCartItems = getCartItems(storeItems);
          this.setState({
            cartItems: newCartItems,
          }, () => {
            updateCartDetails(res.data);
          });
        } else {
          throw new Error();
        }
      }).catch((error) => {
        this.setState(() => {
          if (
            error
            && error.response
            && error.response.status === 400
            && error.response.data
            && error.response.data[0]
          ) {
            return {
              failAddingProductToCart: error.response.data[0].client_message,
            };
          }
          return {
            failAddingProductToCart: 'Oops Something went wrong, Please try again!!',
          };
        });
      });
  }

  handleLazyLoad(event) {
    const {
      scrollHeight,
      scrollTop,
      offsetHeight,
    } = event.target;
    const {
      productCount,
      offset,
      limit,
      loader,
    } = this.state;
    if ((scrollHeight - scrollTop === offsetHeight)
    && (offset + limit < productCount) && !loader) {
      this.setState({
        offset: offset + limit,
        loader: true,
      }, () => { this.handleProductSearch(); });
    }
  }

  handleOpenCartView(status) {
    this.setState({ openCart: status });
  }

  render() {
    const {
      cartDetails, modifyCurrentState, selectedStore,
    } = this.props;
    const {
      searchText,
      products,
      cartItems,
      loader,
      openCart,
      showAddCustomProduct,
      productName,
      unit,
      quantity,
      productUrl,
      addingCustomProductToCart,
      failAddingCustomProductToCart,
      uploadingImg,
      failAddingProductToCart,
    } = this.state;

    // Logic For already custom Item selected store
    // const customProductAdded = cartDetails ? cartDetails.storeItems.filter(
    //   (item) => !!item.customProducts.length,
    // ) : [];
    // const storeCodeHavingCustomProduct = customProductAdded.length > 0
    //   ? customProductAdded[0].store.code
    //   : '';
    // let showAddCustomProductCTA = false;
    // if (storeCodeHavingCustomProduct) {
    //   if (storeCodeHavingCustomProduct === selectedStore) {
    //     showAddCustomProductCTA = true;
    //   }
    // } else if (selectedStore) {
    //   showAddCustomProductCTA = true;
    // }
    const showAddCustomProductCTA = !!selectedStore;

    return (
      <Container fluid className="h-100 p-0 py-3">
        <CustomModal
          show={showAddCustomProduct}
          autoSize
          onHide={() => {
            this.exitAddCustomProductModal();
          }}
          body={(
            <Container
              fluid
              className="p-4"
            >
              <Row>
                <Col
                  xs={24}
                  className="text-center"
                >
                  {
                    uploadingImg ? (
                      <Spinner
                        variant="primary"
                        animation="border"
                      />
                    ) : (
                      <div
                        className="image-upload-section mx-auto mt-n13 mt-md-0"
                      >
                        <div
                          className="profile-image-container bg-white border"
                        >
                          <img
                            src={productUrl || ThumbnailSvg}
                            alt=""
                          />
                        </div>
                        <Form.Group
                          controlId="imageUpload"
                          className="m-0"
                        >
                          <Form.Label
                            className="m-0"
                          >
                            <span
                              className="d-flex align-items-center p-2 bg-primary rounded-circle"
                            >
                              <Svg
                                svg="camera"
                                fill={Constant.Color.WHITE}
                                width="1.4rem"
                              />
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="file"
                            className="d-none"
                            accept="image/*"
                            onChange={(e) => {
                              this.uploadProductImageUrl(e);
                            }}
                            capture="product-img"
                          />
                        </Form.Group>
                      </div>
                    )
                  }
                </Col>
              </Row>
              <Row
                className="p-0 pt-4"
              >
                <Col
                  xs={24}
                >
                  <div
                    className="pb-1 font-weight-bold"
                  >
                    Custom Item Name
                    <span className="text-danger">*</span>
                  </div>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      name="productName"
                      value={productName}
                      placeholder="Enter Name (e.g - Basmati Rice ...)"
                      onChange={this.handleChangeCustomProductDetails}
                    />
                  </div>
                </Col>
              </Row>
              <Row
                className="px-0 pt-4"
              >
                <Col
                  xs={12}
                >
                  <div
                    className="pb-1 font-weight-bold"
                  >
                    Unit
                    <span className="text-danger">*</span>
                  </div>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      name="unit"
                      value={unit}
                      placeholder="Kg / Gram / Unit ..."
                      onChange={this.handleChangeCustomProductDetails}
                    />
                  </div>
                </Col>
                <Col
                  xs={12}
                >
                  <div
                    className="pb-1 font-weight-bold"
                  >
                    Quantity
                    <span className="text-danger">*</span>
                  </div>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      name="quantity"
                      value={quantity}
                      placeholder="1 / 2 / 3 ..."
                      onChange={this.handleChangeCustomProductDetails}
                    />
                  </div>
                </Col>
              </Row>
              <Row
                className="px-0 pt-4 justify-content-end"
              >
                <Col
                  xs="auto"
                >
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      this.exitAddCustomProductModal();
                    }}
                    disabled={addingCustomProductToCart}
                  >
                    EXIT
                  </Button>
                </Col>
                <Col
                  xs="auto"
                >
                  <Button
                    variant="primary"
                    disabled={
                      !productName || !unit || !quantity || addingCustomProductToCart
                    }
                    onClick={() => {
                      this.addCustomProduct();
                    }}
                  >
                    ADD TO CART
                  </Button>
                </Col>
              </Row>
              {
                failAddingCustomProductToCart && (
                  <Row
                    className="px-0 pt-2"
                  >
                    <Col
                      xs={24}
                      className="font-weight-bold text-danger text-center"
                    >
                      Oops Something went wrong, Please try again!!
                    </Col>
                  </Row>
                )
              }
            </Container>
          )}
        />
        <CustomModal
          show={!!failAddingProductToCart}
          title="Cart"
          onHide={() => {
            this.setState({
              failAddingProductToCart: '',
            });
          }}
          autoSize
          border
          closeButton
          body={(
            <Container>
              <Row>
                <Col
                  xs={24}
                  className="py-3 border"
                >
                  <Row
                    className="pt-2"
                  >
                    <Col
                      className="font-weight-bold text-danger text-center"
                    >
                      {failAddingProductToCart}
                    </Col>
                  </Row>
                  <Row
                    className="pt-4 text-center"
                  >
                    <Col>
                      <Button
                        variant="primary"
                        className="py-1"
                        onClick={() => {
                          this.setState({
                            failAddingProductToCart: '',
                          });
                        }}
                      >
                        Okay
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
        )}
        />
        <Row className="shadow">
          <Col xs={24}>
            <InputGroup size="lg" className="rounded-0">
              <FormControl
                type="text"
                value={searchText}
                placeholder="Search product"
                onChange={this.handleSearchTextChange}
                className="rounded-0"
              />
              <InputGroup.Append>
                <Button
                  variant="warning"
                  onClick={() => this.handleOpenCartView(true)}
                >
                  View Cart
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          {
            (
              showAddCustomProductCTA
              && !!searchText
            ) && (
              <Col
                xs={24}
                className="py-3 bg-warning-lighter"
              >
                <Row
                  className="border mx-0 bg-white"
                >
                  <Col
                    xs={12}
                    className="d-flex align-items-center font-weight-bold"
                  >
                    Can&apos;t find
                    <span
                      className="text-success"
                    >
                      &nbsp;&quot;
                      {searchText}
                      &quot;&nbsp;
                    </span>
                  </Col>
                  <Col
                    xs={12}
                    className="px-0"
                  >
                    <Button
                      block
                      variant="outline-success"
                      className="rounded-0 font-weight-bold"
                      onClick={this.toggleAddCustomProduct}
                    >
                      + ADD CUSTOM ITEM
                    </Button>
                  </Col>
                </Row>
              </Col>
            )
          }
        </Row>
        <Row onScroll={this.handleLazyLoad} className="product-list-container bg-light m-0">
          {products.map((product, index) => (
            <Col
              xs={24}
              lg={12}
              key={product.id}
              className={index % 2 ? 'pl-lg-0' : ''}
            >
              <ProductView
                product={product}
                cartItems={cartItems}
                handleAddProductInCart={this.handleAddProductInCart}
                className="bg-white mb-3 py-3"
              />
            </Col>
          ))}
          <Col xs={24} className={loader ? 'text-center p-2' : 'd-none'}>
            <Spinner animation="border" variant="primary" />
          </Col>
        </Row>
        <Row>
          <Col xs>
            <Cart
              show={openCart}
              cartItems={cartItems}
              cartDetails={cartDetails}
              {...this.props}
              handleAddProductInCart={this.handleAddProductInCart}
              handleOpenCartView={this.handleOpenCartView}
              handleAddOrRemoveCustomProduct={this.handleAddOrRemoveCustomProduct}
              modifyCurrentState={modifyCurrentState}
              // storeCodeHavingCustomProduct={storeCodeHavingCustomProduct}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

ProductList.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  customerId: PropTypes.string.isRequired,
  cartId: PropTypes.number.isRequired,
  cartDetails: PropTypes.shape({
    cart: PropTypes.shape({
      billAmount: PropTypes.number.isRequired,
      productCount: PropTypes.number.isRequired,
    }).isRequired,
    storeItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  selectedStore: PropTypes.string,
  updateCartDetails: PropTypes.func.isRequired,
  modifyCurrentState: PropTypes.func.isRequired,
  getAndUpdateCartDetails: PropTypes.func.isRequired,
  showProducts: PropTypes.bool,
  resetDeliveryPreference: PropTypes.bool,
};

ProductList.defaultProps = {
  cartDetails: null,
  selectedStore: null,
  showProducts: false,
  resetDeliveryPreference: false,
};

export default ProductList;
