import React from 'react';

const TimingIcon = () => (
  <svg
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 13A6 6 0 1 0 7 1a6 6 0 0 0 0 12Z"
      stroke="#788898"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M7.002 3.4v3.603l2.544 2.544"
      stroke="#788898"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TimingIcon;
