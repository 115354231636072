import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ width, stroke }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ width, transform: 'rotate(180deg)' }}
    viewBox="0 0 24 25"
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15 6l-6 6m6 6l-6-6"
    />
  </svg>
);

Svg.propTypes = {
  width: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
};

export default Svg;
