import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ fill, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ width }}
    viewBox="0 0 22.765 14.51"
  >
    <g id="Show_password_Eye_" data-name="Show password (Eye)" transform="translate(-229.874 -357.109)">
      <g id="Group_4950" data-name="Group 4950" transform="translate(229.874 357.109)">
        <g id="Group_4949" data-name="Group 4949" transform="translate(0 0)">
          <path id="Path_3366" data-name="Path 3366" d="M22.62,99.647c-.2-.278-5.049-6.812-11.238-6.812S.348,99.369.144,99.647a.751.751,0,0,0,0,.886c.2.278,5.049,6.812,11.238,6.812s11.034-6.534,11.238-6.812A.75.75,0,0,0,22.62,99.647Zm-11.238,6.2c-4.559,0-8.507-4.337-9.676-5.754,1.167-1.419,5.107-5.753,9.676-5.753s8.507,4.336,9.676,5.754C19.891,101.509,15.951,105.843,11.382,105.843Z" transform="translate(0 -92.835)" fill={fill} />
        </g>
      </g>
      <g id="Group_4952" data-name="Group 4952" transform="translate(236.753 359.861)">
        <g id="Group_4951" data-name="Group 4951" transform="translate(0 0)">
          <path
            id="Path_3367"
            data-name="Path 3367"
            d="M159.225,154.725a4.5,4.5,0,1,0,4.5,4.5A4.508,4.508,0,0,0,159.225,154.725Zm0,7.5a3,3,0,1,1,3-3A3.005,3.005,0,0,1,159.225,162.23Z"
            transform="translate(-154.722 -154.725)"
            fill={fill}
          />
        </g>
      </g>
    </g>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Svg;
