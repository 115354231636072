import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { couponOffer } from '../../assets/api/axios';
import { CellLayout, ErrorHandler, Svg } from '../../component/common';
import { Constant, Utils } from '../../utilities';

function getStatusIcon(status) {
  return status
    ? <span className="text-success">&#10004;</span>
    : <span className="text-danger">&#10006;</span>;
}

class CouponDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      error: false,
      coupon: null,
    };
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleLoad = () => {
    const { match } = this.props;
    couponOffer(
      'get',
      null,
      match.params.couponId,
    ).then((res) => {
      this.setState({
        loading: false,
        coupon: res.data,
      });
    })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  render() {
    const { loading, error, coupon } = this.state;
    const { history } = this.props;
    return (
      <Container
        fluid
        className="px-0 bg-white h-100"
      >
        <Row
          className="mx-0 flex-column h-100"
        >
          <Col
            className="flex-grow-0"
          >
            <Row
              className="mx-0 py-2 align-items-center"
            >
              <Col
                xs="auto"
                className="pl-0 pr-4"
              >
                <Button
                  variant="link"
                  onClick={() => {
                    history.push(`/coupon${history.location.search || ''}`);
                  }}
                  className="p-0"
                >
                  <Svg
                    svg="arrow"
                    width="1.2rem"
                    fill={Constant.Color.PRIMARY}
                  />
                </Button>
              </Col>
              {!loading && !error && coupon && (
                <Col
                  xs="auto"
                  className="px-0 font-weight-bold"
                >
                     Coupon
                     &nbsp;
                  {coupon.couponCode}
                </Col>
              )}
            </Row>
          </Col>
          <Col
            classNam="flex-grow-1 px-0"
          >
            {loading && (
              <div
                className="d-flex align-items-center
                justify-content-center h-100"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            )}
            {!loading && error && (
              <ErrorHandler
                retryLogic={() => {
                  this.setState({
                    loading: true,
                    error: false,
                  }, () => {
                    this.handleLoad();
                  });
                }}
              />
            )}
            {!loading && !error && coupon && (
              <Row
                className="mx-0 h-100"
              >
                <Col
                  xs={24}
                  md={12}
                  className="px-0 py-1"
                >
                  <Row
                    className="mx-0 flex-column h-100"
                  >
                    <Col
                      className="px-0 overflow-y"
                    >
                      <CellLayout
                        data={[
                          {
                            header: 'CampaignId',
                            content: coupon.campaignId,
                          },
                          {
                            header: 'Campaign',
                            content: coupon.campaignName,
                          },
                          {
                            header: 'Campaign Type',
                            content: coupon.campaignType,
                          },
                          {
                            header: 'Coupon Id',
                            content: coupon.couponId,
                          },
                          {
                            header: 'Created',
                            content: Utils.dateString(coupon.createdOn),
                          },
                          {
                            header: 'Funded By',
                            content: coupon.fundedBy,
                          },
                          {
                            header: 'Description',
                            content: coupon.description,
                          },
                          {
                            header: 'Deeplink',
                            content: coupon.deeplink,
                          },
                          {
                            header: 'Priority',
                            content: coupon.priority,
                          },
                          {
                            header: 'Valid From',
                            content: Utils.dateString(coupon.validFrom),
                          },
                          {
                            header: 'Valid Till',
                            content: Utils.dateString(coupon.validTill),
                          },
                          {
                            header: 'Discount Type',
                            content: coupon.discountType,
                          },
                          {
                            header: 'Discount Value',
                            content: coupon.discountValue,
                          },
                          {
                            header: 'Max. Discount',
                            content: coupon.maxDiscount,
                          },
                          {
                            header: 'Min Spend Amount',
                            content: coupon.minSpendAmount,
                          },
                          {
                            header: 'No of usage',
                            content: coupon.numberOfUsage,
                          },
                          {
                            header: 'Home Delivery Allowed',
                            content: getStatusIcon(coupon.isHomeDeliveryAllowed),
                          },
                          {
                            header: 'Store Pickup Allowed',
                            content: getStatusIcon(coupon.isPickupFromStoreAllowed),
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  className="px-0 py-1"
                >
                  <Row
                    className="mx-0 flex-column h-100"
                  >
                    <Col
                      className="px-0 overflow-y"
                    >
                      <CellLayout
                        data={coupon.meta.map((item) => ({
                          header: item.key,
                          content: item.value,
                        }))}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

CouponDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      couponId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default CouponDetails;
