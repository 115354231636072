/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Col, Row, Button,
} from 'react-bootstrap';
import { CustomModal, CustomTable, Svg } from '../../../../component/common';
import { getOrderTagStringFormat } from '../../../../utilities/Utils';
import UpdateQty from './UpdateQty';
import CreateNewProduct from './CreateNewProduct';
import LibraryProduct from './LibraryProduct';
import { libraryStoreProduct, storeProduct } from '../../../../assets/api/axios';
import { Constant } from '../../../../utilities';
import permission from '../../../../access&permissions/permission';

function renderModificationFactor(rowContent) {
  let textColor = '';
  if (rowContent.modificationFactor === 'None') {
    textColor = 'text-secondary';
  }
  if (rowContent.modificationFactor === 'Out of Stock') {
    textColor = 'text-danger';
  }
  if (rowContent.modificationFactor === 'Quantity Update' || rowContent.modificationFactor === 'Price Altered') {
    textColor = 'text-primary';
  }
  if (rowContent.modificationFactor === 'Newly Added' || rowContent.modificationFactor === 'Product Replaced') {
    textColor = 'text-green';
  }
  return (
    <div className="">
      <div className={textColor}>{rowContent.modificationFactor}</div>
    </div>
  );
}

export default function ModifyOrder(props) {
  const { userPermission } = props;
  const isForceAdd = userPermission.includes(permission.FORCE_ADD_BULK_UPLOAD);
  const {
    orderDetails, show, onCancel,
    modifyOrder, modifiedOrderPatchStatus, modifyError,
  } = props;

  const {
    orderItems, customOrderItems,
  } = orderDetails;

  const [tab, setTab] = useState('MAIN');
  const [createMode, setCreatingMode] = useState('');
  const [newOrderItems, setNewOrderItems] = useState(
    orderItems.map((item) => ({ ...item, isExisting: true })),
  );
  const [newCustomOrderItems, setNewCustomItems] = useState(customOrderItems);
  const [libProduct, setLibProduct] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState('');
  const [replaceProductIds, setReplaceProductIds] = useState([]);
  const [orderModificationComment, setOrderModificationComment] = useState('');

  const onUpdateQty = (product, newQuantity) => {
    const originalProduct = orderItems.find((item) => item.id === product.id);
    const modifiedData = { quantity: newQuantity, modificationFactor: newQuantity ? 'Quantity Update' : 'Out of Stock' };
    if (originalProduct && newQuantity === originalProduct.quantity) {
      modifiedData.modificationFactor = 'None';
    }
    setNewOrderItems(newOrderItems.map((item) => {
      if (item.id === product.id) { return { ...item, ...modifiedData }; }
      return { ...item };
    }));
  };

  const onChangeSP = (product, newSellingPrice) => {
    if (product.mrp < Number(newSellingPrice)) {
      return;
    }
    const originalProduct = orderItems.find((item) => item.id === product.id);
    const modifiedData = { sellingPrice: Number(newSellingPrice), modificationFactor: 'Price Altered' };
    if (originalProduct && Number(newSellingPrice) === originalProduct.sellingPrice) {
      modifiedData.modificationFactor = 'None';
    }
    setNewOrderItems(newOrderItems.map((item) => {
      if (item.id === product.id) { return { ...item, ...modifiedData }; }
      return { ...item };
    }));
  };

  const onChangeMRP = (product, newMrp) => {
    const originalProduct = orderItems.find((item) => item.id === product.id);
    const modifiedData = { mrp: Number(newMrp), modificationFactor: 'Mrp Altered' };
    if (originalProduct && Number(newMrp) === originalProduct.mrp) {
      modifiedData.modificationFactor = 'None';
    }
    setNewOrderItems(newOrderItems.map((item) => {
      if (item.id === product.id) { return { ...item, ...modifiedData }; }
      return { ...item };
    }));
  };

  const headers = [
    {
      key: 'id',
      displayText: 'Id',
      renderer: (data) => (
        <>
          <div>
            {data.id}
          </div>
          {data.isFreeSample && (
            <div
              className="product-free-sample"
            >
              <span className="text-white font-weight-bold fs-001">
                Free Sample
              </span>
            </div>
          )}
          {data.isFlashSaleProduct && (
            <div
              className="product-flash-sale"
            >
              <span className="text-white font-weight-bold fs-001">
                Flash Sale
              </span>
            </div>
          )}
          {data.isCompetitivePricingProduct && (
          <div
            className="product-competitive-price"
          >
            <span className="text-white font-weight-bold fs-001">
              Competitive Price
            </span>
          </div>
          )}
        </>
      ),
    },
    {
      key: 'displayName',
      displayText: 'Name',
    },
    {
      key: 'approxGrammage',
      displayText: 'Approx Grammage',
      renderer: (data) => data?.approxGrammage || '--',
    },
    {
      key: 'mrp',
      displayText: 'Mrp',
      renderer: (data) => (
        // in case of newly added product isExisting will be undefined
        tab === 'CONFIRMATION' || (!data.isExisting) ? (
          <>{data.mrp}</>
        ) : (
          <input
            type="number"
            id={`mrp-${data.id}`}
            value={data.mrp}
            name="mrp"
            autoFocus
            disabled={data.isFreeSample || data.isFlashSaleProduct || data.isCompetitivePricingProduct}
            onChange={(e) => onChangeMRP(data, e.target.value)}
          />
        )
      ),
    },
    {
      key: 'sellingPrice',
      displayText: 'Selling Price',
      renderer: (data) => (
        // in case of newly added product isExisting will be undefined
        tab === 'CONFIRMATION' || (!data.isExisting) ? (
          <>{data.sellingPrice}</>
        ) : (
          <input
            type="number"
            id={`sp-${data.id}`}
            value={data.sellingPrice}
            name="sellingPrice"
            autoFocus
            disabled={data.isFreeSample || data.isFlashSaleProduct || data.isCompetitivePricingProduct}
            onChange={(e) => onChangeSP(data, e.target.value)}
          />
        )
      ),
    },
    {
      key: 'image',
      displayText: 'Image',
      renderer: (data) => (
        <div
          className="product-image-div"
        >
          <img
            src={data.image}
            alt=""
            className="w-100 h-100"
          />
        </div>
      ),
    },
    // full fillment wrong interpretation
    // prescri deprecated
    ...tab !== 'CONFIRMATION' ? [{
      key: 'tag',
      displayText: 'Tag',
      renderer: (data) => getOrderTagStringFormat(data.tag),
    }] : [],
    {
      key: 'quantity',
      displayText: 'QTY',
      renderer: (data) => (
        tab !== 'CONFIRMATION' ? (
          <UpdateQty rowData={data} onUpdateQty={onUpdateQty} />
        ) : (
          <div>{data.quantity}</div>
        )
      ),
    },
    {
      key: 'modificationFactor',
      displayText: 'Modification Factor',
      renderer: (data) => renderModificationFactor(data),
    },
    {
      key: 'button',
      displayText: 'Add Alternate Product',
      renderer: (data) => (
        <Button
          variant="outline-primary"
          className="px-2 py-2 fs-01"
          disabled={data.isFreeSample || data.isFlashSaleProduct || data.isCompetitivePricingProduct}
          onClick={() => {
            setSelectedProductId(data.id);
            setTab('ADD_LIB_PRODUCT');
          }}
        >
          Suggest Alternative Product
        </Button>
      ),
    },
  ];

  const customItemsConfig = [
    {
      key: 'id',
      displayText: 'Id',
    },
    {
      key: 'displayName',
      displayText: 'Name',
    },
    {
      key: 'sellingPrice',
      displayText: 'SP',
    },
    {
      key: 'unitString',
      displayText: 'Unit',
    },
    {
      key: 'image',
      displayText: 'Image',
      renderer: (data) => (
        <div
          className="product-image-div"
        >
          <img
            src={data.image}
            alt=""
            className="w-100 h-100"
          />
        </div>
      ),
    },
    {
      key: 'quantity',
      displayText: 'QTY',
    },
    {
      key: 'editProduct',
      displayText: 'Edit Product',
      renderer: (data) => (
        <Button
          variant="outline-primary"
          disabled={!modifyOrder}
          onClick={() => {
            setCreatingMode('UPDATECUSTOM');
            setLibProduct({
              ...data,
            });
            setTab('ADD_NEW_PRODUCT');
          }}
        >
          Update
        </Button>
      ),
    },
    {
      key: 'deleteProduct',
      displayText: 'Add / Remove',
      renderer: (data) => (
        <Button
          variant="link"
          onClick={() => {
            setNewCustomItems(newCustomOrderItems.map((item) => {
              if (item.id === data.id) {
                return {
                  ...data,
                  modificationFactor: 'Product Updated',
                  quantity: data.quantity ? 0 : 1,
                };
              }
              return { ...item };
            }));
          }}
        >
          {
            data.quantity ? (
              <Svg
                svg="trash"
                width={24}
                height={24}
                stroke={Constant.Color.PRIMARY}
              />
            ) : (
              <span>Add</span>
            )
          }
        </Button>
      ),
    },
    {
      key: 'modificationStatus',
      displayText: 'Modification Factor',
      renderer: (data) => (
        <span
          className={data.quantity > 0 ? 'text-secondary' : 'text-danger'}
        >
          {
            (!!data.quantity && !!data.sellingPrice)
              ? 'Product Updated'
              : `${data.quantity ? 'NA' : 'Out Of Stock'}`
          }
        </span>
      ),
    },
  ];

  function patchRetailerProduct(product) {
    storeProduct(
      'PATCH',
      {
        mrp: product.mrp,
        sellingPrice: product.sellingPrice,
        name: product.displayName,
        forceAdd: isForceAdd,
      },
      product.storeProductId,
      {
        storeId: orderDetails.store.code,
      },
    ).then((res) => {
      if (res.status === 200) {
        setCreatingMode('');
        setNewOrderItems([...newOrderItems, {
          ...product, modificationFactor: 'Newly Added', fulfilmentStatus: true,
        }]);
      }
    }).catch(() => {});
  }

  function patchCustomProduct(product) {
    libraryStoreProduct(
      'POST',
      {
        name: product.displayName,
        mrp: product.mrp,
        sellingPrice: product.sellingPrice,
        storeId: orderDetails.store.code,
      },
    ).then((res) => {
      const { LibraryProductID } = res.data;
      if (Object.keys(LibraryProductID)) {
        setNewOrderItems([...newOrderItems, {
          ...product,
          id: LibraryProductID.id,
          image: LibraryProductID.image,
          modificationFactor: 'Newly Added',
          fulfilmentStatus: true,
        }]);
        setTab('MAIN');
        setCreatingMode('');
      }
    }).catch(() => { });
  }

  function handleAddProductInList(product) {
    if (createMode === 'FROMLIBRARY') {
      patchRetailerProduct(product);
    } else if (createMode === 'CREATENEW') {
      patchCustomProduct(product);
    } else if (createMode === 'UPDATECUSTOM') {
      setTab('MAIN');
      setCreatingMode('');
      setNewCustomItems(newCustomOrderItems.map((item) => {
        if (item.id === product.id) { return { ...product, modificationFactor: 'Product Updated' }; }
        return { ...item };
      }));
    } else if (selectedProductId) {
      setNewOrderItems(newOrderItems.map((item) => {
        if (item.id === selectedProductId) {
          const originalProductId = replaceProductIds.find((replaceProd) => replaceProd.replaceId === item.id);
          if (originalProductId) {
            setReplaceProductIds(replaceProductIds.map((replaceProduct) => {
              if (replaceProduct.replaceId === item.id) {
                return { originalId: replaceProduct.originalId, replaceId: product.id };
              }
              return { ...replaceProduct };
            }));
          } else {
            setReplaceProductIds([...replaceProductIds, { originalId: item.id, replaceId: product.id }]);
          }
          return {
            ...product, modificationFactor: 'Product Replaced', fulfilmentStatus: true, oldRetailerProductId: item.retailerProductId,
          };
        }
        return { ...item };
      }));
      setTab('MAIN');
      setSelectedProductId('');
    } else {
      setNewOrderItems([...newOrderItems, {
        ...product, modificationFactor: 'Newly Added', fulfilmentStatus: true,
      }]);
    }
  }

  function handleAddNewlyCreatedProduct(creatingMode, libProductNew) {
    setLibProduct(libProductNew);
    setCreatingMode(creatingMode);
  }

  const confirmOrderModifications = () => {
    setTab('CONFIRMATION');
  };

  let totalAmount = 0;
  newOrderItems.forEach((element) => {
    totalAmount += element.sellingPrice * element.quantity;
  });
  newCustomOrderItems.forEach((element) => {
    totalAmount += element.sellingPrice * element.quantity;
  });

  const isCustomProduct = !!newCustomOrderItems.length;
  const isOrderModified = !!newOrderItems.filter((item) => item.modificationFactor !== 'None').length;
  const eligibleCustomProducts = newCustomOrderItems.filter((item) => !!item.quantity);
  const nonModifiedCustomProducts = eligibleCustomProducts.filter((item) => !item.sellingPrice);

  return (
    <CustomModal
      show={show}
      size="xl"
      onHide={onCancel}
      body={(
        <Container>
          <Row className="mb-4 pl-4 py-2 bg-primary text-white">
            <b>{`MODIFYING ORDER - ${orderDetails.orderId}`}</b>
          </Row>
          {
            tab === 'MAIN' && (
              <Row>
                <Col
                  xs={24}
                  className="d-flex justify-content-center py-4"
                >
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setTab('ADD_LIB_PRODUCT');
                    }}
                    disabled={false}
                    className="mx-2"
                  >
                    ADD NEW PRODUCT FROM LIBRARY
                  </Button>
                </Col>
                <Col
                  xs={24}
                >
                  <div className="">
                    <b>Order Items</b>
                  </div>
                  <CustomTable
                    keyField="id"
                    headers={headers}
                    content={newOrderItems}
                    isPaginated={false}
                    totalItems={newOrderItems.length}
                  />
                </Col>
                <Col
                  xs={24}
                >
                  <div className="">
                    <b>Custom Order Items</b>
                  </div>
                  <CustomTable
                    keyField="id"
                    headers={customItemsConfig}
                    content={newCustomOrderItems}
                    isPaginated={false}
                    totalItems={newCustomOrderItems.length}
                  />
                </Col>
                <Col
                  xs={24}
                  className="d-flex justify-content-center my-2"
                >
                  <Button
                    variant="primary"
                    onClick={confirmOrderModifications}
                    disabled={
                      (!isOrderModified && !isCustomProduct)
                      || !!nonModifiedCustomProducts.length
                    }
                    className="mx-2"
                  >
                    SAVE
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={onCancel}
                    disabled={false}
                    className="mx-2"
                  >
                    CANCEL
                  </Button>
                </Col>
              </Row>
            )
          }
          {
            (tab === 'ADD_NEW_PRODUCT'
            || (
              tab === 'ADD_LIB_PRODUCT' && createMode === 'FROMLIBRARY'
            )) && (
              <Col
                xs={24}
              >
                <CreateNewProduct
                  onCancel={() => setTab('MAIN')}
                  createMode={createMode || 'CREATENEW'}
                  product={libProduct}
                  handleAddProductInList={handleAddProductInList}
                />
              </Col>
            )
          }
          {
            tab === 'ADD_LIB_PRODUCT' && (
              <LibraryProduct
                onCancel={() => setTab('MAIN')}
                orderId={orderDetails.orderId}
                storeId={orderDetails.store.code}
                orderItems={newOrderItems}
                handleAddProductInList={handleAddProductInList}
                handleAddNewlyCreatedProduct={handleAddNewlyCreatedProduct}
              />
            )
          }
          {
            tab === 'CONFIRMATION' && (
              <Row>
                <Col
                  xs={24}
                  className="d-flex font-weight-bold pb-2"
                >
                  {/* add custom order items length here */}
                  <div className="pr-2">{`Total Items: ${newOrderItems.length + newCustomOrderItems.length}`}</div>
                  <div className="px-2">{`Total Amount: ${totalAmount}`}</div>
                </Col>
                <Col
                  xs={24}
                >
                  <div className="">
                    <b>Order Items</b>
                  </div>
                  <CustomTable
                    keyField="id"
                    headers={headers}
                    content={newOrderItems}
                    isPaginated={false}
                    totalItems={newOrderItems.length}
                  />
                </Col>
                <Col
                  xs={24}
                >
                  <div className="">
                    <b>Custom Order Items</b>
                  </div>
                  <CustomTable
                    keyField="id"
                    headers={customItemsConfig}
                    content={newCustomOrderItems}
                    isPaginated={false}
                    totalItems={newCustomOrderItems.length}
                  />
                </Col>
                <Col
                  xs={24}
                  className="py-4 d-flex justify-content-center"
                >
                  <input
                    type="text"
                    name="orderModificationComment"
                    placeholder="Add Reason of Modification"
                    value={orderModificationComment}
                    className="input-box"
                    onChange={(e) => {
                      setOrderModificationComment(e.target.value);
                    }}
                  />
                </Col>
                <Col
                  xs={24}
                  className="d-flex justify-content-center"
                >
                  <Button
                    variant="primary"
                    onClick={() => modifyOrder(
                      newOrderItems, newCustomOrderItems, replaceProductIds, orderModificationComment,
                    )}
                    disabled={
                      (modifiedOrderPatchStatus === 'loading')
                      || !orderModificationComment
                    }
                    className="mx-2"
                  >
                    CONFIRM
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => setTab('MAIN')}
                    className="mx-2"
                  >
                    BACK
                  </Button>
                </Col>
                {
                  modifiedOrderPatchStatus === 'error' && (
                    <Col xs={24} className="text-danger text-center py-4">
                      {modifyError}
                    </Col>
                  )
                }
              </Row>
            )
          }
        </Container>
      )}
    />
  );
}

ModifyOrder.propTypes = {
  orderDetails: PropTypes.shape({
    orderId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    orderItems: PropTypes.arrayOf(PropTypes.shape({})),
    customOrderItems: PropTypes.arrayOf(PropTypes.shape({})),
    store: PropTypes.shape({
      code: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  }).isRequired,
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  modifyOrder: PropTypes.func.isRequired,
  modifiedOrderPatchStatus: PropTypes.string.isRequired,
  modifyError: PropTypes.string.isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};
