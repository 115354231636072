import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import moment from 'moment';
import {
  CustomModal, CustomTable, ErrorHandler, Svg,
} from '../../component/common';
import '../../assets/scss/LeadManagement/LeadDetails.scss';
import ExotelCallPatchDetails from './ExotelCallPatchDetails';
import SmsDetails from './SmsDetails';
import { lead, lmsStoreCommentLogs } from '../../assets/api/axios';
import { dateString, getDay } from '../../utilities/Utils';
import { Constant } from '../../utilities';
import { getLeadsSchema } from '../../utilities/Storage';
import permission from '../../access&permissions/permission';
import WhatsAppDetails from './WhatsAppDetails';

function getCellValue(item) {
  if (item.renderer) {
    return item.renderer();
  }
  return item.value;
}

function ViewLeadDetails(props) {
  const {
    show, onHide, leadId, userPermission,
  } = props;
  const getLeadSchema = getLeadsSchema();
  const getSchema = getLeadSchema.schema;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [performingAction, setPerformingAction] = useState('');
  const [updateLeadErrorMsg, setUpdateLeadErrorMsg] = useState('');
  const [showContent, setShowContent] = useState(-1);
  const [leadDetails, setLeadDetails] = useState(null);
  const [leadData, setLeadData] = useState(null);
  const [exotelCallModal, setExotelCallModal] = useState(false);
  const [smsModal, setSmsModal] = useState(false);
  const [whatsappModal, setWhatsappModal] = useState(false);
  const [comment, setComment] = useState('');
  const [addComment, setAddComment] = useState('');
  const [showCallOption, setShowCallOption] = useState(false);
  const [exotelNumberCode, setexotelNumberCode] = useState('');
  const [editDate, setEditDate] = useState(false);
  const [nextCallDate, setNextCallDate] = useState(new Date());
  const [comments, setComments] = useState(null);
  const [commentLoading, setCommentLoading] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [commentErrorMsg, setCommentErrorMsg] = useState('');
  const [commentModal, setCommentModal] = useState(false);

  const loadLeadDetails = () => {
    lead(
      'GET',
      leadId,
      null,
      {},
    ).then((res) => {
      setLeadDetails(res.data);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
      setError(true);
    });
  };

  useEffect(() => {
    loadLeadDetails();
    // eslint-disable-next-line
  }, []);

  const patchLeads = (data) => {
    lead(
      'PATCH',
      leadId,
      data,
      {},
    ).then((response) => {
      setPerformingAction('');
      setLeadDetails({
        activity: leadDetails.activity,
        ...response.data,
      });
      setUpdateLeadErrorMsg('');
      setLeadData(null);
      setCommentModal(false);
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong!! Please Try Again!!';
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.server_response
      ) {
        errorMsg = err.response.data.server_response;
      }
      setUpdateLeadErrorMsg(errorMsg);
      setCommentModal(false);
    });
  };

  const patchLeadDetail = async (value, type, leadType) => {
    setPerformingAction(type);
    setLeadData({ [leadType]: value });
    await patchLeads({ [leadType]: value });
  };

  const getCommentHistory = () => {
    setCommentLoading(true);
    setCommentError(false);
    setCommentErrorMsg('');
    lmsStoreCommentLogs(
      'GET',
      {
        storeCode: leadDetails && leadDetails.storeId,
        leadId: leadDetails && leadDetails.leadId,
        format: 'table',
      },
    ).then((res) => {
      if (res.status === 200) {
        setCommentLoading(false);
        setComments(res.data.data);
      } else {
        throw new Error();
      }
    }).catch((err) => {
      if (
        err
        && err.response
        && err.response.status === 400
        && err.response.data
        && err.response.data.errors
        && err.response.data.errors[0].message
      ) {
        setCommentErrorMsg(err.response.data.errors[0].message);
      }
      setCommentLoading(false);
      setCommentError(true);
    });
  };

  const postCommentHistory = () => {
    setAddComment('loading');
    let data = {
      leadId: leadDetails && leadDetails.leadId,
      comment,
      addedFrom: 'LMS Lead Details Page',
    };
    if (leadDetails && leadDetails.storeId) {
      data = {
        ...data,
        storeCode: leadDetails && leadDetails.storeId,
      };
    }
    lmsStoreCommentLogs(
      'POST',
      {},
      data,
    ).then(() => {
      setAddComment('');
      setComment('');
      setCommentModal(false);
    }).catch(() => {
      setAddComment('error');
    });
  };

  const leadInfo = [
    {
      key: 'column1',
      content: [
        {
          label: 'Lead Id',
          value: (leadDetails && leadDetails.leadId) || '--',
        },
        {
          label: 'Lead Date',
          value: (leadDetails && leadDetails.createdOn),
          renderer: () => dateString(leadDetails.createdOn) || '--',
        },
      ],
    },
    {
      key: 'column2',
      content: [
        {
          label: 'Retailer Name',
          value: (leadDetails && leadDetails.retailerName) || '--',
        },
        {
          label: 'Lead Source',
          value: (leadDetails && leadDetails.leadSource) || '--',
          renderer: () => {
            if (
              performingAction === 'UPDATE_LEAD_SOURCE'
              && !updateLeadErrorMsg
            ) {
              return (
                <div
                  className="pt-1 d-flex align-item-center
                    justify-content-center"
                >
                  <Spinner
                    variant="primary"
                    animation="border"
                    size="sm"
                  />
                </div>
              );
            }
            return (
              <select
                onChange={(e) => {
                  if (e.target.value !== '') {
                    patchLeadDetail(e.target.value, 'UPDATE_LEAD_SOURCE', 'leadSource');
                  }
                }}
                value={leadDetails.leadSource}
                className={`p-1 w-100 ${performingAction ? 'pointer-event-none' : ''}`}
              >
                <option value="">Select</option>
                {
                  ((getSchema && getSchema.leadSources) || []).map((source) => (
                    <option value={source}>{source}</option>
                  ))
                }
              </select>
            );
          },
        },
      ],
    },
    {
      key: 'column3',
      content: [
        {
          label: 'Shop Name',
          value: (leadDetails && leadDetails.shopName) || '--',
        },
        {
          label: 'Lead Assign',
          value: (leadDetails && leadDetails.leadAssignment) || '--',
          renderer: () => {
            if (
              performingAction === 'UPDATE_LEAD_ASSIGN'
              && !updateLeadErrorMsg
            ) {
              return (
                <div
                  className="pt-1 d-flex align-item-center
                    justify-content-center"
                >
                  <Spinner
                    variant="primary"
                    animation="border"
                    size="sm"
                  />
                </div>
              );
            }
            return (
              <select
                onChange={(e) => {
                  if (e.target.value !== '') {
                    patchLeadDetail(e.target.value, 'UPDATE_LEAD_ASSIGN', 'assignedTo');
                  }
                }}
                value={leadDetails.leadAssignment}
                className={`p-1 w-100 ${performingAction ? 'pointer-event-none' : ''}`}
                disabled={!userPermission.includes(permission.LEAD_MANAGEMENT_ADMIN)}
              >
                <option value="">Select</option>
                {
                  ((getSchema && getSchema.agents) || []).map((agent) => (
                    <option value={agent}>{agent}</option>
                  ))
                }
              </select>
            );
          },
        },
      ],
    },
    {
      key: 'column4',
      content: [
        {
          label: 'Disposition',
          value: (leadDetails && leadDetails.disposition) || '--',
        },
        {
          label: 'Lead Status',
          value: (leadDetails && leadDetails.leadStatus) || '--',
          renderer: () => {
            if (
              performingAction === 'UPDATE_LEAD_STATUS'
              && !updateLeadErrorMsg
            ) {
              return (
                <div
                  className="pt-1 d-flex align-item-center
                    justify-content-center"
                >
                  <Spinner
                    variant="primary"
                    animation="border"
                    size="sm"
                  />
                </div>
              );
            }
            return (
              <select
                onChange={(e) => {
                  if (e.target.value !== '') {
                    patchLeadDetail(e.target.value, 'UPDATE_LEAD_STATUS', 'leadStatus');
                  }
                }}
                value={leadDetails.leadStatus}
                className={`p-1 w-100 ${performingAction ? 'pointer-event-none' : ''}`}
                disabled={leadDetails.leadStatus === 'Lost' && !userPermission.includes(permission.LEAD_MANAGEMENT_ADMIN)}
              >
                <option value="">Select</option>
                {
                  ((getSchema && getSchema.statuses) || []).map((status) => (
                    <option value={status}>{status}</option>
                  ))
                }
              </select>
            );
          },
        },
      ],
    },
    {
      key: 'column5',
      content: [
        {
          label: 'EP Code',
          value: (leadDetails && leadDetails.storeId) || '--',
        },
        {
          label: 'City',
          value: (leadDetails && leadDetails.city) || '--',
          renderer: () => {
            if (
              performingAction === 'UPDATE_LEAD_CITY'
              && !updateLeadErrorMsg
            ) {
              return (
                <div
                  className="pt-1 d-flex align-item-center
                    justify-content-center"
                >
                  <Spinner
                    variant="primary"
                    animation="border"
                    size="sm"
                  />
                </div>
              );
            }
            return (
              <select
                onChange={(e) => {
                  if (e.target.value !== '') {
                    patchLeadDetail(e.target.value, 'UPDATE_LEAD_CITY', 'city');
                  }
                }}
                value={leadDetails.city}
                className={`p-1 w-100 ${performingAction ? 'pointer-event-none' : ''}`}
              >
                <option value="">Select</option>
                {
                  ((getSchema && getSchema.cities) || []).map((city) => (
                    <option value={city}>{city}</option>
                  ))
                }
              </select>
            );
          },
        },
      ],
    },
    {
      key: 'column6',
      content: [
        {
          label: 'Call Status',
          value: (leadDetails && leadDetails.lastCallStatus) || '--',
        },
        {
          label: 'Lead Type',
          value: (leadDetails && leadDetails.leadType) || '--',
          renderer: () => {
            if (
              performingAction === 'UPDATE_LEAD_TYPE'
              && !updateLeadErrorMsg
            ) {
              return (
                <div
                  className="pt-1 d-flex align-item-center
                    justify-content-center"
                >
                  <Spinner
                    variant="primary"
                    animation="border"
                    size="sm"
                  />
                </div>
              );
            }
            return (
              <select
                onChange={(e) => {
                  if (e.target.value !== '') {
                    patchLeadDetail(e.target.value, 'UPDATE_LEAD_TYPE', 'leadType');
                  }
                }}
                value={leadDetails.leadType}
                className={`p-1 w-100 ${performingAction ? 'pointer-event-none' : ''}`}
              >
                <option value="">Select</option>
                {
                  ((getSchema && getSchema.leadTypes) || []).map((type) => (
                    <option value={type}>{type}</option>
                  ))
                }
              </select>
            );
          },
        },
      ],
    },
    {
      key: 'column7',
      content: [
        {
          label: 'Remark',
          value: (leadDetails && leadDetails.lastRemark) || '--',
          renderer: () => (
            <div className="w-100">
              { (leadDetails && leadDetails.lastRemark) || '--'}
            </div>
          ),
        },
        {
          label: 'Comment',
          value: (leadDetails && leadDetails.comments) || '--',
          renderer: () => (
            <Button
              variant="outline-primary"
              onClick={() => {
                getCommentHistory();
                setCommentModal(true);
              }}
              className="py-1 px-2 fs-01"
            >
              View Comment
            </Button>
          ),
        },
      ],
    },
    {
      key: 'column8',
      content: [
        {
          label: 'Modified On',
          value: (leadDetails && leadDetails.modifiedOn) || '--',
          renderer: () => dateString(leadDetails.modifiedOn) || '--',
        },
        {
          label: 'Call Back Date',
          value: (leadDetails && leadDetails.nextCallDate) || '--',
          renderer: () => {
            if (
              performingAction === 'UPDATE_NEXT_CALL_DATE'
              && !updateLeadErrorMsg
            ) {
              return (
                <div
                  className="pt-1 d-flex align-item-center
                    justify-content-center"
                >
                  <Spinner
                    variant="primary"
                    animation="border"
                  />
                </div>
              );
            }
            return (
              <div className="w-100">
                {
                  editDate ? (
                    <>
                      <div>
                        <input
                          className="form-control rounded-0 fs-01 bg-light"
                          type="datetime-local"
                          name="nextCallDate"
                          onChange={(event) => {
                            setNextCallDate(new Date(event.target.value));
                          }}
                          min={moment(new Date()).format('YYYY-MM-DDThh:mm')}
                        />
                      </div>
                      <div className="pt-1">
                        <Button
                          variant="light"
                          className="fs-01 rounded-0 border"
                          onClick={() => {
                            if (nextCallDate) {
                              setPerformingAction('UPDATE_NEXT_CALL_DATE');
                              setEditDate(false);
                              setLeadData({ nextCallDate: `${moment(nextCallDate).format('DD-MM-YYYY HH:mm')}` });
                              patchLeads({ nextCallDate: `${moment(nextCallDate).format('DD-MM-YYYY HH:mm')}` });
                            }
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  ) : (
                    <Button
                      variant="link"
                      className="text-medium fs-02 p-0"
                      onClick={() => {
                        setEditDate(true);
                      }}
                    >
                      <span className="fs-1 text-right cursor-pointer text-primary">
                        {dateString(leadDetails.nextCallDate) || 'SELECT DATE'}
                      </span>
                    </Button>
                  )
                }
              </div>
            );
          },
        },
      ],
    },
    {
      key: 'column9',
      content: [
        {
          label: 'Call Attempts',
          value: (leadDetails && leadDetails.callAttemptsCount) || '--',
        },
        {
          label: 'Call Connected',
          value: (leadDetails && leadDetails.callConnectedCount) || '--',
        },
      ],
    },
  ];

  const body = (
    <>
      {(() => {
        let showcase = null;
        if (loading) {
          showcase = (
            <div
              className="h-100 d-flex align-items-center
              justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          );
        } else if (!loading && error) {
          showcase = (
            <div
              className="h-100 d-flex align-items-center
              justify-content-center pt-3"
            >
              <ErrorHandler
                retryLogic={() => {
                  setError(false);
                  setLoading(true);
                  loadLeadDetails();
                }}
              />
            </div>
          );
        } else if (!loading && !error && leadDetails) {
          showcase = (
            <Container
              fluid
            >
              <Row
                className="h-100 align-items-start
                align-content-start"
              >
                <Col
                  xs={24}
                  xl={12}
                  className="p-2 right-border overflow-y minh-800p"
                >
                  {leadInfo.map((info) => (
                    <Row className="pt-5 px-2">
                      {
                        info.content.map((item) => (
                          <>
                            <Col xs={6}>
                              <b>
                                {item.label}
                                :
                              </b>
                            </Col>
                            <Col xs={6}>
                              {getCellValue(item)}
                            </Col>
                          </>
                        ))
                      }
                    </Row>
                  ))}
                </Col>
                <Col
                  xs={24}
                  xl={12}
                  className="p-4"
                >
                  <Row>
                    <Col
                      xs={24}
                      className="pb-2"
                    >
                      <b className="fs-2">Activity Flow</b>
                    </Col>
                    <Col
                      xs={24}
                      className="border-black"
                    >
                      {
                        leadDetails.activity
                          && leadDetails.activity.length > 0
                          ? (
                            <Row
                              className="p-2 overflow-y fs-0 minh-640p"
                            >
                              {
                                leadDetails.activity.map((item, index) => (
                                  <Container>
                                    <Row className="pb-5">
                                      <Col
                                        xs={7}
                                        xl={7}
                                        className="pt-1"
                                      >
                                        <Row
                                          className="align-items-center
                                            justify-content-center"
                                        >
                                          <Svg
                                            svg={item.type === 'OUTBOUND_SMS' ? 'chat' : 'call'}
                                            width="40"
                                            height="40"
                                          />
                                        </Row>
                                        <Row
                                          className="align-items-center
                                            justify-content-center mt-n3"
                                        >
                                          {getDay[(new Date(item.createdOn)).getDay()]}
                                        </Row>
                                        <Row
                                          className="align-items-center
                                            justify-content-center"
                                        >
                                          {dateString(item.createdOn) || '--'}
                                        </Row>

                                      </Col>
                                      <Col
                                        xs={11}
                                        xl={11}
                                      >
                                        <b>{item.type}</b>
                                        <Button
                                          variant="link"
                                          className="pl-2"
                                          onClick={() => {
                                            if (showContent !== -1) {
                                              setShowContent(-1);
                                            } else {
                                              setShowContent(index);
                                            }
                                          }}
                                        >
                                          <Svg
                                            svg="arrowHead"
                                            width="18"
                                            stroke={Constant.Color.DARK}
                                          />
                                        </Button>
                                        <div className={`${showContent === index ? '' : 'd-none'}`}>
                                          {item.content || '--'}
                                        </div>
                                        {
                                          item.type === 'OUTBOUND_CALL' && (
                                            <div className="text-truncate">
                                              <Button
                                                variant="link"
                                                className="p-0 fs-01"
                                                onClick={() => {
                                                  window.open(item.recordingUrl);
                                                }}
                                                disabled={!item.recordingUrl}
                                              >
                                                {item.recordingUrl || '--'}
                                              </Button>
                                            </div>
                                          )
                                        }
                                      </Col>
                                      <Col
                                        xs={6}
                                        xl={6}
                                      >
                                        <b>{item.agent || '--'}</b>
                                      </Col>
                                    </Row>
                                  </Container>
                                ))
                              }
                            </Row>
                          ) : (
                            <Row
                              className="p-2 minh-640p justify-content-center"
                            >
                              <b>No Acivity</b>
                            </Row>
                          )
                        }
                    </Col>

                    <Col
                      xs={24}
                      className="px-5 pb-2 pt-5"
                    >
                      <Row className="d-flex align-items-center flex-row-reverse">
                        <Col
                          xs="auto"
                          className="px-2"
                        >
                          <Button
                            variant="outline-primary"
                            onClick={() => {
                              setWhatsappModal(true);
                            }}
                          >
                            <Svg
                              svg="whatsapp"
                              width="24"
                              height="24"
                            />
                            &nbsp;
                            WhatsApp
                          </Button>
                        </Col>
                        <Col
                          xs="auto"
                          className="px-2"
                        >
                          <Button
                            variant="outline-primary"
                            onClick={() => {
                              setSmsModal(true);
                            }}
                          >
                            <Svg
                              svg="sms"
                              width="24"
                              height="24"
                            />
                            &nbsp;
                            SMS
                          </Button>
                        </Col>
                        <Col
                          xs="auto"
                        >
                          <Button
                            variant="outline-primary"
                            onClick={() => {
                              setShowCallOption(true);
                            }}
                          >
                            <Svg
                              svg="call"
                              width="24"
                              height="24"
                            />
                            &nbsp;
                            CALL
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        }
        return showcase;
      })()}
    </>
  );

  const commentBody = (
    <>
      {(() => {
        let showcase = null;
        if (commentLoading) {
          showcase = (
            <div
              className="h-100 d-flex align-items-center
              justify-content-center p-4"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          );
        } else if (!commentLoading && !!commentErrorMsg) {
          showcase = (
            <div
              className="h-100 d-flex align-items-center
              justify-content-center p-4 text-danger"
            >
              {commentErrorMsg}
            </div>
          );
        } else if (!commentLoading && !commentErrorMsg && commentError) {
          showcase = (
            <div
              className="h-100 d-flex align-items-center
              justify-content-center p-4"
            >
              <ErrorHandler
                retryLogic={() => {
                  getCommentHistory();
                }}
              />
            </div>
          );
        } else if (!commentLoading && !commentErrorMsg && !commentError && comments) {
          showcase = (
            <Container className="p-4">
              <Row>
                <Col>
                  <Row className="px-0">
                    <Col>
                      <textarea
                        className="form-control bg-white text-medium fs-02"
                        name="comment"
                        placeholder="Enter Comment"
                        onChange={(event) => {
                          setComment(event.target.value);
                        }}
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            postCommentHistory();
                          }
                        }}
                        maxLength="100"
                      />
                    </Col>
                    <Col>
                      <Button
                        onClick={() => {
                          postCommentHistory();
                        }}
                        disabled={addComment === 'loading'}
                        className="d-flex"
                      >
                        Add Comment
                        &nbsp;
                        {
                          addComment === 'loading' && (
                            <Spinner
                              variant="secondary"
                              animation="border"
                              size="sm"
                            />
                          )
                        }
                      </Button>
                      {
                        addComment === 'error' && (
                          <div className="text-danger">
                            Something went wrong
                          </div>
                        )
                      }
                    </Col>
                  </Row>
                  <Row className="pt-3 pb-1 px-3">
                    Previous Comments History
                  </Row>
                  <Row>
                    <Col
                      className="px-0 overflow-y minh-640p"
                    >
                      <CustomTable
                        headers={[
                          {
                            key: 'comment',
                            displayText: 'Comment',
                          },
                          {
                            key: 'addedBy',
                            displayText: 'Added By',
                          },
                          {
                            key: 'addedFrom',
                            displayText: 'Added From',
                          },
                          {
                            key: 'createdAt',
                            displayText: 'TimeStamp',
                            renderer: (data) => dateString(data.createdAt) || '--',
                          },
                        ]}
                        content={comments.results}
                        keyField="commentId"
                        totalItems={comments.count}
                        isPaginated={false}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        }
        return showcase;
      })()}
    </>
  );

  return (
    <>
      <CustomModal
        show={show}
        title="Lead Details"
        size="xl"
        body={body}
        onHide={onHide}
        closeButton
        backdrop
      />
      <CustomModal
        show={!!updateLeadErrorMsg}
        onHide={() => {
          setUpdateLeadErrorMsg('');
          setLeadData(null);
          setPerformingAction('');
        }}
        autoSize
        backdrop
        border
        body={(
          <Container className="p-4">
            <Row className="mx-0 py-2 text-danger">
              {updateLeadErrorMsg}
            </Row>
            <Row
              className="mx-0 py-2 d-flex align-items-center flex-row-reverse"
            >
              <Button
                variant="link"
                className="font-weight-bold"
                onClick={() => {
                  setUpdateLeadErrorMsg('');
                  patchLeads(leadData);
                }}
              >
                RETRY
              </Button>
              <Button
                variant="link"
                className="font-weight-bold text-danger"
                onClick={() => {
                  setUpdateLeadErrorMsg('');
                  setPerformingAction('');
                  setLeadData(null);
                }}
              >
                CANCEL
              </Button>
            </Row>
          </Container>
        )}
      />
      <CustomModal
        show={showCallOption}
        title="Call Initiate"
        onHide={() => {
          setShowCallOption(false);
        }}
        autoSize
        backdrop
        closeButton
        border
        body={(
          <Container className="p-4">
            <Row
              className="h-100 d-flex align-items-center
              justify-content-center"
            >
              {
                getSchema && getSchema.exotelNumberCodes
                && getSchema.exotelNumberCodes.map((item) => (
                  <Button
                    variant="link"
                    className="font-weight-bold pl-5"
                    onClick={() => {
                      setShowCallOption(false);
                      setexotelNumberCode(item);
                      setExotelCallModal(true);
                    }}
                  >
                    {item}
                  </Button>
                ))
              }
            </Row>
          </Container>
        )}
      />
      <CustomModal
        show={commentModal}
        title="View Comments"
        onHide={() => {
          setCommentModal(false);
        }}
        size="lg"
        backdrop
        closeButton
        border
        body={commentBody}
      />
      {
        exotelCallModal && (
          <ExotelCallPatchDetails
            show={exotelCallModal}
            onHide={() => {
              setExotelCallModal(false);
            }}
            leadId={leadDetails.leadId}
            exotelNumberCode={exotelNumberCode}
            handleRequestProcessing={() => {
              setLoading(true);
              loadLeadDetails();
            }}
            border
          />
        )
      }
      {
        smsModal && (
          <SmsDetails
            show={smsModal}
            onHide={() => {
              setSmsModal(false);
            }}
            leadId={leadDetails.leadId}
            handleRequestProcessing={() => {
              setLoading(true);
              loadLeadDetails();
            }}
            border
          />
        )
      }
      {
        whatsappModal && (
          <WhatsAppDetails
            show={whatsappModal}
            onHide={() => {
              setWhatsappModal(false);
            }}
            leadId={leadDetails.leadId}
            handleRequestProcessing={() => {
              setLoading(true);
              loadLeadDetails();
            }}
            border
          />
        )
      }
    </>
  );
}

ViewLeadDetails.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  leadId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

ViewLeadDetails.defaultProps = {
  leadId: '',
};

export default ViewLeadDetails;
