import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal, ErrorHandler } from '../../component/common';
import { exotelCallInit, resurrectionStores } from '../../assets/api/axios';
import { getUserName, getUserNumber } from '../../utilities/Storage';

class ExotellCallPatch extends Component {
  constructor() {
    super();
    this.state = {
      callLogId: '',
      callPurpose: '',
      callComment: '',
      additionalComment: '',
      processingCall: false,
      callError: false,
      processingPatch: false,
      patchError: false,
      calledOn: '',
    };
  }

  handleChangeCallDetails = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  initiateCall = () => {
    const { phoneNumber, action } = this.props;
    const exotelCallMeta = {
      action,
      callFromUser: {
        adminUser: getUserName(),
        phoneNumber: getUserNumber(),
      },
      callToUser: {
        phoneNumber,
      },
    };
    exotelCallInit(
      'POST',
      exotelCallMeta,
    ).then((res) => {
      this.setState({
        callLogId: res.data.id,
        calledOn: res.data.createdOn,
        processingCall: false,
        callError: false,
      });
    }).catch(() => {
      this.setState({
        processingCall: false,
        callError: true,
      });
    });
  };

  patchCallDetails = () => {
    const {
      callPurpose, callComment, additionalComment, calledOn,
    } = this.state;
    const { onHide, handleRequestProcessing, storeId } = this.props;
    resurrectionStores(
      'PATCH',
      {
        callPurpose,
        callOutcome: callComment,
        callComment: additionalComment,
        calledOn,
      },
      {
        format: 'table',
      },
      storeId,
    ).then(() => {
      onHide();
      handleRequestProcessing();
    }).catch(() => {
      this.setState({
        processingPatch: false,
        patchError: true,
      });
    });
  }

  reset = () => {
    this.setState({
      callLogId: '',
      callPurpose: '',
      callComment: '',
      additionalComment: '',
      processingCall: false,
      callError: false,
      processingPatch: false,
      patchError: false,
    });
  }

  render() {
    const {
      show, onHide, border, callPurposeOptions, callCommentOptions,
    } = this.props;
    const {
      processingCall, callError, callLogId, callPurpose, callComment,
      additionalComment, processingPatch, patchError,
    } = this.state;
    const body = (
      <>
        {(() => {
          let showcase = null;
          if (processingCall) {
            showcase = (
              <div
                className="text-center p-4"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (!processingCall && callError) {
            showcase = (
              <ErrorHandler
                retryLogic={() => {
                  this.setState({
                    processingCall: true,
                    callError: false,
                  }, () => {
                    this.initiateCall();
                  });
                }}
              />
            );
          } else if (!processingCall && !callError && !!callLogId) {
            showcase = (
              <Container>
                <Row>
                  <Col
                    xs={24}
                    className="p-2 bg-success text-center"
                  >
                    <b>Your call has been initiated successfully</b>
                  </Col>
                  <Col xs={24} className="mt-4 p-2 fs-2 text-center">
                    Please fill following details and submit
                  </Col>
                </Row>
                <Row className="pt-4">
                  <Col xs={24}>
                    <b>1. Call Purpose</b>
                    <span className="text-danger">*</span>
                  </Col>
                  <Col xs={24} className="pt-2">
                    <select
                      name="callPurpose"
                      value={callPurpose}
                      className="form-control"
                      onChange={(event) => {
                        if (event.target.value !== '') {
                          this.handleChangeCallDetails(event);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      {callPurposeOptions.map((purpose) => (
                        <option value={purpose.value} key={purpose.value}>{purpose.label}</option>
                      ))}
                    </select>
                  </Col>
                </Row>
                <Row className="pt-4">
                  <Col xs={24}>
                    <b>2. Call Comments</b>
                    <span className="text-danger">*</span>
                  </Col>
                  <Col xs={24} className="pt-2">
                    <select
                      name="callComment"
                      value={callComment}
                      className="form-control"
                      onChange={(event) => {
                        if (event.target.value !== '') {
                          this.handleChangeCallDetails(event);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      {callCommentOptions.map((purpose) => (
                        <option value={purpose.value} key={purpose.value}>{purpose.label}</option>
                      ))}
                    </select>
                  </Col>
                </Row>
                <Row className="pt-4">
                  <Col xs={24}>
                    <b>3. Additional Comment</b>
                  </Col>
                  <Col xs={24} className="pt-2">
                    <input
                      type="text"
                      name="additionalComment"
                      value={additionalComment}
                      placeholder="*Required if call comment selects Other"
                      className="form-control"
                      maxLength={100}
                      onChange={this.handleChangeCallDetails}
                    />
                  </Col>
                </Row>
                <Row className="pt-4">
                  <Col xs={24} className="text-center">
                    {processingPatch
                      ? (
                        <Spinner
                          variant="primary"
                          animation="border"
                        />
                      ) : (
                        <>
                          <Button
                            variant="primary"
                            block
                            onClick={() => {
                              this.setState({
                                processingPatch: true,
                                patchError: false,
                              }, () => {
                                this.patchCallDetails();
                              });
                            }}
                            disabled={!callPurpose || !callComment || (callComment === 'Others' && !additionalComment)}
                          >
                            Submit
                          </Button>
                          <div className="py-2 text-danger">
                            {!processingPatch && patchError
                            && (
                              <b>Oops Something went wrong!!</b>
                            )}
                          </div>
                        </>
                      )}
                  </Col>
                </Row>
              </Container>
            );
          }
          return showcase;
        })()}
      </>
    );
    return (
      <CustomModal
        show={show}
        onHide={onHide}
        body={body}
        onEnter={() => {
          this.setState({
            processingCall: true,
          }, this.initiateCall());
        }}
        autoSize
        border={border}
        backdrop={processingCall
          || callError || 'static'}
        reset={this.reset}
      />
    );
  }
}

ExotellCallPatch.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  action: PropTypes.string,
  phoneNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  storeId: PropTypes.number.isRequired,
  callPurposeOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  callCommentOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleRequestProcessing: PropTypes.func,
  border: PropTypes.bool,
};

ExotellCallPatch.defaultProps = {
  action: '',
  handleRequestProcessing: () => {},
  border: false,
};

export default ExotellCallPatch;
