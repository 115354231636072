import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal } from '../../../component/common';
import { executors } from '../../../assets/api/axios';

function DeleteExecutor(props) {
  const {
    show, onHide, executorDetails, handleRequestProcessing,
  } = props;
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  function handleDelete() {
    setSubmitting(true);
    executors(
      'DELETE',
      {},
      executorDetails.executorId,
    ).then((res) => {
      if (res.status === 200) {
        setSubmitting(false);
        onHide();
        handleRequestProcessing();
      } else {
        throw new Error();
      }
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong. Please Try Again';
      if (
        err
        && err.response
        && err.response.data
        && err.response.status === 400
        && err.response.data[0].server_message
      ) {
        errorMsg = err.response.data[0].server_message;
      }
      setSubmitting(false);
      setSubmitError(errorMsg);
    });
  }

  const body = (
    <Container className="px-5 py-4">
      <Row>
        <Col
          xs={24}
          className="text-center fs-3"
        >
          Are you sure you want to delete &nbsp;&nbsp;&nbsp;
          <b>{executorDetails.executorName}</b>
        </Col>
      </Row>
      <Row
        className="mt-4"
      >
        <Col
          xs={12}
          className="text-right"
        >
          <Button
            variant="primary"
            onClick={() => onHide()}
          >
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            variant="danger"
            onClick={() => {
              handleDelete();
            }}
          >
            Proceed
          </Button>
        </Col>
      </Row>
      {
        submitting && (
          <Row
            className="pt-2 d-flex align-item-center
              justify-content-center"
          >
            <Spinner
              variant="primary"
              animation="border"
            />
          </Row>
        )
      }
      {
        !submitting && submitError && (
          <Row
            className="pt-2 d-flex align-item-center
              justify-content-center text-danger"
          >
            {submitError}
          </Row>
        )
      }
    </Container>
  );

  return (
    <CustomModal
      show={show}
      title="Delete Executor"
      onHide={onHide}
      body={body}
      autoSize
      closeButton
    />
  );
}

DeleteExecutor.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  executorDetails: PropTypes.shape({
    executorName: PropTypes.string,
    executorId: PropTypes.string,
  }).isRequired,
  handleRequestProcessing: PropTypes.func.isRequired,
};

export default DeleteExecutor;
