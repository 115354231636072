import React, { Component } from 'react';
import {
  Container, Row, Col, Spinner, Button, InputGroup, Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import { reconcile, settlement } from '../../assets/api/axios';
import {
  CustomTable, CustomDropdown, DatePicker, CustomModal, Svg,
} from '../../component/common';
import { Constant } from '../../utilities';
import { dateString } from '../../utilities/Utils';
import Permission from '../../access&permissions/permission';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, createdOnFrom, createdOnTo,
  } = param;
  const {
    storeIds = '',
    orderIds = '',
    status = '',
    paymentMode = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  createdOnFrom = Number(createdOnFrom) || '';
  createdOnTo = Number(createdOnTo) || '';
  return ({
    ...param,
    l,
    p,
    orderIds,
    storeIds,
    status,
    paymentMode,
    createdOnFrom,
    createdOnTo,
  });
};

class PendingSettlements extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    const { userPermission } = props;
    this.canIEdit = userPermission?.includes(Permission.PENDING_SETTLEMENTS_WRITE);
    this.state = {
      settlements: null,
      rowsPerPage: param.l,
      page: param.p,
      storeIds: param.storeIds,
      orderIds: param.orderIds,
      loading: true,
      error: false,
      selectedSettlementsIds: [],
      settlementError: '',
      settlementSuccess: false,
      reconciling: false,
      meta: null,
      submitting: false,
      param,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    const { param } = this.state;
    this.handleLoad({
      createdOnFrom: Number(param.createdOnFrom)
        || new Date(new Date().setDate(new Date().getDate() - 3)).getTime(),
      createdOnTo: Number(param.createdOnTo) || (new Date()).getTime(),
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, storeIds, orderIds, status, paymentMode, createdOnFrom, createdOnTo,
    } = newParam;
    // const offset = (p - 1) * l;
    settlement(
      'GET',
      {
        page: p,
        limit: l,
        storeId: storeIds,
        orderIds,
        status,
        paymentModes: paymentMode,
        createdOnFrom,
        createdOnTo,
      },
      null,
      this.source.token,
    ).then((res) => {
      this.setState({
        settlements: res.data,
        loading: false,
        rowsPerPage: l,
        page: p,
        storeIds: newParam.storeIds,
        orderIds: newParam.orderIds,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  confirmReconcile = (reconciling) => {
    this.setState({
      reconciling,
    });
  }

  handleMarkReconcile = () => {
    const { selectedSettlementsIds } = this.state;
    this.setState({
      submitting: true,
      settlementSuccess: false,
      settlementError: false,
    });
    const settlementIds = selectedSettlementsIds.reduce((acc, item) => {
      if (item.canReconcile) {
        const sIds = item.settlements.reduce((acc1, s) => {
          if (s.canReconcile) {
            return [...acc1, s.id];
          }
          return acc1;
        }, []);
        return [...acc, ...sIds];
      }
      return acc;
    }, []);
    reconcile(
      'POST',
      {
        settlementIds,
      },
    ).then(() => {
      this.setState({
        submitting: false,
        settlementSuccess: true,
        selectedSettlementsIds: [],
      }, () => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong!! Please Try Again!!';
      if (err?.response?.data?.server_response) {
        errorMsg = err.response.data.server_response;
      }
      this.setState({
        submitting: false,
        settlementError: `Error: ${errorMsg}`,
      });
    });
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onSubmitSearchText = (e) => {
    const { name, value } = e.target;
    const { param } = this.state;
    if (value !== param[name]) {
      this.handleRequestProcessing({ [name]: value, p: 1 });
    }
    this.setState({
      selectedSettlementsIds: [],
    });
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
    this.setState({
      selectedSettlementsIds: [],
    });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
    this.setState({
      selectedSettlementsIds: [],
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
      this.setState({
        selectedSettlementsIds: [],
      });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
      this.setState({
        selectedSettlementsIds: [],
      });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
      this.setState({
        selectedSettlementsIds: [],
      });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
      this.setState({
        selectedSettlementsIds: [],
      });
    }
  }

  handleDateChange = (createdOnFrom, createdOnTo) => {
    const { param } = this.state;
    if (createdOnFrom !== param.createdOnFrom
      || createdOnTo !== param.createdOnTo) {
      this.handleRequestProcessing({
        createdOnFrom,
        createdOnTo,
        p: 1,
      });
      this.setState({
        selectedSettlementsIds: [],
      });
    }
  }

  toggleSelectSettlement = (event, data) => {
    const { selectedSettlementsIds } = this.state;
    if (event.target.checked) {
      selectedSettlementsIds.push(data);
    } else {
      const index = selectedSettlementsIds.findIndex((os) => os.orderId === data.orderId);
      selectedSettlementsIds.splice(index, 1);
    }
    this.setState({
      selectedSettlementsIds,
    });
  }

  selectRenderer = (data) => {
    const { selectedSettlementsIds, reconciling } = this.state;
    if (data.canReconcile) {
      return (
        <input
          type="checkbox"
          checked={!!selectedSettlementsIds.find((os) => os.orderId === data.orderId)}
          name="markReconcile"
          onChange={(event) => this.toggleSelectSettlement(event, data)}
          disabled={reconciling}
        />
      );
    } return (
      <Svg
        svg="circleClose"
        width="15px"
        height="15px"
        circleFill={Constant.Color.RED}
        pathFill={Constant.Color.WHITE}
      />
    );
  }

  handleSelectAll = (event) => {
    const { settlements } = this.state;
    if (event.target.checked) {
      this.setState({
        selectedSettlementsIds: (settlements.data).reduce((Ids, item) => {
          if (item.canReconcile) {
            Ids.push(item);
          }
          return Ids;
        }, []),
      });
    } else {
      this.setState({
        selectedSettlementsIds: [],
      });
    }
  }

  render() {
    const {
      settlements, loading, error, rowsPerPage,
      page, param, storeIds, orderIds, settlementError,
      reconciling, meta, selectedSettlementsIds,
      submitting, settlementSuccess,
    } = this.state;

    const { isDetailPage } = this.props;
    const headers = [
      ...!isDetailPage && this.canIEdit ? [
        {
          key: 'select',
          displayText: (
            <input
              type="checkbox"
              checked={
                settlements?.data
                  ? (((settlements.data).filter((item) => item.canReconcile)).length
                        === selectedSettlementsIds.length
                    && (selectedSettlementsIds.length > 0))
                  : (false)
              }
              onChange={(event) => this.handleSelectAll(event)}
              disabled={!settlements?.data}
            />
          ),
          renderer: (data) => this.selectRenderer(data),
        },
      ] : [],
      {
        key: 'orderId',
        displayText: 'Order Id',
      },
      {
        key: 'storeName',
        displayText: 'Store Name - Code',
        renderer: (data) => (
          <>{`${data.storeName} - ${data.storeId}`}</>
        ),
      },
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => (
          <>{dateString(data.createdOn)}</>
        ),
      },
      {
        key: 'totalDigitalAmount',
        displayText: 'Digital Amt.',
      },
      {
        key: 'totalDigitalFees',
        displayText: 'Digital Fees',
      },
      {
        key: 'totalDiscountAmount',
        displayText: 'Discount Amt.',
      },
      {
        key: 'totalRecoveryAmount',
        displayText: 'Total Recovery Amt.',
      },
      // {
      //   key: 'total3PLSettlementAmount',
      //   displayText: '3PL Settlement Amt. ',
      // },
      {
        key: 'totalAmount',
        displayText: 'Total Amt.',
      },
      {
        key: 'statuses',
        displayText: 'Statuses In',
        renderer: (data) => (
          <span>{data.statuses.join(', ')}</span>
        ),
      },
      {
        key: 'meta',
        displayText: 'View Details',
        renderer: (data) => (
          <Button
            variant="link"
            className="px-2 py-2 fs-01"
            onClick={() => {
              this.setState({
                meta: data.settlements,
              });
            }}
          >
            View
          </Button>
        ),
      },
      // ...isEditAllowed && !userPermission.includes('SETTLEMENT_READ_ONLY') ? [
      ...this.canIEdit ? [
        {
          key: '',
          displayText: 'Mark Reconcile',
          renderer: (data) => (
            <Button
              variant="outline-primary"
              className="py-1"
              onClick={() => {
                this.setState({
                  selectedSettlementsIds: [data],
                }, this.confirmReconcile(true));
              }}
              disabled={
                !data.canReconcile
                || reconciling
              }
            >
              <span
                className="fs-01"
              >
                Mark Reconcile
              </span>
            </Button>
          ),
        }] : [],
    ];

    const filterConf = [
      {
        key: 'status',
        displayText: 'Status',
        options: [
          {
            label: 'PENDING',
            value: 'PENDING',
          },
          {
            label: 'PROCESSING',
            value: 'PROCESSING',
          },
          {
            label: 'PAYMENT_PROCESSING',
            value: 'PAYMENT_PROCESSING',
          },
          {
            label: 'RECONCILED',
            value: 'RECONCILED',
          },
          {
            label: 'ON_HOLD',
            value: 'ON_HOLD',
          },
          {
            label: 'WRITE_OFF',
            value: 'WRITE_OFF',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
      {
        key: 'paymentMode',
        displayText: 'Payment Mode',
        options: [
          {
            label: 'DIGITAL',
            value: 'DIGITAL',
          },
          {
            label: 'CASH',
            value: 'CASH',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
    ];

    let totals = {
      total: 0,
      digitalAmount: 0,
      discount: 0,
      positiveAmount: 0,
    };

    if (settlements && selectedSettlementsIds.length) {
      totals = settlements.data.reduce(
        (acc, item) => {
          if (selectedSettlementsIds.find((os) => os.orderId === item.orderId)) {
            totals.total += item.totalAmount;
            totals.digitalAmount += item.totalDigitalAmount;
            totals.discount += item.totalDiscountAmount;
            totals.positiveAmount += item.totalPositiveAmount;
          }
          return acc;
        },
        totals,
      );
    }

    if (loading || error) {
      return (
        <div
          className="pt-3 text-center"
        >
          {loading ? (
            <Spinner
              animation="border"
              variant="primary"
            />
          ) : (
            <>
              <span
                className="text-danger"
              >
                Something Went Wrong
              </span>
              <div>
                <Button
                  variant="primary"
                  onClick={() => this.retry()}
                >
                  Retry
                </Button>
              </div>
            </>
          )}
        </div>
      );
    }

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        <CustomModal
          show={reconciling}
          closeButton
          footer={(
            <Row className="align-items-center">
              {
                submitting && (
                  <Col
                    xs="auto"
                  >
                    <Spinner
                      variant="primary"
                      animation="border"
                      size="md"
                    />
                  </Col>
                )
              }
              {
                settlementError && (
                  <Col
                    xs="auto"
                    className="pb-2 text-danger"
                  >
                    <b>{settlementError}</b>
                  </Col>
                )
              }
              {
                settlementSuccess && (
                  <Col
                    xs="auto"
                    className="px-2 pb-2 text-success"
                  >
                    <b>Successful!</b>
                  </Col>
                )
              }
              <Col
                xs="auto"
                className="text-right"
              >
                <Button
                  variant="outline-primary"
                  value="notNow"
                  className="py-1 px-3 fs-1"
                  disabled={submitting}
                  onClick={() => {
                    this.setState({
                      reconciling: false,
                    });
                  }}
                >
                  NOT NOW
                </Button>
              </Col>
              <Col
                xs="auto"
                className="text-left"
              >
                <Button
                  variant={reconciling ? 'outline-success' : 'outline-danger'}
                  value="submit"
                  className="py-1 px-3 fs-1 lg active"
                  disabled={submitting || settlementSuccess}
                  onClick={() => {
                    this.handleMarkReconcile();
                  }}
                >
                  SUBMIT
                </Button>
              </Col>
            </Row>
          )}
          body={(
            <Container
              className="justify-content-center px-0"
            >
              <Row
                className="mx-auto p-2"
              >
                <Col
                  xs={24}
                  className="text-right px-0"
                >
                  <Button
                    variant="link"
                    className="text-medium p-0"
                    onClick={() => {
                      this.setState({
                        reconciling: false,
                      });
                    }}
                    disabled={submitting}
                  >
                    &#x2715;
                  </Button>
                </Col>
                <Col
                  xs={24}
                  className="text-center fs-1 p-2"
                >
                  Are you sure you want to&nbsp;&nbsp;
                  { reconciling ? 'RECONCILE' : 'DENY' }
                  &nbsp;&nbsp;
                  {selectedSettlementsIds.length === 1 ? 'this settlement?' : 'these settlements?'}
                </Col>
              </Row>
            </Container>
          )}
          autoSize
        />
        <CustomModal
          show={!!meta}
          title="Settlements Detail"
          closeButton
          onHide={() => {
            this.setState({
              meta: null,
            });
          }}
          size="lg"
          body={(
            <>
              {meta ? (
                <Container>
                  <CustomTable
                    headers={[
                      {
                        key: 'id',
                        displayText: 'Id',
                      },
                      {
                        key: 'status',
                        displayText: 'Status',
                      },
                      {
                        key: 'createdOn',
                        displayText: 'Created On',
                        renderer: (data) => (
                          <>{dateString(data.createdOn)}</>
                        ),
                      },
                      {
                        key: 'transferFee',
                        displayText: 'Transfer Fee',
                      },
                      {
                        key: 'amount',
                        displayText: 'Amount',
                      },
                      {
                        key: 'reconciledId',
                        displayText: 'Reconciled Id',
                      },
                      {
                        key: 'settlementType',
                        displayText: 'Settlement Type',
                      },
                      {
                        key: 'remarks',
                        displayText: 'Remark',
                      },
                    ]}
                    content={meta}
                    keyField="id"
                    totalItems={meta.length}
                    isPaginated={false}
                  />
                </Container>
              ) : ''}
            </>
          )}
        />
        <Row>
          <Col
            xs={24}
            sm="auto"
            className="px-2 py-2"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Store Ids"
                name="storeIds"
                className="fs-01 rounded-0"
                value={storeIds}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.onSubmitSearchText(e);
                  }
                }}
              />
            </InputGroup>
          </Col>
          <Col
            xs={24}
            sm="auto"
            className="px-2 py-2"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Order Ids"
                name="orderIds"
                className="fs-01 rounded-0"
                value={orderIds}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.onSubmitSearchText(e);
                  }
                }}
              />
            </InputGroup>
          </Col>
          {filterConf.map((item) => (
            <Col
              key={item.key}
              xs="auto"
              className="px-2 py-2"
            >
              <CustomDropdown
                item={item}
                onChange={this.handleDropdownChange}
                selectedVal={param[item.key]}
              />
            </Col>
          ))}
          <Col
            xs="auto"
            className="px-2 py-2"
          >
            <DatePicker
              isDateRange
              onApply={(dateRange) => {
                this.handleDateChange(
                  new Date(dateRange.startDate).getTime(),
                  new Date(dateRange.endDate).getTime(),
                );
              }}
              startDate={param.createdOnFrom}
              endDate={param.createdOnTo}
              onClear={() => {
                this.handleRequestProcessing({
                  createdOnFrom: '', createdOnTo: '',
                });
              }}
            />
          </Col>
        </Row>
        { selectedSettlementsIds.length > 0 && (
        <Row>
          <Col
            xs="auto"
            className="px-2 pb-2"
          >
            <Button
              variant="primary"
              className="py-1 px-3 fs-01"
              onClick={() => { this.confirmReconcile(true); }}
              disabled={reconciling}
            >
                  &nbsp;&nbsp;Mark Reconcile
            </Button>
          </Col>
          <Col
            xs="auto"
            className="fs-01 mt-2 font-weight-bold"
          >
            Total Amount:&nbsp;
            { totals.total }
          </Col>
          <Col
            xs="auto"
            className="fs-01 mt-2 font-weight-bold"
          >
            Digital Amount:&nbsp;
            { totals.digitalAmount }
          </Col>
          <Col
            xs="auto"
            className="fs-01 mt-2 font-weight-bold"
          >
            Discount Amount:&nbsp;
            { totals.discount }
          </Col>
          <Col
            xs="auto"
            className="fs-01 mt-2 font-weight-bold"
          >
            Total Positive Amount:&nbsp;
            { totals.positiveAmount }
          </Col>
        </Row>
        )}
        <Row>
          <Col
            xs={24}
            className="px-0"
          >
            <CustomTable
              headers={headers}
              content={settlements.data}
              keyField="id"
              l={param.l}
              p={param.p}
              rowsPerPage={rowsPerPage}
              page={page}
              totalItems={settlements.count}
              hasPrev={settlements.prev !== ''}
              hasNext={settlements.next !== ''}
              onNext={this.onNext}
              onPrev={this.onPrev}
              onSubmitPage={this.onSubmitPage}
              onSubmitRowsPerPage={this.onSubmitRowsPerPage}
              updateRowsPageInput={this.handleRowsPageInput}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

PendingSettlements.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  isDetailPage: PropTypes.bool,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

PendingSettlements.defaultProps = {
  isDetailPage: false,
};

export default PendingSettlements;
