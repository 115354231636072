import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Spinner } from 'react-bootstrap';
import { CustomModal, ErrorHandler } from '../../../component/common';

function SuccessfulModal(props) {
  const {
    show, onHide, submittingState, retry,
  } = props;
  const body = (
    <Container className="px-5 py-4">
      {(() => {
        let showcase = null;
        if (submittingState === 'loading') {
          showcase = (
            <div
              className="h-100 d-flex align-items-center
              justify-content-center px-4"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          );
        } else if (submittingState === 'error') {
          showcase = (
            <div
              className="h-100 d-flex align-items-center
              justify-content-center px-4"
            >
              <ErrorHandler
                retryLogic={() => {
                  retry();
                }}
              />
            </div>
          );
        } else if (submittingState === 'success') {
          showcase = (
            <>
              <Row className="justify-content-center text-center px-5">
                <h3>Updated</h3>
              </Row>
              <Row className="justify-content-center text-center py-1">
                <span className="fs-2">
                  Great job! You can edit where you deliver at any time
                </span>
              </Row>
            </>
          );
        }
        return showcase;
      })()}
    </Container>
  );

  return (
    <CustomModal
      show={show}
      onHide={onHide}
      title=" "
      body={body}
      autoSize
      closeButton
      backdrop
    />
  );
}

SuccessfulModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  submittingState: PropTypes.string.isRequired,
  retry: PropTypes.func.isRequired,
};

export default SuccessfulModal;
